import React from "react";
import { Space } from "@mantine/core";
import { shallow } from "zustand/shallow";

import * as QbDateInput from "./QbDateInput";
import QbSegmentedControl from "./QbSegmentedControl";
import QbSelect from "./QbSelect";
import QbNumberField from "./QbNumberField";
import QbTextInput from "./QbTextInput";

import useAppStore from "./report-recipe-runner-store";

export default function ReportRecipeQueryComponents() {
  const { locations, orgs, queryComponents, queryData } = useAppStore(
    (state) => ({
      locations: state.locations,
      orgs: state.orgs,
      queryComponents: state.query_components,
      queryData: state.query_data,
    }),
    shallow
  );

  const hasLocations = locations.length > 0;
  const hasOrgs = orgs.length > 0;

  return (
    <div>
      {queryComponents
        .filter((f) => {
          if (!f.show_on) {
            return true;
          }
          let shouldShow = true;
          f.show_on.forEach((f) => {
            if (["location_ids", "organization_ids"].includes(f)) {
              if (f === "location_ids") {
                if (!hasLocations) shouldShow = false;
              } else {
                if (!hasOrgs) shouldShow = false;
              }
            } else {
              const qc = queryComponents.find((ff) => ff.server_name === f);
              if (!qc) return;
              if (qc.type === "select") {
                if (!queryData[f].length) {
                  shouldShow = false;
                }
              } else {
                shouldShow = queryData[f] ? true : false;
              }
            }
          });
          return shouldShow;
        })
        .map((m, i) => {
          return (
            <div key={i}>
              {m.type === "select" && <QbSelect field={m} />}
              {m.type === "effortPicker" && (
                <QbSelect
                  field={{
                    ...m,
                    label: "Effort",
                  }}
                />
              )}
              {m.type === "datepicker" && <QbDateInput.Standard field={m} />}
              {m.type === "segmentedButton" && <QbSegmentedControl field={m} />}
              {m.type === "numberInput" && <QbNumberField field={m} />}
              {m.type === "textInput" && <QbTextInput field={m} />}
              {i + 1 < queryComponents.length && <Space mb="6px" />}
            </div>
          );
        })}
    </div>
  );
}
