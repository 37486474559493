import React from "react";
import { Card, Group, Tabs, Text, Title, Space } from "@mantine/core";
import { IconReportSearch } from "@tabler/icons-react";

import { EffortManagement } from "@components/Effort";
import { QrCodeGenerator } from "@components/shared";
import entityIcon from "@util/entityIcon";

const SectionTitle = ({ children }) => (
  <Title order={4} mb="sm">
    {children}
  </Title>
);

export default function CampaignViewOnly({ campaignInfo = null }) {
  if (!campaignInfo) return null;

  return (
    <div>
      <Tabs defaultValue="detail">
        <Tabs.List mb="lg">
          <Tabs.Tab value="detail" icon={<IconReportSearch />}>
            Details
          </Tabs.Tab>
          <Tabs.Tab value="efforts" icon={entityIcon.effort()}>
            Efforts
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="detail">
          <Card>
            {campaignInfo.start_date && campaignInfo.end_date && (
              <React.Fragment>
                <SectionTitle>Dates</SectionTitle>
                <Group spacing="xs">
                  {entityIcon.calendar(24)}
                  <Text>
                    {new Date(campaignInfo.start_date).toLocaleDateString()} -{" "}
                    {new Date(campaignInfo.end_date).toLocaleDateString()}
                  </Text>
                </Group>
                {/* <Space mb="xl" /> */}
              </React.Fragment>
            )}
            {/* <SectionTitle>QR Code</SectionTitle>
            <QrCodeGenerator
              text={`${import.meta.env.VITE_DOMAIN}/entry/${
                campaignInfo.microsite_url
              }`}
            /> */}
          </Card>
        </Tabs.Panel>
        <Tabs.Panel value="efforts">
          <EffortManagement showCreate={false} campaignId={campaignInfo.id} />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}
