import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Group, Image, Text, TextInput } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

import { PaginatedList } from "@components/shared";
import entityIcon from "@util/entityIcon";

export default function ArtistList({
  requestInfo = {
    url: `/retrieve-artists/`,
    data: {},
  },
  refresh,
}) {
  const [searchValue, setSearchValue] = useState("");

  const reqData = {
    ...requestInfo.data,
    search_value: searchValue,
    refresh,
    status: 1,
  };

  return (
    <div>
      {requestInfo && (
        <TextInput
          placeholder="Search for an artist..."
          size="lg"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      )}
      <PaginatedList
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/artists/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <Card
            component={Link}
            to={item.original.link_url}
            key={item.original.id}
          >
            <Group align="flex-start" gap="xs">
              <div style={{ flexGrow: 1 }}>
                <Text fw={700}>{item.original.name}</Text>
              </div>
            </Group>
            {item.original.is_curated && (
              <Group gap="xs" align="center">
                <div>
                  <IconCheck
                    size={16}
                    stroke={5}
                    color="var(--mantine-color-green-6)"
                  />
                </div>
                <Text size="sm">Curated</Text>
              </Group>
            )}
            {item.original.song_count && (
              <Group gap="xs" align="center">
                <div>{entityIcon.song(16)}</div>
                <Text size="sm">
                  {item.original.song_count} song
                  {item.original.song_count === 1 ? "" : "s"}
                </Text>
              </Group>
            )}
          </Card>
        )}
      />
    </div>
  );
}
