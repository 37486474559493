import React from "react";
import { Title } from "@mantine/core";

export default function Header({
  children = <React.Fragment>Header</React.Fragment>,
  titleProps = {
    order: 4,
  },
}) {
  return <Title {...titleProps}>{children}</Title>;
}
