import React, { useState, useContext } from "react";
import { TimeInput } from "@mantine/dates";
import { Grid, Modal, Group, Button, Select } from "@mantine/core";
import dayjs from "dayjs";

import { Context } from "./";
import { Counter } from "@components/shared";
import { getUuid } from "@util/getUuid";
import { ValueItem, IntervalItem } from "@components/shared/Intervals";

export const intervalValues = {
  hourly: 1,
  daily: 2,
  weekly: 3,
  monthly: 4,
  yearly: 5,
  ever: 6,
  custom: 7,
};

const intervalOptions = [
  { text: "H", value: intervalValues.hourly, title: "Hour" },
  { text: "D", value: intervalValues.daily, title: "Daily" },
  { text: "W", value: intervalValues.weekly, title: "Weekly" },
  { text: "M", value: intervalValues.monthly, title: "Monthly" },
  { text: "Y", value: intervalValues.yearly, title: "Yearly" },
  { text: "E", value: intervalValues.ever, title: "Ever" },
  { text: "C", value: intervalValues.custom, title: "Custom" },
].map((m, i) => ({
  ...m,
  key: i + 1,
}));

export default function Intervals({ title, items = [], onChange }) {
  const [creating, setCreating] = useState(false);

  return (
    <div style={{ marginBottom: "2em" }}>
      <Group>
        <h3>Intervals</h3>
        <Button
          size="xs"
          variant="light"
          radius="xl"
          onClick={() => setCreating(true)}
        >
          Create
        </Button>
      </Group>
      <Grid>
        {items.map((item) => (
          <Grid.Col key={item.uuid} span={{ base: 12, md: 4 }}>
            <IntervalItem
              key={item.uuid}
              frequency={item.frequency}
              interval={item.interval}
              values={item.values}
              onRemoveClick={() =>
                onChange([...items].filter((f) => f.uuid !== item.uuid))
              }
            />
          </Grid.Col>
        ))}
      </Grid>
      <Modal
        opened={creating}
        onClose={() => setCreating(false)}
        title={`Create ${title}`}
      >
        <AddForm
          onSubmit={(e) => {
            onChange([...items, e]);
            setCreating(false);
          }}
        />
      </Modal>
    </div>
  );
}

const AddForm = ({ onSubmit }) => {
  const [formValues, setFormValues] = useState({
    frequency: 0,
    interval: intervalValues.hourly,
    values: [],
  });

  function onSubmitClick() {
    onSubmit({ ...formValues, uuid: getUuid() });
  }

  return (
    <div>
      <IntervalButtons
        value={formValues.interval}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            interval: e,
            frequency: e === intervalValues.custom ? 0 : formValues.frequency,
          })
        }
      />
      <Group mt="lg" mb="lg">
        {formValues.interval !== intervalValues.custom && (
          <React.Fragment>
            <Counter
              value={formValues.frequency}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  frequency: e,
                })
              }
            />
            <h3 style={{ margin: "0" }}>times</h3>
          </React.Fragment>
        )}
      </Group>
      {formValues.interval === intervalValues.custom && (
        <React.Fragment>
          <h4 style={{ marginBottom: "0" }}>Values</h4>
          {formValues.values.map((v) => (
            <ValueItem
              key={v.uuid}
              day={v.day}
              start={v.start}
              end={v.end}
              onRemoveClick={() =>
                setFormValues({
                  ...formValues,
                  values: [...formValues.values].filter(
                    (f) => f.uuid !== v.uuid
                  ),
                })
              }
            />
          ))}
          <ValueAdd
            onAdd={(e) =>
              setFormValues({
                ...formValues,
                values: [...formValues.values, e],
              })
            }
          />
        </React.Fragment>
      )}
      <Button fullWidth variant="light" mt="md" onClick={onSubmitClick}>
        Create Interval
      </Button>
    </div>
  );
};

const IntervalButtons = ({ value, onChange }) => (
  <Grid>
    {intervalOptions.map((m) => (
      <Grid.Col span={{ base: 12, md: 4 }} key={m.key}>
        <Button
          key={m.key}
          onClick={() => onChange(m.value)}
          variant={value === m.value ? "filled" : "light"}
          size="xs"
          color={value === m.value ? "blue" : "gray"}
          title={m.title}
          fullWidth
        >
          {m.title}
        </Button>
      </Grid.Col>
    ))}
  </Grid>
);

const defaultFormValues = {
  start: "",
  end: "",
  day: "",
};

const ValueAdd = ({ onAdd }) => {
  const { contestDates } = useContext(Context);
  const daysBetween =
    dayjs(contestDates.end).diff(contestDates.begin, "day") + 1;
  const daysBetweenArray = Array.from({ length: daysBetween }, (v, k) => k + 1);
  const dayOptions = daysBetweenArray.map((m, i) => ({
    label: `Day ${m} (${new Date(
      dayjs(contestDates.begin).add(i, "day")
    ).toLocaleDateString()})`,
    value: m,
  }));

  const [formValues, setFormValues] = useState(defaultFormValues);

  return (
    <div>
      <Select
        label="Day"
        data={dayOptions}
        placeholder="Select one..."
        value={formValues.day}
        onChange={(e) => setFormValues({ ...formValues, day: e })}
      />
      <Group grow>
        <TimeInput
          label="Start"
          format="12"
          value={formValues.start}
          onChange={(e) =>
            setFormValues({ ...formValues, start: e.target.value })
          }
        />
        <TimeInput
          label="End"
          format="12"
          value={formValues.end}
          onChange={(e) =>
            setFormValues({ ...formValues, end: e.target.value })
          }
        />
      </Group>
      <Button
        fullWidth
        mt="md"
        onClick={() => {
          onAdd({ ...formValues, uuid: getUuid() });
          setFormValues(defaultFormValues);
        }}
        disabled={!formValues.end || !formValues.start || !formValues.day}
      >
        Add Value
      </Button>
    </div>
  );
};
