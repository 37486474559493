import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Text,
  TextInput,
  SegmentedControl,
  Loader,
  Divider,
  Group,
} from "@mantine/core";

import { CurationItem } from "./";
import { PaginatedList } from "@components/shared";
import { LocationSelect } from "@components/Location";

export default function CurationList() {
  const [sortMethod, setSortMethod] = useState("date");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [locationId, setLocationId] = useState("");

  const reqData = {
    search_value: searchValue,
    sort_by: sortMethod,
    refresh,
    location_id: locationId ? locationId : null,
  };

  const locationSelectReqData = {
    variety: 1,
  };

  return (
    <div>
      <Group mb="xs" size="lg">
        <Text fw={600}>Curation</Text>
        <SegmentedControl
          value={sortMethod}
          data={[
            { label: "Date", value: "date" },
            { label: "Song name", value: "song" },
            { label: "Artist name", value: "artist" },
          ]}
          onChange={(e) => setSortMethod(e)}
        />
      </Group>
      <LocationSelect
        value={locationId}
        onChange={(e) => setLocationId(e)}
        label="Search locations"
        reqData={locationSelectReqData}
      />
      <Divider mt="lg" mb="lg" />
      <TextInput
        placeholder={`Start typing...`}
        value={searchValue}
        mt="xs"
        label="Search songs and artists"
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <PaginatedList
        requestInfo={{
          url: `/retrieve-curation/`,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/curation/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <CurationItem
            key={item.original.id}
            rawData={{
              ...item.original,
              song: true,
            }}
            id={item.original.id}
            fetchData={() => setRefresh(!refresh)}
          />
        )}
      />
    </div>
  );
}
