import React from "react";
import {
  Anchor,
  Checkbox,
  Input,
  MultiSelect,
  NativeSelect,
  FileInput,
  Select,
  Switch,
  Textarea,
  TextInput,
} from "@mantine/core";
import InputMask from "react-input-mask";
import toast from "react-hot-toast";

import AddressLookup from "./AddressLookup";
import ZipLookup from "./ZipLookup";

const CustomInputComponent = ({
  field,
  form: { touched, errors, values },
  ...props
}) => {
  if (props.toggle) {
    return (
      <Switch
        label={props.label}
        checked={field.value}
        style={{ margin: "1em 0" }}
        // onLabel="ON"
        // offLabel="OFF"
        size="md"
        onChange={(e, d) =>
          field.onChange({
            target: {
              name: field.name,
              value: e.currentTarget.checked,
            },
          })
        }
      />
    );
  }
  if (props.checkbox) {
    return (
      <Checkbox
        label={
          <React.Fragment>
            {props.labelUrl ? (
              <Anchor component="a" href={props.labelUrl} target="_blank">
                {props.label}
              </Anchor>
            ) : (
              props.label
            )}
          </React.Fragment>
        }
        mt="md"
        mb="md"
        required={props.required}
        checked={field.value}
        onChange={(e) =>
          field.onChange({
            target: {
              name: field.name,
              value: !field.value,
            },
          })
        }
        error={
          errors[field.name] && touched[field.name] ? errors[field.name] : null
        }
      />
    );
  }
  if (props.mask) {
    return (
      <Input.Wrapper
        label={props.label}
        required={props.required}
        error={
          errors[field.name] && touched[field.name] ? errors[field.name] : null
        }
      >
        <Input
          component={InputMask}
          mask={props.mask.pattern}
          placeholder={props.mask.placeholder || ""}
          value={field.value}
          onChange={(e) =>
            field.onChange({
              target: {
                name: field.name,
                value: e.target.value,
              },
            })
          }
        />
      </Input.Wrapper>
    );
  }
  if (props.select) {
    if (props.multiple) {
      return (
        <MultiSelect
          data={props.options}
          placeholder="Pick some..."
          label={props.label}
          name={field.name}
          value={field.value}
          // required={props.required}
          withAsterisk={props.required}
          searchable
          onChange={(e) =>
            field.onChange({
              target: {
                name: field.name,
                value: e,
              },
            })
          }
          error={
            errors[field.name] && touched[field.name]
              ? errors[field.name]
              : null
          }
        />
      );
    }

    return (
      <NativeSelect
        label={props.label}
        required={props.required}
        placeholder="Pick one..."
        value={field.value}
        disabled={props.disabled}
        name={field.name}
        onBlur={(e) =>
          field.onBlur({
            target: {
              name: field.name,
              value: e.target.value,
            },
          })
        }
        onChange={(e) =>
          field.onChange({
            target: {
              name: field.name,
              value: e.target.value,
            },
          })
        }
        data={[{ value: "", label: "Select..." }, ...props.options]}
        // searchable
        error={
          errors[field.name] && touched[field.name] ? errors[field.name] : null
        }
      />
    );
  }

  if (props.datepicker) {
    return (
      <Input.Wrapper
        label={props.label}
        required={props.required}
        error={
          errors[field.name] && touched[field.name] ? errors[field.name] : null
        }
      >
        <Input
          {...props.datepicker}
          type="date"
          value={field.value}
          onChange={(e) => {
            field.onChange({
              target: {
                name: field.name,
                value: e.target.value,
              },
            });
          }}
        />
      </Input.Wrapper>
    );
  }

  if (props.timepicker) {
    return (
      <Input.Wrapper
        label={props.label}
        required={props.required}
        error={
          errors[field.name] && touched[field.name] ? errors[field.name] : null
        }
      >
        <Input
          {...props.timepicker}
          type="time"
          value={field.value}
          onChange={(e) => {
            field.onChange({
              target: {
                name: field.name,
                value: e.target.value,
              },
            });
          }}
        />
      </Input.Wrapper>
    );
  }

  if (props.textarea) {
    return (
      <Textarea
        label={props.label}
        required={props.required}
        // size="lg"
        value={field.value}
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        error={
          errors[field.name] && touched[field.name] ? errors[field.name] : null
        }
      />
    );
  }

  if (props.addressLookup) {
    return (
      <React.Fragment>
        <AddressLookup
          address={field.value}
          countries={props.addressLookupCountries}
          label={props.label}
          onSelect={(e) => {
            ["address_1", "city", "state_province", "state", "zip", "country"]
              .filter((f) => (props.ignoreZip ? f !== "zip" : true))
              .forEach((f) => {
                field.onChange({ target: { name: f, value: e[f] } });
              });
          }}
          onChange={(value) => {
            field.onChange({
              target: { name: "address_1", value },
            });
          }}
          addressKeys={props.addressKeys}
          error={errors[field.name] && touched[field.name]}
          errorText={
            errors[field.name] && touched[field.name]
              ? errors[field.name]
              : null
          }
          required={props.required}
        />
      </React.Fragment>
    );
  }

  if (props.zip) {
    return (
      <TextInput
        label={props.label}
        required={props.required}
        disabled={props.disabled}
        error={
          errors[field.name] && touched[field.name] ? errors[field.name] : null
        }
        value={field.value}
        onChange={field.onChange}
        name={field.name}
        onBlur={field.onBlur}
        // size="lg"
      />
    );
    // return (
    //   <React.Fragment>
    //     <ZipLookup
    //       required={props.required}
    //       value={field.value}
    //       label={props.label}
    //       onSelect={(e) => {
    //         field.onChange({ target: { name: "zip", value: e } });
    //       }}
    //       onChange={(value) => {
    //         field.onChange({
    //           target: { name: "zip", value },
    //         });
    //       }}
    //       name={field.name}
    //       addressKeys={props.addressKeys}
    //       error={errors[field.name] && touched[field.name]}
    //       errorText={
    //         errors[field.name] && touched[field.name]
    //           ? errors[field.name]
    //           : null
    //       }
    //     />
    //   </React.Fragment>
    // );
  }

  if (props.file) {
    return (
      <FileInput
        {...props.file}
        label={props.label}
        required={props.required}
        error={
          errors[field.name] && touched[field.name] ? errors[field.name] : null
        }
        onChange={(e) => {
          if (!e)
            return field.onChange({
              target: {
                name: field.name,
                value: null,
              },
            });
          if (!["image/jpeg", "image/png"].includes(e.type)) {
            return toast.error("File type unsupported");
          }
          // if (e.size > 10000000) {
          //   field.onChange({
          //     target: {
          //       name: field.name,
          //       value: null,
          //     },
          //   });
          //   return toast.error("Exceeds max file size");
          // }
          field.onChange({
            target: {
              name: field.name,
              value: e,
            },
          });
        }}
        value={field.value}
      />
    );
  }

  return (
    <TextInput
      label={props.label}
      required={props.required}
      disabled={props.disabled}
      error={
        errors[field.name] && touched[field.name] ? errors[field.name] : null
      }
      value={field.value}
      onChange={field.onChange}
      name={field.name}
      onBlur={field.onBlur}
      // size="lg"
    />
  );
};

export default CustomInputComponent;
