import React from "react";
import { Group, Title } from "@mantine/core";

const SectionTitle = ({ title, icon }) => (
  <Group mb="md">
    <Title order={4}>{title}</Title>
    {icon}
  </Group>
);

export default SectionTitle;
