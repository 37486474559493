import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Button, ThemeIcon, Image, FileButton } from "@mantine/core";
import { IconCamera } from "@tabler/icons-react";

export default function LogoUpload({
  campaignId,
  contestId,
  locationId,
  organizationId,
  onSuccess,
  url,
}) {
  const ids = {
    campaign_id: campaignId,
    contest_id: contestId,
    location_id: locationId,
    organization_id: organizationId,
  };

  const reqData = Object.keys(ids).reduce((acc, cur) => {
    if (ids[cur]) {
      acc[cur] = ids[cur];
    }
    return acc;
  }, {});

  function onUpload(file) {
    const formData = new FormData();
    formData.append("tempfile", file);
    formData.append("variety", 4);
    Object.keys(reqData).forEach((k) => formData.append(k, reqData[k]));

    axios
      .post(`/assets/`, formData)
      .then(() => {
        toast.success("Uploaded!");
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <FileButton accept="image/*" onChange={(e) => onUpload(e)}>
      {(props) =>
        url ? (
          <img
            {...props}
            style={{
              width: "120px",
              height: "80px",
              objectFit: "contain",
            }}
            src={url || null}
          />
        ) : (
          <ThemeIcon {...props} mr="md" variant="light" size="xl" radius="xl">
            <IconCamera />
          </ThemeIcon>
        )
      }
    </FileButton>
  );
}
