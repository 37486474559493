import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Button,
  Divider,
  SegmentedControl,
  Text,
  Modal,
  Select,
} from "@mantine/core";
import { IconPlayerPlay, IconCalendarMonth } from "@tabler/icons-react";
import dayjs from "dayjs";

import { DateField, TimeField } from "@components/shared";
import timezones from "@lib/timezones";

export function Enqueue({ reqData, onSuccess, id, disabled, dequeue }) {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    axios
      .post(`/bulk-communicators/${id}/toggle-enqueue/`, reqData)
      .then(() => {
        setLoading(false);
        toast.success(dequeue ? "Removed from queue!" : "Enqueued!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      onClick={onClick}
      loading={loading}
      size="xs"
      color={dequeue ? "red" : "green"}
      disabled={disabled}
    >
      {dequeue ? "Remove from queue" : "Enqueue"}
    </Button>
  );
}

export function Schedule({ reqData, onSuccess, id, isStart, timezone }) {
  const initialFormValues = {
    start_date: "",
    start_time: "",
    timezone: "",
  };
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [method, setMethod] = useState("now");

  useEffect(() => {
    if (timezone) {
      setFormValues({
        ...formValues,
        timezone,
      });
    }
  }, [timezone]);

  function onClose() {
    setOpen(false);
    setFormValues(initialFormValues);
    setLoading(false);
    setMethod("now");
  }

  function onClick() {
    setLoading(true);
    const req = {
      ...reqData,
      ...formValues,
    };

    axios
      .post(`/bulk-communicators/${id}/startup/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Scheduled!");
        onClose();
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onStartClick() {
    setLoading(true);
    const req = {
      ...reqData,
      start_time: dayjs().add(5, "minutes").format("HH:mm"),
      start_date: dayjs().format("YYYY-MM-DD"),
    };

    axios
      .post(`/bulk-communicators/${id}/startup/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Scheduled!");
        onClose();
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <>
      <Button
        leftSection={
          isStart ? (
            <IconPlayerPlay size={16} />
          ) : (
            <IconCalendarMonth size={16} />
          )
        }
        onClick={() => setOpen(true)}
        size="xs"
        color={isStart ? "green" : "blue"}
      >
        {isStart ? "Start" : "Schedule"}
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <SegmentedControl
          mb="lg"
          size="xs"
          value={method}
          onChange={(e) => setMethod(e)}
          data={[
            { label: "Now", value: "now" },
            { label: "Later", value: "later" },
          ]}
        />
        {method === "now" ? (
          <>
            <Text>
              The next available start time is:
              <br />
              <b>{dayjs().add(5, "minutes").format("MM/DD/YYYY hh:mm A")}*</b>
            </Text>
            <Text c="dimmed" size="xs">
              or five minutes from when the Start button below is clicked
            </Text>
            <Divider mt="lg" mb="lg" />
            <Button
              mt="sm"
              onClick={() => (isStart ? onStartClick() : onClick())}
              loading={loading}
              color="green"
              fullWidth
              disabled={
                isStart
                  ? false
                  : !formValues.start_date ||
                    !formValues.start_time ||
                    !formValues.timezone
              }
            >
              Confirm start
            </Button>
          </>
        ) : (
          <>
            <Text>Schedule for the future</Text>
            <DateField
              label="Date"
              min={new Date().toISOString().split("T")[0]}
              value={formValues.start_date}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  start_date: e,
                })
              }
            />
            <TimeField
              label="Time"
              value={formValues.start_time}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  start_time: e,
                })
              }
            />
            <Select
              label="Timezone"
              data={timezones}
              value={formValues.timezone}
              placeholder="Select one..."
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  timezone: e,
                })
              }
            />
            <Button
              mt="sm"
              onClick={onClick}
              loading={loading}
              color="blue"
              fullWidth
              disabled={
                !formValues.start_date ||
                !formValues.start_time ||
                !formValues.timezone
              }
            >
              Schedule
            </Button>
          </>
        )}
      </Modal>
    </>
  );
}

export function Stop({ reqData, onSuccess, id, disabled }) {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    axios
      .post(`/bulk-communicators/${id}/stop/`, reqData)
      .then(() => {
        setLoading(false);
        toast.success("Stopped!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      onClick={onClick}
      loading={loading}
      size="xs"
      color={"red"}
      disabled={disabled}
    >
      Stop
    </Button>
  );
}
