import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Box, Card, Image, Grid, Group, Modal, Text } from "@mantine/core";
import { IconWorld } from "@tabler/icons-react";

import generateStaticMap from "@util/generateStaticMap";

import entityIcon from "@util/entityIcon";

const iconProps = {
  size: "xs",
  color: "gray",
  variant: "light",
  radius: "xl",
};

export default function UserAddresses({ userId }) {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);
    axios
      .post(`/retrieve-user-addresses/`, { user_id: userId })
      .then(({ data }) => {
        // setItems([]);
        setItems(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setItems([]);
        setLoading(false);
      });
  }

  if (!loading && items.length === 0) {
    return <Text>This user has no addresses yet.</Text>;
  }

  return (
    <React.Fragment>
      <Grid>
        {items.map((item) => (
          <Grid.Col span={{ base: 6 }} key={item.id}>
            <UserAddressItem
              data={item}
              id={item.id}
              location={item.location}
              organization={item.organization}
            />
          </Grid.Col>
        ))}
      </Grid>
    </React.Fragment>
  );
}

export const UserAddressItem = ({ id, data, location, organization }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <Card p="xs" style={{ overflow: "hidden" }}>
      <Modal opened={isOpen} onClose={onClose} title={`Interactions`}></Modal>
      {/* <Flex gap="xs" align="center">
        <ThemeIcon {...iconProps} c="dimmed">
          {data.location
            ? entityIcon.location(12)
            : entityIcon.organization(12)}
        </ThemeIcon>
        <Text fw={600}>{data.location_name || data.organization_name}</Text>
      </Flex> */}
      <Card.Section mb="sm">
        {data.latitude ? (
          <Image
            height={250}
            alt="Map"
            style={{ overflow: "hidden" }}
            src={generateStaticMap(
              {
                latitude: data.latitude,
                longitude: data.longitude,
              },
              {
                width: 500,
                height: 250,
              },
              10
            )}
          />
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "var(--mantine-color-gray-2)",
            }}
            h={250}
          >
            <IconWorld color="var(--mantine-color-gray-7)" size={64} />
          </Box>
        )}
      </Card.Section>
      <Text fw={600}>{new Date(data.created_at).toLocaleString()}</Text>
      {data.address_1 && <Text c="dimmed">{data.address_1}</Text>}
      {data.address_2 && <Text c="dimmed">{data.address_2}</Text>}
      {data.city && (
        <Group>
          {/* <ThemeIcon {...iconProps}>{entityIcon.user()}</ThemeIcon> */}
          <Text c="dimmed">
            {data.city}, {data.state_province} {data.country}
          </Text>
        </Group>
      )}
      {data.zip && <Text c="dimmed">{data.zip}</Text>}
    </Card>
  );
};
