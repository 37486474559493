import React from "react";
import { Title, Group, Anchor } from "@mantine/core";
import { Link } from "react-router-dom";

import entityIcon from "@util/entityIcon";

export default function KeywordDashboard({ keywordData }) {
  if (!keywordData) return null;
  const entityGroups = [
    { icon: entityIcon.location(), value: keywordData.location_name },
    // { icon: entityIcon.effort(), value: keywordData.campaign_effort_name },
    {
      icon: entityIcon.calendar(),
      value: `${keywordData.start_date_formatted} - ${keywordData.end_date_formatted}`,
    },
    {
      icon: entityIcon.entry(),
      value: `${keywordData.total_contest_entries_made} entries`,
    },
  ]
    .filter((f) => f.value)
    .map((m, i) => ({
      ...m,
      key: i,
    }));

  return (
    <div>
      <Title order={4}>Attributes</Title>
      {keywordData.contest_info && (
        <FormattedGroup
          icon={entityIcon.contest()}
          value={
            <Anchor
              underline="always"
              c="dark"
              component={Link}
              to={`/contests/${keywordData.contest_info.id}`}
            >
              {keywordData.contest_info.name}
            </Anchor>
          }
        />
      )}
      {keywordData.campaign_effort_name && (
        <FormattedGroup
          icon={entityIcon.effort()}
          value={
            <Anchor
              c="dark"
              underline="always"
              component={Link}
              to={`/efforts/${keywordData.campaign_effort_id}`}
            >
              {keywordData.campaign_effort_name}
            </Anchor>
          }
        />
      )}
      {entityGroups.map((m) => (
        <FormattedGroup icon={m.icon} value={m.value} key={m.key} />
      ))}
    </div>
  );
}

const FormattedGroup = ({ icon, value }) => {
  return (
    <Group spacing="xs">
      {icon} {value}
    </Group>
  );
};
