export const initialState = {
  activeTab: "general",
  song_rating_value: 5,
  song_rating_frequency_in_days: 3,
  check_ins_enabled: true,
  allow_check_in_this_location: true,
  check_in_radius: 0.075,
  value: 5,
  intervals: [],
};
