import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Flex,
  Divider,
  ActionIcon,
  Title,
  Loader,
  Group,
  Text,
  Modal,
  UnstyledButton,
  ThemeIcon,
  Anchor,
} from "@mantine/core";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";

export default function EffortCampaignManagement({
  campaignName,
  campaignId,
  effortId,
  fetchData,
}) {
  const [available, setAvailable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  useEffect(() => {
    if (!isOpen) return;
    fetchAvailable();
  }, [isOpen]);

  function fetchAvailable() {
    setLoading(true);

    axios
      .post(`/efforts/${effortId}/retrieve-available-campaigns/`, {
        campaign_effort_id: effortId,
      })
      .then(({ data }) => {
        // setAvailable(data.response.filter((f) => f.id !== campaignId));
        setAvailable(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div>
      {!campaignId ? (
        <Flex>
          <ThemeIcon
            mr="xs"
            size="sm"
            variant="light"
            color={entityColor.campaign}
          >
            {entityIcon.campaign()}
          </ThemeIcon>
          <UnstyledButton id="addCampaignButton" onClick={() => setOpen(true)}>
            <Text size="sm">add campaign</Text>
          </UnstyledButton>
        </Flex>
      ) : (
        <Flex>
          <ThemeIcon
            mr="xs"
            size="sm"
            variant="light"
            color={entityColor.campaign}
          >
            {entityIcon.campaign()}
          </ThemeIcon>
          <Text size="sm">
            <Anchor
              component={Link}
              to={`/campaigns/${campaignId}`}
              target="_blank"
              style={{
                color: "inherit",
              }}
            >
              {campaignName}
            </Anchor>
          </Text>
          <UnstyledButton ml="sm" onClick={() => setOpen(true)}>
            <Text size="sm">(click to change)</Text>
          </UnstyledButton>
        </Flex>
      )}
      <Modal opened={isOpen} onClose={onClose}>
        <Box
          style={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <List
            effortId={effortId}
            campaignId={campaignId}
            title="Pick a campaign"
            items={available}
            loading={loading}
            fetchData={() => {
              onClose();
              fetchData();
            }}
          />
        </Box>
      </Modal>
    </div>
  );
}

const List = ({ items, campaignId, title, loading, fetchData, effortId }) => {
  return (
    <Box>
      <Group mb="xs">
        <Title order={4}>{title}</Title>
        {loading && <Loader size="xs" />}
      </Group>
      {items.length === 0 && !loading && <Text>No campaigns to pick from</Text>}
      {items.map((item, i) => (
        <div key={item.id}>
          <Item
            fetchData={fetchData}
            name={item.title}
            campaignId={item.id}
            selected={campaignId === item.id}
            effortId={effortId}
          />
          {i < items.length - 1 && <Divider mt="sm" mb="sm" />}
        </div>
      ))}
    </Box>
  );
};

const Item = ({
  fetchData,
  selected,
  name,
  campaignId,
  effortId,
  onAddClick,
}) => {
  const [loading, setLoading] = useState(false);

  function onAdd() {
    if (onAddClick) return onAddClick();

    setLoading(true);

    const req = {
      campaign_effort_id: effortId,
      campaign_id: selected ? null : campaignId,
    };

    axios
      .post(`/efforts/${effortId}/add-campaign/`, req)
      .then(() => {
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Flex align="center">
        <Text
          fw={600}
          style={{
            flexGrow: 1,
          }}
        >
          {campaignId ? (
            <Anchor
              c="dark"
              component={Link}
              to={`/campaigns/${campaignId}`}
              target="_blank"
            >
              {name}
            </Anchor>
          ) : (
            name
          )}
        </Text>
        {selected ? (
          <ActionIcon
            loading={loading}
            onClick={onAdd}
            color="red"
            radius="xl"
            size="xs"
            variant="light"
          >
            {entityIcon.trash()}
          </ActionIcon>
        ) : (
          <ActionIcon loading={loading} onClick={onAdd} radius="xl" size="xs">
            {entityIcon.add()}
          </ActionIcon>
        )}
      </Flex>
    </div>
  );
};
