import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Link,
  Routes,
  Route,
  useParams,
  useLocation,
  Navigate,
} from "react-router-dom";
import InputMask from "react-input-mask";
import {
  Anchor,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Input,
  Text,
  Title,
  TextInput,
} from "@mantine/core";
import toast from "react-hot-toast";

import UserForgotPassword from "./UserForgotPassword";
import UserSignup from "./UserSignup";
import UserLoginForm from "./UserLoginForm";
import { CodeInput } from "@components/Auth";

export default function UserAuth({ locationId, codeId }) {
  const location = useLocation();

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <React.Fragment>
              <UserLoginForm locationId={locationId} />
            </React.Fragment>
          }
        />
        <Route
          path="/signup"
          element={
            <React.Fragment>
              <SignupFlow codeId={codeId} locationId={locationId} />
              <Divider mt="sm" mb="sm" />
              <Text align="center" c="dimmed" size="xs">
                You are signing up to become a member of our loyalty program. In
                doing so, you are confirming that you are at least 13 years of
                age. As a member, you may receive marketing emails from us from
                time to time. You may opt-out at any time, either by clicking
                the unsubscribe link on each email, or using the opt-out
                checkbox in your profile. You may also interact with us via
                text, just as any listener is able to do. While we may respond
                to your texts with information related to your message, you are
                not opting-in to an SMS marketing list. Should we offer that in
                the future, you will have the ability to opt-in or out at any
                time.
              </Text>
            </React.Fragment>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <React.Fragment>
              <UserForgotPassword locationId={locationId} />
            </React.Fragment>
          }
        />
      </Routes>
      <Divider mt="lg" mb="lg" />
      <Box mt="md">
        <Center>
          {(location.pathname.indexOf("signup") > -1 ||
            location.pathname.indexOf("forgot-password") > -1) && (
            <div>
              <Button
                size="xs"
                component={Link}
                to={`/user-login/${codeId}`}
                variant="subtle"
              >
                Login
              </Button>
            </div>
          )}
          {location.pathname.indexOf("signup") === -1 && (
            <div>
              <Button
                size="xs"
                component={Link}
                to={`/user-login/${codeId}/signup`}
                variant="subtle"
              >
                Signup
              </Button>
            </div>
          )}
          {location.pathname.indexOf("forgot-password") === -1 && (
            <div>
              <Button
                size="xs"
                component={Link}
                to={`/user-login/${codeId}/forgot-password`}
                variant="subtle"
              >
                Forgot Password
              </Button>
            </div>
          )}
        </Center>
      </Box>
    </React.Fragment>
  );
}

export const SignupFlow = ({
  codeId,
  email = "",
  firstName = "",
  lastName = "",
  locationId,
  locationName = "Mixer",
  migrationScenario = null,
  mobilePhone = "",
  zip = "",
}) => {
  const [userScenario, setUserScenario] = useState(null);
  const [user, setUser] = useState(null);

  const formattedLocationName = userScenario
    ? userScenario.location_name
    : locationName;

  useEffect(() => {
    if (migrationScenario) {
      setUserScenario(migrationScenario);
    }
  }, [JSON.stringify(migrationScenario)]);

  if (userScenario && !user) {
    if (
      userScenario.need_user ||
      userScenario.need_join ||
      (migrationScenario && userScenario.number) ||
      userScenario.migration ||
      userScenario.need_signup_form
    ) {
      return (
        <>
          {userScenario.migration && (
            <Text align="center" mb="lg">
              Welcome to the new {formattedLocationName}! We need to
              re-credential you to complete your record transfer. Please fill
              out the form below. If this isnt you, we could have made a mistake
              so you should{" "}
              <Anchor href="/submit-trouble-ticket" target="_blank">
                fill out a support ticket
              </Anchor>
              .
            </Text>
          )}
          <UserSignup
            email={email || userScenario.email}
            firstName={firstName || userScenario.first_name}
            lastName={lastName || userScenario.last_name}
            locationId={locationId}
            number={mobilePhone || userScenario.number}
            userLocationId={userScenario.user_location_id}
            onSuccess={(e) => setUser(e)}
            zip={zip || userScenario.zip}
          />
        </>
      );
    }

    if (userScenario.need_email_verified) {
      return (
        <div>
          <ResendLink
            reqData={{
              mobile_phone: userScenario.number,
              user_location_id: userScenario.user_location_id,
            }}
          />
        </div>
      );
    }

    if (
      !Object.keys(userScenario).includes(true) &&
      !userScenario.migration &&
      userScenario.user_location_id
    ) {
      return <Navigate replace to={`/user-login/${codeId}`} />;
    }
  }

  if (user) {
    return (
      <div>
        <ResendLink
          reqData={{
            mobile_phone: userScenario.number,
            user_location_id: user.user_location_id,
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <PhoneCheck
        mobilePhone={mobilePhone}
        locationId={locationId}
        setUserScenario={(e) => {
          setUserScenario(e);
        }}
      />
    </div>
  );
};

export const PhoneCheck = ({
  locationId,
  mobilePhone = "",
  setUserScenario,
}) => {
  const [phoneValue, setPhoneValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [remoteCode, setRemoteCode] = useState(null);
  const [codeValue, setCodeValue] = useState("");

  useEffect(() => {
    if (mobilePhone) {
      setPhoneValue(mobilePhone.replace(/\D/g, ""));
    }
  }, [mobilePhone]);

  useEffect(() => {
    if (codeValue.length === 6) {
      verifyCode();
    }
  }, [codeValue]);

  function onSubmit() {
    setLoading(true);

    const req = {
      mobile_phone: phoneValue.replace(/\D/g, ""),
      location_id: locationId,
    };

    axios
      .post(`/verifications/phone/`, req)
      .then(({ data }) => {
        setLoading(false);
        setRemoteCode(data.response[0]);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function verifyCode() {
    const req = {
      text_code: codeValue,
      remote_code_id: remoteCode.remote_code_id,
      location_id: locationId,
      user_signup: true,
    };

    axios
      .post(`/verifications/phone/check/`, req)
      .then(({ data }) => {
        //
        setUserScenario({
          ...data.response[0],
          number: phoneValue.replace(/\D/g, ""),
        });
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  if (remoteCode) {
    return (
      <div>
        <Title order={2} align="center" mb="sm">
          Enter code
        </Title>
        <CodeInput value={codeValue} onChange={(e) => setCodeValue(e)} />
        <Flex justify="center">
          <Button
            mt="sm"
            variant="subtle"
            size="xs"
            onClick={() => {
              setRemoteCode(null);
            }}
          >
            Didn't receive code?
          </Button>
        </Flex>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Title order={2} align="center" mb="sm">
        Enter phone
      </Title>
      <Input.Wrapper>
        <Input
          size="lg"
          component={InputMask}
          placeholder="(XXX) XXX-XXXX"
          mask="(999) 999-9999"
          onChange={(e) => {
            setPhoneValue(e.target.value);
          }}
          value={phoneValue}
        />
      </Input.Wrapper>
      <Button
        fullWidth
        mt="sm"
        disabled={!phoneValue || phoneValue.length !== 14}
        loading={loading}
        onClick={onSubmit}
      >
        Submit
      </Button>
    </React.Fragment>
  );
};

export const ResendLink = ({ reqData }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function onSubmit() {
    setLoading(true);

    const req = { ...reqData };

    axios
      .post(`/members/resend-signup-email-link/`, req)
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  if (success) {
    return (
      <React.Fragment>
        <Title align="center" order={3}>
          Email sent
        </Title>
        <Text align="center" mb="lg">
          Check your inbox for instructions
        </Text>
      </React.Fragment>
    );
  }

  return (
    <div>
      <Title align="center" order={3}>
        One last thing...
      </Title>
      <Text align="center" mb="lg">
        We need you to finish the signup process to complete your registration.
        Check your email.
      </Text>
      <Flex justify="center">
        <Button loading={loading} onClick={onSubmit} variant="subtle">
          Resend email
        </Button>
      </Flex>
    </div>
  );
};
