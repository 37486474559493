import React, { useState, useEffect } from "react";
import {
  IconDots,
  IconTrash,
  IconKeyboard,
  IconPlus,
} from "@tabler/icons-react";
import {
  ActionIcon,
  Anchor,
  Button,
  Group,
  Menu,
  Modal,
  Table,
  TextInput,
  Title,
} from "@mantine/core";

import { getUuid } from "@util/getUuid";

export default function EntityConfigLinks({ links = [], onUpdate }) {
  const [isOpen, setOpen] = useState(false);

  function onLinkUpdate(newLink) {
    let link = [...links].find((f) => f.id === newLink.id);
    if (!link) return;

    link.text = newLink.text;
    link.url = newLink.url;
    onUpdate(links);
  }

  return (
    <div>
      <Group>
        <Title order={4}>Info Modal Links</Title>
        <Button
          variant="light"
          color="green"
          size="xs"
          leftSection={<IconPlus />}
          onClick={() => setOpen(true)}
        >
          Add
        </Button>
      </Group>
      <LinkModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onSubmit={(e) => {
          onUpdate([...links, e]);
          setOpen(false);
        }}
      />
      <Table striped highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Text</Table.Th>
            <Table.Th>URL</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {links.length === 0 && (
            <Table.Tr>
              <Table.Td colSpan={3}>No items</Table.Td>
            </Table.Tr>
          )}
          {links.map((link) => (
            <LinkItem
              key={link.id}
              url={link.url}
              id={link.id}
              text={link.text}
              onRemoveClick={() =>
                onUpdate([...links].filter((f) => f.id !== link.id))
              }
              onUpdate={onLinkUpdate}
            />
          ))}
        </Table.Tbody>
      </Table>
    </div>
  );
}

const LinkModal = ({
  text = "",
  url = "",
  isOpen,
  onClose,
  id = null,
  onSubmit,
}) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={id ? "Update Link" : "Create Link"}
    >
      <LinkForm id={id} text={text} url={url} onSubmit={onSubmit} />
    </Modal>
  );
};

const LinkItem = ({ id, url, text, onRemoveClick, onUpdate }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <React.Fragment>
      <LinkModal
        isOpen={isOpen}
        onClose={(e) => setOpen(false)}
        id={id}
        text={text}
        url={url}
        onSubmit={(e) => {
          onUpdate(e);
          setOpen(false);
        }}
      />
      <Table.Tr>
        <Table.Td>{text}</Table.Td>
        <Table.Td>
          <Anchor href={url} target="_blank">
            {url}
          </Anchor>
        </Table.Td>
        <Table.Td>
          <Menu>
            <Menu.Target>
              <ActionIcon variant="subtle" size="sm" color="gray">
                <IconDots />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                component="button"
                onClick={() => setOpen(true)}
                icon={<IconKeyboard />}
              >
                Rename
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                component="button"
                onClick={onRemoveClick}
                icon={<IconTrash />}
              >
                Remove
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Table.Td>
      </Table.Tr>
    </React.Fragment>
  );
};

const LinkForm = ({ text = "", url = "", id, onSubmit }) => {
  const [formValues, setFormValues] = useState({
    text,
    url,
  });

  useEffect(() => {
    setFormValues({
      text,
      url,
    });
  }, []);

  return (
    <React.Fragment>
      <TextInput
        value={formValues.text}
        onChange={(e) => setFormValues({ ...formValues, text: e.target.value })}
        label="Text"
      />
      <TextInput
        value={formValues.url}
        onChange={(e) => setFormValues({ ...formValues, url: e.target.value })}
        label="URL"
      />
      {formValues.url && (
        <Anchor href={formValues.url} target="_blank" mt="lg">
          Test Link
        </Anchor>
      )}
      <Button
        fullWidth
        color="green"
        variant="light"
        mt="lg"
        disabled={!formValues.text || !formValues.url}
        onClick={() => {
          onSubmit({
            ...formValues,
            id: id || getUuid(),
          });
        }}
      >
        {id ? "Update" : "Create"}
      </Button>
    </React.Fragment>
  );
};
