export const initialState = {
  // activeTab: "general",
  // contestDates: {
  //   begin: null,
  //   end: null,
  // },
  // keywords: {
  //   intervals: [],
  //   keyword_use_total: 0,
  //   keyword_value: 0,
  //   total_entries_allowed: 0,
  //   tag_rules: [],
  // },
  // initial_entry: {
  //   entry_count: 0,
  // },
  // total_entries_allowed: 0,
  // check_ins: {
  //   intervals: [],
  //   check_in_value: 50,
  // },
  // winner_rules: {
  //   one_prize_per_winner: true,
  // },
  member_rebound_failure_message:
    "Hmm, that didn't work. Did you already redeem this keyword?",
  member_rebound_message: "You've earned points!",
  non_member_rebound_failure_message:
    "Hmm, that didn't work. Did you already redeem this keyword?",
  non_member_rebound_message:
    "You've earned points! Become a member and put your points to work!",
};

export function generateEffortRebounds(effortName) {
  return {
    member_rebound_message: `Thanks for participating in ${effortName}!`,
    non_member_rebound_message: `Thanks for participating in ${effortName}! Become a member and put your points to work!`,
  };
}
