import React, { useContext, useState, useEffect } from "react";
import { Group, Divider, TextInput } from "@mantine/core";

import { Counter } from "@components/shared";
import { Context } from "./";

export default function GeneralSettings({ onUpdate }) {
  const {
    total_entries_allowed: totalEntriesAllowed,
    member_rebound_failure_message: memberReboundFailureMessage,
    member_rebound_message: memberReboundMessage,
    non_member_rebound_failure_message: nonMemberReboundFailureMessage,
    non_member_rebound_message: nonMemberReboundMessage,
  } = useContext(Context);

  const [tempSettings, setTempSettings] = useState({
    member_rebound_failure_message: memberReboundFailureMessage,
    member_rebound_message: memberReboundMessage,
    non_member_rebound_failure_message: nonMemberReboundFailureMessage,
    non_member_rebound_message: nonMemberReboundMessage,
  });

  useEffect(() => {
    setTempSettings({
      member_rebound_failure_message: memberReboundFailureMessage,
      member_rebound_message: memberReboundMessage,
      non_member_rebound_failure_message: nonMemberReboundFailureMessage,
      non_member_rebound_message: nonMemberReboundMessage,
    });
  }, [
    memberReboundFailureMessage,
    memberReboundMessage,
    nonMemberReboundMessage,
    nonMemberReboundMessage,
  ]);

  const textFields = [
    {
      name: "member_rebound_message",
      value: memberReboundMessage,
      label: "Member Rebound Message",
    },
    {
      name: "non_member_rebound_message",
      value: nonMemberReboundMessage,
      label: "Non-Member Rebound Message",
    },
    // {
    //   name: "member_rebound_failure_message",
    //   value: memberReboundFailureMessage,
    //   label: "Member Rebound Failure Message",
    // },
    // {
    //   name: "non_member_rebound_failure_message",
    //   value: nonMemberReboundFailureMessage,
    //   label: "Non-Member Rebound Failure Message",
    // },
  ];

  const counterFields = [
    {
      title: "Total Entries Allowed",
      keyName: "total_entries_allowed",
      value: totalEntriesAllowed,
    },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
  }));

  return (
    <div>
      {/* {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            value={c.value}
            onChange={(e) =>
              onUpdate({
                [c.keyName]: e,
              })
            }
          />
          <h3 style={{ margin: "0" }}>{c.title}</h3>
        </Group>
      ))} */}
      {/* <Divider mt="lg" mb="lg" /> */}
      {textFields.map((m, i) => (
        <TextInput
          key={i}
          label={m.label}
          value={tempSettings[m.name]}
          onChange={(e) => {
            if (e.target.value.length > 160) return;
            // onUpdate({ [m.name]: e.target.value });
            setTempSettings({ ...tempSettings, [m.name]: e.target.value });
          }}
          onBlur={(e) => onUpdate({ [m.name]: e.target.value })}
          max={160}
        />
      ))}
    </div>
  );
}
