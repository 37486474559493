import React, { useState, useEffect } from "react";
import { Button, Box, Divider, Flex, Text } from "@mantine/core";
import useAppStore from "./report-recipe-runner-store";
import { shallow } from "zustand/shallow";
import axios from "axios";
import toast from "react-hot-toast";
import { IconTrash } from "@tabler/icons-react";

import { ShareReportSnapshot } from "@components/ReportRecipe";
import ReportRecipeVisualizer from "./ReportRecipeVisualizer";

export default function SnapshotView({ fetchSnapshots }) {
  const {
    queryData,
    recipeInfo,
    reportData,
    reset,
    snapshot,
    setError,
    setQueryData,
    setReportData,
    setSnapshot,
    savedRecipe,
    setStatBlocks,
    setTableConfig,
  } = useAppStore(
    (state) => ({
      error: state.error,
      queryData: state.query_data,
      recipeInfo: state.recipe_info,
      reportData: state.report_data,
      reset: state.reset,
      savedRecipe: state.saved_recipe,
      snapshot: state.snapshot,
      setError: state.setError,
      setQueryData: state.setQueryData,
      setReportData: state.setReportData,
      setSavedRecipe: state.setSavedRecipe,
      setSnapshot: state.setSnapshot,
      setStatBlocks: state.setStatBlocks,
      setTableConfig: state.setTableConfig,
    }),
    shallow
  );

  const startValue = reportData ? reportData.start_value : null;
  const startDate = startValue ? new Date(startValue) : null;

  useEffect(() => {
    if (snapshot) {
      fetchSnapshotData();
    }
  }, [JSON.stringify(snapshot)]);

  function fetchSnapshotData() {
    if (!snapshot) return;

    const req = {
      visualizers: [],
      report_snapshot_id: snapshot.id,
      snapshot,
    };

    axios
      .post(`/report-snapshots/${snapshot.id}/run-report/`, req)
      .then(({ data }) => {
        const res = data.response[0];
        if (res.response_code) return;
        setQueryData({
          ...queryData,
          visualizer: res.visualizer || queryData.visualizer,
        });
        setReportData(data.response[0].chart_data);
        // console.log(res);
        if (res.meta_block_data?.length) {
          setStatBlocks(res.meta_block_data);
        } else {
          setStatBlocks([]);
        }
        if (res.table_config) {
          setTableConfig(res.table_config);
        }
      })
      .catch((err) => {
        setError(err);
        setReportData(null);
      });
  }

  return (
    <div>
      <Box>
        <Flex gap="xs" align="center">
          {/* <IconCamera size={24} /> */}
          <Text>
            <b>Snapshot:</b> {snapshot.title}
          </Text>
          <Button
            size="xs"
            variant="subtle"
            color="gray"
            p={0}
            onClick={() => {
              setSnapshot(null);
              setReportData(null);
              setError(null);
            }}
          >
            clear
          </Button>
        </Flex>
      </Box>
      {reportData && (
        <>
          <Divider mt="sm" mb="sm" />
          <ReportRecipeVisualizer />
        </>
      )}
      <Divider mt="lg" mb="lg" />
      {recipeInfo.shareable && (
        <>
          <ShareReportSnapshot
            reqData={{
              report_snapshot_id: snapshot.id,
            }}
          />
          <Divider mt="lg" mb="lg" />
        </>
      )}
      <DeleteSnapshotButton
        onSuccess={() => {
          setSnapshot(null);
          setReportData(null);
          setError(null);
          fetchSnapshots();
        }}
        snapshotId={snapshot.id}
      />
    </div>
  );
}

const DeleteSnapshotButton = ({ snapshotId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    axios
      .delete(`/report-snapshots/${snapshotId}/`)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      leftSection={<IconTrash size={16} />}
      color="gray"
      variant="subtle"
      size="xs"
      loading={loading}
      onClick={onClick}
    >
      Delete snapshot
    </Button>
  );
};
