import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Title } from "@mantine/core";

import { FilterButtons, ManagementLayout } from "@components/shared";
import { TagList, TagForm, TagSearch } from "./";

export default function TagManagement({
  contestId,
  keywordId,
  libraryAssetId,
}) {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeVariety, setActiveVariety] = useState(null);
  const [varieties, setVarieties] = useState([]);

  useEffect(() => {
    fetchVarieties();
  }, []);

  useEffect(() => {
    fetchTags();
  }, []);

  function fetchVarieties() {
    axios
      .get(`/retrieve-tag-varieties/`)
      .then(({ data }) => {
        setVarieties(data.response);
      })
      .catch((err) => {
        setVarieties([]);
      });
  }

  function fetchTags() {
    const req = {};

    setLoading(true);

    if (contestId) req.contest_id = contestId;
    if (keywordId) req.keyword_id = keywordId;
    if (libraryAssetId) req.library_asset_id = libraryAssetId;

    axios
      .post(`/retrieve-entity-tags/`, req)
      .then(({ data }) => {
        setTags(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setTags([]);
        setLoading(false);
      });
  }

  return (
    <ManagementLayout
      dataComponent={
        <div>
          {/* <div style={{ marginBottom: "1em" }}>
            <FilterButtons
              activeValue={activeVariety}
              onClick={(e) => setActiveVariety(e === activeVariety ? null : e)}
              items={varieties.map((v) => ({
                value: v.value,
                text: v.text,
              }))}
              title="Filter by"
            />
          </div> */}
          <TagList
            items={tags.filter((f) =>
              !activeVariety ? true : f.tag.variety === activeVariety
            )}
            fetchData={fetchTags}
          />
        </div>
      }
      createComponent={
        <Card>
          <TagSearch
            disabledIds={tags.map((t) => t.tag.id)}
            keywordId={keywordId}
            contestId={contestId}
            onSuccess={fetchTags}
            libraryAssetId={libraryAssetId}
          />
        </Card>
      }
    />
  );
}
