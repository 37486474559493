import React from "react";
import { Box, Badge, Flex, Card, Text, Group } from "@mantine/core";
import { Link } from "react-router-dom";

import { CurateButton, CurationTitleizeButton } from "./";
import { formatArtistList } from "./helpers";
import entityIcon from "@util/entityIcon";

export default function CurationItem({ id, rawData, fetchData }) {
  const reqData = {};
  if (rawData.artist) reqData.artist_ids = [id];
  if (rawData.song) reqData.song_ids = [id];

  const curatedArtists = rawData.artists.map((m) => m.curate);
  const canCurate = !curatedArtists.includes(true);

  return (
    <Card>
      <Flex align="center">
        {/* <div>{rawData.song ? entityIcon.song(36) : entityIcon.artist(36)}</div> */}
        <Box style={{ flexGrow: 1 }}>
          <Text
            fw={600}
            component={Link}
            to={
              rawData.song ? `/curation-song/${id}` : `/curation-artist/${id}`
            }
          >
            {rawData.name}
          </Text>
          {rawData.artists && rawData.artists.length > 0 && (
            <div>
              {rawData.artists.map((artist, i) => (
                <div>
                  <Group gap="xs" key={i}>
                    {/* <div>{entityIcon.artist(16)}</div> */}
                    <Text size="sm">
                      {artist.name} ({artist.song_count} song
                      {artist.song_count === 1 ? "" : "s"})
                    </Text>
                    {!artist.is_curated && (
                      <Badge size="xs" color="red">
                        Uncurated
                      </Badge>
                    )}
                  </Group>
                </div>
              ))}
            </div>
          )}
          <Text size="sm">
            {rawData.plays_count} play
            {rawData.plays_count === 1 ? "" : "s"}
          </Text>
          <Text size="sm">
            {rawData.ratings_count} rating
            {rawData.ratings_count === 1 ? "" : "s"}
          </Text>
          {rawData.popular_song && (
            <Text size="sm">
              {rawData.popular_song.name} played {rawData.popular_song.plays}{" "}
              time
              {rawData.popular_song.plays === 1 ? "" : "s"}
            </Text>
          )}
        </Box>
        {canCurate && (
          <CurateButton
            buttonProps={{
              mt: "0",
              mr: "xs",
            }}
            reqData={reqData}
            onSuccess={fetchData}
          />
        )}
        <CurationTitleizeButton
          buttonProps={{
            size: "sm",
            color: "gray",
            variant: "light",
          }}
          reqData={{
            artist_id: rawData.artist ? id : null,
            song_id: rawData.song ? id : null,
          }}
          onSuccess={fetchData}
          id={rawData.id}
          value={rawData.name}
        />
      </Flex>
    </Card>
  );
}
