import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function BarChart({
  data = [],
  title,
  categories = [],
  chartType = "bar",
  legendEnabled = false,
}) {
  const [options, setOptions] = useState({
    chart: {
      type: chartType,
      style: {
        fontFamily: "inherit",
      },
    },
    title: {
      text: title,
      align: "left",
    },
    xAxis: {
      categories: categories,
      title: {
        text: null,
      },
      gridLineWidth: 1,
      lineWidth: 0,
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      labels: {
        overflow: "justify",
      },
      gridLineWidth: 0,
    },
    tooltip: {
      valueSuffix: "",
    },
    plotOptions: {
      bar: {
        borderRadius: "50%",
        dataLabels: {
          enabled: true,
        },
        groupPadding: 0.1,
      },
    },
    legend: {
      enabled: legendEnabled,
    },
    credits: {
      enabled: false,
    },
    series: data,
  });

  useEffect(() => {
    setOptions({
      chart: {
        type: chartType,
        style: {
          fontFamily: "inherit",
          backgroundColor: "#000",
        },
      },
      title: {
        text: title,
        align: "left",
      },
      xAxis: {
        categories: categories,
        title: {
          text: null,
        },
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          overflow: "justify",
        },
        gridLineWidth: 0,
      },
      tooltip: {
        valueSuffix: "",
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      legend: {
        enabled: legendEnabled,
      },
      credits: {
        enabled: false,
      },
      series: data,
    });
  }, [
    JSON.stringify(categories),
    JSON.stringify(data),
    chartType,
    legendEnabled,
  ]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
