import React from "react";
import { Alert, Divider, Text, Flex } from "@mantine/core";
import styled from "styled-components";

import { VisualizerDisplay } from "@components/ReportRecipe/ReportRecipeRunner/ReportRecipeVisualizer";

export default function SharedReportView({ codeData }) {
  return (
    <>
      <div
        style={{ background: "var(--mantine-color-dark-8)", padding: "15px" }}
      >
        <Flex
          direction={{
            base: "column",
            md: "row",
          }}
          gap="sm"
          align="center"
        >
          {codeData.logo_url && (
            <StyledLogo src={codeData.logo_url} alt="Logo" />
          )}
          <div>
            <Text size="sm" c="gray">
              Snapshot from {codeData.snapshot_title}
            </Text>
          </div>
        </Flex>
      </div>
      <Wrapper>
        <Alert
          mb="lg"
          title={`Hey ${codeData.shared_report_data.recipient_name}! 👋`}
        >
          <Text>
            {codeData.shared_report_data.sender_name} from{" "}
            {codeData.shared_report_data.sender_business_name} shared this
            custom report with you. Here's what they have to say:
          </Text>
          <Text mt="xs" fs="italic">
            {codeData.shared_report_data.additional_info}
          </Text>
        </Alert>
        <VisualizerDisplay
          visualizer={codeData.visualizer}
          title={codeData.report_title}
          reportData={codeData.snapshot_data}
          sharedReport
        />
        <Divider mt="lg" mb="lg" />
        <Text align="center" size="xs" c="dimmed">
          {new Date().getFullYear()} Mixer. All rights reserved.
        </Text>
      </Wrapper>
    </>
  );
}

const StyledLogo = styled.img`
  width: 125px;
`;

const Wrapper = styled.div`
  padding: 20px;
`;
