import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Space,
  ThemeIcon,
  Title,
  Text,
  Group,
  Flex,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { IconCheck } from "@tabler/icons-react";
import { LocationSelect } from "@components/Location";

import { initialState as initialContestSettings } from "@components/Contest/ContestSettings/helpers";
import {
  ContestAssets,
  ContestForm,
  ContestKeywordEffortAssociate,
  ContestWizardKeyword,
} from "@components/Contest";
import { Counter, CustomTooltip } from "@components/shared";
import { PrizeManagement } from "@components/Prize";
import { EffortAssets } from "@components/Effort";

const settingsArr = [
  {
    title: "Contest Keyword Value",
    titleTooltip:
      "How many entries is each keyword worth for this contest? If Value is set to 1, each keyword successfully redeemed will grant the user 1 entry for this contest. This setting applies to every keyword attached to this contest.",
    trigger: "contest_keyword_value_required_status",
    key_name: "keyword_value",
  },
  {
    title: "Contest Keyword Total Entries Allowed",
    titleTooltip:
      "How many total entries can a user earn by redeeming keywords? If this number is set to 10, a user can earn up to 10 total entries by redeeming keywords.",
    trigger: "contest_keyword_entries_allowed_required_status",
    key_name: "total_entries_allowed",
  },
  {
    title: "Contest Keyword Use Total",
    titleTooltip:
      "How many times can any given customer enter a specific keyword for this contest? If your keyword is 'Torch' and Use Total is set to 1, every customer can enter Torch once for this contest. This setting applies to every keyword attached to this contest.",
    trigger: "contest_keyword_use_total_required_status",
    key_name: "keyword_use_total",
  },
  {
    title: "Initial Entry",
    text: "This is the number of entries a user gets simply for registering.",
    trigger: "initial_entry_setting_required_status",
  },
  {
    title: "Total Entries",
    text: "How many total entries are allowed?",
    trigger: "total_entries_allowed_required_status",
  },
  {
    title: "Check-In",
    text: "How much to award on a check-in?",
    trigger: "check_in_value_required_status",
  },
  // {
  //   title: "Keywords",
  //   text: "Manage keywords for this contest",
  //   trigger: "keyword_required_status",
  // },
  {
    title: "Featured Image",
    text: "This is the cover image and thumbnail",
    trigger: "featured_image_required_status",
    auction: true,
    prize_pool: true,
  },
  {
    title: "Prize",
    text: "Add loot item(s) to the prize",
    trigger: "prize_required_status",
    auction: true,
    prize_pool: true,
  },
  {
    title: "Min starting bid",
    // text: "Supplemental text",
    trigger: "min_starting_bid_required_status",
    auction: true,
  },
  {
    title: "Max bid increment",
    // text: "Supplemental text",
    trigger: "max_bid_increment_required_status",
    auction: true,
  },
  {
    title: "Entry Cost",
    text: "How many points does a single entry cost the user?",
    trigger: "entry_cost_required_status",
    prize_pool: true,
  },
  {
    title: "Locations",
    text: "Add your first location. You can add more later.",
    trigger: "locations_required_status",
    key_name: "locations_required",
    auction: true,
    prize_pool: true,
  },
];

const Wrapper = ({ children, minimal }) => {
  if (minimal) {
    return (
      <Box
        mb="sm"
        style={{
          borderBottom: "1px solid var(--mantine-color-gray-3)",
          paddingBottom: "25px",
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Card
      mb="sm"
      style={{
        overflow: "visible",
      }}
    >
      {children}
    </Card>
  );
};

export default function ContestWizardStatus({
  contestDates = null,
  effortId,
  fetchData,
  id,
  isAuction = false,
  isForDuplication = false,
  isPrizePool = false,
  locationId,
  minimal,
  organizationId,
  prize,
  status = {},
  wizardComplete = false,
  wizardSettings,
  wizardStatus,
}) {
  const [settings, setSettings] = useState(initialContestSettings);
  const [searchParams] = useSearchParams();
  const triggeredSettings = settingsArr
    .filter((f) =>
      wizardComplete
        ? ![
            "check_in_value_required_status",
            "contest_keyword_entries_allowed_required_status",
            "contest_keyword_value_required_status",
            "contest_keyword_use_total_required_status",
            "entry_cost_required_status",
            "featured_image_required_status",
            "initial_entry_setting_required_status",
            "locations_required_status",
            "max_bid_increment_required_status",
            "min_starting_bid_required_status",
            "prize_required_status",
            "total_entries_allowed_required_status",
          ].includes(f.trigger)
        : Object.keys(wizardStatus).includes(f.trigger)
    )
    .filter((f) => (isAuction ? f.auction : isPrizePool ? f.prize_pool : true))
    .filter((f) =>
      wizardComplete
        ? wizardSettings.allow_keyword === false
          ? false
          : true
        : wizardSettings.keyword_registration
        ? true
        : f.trigger !== "keyword_required_status"
    );
  // .filter((f) =>
  //   f.trigger === "keyword_required_status" &&
  //   wizardSettings.allow_keyword === false &&
  //   wizardSettings.keyword_required === false
  //     ? false
  //     : true
  // );
  // .filter((f) =>
  //   wizardSettings.allow_keyword !== true
  //     ? f.trigger !== "keyword_required_status"
  //     : true
  // );

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    axios
      .get(`/contests/${id}/settings/`)
      .then(({ data }) => {
        setSettings({ ...settings, ...data.response[0].settings });
      })
      .catch((err) => {
        setSettings({});
      });
  }

  const StatusChange = () => (
    <StatusItem
      title="Status"
      text={`Set the status of the ${isAuction ? "auction" : "contest"}`}
      trigger="status"
      contestId={id}
      onSuccess={fetchData}
      status={status}
      allowStatusToggle={wizardStatus.allow_status_toggle}
      wizardSettings={wizardSettings}
      effortId={effortId}
    />
  );

  if (minimal && wizardComplete) {
    return (
      <Wrapper minimal={minimal}>
        <StatusChange />
      </Wrapper>
    );
  }

  return (
    <div>
      {!wizardComplete && !minimal && (
        <React.Fragment>
          <Title order={3}>Finish setup</Title>
          <Text mb="sm">
            To gain full access, please finish the list of tasks below:
          </Text>
        </React.Fragment>
      )}
      {wizardComplete && (
        <React.Fragment>
          <Wrapper minimal={minimal}>
            <StatusChange />
          </Wrapper>
          {wizardSettings.keyword_registration &&
            wizardSettings.allow_keyword === false && (
              <React.Fragment>
                <Wrapper minimal={minimal}>
                  <ContestWizardKeyword
                    contestDates={contestDates}
                    contestId={id}
                    locationId={locationId}
                    organizationId={organizationId}
                    fetchData={() => {
                      fetchData();
                      fetchSettings();
                    }}
                    showForm={false}
                  />
                </Wrapper>
              </React.Fragment>
            )}
        </React.Fragment>
      )}
      {!wizardComplete && (
        <React.Fragment>
          <Wrapper minimal={minimal}>
            <StatusItem
              title={`${
                isAuction ? "Auction" : isPrizePool ? "Prize Pool" : "Contest"
              } Dates`}
              text={`When does this ${
                isAuction ? "auction" : isPrizePool ? "prize pool" : "contest"
              } start and end?`}
              trigger="contest_dates"
              onSuccess={() => {
                fetchData();
              }}
              contestId={id}
              complete={contestDates ? true : false}
              effortId={effortId}
            />
          </Wrapper>
          {wizardSettings.keyword_required && (
            <Wrapper minimal={minimal}>
              <StatusItem
                title="Keyword"
                // text="Enter Keyword"
                complete={wizardStatus.keyword_required_status}
                trigger="keyword_required"
                onSuccess={() => fetchData()}
                contestId={id}
                locationId={locationId}
                organizationId={organizationId}
                effortId={effortId || searchParams.get("effortId")}
              />
            </Wrapper>
          )}
        </React.Fragment>
      )}
      {triggeredSettings.map((m, i) => (
        <Wrapper key={i} minimal={minimal}>
          <StatusItem
            contestDates={contestDates}
            effortId={effortId}
            title={m.title}
            text={m.text}
            trigger={m.trigger}
            titleTooltip={m.titleTooltip}
            locationId={locationId}
            organizationId={organizationId}
            isForDuplication={isForDuplication}
            minimal={minimal}
            onSuccess={() => {
              fetchData();
              fetchSettings();
            }}
            settings={settings}
            contestId={id}
            prize={prize}
            wizardComplete={wizardComplete}
            complete={wizardComplete ? false : wizardStatus[m.trigger] === true}
            wizardSettings={wizardSettings}
          />
        </Wrapper>
      ))}
    </div>
  );
}

const initialFormValues = {
  value: 0,
  end_date: "",
  end_time: "",
  start_date: "",
  start_time: "",
};

const StatusItem = ({
  allowStatusToggle = false,
  complete = false,
  contestId,
  contestDates,
  effortId,
  isForDuplication,
  locationId,
  minimal,
  onSuccess,
  organizationId,
  prize,
  settings,
  status,
  text,
  title,
  titleTooltip,
  trigger,
  wizardComplete = false,
  wizardSettings,
}) => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);

  function onSettingsSubmit(newSettings) {
    setLoading(true);

    const req = {
      contest_id: contestId,
      settings: newSettings,
    };

    axios
      .post(`/contests/${contestId}/settings/`, req)
      .then(() => {
        setFormValues(initialFormValues);
        toast.success("Saved!");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onStatusClick(newStatus) {
    const req = {
      id: contestId,
      status: newStatus,
    };

    setLoading(true);

    axios
      .post(`/contests/${contestId}/set-status/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Status changed!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onPrizeSettingsSubmit(newSettings) {
    setLoading(true);

    const req = {
      prize_id: prize.id,
      settings: newSettings,
    };

    axios
      .put(`/prizes/${prize.id}/settings/`, req)
      .then(() => {
        setFormValues(initialFormValues);
        toast.success("Saved!");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onLocationSubmit() {
    const req = {
      location_id: formValues.value,
      campaign_effort_id: effortId,
    };

    setLoading(true);

    axios
      .post(`/locations/${formValues.value}/associate/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        toast.success("Location added!");
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <div>
      <Flex align="center" gap="sm">
        {titleTooltip ? (
          <CustomTooltip label={titleTooltip}>
            <Text fw={600}>{title}</Text>
          </CustomTooltip>
        ) : (
          <Text fw={600}>{title}</Text>
        )}
        {complete && (
          <ThemeIcon color="green" size="sm" radius="xl" variant="light">
            <IconCheck />
          </ThemeIcon>
        )}
      </Flex>
      {text && <Text>{text}</Text>}
      {!complete && (
        <React.Fragment>
          {trigger === "contest_dates" && (
            <React.Fragment>
              <ContestForm
                id={contestId}
                wizardSetup
                onSuccess={() => {
                  onSuccess();
                }}
              />
            </React.Fragment>
          )}
          {trigger === "locations_required_status" && effortId && (
            <React.Fragment>
              <Space mt="sm" />
              <LocationSelect
                label=""
                value={formValues.value}
                reqData={{
                  campaign_effort_id: effortId,
                  contest_id: contestId,
                  context: "contest_setup",
                }}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    value: e,
                  })
                }
              />
              <Button
                mt="sm"
                fullWidth
                disabled={!formValues.value}
                loading={loading}
                onClick={onLocationSubmit}
              >
                Submit
              </Button>
            </React.Fragment>
          )}
          {[
            "min_starting_bid_required_status",
            "max_bid_increment_required_status",
            "entry_cost_required_status",
          ].includes(trigger) && (
            <React.Fragment>
              <Counter
                value={formValues.value}
                unlimited={false}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    value: e,
                  })
                }
              />
              <Button
                disabled={formValues.value === 0}
                mt="sm"
                loading={loading}
                onClick={() => {
                  onPrizeSettingsSubmit(
                    makeNewSettingsHash(
                      prize.settings,
                      trigger,
                      formValues.value
                    )
                  );
                }}
              >
                Save
              </Button>
            </React.Fragment>
          )}
          {[
            "check_in_value_required_status",
            "contest_keyword_value_required_status",
            "contest_keyword_entries_allowed_required_status",
            "contest_keyword_use_total_required_status",
            "initial_entry_setting_required_status",
            "total_entries_allowed_required_status",
          ].includes(trigger) && (
            <React.Fragment>
              <Counter
                value={formValues.value}
                maxValue={
                  trigger === "check_in_value_required_status" ? 70 : null
                }
                unlimited={
                  [
                    "total_entries_allowed_required_status",
                    "contest_keyword_entries_allowed_required_status",
                    "contest_keyword_use_total_required_status",
                  ].includes(trigger)
                    ? true
                    : false
                }
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    value: e,
                  })
                }
              />
              <Button
                disabled={formValues.value === 0}
                mt="sm"
                loading={loading}
                onClick={() => {
                  onSettingsSubmit(
                    makeNewSettingsHash(settings, trigger, formValues.value)
                  );
                }}
              >
                Save
              </Button>
            </React.Fragment>
          )}
          {trigger === "keyword_required" && (
            <>
              <ContestKeywordEffortAssociate
                contestId={contestId}
                locationId={locationId}
                organizationId={organizationId}
                onSuccess={onSuccess}
              />
            </>
          )}
          {trigger === "keyword_required_status" && (
            <React.Fragment>
              {wizardComplete && <Space mt="sm" />}
              {!contestDates ? (
                <Text mt="sm" color="yellow" fw={600}>
                  Add contest dates before creating a keyword
                </Text>
              ) : (
                <ContestWizardKeyword
                  contestDates={contestDates}
                  contestId={contestId}
                  locationId={locationId}
                  organizationId={organizationId}
                  fetchData={onSuccess}
                  showList={wizardComplete ? true : false}
                />
              )}
            </React.Fragment>
          )}
          {trigger === "featured_image_required_status" && (
            <Box mt="sm">
              <ContestAssets
                uploadOnly={wizardComplete ? false : true}
                contestId={contestId}
                fetchContest={onSuccess}
              />
            </Box>
          )}
          {trigger === "prize_required_status" && (
            <Box mt="sm">
              <PrizeManagement
                locationId={locationId}
                organizationId={organizationId}
                contestId={contestId}
                fetchData={onSuccess}
                isAuction
                minimal={minimal}
                onModalClose={onSuccess}
                showPrizeEdit={false}
                contestForDuplication={isForDuplication}
              />
            </Box>
          )}
          {trigger === "status" && (
            <Box mt="sm">
              <Group spacing="xs">
                {statusButtons.map((b) => (
                  <Button
                    key={b.key}
                    onClick={() => onStatusClick(b.value)}
                    color={b.color}
                    size="xs"
                    disabled={allowStatusToggle ? false : true}
                    variant={status === b.value ? "filled" : "light"}
                    loading={loading}
                  >
                    {b.text}
                  </Button>
                ))}
              </Group>
            </Box>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

function makeNewSettingsHash(settings, trigger, value) {
  switch (trigger) {
    case "check_in_value_required_status":
      return {
        ...settings,
        check_ins: {
          ...settings.check_ins,
          check_in_value: value,
        },
      };
    case "contest_keyword_value_required_status":
      return {
        ...settings,
        keywords: {
          ...settings.keywords,
          keyword_value: value,
        },
      };
    case "contest_keyword_entries_allowed_required_status":
      return {
        ...settings,
        keywords: {
          ...settings.keywords,
          total_entries_allowed: value,
        },
      };
    case "contest_keyword_use_total_required_status":
      return {
        ...settings,
        keywords: {
          ...settings.keywords,
          keyword_use_total: value,
        },
      };
    case "entry_cost_required_status":
      return {
        ...settings,
        entry_cost: value,
      };
    case "initial_entry_setting_required_status":
      return {
        ...settings,
        initial_entry: {
          ...settings.initial_entry,
          entry_count: value,
        },
      };
    case "min_starting_bid_required_status":
      return {
        ...settings,
        min_starting_bid: value,
      };
    case "max_bid_increment_required_status":
      return {
        ...settings,
        max_bid_increment: value,
      };
    case "total_entries_allowed_required_status":
      return {
        ...settings,
        total_entries_allowed: value,
      };
    default:
      return settings;
  }
}

const statusButtons = [
  // { text: "created", value: 1, color: "yellow" },
  { text: "on", value: 2, color: "green" },
  { text: "off", value: 3, color: "red" },
].map((m) => ({
  ...m,
  key: m.value,
}));
