import React from "react";
import { Box, Group, Text } from "@mantine/core";
import { IconTrophy, IconCurrencyDollar, IconPhoto } from "@tabler/icons-react";

export default function LootItemIndicators({
  hasAsset,
  quantityAllocated,
  value,
}) {
  const iconProps = {
    size: 16,
    color: "black",
    variant: "light",
    radius: "xl",
  };

  const items = [
    {
      value: hasAsset ? "Image Uploaded" : "No Image Uploaded",
      icon: <IconPhoto {...iconProps} />,
      color: hasAsset ? "green" : "grey",
    },
    {
      value: `${quantityAllocated} Allocated`,
      icon: <IconTrophy {...iconProps} />,
      color: quantityAllocated > 0 ? "black" : "grey",
    },
    {
      value: value,
      icon: <IconCurrencyDollar {...iconProps} />,
      color: "black",
    },
  ];

  return (
    <Box>
      {items.map((item, i) => (
        <div key={i}>
          <Group style={{ color: item.color }} size="lg">
            {item.icon}
            <Text size="sm">{item.value}</Text>
          </Group>
        </div>
      ))}
    </Box>
  );
}
