import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  ActionIcon,
  Card,
  Flex,
  Loader,
  Title,
  TextInput,
  Pagination,
  Button,
  Menu,
  Select,
  Badge,
  Table,
  Text,
  Anchor,
} from "@mantine/core";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { IconCheck, IconSearch } from "@tabler/icons-react";
import dayjs from "dayjs";

import { DateField } from "@components/shared";
import entityIcon from "@util/entityIcon";

const today = dayjs().format("YYYY-MM-DDTHH:mm:ss");

const searchByOptions = [
  { text: "To", value: "to" },
  { text: "From", value: "from" },
  { text: "Tag", value: "tag" },
  { text: "Subject", value: "subject" },
];

const dateRangeOptions = [
  {
    text: "All Time",
    value: "all",
    dates: {
      start: "",
      end: "",
    },
  },
  {
    text: "Today",
    value: 0,
    dates: {
      start: getStartDate(0),
      end: today,
    },
  },
  {
    text: "Last 7 days",
    value: 7,
    dates: {
      start: getStartDate(7),
      end: today,
    },
  },
  {
    text: "Last 28 days",
    value: 28,
    dates: {
      start: getStartDate(28),
      end: today,
    },
  },
  {
    text: "Last 45 days",
    value: 45,
    dates: {
      start: getStartDate(45),
      end: today,
    },
  },
];

export const formatRecipients = new Intl.ListFormat("en", {
  style: "long",
  type: "conjunction",
});

export default function EmailActivityList({ apiToken, streamId, serverId }) {
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  const [dateValue, setDateValue] = useState("all");
  const [searchByValue, setSearchByValue] = useState("to");
  const [dates, setDates] = useState({
    start: "",
    end: "",
  });
  const [dateMenuOpen, setDateMenuOpen] = useState(false);
  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const columns = useMemo(
    () => [
      { header: "Event", accessorKey: "Status", enableSorting: false },
      {
        header: "Recipient",
        enableSorting: false,
        id: "recipient",
        cell: ({ row }) => {
          if (row.original.Recipients && row.original.Recipients.length > 0) {
            return formatRecipients.format(row.original.Recipients);
          } else {
            return "";
          }
        },
      },
      {
        header: "Subject",
        accessorKey: "Subject",
        enableSorting: false,
        id: "subject",
        cell: ({ row }) => {
          return (
            <Anchor
              component={Link}
              to={`/postmark/${serverId}/streams/${streamId}/messages/${row.original.MessageID}`}
              c="dark"
              fw={600}
            >
              {row.original.Subject}
            </Anchor>
          );
        },
      },
      {
        header: "Tag",
        accessorKey: "Tag",
        id: "tag",
        cell: ({ row }) => {
          if (row.original.Tag) {
            return (
              <Badge
                variant="light"
                size="xs"
                style={{ textTransform: "none" }}
                color="gray"
              >
                {row.original.Tag}
              </Badge>
            );
          } else {
            return "";
          }
        },
      },
      {
        header: "Date & Time",
        id: "date",
        cell: ({ row }) => {
          return new Date(row.original.ReceivedAt).toLocaleString();
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data: items,
    columns,
    manualPagination: true,
    enableMultiSort: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  useEffect(() => {
    let p = new Promise((resolve, reject) => {
      resolve(table.setPageIndex(0));
    });
    p.then((result) => {
      fetchData();
    }).catch((error) => {
      console.log(error);
    });
  }, [JSON.stringify(dates), statusValue, searchByValue]);

  useEffect(() => {
    table.setPageIndex(0);
    if (table.getState().pagination.pageIndex === 0) {
      fetchData();
    }
  }, [searchValue]);

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(table.getState().pagination)]);

  function onSearchSubmit() {
    let p = new Promise((resolve, reject) => {
      resolve(table.setPageIndex(0));
    });
    p.then(() => {
      fetchData();
    }).catch((error) => {
      console.log(error);
    });
  }

  function fetchData() {
    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      page_size: table.getState().pagination.pageSize,
      page_count:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
      server_token: apiToken,
      message_stream: streamId,
      value: searchValue,
      [searchByValue]: true,
    };

    if (statusValue !== "all") req.status = statusValue;
    if (dates.start) req.from_date = dates.start;
    if (dates.end) req.to_date = dates.end;

    axios
      .post(`/postmark/message-streams/${streamId}/messages/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setItems(data.response[0].Messages);
        if (data.response[0].TotalCount !== undefined) {
          setTotal(data.response[0].TotalCount);
          setPageCount(
            Math.round(
              data.response[0].TotalCount / table.getState().pagination.pageSize
            )
          );
        } else {
          setTotal(data.response[0].Messages.length);
        }
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  const selectedDateOption = dateRangeOptions.find(
    (f) => f.value === dateValue
  );

  const selectedSearchByOption = searchByOptions.find(
    (f) => f.value === searchByValue
  );

  return (
    <div>
      <Flex gap="xs" align="center" mb="sm">
        <Title order={4}>Activity</Title>
        {loading && <Loader size="xs" />}
      </Flex>
      <Flex style={{ width: "100%" }} gap="xs" align="center">
        <TextInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search..."
          style={{
            flexGrow: 1,
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearchSubmit();
            }
          }}
        />
        <Menu>
          <Menu.Target>
            <Button
              style={{ minWidth: "110px" }}
              variant="light"
              color="gray"
              leftSection={<IconSearch size={16} />}
            >
              {selectedSearchByOption ? selectedSearchByOption.text : "Custom"}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {searchByOptions.map((item, i) => (
              <Menu.Item
                key={i}
                leftSection={item.value === searchByValue ? <IconCheck /> : ""}
                onClick={() => {
                  setSearchByValue(item.value);
                }}
              >
                {item.text}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
        <Select
          // label="Status"
          value={statusValue}
          onChange={(e) => setStatusValue(e)}
          data={[
            { label: "All", value: "all" },
            { label: "Queued", value: "queued" },
            { label: "Sent", value: "sent" },
          ]}
        />
        <Menu
          opened={dateMenuOpen}
          onChange={() => setDateMenuOpen(!dateMenuOpen)}
          closeOnItemClick={false}
        >
          <Menu.Target>
            <Button
              style={{ minWidth: "160px" }}
              variant="light"
              leftSection={entityIcon.calendar()}
              color="gray"
            >
              {selectedDateOption ? selectedDateOption.text : "Custom"}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {dateRangeOptions.map((item, i) => (
              <Menu.Item
                key={i}
                leftSection={item.value === dateValue ? <IconCheck /> : ""}
                onClick={() => {
                  setDateValue(item.value);
                  setDates(item.dates);
                  setDateMenuOpen(false);
                }}
              >
                {item.text}
              </Menu.Item>
            ))}
            <Menu.Item>
              <Flex gap="xs" align="center">
                <DateField
                  label="From"
                  value={dates.start}
                  onChange={(e) => {
                    setDateValue("");
                    setDates({
                      ...dates,
                      start: e,
                    });
                  }}
                />
                <DateField
                  label="To"
                  value={dates.end}
                  onChange={(e) => {
                    setDateValue("");
                    setDates({
                      ...dates,
                      end: e,
                    });
                  }}
                />
              </Flex>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <Text size="xs" mt="xs">
        *All time and dates are in Eastern Time Zone
      </Text>
      <Card mt="sm">
        <Table.ScrollContainer minWidth={1000}>
          <Table striped>
            <Table.Thead>
              <Table.Tr>
                {table.getFlatHeaders().map((header) => (
                  <Table.Th key={header.id}>
                    {header.column.columnDef.header}
                  </Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Table.Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Table.Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Td>
                      );
                    })}
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Card>
      <Pagination
        value={table.getState().pagination.pageIndex + 1}
        onChange={(e) => {
          table.setPageIndex(e - 1);
        }}
        total={pageCount}
        mt="lg"
      />
    </div>
  );
}

function getStartDate(daysToSubtract) {
  return dayjs()
    .subtract(daysToSubtract, "day")
    .hour(0)
    .minute(0)
    .second(1)
    .format("YYYY-MM-DDTHH:mm:ss");
}
