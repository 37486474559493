import React from "react";

import { LinkList } from "@components/shared";

export default function AdminList({ items = [] }) {
  return (
    <LinkList
      columns={1}
      items={items.map((item) => ({
        ...item,
        title: `${item.first_name} ${item.last_name}`,
        description: item.email,
        badge_text: item.mfa ? `MFA Enabled` : null,
      }))}
    />
  );
}
