import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, Route, Routes } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Image,
  Modal,
  Switch,
  Tabs,
  Text,
} from "@mantine/core";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import {
  AvatarManagement,
  ErrorView,
  PageTitle,
  PhoneManagement,
  Subnav,
} from "@components/shared";
import { ManagerForm } from "@components/Manager";
import { PasswordForm } from "@components/Auth";
import { AssociateButton } from "@components/Manager/ManagerAssociate";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";
import generateStaticMap from "@util/generateStaticMap";

export default function ManagerDetail({ isProfile, isAdmin }) {
  const [error, setError] = useState(null);
  const [manager, setManager] = useState(populateManager());
  const params = useParams();
  const { id } = params;

  const managerId = isProfile
    ? useSelector((state) => state.manager).manager_id
    : id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/managers/${managerId}/`)
      .then(({ data }) => {
        setManager(populateManager(data.response[0]));
      })
      .catch((err) => {
        setError(err);
        setManager(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  const showLocations = manager.location_joins.length > 0;

  return (
    <div>
      <Group mb="lg">
        <AvatarManagement managerId={managerId} asset={manager.profile_asset} />
        <PageTitle
          logged_in={manager.logged_in}
          title={
            <Group>
              <span>
                {manager.first_name} {manager.last_name}
              </span>
              {manager.logged_in === true && (
                <Badge variant="light" color="green">
                  Logged In
                </Badge>
              )}
              <Badge
                variant="light"
                color={
                  manager.status === 1
                    ? "yellow"
                    : manager.status === 2
                    ? "green"
                    : "red"
                }
              >
                {manager.status === 1
                  ? "Signup incomplete"
                  : manager.status === 2
                  ? "Active"
                  : "Inactive"}
              </Badge>
              {manager.logged_in === false && manager.status !== 4 && (
                <Badge variant="light" color="gray">
                  Logged Out
                </Badge>
              )}
              {manager.status === 4 && (
                <Badge variant="light" color="gray">
                  Disabled
                </Badge>
              )}
            </Group>
          }
          subtitle={<React.Fragment>Manager</React.Fragment>}
          icon={entityIcon.manager()}
          iconColor={entityColor.manager}
          style={{ marginBottom: "1em" }}
        />
      </Group>
      {isAdmin && manager.status === 1 && (
        <AssociateButton
          buttonText="Resend signup email"
          buttonProps={{
            size: "xs",
            mb: "lg",
            color: "green",
          }}
          url="/resend-signup/"
          onSuccess={() => true}
          reqData={{
            manager_id: id,
          }}
        />
      )}
      <Subnav
        buttonColor={entityColor.manager}
        links={[
          { to: `/managers/${id}`, text: "Detail " },
          { to: `/managers/${id}/locations`, text: "Locations" },
          // { to: `/users/${id}/interactions`, text: "Interactions" },
        ].filter((f) => (!showLocations ? f.text !== "Locations" : true))}
      />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Card>
                <ManagerForm
                  id={managerId}
                  email={manager.email}
                  firstName={manager.first_name}
                  lastName={manager.last_name}
                  mfa={manager.mfa}
                  onSuccess={fetchData}
                />
              </Card>
              <Card mt="lg">
                <PhoneManagement
                  managerId={managerId}
                  fetchEntity={fetchData}
                  mfaEnabled={manager.mfa}
                  showMfa={manager.email ? true : false}
                  email={manager.email}
                />
              </Card>
              {isProfile && <ChangePassword managerId={managerId} />}
              {isAdmin && manager.status !== 1 && (
                <ManagerStatus
                  managerId={managerId}
                  fetchData={fetchData}
                  status={manager.status}
                />
              )}
            </>
          }
        />
        {showLocations && (
          <Route
            path="locations"
            element={
              <>
                <Grid>
                  {manager.location_joins.map((m) => (
                    <Grid.Col span={{ base: 12, md: 6 }} key={m.location_id}>
                      <Card component={Link} to={`/locations/${m.location_id}`}>
                        <Text fw={600} mb="sm">
                          {m.name}
                        </Text>
                        <Card.Section>
                          <Image
                            h={250}
                            mah={250}
                            fit="cover"
                            style={{
                              overflow: "hidden",
                            }}
                            src={generateStaticMap(
                              { latitude: m.latitude, longitude: m.longitude },
                              { width: 500, height: 250 }
                            )}
                          />
                        </Card.Section>
                      </Card>
                    </Grid.Col>
                  ))}
                </Grid>
              </>
            }
          />
        )}
      </Routes>
    </div>
  );
}

function populateManager(d = {}) {
  return {
    first_name: d.first_name || "",
    email: d.email || "",
    last_name: d.last_name || "",
    mfa: d.mfa || false,
    logged_in: d.logged_in || false,
    location_joins: d.location_joins || [],
    profile_asset: d.profile_asset || null,
    status: d.status || null,
  };
}

function ChangePassword({ managerId }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Button variant="light" size="xs" mt="xl" onClick={() => setOpen(true)}>
        Change Password
      </Button>
      <Modal
        opened={isOpen}
        onClose={() => setOpen(false)}
        title="Change Password"
      >
        <PasswordForm
          onSuccess={() => setOpen(false)}
          updating
          additionalReqData={{
            manager_id: managerId,
          }}
        />
      </Modal>
    </React.Fragment>
  );
}

function ManagerStatus({ managerId, fetchData, status }) {
  function changeStatus() {
    axios
      .get(`/managers/${managerId}/change-status/`)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        fetchData();
      });
  }

  return (
    <React.Fragment>
      <Switch
        mt="xl"
        size="md"
        description={
          status === 2
            ? "Unchecking will disable this manager from logging in along with removing them from all locations & organizations."
            : "Checking will reactivate this manager allowing them to log in"
        }
        label="Activation Status"
        onClick={() => changeStatus()}
        checked={status === 2}
      />
    </React.Fragment>
  );
}
