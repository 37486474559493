import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Badge,
  Button,
  Card,
  Flex,
  Space,
  Group,
  Text,
  TextInput,
} from "@mantine/core";
import toast from "react-hot-toast";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";
import { DataFilters, PaginatedList } from "@components/shared";
import { AssociateButton } from "./ManagerAssociate";

const filterArr = [
  {
    text: "Status",
    key_name: "status",
    default_value: [1, 2, 3, 4],
    options: [
      { text: "Created", value: 1 },
      { text: "Active", value: 2 },
      { text: "Disabled", value: 4 },
    ],
  },
].map((m, i) => ({
  ...m,
  all_values: m.options.map((mm) => mm.value),
  key: i + 1,
}));

const defaultFilters = filterArr.reduce((acc, cur) => {
  acc[cur.key_name] = cur.default_value;
  return acc;
}, {});

export default function ManagerList({
  items = null,
  requestInfo = {
    url: `/retrieve-managers/`,
    data: {},
  },
  refresh,
  isIndex,
  organizationId,
  locationId,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState(defaultFilters);
  const [refresh2, setRefresh2] = useState(false);

  const isAdmin = useSelector((state) => state.admin) ? true : false;

  const formattedFilters = Object.keys(filters).reduce((acc, cur) => {
    const obj = filterArr.find((f) => f.key_name === cur);
    acc[cur] = filters[cur].length ? filters[cur] : obj.all_values;
    return acc;
  }, {});

  const reqData = {
    ...formattedFilters,
    ...requestInfo.data,
    search_value: searchValue,
    refresh,
    refresh2,
  };

  function onFilterChange(keyName, value) {
    setFilters({
      ...filters,
      [keyName]: filters[keyName].includes(value)
        ? [...filters[keyName]].filter((f) => f !== value)
        : [...filters[keyName], value],
    });
  }

  const showJoinStatus = organizationId || locationId ? true : false;

  return (
    <div>
      {requestInfo && (
        <React.Fragment>
          <TextInput
            placeholder="Search for a manager..."
            size="lg"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <DataFilters
            onChange={onFilterChange}
            values={filters}
            items={filterArr}
          />
        </React.Fragment>
      )}
      <PaginatedList
        tableData={items}
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/managers/${m.id}`,
          }))
        }
        displayFormatter={(item) => {
          const matchingLocation = showJoinStatus
            ? locationId
              ? item.original.location_joins.find(
                  (f) => f.location_id === locationId
                )
              : null
            : null;

          const matchingOrg = showJoinStatus
            ? organizationId
              ? item.original.organization_joins.find(
                  (f) => f.organization_id === organizationId
                )
              : null
            : null;

          const matchingEntity = matchingLocation || matchingOrg;

          return (
            <Card key={item.original.id}>
              <Group>
                <Text
                  fw={700}
                  component={Link}
                  to={`/managers/${item.original.id}`}
                >
                  {item.original.first_name} {item.original.last_name}
                </Text>
                {!showJoinStatus ? (
                  <>
                    {item.original.status === 1 && (
                      <Badge variant="light" color="yellow">
                        Waiting on activation
                      </Badge>
                    )}
                    {item.original.status === 2 && (
                      <Badge variant="light" color="green">
                        Active
                      </Badge>
                    )}
                    {item.original.status === 4 && (
                      <Badge variant="light" color="gray">
                        Disabled
                      </Badge>
                    )}
                  </>
                ) : (
                  <>
                    {matchingEntity && (
                      <>
                        {item.original.status === 1 && (
                          <Badge variant="light" color="yellow">
                            Signup incomplete
                          </Badge>
                        )}
                        {item.original.status === 2 &&
                          matchingEntity.status === 2 && (
                            <Badge variant="light" color="green">
                              Active
                            </Badge>
                          )}
                        {item.original.status === 2 &&
                          matchingEntity.status === 1 && (
                            <Badge variant="light" color="blue">
                              Invited
                            </Badge>
                          )}
                      </>
                    )}
                  </>
                )}
                {item.original.logged_in && (
                  <Badge variant="light" color="green">
                    Logged In
                  </Badge>
                )}
                {!item.original.logged_in && item.original.status !== 4 && (
                  <Badge variant="light" color="gray">
                    Logged Out
                  </Badge>
                )}
              </Group>
              <Group mt="xs">
                {entityIcon.email()}
                <Text size="sm">{item.original.email}</Text>
              </Group>
              {requestInfo && requestInfo.data && !isIndex && isAdmin && (
                <Flex align="center" mt="xs" gap="xs">
                  {matchingEntity &&
                    item.original.status === 2 &&
                    matchingEntity.status === 1 && (
                      <AssociateButton
                        buttonProps={{
                          size: "xs",
                          fullWidth: false,
                          p: 0,
                          color: "gray",
                          variant: "subtle",
                        }}
                        buttonText="Resend Invite"
                        url={`/managers/${item.original.id}/add-to-entity/`}
                        onSuccess={() => {
                          setRefresh2(!refresh2);
                        }}
                        reqData={{
                          organization_id: organizationId,
                          location_id: locationId,
                          manager_id: item.original.id,
                        }}
                      />
                    )}
                  <RemoveButton
                    reqData={{
                      ...requestInfo.data,
                      manager_id: item.original.id,
                      remove: true,
                    }}
                    managerId={item.original.id}
                    onSuccess={() => setRefresh2(!refresh2)}
                  />
                </Flex>
              )}
            </Card>
          );
        }}
      />
    </div>
  );
}

const RemoveButton = ({ reqData, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    const req = {
      ...reqData,
    };

    setLoading(true);

    axios
      .post(`/remove-manager/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Removed!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      onClick={onClick}
      size="xs"
      variant="subtle"
      color="gray"
      loading={loading}
      leftSection={entityIcon.trash()}
      radius="lg"
      p={0}
    >
      Remove
    </Button>
  );
};
