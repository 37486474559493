import React, { useEffect, useState } from "react";
import { Group, Button, Modal, List, Text } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import { KeywordSubmitSummary } from "@components/Keyword";

const EntrySummary = ({ checkin, keyword = "", results = [], onClose }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, [JSON.stringify(results)]);

  const { t } = useTranslation();

  return (
    <Modal opened={isOpen} onClose={() => onClose()} title="Entry Summary">
      {checkin && <Text>{t("entry_summary_check_in_intro")},</Text>}
      {keyword && (
        <Text>
          {t("entry_summary_keyword_intro")} <b>{keyword}</b>...
        </Text>
      )}
      {!checkin && <KeywordSubmitSummary results={results} />}
      {checkin && (
        <>
          <List mt="sm">
            {results.map((result, i) => (
              <List.Item
                key={i}
                icon={
                  result.success ? (
                    <IconCheck color="green" />
                  ) : (
                    <IconX color="red" />
                  )
                }
              >
                {result.success ? (
                  <span>
                    you gained {result.entry_count}{" "}
                    {result.entry_count === 1 ? "entry" : "entries"} for{" "}
                    <b>{result.name}</b>, and have {result.current_entry_total}{" "}
                    out of {result.possible_entries} total
                  </span>
                ) : (
                  <span>
                    {result.message} for <b>{result.name}</b>
                  </span>
                )}
              </List.Item>
            ))}
          </List>
        </>
      )}
      <Group mt="lg" position="center">
        <Button color="gray" size="sm" variant="subtle" onClick={onClose}>
          Close
        </Button>
      </Group>
    </Modal>
  );
};

export default EntrySummary;
