import React from "react";
import { Badge, Group, Table } from "@mantine/core";

export default function EmailValidationHistory({ items = [] }) {
  if (!items.length) return null;

  const demoItems = items.filter((f) => f.data.domain);
  const nonDemoItems = items.filter((f) => !f.data.domain);

  return (
    <React.Fragment>
      {demoItems.length > 0 && (
        <Table mt="sm" striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th style={{ width: "160px" }}>Date</Table.Th>
              <Table.Th style={{ width: "90px" }}>Domain</Table.Th>
              <Table.Th style={{ width: "90px" }}>MX Found</Table.Th>
              <Table.Th style={{ width: "90px" }}>Status</Table.Th>
              <Table.Th style={{ width: "90px" }}>Free Email</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {demoItems.map((m, i) => (
              <Table.Tr key={i}>
                <Table.Td>{new Date(m.created_at).toLocaleString()}</Table.Td>
                <Table.Td>{m.data.domain}</Table.Td>
                <Table.Td>{m.data.mx_found}</Table.Td>
                <Table.Td>{m.data.status}</Table.Td>
                <Table.Td>{m.data.free_email ? "yes" : "no"}</Table.Td>
                <Table.Td>
                  {m.data.flags && (
                    <Group
                      style={{
                        gap: "5px",
                      }}
                    >
                      {m.data.flags.map((mm, ii) => (
                        <Badge variant="light" size="xs" key={ii}>
                          {mm}
                        </Badge>
                      ))}
                    </Group>
                  )}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      )}
      {nonDemoItems.length > 0 && (
        <Table mt="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th style={{ width: "160px" }}>Date</Table.Th>
              <Table.Th style={{ width: "90px" }}>Status</Table.Th>
              <Table.Th style={{ width: "90px" }}>Result</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody striped>
            {nonDemoItems.map((m, i) => (
              <Table.Tr key={i}>
                <Table.Td>{new Date(m.created_at).toLocaleString()}</Table.Td>
                <Table.Td>{m.data.status}</Table.Td>
                <Table.Td>{m.data.result}</Table.Td>
                <Table.Td>
                  {m.data.flags && (
                    <Group
                      style={{
                        gap: "5px",
                      }}
                    >
                      {m.data.flags.map((mm, ii) => (
                        <Badge variant="light" size="xs" key={ii}>
                          {mm}
                        </Badge>
                      ))}
                    </Group>
                  )}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      )}
    </React.Fragment>
  );
}
