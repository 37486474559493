import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Card,
  Flex,
  Loader,
  Title,
  TextInput,
  Button,
  Table,
  Text,
} from "@mantine/core";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import toast from "react-hot-toast";

export default function EmailSuppressionList({ apiToken, serverId, streamId }) {
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const columns = useMemo(
    () => [
      { header: "Email", accessorKey: "EmailAddress", enableSorting: false },
      {
        header: "Reason",
        accessorKey: "SuppressionReason",
        enableSorting: false,
        id: "reason",
      },
      {
        header: "Date added",
        id: "date",
        accessorKey: "CreatedAt",
        enableSorting: false,
        cell: ({ row }) => (
          <>{new Date(row.original.CreatedAt).toLocaleString()}</>
        ),
      },
      {
        header: "",
        id: "actions",
        cell: ({ row }) => {
          return (
            <ReactivateButton
              reqData={{
                email: row.original.EmailAddress,
                postmark_server_token: apiToken,
                stream: streamId,
              }}
              onSuccess={() => setRefresh(!refresh)}
            />
          );
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data: items,
    columns,
    // manualPagination: true,
    enableMultiSort: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 5000,
      },
    },
  });

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(table.getState().pagination), refresh]);

  function fetchData() {
    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      page_size: table.getState().pagination.pageSize,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
      server_token: apiToken,
      message_stream: streamId,
      value: searchValue,
    };

    axios
      .post(`/postmark/message-streams/${streamId}/suppressions/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setItems(data.response[0].Suppressions);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  return (
    <div>
      <Flex gap="xs" align="center" mb="sm">
        <Title order={4}>Suppressions</Title>
        {loading && <Loader size="xs" />}
      </Flex>
      {/* <TextInput
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Filter by email..."
      /> */}
      <Card mt="sm">
        <Table>
          <Table.Thead>
            <Table.Tr>
              {table.getFlatHeaders().map((header) => (
                <Table.Th key={header.id}>
                  {header.column.columnDef.header}
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Table.Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Td>
                    );
                  })}
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      </Card>
    </div>
  );
}

const ReactivateButton = ({ reqData, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {
      ...reqData,
    };

    axios
      .post(`/postmark/message-streams/reactivate-stream/`, req)
      .then(() => {
        toast.success("Reactivated!");
        setLoading(true);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      color="gray"
      variant="light"
      size="xs"
      onClick={onClick}
      loading={loading}
    >
      Reactivate
    </Button>
  );
};
