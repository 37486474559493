import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";

import { BasicForm } from "@components/shared";
import countries from "@lib/countries";
import usStates from "@lib/usStates";

export default function GeospotForm({
  name = "",
  address1 = "",
  address2 = "",
  city = "",
  state = "",
  id = null,
  zip = "",
  country = "",
  locationId = null,
  onSuccess = () => {},
}) {
  const [loading, setLoading] = useState(false);

  function onSubmit(formData) {
    const formatted = {
      ...formData,
      state_province: formData.state,
    };

    setLoading(true);

    if (!id) return onCreate(formatted);

    return onUpdate(formatted);
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      geo_spot_id: id,
    };

    axios
      .put(`/geo-spots/${id}/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Updated!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      location_id: locationId,
    };

    axios
      .post(`/geo-spots/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Created!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "name",
      label: "Name",
      initialValue: name,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required"),
    },
    {
      name: "address_1",
      label: "Address",
      initialValue: address1,
      addressLookup: true,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required!"),
    },
    {
      name: "address_2",
      label: "Address Line 2 (Ste, Apt, etc.)",
      initialValue: address2,
      schema: () => null,
    },
    {
      name: "city",
      label: "City",
      initialValue: city,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required!"),
    },
    {
      name: "state",
      label: "State/Province",
      initialValue: state,
      select: true,
      options: usStates,
      required: true,
      schema: () => Yup.string().nullable().required("Required!"),
    },
    {
      name: "zip",
      label: "Zip/Postal Code",
      initialValue: zip,
      required: true,
      schema: () => Yup.string().required("Required!"),
    },
    {
      name: "country",
      label: "Country",
      initialValue: country,
      select: true,
      options: countries,
      required: true,
      schema: () => Yup.string().nullable().required("Required!"),
    },
  ];

  return (
    <div>
      <BasicForm
        buttonProps={{
          fullWidth: true,
        }}
        loading={loading}
        onSubmit={onSubmit}
        fields={fields}
      />
    </div>
  );
}
