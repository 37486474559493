import { Flex, Text } from "@mantine/core";

export default function StationNowPlaying({ info }) {
  if (!info || !info.is_song) return null;

  return (
    <div>
      <Flex>
        <Text size="xs" c="dimmed">
          Now playing: {info.name} by {info.artist}
        </Text>
      </Flex>
    </div>
  );
}
