import React, { useState } from "react";
import { Checkbox, Box, Flex, Text, UnstyledButton } from "@mantine/core";
import { IconCaretRight, IconCaretDown } from "@tabler/icons-react";
import axios from "axios";

import {
  defaultEmailStreams,
  emailStreamLabels,
} from "@components/Email/helpers";

const iconProps = {
  size: 24,
  color: "var(--mantine-color-dark-3)",
};

export default function MicrositeUserEmailStreams({
  onChange,
  streams = defaultEmailStreams.map((m) => ({ ...m, value: true })),
  user,
}) {
  const [isOpen, setOpen] = useState(false);

  function onStreamChange(streamId) {
    const newStreams = [...streams];
    const newStream = newStreams.find((f) => f.id === streamId);
    if (!newStream) return;
    newStream.value = !newStream.value;
    onChange(newStreams);

    if (user) {
      const req = {
        user_email_stream_id: streamId,
        user_location_id: user.user_location_id,
        user_organization_id: user.user_organization_id,
      };

      axios.post(
        user.user_location_id
          ? `/user-locations/${user.user_location_id}/toggle-email-stream/`
          : `/user-organizations/${user.user_organization_id}/toggle-email-stream/`,
        req
      );
    }
  }

  return (
    <>
      <UnstyledButton onClick={() => setOpen(!isOpen)}>
        <Flex align="center" gap="xs">
          {isOpen ? (
            <IconCaretDown {...iconProps} />
          ) : (
            <IconCaretRight {...iconProps} />
          )}
          <Text c="dimmed">Email Preferences</Text>
        </Flex>
      </UnstyledButton>
      {isOpen && (
        <Box mt="sm">
          {streams.map((stream, i) => (
            <Checkbox
              mb="sm"
              key={i}
              label={emailStreamLabels[stream.variety] || stream.label}
              checked={stream.value}
              onChange={() => onStreamChange(stream.id)}
            />
          ))}
        </Box>
      )}
    </>
  );
}
