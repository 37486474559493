import React from "react";

import { ArtistList } from "@components/Artist";

export default function ArtistIndex() {
  return (
    <div>
      <ArtistList />
    </div>
  );
}
