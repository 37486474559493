import React from "react";
import { Modal, Button, Anchor, Text } from "@mantine/core";

export default function UserLockModal({ data }) {
  if (!data) return null;
  return (
    <Modal
      opened
      onClose={() => {
        window.location.reload();
      }}
    >
      <Text>{data.message}</Text>
      <Button
        variant="light"
        mt="lg"
        component={Anchor}
        href={`/submit-trouble-ticket`}
        target="_blank"
      >
        Open support ticket
      </Button>
    </Modal>
  );
}
