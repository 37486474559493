import React, { useState, useEffect } from "react";
import { NumberInput, Modal, Group, ActionIcon, Button } from "@mantine/core";
import {
  IconInfinity,
  IconMinus,
  IconKeyboard,
  IconPlus,
} from "@tabler/icons-react";

const buttonProps = {
  variant: "light",
  radius: "xl",
  color: "gray",
};

export default function Counter({
  maxValue = null,
  minValue = 0,
  onChange,
  value = "",
  unlimited = true,
}) {
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  function onClose() {
    if ([undefined, ""].includes(inputValue)) {
      onChange(0);
    } else {
      onChange(inputValue);
    }

    setOpen(false);
  }

  return (
    <React.Fragment>
      <Modal opened={isOpen} onClose={onClose} title="Manual Entry">
        <NumberInput
          placeholder="enter value here"
          value={value}
          onChange={(e) => {
            if (maxValue && e > maxValue) return setInputValue(maxValue);
            if (typeof e === "number") {
              setInputValue(e);
            }
          }}
          hideControls
          size="lg"
        />
        <Button fullWidth variant="light" mt="sm" onClick={onClose}>
          Update
        </Button>
      </Modal>
      <Group>
        <ActionIcon
          {...buttonProps}
          onClick={() => onChange(value - 1)}
          disabled={value <= minValue}
        >
          <IconMinus />
        </ActionIcon>
        <ActionIcon
          {...buttonProps}
          onClick={() => onChange(value + 1)}
          disabled={maxValue ? value >= maxValue : false}
        >
          <IconPlus />
        </ActionIcon>
        <ActionIcon variant="subtle" color="gray" onClick={() => setOpen(true)}>
          <IconKeyboard />
        </ActionIcon>
        {unlimited && (
          <ActionIcon
            variant="subtle"
            color="gray"
            onClick={() => onChange(-1)}
            disabled={!unlimited}
          >
            <IconInfinity />
          </ActionIcon>
        )}
        <h1 style={{ margin: "0" }}>{value === -1 ? "Unlimited" : value}</h1>
      </Group>
    </React.Fragment>
  );
}
