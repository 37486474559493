import React from "react";

import { CampaignManagement } from "@components/Campaign";

export default function CampaignIndex({ locationId, organizationId }) {
  return (
    <React.Fragment>
      <CampaignManagement
        locationId={locationId}
        organizationId={organizationId}
      />
    </React.Fragment>
  );
}
