import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Group,
  Badge,
  Box,
  Divider,
  Grid,
  Text,
  Card,
  Radio,
  Button,
} from "@mantine/core";
import toast from "react-hot-toast";

const defaultTemplateAliases = [
  "basic",
  "registration-confirmation",
  "verify-email",
  "winner-notification",
];

export default function EntityEmailTemplates({
  campaignId,
  contestId,
  effortId,
  fetchData,
  postmarkTemplateNames = [],
  templates = [],
}) {
  if (!templates.length) return null;

  const reqData = {};

  if (contestId) reqData.contest_id = contestId;
  if (effortId) reqData.effort_id = effortId;
  if (campaignId) reqData.campaign_id = campaignId;

  return (
    <div>
      <Grid>
        {templates.map((t, i) => (
          <Grid.Col span={{ base: 12, md: 4 }} key={i}>
            <Item
              templateName={t.name}
              templateFieldName={t.field_name}
              templateOptions={postmarkTemplateNames}
              currentTemplateValue={t.value}
              reqData={reqData}
              fetchData={fetchData}
            />
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
}
const Item = ({
  templateName,
  templateFieldName,
  templateOptions,
  reqData,
  fetchData,
  currentTemplateValue,
}) => {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      currentTemplateValue &&
      templateOptions.length > 0 &&
      templateOptions.includes(currentTemplateValue)
    ) {
      setValue(currentTemplateValue);
    }
  }, [currentTemplateValue]);

  function onSubmit() {
    setLoading(true);

    const req = { ...reqData, context: templateFieldName, value };
    // console.log(JSON.stringify(req));

    axios
      .post(`/update-entity-template/`, req)
      .then(() => {
        toast.success("Updated!");
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  if (templateOptions.length === 0) {
    return (
      <Card>
        <Text fw={600}>{templateName}</Text>
        <Text size="xs" c="dimmed">
          currently using Mixer default template
        </Text>
      </Card>
    );
  }

  return (
    <Card>
      <Text fw={600}>{templateName}</Text>
      {!templateOptions.includes(currentTemplateValue) && (
        <Text size="xs" color="red" fw={600}>
          The template "{currentTemplateValue}" no longer exists. Take care of
          this immediately by selecting a new template.
        </Text>
      )}
      {!currentTemplateValue && (
        <Text size="xs" c="dimmed">
          currently using default template
        </Text>
      )}
      {/* <Divider mb="xs" mt="xs" />
      {templateOptions.length === 0 && (
        <Text size="xs" c="dimmed">
          currently using default template
        </Text>
      )} */}
      {templateOptions.length > 0 && (
        <React.Fragment>
          <Divider mb="xs" mt="xs" />
          <Box
            style={{
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {templateOptions.map((m, i) => (
              <Group key={i} mb="xs">
                <Radio
                  key={i}
                  label={m}
                  name={templateFieldName}
                  value={m}
                  onChange={() => setValue(m)}
                  checked={m === value}
                />
                {defaultTemplateAliases.includes(m) && (
                  <Badge variant="light" size="xs" color="gray">
                    default
                  </Badge>
                )}
              </Group>
            ))}
          </Box>
          <Button
            mt="sm"
            disabled={!value}
            loading={loading}
            onClick={onSubmit}
          >
            Save
          </Button>
        </React.Fragment>
      )}
    </Card>
  );
};
