import React from "react";
import { Box, Button, Checkbox, Group, Text } from "@mantine/core";

export default function CurationSongSelect({
  items = [],
  title,
  selected,
  onChange,
}) {
  function onSelectionChange(id) {
    onChange(
      selected.includes(id)
        ? [...selected].filter((f) => f !== id)
        : [...selected, id]
    );
  }

  function onSelectAllClick() {
    onChange(
      selected.length === items.length ? [] : items.map((item) => item.id)
    );
  }

  return (
    <div>
      <Group mb="sm">
        <Text fw={600}>{title}</Text>
        <Button
          size="xs"
          variant="subtle"
          color="gray"
          onClick={onSelectAllClick}
        >
          {selected.length === items.length ? "Clear selection" : "Select all"}
        </Button>
      </Group>
      <Box
        style={{
          maxHeight: "250px",
          overflowY: "auto",
        }}
      >
        {items.map((item, i) => (
          <Checkbox
            label={item.name}
            key={item.id}
            checked={selected.includes(item.id)}
            onChange={() => onSelectionChange(item.id)}
            mb={i !== items.length - 1 ? "xs" : "0"}
          />
        ))}
      </Box>
    </div>
  );
}
