import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import {
  Card,
  Group,
  Button,
  Text,
  Badge,
  Flex,
  Modal,
  Title,
  Loader,
  Menu,
  Select,
  TextInput,
  SegmentedControl,
} from "@mantine/core";

import { IconCheck, IconExclamationCircle } from "@tabler/icons-react";
import { DateField, PaginatedList } from "@components/shared";
import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

const today = dayjs().format("YYYY-MM-DDTHH:mm:ss");

const dateRangeOptions = [
  {
    text: "All Time",
    value: "all",
    dates: {
      start: "",
      end: "",
    },
  },
  {
    text: "Today",
    value: 0,
    dates: {
      start: getStartDate(0),
      end: today,
    },
  },
  {
    text: "Last 7 days",
    value: 7,
    dates: {
      start: getStartDate(7),
      end: today,
    },
  },
  {
    text: "Last 28 days",
    value: 28,
    dates: {
      start: getStartDate(28),
      end: today,
    },
  },
  {
    text: "Last 45 days",
    value: 45,
    dates: {
      start: getStartDate(45),
      end: today,
    },
  },
];

export const formatRecipients = new Intl.ListFormat("en", {
  style: "long",
  type: "conjunction",
});

export default function ServerSessionList({
  requestInfo = {
    url: `/retrieve-server-sessions/`,
    data: {},
  },
}) {
  const [searchValue, setSearchValue] = useState("");
  const [variety, setVariety] = useState("All");
  const [filter, setFilter] = useState("All");
  const [refresh, setRefresh] = useState("All");
  const [confirm, setConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateValue, setDateValue] = useState("all");
  const [dates, setDates] = useState({
    start: "",
    end: "",
  });
  const [dateMenuOpen, setDateMenuOpen] = useState(false);

  const reqData = {
    admin: variety === "Admin",
    manager: variety === "Manager",
    user_location: variety === "User",
    start_date: dates.start.length > 1 ? dates.start : null,
    end_date: dates.end.length > 1 ? dates.end : null,
    access_filter: filter,
    refresh: refresh,
    search_value: searchValue.length > 2 ? searchValue : null,
  };

  const selectedDateOption = dateRangeOptions.find(
    (f) => f.value === dateValue
  );

  function endSessions() {
    axios
      .post("/server-sessions/system-logout/", {})
      .then(() => {
        toast.success("Sessions Closed!");
        setOpen(false);
        setConfirm(false);
        setRefresh(true);
      })
      .catch((err) => {
        setError(err);
        setOpen(false);
        setConfirm(false);
        setRefresh(true);
      });
  }

  function onClose() {
    setOpen(false);
    setConfirm(false);
  }

  return (
    <div>
      <Modal opened={open} onClose={() => onClose()} size="xl">
        <Flex direction="column" justify="center" align="center">
          <IconExclamationCircle size={50} color="red" />
          <Text lh={1} size={35} c="red" mb="lg" ta="center" mt="lg" pt="lg">
            Are you ABSOLUTELY sure you want to end all sessions except yours?
          </Text>
          <Group mb="lg" justify="center">
            <Button color="green" onClick={() => setOpen(false)}>
              No
            </Button>
            <Button
              color="red"
              onClick={confirm ? () => endSessions() : () => setConfirm(true)}
            >
              {confirm ? "Ok For Sure, Yes" : "Yes"}
            </Button>
          </Group>
          <Text size="xl" c="red" ta="center">
            This logs out all users except you.
          </Text>
        </Flex>
      </Modal>
      <Group gap="xs" align="center" mb="sm">
        <Title order={4}>Server Sessions</Title>
        <Button
          variant="subtle"
          color="red"
          size="xs"
          onClick={() => setOpen(true)}
        >
          End all sessions
        </Button>
        {loading && <Loader size="xs" />}
      </Group>
      <Flex style={{ width: "100%" }} gap="md" align="start" mb="lg">
        <Group>
          <SegmentedControl
            label="Variety"
            size="sm"
            value={variety}
            onChange={(e) => setVariety(e)}
            data={[
              { label: "All", value: "All" },
              { label: "Admin", value: "Admin" },
              { label: "Manager", value: "Manager" },
              { label: "User", value: "User" },
            ]}
          />
          <SegmentedControl
            label="Status"
            size="sm"
            value={filter}
            onChange={(e) => setFilter(e)}
            data={[
              { label: "All", value: "All" },
              { label: "Open", value: "open" },
              { label: "Closed", value: "closed" },
            ]}
          />
        </Group>
        <Menu
          opened={dateMenuOpen}
          onChange={() => setDateMenuOpen(!dateMenuOpen)}
          closeOnItemClick={false}
        >
          <Menu.Target>
            <Button
              style={{ minWidth: "160px" }}
              variant="light"
              leftSection={entityIcon.calendar()}
              color="gray"
            >
              {selectedDateOption ? selectedDateOption.text : "Custom"}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {dateRangeOptions.map((item, i) => (
              <Menu.Item
                key={i}
                leftSection={item.value === dateValue ? <IconCheck /> : ""}
                onClick={() => {
                  setDateValue(item.value);
                  setDates(item.dates);
                  setDateMenuOpen(false);
                }}
              >
                {item.text}
              </Menu.Item>
            ))}
            <Menu.Item>
              <Flex gap="xs" align="center">
                <DateField
                  label="From"
                  value={dates.start}
                  onChange={(e) => {
                    setDateValue("");
                    setDates({
                      ...dates,
                      start: e,
                    });
                  }}
                />
                <DateField
                  label="To"
                  value={dates.end}
                  onChange={(e) => {
                    setDateValue("");
                    setDates({
                      ...dates,
                      end: e,
                    });
                  }}
                />
              </Flex>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <TextInput
        placeholder="Search..."
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        size="lg"
      />
      <Text size="xs" mt="xs">
        *All time and dates are in Eastern Time Zone
      </Text>
      <PaginatedList
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/server-sessions/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <Card
            component={Link}
            to={`/server-sessions/${item.original.id}`}
            key={item.original.id}
          >
            <Group align="center" pos="relative">
              {item.original.admin_id && (
                <Text c={entityColor.admin}>{entityIcon.admin(20)}</Text>
              )}
              {item.original.manager_id && (
                <Text c={entityColor.manager}>{entityIcon.manager(20)}</Text>
              )}
              {item.original.user_location_id && (
                <Text c={entityColor.user}>{entityIcon.user(20)}</Text>
              )}
              <Flex direction="column">
                <Group
                  align="center"
                  pos="absolute"
                  right="10px"
                  size="xs"
                  gap={5}
                  c={item.original.closed ? "red" : "green"}
                >
                  {entityIcon.request(14)}
                  <Text size="sm">
                    {item.original.api_requests_count} requests
                  </Text>
                </Group>
                <Group>
                  <Text fw={700} size="lg">
                    {item.original.full_name}
                  </Text>
                  <Badge
                    size="sm"
                    color={item.original.closed ? "red" : "green"}
                    variant="subtle"
                  >
                    {item.original.closed
                      ? item.original.closed_by_system
                        ? "Closed by system"
                        : "Logged Out"
                      : "Open"}
                  </Badge>
                  {!item.original.closed && (
                    <Badge
                      size="md"
                      radius="md"
                      variant="light"
                      color={item.original.closed ? "red" : "green"}
                    >
                      {" "}
                      TTL: <b>{item.original.time_to_live} minutes</b>
                    </Badge>
                  )}
                </Group>
                <Text>{item.original.email}</Text>
                <Text>
                  {item.original.closed ? "Accessed" : "Accessing"}:{" "}
                  {item.original.entity_name}
                </Text>
                {!item.original.closed && (
                  <Text c="dimmed" size="sm">
                    Last seen:{" "}
                    {new Date(item.original.last_seen_at).toLocaleDateString()}{" "}
                    {new Date(item.original.last_seen_at).toLocaleTimeString()}
                  </Text>
                )}
                {item.original.closed_at && !item.original.logged_out_at && (
                  <Text c="dimmed" size="sm">
                    Closed at:{" "}
                    {new Date(item.original.closed_at).toLocaleDateString()}{" "}
                    {new Date(item.original.closed_at).toLocaleTimeString()}
                  </Text>
                )}
                {item.original.logged_out_at && (
                  <Text c="dimmed" size="sm">
                    Logged out at:{" "}
                    {new Date(item.original.logged_out_at).toLocaleDateString()}{" "}
                    {new Date(item.original.logged_out_at).toLocaleTimeString()}
                  </Text>
                )}
              </Flex>
            </Group>
          </Card>
        )}
      />
    </div>
  );
}

function getStartDate(daysToSubtract) {
  return dayjs()
    .subtract(daysToSubtract, "day")
    .hour(0)
    .minute(0)
    .second(1)
    .format("YYYY-MM-DDTHH:mm:ss");
}
