import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Anchor,
  Card,
  Flex,
  Loader,
  Pagination,
  Table,
  TextInput,
  Title,
} from "@mantine/core";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";

export default function PostmarkServerStreamList({ serverId, apiToken }) {
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  if (!apiToken) return null;

  const columns = useMemo(
    () => [
      {
        header: "Message Stream",
        accessorKey: "name",
        enableSorting: false,
        id: "name",
        cell: ({ row }) => {
          return (
            <Anchor
              component={Link}
              c="dark"
              fw={600}
              to={`/postmark/${serverId}/streams/${row.original.ID}`}
            >
              {row.original.Name}
            </Anchor>
          );
        },
      },
      {
        header: "Type",
        accessorKey: "MessageStreamType",
      },
    ],
    []
  );

  const table = useReactTable({
    data: items,
    columns,
    manualPagination: true,
    enableMultiSort: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(table.getState().pagination), refresh, searchValue]);

  function fetchData() {
    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      page_size: table.getState().pagination.pageSize,
      page_count:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
      value: searchValue,
      server_id: serverId,
      server_token: apiToken,
    };

    axios
      .post(`/postmark/servers/${serverId}/message-streams/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setItems(
          data.response[0].MessageStreams.sort((a, b) =>
            a.MessageStreamType > b.MessageStreamType ? 1 : -1
          )
        );
        if (data.response[0].TotalCount !== undefined) {
          setTotal(data.response[0].TotalCount);
          setPageCount(
            Math.round(
              data.response[0].TotalCount / table.getState().pagination.pageSize
            )
          );
        } else {
          setTotal(data.response[0].Servers.length);
        }
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  return (
    <div>
      <Flex gap="xs" align="center" mb="sm">
        <Title order={4}>Message Streams</Title>
        {loading && <Loader size="xs" />}
      </Flex>
      {/* <TextInput
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Jump to a message stream..."
      /> */}
      <Card mt="sm">
        <Table>
          <Table.Thead>
            <Table.Tr>
              {table.getFlatHeaders().map((header) => (
                <Table.Th key={header.id}>
                  {header.column.columnDef.header}
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Table.Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Td>
                    );
                  })}
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      </Card>
      <Pagination
        value={table.getState().pagination.pageIndex + 1}
        onChange={(e) => {
          table.setPageIndex(e - 1);
        }}
        total={pageCount}
        mt="lg"
      />
    </div>
  );
}
