import React, { useState } from "react";
import { Card, Title, TextInput } from "@mantine/core";

import { LootItemForm, LootItemList } from "./";
import { ManagementLayout } from "@components/shared";

export default function LootLockerManagement({ locationId, orgId }) {
  const [success, setSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const reqInfo = {
    refresh,
    search_value: searchValue,
  };

  if (orgId) reqInfo.organization_id = orgId;
  if (locationId) reqInfo.location_id = locationId;

  return (
    <ManagementLayout
      dataComponent={
        <React.Fragment>
          <TextInput
            mb="lg"
            size="lg"
            placeholder="Search for a loot item..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <LootItemList
            requestInfo={{
              url: `/retrieve-loot-locker/`,
              data: reqInfo,
            }}
          />
        </React.Fragment>
      }
      createComponent={
        <Card>
          <Title order={4}>Create loot item</Title>
          {!success && (
            <LootItemForm
              onSuccess={() => {
                setSuccess(!success);
                setRefresh(!refresh);
                setTimeout(() => {
                  setSuccess(false);
                }, 1);
              }}
              orgId={orgId}
              locationId={locationId}
            />
          )}
        </Card>
      }
    />
  );
}
