export const contestWizardSettings = {
  contest_keyword_entries_allowed_required: false,
  contest_keyword_use_total_required: false,
  contest_keyword_value_required: false,
  dates_required: true,
  entry_cost_required: false,
  featured_image_required: true,
  initial_entry_setting_required: false,
  prize_required: true,
  max_bid_increment_required: false,
  min_starting_bid_required: false,
  total_entries_allowed_required: false,
};
