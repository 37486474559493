import React from "react";
import { Box, Flex, SegmentedControl, Text, Radio } from "@mantine/core";
import { shallow } from "zustand/shallow";
import { IconDeviceDesktopAnalytics } from "@tabler/icons-react";

import useAppStore from "./report-recipe-runner-store";

export default function ReportRecipeOptions() {
  const { queryData, recipeInfo, setQueryData } = useAppStore(
    (state) => ({
      queryData: state.query_data,
      recipeInfo: state.recipe_info,
      setQueryData: state.setQueryData,
    }),
    shallow
  );

  const options = [
    {
      title: "How would you like this data displayed?",
      name: "visualizer",
      items: recipeInfo.visualizer_options,
      radio: true,
    },
  ];

  return (
    <Box mb="lg">
      {options.map((option, i) => (
        <div key={i}>
          <Flex gap="xs" mb="xs">
            <IconDeviceDesktopAnalytics />
            <Text size="sm" fw={600}>
              {option.title}
            </Text>
          </Flex>
          {option.items.length !== 1 ? (
            <SegmentedControl
              value={`${queryData[option.name]}`}
              // color="blue"
              data={option.items.map((item) => ({
                label: item.label,
                value: `${item.value}`,
              }))}
              onChange={(e) =>
                setQueryData({
                  ...queryData,
                  [option.name]: parseInt(e),
                })
              }
            />
          ) : (
            // <Flex gap="md">
            //   {option.items.map((item, ii) => (
            //     <Radio
            //       key={ii}
            //       name={option.name}
            //       label={item.label}
            //       checked={queryData[option.name] === item.value}
            //       onChange={() =>
            //         setQueryData({
            //           ...queryData,
            //           [option.name]: item.value,
            //         })
            //       }
            //     />
            //   ))}
            // </Flex>
            <Text>
              This data will be visualized in a <b>{option.items[0].label}</b>.
            </Text>
          )}
        </div>
      ))}
    </Box>
  );
}
