import React from "react";
import { Group, Text } from "@mantine/core";
import { IconHourglassEmpty } from "@tabler/icons-react";

import { getRelativeTime } from "@util/relativeTime";

const Countdown = ({ startDate = null, endDate = null }) => {
  return null;
  return (
    <Group spacing="xs">
      <IconHourglassEmpty size={16} />
      <Text size="sm">
        {getRelativeTime({
          start: startDate,
          end: endDate,
        })}
      </Text>
    </Group>
  );
};

export default Countdown;
