import React, { useState } from "react";
import { Button, Divider, Modal, Text, Textarea } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { IconLock } from "@tabler/icons-react";

const defaultFormValues = {
  comment: "",
  variety: 2,
};

export default function UserLockCreate({ userId, onSuccess }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(defaultFormValues);

  function onClose() {
    if (loading) return;
    setOpen(false);
    setFormValues(defaultFormValues);
    setLoading(false);
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      ...formValues,
      user_id: userId,
    };

    axios
      .post(`/users/lock/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Locked!");
      })
      .then(() => {
        onClose();
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Button
        leftSection={<IconLock />}
        variant="light"
        size="xs"
        color="red"
        onClick={() => setOpen(true)}
      >
        Lock User
      </Button>
      <Modal opened={isOpen} onClose={onClose} title="Add user lock">
        <Text>
          You are about to add a lock to this user's account. Give a reason why.
        </Text>
        <Divider mt="lg" mb="lg" />
        <Textarea
          label="Because:"
          value={formValues.comment}
          onChange={(e) => {
            setFormValues({
              ...formValues,
              comment: e.target.value,
            });
          }}
        />
        <Button
          fullWidth
          onClick={onSubmit}
          loading={loading}
          disabled={!formValues.comment}
          mt="sm"
        >
          Submit
        </Button>
      </Modal>
    </div>
  );
}
