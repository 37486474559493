import React, { useState } from "react";
import { Flex, Card } from "@mantine/core";

import {
  BulkLocationDownload,
  LocationForm,
  LocationList,
} from "@components/Location";
import { ManagementLayout, Header } from "@components/shared";

export default function LocationIndex() {
  const [refresh, setRefresh] = useState(false);

  return (
    <ManagementLayout
      dataComponent={<LocationList isIndex refresh={refresh} />}
      createComponent={
        <Card
          style={{
            overflow: "visible",
          }}
        >
          <Header>Create Location</Header>
          <LocationForm onSuccess={() => setRefresh(!refresh)} />
          <Flex justify="center" mt="lg">
            <BulkLocationDownload
              downloadReqData={{
                context: "location_index",
              }}
            />
          </Flex>
        </Card>
      }
    />
  );
}
