import { useState, useEffect, useRef } from "react";
import {
  PillsInput,
  Pill,
  Combobox,
  CheckIcon,
  Group,
  useCombobox,
  TagsInput,
  Flex,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { IconTag } from "@tabler/icons-react";

export default function MultiSelectCreatable({ reqInfo }) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(true);

  const valueLabels = value.map((m) => m.label);
  const exactOptionMatch = data.some((item) => item === search);

  const handleValueSelect = (val) => {
    if (val === "$create") {
      // setData((current) => [...current, search]);
      // setValue((current) => [...current, search]);
      // setSearch("");
      setSearch("");
      onTagCreate(val);
    } else {
      // setValue((current) =>
      //   current.includes(val)
      //     ? current.filter((v) => v !== val)
      //     : [...current, val]
      // );
      onTagAdd(val);
    }
  };

  const handleValueRemove = (val) => onTagRemove(val);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    if (!search) return;
    searchTags();
  }, [search]);

  useEffect(() => {
    fetchTags();
  }, []);

  function searchTags() {
    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      ...reqInfo,
      value: search,
    };

    axios
      .post(`/search-all-tags/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setData(
          data.response.map((d) => ({
            label: d.name,
            value: d.id,
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setData([]);
      });
  }

  function onTagAdd(e) {
    const req = {
      ...reqInfo,
      tag_id: e,
    };

    setLoading(true);
    axios
      .post(`/tags/`, req)
      .then(() => {
        toast.success("Added!");
        fetchTags();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onTagCreate() {
    const req = {
      ...reqInfo,
      name: search,
    };

    setLoading(true);
    axios
      .post(`/tags/`, req)
      .then(() => {
        toast.success("Added!");
        fetchTags();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onTagRemove(taggingId) {
    setLoading(true);
    axios
      .post(`/delete-tagging/`, { tagging_id: taggingId })
      .then(() => {
        fetchTags();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function fetchTags() {
    setLoading(true);

    axios
      .post(`/retrieve-entity-tags/`, reqInfo)
      .then(({ data }) => {
        setLoading(false);
        setValue(
          data.response.map((m) => ({
            label: m.tag.name,
            value: m.id,
          }))
        );
      })
      .catch((err) => {
        setLoading(false);
        setValue([]);
      });
  }

  const values = value.map((item) => (
    <Pill
      key={item.value}
      withRemoveButton
      onRemove={() => handleValueRemove(item.value)}
    >
      {item.label}
    </Pill>
  ));

  const options = data
    .filter((item) =>
      item.label.toLowerCase().includes(search.trim().toLowerCase())
    )
    .map((item) => (
      <Combobox.Option
        value={item.value}
        key={item.value}
        active={value.includes(item.label)}
      >
        <Group gap="sm">
          {valueLabels.includes(item.label) ? <CheckIcon size={12} /> : null}
          <span>{item.label}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={false}
      mt="md"
      mb="md"
      label={
        <label>
          <Flex gap="xs">
            <IconTag color="var(--mantine-color-pink-5)" />
            <span>Tags</span>
          </Flex>
        </label>
      }
    >
      <Combobox.DropdownTarget>
        <PillsInput onClick={() => combobox.openDropdown()}>
          <Pill.Group>
            {values}
            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder="Search tags"
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace" && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options}
          {!exactOptionMatch && search.trim().length > 0 && (
            <Combobox.Option value="$create">+ Create {search}</Combobox.Option>
          )}

          {exactOptionMatch &&
            search.trim().length > 0 &&
            options.length === 0 && (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
