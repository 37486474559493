import React, { useState } from "react";
import { Group, Button } from "@mantine/core";

const tabs = [
  { text: "My Ratings", value: "ratings" },
  { text: "My Artists", value: "artists" },
].map((m, i) => ({
  ...m,
  key: i,
}));

import { UserArtists, UserRatings } from "./";

export default function UserMusic({ userLocationId, userOrgId }) {
  const [activeTab, setActiveTab] = useState("ratings");

  return (
    <div>
      <Group grow mb="lg">
        {tabs.map((m) => (
          <Button
            key={m.key}
            onClick={() => setActiveTab(m.value)}
            variant={activeTab === m.value ? "filled" : "light"}
          >
            {m.text}
          </Button>
        ))}
      </Group>
      {activeTab === "ratings" && (
        <UserRatings userLocationId={userLocationId} userOrgId={userOrgId} />
      )}
      {activeTab === "artists" && (
        <UserArtists userLocationId={userLocationId} userOrgId={userOrgId} />
      )}
    </div>
  );
}
