import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useMantineTheme } from "@mantine/core";
import { useSelector } from "react-redux";
import {
  Alert,
  Group,
  Loader,
  Button,
  Select,
  Text,
  Grid,
  Pagination,
  Card,
  Badge,
  Flex,
  TextInput,
  rem,
} from "@mantine/core";
import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { IconQuestionMark } from "@tabler/icons-react";

import { ReportRecipeVizIcon } from "./";

import { tableCategories } from "./helpers";

const tableCategoryConfig = tableCategories.reduce((acc, cur) => {
  acc[cur.value] = {
    color: cur.color,
    text: cur.text,
  };
  return acc;
}, {});

const vizOptions = [
  { text: "pie", value: "1" },
  { text: "line", value: "2" },
  { text: "bar", value: "3" },
  { text: "table", value: "4" },
  { text: "map", value: "5" },
];

const adminCategories = [
  { text: "system", value: "1" },
  { text: "location", value: "2" },
  { text: "organization", value: "3" },
];

const actionCategories = [
  { text: "registrations", value: "1" },
  { text: "keyword claim", value: "2" },
  { text: "checkin", value: "3" },
];

export default function ReportRecipeList() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [init, setInit] = useState(false);
  const [filters, setFilters] = useState({
    admin_category: [],
    table_category: [],
    action_category: [],
    search_value: "",
    visualization: [],
  });

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const theme = useMantineTheme();
  const isAdmin = useSelector((state) => state.admin) ? true : false;

  const table = useReactTable({
    manualPagination: true,
    data: items,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  useEffect(() => {
    resetTablePage().then(() => {
      fetchData();
    });
  }, [JSON.stringify(filters)]);

  async function resetTablePage() {
    try {
      table.setPageIndex(0);
    } catch (error) {}
  }

  function fetchData() {
    setError(null);

    const req = {
      action_categories: filters.action_category.map((m) => parseInt(m)),
      admin_categories: filters.admin_category.map((m) => parseInt(m)),
      table_categories: filters.table_category.map((m) => parseInt(m)),
      visualization: filters.visualization.map((m) => parseInt(m)),
      search_value: filters.search_value,
      status: 1,
      page_size: table.getState().pagination.pageSize || 10,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
    };

    if ([null, undefined].includes(req.page) || !req.page) {
      req.page = 0;
    }

    const cancel = cancelRef.current;
    if (cancel) cancel();

    setLoading(true);

    axios
      .post(`/retrieve-recipes/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setItems(data.response[0].data);
        setPageCount(data.response[0].page_count);
        if (data.response[0].total !== undefined) {
          setTotal(data.response[0].total);
        } else {
          setTotal(data.response[0].data.length);
        }
      })
      .then(() => {
        setInit(true);
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        setLoading(false);
        setInit(true);
      });
  }

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(table.getState().pagination)]);

  return (
    <div style={{ marginTop: "0.5em" }}>
      <TextInput
        placeholder="Search for a recipe..."
        size="lg"
        value={filters.search_value}
        onChange={(e) =>
          setFilters({
            ...filters,
            search_value: e.target.value,
          })
        }
        mb="sm"
      />
      <ListFilter
        options={tableCategories}
        onChange={(e) => {
          setFilters({
            ...filters,
            table_category: filters.table_category.includes(e)
              ? [...filters.table_category].filter((f) => f !== e)
              : [...filters.table_category, e],
          });
        }}
        onClearClick={() =>
          setFilters({
            ...filters,
            table_category: [],
          })
        }
        value={filters.table_category}
      />
      <ListFilter
        options={vizOptions}
        onChange={(e) => {
          setFilters({
            ...filters,
            visualization: filters.visualization.includes(e)
              ? [...filters.visualization].filter((f) => f !== e)
              : [...filters.visualization, e],
          });
        }}
        onClearClick={() =>
          setFilters({
            ...filters,
            visualization: [],
          })
        }
        value={filters.visualization}
      />
      <ListFilter
        options={actionCategories}
        onChange={(e) => {
          setFilters({
            ...filters,
            action_category: filters.action_category.includes(e)
              ? [...filters.action_category].filter((f) => f !== e)
              : [...filters.action_category, e],
          });
        }}
        onClearClick={() =>
          setFilters({
            ...filters,
            action_category: [],
          })
        }
        value={filters.action_category}
      />
      {isAdmin && (
        <ListFilter
          options={adminCategories}
          onChange={(e) => {
            setFilters({
              ...filters,
              admin_category: filters.admin_category.includes(e)
                ? [...filters.admin_category].filter((f) => f !== e)
                : [...filters.admin_category, e],
            });
          }}
          onClearClick={() =>
            setFilters({
              ...filters,
              admin_category: [],
            })
          }
          value={filters.admin_category}
        />
      )}
      {!loading && total === 0 && !error && (
        <Grid>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <Card shadow="xs">
              <Card.Section
                style={{
                  background: `var(--mantine-color-yellow-1)`,
                  minHeight: "150px",
                  display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  flexDirection: "column",
                }}
                p="lg"
                mb="sm"
              >
                <Flex gap="sm" align="center">
                  <IconQuestionMark
                    size={64}
                    color="var(--mantine-color-yellow-3)"
                  />
                </Flex>
                <Text
                  size="xl"
                  c={`var(--mantine-color-yellow-8)`}
                  fw={900}
                  mt="sm"
                >
                  No results matching your selections
                </Text>
              </Card.Section>
              <Text>Try adjusting your search parameters</Text>
            </Card>
          </Grid.Col>
        </Grid>
      )}
      {error && !loading && init && (
        <Alert variant="light" color="yellow" title="Oops!">
          {error}
        </Alert>
      )}
      <Grid justify="flex-start" align="stretch">
        {table.getRowModel().rows.map((item) => {
          const baseColor = item.original.table_categories.length
            ? tableCategoryConfig[`${item.original.table_categories[0]}`].color
            : "blue";
          return (
            <Grid.Col key={item.original.id} span={{ base: 12, md: 4 }}>
              <Card
                shadow="xs"
                component={Link}
                to={`/report-recipes/${item.original.id}`}
                style={{ minHeight: rem(300) }}
              >
                <Card.Section
                  style={{
                    background: `var(--mantine-color-${baseColor}-1)`,
                    minHeight: "150px",
                    display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    flexDirection: "column",
                  }}
                  p="lg"
                  mb="sm"
                >
                  <Flex gap="sm" align="center">
                    {item.original.visualizers_formatted.map((m, i) => {
                      const iconProps = {
                        size: 64,
                        color: `var(--mantine-color-${baseColor}-3)`,
                      };
                      return (
                        <ReportRecipeVizIcon
                          vizName={m}
                          iconProps={iconProps}
                        />
                      );
                    })}
                  </Flex>
                  <Text
                    size="xl"
                    c={`var(--mantine-color-${baseColor}-8)`}
                    fw={900}
                    mt="sm"
                  >
                    {item.original.title}
                  </Text>
                </Card.Section>
                {/* <Divider mt="lg" mb="lg" /> */}
                <Flex gap="sm" align="center">
                  {/* <div>{entityIcon.recipe(24)}</div> */}
                  <div style={{ flexGrow: 1 }}>
                    {/* <Text fw={700}>{item.original.title}</Text> */}
                    {item.original.description && (
                      <Text size="sm">{item.original.description}</Text>
                    )}
                    <Text size="xs" fw={600} mt="lg">
                      Providing insights into:
                    </Text>
                    <Flex gap="xs" mt="xs" wrap="wrap">
                      {item.original.table_categories_formatted.map((m, i) => (
                        <Badge size="xs" key={i} color="gray" variant="light">
                          {m}
                        </Badge>
                      ))}
                      {item.original.action_categories_formatted.map((m, i) => (
                        <Badge size="xs" key={i} color="gray" variant="light">
                          {m}
                        </Badge>
                      ))}
                      {isAdmin &&
                        item.original.admin_categories_formatted?.length >
                          0 && (
                          <>
                            {item.original.admin_categories_formatted.map(
                              (m, i) => (
                                <Badge
                                  size="xs"
                                  key={i}
                                  color="gray"
                                  variant="light"
                                >
                                  {m}
                                </Badge>
                              )
                            )}
                          </>
                        )}
                    </Flex>
                  </div>
                </Flex>
              </Card>
            </Grid.Col>
          );
        })}
      </Grid>
      <Group mt="lg">
        <Pagination
          total={pageCount}
          onChange={(e) => table.setPageIndex(e - 1)}
          size="lg"
          disabled={loading}
          value={table.getState().pagination.pageIndex + 1}
        />
        <Select
          size="sm"
          style={{ width: "110px" }}
          value={`${table.getState().pagination.pageSize}`}
          onChange={(e) => table.setPageSize(parseInt(e))}
          data={[5, 10, 20, 30, 40, 50].map((pageSize) => ({
            label: `${pageSize} items`,
            value: `${pageSize}`,
            placeholder: "Select a pagesize",
          }))}
        />
        {loading && <Loader size="xs" variant="dots" />}
      </Group>
      <Text mb="lg" size="sm" mt="sm">
        {total} total item{total === 1 ? "" : "s"}
      </Text>
    </div>
  );
}

const ListFilter = ({ options, onChange, value, onClearClick }) => {
  return (
    <Flex gap="xs" mt="sm" mb="lg" direction={{ base: "column", md: "row" }}>
      {options.map((m, i) => (
        <Button
          // disabled={loading}
          onClick={() => onChange(m.value)}
          color={value.includes(m.value) ? m.color : "gray"}
          variant={value.includes(m.value) ? "" : "light"}
          key={i}
          size="xs"
          radius="xl"
        >
          {m.text}
        </Button>
      ))}
      <Button
        onClick={onClearClick}
        size="xs"
        radius="xl"
        variant="subtle"
        color="gray"
      >
        clear
      </Button>
    </Flex>
  );
};
