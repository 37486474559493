import React, { useState, useEffect } from "react";
import { Badge, Grid, Text, Title, Divider, Box } from "@mantine/core";
import axios from "axios";

import { ContestFinalistMeta, ContestPerson } from "./";

import {
  DemotedEntrants,
  DrawFinalistsButton,
  DrawWinnerButton,
  NotifyWinnerButton,
  StatusButton,
} from "./ContestPrizeButtons";

import { PrizeCard } from "@components/Prize";

export default function ContestFinalists({
  contestId,
  contestSettings,
  fetchData,
  totalEntrants = 0,
}) {
  const [prizes, setPrizes] = useState([]);
  const [loading, setLoading] = useState(true);

  if (!contestSettings) return null;

  const { winner_rules: winnerRules } = contestSettings;

  useEffect(() => {
    fetchPrizes();
  }, []);

  function fetchPrizes() {
    setLoading(true);
    axios
      .get(`/contests/${contestId}/retrieve-prizes/`)
      .then(({ data }) => {
        setPrizes(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setPrizes([]);
        setLoading(false);
      });
  }

  return (
    <div>
      {winnerRules && (
        <Box mb="sm">
          <ContestFinalistMeta
            numberOfFinalists={winnerRules.number_of_finalists}
            onePrizePerWinner={winnerRules.one_prize_per_winner}
            totalEntrants={totalEntrants}
            contestId={contestId}
            fetchData={fetchData}
          />
        </Box>
      )}
      <Grid>
        {prizes.map((p) => (
          <Grid.Col span={{ base: 12, md: 6 }} key={p.id}>
            <Prize
              key={p.id}
              contestId={contestId}
              prizeData={p}
              id={p.id}
              fetchData={fetchPrizes}
            />
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
}

const Prize = ({ contestId, prizeData, id, fetchData }) => {
  const showSettings =
    prizeData.settings && JSON.stringify(prizeData.settings) !== "{}"
      ? true
      : false;

  const finalistsEnabled =
    showSettings && prizeData.settings.finalists ? true : false;

  const showActionDivider =
    prizeData.demoted_entrants.length > 0 ||
    (!finalistsEnabled &&
      prizeData.eligible_entrants_remaining > 0 &&
      !prizeData.winner) ||
    prizeData.winner ||
    (prizeData.eligible_entrants_remaining === 0 && !finalistsEnabled) ||
    (finalistsEnabled && prizeData.settings.draw_winner_from_finalists) ||
    (finalistsEnabled &&
      !prizeData.settings.draw_winner_from_finalists &&
      prizeData.finalists.length === 0);

  return (
    <PrizeCard mb="sm" prizeData={prizeData} showEntrants>
      {prizeData.finalists && prizeData.finalists.length > 0 && (
        <React.Fragment>
          <Divider mt="sm" mb="sm" />
          <Title order={4}>Finalists ({prizeData.finalists.length})</Title>
          <Box
            mt="xs"
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Grid mb="lg">
              {prizeData.finalists.map((f) => (
                <Grid.Col span={{ base: 12 }} key={f.entry_id}>
                  <ContestPerson
                    name={f.name}
                    email={f.email}
                    phone={f.phone}
                    locationName={f.location_name}
                  />
                  {!prizeData.winner && (
                    <Box mt="sm">
                      {prizeData.eligible_entrants_remaining !== 0 && (
                        <DrawFinalistsButton
                          contestId={contestId}
                          prizeId={id}
                          onSuccess={fetchData}
                          isRedraw
                          entryId={f.entry_id}
                        />
                      )}
                      <StatusButton
                        contestId={contestId}
                        prizeId={id}
                        onSuccess={fetchData}
                        buttonText="mark ineligible"
                        reqData={{
                          contest_entry_id: f.entry_id,
                          ineligible: true,
                        }}
                      />
                      <StatusButton
                        contestId={contestId}
                        onSuccess={fetchData}
                        buttonText="remove"
                        reqData={{
                          contest_entry_id: f.entry_id,
                          demote: true,
                        }}
                      />
                      {finalistsEnabled &&
                        !prizeData.settings.draw_winner_from_finalists && (
                          <StatusButton
                            contestId={contestId}
                            onSuccess={fetchData}
                            buttonText="mark winner"
                            reqData={{
                              contest_entry_id: f.entry_id,
                              assign_winner: true,
                            }}
                          />
                        )}
                    </Box>
                  )}
                </Grid.Col>
              ))}
            </Grid>
          </Box>
        </React.Fragment>
      )}
      {prizeData.winner && (
        <React.Fragment>
          <Divider mt="sm" mb="sm" />
          <Box mt="lg" mb="xl">
            <ContestPerson
              name={prizeData.winner.name}
              phone={prizeData.winner.phone}
              email={prizeData.winner.email}
              locationName={prizeData.winner.location_name}
              badge={
                <Badge variant="light" size="sm" color="green">
                  winner
                </Badge>
              }
            />
          </Box>
        </React.Fragment>
      )}
      <div>
        {showActionDivider && <Divider mt="sm" mb="sm" />}
        {!prizeData.winner && (
          <React.Fragment>
            {finalistsEnabled ? (
              <React.Fragment>
                {prizeData.can_draw_finalists && (
                  <DrawFinalistsButton
                    contestId={contestId}
                    prizeId={id}
                    onSuccess={fetchData}
                  />
                )}
                {prizeData.settings.draw_winner_from_finalists &&
                  prizeData.can_draw_winner && (
                    <DrawWinnerButton
                      contestId={contestId}
                      prizeId={id}
                      onSuccess={fetchData}
                    />
                  )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {prizeData.can_draw_winner ? (
                  <DrawWinnerButton
                    contestId={contestId}
                    prizeId={id}
                    onSuccess={fetchData}
                  />
                ) : (
                  <Text
                    color="red"
                    fw={600}
                    mb={prizeData.demoted_entrants.length > 0 ? "sm" : 0}
                  >
                    no entries remaining to draw from
                  </Text>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {prizeData.winner && (
          <React.Fragment>
            <NotifyWinnerButton
              contestId={contestId}
              entryId={prizeData.winner.entry_id}
              prizeId={id}
              onSuccess={fetchData}
            />
            <StatusButton
              contestId={contestId}
              prizeId={id}
              onSuccess={fetchData}
              buttonText="mark ineligible"
              reqData={{
                contest_entry_id: prizeData.winner.entry_id,
                ineligible: true,
              }}
            />
            <StatusButton
              contestId={contestId}
              onSuccess={fetchData}
              buttonText="remove"
              reqData={{
                contest_entry_id: prizeData.winner.entry_id,
                demote: true,
              }}
            />
          </React.Fragment>
        )}
        <DemotedEntrants
          prizeHasWinner={prizeData.winner ? true : false}
          contestId={contestId}
          fetchData={fetchData}
          items={prizeData.demoted_entrants}
        />
      </div>
    </PrizeCard>
  );
};
