import React, { useState, useEffect } from "react";
import { Card, Title } from "@mantine/core";

import { ManagerList, ManagerAssociate } from "./";
import { ManagementLayout } from "@components/shared";

export default function ManagerManagement({ organizationId, locationId }) {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const reqData = {};

  if (organizationId) reqData.organization_id = organizationId;
  if (locationId) reqData.location_id = locationId;

  return (
    <ManagementLayout
      createComponent={
        <Card>
          <Title order={4}>Add Manager</Title>
          <ManagerAssociate
            organizationId={organizationId}
            locationId={locationId}
            onSuccess={() => setRefresh(!refresh)}
          />
        </Card>
      }
      dataComponent={
        <ManagerList
          loading={loading}
          organizationId={organizationId}
          locationId={locationId}
          refresh={refresh}
          requestInfo={{
            url: `/retrieve-managers/`,
            data: reqData,
          }}
        />
      }
    />
  );
}
