import React from "react";
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";

highchartsMore(Highcharts);
solidGauge(Highcharts);

export default function Gauge({ value = 0, maxValue = 200 }) {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          height: "280px",
          backgroundColor: "transparent",
          type: "solidgauge",
          style: {
            fontFamily: "inherit",
            backgroundColor: "transparent",
          },
        },

        title: null,

        pane: {
          center: ["50%", "80%"],
          size: "130%",
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: "transparent",
            borderRadius: 6,
            innerRadius: "60%",
            outerRadius: "100%",
            shape: "arc",
            borderColor: "var(--mantine-color-dark-5)",
          },
        },

        exporting: {
          enabled: false,
        },

        tooltip: {
          enabled: false,
        },

        // the value axis
        yAxis: {
          stops: [
            [0.1, "#55BF3B"], // green
            [0.5, "#DDDF0D"], // yellow
            [0.9, "#DF5353"], // red
          ],
          minorTicks: false,
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 0,
          tickLength: 0,
          labels: {
            enabled: false,
          },
        },

        plotOptions: {
          solidgauge: {
            borderRadius: 6,
            color: "#000",
          },
        },
        yAxis: {
          stops: [
            [0.1, "var(--mantine-color-green-5)"], // green
            [0.5, "var(--mantine-color-green-5)"], // yellow
            [0.9, "var(--mantine-color-green-5)"], // red
          ],
          minorTicks: false,
          min: 0,
          lineWidth: 0,
          tickWidth: 0,
          tickAmount: 0,
          tickLength: 0,
          max: maxValue,
          title: {
            text: "",
          },
          labels: {
            enabled: false,
          },
        },

        credits: {
          enabled: false,
        },

        series: [
          {
            name: "",
            data: [value],
            dataLabels: {
              enabled: false,
            },
            // dataLabels: {
            //   format:
            //     '<div style="text-align:center">' +
            //     '<span style="font-size:25px">{y}</span><br/>' +
            //     '<span style="font-size:12px;opacity:0.4">km/h</span>' +
            //     "</div>",
            // },
            // tooltip: {
            //   valueSuffix: " km/h",
            // },
          },
        ],
      }}
    />
  );
}
