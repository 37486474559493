import React, { useContext, useState, useEffect } from "react";
import { Button, Grid, Group, Space } from "@mantine/core";

import Intervals from "./Intervals";
import { Counter } from "@components/shared";
import { Context } from "./";

export default function Checkins({ beginDate, endDate, onUpdate }) {
  const { check_ins: checkinData } = useContext(Context);
  const { intervals } = checkinData;

  const counterFields = [
    {
      title: "Value",
      keyName: "check_in_value",
      unlimited: false,
      max: 70,
    },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
    value: checkinData[m.keyName],
  }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            value={c.value}
            unlimited={c.unlimited}
            maxValue={c.max}
            onChange={(e) =>
              onUpdate({
                [c.keyName]: e,
              })
            }
          />
          <h3 style={{ margin: "0" }}>{c.title}</h3>
        </Group>
      ))}
      <Space mt="sm" />
      <Intervals
        beginDate={beginDate}
        endDate={endDate}
        items={intervals}
        title="Check-In Interval"
        onChange={(e) => onUpdate({ intervals: e })}
      />
    </div>
  );
}
