import React, { useRef, useState } from "react";
import axios from "axios";
import { Select } from "@mantine/core";
import { useTranslation } from "react-i18next";

import useAppStore from "./microsite-store";

export default function LocationSearch({ allLocations = false, onSelect }) {
  const [locations, setLocations] = useState([]);

  const campaignId = useAppStore((state) => state.campaignId);
  const effortId = useAppStore((state) => state.effortId);
  const entityInfo = useAppStore((state) => state.entityInfo);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const { t } = useTranslation();

  function onChange(loc) {
    const location = locations.find((d) => d.id === loc);
    if (!location) return;

    onSelect(location);
  }

  function fetchData(searchTerm) {
    const req = {
      text: searchTerm,
      campaign_id: campaignId,
      campaign_effort_id: effortId,
    };

    if (allLocations) {
      req.organizational = true;

      if (entityInfo.organization_id)
        req.organization_id = entityInfo.organization_id;
    }

    if (searchTerm.length < 3) {
      return setLocations([]);
    }

    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    axios
      .post(`/microsite/location-text-search/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLocations(data.response);
      })
      .catch((err) => {
        setLocations([]);
      });
  }

  const options = locations.map((d) => ({
    label: d.name,
    value: d.id,
  }));

  return (
    <Select
      label=""
      size="lg"
      placeholder={t("name_search_placeholder")}
      data={options}
      searchable
      onSearchChange={(e) => {
        fetchData(e);
      }}
      onChange={onChange}
    />
  );
}
