import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Flex,
  Group,
  SegmentedControl,
  Text,
  TextInput,
} from "@mantine/core";
import {
  IconCalendar,
  IconDeviceMobile,
  IconHeartbeat,
  IconInbox,
  IconMail,
  IconBell,
  IconMessage,
} from "@tabler/icons-react";

import { PaginatedList } from "@components/shared";
import entityIcon from "@util/entityIcon";

export default function BulkCommunicationList({
  requestInfo = {
    url: `/retrieve-bulk-communicators/`,
    data: {},
  },
  locationId,
  orgId,
}) {
  const [filters, setFilters] = useState({
    disposition: "everything",
    variety: "entire",
  });
  const [searchValue, setSearchValue] = useState("");

  return (
    <div>
      <TextInput
        placeholder="Search..."
        size="lg"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        mb="sm"
      />
      <Flex gap="sm" direction={{ base: "column", sm: "row" }} wrap="wrap">
        <SegmentedControl
          value={filters.variety}
          data={[
            { label: "All", value: "entire" },
            { label: "Email", value: "email" },
            { label: "Text", value: "text" },
            { label: "Alert", value: "alert" },
          ]}
          onChange={(e) =>
            setFilters({
              ...filters,
              variety: e,
            })
          }
        />
        <SegmentedControl
          value={filters.disposition}
          data={[
            { label: "All", value: "everything" },
            { label: "Waiting", value: "waiting" },
            { label: "Enqueued", value: "enqueued" },
            { label: "Scheduled", value: "scheduled" },
            { label: "In Progress", value: "in_progress" },
            { label: "Completed", value: "completed" },
          ]}
          onChange={(e) =>
            setFilters({
              ...filters,
              disposition: e,
            })
          }
        />
      </Flex>
      <PaginatedList
        requestInfo={{
          url: requestInfo.url,
          data: {
            ...requestInfo.data,
            ...filters,
            location_id: locationId,
            organization_id: orgId,
            search_value: searchValue,
          },
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/bulk-communication/${m.id}`,
          }))
        }
        displayFormatter={({ original }) => (
          <Item key={original.id} original={original} />
        )}
      />
    </div>
  );
}

const Item = ({ original }) => {
  return (
    <Card key={original.id}>
      <Flex gap="xs" align="center">
        <Text fw={700} component={Link} to={original.link_url}>
          {original.title}
        </Text>
      </Flex>
      <Group gap="xs" align="center">
        <div>
          {original.location_id
            ? entityIcon.location()
            : entityIcon.organization()}
        </div>
        <Text size="sm">
          {original.location_name
            ? original.location_name
            : original.organization_name}
        </Text>
      </Group>
      <Group gap="xs" align="center">
        <div>
          {original.variety === 1 && <IconMail size={16} />}
          {original.variety === 2 && <IconDeviceMobile size={16} />}
          {original.variety === 3 && <IconBell size={16} />}
        </div>
        <Text size="sm" style={{ textTransform: "capitalize" }}>
          {original.variety_formatted} Sender
        </Text>
      </Group>
      <Group gap="xs" align="center">
        <div>
          <IconHeartbeat size={16} />
        </div>
        <Text size="sm" style={{ textTransform: "capitalize" }}>
          {original.disposition_formatted}
        </Text>
      </Group>
      {original.scheduled_at && (
        <Group gap="xs">
          <div>
            <IconCalendar size={16} />
          </div>
          <Text size="sm">
            scheduled for {new Date(original.scheduled_at).toLocaleString()}
          </Text>
        </Group>
      )}
      {original.subject && (
        <Group gap="xs">
          <div>
            <IconInbox size={16} />
          </div>
          <Text size="sm">Subject: {original.subject}</Text>
        </Group>
      )}
      {original.bulk_communicator_messages_count > 0 && (
        <Group gap="xs">
          <div>
            <IconMessage size={16} />
          </div>
          <Text size="sm">
            {original.bulk_communicator_messages_count} message
            {original.bulk_communicator_messages_count === 1 ? "" : "s"}
          </Text>
        </Group>
      )}
    </Card>
  );
};
