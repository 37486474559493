import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mantine/core";
import { useSelector } from "react-redux";

import {
  ReportRecipeList,
  ReportRecipeEditList,
} from "@components/ReportRecipe";

export default function ReportRecipeIndex({ editing = false }) {
  const isAdmin = useSelector((state) => state.admin) ? true : false;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [editing]);

  return (
    <div>
      {editing ? <ReportRecipeEditList /> : <ReportRecipeList />}
      {isAdmin && (
        <>
          {!editing ? (
            <Button
              component={Link}
              to={`/all-report-recipes`}
              variant="subtle"
              size="xs"
              color="gray"
              p={0}
            >
              Edit recipes
            </Button>
          ) : (
            <Button
              component={Link}
              to={`/report-recipes`}
              variant="subtle"
              size="xs"
              color="gray"
              p={0}
            >
              View my recipes
            </Button>
          )}
        </>
      )}
    </div>
  );
}
