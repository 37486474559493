import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Toaster } from "react-hot-toast";

import { AuthLayout, PasswordForm } from "@components/Auth";
import { ManagerAcceptInvite } from "@components/Manager";
import UserConfirmEmail from "./UserLogin/UserConfirmEmail";
import { SharedReportView } from "@components/ReportRecipe";

export default function Code() {
  const [codeData, setCodeData] = useState(null);
  const [error, setError] = useState(null);

  const params = useParams();
  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/verify-code/${id}/`)
      .then(({ data }) => {
        setCodeData(data.response[0]);
      })
      .catch((err) => {
        setError(err);
      });
  }

  if (error) {
    return <h1>{error}</h1>;
  }

  if (!codeData) return <div>Loading...</div>;

  return (
    <React.Fragment>
      <Toaster />
      {codeData.variety === 1 && (
        <AuthLayout title={`Create a password for ${codeData.email}`}>
          <PasswordForm
            additionalReqData={{
              remote_code_id: codeData.id,
              admin_id: codeData.admin_id,
              manager_id: codeData.manager_id,
              user_id: codeData.user_id,
            }}
            onSuccess={() => {
              window.location = codeData.manager_id
                ? "/manager"
                : codeData.admin_id
                ? `/admin`
                : "/";
            }}
          />
        </AuthLayout>
      )}
      {codeData.variety === 2 && (
        <AuthLayout title={`Create a password for ${codeData.email}`}>
          <PasswordForm
            additionalReqData={{
              remote_code_id: codeData.id,
              admin_id: codeData.admin_id,
              user_id: codeData.user_id,
              manager_id: codeData.manager_id,
            }}
            onSuccess={() => {
              window.location = codeData.admin_id ? `/admin` : "/";
            }}
          />
        </AuthLayout>
      )}
      {codeData.variety === 7 && (
        <AuthLayout>
          <ManagerAcceptInvite
            remoteCodeId={codeData.id}
            entityName={codeData.entity_name}
          />
        </AuthLayout>
      )}
      {codeData.variety === 10 && (
        <UserConfirmEmail
          locationSettings={
            codeData.settings ? codeData.settings.settings : null
          }
          logoUrl={codeData.logo_url}
          remoteCodeId={codeData.id}
          codeData={codeData}
        />
      )}
      {codeData.variety === 22 && <SharedReportView codeData={codeData} />}
    </React.Fragment>
  );
}
