import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  Button,
  Modal,
  TextInput,
  Divider,
  Box,
  Table,
  Text,
  Menu,
  Flex,
  ActionIcon,
  UnstyledButton,
} from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import styled from "styled-components";

import { BasicForm } from "@components/shared";
import { phoneValidation } from "@util/validation";
import { CodeInput } from "@components/Auth";

export default function PhoneManagement({
  adminId,
  email,
  fetchEntity,
  managerId,
  mfaEnabled,
  showMfa = true,
}) {
  const [loading, setLoading] = useState(true);
  const [phones, setPhones] = useState([]);
  const [addPhone, setAddPhone] = useState(false);

  const mfaPresent = phones.find((f) => f.mfa_enabled) ? true : false;
  const primaryPhone = phones.find((f) => f.primary);

  useEffect(() => {
    fetchPhones();
  }, []);

  function fetchPhones() {
    const req = {};

    setLoading(true);

    if (adminId) req.admin_id = adminId;
    if (managerId) req.manager_id = managerId;

    axios
      .post(`/retrieve-phones/`, req)
      .then(({ data }) => {
        setPhones(data.response.sort((a, b) => b.primary - a.primary));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setPhones([]);
      });
  }

  return (
    <div>
      <Modal opened={addPhone} onClose={() => setAddPhone(false)}>
        <PhoneForm
          adminId={adminId}
          managerId={managerId}
          onSuccess={() => {
            fetchPhones();
            setAddPhone(false);
          }}
        />
      </Modal>
      {primaryPhone && showMfa && (
        <MfaToggle
          mfaEnabled={mfaEnabled}
          id={primaryPhone.id}
          email={email}
          number={primaryPhone.number_formatted}
          fetchData={() => {
            fetchEntity();
          }}
        />
      )}
      <Table striped>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Number</Table.Th>
            <Table.Th>Primary</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {phones.map((phone) => (
            <PhoneItem
              key={phone.id}
              id={phone.id}
              number={phone.number_formatted}
              primary={phone.primary}
              fetchData={fetchPhones}
              mfaEnabled={phone.mfa_enabled}
              mfaPresent={mfaPresent}
              showRemove={phones.length > 1}
            />
          ))}
          <Table.Tr>
            <Table.Td>
              <Button
                mt="xs"
                size="xs"
                variant="light"
                onClick={() => setAddPhone(true)}
              >
                Add a new phone
              </Button>
            </Table.Td>
            <Table.Td></Table.Td>
            <Table.Td></Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
      {phones.length > 0 && (
        <>
          <Divider mt="lg" mb="lg" />
          <Text size="sm">
            *Only a primary phone number may be used as an MFA device.
          </Text>
          {mfaPresent && (
            <Text size="sm">
              **To change MFA devices, disable MFA on the primary phone number
              first.
            </Text>
          )}
        </>
      )}
    </div>
  );
}

function PhoneForm({ id, adminId, managerId, phoneValue, onSuccess }) {
  const [loading, setLoading] = useState(false);

  const fields = [
    {
      name: "number",
      label: "Mobile Phone",
      initialValue: phoneValue,
      required: true,
      isPhone: true,
      schema: () =>
        Yup.string()
          .matches(phoneValidation, "Not a valid phone")
          .required("Required!"),
    },
  ];

  function onSubmit(formData) {
    setLoading(true);

    const req = {
      ...formData,
    };

    if (adminId) req.admin_id = adminId;
    if (managerId) req.manager_id = managerId;

    if (id) return onUpdate(req);

    return onCreate(req);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
    };

    axios
      .post(`/phones/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        toast.success("Phone added!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      id,
    };

    axios
      .put(`/phones/${id}/`, req)
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  // return <BasicForm fields={fields} loading={loading} onSubmit={onSubmit} />;

  return (
    <BasicForm
      fields={fields}
      onSubmit={onSubmit}
      loading={loading}
      buttonProps={{
        fullWidth: true,
      }}
    />
  );
}

const PhoneItem = ({
  id,
  number,
  primary,
  mfaEnabled,
  mfaPresent,
  fetchData,
  showRemove,
}) => {
  function onDeleteClick() {
    axios
      .delete(`/phones/${id}/`)
      .then(() => {
        toast.success("Deleted!");
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        fetchData();
      });
  }

  function onPrimaryClick() {
    axios
      .post(`/phones/${id}/mark-primary/`)
      .then(() => {
        toast.success("New primary phone!");
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        fetchData();
      });
  }

  return (
    <Table.Tr>
      <Table.Td>{number}</Table.Td>
      <Table.Td>{primary ? "Yes" : "-"}</Table.Td>
      <Table.Td>
        {!primary && (
          <Menu>
            <Menu.Target>
              <ActionIcon variant="subtle" color="gray">
                <IconDots />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              {!primary && (
                <>
                  {!mfaPresent && (
                    <Menu.Item onClick={onPrimaryClick}>Make Primary</Menu.Item>
                  )}
                  {showRemove && (
                    <Menu.Item onClick={onDeleteClick}>Remove</Menu.Item>
                  )}
                </>
              )}
            </Menu.Dropdown>
          </Menu>
        )}
      </Table.Td>
    </Table.Tr>
  );
};

const MfaToggle = ({ id, number, mfaEnabled, fetchData, email }) => {
  const [mfaOpen, setMfaOpen] = useState(false);
  const [mfaInfo, setMfaInfo] = useState(null);
  const [sending, setSending] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [codeValue, setCodeValue] = useState("");
  const [resent, setResent] = useState(false);
  const [emailFlow, setEmailFlow] = useState(false);

  useEffect(() => {
    if (codeValue.length === 6) {
      onCodeSubmit();
    }
  }, [codeValue]);

  function onMfaClose() {
    setMfaOpen(false);
    setMfaInfo(false);
    setSending(false);
    setCodeValue("");
    setResent(false);
    setEmailFlow(false);
  }

  function onSendClick() {
    setSending(true);

    const req = {};

    let url = "";

    if (emailFlow) {
      req.email = email;
      url = `/microsite/send-verification/`;
    } else {
      req.mobile_phone = number;
      url = `/verifications/phone/`;
    }

    axios
      .post(url, req)
      .then(({ data }) => {
        setSending(false);
        setMfaInfo(data.response[0]);
      })
      .catch((err) => {
        setSending(false);
        toast.error(err);
        setMfaInfo(null);
      });
  }

  function onCodeSubmit() {
    const req = {
      remote_code_id: mfaInfo.remote_code_id,
      code: codeValue,
    };

    setCodeLoading(true);

    axios
      .post(`/microsite/verify-code/`, req)
      .then(() => {
        toggleMfa();
      })
      .catch((err) => {
        setCodeLoading(false);
        toast.error(err);
      });
  }

  function toggleMfa() {
    axios
      .post(`/toggle-mfa/`, {
        phone_id: id,
      })
      .then(() => {
        onMfaClose();
        setCodeLoading(false);
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        setCodeLoading(false);
      });
  }

  return (
    <>
      <Text mb="lg">
        Multi-factor authentication is{" "}
        <b
          style={{
            color: mfaEnabled
              ? "var(--mantine-color-green-7)"
              : "var(--mantine-color-red-7)",
          }}
        >
          {mfaEnabled ? "enabled" : "disabled"}
        </b>
        .{" "}
        <UnstyledButton
          style={{
            textDecoration: "underline",
          }}
          onClick={(e) => {
            e.target.blur();
            setMfaOpen(true);
          }}
        >
          Click here to {mfaEnabled ? "turn it off" : "turn it on"}.
        </UnstyledButton>
        {mfaEnabled && (
          <UnstyledButton
            style={{
              textDecoration: "underline",
              marginLeft: "8px",
            }}
            onClick={(e) => {
              e.target.blur();
              setEmailFlow(true);
              setMfaOpen(true);
            }}
          >
            No longer have access to {number}?
          </UnstyledButton>
        )}
      </Text>
      <Modal opened={mfaOpen} onClose={onMfaClose}>
        {mfaInfo ? (
          <>
            <Text mb="md">
              A code has been sent to <b>{emailFlow ? email : number}</b>. Enter
              it in the input below.
            </Text>
            <StyledInputs>
              <CodeInput value={codeValue} onChange={(e) => setCodeValue(e)} />
            </StyledInputs>
            <Flex justify="center">
              {resent ? (
                <Text mt="sm" size="xs">
                  We've resent the code. Please contact Mixer support.
                </Text>
              ) : (
                <Button
                  onClick={() => {
                    setResent(true);
                    setCodeValue("");
                    onSendClick();
                  }}
                  variant="subtle"
                  color="gray"
                  mt="sm"
                  size="xs"
                  loading={sending}
                >
                  Didn't receive a code?
                </Button>
              )}
            </Flex>
          </>
        ) : (
          <>
            {mfaEnabled ? (
              <Text>
                MFA is currently enabled. To turn it off, you must input a code
                sent to {emailFlow ? email : number}. Press the button below to
                continue.
              </Text>
            ) : (
              <Text>
                You're about to enable MFA for <b>{number}</b>. When you're
                ready, press the button below.
              </Text>
            )}
            <Button mt="lg" fullWidth loading={sending} onClick={onSendClick}>
              I'm ready!
            </Button>
          </>
        )}
      </Modal>
    </>
  );
};

const StyledInputs = styled.div`
  input {
    border: 1px solid #000;
    background: #eee;
  }
`;
