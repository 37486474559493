import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import styled from "styled-components";
import { TicketAssetUpload } from "./";
import {
  Text,
  Loader,
  Card,
  Group,
  Modal,
  Space,
  Divider,
  Button,
} from "@mantine/core";
import { IconSend, IconPaperclip } from "@tabler/icons-react";

import { Rte } from "@components/shared";

export default function TicketMessages({
  id,
  fetchData,
  messages = [],
  status,
}) {
  const [message, setMessage] = useState("<p><br></p>");
  const [files, setFiles] = useState([]);
  const [libraryAssetId, setLibraryAssetId] = useState(null);
  const [loading, setLoading] = useState(false);

  function sendMessage() {
    setLoading(true);

    const formData = new FormData();
    formData.append("body", message);
    formData.append("trouble_ticket_id", id);
    if (libraryAssetId) formData.append("asset_id", libraryAssetId);
    if (files.length > 0) {
      files.forEach((f, i) => formData.append(`attachments[${i}]`, f));
    }

    axios
      .post(`/trouble-tickets/send-email/`, formData)
      .then(() => {
        toast.success("Sent!");
        setMessage("<p><br></p>");
        setFiles([]);
        setLibraryAssetId(null);
        setLoading(false);
        fetchData();
      })
      .then(() => fetchData())
      .catch((err) => {
        toast.error(err);
        setMessage("<p><br></p>");
        setFiles([]);
        setLibraryAssetId(null);
        setLoading(false);
      });
  }

  function removeFile(file) {
    const newFiles = files.filter((f) => f.name !== file.name);

    setFiles(newFiles);
  }

  return (
    <Card mt="md" p="xs" pb="md" mih={1000}>
      <div style={{ minHeight: "100px" }}>
        {!loading && <Rte value={message} onChange={(e) => setMessage(e)} />}
        {loading && (
          <div style={{ margin: "20px auto", width: "fit-content" }}>
            <Loader size="lg" color="teal" />
          </div>
        )}
      </div>
      <Space mt="xs" />
      <TicketAssetUpload
        disabled={message === "<p><br></p>" && files.length === 0}
        setFiles={(e) => setFiles(e)}
        files={files}
        setLibraryAssetId={(e) => setLibraryAssetId(e)}
        fetchData={fetchData}
        libraryAssetId={libraryAssetId}
        removeFile={(e) => removeFile(e)}
        sendMessage={() => sendMessage()}
        status={status}
      />
      <Space mb="xl" />
      {messages && messages.length === 0 && (
        <Text mt="md" c="dimmed" size="md">
          No messages
        </Text>
      )}
      {messages && messages.length > 0 && <Divider />}
      {messages && messages.length > 0 && <MessageList messages={messages} />}
    </Card>
  );
}

const MessageList = ({ messages }) => {
  return (
    <StyledList>
      {messages
        .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
        .map((m, i) => (
          <Message message={m} key={i} messages={messages} />
        ))}
    </StyledList>
  );
};

const Message = ({ message }) => {
  const [hide, setHide] = useState(true);

  function truncateString(str, num) {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  return (
    <Card mb="sm">
      <Text fw={600} size="xl">
        {message.name}
      </Text>
      <Text size="md" c="dimmed">
        {new Date(message.created_at).toLocaleDateString()}
      </Text>

      {message.html_body && (
        <Text size="lg" style={{ wordWrap: "break-word" }}>
          <div dangerouslySetInnerHTML={{ __html: message.html_body }} />
        </Text>
      )}
      {!message.html_body && (
        <Text size="lg" style={{ wordWrap: "break-word" }}>
          {message.stripped_text}
        </Text>
      )}
      {message.assets.length > 0 && (
        <Button
          mt="xs"
          size="xs"
          color="gray"
          variant="light"
          onClick={() => setHide(!hide)}
          leftSection={<IconPaperclip size={14} />}
        >
          <Text size="xs">
            {hide ? "Show attachments" : "Hide attachments"}
          </Text>
        </Button>
      )}
      {message.assets && message.assets.length > 0 && !hide && (
        <Group>
          {message.assets.map((a, i) => (
            <Card.Section key={i} m="sm">
              <ImageItem filename={a.filename_url} />
              <Text c="grey" title={a.original_filename}>
                {truncateString(a.original_filename, 20)}
              </Text>
            </Card.Section>
          ))}
        </Group>
      )}
      <Group mt="xs">
        <Text size="sm" c="dimmed">
          <IconSend size={12} /> Sent at{" "}
          {new Date(message.created_at).toLocaleTimeString()}
        </Text>
      </Group>
    </Card>
  );
};

const ImageItem = ({ filename }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <StyledImage>
      <img
        onClick={() => setOpen(true)}
        src={filename}
        style={{ height: "75px", borderRadius: "4px" }}
      />
      <Modal opened={isOpen} onClose={() => setOpen(false)} size="xl">
        <img
          src={filename}
          style={{ maxWidth: "100%", display: "flex", margin: "0 auto" }}
        />
      </Modal>
    </StyledImage>
  );
};

const StyledImage = styled.div`
  & + & {
    margin-top: 1em;
  }

  img {
    cursor: pointer;
    max-width: 90%;
    display: block;
    max-height: 300px;
  }
`;

const StyledList = styled.div`
  padding: 1.5em 1.5em 1.5em 0.5em;
  max-height: 900px;
  overflow-y: scroll;
`;
