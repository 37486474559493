import React, { useState, useEffect } from "react";
import axios from "axios";
import { Flex, TextInput, Text, Button, Modal, Divider } from "@mantine/core";
import toast from "react-hot-toast";

const statusButtons = [
  { text: "Active", color: "green", value: 1 },
  { text: "Inactive", color: "red", value: 2 },
];

export default function SongQueryEdit({ id, onSuccess, name, status }) {
  const [isOpen, setOpen] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [statusValue, setStatusValue] = useState(1);

  useEffect(() => {
    setNameValue(name);
  }, [name]);

  useEffect(() => {
    setStatusValue(status);
  }, [status]);

  function onClose() {
    if (loading) return;
    setOpen(false);
    setLoading(false);
  }

  function onFormSubmit() {
    setLoading(true);

    const req = {
      song_query_id: id,
      name: nameValue,
    };

    axios
      .put(`/song-queries/${id}/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Updated!");
        onSuccess();
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onStatusSubmit(newStatus) {
    setLoading(true);

    const req = {
      song_query_id: id,
      status: newStatus,
    };

    axios
      .post(`/toggle-song-query-status/`, req)
      .then(() => {
        toast.success("Updated!");
        // onSuccess();
        // onClose();
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <>
      <Button
        size="xs"
        variant="subtle"
        p={0}
        c="dimmed"
        onClick={() => setOpen(true)}
      >
        Edit Category
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <Text mb="xs" fw={600}>
          Edit Song Category
        </Text>
        <TextInput
          value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}
          mb="xs"
        />
        <Button
          fullWidth
          onClick={onFormSubmit}
          loading={loading}
          disabled={!nameValue}
        >
          Save
        </Button>
        <Divider mt="xl" mb="xl" />
        <Text mb="xs" fw={600}>
          Activation Status
        </Text>
        <Flex gap="xs">
          {statusButtons.map((button) => (
            <Button
              key={button.value}
              size="xs"
              onClick={() => onStatusSubmit(button.value)}
              variant={statusValue === button.value ? "filled" : "light"}
              color={statusValue === button.value ? button.color : "gray"}
            >
              {button.text}
            </Button>
          ))}
        </Flex>
      </Modal>
    </>
  );
}
