import React from "react";
import { useSelector } from "react-redux";
import {
  ActionIcon,
  Text,
  Flex,
  UnstyledButton,
  ThemeIcon,
} from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";
import axios from "axios";

const LogoutButton = ({ text }) => {
  const isAdmin = useSelector((state) => state.admin) ? true : false;
  const isManager = useSelector((state) => state.manager) ? true : false;
  const user = useSelector((state) => state.user);

  function onClick() {
    axios
      .post(`/logout/`)
      .then(
        () =>
          (window.location = isAdmin
            ? `/admin`
            : user
            ? `/user-login/${user.current_user_hash.settings_code}`
            : "/manager")
      )
      .catch(
        (err) =>
          (window.location = isAdmin
            ? `/admin`
            : user
            ? `/user-login/${user.current_user_hash.settings_code}`
            : "/manager")
      );
  }

  if (text) {
    return (
      <UnstyledButton onClick={onClick}>
        <Flex>
          <ThemeIcon mr="sm" variant="subtle" color="gray">
            <IconLogout size={18} />
          </ThemeIcon>
          <Text>Logout</Text>
        </Flex>
      </UnstyledButton>
    );
  }

  return (
    <ActionIcon variant="light" title="Logout" onClick={onClick}>
      <IconLogout size={18} />
    </ActionIcon>
  );
};

export default LogoutButton;
