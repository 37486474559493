import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Menu,
  Card,
  Group,
  Text,
  TextInput,
  Button,
  ActionIcon,
  Divider,
  Badge,
  Space,
} from "@mantine/core";

import { colors } from "./helpers";
import { DataFilters, PaginatedList, PageTitle } from "@components/shared";
import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";
import {
  IconSend,
  IconChecks,
  IconChecklist,
  IconClock,
  IconEyeOff,
  IconFlame,
  IconDots,
  IconEye,
  IconRefresh,
  IconWorld,
} from "@tabler/icons-react";

export default function TicketList() {
  const [status, setStatus] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const filterButtons = [
    {
      text: "All",
      status: null,
      icon: <IconWorld size={16} />,
      color: "indigo",
    },
    {
      text: "Fresh",
      status: "fresh",
      icon: <IconFlame size={16} />,
      color: "red",
    },
    {
      text: "Unviewed",
      status: "unviewed",
      icon: <IconEyeOff size={16} />,
      color: "gray",
    },
    {
      text: "Unread messages",
      status: "reply_waiting",
      icon: <IconSend size={16} />,
      color: "orange",
    },
    {
      text: "Waiting to be claimed",
      status: "waiting",
      icon: <IconClock size={16} />,
      color: "grape",
    },
    {
      text: "Claimed",
      status: "acknowledged",
      icon: <IconChecklist size={16} />,
      color: "blue",
    },
    {
      text: "Closed",
      status: "closed",
      icon: <IconChecks size={16} />,
      color: "teal",
    },
  ].map((m, i) => ({
    ...m,
    active: status == m.status ? true : false,
    key: i,
  }));

  function onUpdate(req) {
    axios
      .put(`/trouble-tickets/${req.id}/`, req.reqData)
      .then(() => {
        toast.success("Updated!");
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      {/* <PageTitle
        title={`Trouble Tickets`}
        icon={entityIcon.ticket()}
        iconColor={entityColor.ticket}
      /> */}
      <TextInput
        placeholder="Search..."
        size="lg"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <Group mt="md" mb="md">
        {filterButtons.map((b, i) => (
          <Button
            key={i}
            c={b.active ? "" : b.color}
            color={b.color}
            variant={b.active ? "filled" : "outline"}
            size="xs"
            onClick={() => setStatus(b.status)}
          >
            {b.icon && <div style={{ marginRight: "5px" }}>{b.icon}</div>}
            {b.text}
          </Button>
        ))}
      </Group>
      <PaginatedList
        requestInfo={{
          url: `/retrieve-trouble-tickets/`,
          data: {
            status: status,
            search_value: searchValue,
          },
        }}
        responseMapping={(r) =>
          r
            // .sort((a, b) => (a.status > b.status ? 1 : -1))
            .map((m) => ({
              ...m,
              link_url: `/trouble-tickets/${m.id}`,
            }))
        }
        displayFormatter={(item) => (
          <Card
            key={item.original.id}
            style={{ overflow: "visible" }}
            opacity={item.original.status === 3 ? 0.6 : 1}
          >
            <Group>
              <Text fw={700} component={Link} to={item.original.link_url}>
                {item.original.ident}
              </Text>
            </Group>
            {item.original.created_at_formatted && (
              <Text size="sm" c="dimmed">
                submitted by {item.original.first_name}{" "}
                {item.original.last_name} on{" "}
                {item.original.created_at_formatted}
              </Text>
            )}
            {item.original.campaign_effort_name && (
              <Group spacing="xs">
                {entityIcon.effort()}
                <Text size="sm">{item.original.campaign_effort_name}</Text>
              </Group>
            )}
            {item.original.campaign_name && (
              <Group spacing="xs">
                {entityIcon.campaign()}
                <Text size="sm">{item.original.campaign_name}</Text>
              </Group>
            )}
            <Text mt="xs">{item.original.issue_description}</Text>
            <Space mt="xs" />
            <Group position="apart">
              {item.original.status === 3 && <div></div>}
              {item.original.status !== 3 && status !== "closed" && (
                <Menu style={{ marginRight: "15px" }}>
                  <Menu.Target>
                    <ActionIcon color="gray" variant="subtle">
                      <IconDots />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    {item.original.status === 1 && (
                      <Menu.Item
                        component="button"
                        icon={<IconChecklist size={16} />}
                        onClick={() =>
                          onUpdate({
                            reqData: { status: 2 },
                            id: item.original.id,
                          })
                        }
                      >
                        Claim
                      </Menu.Item>
                    )}
                    {!item.original.viewed && (
                      <Menu.Item
                        component="button"
                        icon={<IconEye size={16} />}
                        onClick={() =>
                          onUpdate({
                            reqData: { viewed: true },
                            id: item.original.id,
                          })
                        }
                      >
                        Mark as viewed
                      </Menu.Item>
                    )}
                    <Menu.Item
                      component="button"
                      icon={<IconRefresh size={16} />}
                      onClick={() =>
                        onUpdate({
                          reqData: { status: 1 },
                          id: item.original.id,
                        })
                      }
                    >
                      Refresh
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
              {(!status || status === "fresh") && (
                <Group>
                  {item.original.is_fresh && (
                    <Badge
                      leftSection={
                        <IconFlame size={16} style={{ display: "flex" }} />
                      }
                      variant="light"
                      size="sm"
                      color="red"
                    >
                      Fresh
                    </Badge>
                  )}
                  {item.original.status === 1 && (
                    <Badge
                      leftSection={
                        <IconClock size={16} style={{ display: "flex" }} />
                      }
                      variant="light"
                      size="sm"
                      color="grape"
                    >
                      Waiting
                    </Badge>
                  )}
                  {item.original.status === 2 && (
                    <Badge
                      leftSection={
                        <IconChecklist size={16} style={{ display: "flex" }} />
                      }
                      variant="light"
                      size="sm"
                      color="blue"
                    >
                      Claimed
                    </Badge>
                  )}
                  {item.original.status === 3 && (
                    <Badge
                      leftSection={
                        <IconChecks size={16} style={{ display: "flex" }} />
                      }
                      variant="light"
                      size="sm"
                      color="teal"
                    >
                      Closed
                    </Badge>
                  )}
                  {item.original.status !== 3 && !item.original.viewed && (
                    <Badge
                      leftSection={
                        <IconEyeOff size={16} style={{ display: "flex" }} />
                      }
                      variant="light"
                      size="sm"
                      color="gray"
                    >
                      Unviewed
                    </Badge>
                  )}
                  {item.original.status !== 3 &&
                    item.original.has_unread_messages && (
                      <Badge
                        leftSection={
                          <IconSend size={16} style={{ display: "flex" }} />
                        }
                        variant="light"
                        size="sm"
                        color="orange"
                      >
                        Unread Messages
                      </Badge>
                    )}
                </Group>
              )}
            </Group>
          </Card>
        )}
      />
    </React.Fragment>
  );
}
