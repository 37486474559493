import React from "react";
import { Text, ThemeIcon, Group } from "@mantine/core";
import { IconThumbUp, IconThumbDown } from "@tabler/icons-react";

export default function EmailValidationNameplate({ allowed, title, subtitle }) {
  return (
    <Group>
      <ThemeIcon radius="xl" variant="light" color={allowed ? "green" : "red"}>
        {allowed ? <IconThumbUp /> : <IconThumbDown />}
      </ThemeIcon>
      <div>
        <Text fw={600}>{title}</Text>
        <Text size="xs" c="dimmed">
          {subtitle}
        </Text>
      </div>
    </Group>
  );
}
