import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Card, Group, Switch, Text } from "@mantine/core";
import toast from "react-hot-toast";

import { ManagementLayout } from "@components/shared";
import { ContestList, ContestAddCreate } from "@components/Contest";
import { ContestRemoveButton } from "@components/Contest/ContestItem";
import entityIcon from "@util/entityIcon";

export default function MultiSweepsEffortAwards({
  effortId,
  effortName,
  locationId,
  organizationId,
  showCreate = true,
  fetchData,
}) {
  const [refresh, setRefresh] = useState(false);

  const List = () => (
    <ContestList
      refresh={refresh}
      hiddenFilters={["owned_by", "status"]}
      requestInfo={{
        url: `/retrieve-contests/`,
        data: {
          campaign_effort_id: effortId,
        },
      }}
      showVarietyFilters={false}
      displayFormatter={(contest) => (
        <Card key={contest.id}>
          <Text component={Link} to={`/contests/${contest.id}`} fw={700}>
            {contest.name}
          </Text>
          {contest.location_name && (
            <Group spacing="xs">
              {entityIcon.location()}
              <Text size="sm">{contest.location_name}</Text>
            </Group>
          )}
          {contest.organization_name && (
            <Group spacing="xs">
              {entityIcon.organization()}
              <Text size="sm">{contest.organization_name}</Text>
            </Group>
          )}
          {contest.start_date_formatted && contest.end_date_formatted && (
            <Group spacing="xs">
              {entityIcon.calendar()}{" "}
              <Text size="sm">
                {contest.start_date_formatted} - {contest.end_date_formatted}
              </Text>
            </Group>
          )}
          <Group mt="xs">
            <ContestRemoveButton
              effortId={effortId}
              contestId={contest.id}
              onSuccess={fetchData}
            />
            <IncludeInitialToggle
              checked={contest.include_in_registration === true}
              contestId={contest.id}
              effortId={effortId}
              joinId={contest.join_id}
              onSuccess={() => setRefresh(!refresh)}
            />
          </Group>
        </Card>
      )}
    />
  );

  if (!showCreate) return <List />;

  return (
    <div>
      {/* <Flex align="center" gap="sm">
        <Title order={5} mb="sm">
          Sweepstakes
        </Title>
        {loading && <Loader size="xs" />}
      </Flex> */}
      <ManagementLayout
        dataComponent={
          <div>
            <List />
          </div>
        }
        createComponent={
          <Card>
            <ContestAddCreate
              locationId={locationId}
              organizationId={organizationId}
              addOutside={organizationId ? true : false}
              effortId={effortId}
              effortName={effortName}
              onSuccess={() => {
                fetchData();
                setRefresh(!refresh);
              }}
            />
          </Card>
        }
      />
    </div>
  );
}

const IncludeInitialToggle = ({
  checked,
  contestId,
  effortId,
  joinId,
  onSuccess,
}) => {
  function onChange() {
    axios
      .post(`/contests/${contestId}/toggle-initial-registration/`, {
        campaign_effort_id: effortId,
        join_id: joinId,
      })
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Switch
      checked={checked}
      size="xs"
      label="Include in initial entry"
      onChange={onChange}
    />
  );
};
