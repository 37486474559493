import React from "react";
import { Card, useMantineTheme } from "@mantine/core";
import { IconCheckbox, IconSquareX } from "@tabler/icons-react";

import { StatBlocks } from "@components/DataViz";

const iconSize = 36;

export default function ContestFinalistMeta({
  onePrizePerWinner,
  totalEntrants,
}) {
  const theme = useMantineTheme();

  const colors = {
    positive: theme.colors.green[4],
    negative: theme.colors.red[4],
  };

  return (
    <Card p="xs">
      <StatBlocks
        span={6}
        backgroundColor={theme.colors.gray[0]}
        data={[
          {
            text: "winner or finalist may win multiple prizes",
            value: onePrizePerWinner ? (
              <IconSquareX size={iconSize} color={colors.negative} />
            ) : (
              <IconCheckbox size={iconSize} color={colors.positive} />
            ),
          },
          { text: "total entrants", value: totalEntrants },
        ]}
      />
    </Card>
  );
}
