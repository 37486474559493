import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Anchor, Card, Table, LoadingOverlay, Pagination } from "@mantine/core";
import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";

export default function StationPlays({ locationId }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const table = useReactTable({
    manualPagination: true,
    pageCount,
    data: items,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  function fetchData() {
    const req = {
      location_id: locationId,
      page_size: table.getState().pagination.pageSize,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
    };

    const cancel = cancelRef.current;
    if (cancel) cancel();

    setLoading(true);

    axios
      .post(`/retrieve-plays/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLoading(false);
        setItems(data.response[0].data);
        setPageCount(data.response[0].page_count);
        if (data.response[0].total !== undefined) {
          setTotal(data.response[0].total);
        } else {
          setTotal(data.response[0].data.length);
        }
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(table.getState().pagination)]);

  return (
    <Card pos="relative">
      <LoadingOverlay visible={loading} />
      <Table mb="lg" striped highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{ width: "50px" }}>variety</Table.Th>
            <Table.Th style={{ width: "170px" }}>start</Table.Th>
            <Table.Th>name</Table.Th>
            <Table.Th>artist</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {items.map((item) => (
            <Table.Tr key={item.id}>
              <Table.Td>{item.variety_formatted}</Table.Td>
              <Table.Td>
                {new Date(item.posted_start_time).toLocaleString()}
              </Table.Td>
              <Table.Td>
                <Anchor
                  to={`/plays/${item.id}`}
                  component={Link}
                  target="_blank"
                >
                  {item.display_name}
                </Anchor>
              </Table.Td>
              <Table.Td>
                {item.song && (
                  <React.Fragment>
                    {new Intl.ListFormat("en", {
                      style: "short",
                      type: "conjunction",
                    }).format(item.song.artists.map((a) => a.name))}
                  </React.Fragment>
                )}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      <Pagination
        value={table.getState().pagination.pageIndex + 1}
        // onChange={() => table.nextPage()}
        onChange={(e) => {
          table.setPageIndex(e - 1);
          // table.nextPage();
        }}
        total={pageCount}
      />
    </Card>
  );
}
