import React, { useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Group,
  Checkbox,
  Text,
  Radio,
} from "@mantine/core";
import styled from "styled-components";
import { IconAdjustmentsHorizontal } from "@tabler/icons-react";

export default function DataFilters({
  items = [],
  onChange,
  values,
  title = "Filter Items",
}) {
  const [opened, setOpened] = useState(false);

  return (
    <div style={{ margin: "1em 0" }}>
      <Button
        onClick={() => setOpened(true)}
        variant="light"
        size="xs"
        leftSection={<IconAdjustmentsHorizontal />}
      >
        {title}
      </Button>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        padding="xl"
        size="xl"
        style={{ overflowY: "auto" }}
      >
        <div style={{ margin: "1em 0" }}>
          {items.map((item, i) => (
            <StyledGroup key={i + 1}>
              <Text mb="sm" fw={600}>
                {item.text}
              </Text>
              {item.options.map((opt, ii) =>
                item.radio ? (
                  <Radio
                    key={opt.value}
                    name={item.key_name}
                    checked={values[item.key_name] === opt.value}
                    label={opt.text}
                    onChange={() => onChange(item.key_name, opt.value)}
                    size="md"
                    mb={ii !== item.options.length - 1 ? "sm" : "xs"}
                  />
                ) : (
                  <Checkbox
                    key={opt.value}
                    label={opt.text}
                    size="md"
                    checked={values[item.key_name].includes(opt.value)}
                    onChange={() => onChange(item.key_name, opt.value)}
                    mb={ii !== item.options.length - 1 ? "sm" : "xs"}
                  />
                )
              )}
              {i !== items.length - 1 && <Divider mt="lg" />}
            </StyledGroup>
          ))}
        </div>
      </Drawer>
    </div>
  );
}

const StyledGroup = styled.div`
  & + & {
    margin-top: 1em;
  }
`;
