import React, { useState, useEffect } from "react";
import { Grid, Modal, Title, Group, Button } from "@mantine/core";

import {
  ValueItem,
  ValueAdd,
  IntervalItem,
} from "@components/shared/Intervals";
import { Counter } from "@components/shared";
import { getUuid } from "@util/getUuid";

export const intervalValues = {
  hourly: 1,
  daily: 2,
  weekly: 3,
  monthly: 4,
  yearly: 5,
  ever: 6,
  custom: 7,
};

const intervalOptions = [
  { text: "H", value: intervalValues.hourly, title: "Hour" },
  { text: "D", value: intervalValues.daily, title: "Daily" },
  { text: "W", value: intervalValues.weekly, title: "Weekly" },
  { text: "M", value: intervalValues.monthly, title: "Monthly" },
  { text: "Y", value: intervalValues.yearly, title: "Yearly" },
  { text: "E", value: intervalValues.ever, title: "Ever" },
  { text: "C", value: intervalValues.custom, title: "Custom" },
].map((m, i) => ({
  ...m,
  key: i + 1,
}));

export default function Intervals({
  dates,
  sectionTitle = "Intervals",
  title = "Interval",
  items = [],
  onChange,
}) {
  const [creating, setCreating] = useState(false);

  const disabledIntervals = items.map((m) => m.interval);

  return (
    <div style={{ marginBottom: "2em" }}>
      <Group>
        <Title order={4}>{sectionTitle}</Title>
        <Button
          size="xs"
          variant="light"
          radius="xl"
          onClick={() => setCreating(true)}
        >
          Create
        </Button>
      </Group>
      {items.length > 0 && (
        <Grid mt="xs">
          {items.map((item) => (
            <Grid.Col key={item.uuid} span={{ base: 12, md: 6 }}>
              <IntervalItem
                key={item.uuid}
                frequency={item.frequency}
                interval={item.interval}
                values={item.values}
                onRemoveClick={() =>
                  onChange([...items].filter((f) => f.uuid !== item.uuid))
                }
              />
            </Grid.Col>
          ))}
        </Grid>
      )}
      <Modal
        opened={creating}
        onClose={() => setCreating(false)}
        title={`Create ${title}`}
      >
        <AddForm
          dates={dates}
          disabledIntervals={disabledIntervals}
          onSubmit={(e) => {
            onChange([...items, e]);
            setCreating(false);
          }}
        />
      </Modal>
    </div>
  );
}

export const AddForm = ({
  selectedInterval = null,
  disabledIntervals = [],
  hiddenIntervals = [],
  onSubmit,
  dates,
}) => {
  const [formValues, setFormValues] = useState({
    frequency: 0,
    interval: intervalValues.hourly,
    values: [],
  });

  useEffect(() => {
    if (selectedInterval) {
      setFormValues({
        ...formValues,
        interval: selectedInterval,
      });
    }
  }, [selectedInterval]);

  function onSubmitClick() {
    onSubmit({ ...formValues, uuid: getUuid() });
  }

  return (
    <div>
      {!selectedInterval && (
        <IntervalButtons
          value={formValues.interval}
          disabledIntervals={disabledIntervals}
          hiddenIntervals={hiddenIntervals}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              interval: e,
              frequency: e === intervalValues.custom ? 0 : formValues.frequency,
            })
          }
        />
      )}
      <Group mt="lg" mb="lg">
        {formValues.interval !== intervalValues.custom && (
          <React.Fragment>
            <Counter
              value={formValues.frequency}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  frequency: e,
                })
              }
            />
            <h3 style={{ margin: "0" }}>times</h3>
          </React.Fragment>
        )}
      </Group>
      {formValues.interval === intervalValues.custom && (
        <React.Fragment>
          <h4 style={{ marginBottom: "0" }}>Values</h4>
          {formValues.values.map((v) => (
            <ValueItem
              key={v.uuid}
              day={v.day}
              start={v.start}
              end={v.end}
              onRemoveClick={() =>
                setFormValues({
                  ...formValues,
                  values: [...formValues.values].filter(
                    (f) => f.uuid !== v.uuid
                  ),
                })
              }
            />
          ))}
          <ValueAdd
            dates={dates}
            onAdd={(e) =>
              setFormValues({
                ...formValues,
                values: [...formValues.values, e],
              })
            }
          />
        </React.Fragment>
      )}
      <Button fullWidth mt="md" onClick={onSubmitClick}>
        Create Interval
      </Button>
    </div>
  );
};

const IntervalButtons = ({
  value,
  disabledIntervals = [],
  hiddenIntervals = [],
  onChange,
}) => (
  <Grid>
    {intervalOptions
      .filter((f) => !hiddenIntervals.includes(f.value))
      .map((m) => (
        <Grid.Col span={{ base: 12, md: 4 }} key={m.key}>
          <Button
            key={m.key}
            onClick={() => onChange(m.value)}
            variant={value === m.value ? "light" : "subtle"}
            size="xs"
            color={value === m.value ? "blue" : "gray"}
            title={m.title}
            fullWidth
            disabled={disabledIntervals.includes(m.value)}
          >
            {m.title}
          </Button>
        </Grid.Col>
      ))}
  </Grid>
);
