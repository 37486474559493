import React, { createContext, useContext, useState, useEffect } from "react";
import { Card, Tabs } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { initialState } from "./helpers";
import Keywords from "./Keywords";
import Checkins from "./Checkins";
import GeneralSettings from "./GeneralSettings";
import InitialEntry from "./InitialEntry";
import WinnerRules from "./WinnerRules";

import { effortRecurringEngagement } from "@components/Effort/helpers";

const tabDefs = {
  general: "general",
  keywords: "keywords",
  initial_entry: "initial entry",
  check_ins: "check-ins",
  winner_rules: "winner rules",
};

export const Context = createContext(null);

export default function EffortPointSettings({
  beginDate,
  canEditWinnerRules,
  effortId,
  endDate,
  fetchEffort,
  pointsOnly,
  recurringEngagement,
  wizardSettings,
}) {
  const [state, setState] = useState({
    ...initialState,
    contestDates: {
      begin: beginDate,
      end: endDate,
    },
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    axios
      .get(`/efforts/${effortId}/point-settings/`)
      .then(({ data }) => {
        setState({ ...state, ...data.response[0].settings });
      })
      .catch((err) => {
        // setSettings({});
      });
  }

  function updateSettings(newState) {
    const req = { campaign_effort_id: effortId, settings: newState };

    axios
      .post(`/efforts/${effortId}/point-settings/`, req)
      .then(() => {
        fetchSettings();
      })
      .then(() => fetchEffort())
      .catch((err) => {
        toast.error(err);
      });
  }

  function onUpdate(newState) {
    setState({ ...state, ...newState });
    updateSettings({ ...state, ...newState });
  }

  return (
    <Context.Provider value={state}>
      <Settings
        onUpdate={onUpdate}
        fetchEffort={fetchEffort}
        canEditWinnerRules={canEditWinnerRules}
        pointsOnly={pointsOnly}
        recurringEngagement={recurringEngagement}
        wizardSettings={wizardSettings}
      />
    </Context.Provider>
  );
}

function Settings({
  onUpdate,
  pointsOnly,
  canEditWinnerRules,
  recurringEngagement,
  wizardSettings,
}) {
  const {
    activeTab,
    keywords: keywordData,
    check_ins: checkinData,
    initial_entry: initialEntryData,
    winner_rules: winnerRules,
  } = useContext(Context);

  if (pointsOnly) {
    return (
      <Card>
        <GeneralSettings
          onUpdate={(e) => onUpdate({ ...e })}
          pointsOnly={pointsOnly}
          wizardSettings={wizardSettings}
        />
      </Card>
    );
  }

  return (
    <Card>
      <Tabs onChange={(e) => onUpdate({ activeTab: e })} value={activeTab}>
        <Tabs.List mb="xl">
          <Tabs.Tab value={tabDefs.general}>General</Tabs.Tab>
          <Tabs.Tab value={tabDefs.initial_entry}>Initial Entry</Tabs.Tab>
          {recurringEngagement === effortRecurringEngagement.true && (
            <React.Fragment>
              <Tabs.Tab value={tabDefs.keywords}>Keywords</Tabs.Tab>
              <Tabs.Tab value={tabDefs.check_ins}>Check-Ins</Tabs.Tab>
            </React.Fragment>
          )}
          <Tabs.Tab value={tabDefs.winner_rules}>Winner Rules</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={tabDefs.general}>
          <GeneralSettings onUpdate={(e) => onUpdate({ ...e })} />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.initial_entry}>
          <InitialEntry
            onUpdate={(e) =>
              onUpdate({ initial_entry: { ...initialEntryData, ...e } })
            }
          />
        </Tabs.Panel>
        {recurringEngagement === effortRecurringEngagement.true && (
          <React.Fragment>
            <Tabs.Panel value={tabDefs.check_ins}>
              <Checkins
                onUpdate={(e) =>
                  onUpdate({ check_ins: { ...checkinData, ...e } })
                }
              />
            </Tabs.Panel>
            <Tabs.Panel value={tabDefs.keywords}>
              <Keywords
                onUpdate={(e) =>
                  onUpdate({ keywords: { ...keywordData, ...e } })
                }
              />
            </Tabs.Panel>
          </React.Fragment>
        )}
        <Tabs.Panel value={tabDefs.winner_rules}>
          <WinnerRules
            canEditWinnerRules={canEditWinnerRules}
            onUpdate={(e) =>
              onUpdate({ winner_rules: { ...winnerRules, ...e } })
            }
          />
        </Tabs.Panel>
      </Tabs>
    </Card>
  );
}
