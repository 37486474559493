import React, { useState } from "react";
import axios from "axios";
import { Button, Card, Flex, Text } from "@mantine/core";
import toast from "react-hot-toast";

export default function EmailServerCreate({
  organizationId,
  locationId,
  fetchData,
}) {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {};

    if (organizationId) req.organization_id = organizationId;
    if (locationId) req.location_id = locationId;

    axios
      .post(`/postmark-servers/add-to-entity/`, req)
      .then(() => {
        toast.success("Created!");
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Card mt="lg">
      <Flex>
        <div style={{ flexGrow: 1 }}>
          <Text fw={600}>Create Postmark server</Text>
          <Text size="sm">
            Click the button to create a Postmark server for this{" "}
            {organizationId ? "organization" : "location"}
          </Text>
        </div>
        <Button mt="md" size="xs" loading={loading} onClick={onClick}>
          Create
        </Button>
      </Flex>
    </Card>
  );
}
