import React, { useState, useEffect } from "react";
import { SegmentedControl, TextInput } from "@mantine/core";
import { useSelector } from "react-redux";

import { DataFilters, PaginatedList } from "@components/shared";
import { ContestIndicators, ContestItem } from "./";
import { locationVariety } from "@components/Location/helpers";

export default function ContestList({
  campaignId,
  displayFormatter = null,
  effortId,
  forDuplication,
  hiddenFilters = [],
  isIndex = false,
  isManager = false,
  items = null,
  refresh,
  requestInfo = {
    url: `/retrieve-contests/`,
    data: {},
  },
  showVarietyFilters = true,
}) {
  const managerInfo = useSelector((state) => state.manager);

  const filterArr = [
    {
      text: "Crafted By",
      key_name: "owned_by",
      default_value: true,
      radio: true,
      options: [
        { text: "this entity", value: true },
        { text: "someone else", value: false },
      ],
    },
    {
      text: "Status",
      key_name: "status",
      default_value: "entire",
      radio: true,
      options: [
        { text: "All", value: "entire" },
        { text: "Active", value: "active" },
        { text: "Upcoming", value: "upcoming" },
        { text: "Expired", value: "expired" },
      ],
    },
  ]
    .filter((f) => (isManager || isIndex ? f.key_name !== "owned_by" : true))
    .map((m, i) => ({
      ...m,
      all_values: m.options.map((mm) => mm.value),
      key: i + 1,
    }));

  const defaultFilters = filterArr.reduce((acc, cur) => {
    acc[cur.key_name] = cur.default_value;
    return acc;
  }, {});

  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState(defaultFilters);
  const [refresh2, setRefresh2] = useState(false);
  const [variety, setVariety] = useState("1");

  const formattedFilters = Object.keys(filters).reduce((acc, cur) => {
    const obj = filterArr.find((f) => f.key_name === cur);
    if (obj.radio) {
      acc[cur] = filters[cur];
    } else {
      acc[cur] = filters[cur].length ? filters[cur] : obj.all_values;
    }
    return acc;
  }, {});

  const reqData = {
    ...formattedFilters,
    owned_by: isManager ? true : formattedFilters.owned_by,
    ...requestInfo.data,
    search_value: searchValue,
    refresh,
    refresh2,
    variety: parseInt(variety),
  };

  if (forDuplication) reqData.status = "for_duplication";

  function onFilterChange(keyName, value) {
    if (typeof value === "object") {
      setFilters({
        ...filters,
        [keyName]: filters[keyName].includes(value)
          ? [...filters[keyName]].filter((f) => f !== value)
          : [...filters[keyName], value],
      });
    } else {
      setFilters({
        ...filters,
        [keyName]: value,
      });
    }
  }

  const hasFilters = filters.length > 0;

  return (
    <div>
      {requestInfo && (
        <React.Fragment>
          <TextInput
            placeholder="Search..."
            size="md"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            mb={!hasFilters ? "sm" : 0}
          />
          {hasFilters && (
            <DataFilters
              onChange={onFilterChange}
              values={filters}
              items={filterArr.filter(
                (f) => !hiddenFilters.includes(f.key_name)
              )}
            />
          )}
        </React.Fragment>
      )}
      {(!managerInfo ||
        (managerInfo &&
          managerInfo.location_variety !== locationVariety.dealer)) &&
        showVarietyFilters &&
        !forDuplication && (
          <SegmentedControl
            value={variety}
            data={[
              { label: "Sweepstakes", value: "1" },
              { label: "Prize Pool", value: "2" },
              { label: "Auction", value: "3" },
            ]}
            onChange={(e) => setVariety(e)}
            mb="sm"
          />
        )}
      <PaginatedList
        tableData={items}
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            entity_id:
              m.campaign_effort_id ||
              m.campaign_id ||
              m.organization_id ||
              m.location_id,
            link_url: `/contests/${m.id}`,
          }))
        }
        displayFormatter={(item) =>
          displayFormatter ? (
            displayFormatter(item.original)
          ) : (
            <ContestItem
              key={item.original.id}
              contestInfo={item.original}
              asLink
              fetchData={() => setRefresh2(!refresh2)}
              campaignId={campaignId}
              effortId={effortId}
            />
          )
        }
      />
    </div>
  );
}
