import React from "react";
import { Link } from "react-router-dom";
import { Card, Text } from "@mantine/core";

import LootItemIndicators from "./LootItemIndicators";
import { PaginatedList } from "@components/shared";

export default function LootItemList({ requestInfo }) {
  return (
    <PaginatedList
      requestInfo={requestInfo}
      responseMapping={(r) =>
        r.map((m) => ({
          ...m,
          link_url: `/loot-items/${m.id}`,
        }))
      }
      displayFormatter={(item, i) => (
        <Card key={item.original.id}>
          <Text fw={700} component={Link} to={item.original.link_url}>
            {item.original.name} ({item.original.quantity_available} Available)
          </Text>
          <Card.Section pl="sm" pr="sm" pb="lg">
            <LootItemIndicators
              hasAsset={item.original.has_asset}
              quantityAllocated={item.original.quantity_allocated}
              value={item.original.value_formatted}
            />
          </Card.Section>
        </Card>
      )}
    />
  );
}
