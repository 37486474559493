import React from "react";
import styled from "styled-components";
import { Title } from "@mantine/core";

export default function AuthLayout({ children, title = "" }) {
  return (
    <Layout>
      <div className="wrapper">
        <img
          src={"https://mixer-public-assets.s3.amazonaws.com/logo-white.png"}
          alt="Mixer"
        />
        {title && (
          <Title mb="sm" order={3}>
            {title}
          </Title>
        )}
        {children}
      </div>
    </Layout>
  );
}

const Layout = styled.div`
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 250px;
    margin: 0 auto 2em auto;
  }

  .wrapper {
    text-align: center;
    color: #fff;
    width: 450px;
  }

  label {
    color: #fff;
  }

  form {
    display: flex;
    flex-direction: column;

    /* input {
      font-size: 1.15em;
      padding: 1em;
      border-bottom: 1px solid #000;
      border-left: none;
      border-right: none;
      border-top: none;
    }

    input + input {
      margin-top: 0.5em;
    } */

    button {
      margin-top: 0.5em;
      margin-bottom: 1em;
    }
  }
`;
