import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Box,
  Flex,
  Divider,
  Text,
  Table,
  Anchor,
  TextInput,
  Modal,
  Button,
  ColorSwatch,
  LoadingOverlay,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { IconPencil, IconPalette, IconDeviceFloppy } from "@tabler/icons-react";
import styled from "styled-components";

export default function LookAndFeelManagement({
  createReqData,
  addReqData,
  fetchData,
  isWizard = false,
}) {
  return (
    <Flex gap="xs">
      {!isWizard && (
        <LookAndFeelCreate reqData={createReqData} onSuccess={fetchData} />
      )}
      <LookAndFeelSelect reqData={addReqData} onSuccess={fetchData} />
    </Flex>
  );
}

function LookAndFeelSelect({ onSuccess, reqData }) {
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setLoading(false);
    setOpen(false);
    setSearchValue("");
    setItems([]);
  }

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    if (!isOpen) return;
    fetchItems();
  }, [searchValue, isOpen]);

  function fetchItems() {
    const req = {
      search: searchValue,
      page_size: 1000,
      page: 0,
    };

    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    axios
      .post(`/retrieve-look-and-feel-templates/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLoading(false);
        setItems(data.response[0].data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        size="xs"
        radius="xl"
        variant="light"
        leftSection={<IconPalette size={16} />}
        color="gray"
      >
        Look & Feel Library
      </Button>
      <Modal size="lg" onClose={onClose} opened={isOpen}>
        <div style={{ position: "relative" }}>
          <LoadingOverlay visible={loading} />
          <TextInput mb="sm" placeholder="Search..." />
          {items.map((item, i) => (
            <div key={i}>
              <OptionItem
                id={item.id}
                item={item}
                onSuccess={() => {
                  onClose();
                  onSuccess();
                }}
                fetchData={fetchItems}
                reqData={reqData}
                setModalLoading={(c) => setLoading(c)}
              />
              {i !== items.length - 1 && <Divider mt="lg" mb="lg" />}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
}

const OptionItem = ({
  id,
  item,
  fetchData,
  onSuccess,
  reqData,
  setModalLoading,
}) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [adding, setAdding] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(null);
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    if (!expanded) return;

    fetchItem();
  }, [expanded]);

  function fetchItem() {
    setLoading(true);

    axios
      .get(`/look-and-feel-templates/${id}/`)
      .then(({ data }) => {
        setLoading(false);
        setInfo(data.response[0]);
      })
      .then(() => {
        fetchAssets();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function fetchAssets() {
    axios
      .post(`/retrieve-assets/`, {
        look_and_feel_template_id: id,
      })
      .then(({ data }) => {
        setAssets(
          data.response[0].data.map((d) => ({
            ...d,
            ...d.asset,
          }))
        );
      })
      .catch((err) => {
        setAssets([]);
      });
  }

  function onAddClick() {
    const req = {
      ...reqData,
      look_and_feel_template_id: item.id,
    };

    setModalLoading(true);

    axios
      .post(`/apply-look-and-feel-template/`, req)
      .then(() => {
        toast.success("Added!");
        setModalLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setModalLoading(false);
        toast.error(err);
      });
  }

  const config = info ? info.template_data : null;
  const emptyConfig = JSON.stringify(config) === "{}";

  function getAssetUrl(placement) {
    if (!config || emptyConfig) return null;
    const configAsset = config.assets.find((f) => f.placement === placement);
    if (!configAsset) return null;
    const asset = assets.find((f) => configAsset.id === f.id);
    if (!asset) return null;
    return asset.filename_url;
  }

  const sections = config
    ? [
        {
          title: "Settings",
          items: config.settings
            ? [
                {
                  label: "Ask for preferred location",
                  value:
                    config.settings.preferred_location !== undefined
                      ? config.settings.preferred_location === true
                        ? "Yes"
                        : "No"
                      : "",
                },
                {
                  label: "Lock to location",
                  value:
                    config.settings.location_locked !== undefined
                      ? config.settings.location_locked === true
                        ? "Yes"
                        : "No"
                      : "",
                },
                { label: "Language", value: config.settings.language || "" },
              ].filter((f) => f.value)
            : [],
        },
        {
          title: "Text",
          items: config.text
            ? [
                { label: "Info Button Label", value: config.text.info_button },
                { label: "Info Headline", value: config.text.title },
                { label: "Contest Information", value: config.text.intro },
              ].filter((f) => f.value)
            : [],
        },
        {
          title: "Colors",
          items: config.colors
            ? [
                {
                  label: "Site Button Color",
                  color: true,
                  value: config.colors.base,
                  mantine: true,
                },
                {
                  label: "Background",
                  color: true,
                  value: config.colors.background,
                },
                { label: "Icon", color: true, value: config.colors.icon },
                { label: "Card", color: true, value: config.colors.card },
              ].filter((f) => f.value)
            : [],
        },
        {
          title: "Assets",
          items: config.assets
            ? [
                {
                  label: "Info Modal Featured Image",
                  value: getAssetUrl(1),
                  image: true,
                },
                {
                  label: "Site Background",
                  value: getAssetUrl(4),
                  image: true,
                },
              ].filter((f) => f.value)
            : [],
        },
        {
          title: "Components",
          items: config.components
            ? [
                {
                  label: "Entry Progress Visualization",
                  value: config.components.gauge_variant
                    ? `Variant ${config.components.gauge_variant}`
                    : "",
                },
              ].filter((f) => f.value)
            : [],
        },
        {
          title: "Links",
          items:
            config.links && config.links.length
              ? config.links.map((m) => ({
                  ...m,
                  label: m.text,
                  link: true,
                }))
              : [],
        },
      ].filter((f) => f.items.length)
    : [];

  function getTemplateLinkParams() {
    if (reqData.campaign_effort_id) {
      return `?effortId=${reqData.campaign_effort_id}`;
    } else if (reqData.campaign_id) {
      return `?campaignId=${reqData.campaign_id}`;
    } else if (reqData.contest_id) {
      return `?contestId=${reqData.contest_id}`;
    } else {
      return "";
    }
  }

  return (
    <div key={item.id}>
      <Text fw={600}>{item.title}</Text>
      <Text size="sm">{item.description}</Text>
      {confirm && (
        <Box mt="sm">
          <Text size="sm">
            Applying this configuration will{" "}
            <b>replace the current draft configuration</b>. Are you sure you
            want to do this?
          </Text>
          <Flex mt="sm" gap="xs">
            <Button
              size="xs"
              color="green"
              onClick={onAddClick}
              loading={adding}
              radius="xl"
            >
              I'm sure, apply it
            </Button>
            <Button
              size="xs"
              onClick={() => setConfirm(false)}
              color="gray"
              variant="light"
              radius="xl"
              disabled={adding}
            >
              Get me out of here
            </Button>
          </Flex>
        </Box>
      )}
      {!confirm && (
        <Flex mt="sm" mb="sm" gap="md" align="center">
          <Button
            onClick={() => setConfirm(true)}
            loading={adding}
            color="green"
            size="xs"
            radius="xl"
          >
            Apply this configuration
          </Button>
          <Button
            component={Link}
            to={`/look-and-feel/${id}${getTemplateLinkParams()}`}
            variant="light"
            radius="xl"
            size="xs"
            color="gray"
            leftSection={<IconPencil size={16} />}
          >
            Edit
          </Button>
          <Button
            variant="light"
            color="gray"
            onClick={() => {
              setExpanded(!expanded);
              setEditing(false);
            }}
            leftSection={<IconPalette size={20} />}
            size="xs"
            radius="xl"
          >
            View configuration
          </Button>
        </Flex>
      )}
      {!confirm && (
        <>
          {expanded && (
            <Box mt="lg">
              {!error ? (
                <>
                  {sections.map((section, i) => (
                    <Box key={i} mb={i !== sections.length - 1 ? "md" : 0}>
                      <Text size="sm" mb="xs" fw={600}>
                        {section.title}
                      </Text>
                      <Table striped>
                        <Table.Tbody>
                          {section.items.map((sectionItem, ii) => (
                            <Table.Tr key={ii}>
                              <Table.Td w={250}>{sectionItem.label}</Table.Td>
                              <Table.Td>
                                <Flex gap="xs">
                                  {!sectionItem.color &&
                                    !sectionItem.image &&
                                    !sectionItem.link && (
                                      <>{sectionItem.value}</>
                                    )}
                                  {sectionItem.color && (
                                    <>
                                      <ColorSwatch
                                        color={
                                          sectionItem.mantine
                                            ? `var(--mantine-color-${sectionItem.value}-5)`
                                            : sectionItem.value
                                        }
                                      />
                                      <span>({sectionItem.value})</span>
                                    </>
                                  )}
                                  {sectionItem.image && (
                                    <>
                                      <a
                                        href={sectionItem.value}
                                        target="_blank"
                                      >
                                        <StyledImage
                                          src={sectionItem.value}
                                          title={sectionItem.label}
                                        />
                                      </a>
                                    </>
                                  )}
                                  {sectionItem.link && (
                                    <Anchor
                                      href={sectionItem.url}
                                      target="_blank"
                                      size="sm"
                                    >
                                      {sectionItem.url}
                                    </Anchor>
                                  )}
                                </Flex>
                              </Table.Td>
                            </Table.Tr>
                          ))}
                        </Table.Tbody>
                      </Table>
                    </Box>
                  ))}
                </>
              ) : (
                <Text>{error}</Text>
              )}
            </Box>
          )}
        </>
      )}
    </div>
  );
};

const StyledImage = styled.img`
  height: 75px;
  width: 75px;
  object-fit: cover;
`;

export const LookAndFeelForm = ({ title, description, onSubmit, loading }) => {
  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    setFormValues({
      title,
      description,
    });
  }, []);

  return (
    <div>
      <TextInput
        label="Title"
        required
        value={formValues.title}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            title: e.target.value,
          })
        }
      />
      <TextInput
        label="Short Description"
        required
        value={formValues.description}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            description: e.target.value,
          })
        }
      />
      <Button
        onClick={() => onSubmit(formValues)}
        disabled={!formValues.title || !formValues.description}
        loading={loading}
        mt="sm"
        size="sm"
        fullWidth
      >
        Save
      </Button>
    </div>
  );
};

function LookAndFeelCreate({ reqData }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function onClose() {
    if (loading) return;
    setOpen(false);
    setLoading(false);
  }

  function onSubmit(formValues) {
    const req = {
      ...reqData,
      ...formValues,
    };

    setLoading(true);

    axios
      .post(`/look-and-feel-templates/`, req)
      .then(() => {
        toast.success("Created!");
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        size="xs"
        radius="xl"
        variant="light"
        leftSection={<IconDeviceFloppy size={16} />}
        color="gray"
      >
        Save to Look & Feel Library
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <LookAndFeelForm loading={loading} onSubmit={onSubmit} />
      </Modal>
    </div>
  );
}
