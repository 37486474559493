import React, { useState, useEffect, useRef } from "react";
import { shallow } from "zustand/shallow";
import { Flex, MultiSelect, Button, Select } from "@mantine/core";
import axios from "axios";

import useAppStore from "./report-recipe-runner-store";

import { CustomTooltip } from "@components/shared";

export default function QbSelect({ field }) {
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const {
    initialQueryData,
    locations,
    orgs,
    queryData,
    queryComponents,
    recipeInfo,
    setQueryData,
  } = useAppStore(
    (state) => ({
      initialQueryData: state.initial_query_data,
      locations: state.locations,
      orgs: state.orgs,
      queryData: state.query_data,
      queryComponents: state.query_components,
      recipeInfo: state.recipe_info,
      setQueryData: state.setQueryData,
    }),
    shallow
  );

  const keysToResetOnChange = queryComponents
    .filter((f) => f.request_config)
    .filter(
      (f) =>
        f.request_config?.dynamic_payload?.includes(field.server_name) ||
        f.request_config?.additional_payload?.includes(field.server_name)
    )
    .map((m) => m.server_name)
    .reduce((acc, cur) => {
      acc[cur] = initialQueryData[cur];
      return acc;
    }, {});

  useEffect(() => {
    if (field.options) {
      setItems(field.options);
    } else {
      fetchData();
    }
  }, [JSON.stringify(field)]);

  useEffect(() => {
    if (field.options) return;
    let fetchTheData = true;
    // see if array has length
    if (queryData[field.clientName].length) {
      fetchTheData = false;
    }
    // if (field.multiple) {
    //   if (queryData[field.clientName].length) {
    //     fetchTheData = false;
    //   }
    // } else {
    //   if (queryData[field.clientName]) {
    //     fetchTheData = false;
    //   }
    // }

    if (fetchTheData) {
      fetchData();
    }
  }, [searchValue]);

  function getDisabledStatus() {
    if (!field.request_config) return false;
    if (
      field.type === "effortPicker" ||
      JSON.stringify(field.request_config.dynamic_payload) ===
        `["location_ids","organization_ids"]`
    ) {
      return false;
    }

    let conditions = [];

    field.request_config.dynamic_payload.forEach((k) => {
      if (k === "location_ids") {
        conditions.push(locations.length > 0);
      } else if (k === "organization_ids") {
        conditions.push(orgs.length > 0);
      } else if (Array.isArray(queryData[k])) {
        if (queryData[k].length === 0) {
          conditions.push(false);
        }
      } else {
        if (queryData[k]) {
          conditions.push(true);
        } else {
          conditions.push(false);
        }
      }
    });

    return conditions.includes(false) ? true : false;
  }

  const inputDisabled = getDisabledStatus();
  const dynamicPayloadValues = field.request_config?.dynamic_payload?.map(
    (m) => queryData[m]
  );
  const additionalPayloadValues = field.request_config?.additional_payload?.map(
    (m) => queryData[m]
  );

  useEffect(() => {
    if (field.request_config && field.request_config.dynamic_payload) {
      fetchData();
    }
  }, [JSON.stringify(dynamicPayloadValues)]);

  useEffect(() => {
    if (field.request_config && field.request_config.additional_payload) {
      fetchData();
    }
  }, [JSON.stringify(additionalPayloadValues)]);

  useEffect(() => {
    if (field.request_config) {
      fetchData();
    }
  }, [JSON.stringify(locations), JSON.stringify(orgs)]);

  function fetchData() {
    if (inputDisabled) return;
    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      ...field.request_config.data,
      search_value: searchValue,
      report_recipe_id: recipeInfo.id,
    };

    if (locations) req.location_ids = locations.map((m) => m.id);
    if (orgs) req.organization_ids = orgs.map((m) => m.id);

    if (field.request_config.dynamic_payload) {
      field.request_config.dynamic_payload.forEach((k) => {
        if (k === "location_ids") {
          req.location_ids = locations.map((m) => m.id);
        } else if (k === "organization_ids") {
          req.organization_ids = orgs.map((m) => m.id);
        } else {
          req[k] = queryData[k];
        }
      });
    }

    if (field.request_config.additional_payload) {
      field.request_config.additional_payload.forEach((k) => {
        if (k === "location_ids") {
          req.location_ids = locations.map((m) => m.id);
        } else if (k === "organization_ids") {
          req.organization_ids = orgs.map((m) => m.id);
        } else {
          req[k] = queryData[k];
        }
      });
    }

    axios
      .post(field.request_config.api_endpoint, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        if (field.type === "effortPicker") {
          setItems(data.response);
          // setItems(
          //   data.response.map((m) => ({
          //     ...m,
          //     display_text: m.title,
          //     value: m.id,
          //   }))
          // );
        } else {
          setItems(data.response);
        }
      })
      .catch((err) => {
        setItems([]);
      });
  }

  const formattedOptions = items.map((m) => ({
    label: m.display_text,
    value: `${m.value}`,
  }));

  // console.log(field.label, keysToResetOnChange);
  // console.log(queryData);

  if (field.multiple) {
    return (
      <div>
        <MultiSelect
          labelProps={{
            labelElement: "div",
            style: {
              display: "flex",
              alignItems: "center",
              gap: "6px",
            },
          }}
          label={
            field.tooltip_text ? (
              <CustomTooltip label={field.tooltip_text}>
                <label>{field.label}</label>
              </CustomTooltip>
            ) : (
              <label>{field.label}</label>
            )
          }
          value={queryData[field.clientName]}
          data={formattedOptions}
          disabled={inputDisabled}
          onChange={(e) => {
            setQueryData({
              ...queryData,
              ...keysToResetOnChange,
              [field.clientName]: e,
            });
          }}
          placeholder="Start typing to search..."
          required={field.required}
          searchable
          // searchValue={searchValue}
          // onSearchChange={(e) => setSearchValue(e)}
          limit={25}
        />
        {!inputDisabled && formattedOptions.length > 0 && (
          <Button
            onClick={() =>
              setQueryData({
                ...queryData,
                ...keysToResetOnChange,
                [field.clientName]: formattedOptions.map((m) => m.value),
              })
            }
            size="xs"
            p={0}
            mt="xs"
            variant="subtle"
            color="gray"
          >
            Select all
          </Button>
        )}
      </div>
    );
  } else {
    return (
      <Select
        data={formattedOptions}
        labelProps={{
          labelElement: "div",
          style: {
            display: "flex",
            alignItems: "center",
            gap: "6px",
          },
        }}
        label={
          field.tooltip_text ? (
            <CustomTooltip label={field.tooltip_text}>
              <label>{field.label}</label>
            </CustomTooltip>
          ) : (
            <label>{field.label}</label>
          )
        }
        disabled={inputDisabled}
        required={field.required}
        clearable
        onClear={() =>
          setQueryData({
            ...queryData,
            ...keysToResetOnChange,
            [field.clientName]: [],
          })
        }
        onChange={(e) => {
          if (!e) {
            setQueryData({
              ...queryData,
              ...keysToResetOnChange,
              [field.clientName]: [],
            });
          } else {
            const foundOpt = items.find((f) => f.value === e);
            if (!foundOpt) return;
            const queryDataToSet = foundOpt.query_data_to_set || {};
            queryComponents
              .filter((f) => f.on_select_populate)
              .forEach((qc) => {
                if (queryDataToSet[qc.on_select_populate]) {
                  queryDataToSet[qc.clientName] =
                    queryDataToSet[qc.on_select_populate];
                  delete queryDataToSet[qc.on_select_populate];
                }
              });
            setQueryData({
              ...queryData,
              ...keysToResetOnChange,
              ...queryDataToSet,
              [field.clientName]: [foundOpt.value],
              // [field.clientName]: e,
            });
          }
        }}
        placeholder="Start typing to search..."
        searchable
        // value={queryData[field.clientName]}
        value={
          queryData[field.clientName].length > 0
            ? queryData[field.clientName][0]
            : ""
        }
        searchValue={searchValue}
        onSearchChange={(e) => setSearchValue(e)}
      />
    );
  }
}
