import React, { useState } from "react";
import {
  Badge,
  Card,
  Flex,
  Text,
  Group,
  Table,
  ActionIcon,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import axios from "axios";
import toast from "react-hot-toast";

import { EmptyMessage } from "@components/shared";

export default function TagList({ items = [], fetchData }) {
  if (!items.length) return <EmptyMessage />;

  return (
    <div>
      <Table striped highlightOnHover>
        <Table.Tbody>
          {items.map((item) => (
            <Table.Tr key={item.id}>
              <Table.Td>{item.tag.name}</Table.Td>
              <Table.Td style={{ display: "flex", justifyContent: "flex-end" }}>
                <RemoveButton id={item.id} onSuccess={fetchData} />
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </div>
  );
}

const RemoveButton = ({ id, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    axios
      .post(`/delete-tagging/`, { tagging_id: id })
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <ActionIcon
      color="red"
      variant="light"
      radius="xl"
      size="sm"
      onClick={onClick}
      loading={loading}
    >
      <IconTrash />
    </ActionIcon>
  );
};
