import React, { useState } from "react";
import { Card, Title } from "@mantine/core";

import { KeywordForm, KeywordList } from "./";
import { ManagementLayout } from "@components/shared";
import KeywordFormSetup from "./KeywordFormSetup";

export default function KeywordManagement({
  additionalCreateData = {},
  campaignId,
  contestId,
  effortId,
  locationId,
  organizationId,
  minStartDate,
  maxEndDate,
  showCreate = true,
}) {
  const [refresh, setRefresh] = useState(false);

  const req = {
    refresh,
  };

  if (effortId) req.campaign_effort_id = effortId;
  if (campaignId) req.campaign_id = campaignId;
  if (contestId) req.contest_id = contestId;
  if (organizationId) req.organization_id = organizationId;
  if (locationId) req.location_id = locationId;

  if (!showCreate) {
    return (
      <KeywordList
        refresh={refresh}
        requestInfo={{
          url: `/retrieve-keywords/`,
          data: req,
        }}
      />
    );
  }

  return (
    <ManagementLayout
      dataComponent={
        <KeywordList
          refresh={refresh}
          requestInfo={{
            url: `/retrieve-keywords/`,
            data: req,
          }}
        />
      }
      createComponent={
        <Card
          style={{
            overflow: "visible",
          }}
        >
          <Title order={4}>Create Keyword</Title>
          {!organizationId && !locationId ? (
            <KeywordForm
              additionalReqData={additionalCreateData}
              campaignId={campaignId}
              contestId={contestId}
              effortId={effortId}
              minStartDate={minStartDate}
              maxEndDate={maxEndDate}
              onSuccess={() => setRefresh(!refresh)}
            />
          ) : (
            <KeywordFormSetup
              locationId={locationId}
              organizationId={organizationId}
              onSuccess={() => setRefresh(!refresh)}
            />
          )}
        </Card>
      }
    />
  );
}
