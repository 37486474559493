import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { ActionIcon, Badge, Modal, Button } from "@mantine/core";
import { IconStereoGlasses } from "@tabler/icons-react";

import { AssetManagement, AssetLibraryPicker } from "@components/Asset";
import EffortSlider from "@components/Microsite/EffortSlider";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  addFileToConfig,
  formatPlacements,
  renameFileInConfig,
  removeFileInConfig,
} from "@components/Asset/helpers";
import { getUuid } from "@util/getUuid";
import { placementDefs } from "@components/Asset/placementDefs";

export default function EffortAssets({
  effortId,
  effortName,
  fetchEffort,
  uploadOnly = false,
}) {
  const queryClient = useQueryClient();
  const queryKey = `efforts${effortId}Configuration`;
  const assetsQueryKey = `efforts${effortId}Assets`;

  const { data: jsonData } = useQuery({
    queryKey: [queryKey],
    queryFn: async () =>
      axios
        .get(`/efforts/${effortId}/configuration/`)
        .then(({ data }) => {
          return data.response[0];
        })
        // .then(() => fetchAssets())
        .catch((err) => {
          throw err;
        }),
  });

  const { data: assets } = useQuery({
    queryKey: [assetsQueryKey],
    queryFn: async () =>
      axios
        .post(`/retrieve-assets/`, {
          campaign_effort_id: effortId,
          pagination: false,
        })
        .then(({ data }) =>
          data.response[0].data.map((d) => ({
            ...d,
            ...d.asset,
          }))
        ),
  });

  const fetchData = () => queryClient.invalidateQueries([queryKey]);
  const fetchAssets = () => queryClient.invalidateQueries([assetsQueryKey]);

  const placements = [
    {
      text: "Featured",
      value: placementDefs.featured,
      limit: 1,
      preview: (assets) => (
        <PreviewItem assets={assets} effortName={effortName} />
      ),
    },
    { text: "Slider", value: placementDefs.slider, limit: 10 },
    // { text: "Side", value: placementDefs.side, limit: 1 },
  ]
    .map((m) => ({
      ...m,
      key: getUuid(),
    }))
    .filter((f) => (uploadOnly ? !["Slider"].includes(f.text) : true));

  function updateConfig(newConfig, hardReload = false) {
    const req = {
      configuration: { ...jsonData, ...newConfig },
    };

    axios
      .put(`/efforts/${effortId}/update-config/`, req)
      .then(() => {
        if (hardReload) {
          window.location.reload();
        } else {
          fetchData();
          if (fetchEffort) {
            fetchEffort();
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  }

  function onUploadSuccess(newId, placementValue) {
    updateConfig(addFileToConfig(jsonData, newId, placementValue));
  }

  function onRemoveSuccess(removedId) {
    updateConfig(removeFileInConfig(jsonData, removedId), true);
  }

  function onRenameSuccess(assetId, newName) {
    updateConfig(renameFileInConfig(jsonData, { id: assetId, name: newName }));
  }

  function onLibrarySelection(itemId, placementValue) {
    const req = { campaign_effort_id: effortId, asset_id: itemId, variety: 2 };

    axios
      .post(`/replicate-library-asset/`, req)
      .then(({ data }) => {
        onUploadSuccess(data.response[0].id, placementValue);
      })
      .catch((err) => {
        fetchAssets();
        toast.error(err);
      });
  }

  function onExternalUrlUpdate(assetId, newUrl) {
    updateConfig(
      renameFileInConfig(jsonData, { id: assetId, external_url: newUrl })
    );
  }

  if (!jsonData || !assets) return null;
  const formattedPlacements = formatPlacements(placements, jsonData, assets);

  return (
    <React.Fragment>
      {formattedPlacements.map((p) => (
        <AssetManagement
          library={false}
          items={p.assets}
          effortId={effortId}
          title={
            <React.Fragment>
              {p.text}{" "}
              {p.limit && (
                <Badge variant="light" color="yellow">
                  Limit of {p.limit}
                </Badge>
              )}
            </React.Fragment>
          }
          key={p.value}
          limit={p.limit}
          preview={p.preview}
          onRenameSuccess={onRenameSuccess}
          onExternalUrlUpdate={onExternalUrlUpdate}
          onRemoveSuccess={onRemoveSuccess}
          onUploadSuccess={(newId) => onUploadSuccess(newId, p.value)}
          shouldFetch={false}
          showExternalUrl={p.value === placementDefs.slider}
          uploadOnly={uploadOnly}
        >
          {p.assets.length < p.limit && (
            <AssetLibraryPicker
              onClose={fetchAssets}
              onSelect={(assetId) => onLibrarySelection(assetId, p.value)}
              reqData={{
                variety: [3, 4],
                campaign_effort_id: effortId,
              }}
            />
          )}
        </AssetManagement>
      ))}
    </React.Fragment>
  );
}

const PreviewItem = ({ assets = [], effortName = "Test Name" }) => {
  const [isOpen, setOpen] = useState(false);

  if (!assets.length) return null;

  return (
    <React.Fragment>
      <ActionIcon
        size="md"
        title="Preview"
        variant="light"
        color="green"
        radius="lg"
        onClick={() => setOpen(true)}
      >
        <IconStereoGlasses
          style={{ width: "70%", height: "70%" }}
          stroke={1.5}
        />
      </ActionIcon>
      <Modal
        size="lg"
        opened={isOpen}
        onClose={() => {
          setOpen(false);
        }}
      >
        <EffortSlider
          efforts={[
            {
              title: effortName,
              category: "effort",
              image: assets[0].filename_url,
              campaign_effort_id: assets[0].id,
              key: assets[0].id,
            },
          ]}
        />
      </Modal>
    </React.Fragment>
  );
};
