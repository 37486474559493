import React, { useState, useEffect } from "react";
import { Space } from "@mantine/core";

import { SongQuerySelect } from "@components/SongQuery";
import SongReportTable from "./SongReportTable";

export default function SongReport({ locationId }) {
  const [categories, setCategories] = useState([]);

  return (
    <div>
      <SongQuerySelect
        reqData={{
          location_id: locationId,
          variety: 1,
        }}
        onChange={(e) => setCategories(e)}
      />
      <Space mt="xs" />
      {categories.length > 0 && (
        <SongReportTable
          reqData={{
            location_id: locationId,
            song_query_ids: categories,
            report_type: "rotation_horizontal_agg",
          }}
        />
      )}
    </div>
  );
}
