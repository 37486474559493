import React, { useState } from "react";
import axios from "axios";
import { Group, Text, Button, Modal } from "@mantine/core";
import toast from "react-hot-toast";

export default function AuctionNewWinner({ contestId, reqData, fetchData }) {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setLoading(false);
    setOpen(false);
  }

  function onClick() {
    setLoading(true);

    const req = { ...reqData };

    axios
      .post(`/contests/${contestId}/new-auction-winner/`, req)
      .then(() => {
        setLoading(false);
        onClose();
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      <Button
        variant="light"
        color="gray"
        mt="sm"
        size="xs"
        onClick={() => setOpen(true)}
      >
        Decline
      </Button>
      <Modal opened={isOpen} onClose={onClose} title="Decline Winning Bid">
        <Text mb="lg" size="lg">
          Declining a winning bid will choose a new winning bid. Are you sure
          you want to do this?
        </Text>
        <Button
          size="md"
          color="red"
          fullWidth
          loading={loading}
          onClick={onClick}
        >
          I'm sure. Decline it.
        </Button>
        <Group justify="center" mt="xs" onClick={onClose}>
          <Button variant="subtle" color="gray" size="xs">
            Cancel
          </Button>
        </Group>
      </Modal>
    </React.Fragment>
  );
}
