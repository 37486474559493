import React from "react";
import { Grid } from "@mantine/core";
import styled from "styled-components";

export default function ManagementLayout({ dataComponent, createComponent }) {
  return (
    <Grid>
      <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>{dataComponent}</Grid.Col>
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <div className="sticky">{createComponent}</div>
      </Grid.Col>
    </Grid>
  );
}

const Layout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
  align-items: start;
  margin: 1em 0;

  .sticky {
    position: sticky;
    top: 10px;
    /* max-height: 200px; */
  }
`;
