import React from "react";
import { useSelector } from "react-redux";

import SongQueryList from "./SongQueryList";

export default function SongQueryManagement({ locationId }) {
  const managerInfo = useSelector((state) => state.manager);

  const formattedLocationId = locationId
    ? locationId
    : managerInfo && managerInfo.location_id
    ? managerInfo.location_id
    : null;

  if (!formattedLocationId) return <p>Missing location ID</p>;

  return <SongQueryList locationId={formattedLocationId} />;
}
