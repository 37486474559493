import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select } from "@mantine/core";

const UserInteractionEffortSelect = ({
  onChange,
  value = null,
  organization,
  location,
  id,
}) => {
  const [effortData, setEffortData] = useState([]);

  function fetchData() {
    if (effortData.length > 0) return;

    axios
      .post(`/user-joins/${id}/retrieve-efforts/`, { user_location: location })
      .then(({ data }) => {
        setEffortData(data.response);
      })
      .catch((err) => {
        setEffortData([]);
      });
  }

  const formattedData = effortData.map((m) => ({
    label: m.name,
    value: m.id,
  }));

  return (
    <Select
      searchable
      data={formattedData}
      placeholder="Select an effort..."
      onChange={(e) => onChange(e)}
      value={value}
      onClick={() => fetchData()}
    />
  );
};

export default UserInteractionEffortSelect;
