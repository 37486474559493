import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter,
  Route,
  Link,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { shallow } from "zustand/shallow";
import {
  Anchor,
  Box,
  Divider,
  Flex,
  MantineProvider,
  NavLink,
  Text,
  ThemeIcon,
  Title,
  UnstyledButton,
  useMantineTheme,
  useMantineColorScheme,
} from "@mantine/core";
import { Toaster } from "react-hot-toast";
import { useSelector, connect } from "react-redux";
import { IconSun, IconQuestionMark, IconMoonStars } from "@tabler/icons-react";
import { useLocalStorage } from "@mantine/hooks";
import styled from "styled-components";

import { MessagingContainer } from "./";
import { StationUserInteract } from "@components/Station";
import { BoxWrapper } from "@components/shared";
import { UserLockModal, UserDeleteAccount } from "@components/User";
import { SET_LOCATION_SETTINGS } from "@duck/locationSettings";
import stationUserAppStore from "@duck/station-user-app-store";

const UserDashboard = React.lazy(() => import("@pages/User/UserDashboard"));
const UserSweepstakesDetail = React.lazy(() =>
  import("@pages/User/UserSweepstakesDetail")
);
const UserEarnIndex = React.lazy(() => import("@pages/User/UserEarnIndex"));
const UserEffortDetail = React.lazy(() =>
  import("@pages/User/UserEffortDetail")
);
const UserPointsIndex = React.lazy(() => import("@pages/User/UserPointsIndex"));
const UserWinIndex = React.lazy(() => import("@pages/User/UserWinIndex"));
const UserPrizePoolDetail = React.lazy(() =>
  import("@pages/User/UserPrizePoolDetail")
);
const UserProfile = React.lazy(() => import("@pages/User/UserProfile"));
const UserAuctionDetail = React.lazy(() =>
  import("@pages/User/UserAuctionDetail")
);
const UserArtistDetail = React.lazy(() =>
  import("@pages/User/UserArtistDetail")
);
const UserRatingDetail = React.lazy(() =>
  import("@pages/User/UserRatingDetail")
);

import { UserArtists, UserRatings } from "@components/User";
import { LogoutButton } from "@components/Auth";
import { ImageSlider, SuspenseWrapper } from "@components/shared";

const links = [
  { text: "Dashboard", path: `/` },
  { text: "Win", path: `/win` },
  { text: "Earn", path: `/earn` },
  { text: "Messaging", path: `/messaging` },
  { text: "Points", path: `/points` },
  { text: "Profile", path: `/profile` },
  // { text: "Ratings", path: `/ratings` },
  // { text: "Artists", path: `/artists` },
].map((m, i) => ({
  ...m,
  key: i,
}));

const boxProps = {
  style: {
    paddingTop: "1em",
  },
};

function UserContainer({ dispatch, locationSettings }) {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(null);
  const [bannerImages, setBannerImages] = useState([]);
  const [modalInfo, setModalInfo] = useState(null);

  const userInfo = useSelector((state) => state.user);
  const theme = useMantineTheme();
  const { setColorScheme, clearColorScheme, colorScheme } =
    useMantineColorScheme();
  const isDark = colorScheme === "dark";

  const { coords, setCoords } = stationUserAppStore(
    (state) => ({
      coords: state.coords,
      setCoords: state.setCoords,
    }),
    shallow
  );

  useEffect(() => {
    clearColorScheme();
    setColorScheme("dark");
    fetchSettings();
    fetchGeolocation();
  }, []);

  axios.interceptors.response.use(function (response) {
    const res = response.data;
    if (res.response_code === 3) {
      setModalInfo(res.response[0]);
      return response;
    } else {
      return response;
    }
  });

  function fetchSettings() {
    axios
      .get(
        `/locations/${userInfo.current_user_hash.location_id}/site-settings/`
      )
      .then(({ data }) => {
        setSettings(
          data.response[0].settings.settings
            ? data.response[0].settings.settings
            : {}
        );
        setBannerImages(data.response[0].banner_images);
        dispatch({
          type: SET_LOCATION_SETTINGS,
          payload: data.response[0],
        });
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        //
      });
  }

  function fetchGeolocation() {
    const successCallback = (position) => {
      setCoords({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    };

    const errorCallback = (error) => {
      setCoords(null);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0,
    });
  }

  if (loading || !settings) return null;

  const AppWrapper = ({ children }) => {
    return (
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        // defaultColorScheme="dark"
        theme={{
          components: {
            Card: {
              defaultProps: {
                radius: "md",
                p: "sm",
                withBorder: true,
              },
            },
          },
          defaultGradient: {
            from: settings.color || "blue",
            to: "red",
            deg: 45,
          },
          fontFamily: theme.fontFamily,
          fontFamilyMonospace: theme.fontFamilyMonospace,
          headings: {
            ...theme.headings,
            fontFamily: theme.fontFamily,
          },
          // colorScheme: colorScheme,
          primaryColor: settings.color || "blue",
          // primaryColor: colors ? colors.base : "orange",
        }}
      >
        <Box>{children}</Box>
      </MantineProvider>
    );
  };

  if (userInfo) {
    return (
      <AppWrapper>
        <Toaster />
        <UserLockModal data={modalInfo} />
        <Header
          locationId={userInfo.current_user_hash.location_id}
          locationSettings={locationSettings}
        />
        <BrowserRouter>
          <Nav />
          <Box>
            <SuspenseWrapper>
              <Routes>
                {/* <Route index element={<UserDashboard />} /> */}
                <Route
                  index
                  element={
                    <React.Fragment>
                      {bannerImages.length > 0 && (
                        <Box
                          mb="lg"
                          style={{
                            width: "100%",
                          }}
                        >
                          <ImageSlider
                            sliderProps={{
                              withControls: false,
                              withIndicators: false,
                              draggable: false,
                            }}
                            items={bannerImages.map((m) => ({
                              src: m.filename_url,
                              external_link_url: m.external_link_url,
                            }))}
                            imageFormatter={(d) => (
                              <img
                                src={d.src}
                                style={{
                                  maxHeight: "300px",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            )}
                          />
                        </Box>
                      )}
                      <BoxWrapper>
                        <UserDashboard
                          hasBannerImages={bannerImages.length > 0}
                        />
                      </BoxWrapper>
                    </React.Fragment>
                  }
                />
                <Route path="/user-login/*" element={<Navigate to="/" />} />
                <Route
                  path="/win"
                  element={
                    <BoxWrapper {...boxProps}>
                      <UserWinIndex />
                    </BoxWrapper>
                  }
                />
                <Route
                  path="/earn"
                  element={
                    <BoxWrapper {...boxProps}>
                      <UserEarnIndex />
                    </BoxWrapper>
                  }
                />
                <Route
                  path="/earn/efforts/:id/*"
                  element={
                    <BoxWrapper {...boxProps}>
                      <UserEffortDetail />
                    </BoxWrapper>
                  }
                />
                <Route
                  path="/auctions/:id/*"
                  element={
                    <BoxWrapper {...boxProps}>
                      <UserAuctionDetail />
                    </BoxWrapper>
                  }
                />
                <Route
                  path="/prize-pools/:id/*"
                  element={
                    <BoxWrapper {...boxProps}>
                      <UserPrizePoolDetail />
                    </BoxWrapper>
                  }
                />
                <Route
                  path="/sweepstakes/:id/*"
                  element={
                    <BoxWrapper {...boxProps}>
                      <UserSweepstakesDetail />
                    </BoxWrapper>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <BoxWrapper {...boxProps}>
                      <UserProfile />
                      <Divider mt="sm" mb="sm" />
                      <UnstyledButton
                        onClick={() =>
                          setColorScheme(isDark ? "light" : "dark")
                        }
                      >
                        <Flex>
                          <ThemeIcon
                            variant="subtle"
                            color="gray"
                            title="Toggle color scheme"
                            mr="sm"
                          >
                            {isDark ? (
                              <IconSun size={18} />
                            ) : (
                              <IconMoonStars size={18} />
                            )}
                          </ThemeIcon>
                          <Text>
                            Switch to {isDark ? "light" : "dark"} mode
                          </Text>
                        </Flex>
                      </UnstyledButton>
                      <Divider mt="sm" mb="sm" />
                      <Flex>
                        <ThemeIcon
                          variant="subtle"
                          color="gray"
                          title="Toggle color scheme"
                          mr="sm"
                        >
                          <IconQuestionMark />
                        </ThemeIcon>
                        <Anchor
                          style={{
                            color: "inherit",
                          }}
                          component={Link}
                          target="_blank"
                          to={`/submit-trouble-ticket`}
                        >
                          Need help?
                        </Anchor>
                      </Flex>
                      <Divider mt="sm" mb="sm" />
                      <UserDeleteAccount />
                      <Divider mt="sm" mb="sm" />
                      <LogoutButton text="Logout" />
                    </BoxWrapper>
                  }
                />
                <Route
                  path="/points"
                  element={
                    <BoxWrapper {...boxProps}>
                      <UserPointsIndex />
                    </BoxWrapper>
                  }
                />
                <Route path="/messaging" element={<MessagingContainer />} />
                {/* <Route
                path="/artists"
                element={
                  <BoxWrapper {...boxProps}>
                    <UserArtists
                      userLocationId={userInfo.user_location_id}
                      userOrgId={userInfo.user_organization_id}
                    />
                  </BoxWrapper>
                }
              />
              <Route
                path="/artists/:id/*"
                element={
                  <BoxWrapper {...boxProps}>
                    <UserArtistDetail />
                  </BoxWrapper>
                }
              />
              <Route
                path="/ratings"
                element={
                  <BoxWrapper {...boxProps}>
                    <UserRatings
                      userLocationId={userInfo.user_location_id}
                      userOrgId={userInfo.user_organization_id}
                    />
                  </BoxWrapper>
                }
              />
              <Route
                path="/ratings/:id/*"
                element={
                  <BoxWrapper {...boxProps}>
                    <UserRatingDetail />
                  </BoxWrapper>
                }
              /> */}
                <Route
                  path="*"
                  element={
                    <BoxWrapper {...boxProps}>
                      <NoMatch />
                    </BoxWrapper>
                  }
                />
              </Routes>
            </SuspenseWrapper>
          </Box>
        </BrowserRouter>
      </AppWrapper>
    );
  }

  return null;
}

function mapStateToProps(state) {
  return {
    locationSettings: state.locationSettings,
  };
}
export default connect(mapStateToProps)(UserContainer);

const NoMatch = () => (
  <React.Fragment>
    <Title order={1} color="red">
      404
    </Title>
    <Text>Page not found</Text>
  </React.Fragment>
);

const Header = ({ locationSettings, locationId }) => {
  const showPlayer =
    locationSettings &&
    locationSettings.settings &&
    locationSettings.settings.settings &&
    locationSettings.settings.settings.stream_url;

  return (
    <Box
      style={{
        backgroundColor: "var(--mantine-color-dark-9)",
      }}
    >
      <StationUserInteract
        name={locationSettings.name}
        logo={locationSettings.logo_url}
        locationId={locationId}
      >
        {showPlayer && (
          <Box mt="xs">
            <StyledAudio>
              <audio
                controls
                autoPlay={
                  locationSettings.settings.settings.autoplay_stream === true
                }
              >
                <source src={locationSettings.settings.settings.stream_url} />
                Your browser doesn't support radio streaming
              </audio>
            </StyledAudio>
          </Box>
        )}
      </StationUserInteract>
    </Box>
  );
};

const Nav = () => {
  const { pathname } = useLocation();

  return (
    <Box
      style={{
        backgroundColor: "var(--mantine-color-dark-9)",
      }}
    >
      <Divider mb="sm" opacity={0.25} />
      <Flex
        style={{
          overflowX: "scroll",
          padding: "0 0 1em 0.5em",
        }}
        gap="xs"
      >
        {links.map((l) => {
          const isActive =
            pathname === "/" && l.path === "/"
              ? true
              : l.path !== "/" && pathname.indexOf(l.path) > -1;
          return (
            <NavLink
              key={l.key}
              to={l.path}
              active={isActive}
              component={Link}
              label={l.text}
              style={(theme) => ({
                textAlign: "center",
                borderRadius: "12px",
                color: "var(--mantine-color-gray-2)",
                fontWeight: 700,
                background: isActive
                  ? theme.colors[theme.primaryColor][8]
                  : "var(--mantine-color-dark-6)",

                "&:hover": {
                  background: "var(--mantine-color-dark-4)",
                  color: "var(--mantine-color-gray-4)",
                },
              })}
            />
          );
        })}
      </Flex>
    </Box>
  );
};

const StyledAudio = styled.div`
  audio {
    border-radius: 1em;
    width: 100%;
  }

  audio::-webkit-media-controls-timeline,
  video::-webkit-media-controls-timeline {
    display: none;
  }

  audio::-webkit-media-controls-current-time-display,
  audio::-webkit-media-controls-time-remaining-display {
    display: none;
  }
`;
