import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Card,
  Group,
  Pagination,
  Table,
  Text,
  Space,
  LoadingOverlay,
  TextInput,
  Flex,
  ActionIcon,
} from "@mantine/core";
import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  flexRender,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import axios from "axios";
import {
  IconSortAscending,
  IconSortDescending,
  IconPlayerSkipBack,
} from "@tabler/icons-react";
import toast from "react-hot-toast";

import { PointAssignment } from "./";

const varietyFormatted = {
  1: "song rating",
  2: "survey response",
  3: "check-in",
  10: "correction",
};

export default function PointLedger({
  userLocationId,
  userOrgId,
  actionable = false,
}) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [sorting, setSorting] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const joinData = {
    user_location_id: userLocationId,
    user_organization_id: userOrgId,
  };

  Object.keys(joinData).forEach((k) => {
    if ([null, undefined].includes(joinData[k])) delete joinData[k];
  });

  const columns = useMemo(
    () =>
      [
        {
          header: () => "Date",
          accessorKey: "date",
          cell: (info) => (
            <React.Fragment>
              {new Date(info.row.original.created_at).toLocaleString()}
            </React.Fragment>
          ),
        },
        {
          header: () => "Points",
          accessorKey: "value",
          cell: (info) => info.getValue(),
        },
        {
          header: () => "Type",
          accessorKey: "variety",
          id: "variety",
          cell: (info) => (
            <React.Fragment>
              {info.row.original.variety_formatted}
            </React.Fragment>
          ),
        },
        {
          header: () => "Entered by",
          accessorKey: "entered_by",
          id: "author",
          cell: (info) => info.getValue(),
        },
        {
          header: () => "Comments",
          accessorKey: "comment",
          id: "comment",
          cell: (info) => (
            <React.Fragment>
              {info.row.original.rev_referral_date ? (
                <React.Fragment>
                  Reversal of {Math.abs(info.row.original.value)} point entry
                  created on{" "}
                  {new Date(
                    info.row.original.rev_referral_date
                  ).toLocaleString()}
                </React.Fragment>
              ) : (
                <React.Fragment>{info.getValue()}</React.Fragment>
              )}
            </React.Fragment>
          ),
          enableSorting: false,
        },
        {
          header: () => "",
          accessorKey: "",
          id: "actions",
          cell: (info) => (
            <React.Fragment>
              {!info.row.original.reversed && (
                <ReverseButton
                  onSuccess={fetchData}
                  entryId={info.row.original.id}
                />
              )}
            </React.Fragment>
          ),
          enableSorting: false,
        },
      ].filter((f) =>
        !actionable ? !["actions", "author"].includes(f.id) : true
      ),
    []
  );

  const table = useReactTable({
    data: items,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  useEffect(() => {
    if (searchValue.length >= 2) {
      fetchData();
    }
  }, [searchValue]);

  useEffect(() => {
    fetchData();
  }, [userLocationId, userOrgId, JSON.stringify(table.getState())]);

  function fetchData() {
    // setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      ...joinData,
      sorting,
      page_size: table.getState().pagination.pageSize,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
    };

    if (searchValue) req.search_value = searchValue;

    Object.keys(req).forEach((k) => {
      if ([null, undefined].includes(req[k])) delete req[k];
    });

    if (!Object.keys(req).length) return setLoading(false);

    axios
      .post(`/retrieve-point-ledger/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLoading(false);
        setItems(data.response[0].data);
        setPageCount(data.response[0].page_count);
        if (data.response[0].total !== undefined) {
          setTotal(data.response[0].total);
        } else {
          setTotal(data.response[0].data.length);
        }

        setTotalPoints(data.response[0].point_total);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  return (
    <div>
      {/* <TextInput
        placeholder="Search ledger"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        mb="md"
      /> */}
      <Flex mb="xs">
        <Text size="lg" mr="lg" fw={600}>
          Total Points: {totalPoints}
        </Text>
        {actionable && (
          <PointAssignment reqData={joinData} fetchData={fetchData} />
        )}
      </Flex>
      <LoadingOverlay visible={loading} />
      <Table striped highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            {table.getFlatHeaders().map((header) => (
              <Table.Th
                key={header.id}
                style={{
                  width: header.column.columnDef.width,
                }}
              >
                <div
                  {...{
                    onClick: header.column.getToggleSortingHandler(),
                    style: {
                      cursor: header.column.getCanSort() ? "pointer" : "",
                    },
                  }}
                >
                  <Group>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <IconSortAscending size={16} />,
                      desc: <IconSortDescending size={16} />,
                    }[header.column.getIsSorted()] ?? null}
                  </Group>
                </div>
              </Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {!loading && table.getRowModel().rows.length === 0 && (
            <Table.Tr>
              <td colSpan="5">No point entries yet</td>
            </Table.Tr>
          )}
          {table.getRowModel().rows.map((row) => (
            <Table.Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Td
                  key={cell.id}
                  style={{
                    width: `${cell.column.columnDef.minSize}px`,
                    // maxWidth: `${cell.column.columnDef.minSize}px`,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Td>
              ))}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      <Pagination
        value={table.getState().pagination.pageIndex + 1}
        onChange={(e) => {
          table.setPageIndex(e - 1);
          // table.nextPage();
        }}
        total={pageCount}
        mt="lg"
      />
    </div>
  );
}

const ReverseButton = ({ entryId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {
      point_ledger_id: entryId,
    };

    axios
      .post(`/points-ledger-reversal/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        toast.success("Reversed!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <ActionIcon
      size="xs"
      onClick={onClick}
      loading={loading}
      title="Reverse entry"
      variant="subtle"
    >
      <IconPlayerSkipBack />
    </ActionIcon>
  );
};
