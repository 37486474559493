import React from "react";
import { Anchor, Space } from "@mantine/core";
import { Link } from "react-router-dom";

import { ContestList, ContestManagement } from "@components/Contest";

export default function ContestIndex() {
  return (
    <div>
      <ContestList />
      <Space mt="xl" mb="xl" />
      <Anchor c="gray" size="sm" component={Link} to={`/contest-replication`}>
        Manage Contest replication
      </Anchor>
    </div>
  );
}
