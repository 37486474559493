import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Badge,
  Grid,
  Card,
  Flex,
  Text,
  Title,
  Space,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { IconDeviceFloppy } from "@tabler/icons-react";

import { ReportRecipeVizIcon, ReportRecipeUnpinButton } from "./";

import { tableCategories } from "./helpers";

const tableCategoryConfig = tableCategories.reduce((acc, cur) => {
  acc[cur.value] = {
    color: cur.color,
    text: cur.text,
  };
  return acc;
}, {});

export default function PinnedReportRecipeList({ reqData = {} }) {
  const [pins, setPins] = useState([]);
  const theme = useMantineTheme();

  useEffect(() => {
    fetchPins();
  }, []);

  function fetchPins() {
    const req = {
      ...reqData,
    };

    axios
      .post(`/retrieve-pinned-recipes/`, req)
      .then(({ data }) => {
        setPins(data.response);
      })
      .catch((err) => {
        setPins([]);
      });
  }

  if (pins.length === 0) return null;

  return (
    <div>
      <Title order={5} mb="sm">
        Pinned Report Recipes
      </Title>
      <Grid>
        {pins.map((pin, i) => {
          // const baseColor = "blue";
          const baseColor = pin.table_categories.length
            ? tableCategoryConfig[`${pin.table_categories[0]}`].color
            : "blue";
          return (
            <Grid.Col span={{ base: 12, md: 4 }} key={i}>
              <Card
                shadow="xs"
                component={Link}
                to={`/report-recipes/${
                  pin.sr_recipe_id || pin.report_recipe_id
                }${
                  pin.saved_report_recipe_id
                    ? `?savedReportRecipeId=${pin.saved_report_recipe_id}`
                    : ""
                }`}
                target="_blank"
                key={i}
                style={{ minHeight: rem(300) }}
              >
                <Card.Section
                  style={{
                    background: `var(--mantine-color-${baseColor}-1)`,
                    minHeight: "150px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  p="lg"
                  mb="sm"
                >
                  <Flex gap="sm" align="center">
                    {pin.visualizers_formatted.map((m, i) => {
                      const iconProps = {
                        size: 64,
                        color: `var(--mantine-color-${baseColor}-3)`,
                      };
                      return (
                        <ReportRecipeVizIcon
                          key={i}
                          vizName={m}
                          iconProps={iconProps}
                        />
                      );
                    })}
                  </Flex>
                  <Flex gap="xs" mt="sm" align="center">
                    <Text
                      size="xl"
                      c={`var(--mantine-color-${baseColor}-8)`}
                      fw={900}
                    >
                      {pin.saved_recipe_title || pin.report_title}
                    </Text>
                    {pin.saved_recipe_title && (
                      <IconDeviceFloppy
                        size={20}
                        color={`var(--mantine-color-${baseColor}-8)`}
                      />
                    )}
                  </Flex>
                </Card.Section>
                <Flex gap="sm" align="center">
                  <div style={{ flexGrow: 1 }}>
                    {pin.description && (
                      <Text size="sm">{pin.description}</Text>
                    )}
                    {pin.saved_recipe_description && (
                      <Text
                        size="xs"
                        mt="sm"
                        style={{
                          padding: "10px 10px 10px 8px",
                          background: "var(--mantine-color-gray-0)",
                          borderRadius: "5px",
                        }}
                      >
                        {pin.saved_recipe_description}
                      </Text>
                    )}
                    <Text size="xs" fw={600} mt="lg">
                      Providing insights into:
                    </Text>
                    <Flex gap="xs" mt="xs" wrap="wrap">
                      {pin.table_categories_formatted.map((m, i) => (
                        <Badge size="xs" key={i} color="gray" variant="light">
                          {m}
                        </Badge>
                      ))}
                      {pin.action_categories_formatted.map((m, i) => (
                        <Badge size="xs" key={i} color="gray" variant="light">
                          {m}
                        </Badge>
                      ))}
                    </Flex>
                  </div>
                </Flex>
              </Card>
              <Space mt="xs" />
              <Flex justify="flex-end" pr={20}>
                <ReportRecipeUnpinButton pinId={pin.id} onSuccess={fetchPins} />
              </Flex>
            </Grid.Col>
          );
        })}
      </Grid>
    </div>
  );
}
