import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Flex, Alert, Grid, Space, Divider } from "@mantine/core";
import { useParams, useMatch, Route, Routes } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  ErrorView,
  PageTitle,
  Subnav,
  EntitySubtitles,
} from "@components/shared";
import {
  BulkCommunicationAlerts,
  BulkCommunicationForm,
  BulkCommunicationMailerStatus,
  BulkCommunicationMessages,
  BulkCommunicationStatus,
  BulkCommunicationTemplate,
} from "@components/BulkCommunication";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function BulkCommunicationDetail() {
  const queryClient = useQueryClient();
  const params = useParams();
  const { id } = params;
  const queryKey = `bulkCommunication${id}`;
  const statusQueryKey = `bulkCommunication${id}Status`;
  const [refreshMessages, setRefreshMessages] = useState(false);

  const { failureReason, data: info } = useQuery({
    queryKey: [queryKey],
    queryFn: async () =>
      axios
        .get(`/bulk-communicators/${params.id}/`)
        .then(({ data }) => {
          return data.response[0];
        })
        .catch((err) => {
          throw err;
        }),
  });

  const { statusfailureReason, data: statusInfo } = useQuery({
    queryKey: [statusQueryKey],
    refetchInterval: 5000,
    queryFn: async () =>
      axios
        .post(`/bulk-communicators/${params.id}/data-grabber/`, {
          bulk_communicator_id: id,
          context: "polling",
        })
        .then(({ data }) => {
          if (data.response[0].adding_messages_complete !== refreshMessages) {
            setRefreshMessages(!data.response[0].adding_messages_complete);
          }
          return data.response[0];
        })
        .catch((err) => {
          throw err;
        }),
  });

  const fetchData = () =>
    queryClient.invalidateQueries([queryKey, statusQueryKey]);

  if (failureReason) {
    return <ErrorView text={failureReason} />;
  }

  if (!info || !statusInfo) return null;

  const links = [
    { to: `/bulk-communication/${id}`, text: "Detail " },
    { to: `/bulk-communication/${id}/messages`, text: "Messages" },
    { to: `/bulk-communication/${id}/template`, text: "Template" },
  ];

  return (
    <div>
      <PageTitle
        title={info.title}
        subtitle={<React.Fragment>Bulk Communication</React.Fragment>}
        icon={entityIcon.send()}
        iconColor={entityColor.send}
        style={{ marginBottom: 0 }}
      />
      {info.created_by && (
        <PageTitle
          icon={
            info.created_by.class_name === "Manager"
              ? entityIcon.manager()
              : entityIcon.admin()
          }
          iconColor={
            info.created_by.class_name === "Manager"
              ? entityColor.manager
              : entityColor.admin
          }
          subtitle={`created by ${info.created_by.full_name} (${info.created_by.class_name})`}
          style={{ marginBottom: 0 }}
        />
      )}
      <EntitySubtitles
        info={{
          ...info,
        }}
      />
      <Space mt="md" />
      {statusInfo.disposition_formatted === "waiting" && (
        <>
          {!statusInfo.adding_messages_complete ? (
            <Alert
              radius="md"
              mb="sm"
              variant="light"
              color="red"
              title="Not quite ready"
            >
              We're adding messages. We'll let you know when we're done.
            </Alert>
          ) : (
            <Alert
              radius="md"
              mb="sm"
              variant="light"
              color="green"
              title="Message adding complete"
            >
              All messages have been added. Please proceed.
            </Alert>
          )}
        </>
      )}
      <Card>
        <Flex gap="xs">
          <div style={{ width: info.enqueued_at ? "50%" : "100%" }}>
            <BulkCommunicationStatus
              id={id}
              fetchData={fetchData}
              fetchStatus={() => true}
              status={info.status}
              info={info}
              statusInfo={statusInfo}
              reqData={{
                bulk_communication_id: id,
              }}
            />
          </div>
          {info.enqueued_at && (
            <div style={{ width: "50%" }}>
              <BulkCommunicationMailerStatus
                id={id}
                mailerStatusInfo={statusInfo}
              />
            </div>
          )}
        </Flex>
      </Card>
      <Space mt="md" />
      <Subnav links={links} />
      <BulkCommunicationAlerts id={id} />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <React.Fragment>
              <Card>
                <BulkCommunicationForm
                  id={id}
                  title={info.title}
                  onSuccess={fetchData}
                />
              </Card>
            </React.Fragment>
          }
        />
        <Route
          path="template"
          element={
            <div>
              <BulkCommunicationTemplate
                locationId={info.location_id}
                orgId={info.organization_id}
                bulkCommunicationId={id}
                editable={!statusInfo.enqueued}
              />
            </div>
          }
        />
        <Route
          path="messages"
          element={
            <div>
              <BulkCommunicationMessages
                id={id}
                variety={info.variety}
                refresh={refreshMessages}
              />
            </div>
          }
        />
      </Routes>
    </div>
  );
}
