import React from "react";
import { Grid, Title } from "@mantine/core";

import { ColorPicker } from "@components/shared";

export default function EntityConfigColorFields({ values, fields, onChange }) {
  const swatches = [
    ...new Set(
      Object.keys(values)
        .filter((f) => f !== "base")
        .map((m) => values[m])
        .filter((f) => f)
    ),
  ];

  return (
    <Grid>
      {fields.map((item) => (
        <Grid.Col span={{ base: 12, md: 4 }} key={item.key}>
          <Title mb="xs" order={4}>
            {item.title}
          </Title>
          <ColorPicker
            onChange={(e) => {
              onChange({
                [item.name]: e === "" ? "" : e.indexOf("#") > -1 ? e : `#e`,
              });
            }}
            value={values[item.name]}
            swatches={swatches}
          />
        </Grid.Col>
      ))}
    </Grid>
  );
}
