import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Flex,
  Group,
  Space,
  List,
  Text,
  ThemeIcon,
  Title,
  Alert,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { IconCheck } from "@tabler/icons-react";
import styled from "styled-components";

import { initialState as initialContestSettings } from "@components/Contest/ContestSettings/helpers";
import {
  EffortAssets,
  EffortForm,
  EffortWizardSweepstakes,
} from "@components/Effort";
import { EntityConfigTextFields } from "@components/EntityConfig";
import { Counter, CustomTooltip } from "@components/shared";
import {
  textItems,
  initialCampaignConfiguration as initialEffortConfiguration,
} from "@components/Campaign/helpers";
import { LocationSelect } from "@components/Location";
import { ContestWizardKeyword } from "@components/Contest";
import { KeywordForm } from "@components/Keyword";
import { keywordWizardSettings } from "@components/Keyword/helpers";
import { LookAndFeelManagement } from "@components/shared";

const filteredTextQuestions = textItems.filter(
  (f) => ["title", "intro", "info_button"].includes(f.name)
  // ["title", "intro", "info_button"].includes(f.name)
);

const settingsArr = [
  {
    title: "Featured Image",
    text: "This is the cover image and thumbnail",
    trigger: "featured_image_required_status",
  },
  {
    title: "Point Award",
    // text: "Supplemental text",
    trigger: "point_award_required_status",
  },
  {
    title: "Locations",
    text: "Add your first location. You can add more later.",
    trigger: "locations_required_status",
    key_name: "locations_required",
  },
];

export default function EffortWizardStatus({
  campaignId,
  contestId,
  effortDates = null,
  effortName,
  effortVariety,
  fetchData,
  id,
  isRecurring,
  locationId,
  organizationId,
  pointSettings = {},
  status = {},
  wizardComplete = false,
  wizardSettings,
  wizardStatus,
}) {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(initialEffortConfiguration);
  const [settings, setSettings] = useState(initialContestSettings);
  const triggeredSettings = settingsArr
    .filter((f) =>
      wizardComplete
        ? ![
            "check_in_value_required_status",
            "contest_keyword_value_required_status",
            "contest_required_status",
            "featured_image_required_status",
            "initial_entry_setting_required_status",
            "keyword_required_status",
            "locations_required_status",
            "point_award_required_status",
            "prize_required_status",
            "total_entries_allowed_required_status",
          ].includes(f.trigger)
        : Object.keys(wizardStatus).includes(f.trigger)
    )
    .filter((f) =>
      wizardSettings.allow_keyword !== true
        ? f.trigger !== "keyword_required_status"
        : true
    );

  useEffect(() => {
    fetchConfig();
  }, []);

  function fetchConfig() {
    setLoading(true);
    axios
      .get(`/efforts/${id}/app-configuration/`)
      .then(({ data }) => {
        const resConfig = data.response[0];

        if (JSON.stringify(resConfig) !== "{}") {
          setConfig({ ...config, ...resConfig });
        }
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const contestRequired = wizardSettings.contest_required === true;

  if (loading) return <div></div>;

  return (
    <div>
      {!wizardComplete && (
        <React.Fragment>
          <Title order={3}>Finish setup</Title>
          <Text mb="sm">
            To gain full access, please finish the list of tasks below:
          </Text>
        </React.Fragment>
      )}
      {wizardComplete && (
        <React.Fragment>
          <Card mb="sm">
            <StatusItem
              title="Status"
              text="Set the status of the effort"
              trigger="status"
              effortId={id}
              onSuccess={fetchData}
              status={status}
              allowStatusToggle={wizardStatus.allow_status_toggle}
            />
          </Card>
          {contestRequired && (
            <Card mb="sm">
              <StatusItem
                title="Sweepstakes"
                text="View sweepstakes associated with this effort"
                trigger="sweepstakes"
                wizardStatus={wizardStatus}
                effortVariety={effortVariety}
                onSuccess={fetchData}
                locationId={locationId}
                organizationId={organizationId}
                effortId={id}
              />
            </Card>
          )}
          {/* {wizardSettings.allow_keyword !== false &&
            contestId &&
            effortDates && (
              <Card mb="sm">
                <ContestWizardKeyword
                  contestDates={effortDates}
                  contestId={contestId}
                  locationId={locationId}
                  organizationId={organizationId}
                  fetchData={() => {
                    true;
                    // fetchData();
                    // fetchSettings();
                  }}
                  showList
                  showForm={isRecurring ? true : false}
                />
              </Card>
            )} */}
        </React.Fragment>
      )}
      {!wizardComplete && (
        <React.Fragment>
          <Card mb="sm">
            <StatusItem
              title="Dates"
              text="When will this event start and end?"
              trigger="effort_dates"
              onSuccess={() => {
                fetchData();
              }}
              effortId={id}
              effortVariety={effortVariety}
              complete={effortDates ? true : false}
            />
          </Card>
          {wizardStatus.look_and_feel_required_draft_status !== undefined && (
            <Card mb="sm">
              <StatusItem
                title="Text"
                titleTooltip="Use this section to configure the text that will be accessible to all users via a button at the top of their screen. This is a good place to include marketing copy, prize info, terms, etc."
                text=""
                trigger="text"
                onSuccess={() => {
                  fetchData();
                }}
                campaignId={campaignId}
                effortId={id}
                effortVariety={effortVariety}
                complete={
                  wizardStatus.look_and_feel_required_draft_status === true
                }
                effortConfig={config}
              />
            </Card>
          )}
          {contestRequired && (
            <Card mb="sm">
              <StatusItem
                complete={wizardStatus.contest_required_status === true}
                effortId={id}
                onSuccess={fetchData}
                text={
                  wizardStatus.contest_required_status === true
                    ? "Sweepstakes added"
                    : "Add one now. You can add more later."
                }
                locationId={locationId}
                organizationId={organizationId}
                effortVariety={effortVariety}
                effortName={effortName}
                title="Sweepstakes"
                trigger="sweepstakes"
                wizardStatus={wizardStatus}
              />
            </Card>
          )}
          {wizardSettings.keyword_required === true && (
            <Card mb="sm">
              <StatusItem
                complete={wizardStatus.keyword_required_status === true}
                effortDates={effortDates}
                effortId={id}
                effortVariety={effortVariety}
                locationId={locationId}
                onSuccess={fetchData}
                organizationId={organizationId}
                settings={wizardSettings}
                smsRequired={wizardSettings.keyword_sms_required === true}
                text="Create a keyword for this effort"
                title="Keyword"
                trigger="keyword_required"
                wizardStatus={wizardStatus}
              />
            </Card>
          )}
        </React.Fragment>
      )}
      {triggeredSettings.map((m, i) => (
        <Card
          mb="sm"
          key={i}
          style={{
            overflow: "visible",
          }}
        >
          <StatusItem
            effortDates={effortDates}
            effortVariety={effortVariety}
            title={m.title}
            text={m.text}
            trigger={m.trigger}
            locationId={locationId}
            onSuccess={() => {
              fetchData();
              fetchConfig();
            }}
            organizationId={organizationId}
            pointSettings={pointSettings}
            settings={settings}
            effortId={id}
            wizardComplete={wizardComplete}
            complete={wizardComplete ? false : wizardStatus[m.trigger] === true}
            registrationMethod={wizardSettings.registration_method}
          />
        </Card>
      ))}
    </div>
  );
}

const initialFormValues = {
  end_date: "",
  end_time: "",
  info_button: "",
  intro: "",
  start_date: "",
  start_time: "",
  title: "",
  value: 0,
};

const StatusItem = ({
  allowStatusToggle = false,
  campaignId,
  complete = false,
  effortConfig,
  effortId,
  effortDates,
  effortName,
  effortVariety,
  locationId,
  onSuccess,
  organizationId,
  pointSettings,
  registrationMethod,
  settings,
  smsRequired,
  status,
  text,
  title,
  trigger,
  wizardComplete = false,
  wizardStatus,
  titleTooltip,
}) => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);

  useEffect(() => {
    if (effortConfig && effortConfig.text) {
      setFormValues({
        ...formValues,
        ...effortConfig.text,
      });
    }
  }, [JSON.stringify(effortConfig)]);

  function onConfigSubmit(newConfig) {
    const req = {
      configuration: { ...effortConfig, ...newConfig },
    };

    axios
      .put(`/efforts/${effortId}/configuration/`, req)
      .then(() => {
        onSuccess();
        toast.success("Config updated!");
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onPointSettingsSubmit(newSettings) {
    setLoading(true);

    const req = {
      campaign_effort_id: effortId,
      settings: newSettings,
    };

    axios
      .put(`/efforts/${effortId}/update-points-settings/`, req)
      .then(() => {
        setFormValues(initialFormValues);
        toast.success("Saved!");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onStatusClick(newStatus) {
    const req = {
      effort_id: effortId,
      status: newStatus,
    };

    setLoading(true);

    axios
      .put(`/efforts/${effortId}/set-status/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Status changed!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onLocationSubmit() {
    const req = {
      location_id: formValues.value,
      campaign_effort_id: effortId,
    };

    setLoading(true);

    axios
      .post(`/locations/${formValues.value}/associate/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        toast.success("Location added!");
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  const isFormRegistration = registrationMethod === "form";

  return (
    <div>
      <Flex align="center" gap="sm">
        {titleTooltip && (
          <CustomTooltip label={titleTooltip}>
            <Text fw={600}>{title}</Text>
          </CustomTooltip>
        )}
        {!titleTooltip && <Text fw={600}>{title}</Text>}
        {complete && (
          <ThemeIcon color="green" size="sm" radius="xl" variant="light">
            <IconCheck />
          </ThemeIcon>
        )}
      </Flex>
      {text && <Text>{text}</Text>}
      {complete && trigger === "text" && campaignId && (
        <Text>Using campaign app config</Text>
      )}
      {!complete && (
        <React.Fragment>
          {trigger === "effort_dates" && (
            <React.Fragment>
              <EffortForm
                id={effortId}
                wizardSetup
                onSuccess={() => {
                  onSuccess();
                }}
              />
            </React.Fragment>
          )}
          {trigger === "featured_image_required_status" && (
            <Box mt="sm">
              <EffortAssets
                uploadOnly
                effortId={effortId}
                fetchEffort={onSuccess}
              />
            </Box>
          )}
          {trigger === "status" && (
            <Box mt="sm">
              <Group spacing="xs">
                {statusButtons.map((b) => (
                  <Button
                    key={b.key}
                    onClick={() => onStatusClick(b.value)}
                    color={b.color}
                    size="xs"
                    disabled={allowStatusToggle ? false : true}
                    variant={status === b.value ? "filled" : "light"}
                    loading={loading}
                  >
                    {b.text}
                  </Button>
                ))}
              </Group>
            </Box>
          )}
          {trigger === "text" && (
            <>
              {/* <Text
                onClick={() => {
                  const btn = document.getElementById("addCampaignButton");
                  if (btn) btn.click();
                }}
                style={{ cursor: "pointer" }}
              >
                If you plan to use the look/feel settings from the Campaign
                level, not the effort level,{" "}
                <span style={{ textDecoration: "underline" }}>click here</span>.
              </Text> */}
              <Box mt="xs">
                <LookAndFeelManagement
                  isWizard
                  createReqData={{
                    campaign_effort_id: effortId,
                  }}
                  addReqData={{
                    campaign_effort_id: effortId,
                  }}
                  fetchData={onSuccess}
                />
              </Box>
              <Box mt="sm">
                <EntityConfigTextFields
                  fields={filteredTextQuestions}
                  values={{
                    ...formValues,
                  }}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      ...e,
                    });
                  }}
                />
                <Button
                  // disabled={!formValues.title || !formValues.intro}
                  disabled={!formValues.intro}
                  mt="sm"
                  loading={loading}
                  onClick={() =>
                    onConfigSubmit({
                      ...effortConfig,
                      text: {
                        ...effortConfig.text,
                        info_button: formValues.info_button,
                        intro: formValues.intro,
                        title: formValues.title,
                      },
                    })
                  }
                >
                  Save
                </Button>
              </Box>
            </>
          )}
          {trigger === "locations_required_status" && effortId && (
            <React.Fragment>
              <Space mt="sm" />
              <LocationSelect
                label=""
                value={formValues.value}
                reqData={{
                  campaign_effort_id: effortId,
                  organization_id: organizationId,
                  context: "campaign_effort_setup",
                }}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    value: e,
                  })
                }
              />
              <Button
                mt="sm"
                fullWidth
                disabled={!formValues.value}
                loading={loading}
                onClick={onLocationSubmit}
              >
                Submit
              </Button>
            </React.Fragment>
          )}
          {["point_award_required_status"].includes(trigger) && (
            <React.Fragment>
              <Counter
                value={formValues.value}
                unlimited={false}
                maxValue={isFormRegistration ? 30 : 20}
                onChange={(e) => {
                  if (isFormRegistration) {
                    if (e > 30) {
                      setFormValues({
                        ...formValues,
                        value: 30,
                      });
                    } else {
                      setFormValues({
                        ...formValues,
                        value: e,
                      });
                    }
                  } else {
                    if (e > 20) {
                      setFormValues({
                        ...formValues,
                        value: 20,
                      });
                    } else {
                      setFormValues({
                        ...formValues,
                        value: e,
                      });
                    }
                  }
                }}
              />
              <Button
                disabled={formValues.value === 0}
                mt="sm"
                loading={loading}
                onClick={() => {
                  onPointSettingsSubmit(
                    makeNewSettingsHash(
                      pointSettings,
                      trigger,
                      formValues.value
                    )
                  );
                }}
              >
                Save
              </Button>
            </React.Fragment>
          )}
          {trigger === "sweepstakes" && (
            <Box mt="sm">
              <StyledSweeps>
                <EffortWizardSweepstakes
                  effortVariety={effortVariety}
                  effortId={effortId}
                  effortName={effortName}
                  fetchData={onSuccess}
                  items={wizardStatus.sweepstakes_info}
                  locationId={locationId}
                  organizationId={organizationId}
                  wizardStatus={wizardStatus}
                />
              </StyledSweeps>
            </Box>
          )}
          {trigger === "keyword_required" && (
            <Box>
              {settings?.registration_method === "keyword" && (
                <Alert mt="sm" mb="sm" title="You should know...">
                  <List>
                    <List.Item>
                      You are creating a keyword that will be used for
                      registration.
                    </List.Item>
                    <List.Item>
                      This keyword will not count towards intervals you
                      configure.
                    </List.Item>
                    <List.Item>
                      If you want this keyword to be claimed after registration,
                      you need to explicity join contests to this keyword.
                    </List.Item>
                  </List>
                </Alert>
              )}
              <KeywordForm
                minStartDate={effortDates ? effortDates.start_date : ""}
                minEndDate={effortDates ? effortDates.end_date : ""}
                effortId={effortId}
                locationId={locationId}
                organizationId={organizationId}
                onSuccess={onSuccess}
                smsRequired={smsRequired}
                additionalReqData={{
                  wizard_settings: {
                    ...keywordWizardSettings,
                    show_contests: true,
                  },
                  activate_after_create: settings.activate_keyword_on_create
                    ? true
                    : false,
                }}
              />
            </Box>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const statusButtons = [
  // { text: "created", value: 1, color: "yellow" },
  { text: "on", value: 2, color: "green" },
  { text: "off", value: 3, color: "red" },
  { text: "deleted", value: 4, color: "yellow" },
].map((m) => ({
  ...m,
  key: m.value,
}));

function makeNewSettingsHash(settings, trigger, value) {
  switch (trigger) {
    case "point_award_required_status":
      return {
        ...settings,
        value,
      };
    default:
      return settings;
  }
}

const StyledSweeps = styled.div`
  .mantine-Card-root {
    padding: 0.5em 1em !important;
    border: none;
    border-radius: 0 !important;

    &:first-of-type {
      padding-top: 0;
    }

    & + .mantine-Card-root {
      /* margin-top: 1em !important; */
      background: var(--mantine-color-gray-0);
      border-top: 1px solid var(--mantine-color-gray-2);
    }
  }
`;
