import React, { useState } from "react";
import { Button } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { IconPin } from "@tabler/icons-react";

export default function PinReportRecipe({ reqData, text = "Pin", onSuccess }) {
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    setLoading(true);

    const req = {
      ...reqData,
    };

    axios
      .post(`/report-recipes/pin-recipe/`, req)
      .then(() => {
        onSuccess();
        setLoading(false);
        toast.success("Pinned!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      leftSection={<IconPin size={16} />}
      variant="subtle"
      color="gray"
      size="xs"
      onClick={onSubmit}
      loading={loading}
    >
      {text}
    </Button>
  );
}
