export const SET_LOCATION_SETTINGS = "SET_LOCATION_SETTINGS";

function locationSettingsReducer(state, action) {
  switch (action.type) {
    case SET_LOCATION_SETTINGS:
      return {
        ...state,
        locationSettings: action.payload,
      };
    default:
      return state;
  }

  return state;
}

export default locationSettingsReducer;
