import React, { useState, useEffect } from "react";
import { Select } from "@mantine/core";
import axios from "axios";

export default function OrganizationSelect({
  label = "Organization",
  value = null,
  disabledIds = [],
  onChange,
  objectOnChange = false,
  disabled = false,
}) {
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!value) {
      setSearchValue("");
    }
  }, [value]);

  function fetchData() {
    axios
      .post(`/retrieve-organizations/`, {
        page_size: 10000,
        page: 0,
      })
      .then(({ data }) => {
        setOptions(
          data.response[0].data.sort((a, b) => (a.name > b.name ? 1 : -1))
        );
      });
  }

  const formattedOptions = options.map((m) => ({
    label: m.name,
    value: m.id,
    key: m.id,
    disabled: disabledIds.includes(m.id),
  }));

  return (
    <Select
      label={label}
      data={formattedOptions}
      onChange={(e) => {
        if (e === null) {
          onChange(e);
        }
        if (objectOnChange) {
          const found = options.find((f) => f.id === e);
          if (found) {
            onChange(found);
          }
        } else {
          onChange(e);
        }
      }}
      allowDeselect
      searchable
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      placeholder={"Select an organization"}
      value={value}
      disabled={disabled}
      limit={20}
      clearable
    />
  );
}
