import React from "react";
import { Button } from "@mantine/core";
import { IconDeviceFloppy } from "@tabler/icons-react";

const SaveButton = ({ onClick, text = "Save Config", color = "blue" }) => {
  return (
    <Button
      mt="lg"
      variant="light"
      onClick={onClick}
      leftSection={<IconDeviceFloppy />}
      color={color}
    >
      {text}
    </Button>
  );
};

export default SaveButton;
