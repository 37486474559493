import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Autocomplete,
  Loader,
  Flex,
  Group,
  Title,
  ActionIcon,
  Button,
} from "@mantine/core";
import toast from "react-hot-toast";
import { IconArrowNarrowRight } from "@tabler/icons-react";

import { TagVarietyButtons } from "./";

export default function TagSearch({
  contestId,
  keywordId,
  libraryAssetId,
  onSuccess,
  disabledIds,
}) {
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // const [variety, setVariety] = useState([]);
  // const [varieties, setVarieties] = useState([]);

  // useEffect(() => {
  //   fetchVarieties();
  // }, []);

  // function fetchVarieties() {
  //   fetchTagVarieties()
  //     .then(({ data }) => {
  //       setVarieties(data.response);
  //     })
  //     .catch((err) => {
  //       setVarieties([]);
  //     });
  // }

  const ids = {
    contest_id: contestId,
    keyword_id: keywordId,
    library_asset_id: libraryAssetId,
  };

  const entityData = Object.keys({
    contest_id: contestId,
    keyword_id: keywordId,
    library_asset_id: libraryAssetId,
  }).reduce((acc, cur) => {
    if (ids[cur]) {
      acc[cur] = ids[cur];
    }
    return acc;
  }, {});

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  function fetchTags(value) {
    setSearchValue(value);
    if (value === "") {
      setOptions([]);
      return;
    }
    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      ...entityData,
      value,
      // variety: !variety.length ? varieties.map((v) => v.value) : variety,
    };

    axios
      .post(`/search-all-tags/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setOptions(
          data.response.map((d) => ({
            label: d.name,
            value: d.id,
            disabled: disabledIds.includes(d.id),
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setOptions([]);
      });
  }

  function onItemSelect(e) {
    const req = {
      ...entityData,
      tag_id: e,
    };

    axios
      .post(`/tags/`, req)
      .then(() => {
        setSearchValue("");
        toast.success("Added");
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onTagCreate() {
    const req = {
      // ...formValues,
      ...entityData,
      name: searchValue.trim(),
    };

    setSubmitting(true);

    axios
      .post(`/tags/`, req)
      .then(() => {
        toast.success("Tag Added!");
        setSearchValue("");
        onSuccess();
        setSubmitting(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
        setSubmitting(false);
      });
  }

  return (
    <div>
      <Flex gap="sm" align="center" mb="sm">
        <Group align="center">
          <Title order={4}>Search tags</Title>
        </Group>
        {loading && <Loader size="xs" />}
      </Flex>
      <Autocomplete
        placeholder="Start typing..."
        data={options}
        value={searchValue}
        onChange={(e) => fetchTags(e)}
        onOptionSubmit={(e) => onItemSelect(e)}
        // filter={(value, item) => true}
      />
      {!loading &&
        searchValue &&
        !options.map((o) => o.label).includes(searchValue) && (
          <Button
            onClick={onTagCreate}
            loading={submitting}
            color="blue"
            mt="xs"
            fullWidth
          >
            Create tag
          </Button>
        )}
    </div>
  );
}
