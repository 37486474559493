import React from "react";

import { ManagerList } from "@components/Manager";

export default function ManagerIndex() {
  return (
    <ManagerList
      isIndex
      requestInfo={{
        url: `/retrieve-managers/`,
        data: {},
      }}
    />
  );
}
