import React, { useState } from "react";
import { Button, Divider } from "@mantine/core";

import { CurationSongSelect } from "./";
import axios from "axios";
import toast from "react-hot-toast";

import { titleize } from "./helpers";

export default function TitlelizeSongs({ items = [], onSuccess, artistId }) {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  function onClose() {
    setLoading(false);
    setSelected([]);
  }

  function onSubmit() {
    const req = {
      artist_id: artistId,
    };

    const selectedItems = items
      .filter((f) => selected.includes(f.id))
      .map((m) => ({
        song_id: m.id,
        name: titleize(m.name),
      }));

    req.songs = selectedItems;

    setLoading(true);

    axios
      .put(`/songs/${selectedItems[0].song_id}/`, req)
      .then(() => {
        toast.success("Titleized!");
        setLoading(false);
        onClose();
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <CurationSongSelect
        items={items}
        title="Select songs to titleize"
        selected={selected}
        onChange={(e) => setSelected(e)}
      />
      <Divider mt="lg" mb="lg" />
      <Button
        fullWidth
        loading={loading}
        onClick={onSubmit}
        disabled={selected.length === 0}
      >
        Titleize {selected.length} song{selected.length === 1 ? "" : "s"}
      </Button>
    </div>
  );
}
