import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Loader, Text, Flex } from "@mantine/core";
import { shallow } from "zustand/shallow";

import { StatBlocks } from "@components/DataViz";

import useAppStore from "./report-recipe-runner-store";

export default function ReportRecipeStatBlocks({
  statBlockData = null,
  requestConfig,
}) {
  // const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const {
    locations,
    orgs,
    recipeInfo,
    savedRecipe,
    snapshot,
    statBlocks,
    setStatBlocks,
  } = useAppStore(
    (state) => ({
      locations: state.locations,
      orgs: state.orgs,
      recipeInfo: state.recipe_info,
      savedRecipe: state.saved_recipe,
      snapshot: state.snapshot,
      statBlocks: state.stat_blocks,
      setStatBlocks: state.setStatBlocks,
    }),
    shallow
  );

  useEffect(() => {
    if (statBlockData) {
      setLoading(false);
      // setItems(statBlockData);
      setStatBlocks(statBlockData);
    }
  }, [JSON.stringify(statBlockData)]);

  useEffect(() => {
    if (!requestConfig || snapshot) return setLoading(false);
    fetchData();
  }, [JSON.stringify(requestConfig)]);

  function fetchData() {
    if (statBlockData) return setLoading(false);
    setError(false);

    const req = {
      ...requestConfig.data,
      report_recipe_id: recipeInfo.id,
      saved_recipe_id: savedRecipe?.id,
      snapshot_id: snapshot?.id,
      table_data: false,
      meta_blocks: true,
      location_ids: locations.map((m) => m.id),
      organization_ids: orgs.map((m) => m.id),
    };

    axios
      .post(requestConfig.api_endpoint, req)
      .then(({ data }) => {
        setLoading(false);
        // setItems(data.response);
        setStatBlocks(data.response);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setStatBlocks([]);
      });
  }

  if (snapshot && !statBlocks) return null;

  if (loading || error) {
    return (
      <div style={{ minHeight: "100px" }}>
        <StatBlocks
          data={[
            error
              ? {
                  text: "There was a problem loading this data",
                  value: "Error!",
                }
              : { text: "Loading", value: <Loader size="sm" /> },
          ]}
        />
      </div>
    );
  }

  return (
    <StatBlocks
      flex
      backgroundColor="var(--mantine-color-gray-0)"
      // data={items}
      data={statBlocks}
    />
  );
}
