import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Divider,
  LoadingOverlay,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import toast from "react-hot-toast";

import { initialEmailAppConfig } from "./helpers";
import { defaultSettings } from "@components/shared/EntitySettingsCode";

const links = [
  { label: "Facebook URL", name: "facebook_url" },
  { label: "Instagram URL", name: "instagram_url" },
  { label: "Twitter URL", name: "twitter_url" },
  { label: "TikTok URL", name: "tiktok_url" },
  { label: "YouTube URL", name: "youtube_url" },
];

export default function EmailAppConfig({
  name,
  color = "blue",
  locationId,
  orgId,
  configId,
}) {
  const [formValues, setFormValues] = useState(initialEmailAppConfig);
  const [saving, setSaving] = useState(false);
  const [settings, setSettings] = useState(defaultSettings);
  const [loading, setLoading] = useState(true);

  const theme = useMantineTheme();

  const defaultColor = theme.colors[color][5];

  useEffect(() => {
    setFormValues({
      ...formValues,
      entity_name: name,
      branding_color: defaultColor,
    });
  }, [name]);

  useEffect(() => {
    if (configId) {
      fetchAppConfig();
    } else {
      fetchSettings();
    }
  }, []);

  function fetchAppConfig() {
    setLoading(true);

    axios
      .post(`/app-configurations/data-grabber/`, {
        context: "email_branding",
      })
      .then(({ data }) => {
        setFormValues({
          ...formValues,
          ...data.response[0],
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function fetchSettings() {
    setLoading(true);

    axios
      .get(orgId ? `/organizations/${orgId}/` : `/locations/${locationId}/`)
      .then(({ data }) => {
        if (data.response[0].settings_code) {
          const nestedSettings =
            data.response[0].settings_code.settings.settings;
          setSettings({
            ...settings,
            ...nestedSettings,
          });

          if (nestedSettings.email_branding) {
            setFormValues({
              ...formValues,
              ...nestedSettings.email_branding,
            });
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function onAppConfigSubmit() {
    const req = {
      email_branding: { ...formValues },
      app_configuration_id: configId,
    };

    axios
      .put(`/app-configurations/${configId}/`, req)
      .then(() => {
        fetchAppConfig();
        setSaving(false);
        toast.success("Updated!");
      })
      .catch((err) => {
        toast.error(err);
        setSaving(false);
      });
  }

  function onSubmit() {
    setSaving(true);
    if (configId) return onAppConfigSubmit();
    const req = {
      location_id: locationId,
      organization_id: orgId,
      settings: {
        ...settings,
        email_branding: formValues,
      },
    };

    axios
      .put(
        orgId
          ? `/organizations/${orgId}/site-settings/`
          : `/locations/${locationId}/site-settings/`,
        req
      )
      .then(() => {
        setSaving(false);
        toast.success("Saved!");
      })
      .then(() => {
        fetchSettings();
      })
      .catch((err) => {
        setSaving(false);
        toast.error(err);
      });
  }

  return (
    <>
      <Card>
        <LoadingOverlay visible={loading} />
        <Title order={4}>Branding</Title>
        <TextInput
          label="Name"
          maxLength={100}
          value={formValues.entity_name}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              entity_name: e.target.value,
            })
          }
        />
        <TextInput
          label="Button Color"
          value={formValues.branding_color}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              branding_color: e.target.value,
            })
          }
        />
        <Button
          size="sm"
          mt="xs"
          style={{
            backgroundColor: formValues.branding_color,
            width: "fit-content",
          }}
        >
          Sample Button Color
        </Button>
        <Divider mt="lg" mb="lg" />
        <Title order={4}>Social Media Links</Title>
        <Text c="dimmed" mb="xs" size="xs">
          Empty URL fields will not appear in email
        </Text>
        {links.map((link) => (
          <TextInput
            key={link.name}
            name={link.name}
            label={link.label}
            value={formValues[link.name]}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                [link.name]: e.target.value,
              })
            }
          />
        ))}
        <Divider mt="lg" mb="lg" />
        <Title order={4}>Footer</Title>
        <Text c="dimmed" mb="xs" size="xs">
          If present, will show up on bottom of emails
        </Text>
        <TextInput
          label="Support Phone"
          maxLength={100}
          value={formValues.support_phone}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              support_phone: e.target.value,
            })
          }
        />
        <TextInput
          label="Support Email"
          value={formValues.support_email}
          maxLength={100}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              support_email: e.target.value,
            })
          }
        />
      </Card>
      <Button mt="sm" onClick={onSubmit} loading={saving}>
        Save
      </Button>
    </>
  );
}
