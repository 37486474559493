export const initialEmailAppConfig = {
  branding_color: "#000",
  entity_name: "",
  facebook_url: "",
  support_email: "",
  support_phone: "",
  instagram_url: "",
  tiktok_url: "",
  twitter_url: "",
  youtube_url: "",
};

// additional email_hash data
// {
//   copyright_year: "", // not editable by user
//   logo_url: "", // not editable by user
// }
