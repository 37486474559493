import React, { useState, useEffect } from "react";
import { Grid, Title, Text, Group, Loader } from "@mantine/core";
import axios from "axios";

import { KeywordForm, KeywordLinkList } from "@components/Keyword";

export default function ContestWizardKeyword({
  contestDates = null,
  contestId,
  locationId,
  fetchData,
  showList = false,
  showForm,
  smsRequired = false,
}) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchKeywords();
  }, []);

  function fetchKeywords() {
    setLoading(true);
    const req = { contest_id: contestId, location_id: locationId };
    axios
      .post(`/retrieve-wizard-keywords/`, req)
      .then(({ data }) => {
        setLoading(false);
        setItems(data.response);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  if (showForm === false) {
    return (
      <React.Fragment>
        <Group mb="sm">
          <Title order={5}>Current Keywords</Title>
          {loading && <Loader size="xs" />}
        </Group>
        <KeywordLinkList
          items={items}
          fetchData={() => {
            fetchData();
            fetchKeywords();
          }}
        />
      </React.Fragment>
    );
  }

  return (
    <Grid>
      <Grid.Col span={{ base: 12, md: showList ? 6 : 12 }}>
        {showList && <Title order={5}>Create keyword</Title>}
        {contestDates && (
          <Text size="sm" mt="xs" mb="xs">
            *Contest runs from: {contestDates.start_date_formatted}-
            {contestDates.end_date_formatted}
          </Text>
        )}
        {smsRequired && (
          <Text size="sm">
            **Because of the selected options, claiming keywords by SMS is
            enabled
          </Text>
        )}
        <KeywordForm
          minStartDate={contestDates.start_date}
          maxEndDate={contestDates.end_date}
          contestId={contestId}
          locationId={locationId}
          onSuccess={() => {
            fetchData();
            fetchKeywords();
          }}
          smsRequired={smsRequired}
        />
      </Grid.Col>
      {showList && (
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Group mb="sm">
            <Title order={5}>Current Keywords</Title>
            {loading && <Loader size="xs" />}
          </Group>
          <KeywordLinkList
            items={items}
            fetchData={() => {
              fetchData();
              fetchKeywords();
            }}
          />
        </Grid.Col>
      )}
    </Grid>
  );
}
