import React from "react";
import { Flex, Box, Badge, Text } from "@mantine/core";

const typeColors = {
  SubscriptionChanged: "gray",
  Delivered: "blue",
  Transient: "yellow",
  Opened: "green",
  LinkClicked: "teal",
  Bounced: "red",
};

export default function EmailMessageEvents({ events = [] }) {
  return (
    <>
      {events.map((e, i) => (
        <Box mb="lg" key={i}>
          <Flex mb="xs" align="center">
            <Badge size="sm" color={typeColors[e.Type]} variant="light">
              {e.Type}
            </Badge>
            <Text
              style={{ flexGrow: 1, textAlign: "right" }}
              c="dimmed"
              size="sm"
            >
              {new Date(e.ReceivedAt).toLocaleString()}
            </Text>
          </Flex>
          {e.Details && e.Details.Summary && <Text>{e.Details.Summary}</Text>}
          {e.Type === "Delivered" && <Text>{e.Details.DeliveryMessage}</Text>}
        </Box>
      ))}
    </>
  );
}
