import React from "react";
import { Space } from "@mantine/core";
import { useSelector } from "react-redux";

import { locationVariety } from "@components/Location/helpers";

import { PinnedReportRecipeList } from "@components/ReportRecipe";
import {
  DashboardMetaBlocks,
  DashboardPlays,
  DashboardContestWinners,
  DashboardEfforts,
} from "@components/Dashboard";

export default function EntityDashboard() {
  const managerInfo = useSelector((state) => state.manager);

  return (
    <>
      <DashboardMetaBlocks />
      <Space mt="lg" mb="lg" />
      {managerInfo &&
        managerInfo.location_variety === locationVariety.station && (
          <DashboardPlays />
        )}
      <DashboardEfforts />
      <Space mt="lg" mb="lg" />
      <PinnedReportRecipeList
        reqData={{
          pinned_to: 1,
        }}
      />
    </>
  );
}
