export default function qbHasChanged(qd, rqd) {
  const queryData = { ...qd };
  const reportDataQuery = { ...rqd };
  if (!reportDataQuery) return false;
  delete queryData.visualizer;
  delete reportDataQuery.visualizer;
  // if (reportDataQuery?.visualizer !== queryData.visualizer) return false;

  return JSON.stringify(reportDataQuery) !== JSON.stringify(queryData);
}
