import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Anchor, Card } from "@mantine/core";
import { useParams, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import {
  LootItemForm,
  LootItemQuantity,
  LootItemJoins,
} from "@components/LootLocker";
import { AssetManagement, AssetLibraryPicker } from "@components/Asset";

import { ErrorView, PageTitle, Subnav } from "@components/shared";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function LootItemDetail() {
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const params = useParams();
  const { id } = params;
  const managerInfo = useSelector((state) => state.manager);

  useEffect(() => {
    fetchData();
  }, [id]);

  function fetchData() {
    setInfo(null);
    axios
      .get(`/loot-items/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  function onLibrarySelection(itemId) {
    const req = { loot_item_id: id, asset_id: itemId, variety: 11 };

    axios
      .post(`/replicate-library-asset/`, req)
      .then(({ data }) => {
        toast.success("Added!");
        fetchData();
      })
      .catch((err) => {
        fetchData();
        toast.error(err);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  return (
    <div>
      <PageTitle
        title={info.name}
        subtitle={
          <React.Fragment>
            Loot Item
            {!managerInfo && (
              <React.Fragment>
                {" "}
                for{" "}
                {info.location_id && (
                  <Anchor
                    component={Link}
                    to={`/locations/${info.location_id}/loot-locker`}
                  >
                    location
                  </Anchor>
                )}
                {info.organization_id && (
                  <Anchor
                    component={Link}
                    to={`/organizations/${info.organization_id}/loot-locker`}
                  >
                    organization
                  </Anchor>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        }
        icon={entityIcon.loot()}
        iconColor={entityColor.loot}
      />
      <Subnav
        buttonColor={entityColor.loot}
        links={[
          {
            text: "Detail",
            to: `/loot-items/${id}`,
          },
          {
            text: "Photos",
            to: `/loot-items/${id}/assets`,
          },
          {
            text: "Allocations",
            to: `/loot-items/${id}/allocations`,
          },
        ]}
      />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Card>
              <LootItemForm
                id={id}
                name={info.name}
                description={info.description}
                retailValue={info.value_formatted}
                onSuccess={fetchData}
              />
            </Card>
          }
        />
        <Route
          path="assets"
          element={
            <React.Fragment>
              <AssetManagement lootItemId={id}>
                <AssetLibraryPicker
                  onClose={fetchData}
                  onSelect={(assetId) => onLibrarySelection(assetId)}
                  reqData={{
                    variety: [1, 2, 3, 4],
                  }}
                />
              </AssetManagement>
            </React.Fragment>
          }
        />
        <Route
          path="allocations"
          element={
            <React.Fragment>
              <LootItemQuantity
                id={id}
                allocated={info.quantity_allocated}
                available={info.quantity_available}
                joinCount={info.loot_item_joins_count}
                fetchData={fetchData}
              />
            </React.Fragment>
          }
        />
      </Routes>
    </div>
  );
}
