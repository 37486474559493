import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Divider, Flex } from "@mantine/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { ChatHeader, Chat } from "@components/Messaging";

const defaultPageSize = 10;

const contextFilters = [
  { text: "Conversation", value: "conversational" },
  { text: "Keyword", value: "keyword" },
  { text: "Rating", value: "rating" },
];

export default function InteractionDetail({ onMessageSend }) {
  const [interactionInfo, setInteraction] = useState(null);
  const [filters, setFilters] = useState({
    context: "conversational",
    favorites: false,
  });
  const { id } = useParams();

  const manager = useSelector((state) => state.manager);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    fetchData();
  }, [id]);

  function fetchData() {
    if (!id) return;

    axios
      .get(`/station-interactions/${id}/`)
      .then(({ data }) => {
        setInteraction(populateInteraction(data.response[0]));
      })
      .catch((err) => {});
  }

  if (!user && !interactionInfo) return null;

  return (
    <div>
      {manager && (
        <Box mb="sm">
          <ChatHeader
            interactionId={id}
            name={interactionInfo.full_name}
            nickname={interactionInfo.nickname}
            phoneId={interactionInfo.phone_number_id}
            phoneNumber={interactionInfo.number}
            onUpdate={() => {
              onMessageSend();
              fetchData();
            }}
          />
        </Box>
      )}
      {manager && (
        <>
          <Flex gap="xs">
            {contextFilters
              .filter((f) => (user ? f.value !== "rating" : true))
              .map((b, i) => (
                <Button
                  size="xs"
                  key={i}
                  variant={filters.context === b.value ? "light" : "subtle"}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      context: b.value,
                    })
                  }
                >
                  {b.text}
                </Button>
              ))}
          </Flex>
          <Divider mt="sm" opacity={0.5} />
        </>
      )}
      {(interactionInfo || user) && (
        <Chat
          contextFilter={filters.context}
          interactionId={id}
          onMessageSend={() => {
            onMessageSend();
          }}
          title={!user ? interactionInfo.nickname : ""}
        />
      )}
    </div>
  );
}

function populateInteraction(d = {}) {
  return {
    contact_list_id: d.contact_list_id || null,
    full_name: d.full_name || "",
    nickname: d.nickname || "",
    number: d.number || "",
    phone_number_id: d.phone_number_id || null,
  };
}
