import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Group,
  Loader,
  Paper,
  Table,
  Title,
  Grid,
  UnstyledButton,
} from "@mantine/core";
import { IconMap } from "@tabler/icons-react";

import { HeatMap } from "@components/DataViz";

const reportValues = {
  1: "registration_count",
  2: "check_in_count",
  3: "keyword_count",
  4: "interaction_count",
  5: "customers",
};

const varietyButtons = [
  { text: "Registration", value: reportValues[1] },
  { text: "Check-In", value: reportValues[2] },
  { text: "Keyword", value: reportValues[3] },
  { text: "Interaction", value: reportValues[4] },
  // { text: "Customers", value: reportValues[5] },
];

const HeatMapReport = ({ campaignId, effortId }) => {
  const [reportData, setReportData] = useState(null);
  const [activeFilter, setActiveFilter] = useState(reportValues[1]);
  const [loading, setLoading] = useState(false);
  const [activeKeyword, setActiveKeyword] = useState(null);

  const managerInfo = useSelector((state) => state.manager);
  const isAdmin = useSelector((state) => state.admin) ? true : false;

  const isLocationManager =
    managerInfo && managerInfo.location_id ? true : false;

  useEffect(() => {
    fetchData();
  }, [campaignId, effortId, activeFilter]);

  function fetchData() {
    const req = {
      report: 3,
      context: activeFilter,
    };

    if (effortId) req.campaign_effort_id = effortId;
    if (campaignId) req.campaign_id = campaignId;

    setLoading(true);

    axios
      .post(`/retrieve-report-data/`, req)
      .then(({ data }) => {
        const formattedData = formatReportData(
          data.response,
          activeFilter,
          isAdmin || !isLocationManager
        );

        setReportData(formattedData);

        if (
          activeFilter === reportValues[3] &&
          formattedData &&
          formattedData.list_data &&
          formattedData.list_data.length
        ) {
          setActiveKeyword(formattedData.list_data[0]);
        }

        setLoading(false);
      })
      .catch((err) => {
        setReportData(null);
        setLoading(false);
      });
  }

  if (!reportData) return null;

  const showList = isLocationManager
    ? ![reportValues[1], reportValues[5]].includes(activeFilter)
    : true;

  return (
    <div>
      <Group mb="md" mt="lg">
        <Title order={4}>Heat Map</Title>
        {loading && <Loader size="xs" />}
      </Group>
      <Button.Group style={{ marginBottom: "1em" }}>
        {varietyButtons.map((b) => (
          <Button
            key={b.key}
            variant={b.value !== activeFilter ? "light" : "filled"}
            onClick={() => {
              setActiveFilter(b.value);
              setActiveKeyword(null);
            }}
            size="xs"
          >
            {b.text}
          </Button>
        ))}
      </Button.Group>
      <Paper p="lg">
        <Grid>
          {showList && (
            <Grid.Col span={{ base: 12, md: 4 }}>
              <Box
                style={{
                  height: "600px",
                  maxHeight: "600px",
                  overflowY: "auto",
                }}
              >
                <Table striped>
                  {isLocationManager && activeFilter === reportValues[4] && (
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>Customer ID</Table.Th>
                        <Table.Th></Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                  )}
                  <Table.Tbody>
                    {reportData.list_data.length === 0 && (
                      <Table.Tr>
                        <Table.Td>No items to display at this time</Table.Td>
                      </Table.Tr>
                    )}
                    {reportData.list_data
                      .sort((a, b) => b.count - a.count)
                      .map((p, i) => (
                        <Table.Tr key={i}>
                          <Table.Td>
                            {activeFilter === reportValues[3] &&
                            isLocationManager ? (
                              <UnstyledButton
                                onClick={() => setActiveKeyword(p)}
                                style={{
                                  fontWeight:
                                    activeKeyword &&
                                    activeKeyword.name === p.name
                                      ? "bold"
                                      : 400,
                                  color:
                                    activeKeyword &&
                                    activeKeyword.name === p.name
                                      ? "var(--mantine-color-gray-1)"
                                      : "inherit",
                                  backgroundColor:
                                    activeKeyword &&
                                    activeKeyword.name === p.name
                                      ? "var(--mantine-color-blue-4)"
                                      : "transparent",
                                  fontSize: "1em",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                  padding: "var(--mantine-spacing-xs)",
                                  borderRadius: "var(--mantine-radius-md)",
                                }}
                              >
                                <span>{p.name}</span>
                                {activeKeyword &&
                                activeKeyword.name === p.name ? (
                                  <IconMap
                                    style={{ marginLeft: "5px" }}
                                    size={16}
                                  />
                                ) : (
                                  ""
                                )}
                              </UnstyledButton>
                            ) : (
                              p.name
                            )}
                          </Table.Td>
                          <Table.Td>{p.weight}</Table.Td>
                        </Table.Tr>
                      ))}
                  </Table.Tbody>
                </Table>
              </Box>
            </Grid.Col>
          )}
          <Grid.Col span={{ base: 12, md: showList ? 8 : 12 }}>
            <HeatMap
              // data={activeKeyword ? activeKeyword.markers : reportData.map_data}
              data={
                activeKeyword && isLocationManager
                  ? activeKeyword.markers
                  : reportData.map_data
              }
              defaultCenter={
                isLocationManager &&
                managerInfo.latitude &&
                managerInfo.longitude
                  ? {
                      lat: managerInfo.latitude,
                      lng: managerInfo.longitude,
                    }
                  : undefined
              }
            />
          </Grid.Col>
        </Grid>
      </Paper>
    </div>
  );
};

export default HeatMapReport;

function formatReportData(reportData, reportType, isAdmin) {
  switch (reportType) {
    case reportValues[3]:
      if (isAdmin) {
        return {
          map_data: reportData.map((m) => ({
            name: m.name,
            lat: m.latitude,
            lng: m.longitude,
            weight: m.count,
          })),
          list_data: reportData
            .map((m) => ({
              name: m.name,
              weight: m.count,
              markers: [
                {
                  name: "yo",
                  lat: m.latitude,
                  lng: m.longitude,
                  weight: m.count,
                },
              ],
            }))
            .sort((a, b) => b.weight - a.weight),
        };
      }
      return {
        list_data: reportData
          .map((m) => ({
            name: m.name,
            weight: m.count,
            markers: m.lat_lngs
              ? m.lat_lngs
                  .map((mm) => ({
                    name: "yo",
                    lat: mm.latitude,
                    lng: mm.longitude,
                    weight: m.lat_lngs.length,
                  }))
                  .reduce((acc, cur) => {
                    const found = acc.find(
                      (f) => f.lat === cur.lat && f.lng === cur.lng
                    );
                    if (!found) {
                      acc.push({
                        ...cur,
                        weight: 1,
                      });
                    } else {
                      found.weight += 1;
                    }
                    return acc;
                  }, [])
              : [],
          }))
          .sort((a, b) => b.weight - a.weight),
      };
    case reportValues[4]:
      const md = reportData
        .map((m) => ({
          name: m.name,
          location_id: m.location_id,
          lat: m.latitude,
          lng: m.longitude,
          weight: m.count,
        }))
        .sort((a, b) => b.weight - a.weight);

      return {
        list_data: isAdmin
          ? md.reduce((acc, cur) => {
              const found = acc.find((f) => f.location_id === cur.location_id);
              if (!found) {
                acc.push(cur);
              }
              return acc;
            }, [])
          : md.reduce((acc, cur) => {
              const found = acc.find((f) => f.name === cur.name);
              if (!found) {
                acc.push(cur);
              }
              return acc;
            }, []),
        map_data: md,
      };
    default:
      const mapData = reportData
        .map((m) => ({
          name: m.name,
          location_id: m.location_id,
          lat: m.latitude,
          lng: m.longitude,
          weight: m.count,
        }))
        .sort((a, b) => b.weight - a.weight);

      return {
        list_data: mapData.reduce((acc, cur) => {
          const found = acc.find((f) => f.location_id === cur.location_id);
          if (!found) {
            acc.push(cur);
          }
          return acc;
        }, []),
        map_data: mapData,
      };
  }
}
