import React from "react";

import { ManagementLayout } from "@components/shared";
import { UserList } from "./";

export default function UserManagement() {
  return <UserList />;

  return <ManagementLayout dataComponent={<UserList />} />;
}
