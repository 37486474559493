import React from "react";

import { ServerSessionList } from "@components/ServerSession";

export default function ServerSessionIndex() {
  return (
    <div>
      <ServerSessionList />
    </div>
  );
}
