import React, { useContext, useState, useEffect } from "react";
import { TextInput } from "@mantine/core";

import { Context } from "./";

export default function TextSettings({ onUpdate }) {
  const {
    member_rebound_failure_message: memberReboundFailureMessage,
    member_rebound_message: memberReboundMessage,
    non_member_rebound_failure_message: nonMemberReboundFailureMessage,
    non_member_rebound_message: nonMemberReboundMessage,
  } = useContext(Context);

  const textFields = [
    {
      name: "member_rebound_message",
      value: memberReboundMessage,
      label: "Member Rebound Message",
    },
    {
      name: "non_member_rebound_message",
      value: nonMemberReboundMessage,
      label: "Non-Member Rebound Message",
    },
    // {
    //   name: "member_rebound_failure_message",
    //   value: memberReboundFailureMessage,
    //   label: "Member Rebound Failure Message",
    // },
    // {
    //   name: "non_member_rebound_failure_message",
    //   value: nonMemberReboundFailureMessage,
    //   label: "Non-Member Rebound Failure Message",
    // },
  ];

  return (
    <div>
      {textFields.map((m, i) => (
        <TextInput
          key={i}
          label={m.label}
          value={m.value}
          onChange={(e) => {
            if (e.target.value.length > 160) return;
            onUpdate({ [m.name]: e.target.value });
          }}
          max={160}
        />
      ))}
    </div>
  );
}
