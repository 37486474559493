import React from "react";
import { useSelector } from "react-redux";

import { LootLockerManagement } from "@components/LootLocker";

export default function LootLockerIndex() {
  const managerInfo = useSelector((state) => state.manager);
  return (
    <LootLockerManagement
      orgId={managerInfo.organization_id}
      locationId={managerInfo.location_id}
    />
  );
}
