import React from "react";

import { ContestManagement } from "@components/Contest";

export default function ContestIndex({ locationId, organizationId }) {
  return (
    <ContestManagement
      locationId={locationId}
      organizationId={organizationId}
    />
  );
}
