import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Group, Image, Text, TextInput } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

import { PaginatedList } from "@components/shared";
import entityIcon from "@util/entityIcon";

import { formatArtistList } from "@components/Curation/helpers";

export default function SongList({
  artistId,
  locationId,
  requestInfo = {
    url: `/retrieve-songs/`,
    data: {},
  },
  refresh,
}) {
  // if we get a location id here, we are getting back
  // location song joins
  const [searchValue, setSearchValue] = useState("");

  const reqData = {
    ...requestInfo.data,
    search_value: searchValue,
    refresh,
    status: 1,
  };

  if (artistId) reqData.artist_id = artistId;
  if (locationId) reqData.location_id = locationId;

  return (
    <div>
      {requestInfo && (
        <TextInput
          placeholder="Search for a song..."
          size="lg"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      )}
      <PaginatedList
        requestInfo={{
          url: locationId ? `/retrieve-location-songs/` : requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: locationId
              ? `/location-songs/${m.location_song_id}`
              : `/songs/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <Card
            component={Link}
            to={item.original.link_url}
            key={item.original.song_id}
          >
            <Group align="flex-start">
              <div style={{ flexGrow: 1 }}>
                <Text fw={700}>{item.original.name}</Text>
              </div>
            </Group>
            {item.original.artists && (
              <Group gap="xs">
                <div>{entityIcon.artist()}</div>
                <Text size="sm">{formatArtistList(item.original.artists)}</Text>
              </Group>
            )}
            {item.original.artist && (
              <Group gap="xs">
                <div>{entityIcon.artist()}</div>
                <Text size="sm">
                  {formatArtistList([{ ...item.original.artist }])}
                </Text>
              </Group>
            )}
            {item.original.is_curated && (
              <Group gap="xs" align="center">
                <div>
                  <IconCheck
                    size={16}
                    stroke={5}
                    color="var(--mantine-color-green-6)"
                  />
                </div>
                <Text size="sm">Curated</Text>
              </Group>
            )}
          </Card>
        )}
      />
    </div>
  );
}
