import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Badge, Card, Group, Text } from "@mantine/core";

import { DataFilters, PaginatedList } from "@components/shared";

const varietyColors = {
  1: "green",
  2: "teal",
  3: "blue",
  4: "indigo",
  5: "orange",
};

const filterArr = [
  {
    text: "Variety",
    key_name: "variety",
    default_value: [1, 2, 3, 4, 5, 6],
    options: [
      { text: "Google Geocode", value: 1 },
      { text: "Twilio Carrier Lookup", value: 2 },
      { text: "Twilio Text Message", value: 3 },
      { text: "Postmark Email", value: 4 },
      { text: "Postmark Servers & Templates", value: 5 },
      { text: "Validation Check", value: 6 },
    ],
  },
].map((m, i) => ({
  ...m,
  all_values: m.options.map((mm) => mm.value),
  key: i + 1,
}));

const defaultFilters = filterArr.reduce((acc, cur) => {
  acc[cur.key_name] = cur.default_value;
  return acc;
}, {});

export default function ApiUseList({
  items = null,
  refresh,
  requestInfo = {
    url: `/retrieve-api-uses/`,
    data: {},
  },
}) {
  const [filters, setFilters] = useState(defaultFilters);

  const formattedFilters = Object.keys(filters).reduce((acc, cur) => {
    const obj = filterArr.find((f) => f.key_name === cur);
    acc[cur] = filters[cur].length ? filters[cur] : obj.all_values;
    return acc;
  }, {});

  function onFilterChange(keyName, value) {
    setFilters({
      ...filters,
      [keyName]: filters[keyName].includes(value)
        ? [...filters[keyName]].filter((f) => f !== value)
        : [...filters[keyName], value],
    });
  }

  const reqData = {
    ...formattedFilters,
    ...requestInfo.data,
    refresh,
  };

  return (
    <div>
      <React.Fragment>
        <DataFilters
          onChange={onFilterChange}
          values={filters}
          items={filterArr}
        />
      </React.Fragment>
      <PaginatedList
        tableData={items}
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/third-party-uses/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <Card key={item.original.id}>
            <Group>
              <Text
                size="md"
                fw={700}
                component={Link}
                to={`/third-party-uses/${item.original.id}`}
              >
                {item.original.title_string}
              </Text>

              <Text>
                {new Date(item.original.created_at).toLocaleDateString()}
              </Text>
            </Group>
            <Group>
              <Badge
                variant="light"
                p="sm"
                color={varietyColors[item.original.variety]}
              >
                {item.original.variety_formatted}
              </Badge>
              {item.original.context_formatted && (
                <Text size="sm">
                  {item.original.context_formatted.toUpperCase()}
                </Text>
              )}
            </Group>
          </Card>
        )}
      />
    </div>
  );
}
