import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  List,
  Divider,
  Modal,
  Button,
  Grid,
  Text,
  Group,
  Title,
} from "@mantine/core";
import { IconMapPin, IconHomeStar } from "@tabler/icons-react";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { t } from "i18next";

import EffortBuilderForm from "@components/EffortBuilder/EffortBuilderForm";
import {
  populateEffortFields,
  generateEffortFieldRequest,
} from "@components/EffortBuilder/helpers";
import { MicrositeUserEmailStreams } from "@components/Email";
import { ImageSlider } from "@components/shared";
import ViewLoader from "./ViewLoader";
import getGeoLocation from "@util/getGeoLocation";
import useAppStore from "./microsite-store";

export default function EffortView({
  preview,
  onEnterSuccess,
  onMoreEfforts,
  showPreferredLocation,
}) {
  const [effortData, setEffortData] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalOpened, setModalOpened] = useState(false);
  const [modalEfforts, setModalEfforts] = useState([]);
  const [successData, setSuccessData] = useState(null);

  const {
    appConfig,
    coords,
    email,
    effortId,
    emailStreams,
    entityInfo,
    location,
    phone,
    preferredLocation,
    effort,
    user,
    verificationMethod,
    setEffort,
    setEmailStreams,
    setLocation,
    setPreferredLocation,
  } = useAppStore(
    (state) => ({
      appConfig: state.appConfig,
      coords: state.coords,
      effortId: state.effortId,
      email: state.email,
      emailStreams: state.emailStreams,
      entityInfo: state.entityInfo,
      location: state.location,
      phone: state.phone,
      preferredLocation: state.preferredLocation,
      effort: state.effort,
      setEffort: state.setEffort,
      setEmailStreams: state.setEmailStreams,
      setLocation: state.setLocation,
      setPreferredLocation: state.setPreferredLocation,
      user: state.user,
      verificationMethod: state.verificationMethod,
    }),
    shallow
  );

  const isAdmin = useSelector((state) => state.admin) ? true : false;
  const isManager = useSelector((state) => state.manager) ? true : false;

  const isKeywordEntry = !effortData
    ? false
    : effortData.registration_method === "keyword";

  const { t } = useTranslation();

  const showChangeLocation =
    entityInfo.location_locked === true ||
    (entityInfo.location && entityInfo.location_locked === false)
      ? false
      : true;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    const req = {
      campaign_effort_id: effort ? effort.id : effortId,
    };

    if (preview) req.preview = true;
    if (entityInfo.location) {
      req.location_id = entityInfo.location.id;
    }

    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    axios
      .post(`/microsite/effort-data/`, req)
      .then(({ data }) => {
        setEffortData({
          ...data.response[0],
          configuration: data.response[0].configuration
            ? data.response[0].configuration
            : {},
        });
        setLoading(false);
      })
      .catch((err) => {
        setEffortData(null);
      });
  }

  function onFormSubmit(formData) {
    const hpField = document.getElementById("requiredReferralId");

    if (hpField && hpField.value) {
      return alert(
        "Success! The form has been submitted. You may now close this page!"
      );
    }

    if (isAdmin || isManager)
      return alert(
        `If we did let this form actually submit, the data that would've been submitted would've looked something like:\n\n${JSON.stringify(
          formData
        )}\n\nFortunately, this didn't submit because you're logged in.`
      );

    setSubmitting(true);

    generateEffortFieldRequest(fields, formData, user)
      .then((newReq) => {
        submitForm(newReq, formData);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  }

  function submitForm(reqData, formData) {
    const req = {
      ...reqData,
      campaign_effort_id: effort ? effort.id : effortId,
      submission_method: isKeywordEntry ? "keyword" : "form",
      keyword: isKeywordEntry ? formData.keyword : null,
    };

    if (preferredLocation) req.preferred_location_id = preferredLocation.id;
    if (user) req.user_id = user.id;

    if (!req.demographics.email) {
      if (email) {
        req.demographics.email = email;
      } else {
        if (user && user.email) {
          req.demographics.email = user.email;
        }
      }
    }
    if (phone) req.demographics.mobile_phone = phone;
    if (verificationMethod) req.verification_method = verificationMethod;
    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    if (location) {
      req.location_id = location.id;

      if (location.present_at_location) req.present_at_location = true;

      if (location.geo_spot_id) {
        req.campaign_effort_geo_spot_id = location.geo_spot_id;
        req.geo_spot_id = location.geo_spot_id;
      }
    }

    if (!user) {
      req.email_streams = {
        ...emailStreams.reduce((acc, cur) => {
          acc[cur.name] = cur.value;
          return acc;
        }, {}),
      };
    }

    setSubmitting(true);

    getGeoLocation().then((newCoords) => {
      if (newCoords) {
        req.latitude = newCoords.latitude;
        req.longitude = newCoords.longitude;
      }

      axios
        .post(`/microsite/submit/`, req)
        .then(({ data }) => {
          const res = data.response[0];
          setSubmitting(false);
          toast.success("Success!");

          if (entityInfo.pixel_code && window.fbq) {
            window.fbq("trackCustom", "FormSubmit");
          }

          if (res.has_more_efforts) {
            setModalOpened(true);
            setSuccessData(res);
            if (res.additonal_efforts) {
              setModalEfforts(res.additonal_efforts);
            }
          } else {
            onEnterSuccess(!user ? res : null);
          }
        })
        .catch((err) => {
          toast.error(err);
          setSubmitting(false);
        });
    });
  }

  function onModalClose() {
    setModalOpened(false);
    onEnterSuccess(!user ? successData : null);
  }

  function onMoreEffortsClick() {
    onMoreEfforts(successData);
  }

  if (loading) return <ViewLoader />;

  if (!effortData || !effort) return null;

  const effortAssets = effortData.assets;
  const { fields } = effortData.configuration;
  const assets = effortData.configuration.assets
    ? effortData.configuration.assets
        .map((m) => ({
          ...m,
          asset: effortAssets.find((f) => f.id === m.id),
        }))
        .filter((f) => f.asset)
    : [].filter((f) => f.asset);
  const sliderImages = assets.filter((f) => f.placement === 2);

  let fieldsToDisplay = populateEffortFields(fields, user, {
    phone: phone,
    email,
  });

  if (isKeywordEntry)
    fieldsToDisplay.push({
      label: "Keyword",
      required: true,
      schema: () => Yup.string().required(),
      name: "keyword",
      type: "text",
    });

  return (
    <div>
      <SuccessModal
        onClose={onModalClose}
        opened={modalOpened}
        onMoreEffortsClick={onMoreEffortsClick}
        efforts={modalEfforts}
      />
      {sliderImages.length > 0 && (
        <ImageSlider
          items={[
            ...sliderImages.map((m) => ({
              src: m.asset.filename_url,
              external_link_url: m.asset.external_link_url,
              key: m.id,
            })),
          ]}
        />
      )}
      <Title align="center" mt="sm" c="white">
        {effortData.title}
      </Title>
      {(showChangeLocation || showPreferredLocation) && (
        <Group justify="center" mt="sm" mb="lg">
          {showChangeLocation && (
            <Button
              size="xs"
              leftSection={<IconMapPin />}
              title="Change location"
              radius="xl"
              onClick={() => {
                if (!effortId) {
                  setEffort(null);
                }
                setLocation(null);
                setPreferredLocation(null);
              }}
            >
              {location.name}
            </Button>
          )}
          {showPreferredLocation && (
            <Button
              radius="xl"
              size="xs"
              leftSection={<IconHomeStar />}
              variant="light"
              title="Change preferred location"
              onClick={() => {
                setPreferredLocation(null);
              }}
            >
              {preferredLocation.name}
            </Button>
          )}
        </Group>
      )}
      {user && (
        <Text align="center" mb="lg" size="xl" color="white" mt="sm">
          {user.first_name
            ? `${t("user_welcome_title")}, ${user.first_name}!`
            : `${t("user_welcome_title")}!`}
        </Text>
      )}
      <Grid gutter={30}>
        <Grid.Col span={{ base: 12 }}>
          <React.Fragment>
            {fieldsToDisplay && fieldsToDisplay.length > 0 && (
              <EffortBuilderForm
                fields={fieldsToDisplay}
                onSubmit={onFormSubmit}
                loading={submitting}
                buttonText={t("effort_form_submit_button")}
              />
            )}
          </React.Fragment>
        </Grid.Col>
      </Grid>
      <>
        <Divider mt="lg" mb="lg" />
        <MicrositeUserEmailStreams
          user={user}
          streams={emailStreams}
          onChange={(e) => {
            setEmailStreams(e);
          }}
        />
      </>
      {user && (
        <Group justify="center">
          <Button
            variant="subtle"
            color="gray"
            style={{ marginTop: user ? "1em" : "3em" }}
            onClick={() => setEffort(null)}
          >
            {t("back")}
          </Button>
        </Group>
      )}
      {/* {user && <EnterEffortButton onSuccess={onEnterSuccess} />} */}
    </div>
  );
}

const SuccessModal = ({ opened, onClose, onMoreEffortsClick, efforts }) => {
  return (
    <Modal opened={opened} withCloseButton={false}>
      <Text align="center" size="xl" fw={600}>
        {t("more_ways_to_win")}
      </Text>
      {efforts && efforts.length > 0 && (
        <List mt="lg">
          {efforts.map((eff, i) => (
            <List.Item key={i}>{eff.title}</List.Item>
          ))}
        </List>
      )}
      <Divider mt="lg" mb="lg" />
      <Button fullWidth onClick={onMoreEffortsClick}>
        {t("view_more_efforts")}
      </Button>
      <Group justify="center" mt="sm">
        <Button variant="subtle" onClick={onClose}>
          {t("registration_complete")}
        </Button>
      </Group>
    </Modal>
  );
};
