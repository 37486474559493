import React from "react";
import { Card, Text } from "@mantine/core";

export default function EmptyMessage({
  text = "If you had results, they'd appear here.",
  title = "No results",
}) {
  return (
    <Card>
      <Text fw={700}>{title}</Text>
      <Text size="sm" variant="subtle">
        {text}
      </Text>
    </Card>
  );
}
