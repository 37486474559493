import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Title } from "@mantine/core";

import { AuctionCreate, AuctionList } from "./";
import { ManagementLayout } from "@components/shared";

export default function AuctionManagement({
  endDate = null,
  locationId = null,
  organizationId = null,
}) {
  const [creating, setCreating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const isManager = useSelector((state) => state.manager);

  const req = {
    refresh,
  };

  if (organizationId) req.organization_id = organizationId;
  if (locationId) req.location_id = locationId;

  useEffect(() => {
    if (organizationId || locationId) {
      setCreating(true);
    } else {
      setCreating(false);
    }
  }, [organizationId, locationId]);

  if (!organizationId && !locationId) {
    return (
      <AuctionList
        isManager={isManager}
        hiddenFilters={organizationId ? ["owned_by"] : []}
        refresh={refresh}
        requestInfo={{ url: `/retrieve-contests/`, data: req }}
      />
    );
  }

  const showCreateAssign = locationId || organizationId;

  return (
    <ManagementLayout
      createComponent={
        showCreateAssign && (
          <Card>
            <Title order={4}>Create Auction</Title>
            {creating && !success && (
              <AuctionCreate
                organizationId={organizationId}
                locationId={locationId}
                endDate={endDate}
                reqData={req}
                onSuccess={() => {
                  setSuccess(!success);
                  setRefresh(!refresh);
                  setTimeout(() => {
                    setSuccess(false);
                  }, 1);
                }}
              />
            )}
          </Card>
        )
      }
      dataComponent={
        <div>
          <AuctionList
            isManager={isManager}
            hiddenFilters={organizationId ? ["owned_by"] : []}
            refresh={refresh}
            requestInfo={{ url: `/retrieve-contests/`, data: req }}
          />
        </div>
      }
    />
  );
}
