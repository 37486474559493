import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Card, TextInput, Title } from "@mantine/core";

import { ManagementLayout } from "@components/shared";
import { GeospotForm, GeospotList } from "./";

export default function GeospotManagement({ locationId }) {
  const [createVisible, setCreateVisible] = useState(true);
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [searchValue]);

  function fetchData() {
    const req = {
      location_id: locationId,
      search_value: searchValue,
    };

    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    axios
      .post(`/retrieve-geo-spots/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setItems(data.response);
      })
      .catch((err) => {
        setItems([]);
      });
  }

  return (
    <ManagementLayout
      dataComponent={
        <div>
          <TextInput
            placeholder="Search for a geospot..."
            size="lg"
            mb="md"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <GeospotList items={items} fetchData={fetchData} />
        </div>
      }
      createComponent={
        <Card
          style={{
            overflow: "visible",
          }}
        >
          {createVisible && (
            <React.Fragment>
              <Title order={4}>Create Geo-Spot</Title>
              <GeospotForm
                locationId={locationId}
                onSuccess={() => {
                  setCreateVisible(false);
                  fetchData();

                  setTimeout(() => {
                    setCreateVisible(true);
                  }, 10);
                }}
              />
            </React.Fragment>
          )}
        </Card>
      }
    />
  );
}
