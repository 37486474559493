import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Button, Group, Title, Text } from "@mantine/core";

const btns = [
  // { text: "created", value: 1, color: "yellow" },
  { text: "on", value: 2, color: "green" },
  { text: "off", value: 3, color: "red" },
].map((m) => ({
  ...m,
  key: m.value,
}));

export default function KeywordStatus({ keywordId, fetchData, status }) {
  const [loading, setLoading] = useState(false);

  function onClick(newStatus) {
    const req = {
      id: keywordId,
      status: newStatus,
    };

    setLoading(true);

    axios
      .post(`/keywords/${keywordId}/set-status/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Status changed!");
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      <Title order={4}>Status</Title>
      {status === 1 && (
        <Text mb="xs">
          This keyword is in a created state and is not yet active.
        </Text>
      )}
      <Group spacing="xs">
        {btns.map((b) => (
          <Button
            key={b.key}
            onClick={() => onClick(b.value)}
            color={b.color}
            size="xs"
            variant={status === b.value ? "filled" : "light"}
          >
            {b.text}
          </Button>
        ))}
      </Group>
    </React.Fragment>
  );
}
