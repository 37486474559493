import React from "react";
import { Title } from "@mantine/core";

import { SongReport } from "@components/Report";

export default function SongReportIndex({ locationId }) {
  return (
    <>
      <Title order={4} mb="sm">
        Song Reporting
      </Title>
      <SongReport locationId={locationId} />
    </>
  );
}
