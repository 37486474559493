import { getUuid } from "@util/getUuid";

export const textItems = [
  { title: "Info Modal Button", name: "info_button" },
  { title: "Info Modal Title", name: "title" },
  { title: "Info Modal Description", name: "intro", rte: true },
  { title: "Location question (where are you)", name: "location_question" },
  { title: "Location search title", name: "location_search_title" },
  { title: "Location search subtitle", name: "location_search_subtitle" },
].map((m) => ({
  ...m,
  key: getUuid(),
}));

export const colorItems = [
  { title: "Background", name: "background" },
  { title: "Icon", name: "icon" },
  { title: "Card", name: "card" },
].map((m) => ({
  ...m,
  key: getUuid(),
}));

export const initialCampaignConfiguration = {
  assets: [],
  components: {
    gauge_variant: 1,
  },
  colors: {
    base: "dark",
    ...colorItems.reduce((acc, cur) => {
      acc[cur.name] = "";
      return acc;
    }, {}),
  },
  settings: {
    geolocation_prompt: true,
    location_locked: false,
    preferred_location: false,
    language: "en",
  },
  text: textItems.reduce((acc, cur) => {
    acc[cur.name] = "";
    return acc;
  }, {}),
  links: [],
};
