import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Text, Alert } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

import { EffortList } from "@components/Effort";
import { TransferList } from "@components/shared";

export default function EffortAddonManagement({ effortId }) {
  const [efforts, setEfforts] = useState([]);
  const [availableEfforts, setAvailableEfforts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [linkedEfforts, setLinkedEfforts] = useState([]);

  const currentEffortIds = efforts.map((c) => c.id);

  useEffect(() => {
    fetchAddedEfforts();
    fetchAvailableEfforts();
    fetchLinkedEfforts();
  }, [effortId]);

  function onChange(e) {
    const newEffortIds = e[0].map((m) => m.value);
    const removedEffortIds = e[1].map((m) => m.value);

    const effortsWereRemoved = currentEffortIds.some((r) =>
      removedEffortIds.includes(r)
    );

    if (effortsWereRemoved) {
      return onEffortRemove(
        removedEffortIds.filter((f) => currentEffortIds.includes(f))
      );
    }

    const req = {
      primary_effort_id: effortId,
      addon_effort_ids: newEffortIds,
    };

    axios
      .post(`/add-effort-addon/`, req)
      .then(() => {
        fetchAddedEfforts();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onEffortRemove(removedEffortIds) {
    const req = {
      primary_effort_id: effortId,
      addon_effort_ids: removedEffortIds,
    };

    axios
      .post(`/remove-effort-addon/`, req)
      .then(() => {
        fetchAvailableEfforts();
        fetchAddedEfforts();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function fetchAddedEfforts() {
    setLoading(true);

    axios
      .post(`/efforts/${effortId}/retrieve-added-efforts/`, {
        campaign_effort_id: effortId,
      })
      .then(({ data }) => {
        setEfforts(data.response);
      })
      .catch((err) => {
        setEfforts([]);
      });
  }

  function fetchAvailableEfforts() {
    axios
      .post(`/efforts/${effortId}/retrieve-addable-efforts/`, {
        campaign_effort_id: effortId,
      })
      .then(({ data }) => {
        setAvailableEfforts(data.response);
      })
      .catch((err) => {
        setAvailableEfforts([]);
      });
  }

  function fetchLinkedEfforts() {
    setLoading(true);

    axios
      .post(`/efforts/${effortId}/retrieve-inverse-efforts/`, {
        campaign_effort_id: effortId,
      })
      .then(({ data }) => {
        setLinkedEfforts(
          data.response.map((m) => ({
            ...m,
            original: {
              ...m,
              link_url: `/efforts/${m.id}/addons`,
            },
          }))
        );
      })
      .catch((err) => {
        setLinkedEfforts([]);
      });
  }

  const formattedAdded = efforts.map((m) => ({
    value: m.id,
    label: m.title,
  }));

  const addedIds = efforts.map((m) => m.id);

  const formattedAvailable = availableEfforts
    .filter((f) => !addedIds.includes(f.id))
    .map((m) => ({
      label: m.title,
      value: m.id,
    }));

  return (
    <div>
      <TransferList
        breakpoint="sm"
        value={[[...formattedAdded], [...formattedAvailable]]}
        onChange={onChange}
        searchPlaceholder="Search..."
        nothingFound="Nothing here..."
        listHeight={400}
        titles={["Visible Efforts", "Invisible Efforts"]}
      />
      {linkedEfforts.length > 0 && (
        <>
          <Text mb="sm" mt="lg" fw={600}>
            This effort is visible to the following efforts
          </Text>
          <EffortList items={linkedEfforts} shouldFetch={false} />
        </>
      )}
    </div>
  );
}
