import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Group, Loader, Radio, Text, TextInput } from "@mantine/core";
import styled from "styled-components";

import { formatArtistList } from "@components/Curation/helpers";

const CurationSearch = ({
  artistId,
  isArtist,
  isSong,
  onChange,
  songId,
  text,
  value,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [searchValue]);

  function fetchData() {
    if (!searchValue || searchValue.length < 2) return;

    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    setLoading(true);
    onChange(null);

    const req = {
      context: isArtist ? "artist" : "song",
      search_value: searchValue,
    };

    if (songId) req.song_id = songId;
    if (artistId) req.artist_id = artistId;

    axios
      .post(`/curation-search/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setResults(data.response.sort((a, b) => (a.name > b.name ? 1 : -1)));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div>
      <Group mb="sm">
        <Text fw={600}>
          {text
            ? text
            : isArtist
            ? "or assign this song to a different artist"
            : "Mark this song as an existing song"}
        </Text>
        {loading && <Loader size="xs" />}
      </Group>
      <TextInput
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={`search ${isArtist ? "artists" : "songs"}`}
        mb="sm"
      />
      {results.length > 0 && (
        <React.Fragment>
          <StyledRadioList>
            {results.map((r) => (
              <Group mb="xs">
                <Radio
                  name="tagId"
                  checked={r.id === value}
                  onChange={() => onChange(r.id)}
                  key={r.id}
                />
                <div>
                  <Text size="sm">
                    {r.name}{" "}
                    {!isSong &&
                      `(${r.song_count} song${r.song_count === 1 ? "" : "s"})`}
                  </Text>
                  {isSong && r.play_count !== undefined && (
                    <Text size="xs" c="dimmed">
                      {r.play_count} play{r.play_count === 1 ? "" : "s"}
                    </Text>
                  )}
                  {isSong && r.rating_count !== undefined && (
                    <Text size="xs" c="dimmed">
                      {r.rating_count} rating{r.rating_count === 1 ? "" : "s"}
                    </Text>
                  )}
                  {isSong && r.artists && (
                    <div>
                      {r.artists.map((artist, i) => (
                        <Text key={i} size="xs" c="dimmed">
                          {artist.name} ({artist.song_count} song
                          {artist.song_count === 1 ? "" : "s"})
                        </Text>
                      ))}
                    </div>
                  )}
                </div>
              </Group>
            ))}
          </StyledRadioList>
        </React.Fragment>
      )}
      {!loading && results.length === 0 && searchValue.length > 1 && (
        <React.Fragment>
          <Text>No results</Text>
        </React.Fragment>
      )}
    </div>
  );
};

const StyledRadioList = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin-top: 15px;
`;

export default CurationSearch;
