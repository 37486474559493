import React, { useEffect, useState } from "react";
import { Center, Text, Button, Group } from "@mantine/core";
import { IconHomeStar } from "@tabler/icons-react";
import { shallow } from "zustand/shallow";

import LocationSearch from "./LocationSearch";
import LocationLocator from "./LocationLocator";
import ViewTitle from "./ViewTitle";
import { iconSize } from "./helpers";
import useAppStore from "./microsite-store";

export default function PreferredLocation({ onSubmit = null }) {
  const [showSelect, setShowSelect] = useState(false);

  const { user, location, setPreferredLocation } = useAppStore(
    (state) => ({
      user: state.user,
      location: state.location,
      setPreferredLocation: state.setPreferredLocation,
    }),
    shallow
  );

  useEffect(() => {
    if (user) {
      setShowSelect(true);
    }
  }, []);

  if (showSelect) {
    return (
      <div>
        <ViewTitle
          title="Home Location"
          subtitle="Find your home location"
          icon={<IconHomeStar size={iconSize} />}
        />
        <Text c="white">Search for your home location below:</Text>
        <LocationSearch
          allLocations
          onSelect={(e) => (onSubmit ? onSubmit(e) : setPreferredLocation(e))}
        />
        <Text c="white">
          <b>or</b>
        </Text>
        <Text c="white">enter your zip code to find locations near you</Text>
        <LocationLocator
          showTitle={false}
          onLocationSelect={(e) => {
            onSubmit ? onSubmit(e) : setPreferredLocation(e);
          }}
          buttonText="Set Home Location"
          allLocations
        />
        {(location || (user && user.preferred_location)) && (
          <Group justify="center">
            <Button
              variant="subtle"
              color="gray"
              style={{ marginTop: user ? "1em" : "3em" }}
              onClick={() =>
                setPreferredLocation(user ? user.preferred_location : location)
              }
            >
              Nevermind
            </Button>
          </Group>
        )}
      </div>
    );
  }

  return (
    <div>
      <ViewTitle
        title="Home Location"
        subtitle="Set your home location"
        icon={<IconHomeStar size={iconSize} />}
      />
      {location && (
        <Center>
          <Text size="xl" mb="xl" align="center" c="white">
            Is <b>{location.name}</b> also your home location?
          </Text>
        </Center>
      )}
      <Group grow>
        <Button onClick={() => setPreferredLocation(location)} size="xl">
          Yep!
        </Button>
        <Button
          color="red"
          onClick={() => setShowSelect(true)}
          variant="light"
          size="xl"
        >
          Nah
        </Button>
      </Group>
    </div>
  );
}
