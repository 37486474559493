import React, { useContext, useState, useEffect } from "react";
import { Group } from "@mantine/core";

import { Counter } from "@components/shared";
import { Context } from "./";

export default function GeneralSettings({ onUpdate }) {
  const { point_value: pointValue } = useContext(Context);

  const counterFields = [
    {
      title: "Point Value",
      keyName: "point_value",
      value: pointValue,
      maxValue: 7,
    },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
  }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            value={c.value}
            onChange={(e) => {
              if (parseFloat(e) > c.maxValue) {
                onUpdate({
                  [c.keyName]: 5,
                });
              } else {
                onUpdate({
                  [c.keyName]: e,
                });
              }
            }}
            maxValue={c.maxValue}
            unlimited={false}
          />
          <h3 style={{ margin: "0" }}>{c.title}</h3>
        </Group>
      ))}
    </div>
  );
}
