import React, { useState } from "react";
import { shallow } from "zustand/shallow";
import useAppStore from "./report-recipe-runner-store";
import {
  Flex,
  Grid,
  Radio,
  Select,
  Switch,
  Box,
  ActionIcon,
  Text,
  Space,
} from "@mantine/core";
import { useSelector } from "react-redux";
import { IconX } from "@tabler/icons-react";

import { LocationSelect } from "@components/Location";
import { OrganizationSelect } from "@components/Organization";

const RemoveIcon = () => <IconX size={16} />;

export default function ReportRecipeEntityPicker() {
  const [showEntitySelect, setShowEntitySelect] = useState(true);
  const adminInfo = useSelector((state) => state.admin);
  const managerInfo = useSelector((state) => state.manager);

  const isAdmin = adminInfo ? true : false;
  const isOrgManager = managerInfo?.organization_id;
  const isLocationManager = managerInfo?.location_id;

  const activeOrg =
    isAdmin || isLocationManager
      ? null
      : managerInfo.organizations.find(
          (f) => f.organization_id === managerInfo.organization_id
        );

  const {
    locations,
    orgs,
    recipeInfo,
    setLocations,
    setOrgs,
    managerQueryEntity,
    setManagerQueryEntity,
    setReportData,
    setQueryData,
  } = useAppStore(
    (state) => ({
      locations: state.locations,
      orgs: state.orgs,
      recipeInfo: state.recipe_info,
      setLocations: state.setLocations,
      setOrgs: state.setOrgs,
      managerQueryEntity: state.manager_query_entity,
      setManagerQueryEntity: state.setManagerQueryEntity,
      setQueryData: state.setQueryData,
      setReportData: state.setReportData,
    }),
    shallow
  );

  const showOrgSelect = recipeInfo.show_org_select;
  const showLocationSelect = recipeInfo.show_loc_select;
  const showOrgLocationSelect = recipeInfo.show_org_loc_select;

  if (showOrgSelect) {
    return (
      <OrganizationSelect
        value={orgs.length === 1 ? orgs[0].id : ""}
        onChange={(e) => {
          if (!e) {
            setOrgs([]);
          } else {
            setOrgs([{ id: e.id, name: e.name }]);
          }
          setReportData(null);
          setShowEntitySelect(false);
          setTimeout(() => {
            setShowEntitySelect(true);
          }, 1);
        }}
        objectOnChange
        // disabled={orgs.length > 0}
        // disabledIds={orgs.map((m) => m.id)}
      />
    );
  }

  if (showLocationSelect) {
    return (
      <LocationSelect
        value={locations.length === 1 ? locations[0].id : ""}
        onChange={(e) => {
          if (!e) {
            setLocations([]);
          } else {
            setLocations([...locations, { id: e.id, name: e.name }]);
          }
          setReportData(null);
          setShowEntitySelect(false);
          setTimeout(() => {
            setShowEntitySelect(true);
          }, 1);
        }}
        // disabled={
        //   locations.length === 1 || orgs.length > 0
        //     ? true
        //     : false || managerQueryEntity !== "location"
        // }
        // disabledIds={locations.map((m) => m.id)}
        objectOnChange
      />
    );
  }

  return (
    <div>
      <OrganizationSelect
        value={orgs.length === 1 ? orgs[0].id : ""}
        onChange={(e) => {
          if (!e) {
            setOrgs([]);
          } else {
            setOrgs([{ id: e.id, name: e.name }]);
          }
          setReportData(null);
          setShowEntitySelect(false);
          setTimeout(() => {
            setShowEntitySelect(true);
          }, 1);
        }}
        objectOnChange
        disabled={locations.length > 0}
        // disabled={orgs.length > 0}
        // disabledIds={orgs.map((m) => m.id)}
      />
      <Space mb="6px" />
      <LocationSelect
        value={locations.length === 1 ? locations[0].id : ""}
        onChange={(e) => {
          if (!e) {
            setLocations([]);
          } else {
            setLocations([...locations, { id: e.id, name: e.name }]);
          }
          setReportData(null);
          setShowEntitySelect(false);
          setTimeout(() => {
            setShowEntitySelect(true);
          }, 1);
        }}
        disabled={orgs.length > 0}
        // disabledIds={locations.map((m) => m.id)}
        objectOnChange
      />
    </div>
  );
}

// {showOrgLocationSelect && (
//   <Flex gap="xs" direction="column" mb="sm">
//     <Radio
//       name="queryEntity"
//       label={`Use ${activeOrg?.name}`}
//       checked={managerQueryEntity === "organization"}
//       onChange={() => setManagerQueryEntity("organization")}
//     />
//     <Radio
//       name="queryEntity"
//       label={`Use ${activeOrg?.name} Locations`}
//       checked={managerQueryEntity === "location"}
//       onChange={() => setManagerQueryEntity("location")}
//     />
//   </Flex>
// )}
// <Flex
//   gap="xs"
//   direction="column"
//   align="flex-start"
//   style={{ width: "100%" }}
// >
//   {locations.length === 0 && isAdmin && showOrgSelect && (
//     <div style={{ flexGrow: 1, width: "100%" }}>
//       {showEntitySelect ? (
//         <OrganizationSelect
//           value={orgs.length === 1 ? orgs[0].id : ""}
//           onChange={(e) => {
//             if (!e) {
//               setOrgs([]);
//             } else {
//               setOrgs([{ id: e.id, name: e.name }]);
//             }
//             setReportData(null);
//             setShowEntitySelect(false);
//             setTimeout(() => {
//               setShowEntitySelect(true);
//             }, 1);
//           }}
//           objectOnChange
//           // disabled={orgs.length > 0}
//           // disabledIds={orgs.map((m) => m.id)}
//         />
//       ) : (
//         <Select label="Organization" />
//       )}
//       <Box mt="sm">
//         <Grid>
//           {orgs.map((m, i) => (
//             <Grid.Col key={m.id} span={{ base: 12, md: 12 }}>
//               <Flex gap="xs" align="center">
//                 <ActionIcon
//                   size="xs"
//                   color="red"
//                   variant="subtle"
//                   onClick={() => {
//                     setOrgs([...orgs].filter((f) => f.id !== m.id));
//                     setReportData(null);
//                   }}
//                 >
//                   <RemoveIcon />
//                 </ActionIcon>
//                 <Text size="sm">{m.name}</Text>
//               </Flex>
//             </Grid.Col>
//           ))}
//         </Grid>
//       </Box>
//     </div>
//   )}
//   {orgs.length === 0 && showLocationSelect && (
//     <div style={{ flexGrow: 1, width: "100%" }}>
//       {showEntitySelect ? (
//         <LocationSelect
//           value={locations.length === 1 ? locations[0].id : ""}
//           onChange={(e) => {
//             if (!e) {
//               setLocations([]);
//             } else {
//               setLocations([...locations, { id: e.id, name: e.name }]);
//             }
//             setReportData(null);
//             setShowEntitySelect(false);
//             setTimeout(() => {
//               setShowEntitySelect(true);
//             }, 1);
//           }}
//           // disabled={
//           //   locations.length === 1 || orgs.length > 0
//           //     ? true
//           //     : false || managerQueryEntity !== "location"
//           // }
//           // disabledIds={locations.map((m) => m.id)}
//           objectOnChange
//         />
//       ) : (
//         <Select label="Location" />
//       )}
//       <Box mt="sm">
//         <Grid>
//           {locations.map((m, i) => (
//             <Grid.Col span={{ base: 12, md: 12 }} key={i}>
//               <Flex gap="xs" align="center" key={m.id}>
//                 <ActionIcon
//                   size="xs"
//                   color="red"
//                   variant="subtle"
//                   onClick={() => {
//                     setLocations(
//                       [...locations].filter((f) => f.id !== m.id)
//                     );
//                     setReportData(null);
//                   }}
//                 >
//                   <RemoveIcon />
//                 </ActionIcon>
//                 <Text size="sm">{m.name}</Text>
//               </Flex>
//             </Grid.Col>
//           ))}
//         </Grid>
//       </Box>
//     </div>
//   )}
// </Flex>
