import React from "react";
import { Badge } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { getPlacementImage } from "@components/Asset/helpers";
import { placementDefs } from "@components/Asset/placementDefs";

import ItemSlider from "./ItemSlider";
import useAppStore from "./microsite-store";

const ContestSlider = ({ contests = [], onSlideChange }) => {
  if (!contests.length) return null;

  const colors = useAppStore((state) => state.colors);

  const { t } = useTranslation();

  return (
    <ItemSlider
      autoplaySpeed={5000}
      textBottom
      onSlideChange={onSlideChange}
      data={contests
        // .sort((a, b) => b.retail_value_in_pennies - a.retail_value_in_pennies)
        .map((contest) => ({
          ...contest,
          image: getPlacementImage(
            contest.configuration,
            contest.assets,
            placementDefs.featured
          ),
          textBottom: true,
          backgroundColor: colors.card,
          title: contest.name,
          category: contest.message
            ? `${t("contest")} - ${contest.message}`
            : t("contest"),
          children: (
            <Badge variant="light" size="lg">
              {t("entries")}: {contest.count}
              {/* {contest.count} entr{contest.count === 1 ? "y" : "ies"} so far */}
            </Badge>
          ),
        }))}
    />
  );
};

export default ContestSlider;
