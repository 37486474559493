import React from "react";
import { Group, Loader } from "@mantine/core";

export default function ViewLoader() {
  return (
    <Group
      justify="center"
      style={{
        padding: "140px 0",
      }}
    >
      <Loader size="xl" variant="dots" color="gray" />
    </Group>
  );
}
