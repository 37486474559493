import React, { useState, useEffect } from "react";
import { Card } from "@mantine/core";

import { OrganizationForm, OrganizationList } from "./";
import { Header, ManagementLayout } from "@components/shared";

export default function OrganizationManagement() {
  const [success, setSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);

  return (
    <ManagementLayout
      dataComponent={<OrganizationList refresh={refresh} />}
      createComponent={
        <Card
          style={{
            overflow: "visible",
          }}
        >
          <Header>Create Organization</Header>
          {!success && (
            <OrganizationForm
              onSuccess={() => {
                setSuccess(!success);
                setRefresh(!refresh);
                setTimeout(() => {
                  setSuccess(false);
                }, 1);
              }}
            />
          )}
        </Card>
      }
    />
  );
}
