import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Card,
  Group,
  Loader,
  NumberInput,
  Select,
  Text,
  ActionIcon,
  Table,
} from "@mantine/core";
import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import {
  IconChevronsLeft,
  IconChevronsRight,
  IconChevronRight,
  IconChevronLeft,
} from "@tabler/icons-react";

import { PageTitle } from "@components/shared";

export default function UserInteractionTable() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const table = useReactTable({
    manualPagination: true,
    pageCount: pageCount,
    data: items,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(table.getState().pagination)]);

  function fetchData() {
    const req = {
      page_size: table.getState().pagination.pageSize,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
    };

    const cancel = cancelRef.current;
    if (cancel) cancel();

    setLoading(true);

    axios
      .post("/monitoring/user-interactions/", req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLoading(false);
        setItems(data.response[0].data);
        setPageCount(data.response[0].page_count);
        if (data.response[0].total !== undefined) {
          setTotal(data.response[0].total);
        } else {
          setTotal(data.response[0].data.length);
        }
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  const arrowButtons = [
    {
      title: "First Page",
      onClick: () => table.setPageIndex(0),
      disabled: !table.getCanPreviousPage(),
      icon: <IconChevronsLeft />,
    },
    {
      title: "Previous Page",
      onClick: () => table.previousPage(),
      disabled: !table.getCanPreviousPage(),
      icon: <IconChevronLeft />,
    },
    {
      title: "Next Page",
      onClick: () => table.nextPage(),
      disabled: !table.getCanNextPage(),
      icon: <IconChevronRight />,
    },
    {
      title: "Last Page",
      onClick: () => table.setPageIndex(table.getPageCount() - 1),
      disabled: !table.getCanNextPage(),
      icon: <IconChevronsRight />,
    },
  ];

  return (
    <div>
      <PageTitle title="Interactions" />
      <Group mb="lg" mt="lg">
        {arrowButtons.map((m, i) => (
          <ActionIcon
            disabled={m.disabled}
            key={i}
            onClick={m.onClick}
            title={m.title}
            size="lg"
            radius="sm"
            variant="light"
            color="blue"
          >
            {m.icon}
          </ActionIcon>
        ))}
        <Select
          size="sm"
          style={{ width: "110px" }}
          value={table.getState().pagination.pageSize}
          onChange={(e) => table.setPageSize(e)}
          data={[5, 10, 20, 30, 40, 50].map((pageSize) => ({
            label: `${pageSize} items`,
            value: `${pageSize}`,
            placeholder: "Select a pagesize",
          }))}
        />
        <NumberInput
          value={table.getState().pagination.pageIndex + 1}
          style={{ width: "100px" }}
          min={1}
          max={pageCount}
          onChange={(e) => {
            const page = e ? Number(e) - 1 : 0;
            table.setPageIndex(page);
          }}
        />
        <Text>
          {total} total item{total === 1 ? "" : "s"}
        </Text>
        {loading && <Loader size="xs" variant="dots" />}
      </Group>
      <Card>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Time</Table.Th>
              <Table.Th>Email</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {table.getRowModel().rows.map((item) => (
              <Table.Tr>
                <Table.Td>
                  {new Date(item.original.created_at).toLocaleString()}
                </Table.Td>
                <Table.Td>{item.original.email}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Card>
    </div>
  );
}
