import React, { useState } from "react";
import { shallow } from "zustand/shallow";
import { useNavigate } from "react-router-dom";
import { Button, TextInput, Space } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { CustomTooltip } from "@components/shared";
import { SectionTitle } from "./WizardComponents";
import { initialState as prizePoolInitialSettings } from "@components/PrizePool/PrizePoolSettings/helpers";
import { initialPrizePoolSettings } from "@components/Prize/helpers";
import { initialConfig as initialMicrositeConfig } from "@components/shared/MicrositeSettings/helpers";
import {
  initialState as effortInitialSettings,
  generateEffortRebounds,
} from "@components/Effort/EffortSettings/helpers";
import {
  effortWizardSettings,
  defaultEffortConfig,
} from "@components/Effort/helpers";
import { contestWizardSettings } from "@components/Contest/helpers";

import useAppStore from "./wizard-store";

export default function WizardPrizePool() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { contestSettings, location, organization, setContestSettings } =
    useAppStore(
      (state) => ({
        contestSettings: state.contestSettings,
        location: state.location,
        organization: state.organization,
        setContestSettings: state.setContestSettings,
      }),
      shallow
    );

  function onSubmit() {
    const req = {
      create_prize_pool: {
        ...contestSettings,
        location_id: location ? location.id : null,
        organization_id: organization ? organization.id : null,
        variety: 2,
        settings: {
          ...prizePoolInitialSettings,
        },
        wizard_settings: {
          ...contestWizardSettings,
          dates_required: true,
          entry_cost_required: true,
          featured_image_required: true,
          locations_required: false,
          prize_required: true,
          // locations_required: organization ? true : false,
        },
        prize: {
          settings: initialPrizePoolSettings,
          name: `${contestSettings.name} Prize`,
          // description: "A description of this prize",
          description: "",
        },
        create_effort: {
          title: `${contestSettings.name} Prize Pool Effort`,
          recurring_engagement: 2,
          award_type: 1,
          variety: 2,
          location_id: location ? location.id : null,
          organization_id: organization ? organization.id : null,
          draft_app_configuration: initialMicrositeConfig,
          draft_configuration: defaultEffortConfig,
          settings: {
            ...effortInitialSettings,
            ...generateEffortRebounds(contestSettings.name),
          },
          wizard_settings: {
            ...effortWizardSettings,
            recurring: false,
            show_assets: false,
            show_award_type: false,
            show_awards: true,
            show_builder: false,
            show_csv: false,
            show_geospots: false,
            show_interaction_type: false,
            show_keywords: false,
            show_look_feel: false,
            show_quick_settings: false,
          },
        },
      },
    };

    axios
      .post(`/wizard/`, req)
      .then(({ data }) => {
        setLoading(false);
        navigate(`/prize-pools/${data.response[0].contest_id}`);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const submitDisabled = !contestSettings.name || (!location && !organization);

  return (
    <div>
      <React.Fragment>
        <Space mt="lg" />
        <CustomTooltip label="Prize Pool names will be visible to the users.">
          <SectionTitle text="Tell us a little more" />
        </CustomTooltip>
        <TextInput
          label="Prize Pool Name"
          maxLength={100}
          value={contestSettings.name}
          onChange={(e) =>
            setContestSettings({
              ...contestSettings,
              name: e.target.value,
            })
          }
        />
        <Button
          mt="sm"
          fullWidth
          onClick={onSubmit}
          loading={loading}
          disabled={submitDisabled}
        >
          Create
        </Button>
      </React.Fragment>
    </div>
  );
}
