import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Loader, Card } from "@mantine/core";

import { StatBlocks } from "@components/DataViz";

export default function DashboardMetaBlocks() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setError(false);

    const req = {
      context: "meta_blocks",
    };

    axios
      .post(`/dashboard/`, req)
      .then(({ data }) => {
        setLoading(false);
        setItems(data.response[0].meta_blocks);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setItems([]);
      });
  }

  if (loading || error) {
    return (
      <div style={{ minHeight: "100px" }}>
        <StatBlocks
          data={[
            error
              ? {
                  text: "There was a problem loading this data",
                  value: "Error!",
                }
              : { text: "Loading", value: <Loader size="sm" /> },
          ]}
        />
      </div>
    );
  }

  return (
    <Card>
      <StatBlocks
        flex
        backgroundColor="var(--mantine-color-gray-0)"
        data={items}
      />
    </Card>
  );
}
