import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  TextInput,
  Loader,
  Group,
  Text,
  ButtonGroup,
  Button,
  Box,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";

import { OrgGalleryLocations } from "./";

const initialFormValues = {
  zip: "",
  name: "",
};

const searchMethods = [
  { text: "Around Me", value: "geo" },
  { text: "By Zip", value: "zip" },
  { text: "By Name", value: "name" },
];

export default function OrgGalleryLocationSelect({
  onLocationSelect,
  reqData,
  orgId,
}) {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [locations, setLocations] = useState([]);
  const [searchMethod, setSearchMethod] = useState("geo");
  const [loading, setLoading] = useState(false);
  const [coords, setCoords] = useState(null);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const showNoLocations =
    !loading &&
    !locations.length &&
    (formValues.name.length >= 2 ||
      formValues.zip.length >= 5 ||
      (searchMethod === "geo" && !coords) ||
      (searchMethod === "geo" && coords && !locations.length));

  useEffect(() => {
    if ([5, 6, 7].includes(formValues.zip.length)) {
      fetchLocations();
    }
  }, [formValues.zip]);

  useEffect(() => {
    if (formValues.name.length >= 2) {
      fetchLocations();
    } else {
      setLocations([]);
    }
  }, [formValues.name]);

  useEffect(() => {
    setFormValues(initialFormValues);
    setLocations([]);

    if (searchMethod === "geo") {
      fetchGeo();
    }
  }, [searchMethod]);

  function fetchLocations() {
    setLoading(true);

    const req = {
      ...reqData,
    };

    if (searchMethod === "zip") {
      req.zipcode = formValues.zip;
    } else {
      req.text = formValues.name;
    }

    const url =
      searchMethod === "zip"
        ? `/gallery/location-point-check/`
        : `/gallery/location-text-search/`;

    axios
      .post(url, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLoading(false);
        setLocations(data.response);
      })
      .catch((err) => {
        setLoading(false);
        setLocations([]);
      });
  }

  function fetchGeo() {
    setLoading(true);
    const successCallback = (position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      setCoords({
        latitude: lat,
        longitude: lng,
      });
      fetchLocationsFromGeo({
        latitude: lat,
        longitude: lng,
      });
    };

    const errorCallback = (error) => {
      setLoading(false);
      setCoords(null);
      setSearchMethod("zip");
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: Infinity,
    });
  }

  function fetchLocationsFromGeo(position) {
    const req = {
      organization_id: orgId,
      latitude: position.latitude,
      longitude: position.longitude,
    };

    axios
      .post(`/gallery/location-point-check/`, req)
      .then(({ data }) => {
        setLocations(data.response);
        setLoading(false);
        if (data.response.length === 0) {
          setSearchMethod("zip");
        }
      })
      .catch((err) => {
        setLocations([]);
        setLoading(false);
      });
  }

  return (
    <div>
      <Group justify="center" mb="md">
        <IconSearch size={24} />
        <Text size="sm" fw={600}>
          Search locations
        </Text>
      </Group>
      <Group mb="lg" justify="center">
        {searchMethods.map((m, i) => (
          <Button
            variant={m.value === searchMethod ? "filled" : "light"}
            size="md"
            key={i}
            radius="xl"
            onClick={() => setSearchMethod(m.value)}
          >
            {m.text}
          </Button>
        ))}
      </Group>
      {searchMethod === "zip" && (
        <TextInput
          size="lg"
          value={formValues.zip}
          placeholder="Zip Code"
          onChange={(e) =>
            setFormValues({
              ...formValues,
              zip: e.target.value,
            })
          }
        />
      )}
      {searchMethod === "name" && (
        <TextInput
          size="lg"
          value={formValues.name}
          placeholder="Name"
          onChange={(e) =>
            setFormValues({
              ...formValues,
              name: e.target.value,
            })
          }
        />
      )}
      {loading && (
        <Group justify="center" mt="xl">
          <Loader />
          <Text size="lg" fw={600}>
            Locating...
          </Text>
        </Group>
      )}
      {showNoLocations && (
        <Text align="center" fw={600} mt="xl" size="xl">
          We found no locations
        </Text>
      )}
      <Box mt="lg">
        <OrgGalleryLocations
          locations={locations}
          onLocationSelect={onLocationSelect}
        />
      </Box>
    </div>
  );
}
