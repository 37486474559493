import React from "react";

import { CurationList } from "@components/Curation";

export default function CurationIndex() {
  return (
    <div>
      <CurationList />
    </div>
  );
}
