import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Button, Grid, Title, TextInput } from "@mantine/core";
import { EmptyMessage } from "@components/shared";

import { GeospotList } from "./";

export default function GeospotAssignment({ effortId, locationId }) {
  const [items, setItems] = useState([]);
  const [locationGeospots, setLocationGeospots] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [availableValue, setAvailableValue] = useState("");

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const CancelToken2 = axios.CancelToken;
  const cancelRef2 = useRef(null);

  useEffect(() => {
    fetchAssignments();
  }, [searchValue]);

  useEffect(() => {
    fetchLocationGeospots();
  }, [availableValue]);

  function fetchAssignments() {
    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    axios
      .post(
        `/efforts/${effortId}/retrieve-geo-spots/`,
        {
          campaign_effort_id: effortId,
          search_value: searchValue,
        },
        {
          cancelToken: new CancelToken(function executor(c) {
            cancelRef.current = c;
          }),
        }
      )
      .then(({ data }) => {
        setItems(data.response);
      })
      .catch((err) => {
        setItems([]);
      });
  }

  function fetchLocationGeospots() {
    const req = {
      campaign_effort_id: effortId,
      location_id: locationId,
      search_value: availableValue,
    };

    const cancel = cancelRef2.current;
    if (cancel) {
      cancel();
    }

    axios
      .post(`/retrieve-geo-spots/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef2.current = c;
        }),
      })
      .then(({ data }) => {
        setLocationGeospots(
          data.response.sort((a, b) => (a.name > b.name ? 1 : -1))
        );
      })
      .catch((err) => {
        setItems([]);
      });
  }

  function onAdd(geospotId) {
    const req = {
      geo_spot_id: geospotId,
      effort_id: effortId,
    };

    axios
      .post(`/efforts/${effortId}/add-geo-spots/`, req)
      .then(() => {
        fetchAssignments();
        fetchLocationGeospots();
        toast.success("Added!");
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onRemove(joinId) {
    const req = {
      join_id: joinId,
    };

    axios
      .post(`/efforts/${effortId}/remove-geo-spots/`, req)
      .then(() => {
        fetchAssignments();
        fetchLocationGeospots();
        toast.success("Removed!");
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  const addedIds = items.map((item) => item.geo_spot_id);
  const filteredAvailable = locationGeospots.filter(
    (f) => !addedIds.includes(f.id)
  );

  return (
    <Grid>
      <Grid.Col span={{ base: 12, md: 6 }}>
        <Title order={4} mb="xs">
          Added
        </Title>
        <TextInput
          placeholder="Search added geospots..."
          size="md"
          mb="md"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {items.length === 0 && <EmptyMessage title="No added Geo-Spots yet" />}
        <GeospotList
          items={items.map((m) => ({
            ...m,
            id: m.join_id,
            children: (
              <Button
                variant="light"
                size="xs"
                color="red"
                onClick={() => onRemove(m.join_id)}
              >
                remove
              </Button>
            ),
          }))}
          editable={false}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6 }}>
        <Title order={4} mb="xs">
          Available
        </Title>
        <TextInput
          placeholder="Search available geospots..."
          size="md"
          mb="md"
          value={availableValue}
          onChange={(e) => setAvailableValue(e.target.value)}
        />
        {filteredAvailable.length === 0 && (
          <EmptyMessage title="No more Geo-Spots to add" />
        )}
        <GeospotList
          items={filteredAvailable.map((m) => ({
            ...m,
            children: (
              <Button
                size="xs"
                variant="light"
                color="green"
                onClick={() => onAdd(m.id)}
              >
                add
              </Button>
            ),
          }))}
          fetchData={fetchLocationGeospots}
          editable={false}
        />
      </Grid.Col>
      {/* {filteredAvailable.map((m) => (
        // <button key={m.id} onClick={() => onAdd(m.id)}>
        //   {m.name}
        // </button>
      ))} */}
    </Grid>
  );
}
