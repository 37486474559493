import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Badge, Space } from "@mantine/core";

import { getUuid } from "@util/getUuid";
import { AssetManagement, AssetLibraryPicker } from "@components/Asset";
import {
  addFileToConfig,
  formatPlacements,
  renameFileInConfig,
  removeFileInConfig,
} from "@components/Asset/helpers";

import { placementDefs } from "@components/Asset/placementDefs";

const placements = [
  { text: "Featured", value: placementDefs.featured, limit: 1 },
].map((m) => ({
  ...m,
  key: getUuid(),
}));

export default function ContestAssets({
  contestId,
  fetchContest,
  uploadOnly = false,
}) {
  const [jsonData, setJsonData] = useState(null);
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/contests/${contestId}/configuration/`)
      .then(({ data }) => {
        const config = data.response[0];
        if (config !== "{}") {
          setJsonData(config);
        }
      })
      .then(() => fetchAssets())
      .catch((err) => {});
  }

  function fetchAssets() {
    axios
      .post(`/retrieve-assets/`, { contest_id: contestId })
      .then(({ data }) => {
        setAssets(
          data.response[0].data.map((d) => ({
            ...d,
            ...d.asset,
          }))
        );
      })
      .catch((err) => {
        setAssets([]);
      });
  }

  function updateConfig(newConfig) {
    const req = {
      configuration: { ...jsonData, ...newConfig },
    };

    axios
      .put(`/contests/${contestId}/configuration/`, req)
      .then(() => {
        fetchData();
        fetchContest();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onUploadSuccess(newId, placementValue) {
    updateConfig(addFileToConfig(jsonData, newId, placementValue));
  }

  function onRemoveSuccess(removedId) {
    updateConfig(removeFileInConfig(jsonData, removedId));
  }

  function onRenameSuccess(assetId, newName) {
    updateConfig(renameFileInConfig(jsonData, { id: assetId, name: newName }));
  }

  function onLibrarySelection(assetId, placementValue) {
    const req = { contest_id: contestId, asset_id: assetId, variety: 5 };

    axios
      .post(`/replicate-library-asset/`, req)
      .then(({ data }) => {
        onUploadSuccess(data.response[0].id, placementValue);
      })
      .catch((err) => {
        fetchAssets();
        toast.error(err);
      });
  }

  const formattedPlacements = formatPlacements(placements, jsonData, assets);

  return (
    <React.Fragment>
      {formattedPlacements.map((p, i) => (
        <div key={p.value}>
          <AssetManagement
            library={false}
            contestId={contestId}
            items={p.assets}
            title={
              <React.Fragment>
                {p.text}{" "}
                {p.limit && (
                  <Badge variant="light" color="yellow">
                    Limit of {p.limit}
                  </Badge>
                )}
              </React.Fragment>
            }
            key={p.value}
            limit={p.limit}
            onRenameSuccess={onRenameSuccess}
            onRemoveSuccess={onRemoveSuccess}
            onUploadSuccess={(newId) => onUploadSuccess(newId, p.value)}
            shouldFetch={false}
            uploadOnly={uploadOnly}
          >
            {p.assets.length < p.limit && (
              <AssetLibraryPicker
                onClose={fetchAssets}
                onSelect={(assetId) => onLibrarySelection(assetId, p.value)}
                reqData={{
                  variety: [1, 4],
                  contest_id: contestId,
                }}
              />
            )}
          </AssetManagement>
          {!uploadOnly && i !== formatPlacements.length - 1 && (
            <Space mb="xl" />
          )}
        </div>
      ))}
    </React.Fragment>
  );
}
