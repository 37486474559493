import React from "react";
import { Box, Flex, Grid, Text, Title } from "@mantine/core";

export default function StatBlocks({
  data = [],
  backgroundColor = null,
  flex,
}) {
  const Block = ({ value, text }) => (
    <Box
      style={{
        backgroundColor: backgroundColor
          ? backgroundColor
          : "var(--mantine-color-gray-1)",
        padding: "var(--mantine-spacing-xs)",
        borderRadius: "var(--mantine-radius-md)",
        border: "1px solid var(--mantine-color-gray-2)",
      }}
    >
      <Title order={1}>{value}</Title>
      <Text size="xs">{text}</Text>
    </Box>
  );

  if (flex) {
    return (
      <Flex gap="xs" wrap="wrap">
        {data.map((d, i) => (
          <div key={i}>
            <Block value={d.value} text={d.text} />
          </div>
        ))}
      </Flex>
    );
  }

  return (
    <Grid>
      {data.map((d, i) => (
        <Grid.Col span={{ base: 12, md: d.span }} key={i}>
          <Block value={d.value} text={d.text} />
        </Grid.Col>
      ))}
    </Grid>
  );
}
