import React from "react";
import { useTranslation } from "react-i18next";

import ItemSlider from "./ItemSlider";

import { getPlacementImage } from "@components/Asset/helpers";
import { placementDefs } from "@components/Asset/placementDefs";

const EffortSlider = ({
  colors = {
    card: "#000",
  },
  efforts = [],
}) => {
  if (!efforts.length) return null;

  const { t } = useTranslation();

  return (
    <ItemSlider
      data={efforts.map((effort) => ({
        ...effort,
        backgroundColor: colors.card,
        image:
          effort.image ||
          getPlacementImage(
            effort.draft_configuration,
            effort.assets,
            placementDefs.featured
          ),
        id: effort.id,
        category: t("effort_card_subtitle"),
        title: effort.title,
      }))}
    />
  );
};

export default EffortSlider;
