import React from "react";
import { Text, ThemeIcon, Group } from "@mantine/core";

export default function PhoneValidationNameplate({ data, title }) {
  return (
    <Group>
      <div>
        <Text mb="sm">{title}</Text>
        {data.carrier && (
          <React.Fragment>
            {data.carrier.name && (
              <Text>
                {data.carrier.name} ({data.carrier.type})
              </Text>
            )}
            {data.carrier.error_message && (
              <Text c="red">
                {data.carrier.error_message.code}:{" "}
                {data.carrier.error_message.message}
              </Text>
            )}
          </React.Fragment>
        )}
        {data.caller_name && (
          <React.Fragment>
            <Text>{data.caller_name.caller_name}</Text>
          </React.Fragment>
        )}
      </div>
    </Group>
  );
}
