import React, { useState } from "react";
import { Card, Title, TextInput, Button } from "@mantine/core";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function SongQueryForm({
  locationId,
  variety,
  varietyName,
  onSuccess,
}) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  function onSubmit() {
    setLoading(true);

    const req = {
      location_id: locationId,
      name: value,
      variety,
    };

    axios
      .post(`/song-queries/`, req)
      .then(() => {
        toast.success("Created!");
        setLoading(false);
        setValue("");
        // navigate(`/song-categories/${data.response[0].id}`);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <>
      <Title order={4} mb="sm">
        Create {varietyName}
      </Title>
      <Card>
        <TextInput value={value} onChange={(e) => setValue(e.target.value)} />
        <Button
          loading={loading}
          onClick={onSubmit}
          disabled={!value}
          fullWidth
          mt="sm"
        >
          Create
        </Button>
      </Card>
    </>
  );
}
