import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Select, Card, Title } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { CampaignForm, CampaignList } from "./";
import { ManagementLayout, AssignCreateToggle } from "@components/shared";

export default function CampaignManagement({
  organizationId,
  locationId = null,
}) {
  const [campaignId, setCampaignId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(organizationId ? true : false);
  const [success, setSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const isManager = useSelector((state) => state.manager) ? true : false;
  const managerInfo = useSelector((state) => state.manager);

  useEffect(() => {
    if (isManager) {
      setCreating(true);
    }
  }, [isManager]);

  const reqInfo = {
    refresh,
  };

  if (organizationId) reqInfo.organization_id = organizationId;
  if (locationId) reqInfo.location_id = locationId;

  function onAssignSubmit() {
    setLoading(true);

    const req = {
      campaign_id: campaignId,
    };

    if (organizationId) req.organization_id = organizationId;
    if (locationId) req.location_id = locationId;

    axios
      .post(`/campaigns/${campaignId}/add-participant/`, req)
      .then(() => {
        setRefresh(!refresh);
        setLoading(false);
        setCampaignId(null);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  if (managerInfo && !managerInfo.allow_campaign_create) {
    return (
      <CampaignList
        isManager={isManager}
        refresh={refresh}
        hiddenFilters={organizationId ? ["owned_by"] : []}
        requestInfo={{
          url: `/retrieve-campaigns/`,
          data: reqInfo,
        }}
      />
    );
  }

  return (
    <ManagementLayout
      dataComponent={
        <div>
          <CampaignList
            isManager={isManager}
            refresh={refresh}
            hiddenFilters={organizationId ? ["owned_by"] : []}
            requestInfo={{
              url: `/retrieve-campaigns/`,
              data: reqInfo,
            }}
          />
        </div>
      }
      createComponent={
        <Card
          style={{
            overflow: "visible",
          }}
        >
          {(isManager || organizationId) && (
            <Title order={4}>Create Campaign</Title>
          )}
          {!organizationId && !isManager && (
            <AssignCreateToggle
              active={creating}
              buttons={[
                { value: false, text: "Assign Campaign " },
                { value: true, text: "Create Campaign " },
              ]}
              onClick={(e) => setCreating(e)}
            />
          )}
          {!creating && !isManager && !organizationId && (
            <React.Fragment>
              <CampaignSelect
                onChange={(e) => setCampaignId(e)}
                value={campaignId}
                disabledIds={[]}
                reqData={{
                  location_id: locationId,
                }}
              />
              <Button
                disabled={!campaignId || loading}
                onClick={onAssignSubmit}
                loading={loading}
                mt="lg"
                fullWidth
              >
                Add
              </Button>
            </React.Fragment>
          )}
          {creating && !success && (
            <React.Fragment>
              <CampaignForm
                locationId={locationId}
                organizationId={organizationId}
                onSuccess={() => {
                  setRefresh(!refresh);
                  setSuccess(!success);
                  setTimeout(() => {
                    setSuccess(false);
                  }, 1);
                }}
              />
            </React.Fragment>
          )}
        </Card>
      }
    />
  );
}

const CampaignSelect = ({ onChange, disabledIds, reqData = {}, value }) => {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .post(`/retrieve-campaigns/`, {
        ...reqData,
        select: true,
        pagination: false,
      })
      .then(({ data }) => {
        setCampaigns(
          data.response.map((m) => ({
            label: m.title,
            value: m.id,
          }))
        );
      })
      .catch((err) => {
        setCampaigns([]);
      });
  }

  const formattedOptions = campaigns.map((m) => ({
    ...m,
    disabled: disabledIds.includes(m.value),
  }));

  return (
    <Select
      searchable
      label="Select a campaign"
      data={formattedOptions}
      placeholder="Start typing..."
      onChange={(e) => onChange(e)}
      value={value}
    />
  );
};
