import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Alert, Card, Text, Button } from "@mantine/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { IconInfoCircle } from "@tabler/icons-react";
import { useSelector } from "react-redux";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";
import { ErrorView, PageTitle } from "@components/shared";
import { ReportRecipeRunner, ReportRecipeEdit } from "@components/ReportRecipe";

export default function ReportRecipeDetail() {
  const queryClient = useQueryClient();
  const params = useParams();
  const { id } = params;
  const [searchParams] = useSearchParams();
  const userParams = {
    savedRecipeId: searchParams.get("savedReportRecipeId") || "",
  };
  const isAdmin = useSelector((state) => state.admin) ? true : false;
  const [editing, setEditing] = useState(false);

  const { failureReason: error, data: info } = useQuery({
    queryKey: [`reportRecipeDetail`, id, userParams],
    queryFn: async () =>
      axios
        .get(`/report-recipes/${id}/`)
        .then(({ data }) => {
          return data.response[0];
        })
        .catch((err) => {
          throw err;
        }),
  });

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  return (
    <div>
      <PageTitle title={info.title} iconColor={entityColor.recipe} />
      {isAdmin && (
        <Button
          variant="subtle"
          color="gray"
          mb="lg"
          onClick={() => setEditing(!editing)}
          p={0}
        >
          Edit recipe
        </Button>
      )}
      {editing ? (
        <Card>
          <ReportRecipeEdit
            fetchData={() => {
              setEditing(false);
              queryClient.invalidateQueries([
                `reportRecipeDetail`,
                id,
                userParams,
              ]);
            }}
            id={id}
            data={info}
          />
        </Card>
      ) : (
        <>
          {info.description && (
            <Alert
              icon={<IconInfoCircle />}
              mb="lg"
              title="What does this report do?"
            >
              <Text>{info.description}</Text>
            </Alert>
          )}
          <ReportRecipeRunner info={info} id={id} />
        </>
      )}
    </div>
  );
}
