import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Avatar, Box, Group, Card, Tabs, Space } from "@mantine/core";

import { PageTitle, ErrorView } from "@components/shared";
import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";
import { StatBlocks } from "@components/DataViz";
import { getUuid } from "@util/getUuid";
import {
  UserInteractionEffortSelect,
  UserInteractionEffort,
  UserInteractionEdit,
} from "@components/UserInteraction";
import { UserLockManagement } from "@components/User";
import { PointLedger } from "@components/Point";

import { locationVariety } from "@components/Location/helpers";

export const tabs = [
  { title: "Rollup", value: "rollup", icon: entityIcon.user() },
  { title: "Efforts", value: "efforts", icon: entityIcon.effort() },
  { title: "Edit", value: "edit" },
  { title: "Points", value: "points" },
].map((m) => ({
  ...m,
  key: getUuid(),
}));

export default function UserDetail() {
  const [info, setInfo] = useState(null);
  const [effortId, setEffortId] = useState(null);
  const [joinMetaData, setJoinMetaData] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();
  const managerInfo = useSelector((state) => state.manager);

  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/manager-user/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
        setJoinMetaData([
          {
            value: data.response[0].interaction_count,
            text: "Total Interactions",
          },
          {
            value: data.response[0].entry_count,
            text: "Contest Entries",
          },
          {
            value: data.response[0].check_in_count,
            text: "Total Check-Ins",
          },
          {
            value: data.response[0].keyword_count,
            text: "Total Keywords",
          },
          {
            value: data.response[0].contest_won_count,
            text: "Contests Won",
          },
        ]);
      })
      .catch((err) => {
        setError(err);
        setInfo([]);
        setJoinMetaData([]);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  const location = info.location_name ? true : false;
  const organization = info.organization_name ? true : false;

  return (
    <div>
      <Group>
        <Avatar src={info.avatar_url} radius="100%" size="xl" />
        <PageTitle
          title={info.full_name || "Unidentified"}
          subtitle={"User"}
          icon={entityIcon.user()}
          iconColor={entityColor.user}
        />
      </Group>
      {info.last_interacted && (
        <PageTitle
          style={{ margin: ".5em 0" }}
          subtitle={new Date(info.last_interacted).toLocaleString()}
          icon={entityIcon.calendar()}
        />
      )}
      {info.user_lock && (
        <Box mt="xl">
          <UserLockManagement fetchData={fetchData} lockData={info.user_lock} />
        </Box>
      )}
      <Tabs defaultValue="edit" mt="lg">
        <Tabs.List mb="lg">
          {tabs
            .filter((f) =>
              managerInfo.location_variety !== locationVariety.station
                ? f.title !== "Points"
                : true
            )
            .map((tab) => (
              <Tabs.Tab key={tab.key} value={tab.value} icon={tab.icon}>
                {tab.title}
              </Tabs.Tab>
            ))}
        </Tabs.List>
        <Tabs.Panel value="rollup">
          <StatBlocks data={joinMetaData} span={3} />
        </Tabs.Panel>
        <Tabs.Panel value="points">
          <Card position="relative">
            <PointLedger
              userLocationId={location ? id : null}
              userOrgId={organization ? id : null}
              actionable
            />
          </Card>
        </Tabs.Panel>
        <Tabs.Panel value="efforts">
          <UserInteractionEffortSelect
            id={id}
            onChange={(e) => setEffortId(e)}
            value={effortId}
            location={location}
            organization={organization}
          />
          <Space mt="xs" />
          <UserInteractionEffort
            joinId={id}
            location={location}
            effortId={effortId}
          />
        </Tabs.Panel>
        <Tabs.Panel value="edit">
          <UserInteractionEdit
            joinId={params.id}
            fetchData={fetchData}
            isManager
            rawData={{
              ...info,
              is_location: location ? true : false,
              is_org: organization ? true : false,
            }}
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}
