import React from "react";
import { useSelector } from "react-redux";
import { Card } from "@mantine/core";

import { Header, ManagementLayout } from "@components/shared";
import {
  BulkCommunicationList,
  BulkCommunicationForm,
} from "@components/BulkCommunication";

export default function BulkCommunicationManagement({ locationId, orgId }) {
  const managerInfo = useSelector((state) => state.manager);

  // admin index view
  if (!managerInfo && !locationId && !orgId) return <BulkCommunicationList />;

  return (
    <div>
      <ManagementLayout
        dataComponent={
          <>
            <BulkCommunicationList locationId={locationId} orgId={orgId} />
          </>
        }
        createComponent={
          <Card>
            <Header>Create Bulk Communication</Header>
            <BulkCommunicationForm locationId={locationId} orgId={orgId} />
          </Card>
        }
      />
    </div>
  );
}
