import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Anchor,
  Badge,
  Table,
  Text,
  Button,
  Box,
  Title,
  Card,
  Loader,
  Flex,
  Modal,
  Divider,
} from "@mantine/core";
import toast from "react-hot-toast";

import { AuctionNewWinner } from "./";
import { ErrorView } from "@components/shared";

export default function AuctionBidManagement({ contestId, fetchData }) {
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBids();
  }, []);

  function fetchBids() {
    setLoading(true);

    axios
      .get(`/contests/${contestId}/retrieve-bid-history/`)
      .then(({ data }) => {
        setLoading(false);
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  const winningBid = info.is_concluded
    ? info.bid_history.find((f) => f.is_winner && f.is_eligible)
    : null;

  const bids = info.bid_history;

  return (
    <div>
      <Card>
        <Flex gap="sm" align="center">
          <Title order={4}>
            {info.is_concluded ? "Concluded" : "In progress"}
          </Title>
          {loading && <Loader size="xs" />}
        </Flex>
        {winningBid && (
          <Box mt="xs">
            <Winner
              bid={winningBid}
              fetchData={fetchBids}
              contestId={contestId}
            />
          </Box>
        )}
      </Card>
      <Card mt="md">
        <Title order={4}>Bid History</Title>
        <BidderTable data={bids} />
      </Card>
    </div>
  );
}

const BidderTable = ({ data }) => {
  return (
    <Table striped>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Date</Table.Th>
          <Table.Th>Name</Table.Th>
          <Table.Th>Email</Table.Th>
          <Table.Th>Phone</Table.Th>
          <Table.Th>Bid</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {data
          .sort((a, b) => b.bid_value - a.bid_value)
          .map((bid, i) => (
            <BidItem bid={bid} key={bid.id} />
          ))}
      </Table.Tbody>
    </Table>
  );
};

const Winner = ({ bid, fetchData, contestId }) => {
  const [notifyOpen, setNotifyOpen] = useState(false);

  return (
    <React.Fragment>
      <Flex align="center" gap="xs">
        <Title order={4}>
          <Anchor
            fw={600}
            target="_blank"
            href={
              bid.user_data.location_id
                ? `/user-locations/${bid.user_data.id}`
                : `/user-organizations/${bid.user_data.id}`
            }
          >
            {bid.user_data.first_name} {bid.user_data.last_name}
          </Anchor>{" "}
          won with a bid of {bid.bid_value}!
        </Title>
        {bid.notification_sent && (
          <Badge size="xs" color="green" variant="light">
            notified
          </Badge>
        )}
      </Flex>
      <Text>bid placed {new Date(bid.created_at).toLocaleString()}</Text>
      <Text>{bid.user_data.email}</Text>
      <Text>{bid.user_data.mobile_phone}</Text>
      <Flex gap="sm">
        <Button
          variant="light"
          mt="sm"
          size="xs"
          onClick={() => setNotifyOpen(true)}
        >
          Send winner email
        </Button>
        <AuctionNewWinner
          fetchData={fetchData}
          contestId={contestId}
          reqData={{
            contest_id: contestId,
            contest_entry_id: bid.id,
          }}
        />
      </Flex>
      <NotifyModal
        email={bid.user_data.email}
        fetchData={fetchData}
        resending={bid.notification_sent}
        toggleOpen={() => setNotifyOpen(!notifyOpen)}
        isOpen={notifyOpen}
        contestId={contestId}
        reqData={{
          contest_id: contestId,
          contest_entry_id: bid.id,
        }}
      />
    </React.Fragment>
  );
};

const BidItem = ({ bid }) => {
  return (
    <React.Fragment>
      <Table.Tr key={bid.id}>
        <Table.Td>{new Date(bid.created_at).toLocaleString()}</Table.Td>
        <Table.Td>
          <Anchor
            fw={600}
            c="dark"
            target="_blank"
            href={
              bid.user_data.location_id
                ? `/user-locations/${bid.user_data.id}`
                : `/user-organizations/${bid.user_data.id}`
            }
          >
            {bid.user_data.first_name} {bid.user_data.last_name}
          </Anchor>
        </Table.Td>
        <Table.Td>
          <Flex align="center" gap="xs">
            <span>{bid.user_data.email}</span>
            {bid.is_winner && bid.is_eligible && (
              <Badge size="xs" color="green" variant="light">
                winner
              </Badge>
            )}
            {bid.is_winner && !bid.is_eligible && (
              <Badge size="xs" color="red" variant="light">
                declined
              </Badge>
            )}
          </Flex>
        </Table.Td>
        <Table.Td>{bid.user_data.mobile_phone}</Table.Td>
        <Table.Td>{bid.bid_value}</Table.Td>
      </Table.Tr>
    </React.Fragment>
  );
};

const NotifyModal = ({
  resending,
  contestId,
  toggleOpen,
  email,
  isOpen,
  reqData,
}) => {
  const [loading, setLoading] = useState(false);

  function onClose() {
    setLoading(false);
    toggleOpen();
  }

  function onClick() {
    setLoading(true);

    const req = { ...reqData };

    axios
      .post(`/contests/${contestId}/notify-winner/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Email sent!");
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Modal opened={isOpen} onClose={onClose}>
      <Text mb="lg">
        {resending ? "Resend" : "Send"} a winner notification email to{" "}
        <b>{email}</b>
      </Text>
      <Button fullWidth loading={loading} onClick={onClick}>
        Send Now
      </Button>
    </Modal>
  );
};
