import React, { useState } from "react";
import { Text, Box } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";

import { CustomLink } from "@components/shared/Nav";

import classes from "./NavGroup.module.css";

const NavGroup = ({ title, links }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Box
      style={{
        marginBottom: "0.5rem",
        paddingLeft: "0.75rem",
      }}
    >
      <button
        className={classes.header}
        type="button"
        onClick={() => setCollapsed(!collapsed)}
      >
        <IconChevronDown
          className={!collapsed ? classes.icon : classes.iconCollapsed}
        />
        <Text className={classes.title} fw={700} transform="uppercase">
          {title}
        </Text>
      </button>
      {!collapsed && (
        <Box
          style={{
            marginLeft: "0.5rem",
            marginTop: "0.25rem",
          }}
        >
          {links.map((l, i) => (
            <CustomLink
              key={i}
              to={l.to}
              icon={l.icon}
              color={l.color}
              text={l.text}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default NavGroup;
