import React from "react";
import { ColorSwatch, Group, useMantineTheme, CheckIcon } from "@mantine/core";

export default function EntityConfigBaseColor({ value, onChange }) {
  const theme = useMantineTheme();
  const swatches = Object.keys(theme.colors).map((color) => (
    <ColorSwatch
      key={color}
      color={theme.colors[color][6]}
      component="button"
      onClick={() => onChange(color)}
    >
      {value === color && <CheckIcon width={10} color="#fff" />}
    </ColorSwatch>
  ));

  return <Group spacing="xs">{swatches}</Group>;
}
