import React, { useState, useMemo, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Anchor,
  Card,
  Center,
  Group,
  LoadingOverlay,
  Pagination,
  Space,
  Table,
  Text,
  UnstyledButton,
} from "@mantine/core";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
} from "@tabler/icons-react";

import SongReportCsvButton from "./SongReportCsvButton";

export default function SongReportTable({ reqData }) {
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [sorting, setSorting] = useState([]);
  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const columns = useMemo(
    () => [
      { header: "Artist", accessorKey: "artist_name", enableSorting: false },
      {
        header: "Title",
        accessorKey: "song_name",
        enableSorting: false,
        id: "songName",
        cell: ({ row }) => {
          return (
            <CellLink
              url={`/location-songs/${row.original.location_song_id}`}
              text={row.original.song_name}
            />
          );
        },
      },
      {
        header: "Category",
        accessorKey: "in_rotation_category.name",
        enableSorting: false,
        id: "inRotatingCategory",
        cell: ({ row }) => {
          if (!row.original.in_rotation_category) return "";
          return (
            <CellLink
              url={`/song-categories/${row.original.in_rotation_category.id}`}
              text={row.original.in_rotation_category.name}
            />
          );
        },
      },
      { header: "Score", id: "score_historic", accessorKey: "score_historic" },
      { header: "Burn", accessorKey: "burn_historic" },
      { header: "1", accessorKey: "one_rating_historic" },
      { header: "2", accessorKey: "two_rating_historic" },
      { header: "3", accessorKey: "three_rating_historic" },
      { header: "4", accessorKey: "four_rating_historic" },
      { header: "5", accessorKey: "five_rating_historic" },
      { header: "Sample", accessorKey: "count_historic" },
      {
        header: "Sound",
        accessorKey: "in_sound_code.name",
        enableSorting: false,
        id: "inSoundCode",
        cell: ({ row }) => {
          if (!row.original.in_sound_code) return "";
          return (
            <CellLink
              url={`/song-categories/${row.original.in_sound_code.id}`}
              text={row.original.in_sound_code.name}
            />
          );
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data: items,
    columns,
    manualPagination: true,
    state: {
      sorting,
    },
    onSortingChange: (e) => {
      setSorting(e);
    },
    enableMultiSort: false,
    manualSorting: true,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(reqData),
    JSON.stringify(table.getState().pagination),
    JSON.stringify(table.getState().sorting),
  ]);

  const tableDataReq = {
    ...reqData,
    pagination: true,
    sorting,
    page_size: table.getState().pagination.pageSize,
    page:
      table.getState().pagination.pageIndex === -1
        ? 0
        : table.getState().pagination.pageIndex,
  };

  function fetchData() {
    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    axios
      .post(`/song-reports/`, tableDataReq, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setItems(data.response[0].data);
        setPageCount(data.response[0].page_count);
        if (data.response[0].total !== undefined) {
          setTotal(data.response[0].total);
        } else {
          setTotal(data.response[0].data.length);
        }
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setItems([]);
        setLoading(false);
      });
  }

  return (
    <>
      <Group align="flex-end" justify="flex-end">
        <SongReportCsvButton reqData={tableDataReq} />
      </Group>
      <Space mb="xs" />
      <Card>
        <LoadingOverlay visible={loading} zIndex={1000}></LoadingOverlay>
        <Table.ScrollContainer type="native" minWidth={1200}>
          <Table striped highlightOnHover>
            <Table.Thead>
              <Table.Tr>
                {table.getFlatHeaders().map((header) => (
                  <Table.Th key={header.id}>
                    <UnstyledButton
                      onClick={(e) => {
                        if (header.column.getCanSort()) {
                          header.column.toggleSorting();
                        }
                      }}
                      style={{
                        cursor: header.column.getCanSort()
                          ? "pointer"
                          : "initial",
                      }}
                    >
                      <Group>
                        <Text fw={600} fz="xs">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Text>
                        {header.column.getCanSort() && (
                          <Center>
                            {{
                              asc: <IconChevronUp size={14} />,
                              desc: <IconChevronDown size={14} />,
                            }[header.column.getIsSorted()] ?? (
                              <IconSelector size={14} />
                            )}
                          </Center>
                        )}
                      </Group>
                    </UnstyledButton>
                  </Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Table.Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Table.Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Td>
                      );
                    })}
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
        <Pagination
          mt="sm"
          total={pageCount}
          onChange={(e) => table.setPageIndex(e - 1)}
          disabled={loading}
        />
      </Card>
    </>
  );
}

const CellLink = ({ url, text }) => {
  return (
    <Text size="sm" component={Link} to={url} target="_blank">
      {text}
    </Text>
  );
};
