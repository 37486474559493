import { create } from "zustand";

import qbPopulateQueryComponents from "./qb-populate-query-components";

const initialQueryData = {
  // efforts: [],
  // effortStatus: [],
  // end_date: "",
  // locations: [],
  // organizations: [],
  // start_date: "",
  visualizer: null,
};

const initialState = {
  active_tab: "form",
  error: null,
  orgs: [],
  locations: [],
  loading: false,
  query_data: initialQueryData,
  initial_query_data: {},
  query_components: [],
  manager_query_entity: "location",
  recipe_info: null,
  report_data: null,
  report_data_query: null,
  saved_recipe: null,
  snapshot: null,
  stat_blocks: [],
  table_config: {
    data: [],
    columns: [],
  },
};

const useAppStore = create((set) => ({
  ...initialState,
  reset: async (full = false) => {
    full
      ? set((state) => ({
          ...initialState,
          active_tab: state.active_tab,
        }))
      : set((state) => ({
          ...initialState,
          active_tab: state.active_tab,
          initial_query_data: state.initial_query_data,
          query_components: state.query_components,
          query_data: {
            // ...initialQueryData,
            ...state.initial_query_data,
            // locations: [],
            // organizations: [],
            visualizer:
              state.recipe_info.visualizer_options.length === 1
                ? state.recipe_info.visualizer_options[0].value
                : initialQueryData.visualizer,
          },
          recipe_info: state.recipe_info,
        }));
  },
  setActiveTab: (activeTab) => set(() => ({ active_tab: activeTab })),
  setError: (error) => set(() => ({ error })),
  setManagerQueryEntity: (entity, init = false) =>
    !init
      ? set((state) => ({
          ...initialState,
          query_components: state.query_components,
          initial_query_data: state.initial_query_data,
          query_data: {
            ...state.initial_query_data,
            visualizer:
              state.recipe_info.visualizer_options.length === 1
                ? state.recipe_info.visualizer_options[0].value
                : initialQueryData.visualizer,
          },
          recipe_info: state.recipe_info,
          manager_query_entity: entity,
        }))
      : set(() => ({
          manager_query_entity: entity,
        })),
  setLoading: (loading) => set(() => ({ loading })),
  setLocations: (locations) =>
    set((state) => ({ locations, query_data: state.initial_query_data })),
  setOrgs: (orgs) =>
    set((state) => ({ orgs, query_data: state.initial_query_data })),
  setQueryComponents: (queryComponents) =>
    set(() => ({ query_components: queryComponents })),
  setQueryData: (queryData) =>
    set((state) => ({
      query_data: {
        ...queryData,
      },
      query_components: qbPopulateQueryComponents(
        queryData,
        state.query_components
      ),
    })),
  setInitialQueryData: (queryData) =>
    set((state) => ({
      initial_query_data: {
        ...queryData,
      },
      query_components: qbPopulateQueryComponents(
        queryData,
        state.query_components
      ),
    })),
  setRecipeInfo: (recipeInfo) => set(() => ({ recipe_info: recipeInfo })),
  setReportData: (reportData) => set(() => ({ report_data: reportData })),
  setReportDataQuery: (reportDataQuery) =>
    set(() => ({ report_data_query: reportDataQuery })),
  setSavedRecipe: (savedRecipe) =>
    set(() => ({ saved_recipe: savedRecipe, report_data: null })),
  setSnapshot: (snapshot) => set(() => ({ snapshot })),
  setStatBlocks: (statBlocks) => set(() => ({ stat_blocks: statBlocks })),
  setTableConfig: (tableConfig) => set(() => ({ table_config: tableConfig })),
}));

export default useAppStore;
