import React from "react";
import { Input } from "@mantine/core";

export default function DateField({
  hasTooltip = false,
  label = "Date",
  required = false,
  min = "",
  max = "",
  value = "",
  onChange,
  disabled = false,
}) {
  return (
    <Input.Wrapper
      labelProps={{
        labelElement: hasTooltip ? "div" : "label",
        style: {
          display: "flex",
          alignItems: "center",
          gap: "6px",
        },
      }}
      label={label}
      required={required}
    >
      <Input
        type="date"
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        min={min}
        max={max}
      />
    </Input.Wrapper>
  );
}
