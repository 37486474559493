import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingModule from "highcharts/modules/exporting";

exportingModule(Highcharts);

export default function BarChart2({
  pointInterval = "",
  pointIntervalUnit = "",
  pointStart = "",
  seriesData = [],
  subtitle = "",
  title = "",
  yAxisTitle = "",
  xAxisType = "category",
  timezone = "America/New_York",
}) {
  Highcharts.setOptions({
    time: {
      timezone,
    },
  });

  const options = {
    chart: {
      type: "column",
      style: {
        fontFamily: "inherit",
      },
    },
    title: {
      text: title,
      align: "left",
    },

    subtitle: {
      text: subtitle,
      align: "left",
    },
    xAxis: {
      type: xAxisType,
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },
    legend: {
      enabled: seriesData.length > 1,
      layout: "vertical",
      align: "center",
      verticalAlign: "bottom",
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        pointStart: pointStart,
        pointInterval: pointInterval,
        pointIntervalUnit: pointIntervalUnit,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
