import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Image,
  MantineProvider,
  Group,
  Box,
  useMantineTheme,
} from "@mantine/core";

import UserAuth from "./UserAuth";
import { UserLockModal } from "@components/User";

export default function UserLogin() {
  const [codeData, setCodeData] = useState(null);
  const [error, setError] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);

  const { id } = useParams();
  const siteSettings = codeData ? codeData.settings.settings : null;
  const theme = useMantineTheme();

  if (!id) return null;

  axios.interceptors.response.use(function (response) {
    const res = response.data;
    if (res.response_code === 3) {
      setModalInfo(res.response[0]);
      return response;
    } else {
      return response;
    }
  });

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/verify-code/${id}/`)
      .then(({ data }) => {
        setCodeData(data.response[0]);
      })
      .catch((err) => {
        setError(err);
      });
  }

  if (!codeData) return null;

  if (error) {
    return <h1>{error}</h1>;
  }

  if (codeData.variety !== 20) {
    return <h1>not ready yet</h1>;
  }

  const { logo_url: logo } = codeData;

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      defaultColorScheme="dark"
      theme={{
        fontFamily: theme.fontFamily,
        fontFamilyMonospace: theme.fontFamilyMonospace,
        headings: {
          ...theme.headings,
          fontFamily: theme.fontFamily,
        },
        primaryColor: siteSettings ? siteSettings.color : theme.primaryColor,
      }}
    >
      <UserLockModal data={modalInfo} />
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            width: "400px",
            margin: "0 auto",
          }}
        >
          {logo && (
            <Group justify="center" mb="sm">
              <img
                src={logo}
                style={{
                  width: "150px",
                  maxHeight: "200px",
                  objectFit: "contain",
                }}
              />
            </Group>
          )}
          <UserAuth locationId={codeData.location_id} codeId={id} />
        </Box>
      </Box>
    </MantineProvider>
  );
}
