import React, { useState, useEffect } from "react";
import useInterval from "@util/useInterval";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Divider, Title, Grid, Group, Card, Text, Anchor } from "@mantine/core";
import { IconLink, IconMessages } from "@tabler/icons-react";

import { TicketStatus, TicketMessages } from "@components/Ticket";
import { ErrorView, PageTitle, Notes } from "@components/shared";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function TicketDetail() {
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  useInterval(() => {
    fetchData();
  }, 15000);

  function fetchData() {
    axios
      .get(`/trouble-tickets/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  function getStatusColor() {
    if (info.status === 1) {
      return "red";
    }
    if (info.status === 2) {
      return "grape";
    }
    if (info.status === 3) {
      return "teal";
    }
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  const hasDeviceInfo = JSON.stringify(info.device) !== "{}";

  return (
    <React.Fragment>
      <PageTitle
        title={`#${info.ident}`}
        subtitle={
          <Group>
            <Text>Trouble Ticket</Text>
            <Text color={getStatusColor()}>{info.status_formatted}</Text>
          </Group>
        }
        icon={entityIcon.ticket()}
        iconColor={entityColor.ticket}
      />
      <Card>
        <Text size="xl" fw={700}>
          {info.first_name} {info.last_name}
        </Text>
        {info.campaign_effort_name && (
          <Group spacing="xs">
            {entityIcon.effort()}
            <Text size="sm">
              <Anchor
                component={Link}
                to={`/efforts/${info.campaign_effort_id}`}
              >
                {info.campaign_effort_name}
              </Anchor>
            </Text>
          </Group>
        )}
        {info.campaign_name && (
          <Group spacing="xs">
            {entityIcon.campaign()}
            <Text size="sm">
              <Anchor component={Link} to={`/campaigns/${info.campaign_id}`}>
                {info.campaign_name}
              </Anchor>
            </Text>
          </Group>
        )}
        {info.url && (
          <Group spacing="xs">
            <IconLink size={16} />
            <Text size="sm">
              <Anchor href={info.url} target="_blank">
                {info.url}
              </Anchor>
            </Text>
          </Group>
        )}
        <Group spacing="xs">
          {entityIcon.calendar()}
          {""}
          <Text size="sm">{info.created_at_formatted}</Text>
        </Group>
        <Group spacing="xs">
          {entityIcon.email()}
          {""}
          <Text size="sm">{info.email}</Text>
        </Group>
        <Group spacing="xs">
          {entityIcon.phone()}
          {""}
          <Text size="sm">{info.phone}</Text>
        </Group>
        <Text mt="sm">{info.issue_description}</Text>
        {hasDeviceInfo ? (
          <React.Fragment>
            <Divider mt="sm" mb="sm" />
            <DeviceInfo info={info.device} />
            <Divider mt="sm" mb="sm" />
          </React.Fragment>
        ) : (
          <Divider mt="sm" mb="sm" />
        )}
        <Group grow>
          <TicketStatus id={id} status={info.status} fetchData={fetchData} />
          <Text size="xs" c="dimmed" align="right">
            last updated on {info.updated_at_formatted}
          </Text>
        </Group>
      </Card>
      <div>
        <PageTitle
          subtitle="Messages"
          icon={<IconMessages size={16} />}
          style={{ margin: "20px 0 5px 0" }}
        />
        <TicketMessages
          id={id}
          closed={info.status === 3}
          fetchData={() => fetchData()}
          messages={info.messages}
          status={info.status}
        />
      </div>
      <Notes
        additionalCreateData={{
          trouble_ticket_id: id,
        }}
        additionalFetchData={{
          trouble_ticket_id: id,
        }}
      />
    </React.Fragment>
  );
}

const DeviceInfo = ({ info }) => {
  if (!info) return null;

  const { os, client, device } = info;

  return (
    <div>
      <Title order={4}>Device Info</Title>
      {device && (
        <React.Fragment>
          <Text>
            {device.brand} {device.model} {device.type}
          </Text>
        </React.Fragment>
      )}
      {os && (
        <React.Fragment>
          <Text>
            {os.name} {os.version} {os.platform}
          </Text>
        </React.Fragment>
      )}
      {client && (
        <React.Fragment>
          <Text>
            {client.name} v{client.version} ({client.type})
          </Text>
        </React.Fragment>
      )}
    </div>
  );
};
