import React from "react";
import { Tooltip, Flex } from "@mantine/core";
import { IconHelp } from "@tabler/icons-react";

export default function CustomTooltip({
  label = "",
  children,
  color = "black",
}) {
  return (
    <Flex align="center" gap="xs">
      {children}
      <Tooltip
        label={label}
        color={color}
        multiline
        w={300}
        withArrow
        arrowSize={8}
      >
        <IconHelp size={18} color="var(--mantine-color-blue-5)" />
      </Tooltip>
    </Flex>
  );
}
