import React, { useState } from "react";
import { Card, Group, Tabs, Text, Title, Space } from "@mantine/core";
import { IconReportSearch } from "@tabler/icons-react";

import { KeywordManagement } from "@components/Keyword";
import { ContestItem, ContestList } from "@components/Contest";
import { QrCodeGenerator } from "@components/shared";
import entityIcon from "@util/entityIcon";

const SectionTitle = ({ children }) => (
  <Title order={4} mb="sm">
    {children}
  </Title>
);

export default function EffortReadOnly({ effortInfo }) {
  const [refresh, setRefresh] = useState(false);

  const hasWizardSettings = JSON.stringify(effortInfo.wizard_settings) !== "{}";
  const showQr =
    !hasWizardSettings ||
    (effortInfo.wizard_settings &&
      effortInfo.wizard_settings.microsite === true);

  const ContestAddOnly = () => (
    <ContestList
      isManager
      readOnly
      refresh={refresh}
      showVarietyFilters={effortInfo.variety !== 5}
      requestInfo={{
        url: `/retrieve-contests/`,
        data: {
          campaign_effort_id: effortInfo.id,
        },
      }}
      displayFormatter={(contest) => (
        <ContestItem key={contest.id} contestInfo={contest} readOnly />
      )}
    />
  );

  return (
    <div>
      <Tabs defaultValue="detail" keepMounted={false}>
        <Tabs.List mb="lg">
          <Tabs.Tab value="detail" icon={<IconReportSearch />}>
            Details
          </Tabs.Tab>
          <Tabs.Tab value="contest" icon={entityIcon.contest()}>
            Contests
          </Tabs.Tab>
          {(!hasWizardSettings ||
            (effortInfo.wizard_settings &&
              effortInfo.wizard_settings.show_keywords === true)) && (
            <Tabs.Tab value="keyword" icon={entityIcon.keyword()}>
              Keywords
            </Tabs.Tab>
          )}
        </Tabs.List>
        <Tabs.Panel value="detail">
          <Card>
            {effortInfo.start_date_formatted &&
              effortInfo.end_date_formatted && (
                <React.Fragment>
                  <SectionTitle>Dates</SectionTitle>
                  <Group spacing="xs">
                    {entityIcon.calendar(24)}
                    <Text>
                      {effortInfo.start_date_formatted} -{" "}
                      {effortInfo.end_date_formatted}
                    </Text>
                  </Group>
                </React.Fragment>
              )}
            {showQr && (
              <React.Fragment>
                <Space mb="xl" />
                <SectionTitle>QR Code</SectionTitle>
                <QrCodeGenerator
                  text={`${import.meta.env.VITE_DOMAIN}/entry/${
                    effortInfo.microsite_url
                  }`}
                />
              </React.Fragment>
            )}
          </Card>
        </Tabs.Panel>
        <Tabs.Panel value="contest">
          <ContestAddOnly />
        </Tabs.Panel>
        <Tabs.Panel value="keyword">
          <KeywordManagement
            minStartDate={effortInfo.start_date}
            maxEndDate={effortInfo.end_date}
            effortId={effortInfo.id}
            showCreate={false}
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}
