import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import entityColor from "@util/entityColor";
import {
  Group,
  Box,
  Button,
  TextInput,
  Space,
  Loader,
  Select,
  Text,
  Table,
  Card,
  Pagination,
} from "@mantine/core";
import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";

import { UserInteractionTableBody } from "./";
import { StatBlocks } from "@components/DataViz";

export default function UserInteractionEffort({ effortId, joinId, location }) {
  const [data, setData] = useState(null);
  const [active, setActive] = useState("interactions");
  const [searchValue, setSearchValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const table = useReactTable({
    manualPagination: true,
    pageCount: pageCount,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  // useEffect(() => {
  //   table.setPageIndex(3);
  //   console.log(table.getState().pagination);
  // }, [searchValue]);

  useEffect(() => {
    fetchData(false);
  }, [active, JSON.stringify(table.getState().pagination)]);

  useEffect(() => {
    if (table.getState().pagination.pageIndex !== 0) {
      table.setPageIndex(0);
    } else {
      fetchData(false);
    }
  }, [searchValue]);

  useEffect(() => {
    fetchData(true);
  }, [effortId]);

  useEffect(() => {
    setSearchValue("");
  }, [active]);

  function fetchData(meta) {
    if (!effortId) return;

    setLoading(true);

    const reqData = {
      search_value: searchValue,
      context: active,
      campaign_effort_id: effortId,
      meta: meta,
      user_location: location,
      page_size: table.getState().pagination.pageSize,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
    };

    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      ...reqData,
    };

    axios
      .post(`/user-joins/${joinId}/campaign-efforts/${effortId}/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLoading(false);
        setTableData(data.response[0].data);
        setTotal(data.response[0].total);
        setPageCount(data.response[0].page_count);
        if (req.meta) {
          setData([
            {
              value: data.response[0].interaction_count,
              text: "Total Interactions",
            },
            {
              value: data.response[0].entry_count,
              text: "Contest Entries",
            },
            {
              value: data.response[0].check_in_count,
              text: "Total Check-Ins",
            },
            {
              value: data.response[0].keyword_count,
              text: "Total Keywords",
            },
          ]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setTableData([]);
        setData(null);
      });
  }

  const filterButtons = [
    {
      text: "interactions",
      variety: "Interactions",
      color: entityColor.location,
    },
    {
      text: "keywords",
      variety: "Keywords",
      color: entityColor.keyword,
    },
    {
      text: "check-ins",
      variety: "Check-ins",
      color: entityColor.campaign,
    },
    // {
    //   text: "entries",
    //   variety: "Entries",
    //   color: entityColor.entry,
    // },
  ].map((m, i) => ({
    ...m,
    key: i,
  }));

  const tableProps = {
    interactions: {
      headers: [
        "Date",
        location ? "Location" : "Organization",
        "Variety",
        "Entry Count",
        "View",
      ],
    },
    keywords: {
      headers: [
        "Name",
        "Date",
        location ? "Location" : "Organization",
        "Entry Count",
      ],
    },
    "check-ins": {
      headers: [
        "Date",
        location ? "Location" : "Organization",
        "Variety",
        "Entry Count",
      ],
    },
  };

  function getTableData(item) {
    if (active === "interactions") {
      return (
        <UserInteractionTableBody
          occurredAt={new Date(item.occurred_at).toLocaleString()}
          name={location ? item.location_name : item.organization_name}
          variety={item.variety}
          entryCount={item.entry_count}
          interactionId={item.id}
        />
      );
    }
    if (active === "keywords") {
      return (
        <Table.Tr>
          <Table.Td>{item.keyword_text}</Table.Td>
          <Table.Td>{new Date(item.occurred_at).toLocaleString()}</Table.Td>
          <Table.Td>
            {location ? item.location_name : item.organization_name}
          </Table.Td>
          <Table.Td>{item.entry_count}</Table.Td>
        </Table.Tr>
      );
    }
    if (active === "check-ins") {
      return (
        <Table.Tr>
          <Table.Td>{new Date(item.occurred_at).toLocaleString()}</Table.Td>
          <Table.Td>
            {location ? item.location_name : item.organization_name}
          </Table.Td>
          <Table.Td>{item.variety}</Table.Td>
          <Table.Td>{item.entry_count}</Table.Td>
        </Table.Tr>
      );
    }
  }

  if (!effortId) return;

  return (
    <div>
      <Box mt="sm">
        {data && <StatBlocks data={data} span={3} />}
        <Group mt="lg" mb="lg">
          {filterButtons.map((b, i) => {
            return (
              <Button
                key={i}
                variant={active === b.text ? "filled" : "outline"}
                size="xs"
                color={b.color}
                onClick={() => setActive(b.text)}
              >
                {b.variety}
              </Button>
            );
          })}
        </Group>
        <React.Fragment>
          {active === "keywords" && (
            <React.Fragment>
              <Space mt="lg" />
              <TextInput
                placeholder={`Search for ${active}`}
                value={searchValue}
                // disabled={loading}
                onChange={(e) => {
                  table.setPageIndex(0);
                  setSearchValue(e.target.value);
                }}
              />
              <Space mt="lg" />
            </React.Fragment>
          )}
          {tableData && tableData.length > 0 && (
            <Card>
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    {tableProps[active].headers.map((m, i) => (
                      <Table.Th key={i}>{m}</Table.Th>
                    ))}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {table
                    .getRowModel()
                    .rows.map((item) => getTableData(item.original))}
                </Table.Tbody>
              </Table>
            </Card>
          )}
          {!tableData.length && !loading && (
            <Card>
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    {tableProps[active].headers.map((m, i) => (
                      <Table.Th key={i}>{m}</Table.Th>
                    ))}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {table
                    .getRowModel()
                    .rows.map((item) => getTableData(item.original))}
                </Table.Tbody>
              </Table>
            </Card>
          )}
          {/* {console.log(table.getState().pagination.pageIndex)} */}
          <Group mt="lg">
            <Pagination
              total={pageCount}
              onChange={(e) => {
                // console.log(e);
                table.setPageIndex(e - 1);
              }}
              size="lg"
              value={table.getState().pagination.pageIndex + 1}
            />
            <Select
              size="sm"
              style={{ width: "110px" }}
              value={`${table.getState().pagination.pageSize}`}
              onChange={(e) => {
                table.setPageSize(e);
              }}
              data={[5, 10, 20, 30, 40, 50].map((pageSize) => ({
                label: `${pageSize} items`,
                value: `${pageSize}`,
                placeholder: "Select a pagesize",
              }))}
            />
            {loading && <Loader size="xs" variant="dots" />}
          </Group>
          <Text size="sm" mt="sm" mb="lg">
            {total} total item{total === 1 ? "" : "s"}
          </Text>
        </React.Fragment>
      </Box>
    </div>
  );
}
