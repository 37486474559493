import React from "react";
import { Anchor } from "@mantine/core";
import { Link } from "react-router-dom";

import { PageTitle } from "@components/shared";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function EntitySubtitles({ info }) {
  if (!info) return null;
  const subtitles = [
    {
      value: info.campaign_effort_id,
      icon: entityIcon.effort(),
      color: entityColor.effort,
      name: info.campaign_effort_name,
      url: `/efforts`,
    },
    {
      value: info.campaign_id,
      icon: entityIcon.campaign(),
      color: entityColor.campaign,
      name: info.campaign_name,
      url: `/campaigns`,
    },
    {
      value: info.organization_id,
      icon: entityIcon.organization(),
      color: entityColor.organization,
      name: info.organization_name,
      url: `/organizations`,
    },
    {
      value: info.location_id,
      icon: entityIcon.location(),
      color: entityColor.location,
      name: info.location_name,
      url: `/locations`,
    },
  ]
    .filter((f) => f.value)
    .map((m) => ({
      ...m,
      url: `${m.url}/${m.value}`,
    }));

  return (
    <div>
      {subtitles.map((m, i) => (
        <PageTitle
          key={i}
          icon={m.icon}
          iconColor={m.color}
          style={{ marginBottom: 0 }}
          subtitle={
            <Anchor
              style={{
                color: "inherit",
              }}
              component={Link}
              to={m.url}
              target="_blank"
            >
              {m.name}
            </Anchor>
          }
        />
      ))}
    </div>
  );
}
