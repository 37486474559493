import React, { useState, useEffect } from "react";
import {
  Button,
  Stack,
  Grid,
  Modal,
  Text,
  Space,
  TextInput,
  Tooltip,
} from "@mantine/core";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";
import axios from "axios";

export default function AssetLibraryPicker({ onSelect, reqData = {}, title }) {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (!open) return;

    fetchItems();
  }, [open]);

  useEffect(() => {
    if (!searchValue) return;
    fetchItems(searchValue);
  }, [searchValue]);

  function fetchItems(value) {
    setLoading(true);
    setSearchValue(value);

    const req = { ...reqData };

    if (value) req.value = value;

    axios
      .post(`/retrieve-library-assets/`, { ...req })
      .then(({ data }) => {
        setItems(data.response[0].data);
        setLoading(false);
      })
      .catch((err) => {
        setItems([]);
        setLoading(false);
      });
  }

  function onModalClose() {
    setOpen(false);
    // onClose();
  }

  return (
    <React.Fragment>
      <Button
        onClick={() => setOpen(true)}
        leftSection={entityIcon.library()}
        color={entityColor.library}
        size="xs"
        variant="light"
      >
        {title ? title : "Asset Library"}
      </Button>
      <Modal
        size="xl"
        opened={open}
        onClose={() => onModalClose()}
        title="Asset Library"
      >
        {!loading && items.length === 0 && (
          <Text mb="xl">No library images</Text>
        )}
        {loading && <Text mb="xl">Loading...</Text>}
        <TextInput
          placeholder="Search for an asset..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Space mt="lg" />
        <Grid>
          {items.map((item) => (
            <Grid.Col span={{ base: 12, md: 4 }} key={item.id}>
              <Stack align="center">
                <div>
                  <a href={item.filename_url} target="blank">
                    <img
                      src={item.filename_url}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </a>
                </div>
                <Tooltip label={item.original_filename}>
                  <Text lineClamp={1}>{item.original_filename}</Text>
                </Tooltip>
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => {
                    onSelect(item.asset_id);
                    onModalClose();
                  }}
                >
                  Choose
                </Button>
              </Stack>
            </Grid.Col>
          ))}
        </Grid>
      </Modal>
    </React.Fragment>
  );
}
