import React, { useState } from "react";
import { Button, Divider, Modal, TextInput } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { EmailValidationHistory, EmailValidationNameplate } from "./";

import { ContactMethodVerification } from "@components/shared";

export default function EmailValidationWidget({ fetchData }) {
  const [isOpen, setOpen] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);

  function onClose() {
    setOpen(false);
    setLoading(false);
    setEmailValue("");
    setInfo(null);
    fetchData();
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      email: emailValue,
    };

    axios
      .post(`/email-validations/single-validation/`, req)
      .then(({ data }) => {
        setLoading(false);
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Button onClick={() => setOpen(true)} size="xs" mb="sm" variant="light">
        Check Email
      </Button>
      <Modal
        opened={isOpen}
        onClose={onClose}
        size={info ? "xl" : "sm"}
        title="Email Verification"
      >
        {info ? (
          <React.Fragment>
            <React.Fragment>
              <EmailValidationNameplate
                title={info.address}
                subtitle={`last checked ${info.last_checked}`}
                allowed={info.allowed}
              />
              <EmailValidationHistory items={info.history} />
              <Divider mt="lg" mb="lg" />
              <div>
                <ContactMethodVerification isEmail value={emailValue} />
              </div>
            </React.Fragment>
            <Button
              size="xs"
              mt="xl"
              color="gray"
              variant="light"
              onClick={() => {
                setEmailValue("");
                setInfo(null);
              }}
            >
              Back
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TextInput
              placeholder="email"
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
              size="md"
            />
            <Button
              fullWidth
              mt="sm"
              disabled={!emailValue}
              loading={loading}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </React.Fragment>
        )}
      </Modal>
    </div>
  );
}
