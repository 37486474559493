import React from "react";
import { Box, Flex, Button } from "@mantine/core";
import { shallow } from "zustand/shallow";

import { CustomTooltip, DateField } from "@components/shared";

import useAppStore from "./report-recipe-runner-store";

export function Standard({ field }) {
  const { queryData, setQueryData } = useAppStore(
    (state) => ({
      queryData: state.query_data,
      setQueryData: state.setQueryData,
    }),
    shallow
  );

  return (
    <Flex align="end" gap="xs">
      <div style={{ flexGrow: 1 }}>
        <DateField
          label={
            field.tooltip_text ? (
              <CustomTooltip label={field.tooltip_text}>
                <label>{field.label}</label>
              </CustomTooltip>
            ) : (
              <label>{field.label}</label>
            )
          }
          hasTooltip={field.tooltip_text ? true : false}
          value={queryData[field.clientName]}
          required={field.required}
          onChange={(e) =>
            setQueryData({
              ...queryData,
              [field.clientName]: e,
            })
          }
        />
      </div>
      <Button
        onClick={() => {
          setQueryData({
            ...queryData,
            [field.clientName]: "",
          });
        }}
        mt="xs"
        size="xs"
        variant="subtle"
        color="gray"
        p={0}
      >
        clear
      </Button>
    </Flex>
  );
}

export function Start() {
  const { queryData, queryComponents, setQueryData } = useAppStore(
    (state) => ({
      queryData: state.query_data,
      queryComponents: state.query_components,
      recipeInfo: state.recipe_info,
      setQueryData: state.setQueryData,
    }),
    shallow
  );

  const effort = queryData.efforts.length === 1 ? queryData.efforts[0] : null;

  return (
    <Flex align="end" gap="xs">
      <div style={{ flexGrow: 1 }}>
        <DateField
          label={
            effort
              ? `Start Date (effort start: ${effort.start_date})`
              : "Start Date"
          }
          value={queryData.start_date}
          min={effort?.start_date}
          max={effort?.end_date}
          required={queryComponents.startDatePicker.required}
          onChange={(e) =>
            setQueryData({
              ...queryData,
              start_date: e,
            })
          }
        />
      </div>
      <Button
        onClick={() => {
          setQueryData({
            ...queryData,
            start_date: "",
          });
        }}
        mt="xs"
        size="xs"
        variant="subtle"
        color="gray"
        p={0}
      >
        clear
      </Button>
    </Flex>
  );
}

export function End() {
  const { queryData, queryComponents, setQueryData } = useAppStore(
    (state) => ({
      queryData: state.query_data,
      queryComponents: state.query_components,
      recipeInfo: state.recipe_info,
      setQueryData: state.setQueryData,
    }),
    shallow
  );

  const effort = queryData.efforts.length === 1 ? queryData.efforts[0] : null;

  return (
    <Flex align="end" gap="xs">
      <div style={{ flexGrow: 1 }}>
        <DateField
          label={
            effort ? `End Date (effort end: ${effort.end_date})` : "End Date"
          }
          value={queryData.end_date}
          min={effort?.start_date}
          max={effort?.end_date}
          required={queryComponents.endDatePicker.required}
          onChange={(e) =>
            setQueryData({
              ...queryData,
              end_date: e,
            })
          }
        />
      </div>
      <Button
        onClick={() => {
          setQueryData({
            ...queryData,
            end_date: "",
          });
        }}
        mt="xs"
        size="xs"
        variant="subtle"
        color="gray"
        p={0}
      >
        clear
      </Button>
    </Flex>
  );
}
