import React, { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import { Carousel } from "@mantine/carousel";
import { useMediaQuery } from "@mantine/hooks";
import { Paper, Text, Title, useMantineTheme } from "@mantine/core";

import { getUuid } from "@util/getUuid";
import classes from "./ItemSlider.module.css";

function Card({
  backgroundColor = "#000",
  image,
  title,
  category,
  children,
  textBottom = false,
}) {
  return (
    <Paper
      shadow="md"
      p="xl"
      radius="md"
      style={{
        backgroundImage: image ? `url(${image})` : null,
        backgroundColor: !image ? backgroundColor : null,
      }}
      className={classes.card}
    >
      {!textBottom ? (
        <React.Fragment>
          <div>
            <Text c="white" fw={600} className={classes.category}>
              {category}
            </Text>
            <Title c="white" order={2} className={classes.title}>
              {title}
            </Title>
          </div>
          {children && !textBottom && (
            <React.Fragment>{children}</React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {children && textBottom && (
            <React.Fragment>{children}</React.Fragment>
          )}
          <div>
            <Text c="white" className={classes.category}>
              {category}
            </Text>
            <Title c="white" order={3} className={classes.title}>
              {title}
            </Title>
          </div>
        </React.Fragment>
      )}
    </Paper>
  );
}

export default function ItemSlider({
  data = [],
  autoplaySpeed = 6500,
  onSlideChange = () => true,
}) {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  const autoplay = useRef(Autoplay({ delay: autoplaySpeed }));

  const slides = data.map((item) => (
    <Carousel.Slide key={getUuid()}>
      <Card {...item} />
    </Carousel.Slide>
  ));

  return (
    <Carousel
      slideSize={{ base: "100%", md: "50%" }}
      breakpoints={[{ maxWidth: "sm", slideSize: "100%", slideGap: 2 }]}
      slideGap="xl"
      align="start"
      withControls={data.length > 1}
      slidesToScroll={mobile ? 1 : 1}
      plugins={[autoplay.current]}
      onSlideChange={(activeSlideIndex) => onSlideChange(activeSlideIndex)}
    >
      {slides}
    </Carousel>
  );
}
