export const initialPrizeSettings = {
  finalists: false,
  number_of_finalists: 0,
  draw_winner_from_finalists: false,
};

export const initialPrizePoolSettings = {
  entry_cost: 0,
};

export const initialAuctionItemSettings = {
  min_starting_bid: 0,
  max_bid_increment: 0,
};
