import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Group,
  Card,
  SegmentedControl,
  Text,
  rem,
} from "@mantine/core";
import {
  IconArrowsUpDown,
  IconPencil,
  IconPlus,
  IconPhoto,
} from "@tabler/icons-react";

import { LootItemForm } from "@components/LootLocker";
import { AssetManagement, AssetLibraryPicker } from "@components/Asset";

export default function PrizeLootItemsForDupeContest({
  prizeId,
  locationId,
  organizationId,
}) {
  const [lootItems, setLootItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState("list");
  const [entityName, setEntityName] = useState("");

  useEffect(() => {
    fetchItems();
  }, []);

  function fetchItems() {
    setLoading(true);
    axios
      .get(`/prizes/${prizeId}/`)
      .then(({ data }) => {
        if (data.response[0].loot_items.length === 0) {
          setTabValue("add");
        } else {
          setTabValue("list");
        }
        setLootItems(data.response[0].loot_items);
        setLoading(false);
        setEntityName(data.response[0].entity_name);
      })
      .catch((err) => {
        setLootItems([]);
        setLoading(false);
      });
  }

  const addedLootItemIds = lootItems.map((m) => m.loot_item_id);

  if (loading) return null;

  return (
    <div>
      <SegmentedControl
        mb="lg"
        value={tabValue}
        onChange={setTabValue}
        data={[
          {
            label: (
              <Center style={{ gap: 10 }}>
                <IconArrowsUpDown style={{ width: rem(16), height: rem(16) }} />
                <span>Manage Items</span>
              </Center>
            ),
            value: "list",
          },
          {
            label: (
              <Center style={{ gap: 10 }}>
                <IconPlus style={{ width: rem(16), height: rem(16) }} />
                <span>Add Items</span>
              </Center>
            ),
            value: "add",
          },
        ]}
      />
      <Box>
        {tabValue === "list" && (
          <div>
            {lootItems.length > 0 && (
              <LootItemList
                prizeId={prizeId}
                onSuccess={() => fetchItems()}
                currentItems={lootItems}
              />
            )}
          </div>
        )}
        {tabValue === "add" && (
          <div>
            <AddLootItems
              addedLootItemIds={addedLootItemIds}
              prizeId={prizeId}
              locationId={locationId}
              organizationId={organizationId}
              entityName={entityName}
              onSuccess={() => {
                fetchItems();
              }}
            />
          </div>
        )}
      </Box>
    </div>
  );
}

function LootItemList({ currentItems = [], prizeId, onSuccess }) {
  const [items, setItems] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setItems(
      currentItems.map((m) => ({
        ...m,
        count: m.quantity,
      }))
    );
  }, [JSON.stringify(currentItems)]);

  function onItemAllocationChange(itemId, count) {
    const newItems = [...items];
    const newItem = newItems.find((f) => f.loot_item_id === itemId);
    if (!newItem) return;

    newItem.count = count;
    setItems([...newItems]);
  }

  function onSubmitClick() {
    setSubmitting(true);

    const changed = items
      .filter((f) => {
        const foundItem = currentItems.find(
          (i) => i.loot_item_id === f.loot_item_id
        );
        if (!foundItem || f.count === foundItem.quantity) return;
        return f;
      })
      .map((m) => {
        const obj = {
          loot_item_id: m.loot_item_id,
          prize_id: prizeId,
        };
        const foundItem = currentItems.find(
          (i) => i.loot_item_id === m.loot_item_id
        );
        if (!foundItem) return;
        if (m.count < foundItem.quantity) {
          obj.add = false;
          obj.quantity = foundItem.quantity - m.count;
        } else {
          obj.add = true;
          obj.quantity = m.count - foundItem.quantity;
        }
        return obj;
      });

    const req = {
      loot_items: changed,
    };

    axios
      .post(`/prizes/${prizeId}/adjust-loot-quantity/`, req)
      .then(() => {
        onSuccess();
        setSubmitting(false);
        toast.success("Updated!");
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error(err);
      });
  }

  const filteredItems = searchValue.length
    ? items.filter((f) =>
        f.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
          ? true
          : false
      )
    : items;

  return (
    <React.Fragment>
      <OptionsWrapper>
        {filteredItems.map((item) => {
          const quantityAvailableToAdd =
            item.quantity + item.quantity_available;

          return (
            <Card p="xs" key={item.loot_item_id} mb="xs">
              <LootItemEditItem
                lootItem={item}
                id={item.loot_item_id}
                fetchData={onSuccess}
                onItemAllocationChange={onItemAllocationChange}
              />
            </Card>
          );
        })}
      </OptionsWrapper>
      {/* <Button
        fullWidth
        size="lg"
        color="green"
        onClick={onSubmitClick}
        // loading={submitting}
        mt="lg"
      >
        Save
      </Button> */}
    </React.Fragment>
  );
}

function AddLootItems({ prizeId, onSuccess }) {
  return <LootItemForm prizeId={prizeId} onSuccess={onSuccess} />;
}

const OptionsWrapper = ({ children }) => {
  return (
    <Box
      style={{
        background: "var(--mantine-color-gray-1)",
        padding: "var(--mantine-spacing-xs)",
        border: "1px solid #ccc",
        overflowY: "auto",
        // maxHeight: "300px",
        // minHeight: "300px",
      }}
    >
      {children}
    </Box>
  );
};

const LootItemEditItem = ({ lootItem, fetchData, id }) => {
  const [editing, setEditing] = useState(false);
  const [tabValue, setTabValue] = useState("form");
  const [showAssets, setShowAssets] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);

  function onLibrarySelection(itemId) {
    const req = { loot_item_id: id, asset_id: itemId, variety: 11 };

    axios
      .post(`/replicate-library-asset/`, req)
      .then(({ data }) => {
        toast.success("Added!");
        fetchData();
        setShowAssets(false);
        setTimeout(() => {
          setShowAssets(true);
        }, 100);
      })
      .catch((err) => {
        onSuccess();
        toast.error(err);
      });
  }

  function onDeleteClick() {
    setDeleteLoading(true);
    axios
      .post(`/remove-loot-item/`, { loot_item_id: id })
      .then(() => {
        toast.success("Deleted!");
        fetchData();
        setDeleteLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setDeleteLoading(false);
      });
  }

  return (
    <div>
      <Group>
        <ActionIcon
          onClick={() => setEditing(!editing)}
          variant="light"
          radius="xl"
          color="gray"
        >
          <IconPencil style={{ width: rem(16), height: rem(16) }} />
        </ActionIcon>
        <Text fw={600} style={{ flexGrow: 1 }}>
          {lootItem.name}
        </Text>
        <Text fw={600}>{lootItem.quantity}</Text>
      </Group>
      {editing && (
        <Box mt="lg">
          <SegmentedControl
            mb="lg"
            size="xs"
            value={tabValue}
            onChange={setTabValue}
            data={[
              {
                label: (
                  <Center style={{ gap: 10 }}>
                    <IconPencil style={{ width: rem(16), height: rem(16) }} />
                    <span>Edit Details</span>
                  </Center>
                ),
                value: "form",
              },
              {
                label: (
                  <Center style={{ gap: 10 }}>
                    <IconPhoto style={{ width: rem(16), height: rem(16) }} />
                    <span>Assets</span>
                  </Center>
                ),
                value: "assets",
              },
            ]}
          />
          <Box>
            {tabValue === "form" && (
              <div>
                <LootItemForm
                  id={id}
                  name={lootItem.name}
                  description={lootItem.description}
                  retailValue={lootItem.value_formatted}
                  onSuccess={() => {
                    setEditing(false);
                    fetchData();
                  }}
                />
                <Group justify="flex-end">
                  <Button
                    onClick={onDeleteClick}
                    loading={deleteLoading}
                    mt="md"
                    color="gray"
                    size="xs"
                    variant="subtle"
                    p={0}
                  >
                    Delete this loot item
                  </Button>
                </Group>
              </div>
            )}
            {tabValue === "assets" && (
              <div>
                {showAssets ? (
                  <>
                    <AssetManagement lootItemId={id}>
                      <AssetLibraryPicker
                        onClose={() => {
                          fetchData();
                        }}
                        onSelect={(assetId) => onLibrarySelection(assetId)}
                        reqData={{
                          variety: [1, 2, 3, 4],
                        }}
                      />
                    </AssetManagement>
                  </>
                ) : (
                  <Box style={{ minHeight: "200px" }}></Box>
                )}
              </div>
            )}
          </Box>
        </Box>
      )}
    </div>
  );
};
