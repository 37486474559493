import React, { useState, useEffect } from "react";
import { Select } from "@mantine/core";
import axios from "axios";

import { KeywordForm } from "./";

const VALUE_CAMPAIGN = "1";
const VALUE_EFFORT = "2";
const VALUE_NOTHING = "3";

export default function KeywordFormSetup({
  isManager,
  locationId,
  organizationId,
  onSuccess,
}) {
  const [formValues, setFormValues] = useState({
    entity: null,
    campaignId: null,
    effortId: null,
  });

  const showForm =
    (formValues.entity === VALUE_CAMPAIGN && formValues.campaignId) ||
    (formValues.entity === VALUE_EFFORT && formValues.effortId) ||
    formValues.entity === VALUE_NOTHING;

  return (
    <React.Fragment>
      <Select
        placeholder="Select one..."
        label="What is this for?"
        data={[
          { label: "Campaign", value: VALUE_CAMPAIGN },
          { label: "Effort", value: VALUE_EFFORT },
          // { label: "Nothing in particular", value: VALUE_NOTHING },
        ]}
        value={formValues.entity}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            entity: e,
            campaignId: [VALUE_NOTHING, VALUE_EFFORT].includes(e)
              ? null
              : formValues.campaignId,
            effortId: [VALUE_CAMPAIGN, VALUE_NOTHING].includes(e)
              ? null
              : formValues.effortId,
          })
        }
      />
      {formValues.entity === VALUE_CAMPAIGN && (
        <CampaignSelect
          organizationId={organizationId}
          locationId={locationId}
          value={formValues.campaignId}
          onChange={(e) => setFormValues({ ...formValues, campaignId: e })}
        />
      )}
      {formValues.entity === VALUE_EFFORT && (
        <EffortSelect
          organizationId={organizationId}
          locationId={locationId}
          value={formValues.effortId}
          onChange={(e) => setFormValues({ ...formValues, effortId: e })}
        />
      )}
      {showForm && (
        <KeywordForm
          campaignId={formValues.campaignId}
          effortId={formValues.effortId}
          locationId={locationId}
          organizationId={organizationId}
          onSuccess={onSuccess}
        />
      )}
    </React.Fragment>
  );
}

const CampaignSelect = ({ value, onChange, locationId, organizationId }) => {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  function fetchCampaigns() {
    const req = {
      pagination: false,
      keyword_select: true,
    };

    if (organizationId) req.organization_id = organizationId;
    if (locationId) req.location_id = locationId;

    axios
      .post(`/retrieve-campaigns/`, req)
      .then(({ data }) => {
        setCampaigns(
          data.response.map((m) => ({
            label: m.title,
            value: m.id,
          }))
        );
      })
      .catch((err) => {});
  }

  return (
    <Select
      label="Which Campaign?"
      placeholder="Select one..."
      value={value}
      searchable
      data={campaigns}
      onChange={(e) => onChange(e)}
    />
  );
};

const EffortSelect = ({ value, onChange, locationId, organizationId }) => {
  const [efforts, setEfforts] = useState([]);

  useEffect(() => {
    fetchEfforts();
  }, []);

  function fetchEfforts() {
    const req = {};

    const url = organizationId
      ? `/organizations/${organizationId}/retrieve-efforts/`
      : `/locations/${locationId}/all-efforts/`;

    if (organizationId) req.organization_id = organizationId;
    if (locationId) req.location_id = locationId;

    axios
      .post(url, req)
      .then(({ data }) => {
        setEfforts(
          data.response.map((m) => ({
            label: m.title,
            value: m.id,
          }))
        );
      })
      .catch((err) => {});
  }

  return (
    <Select
      label="Which Effort?"
      placeholder="Select one..."
      value={value}
      searchable
      data={efforts}
      onChange={(e) => onChange(e)}
    />
  );
};
