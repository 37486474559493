import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Group, Text } from "@mantine/core";
import styled from "styled-components";
import toast from "react-hot-toast";

import { CodeInput } from "@components/Auth";

const ContactMethodVerification = ({
  isPhone = false,
  isEmail = false,
  onSuccess,
  value,
}) => {
  const [loading, setLoading] = useState(false);
  const [codeValue, setCodeValue] = useState("");
  const [remoteCodeId, setRemoteCodeId] = useState(null);
  const [codeValidated, setCodeValidated] = useState(false);

  function resetState() {
    setCodeValue("");
    setRemoteCodeId(null);
    setCodeValidated(false);
  }

  useEffect(() => {
    if (codeValue.length === 6) {
      verifyCode();
    }
  }, [codeValue]);

  function sendCodeToMethod() {
    setLoading(true);

    const req = {};

    if (isEmail) req.email = value;
    if (isPhone) req.mobile_phone = value;

    axios
      .post(`/microsite/send-verification/`, req)
      .then(({ data }) => {
        setLoading(false);
        setRemoteCodeId(data.response[0].remote_code_id);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function verifyCode() {
    const req = {
      code: codeValue,
      remote_code_id: remoteCodeId,
    };

    axios
      .post(`/microsite/verify-code/`, req)
      .then(() => {
        setCodeValidated(true);
      })
      .catch((err) => {
        toast.error(err);
        setCodeValue("");
      });
  }

  if (codeValidated) {
    return (
      <div>
        <Text mb="sm" fw={600} align="center">
          Code validated
        </Text>
      </div>
    );
  }

  return (
    <React.Fragment>
      {remoteCodeId ? (
        <React.Fragment>
          <Text mb="sm" align="center" fw={600} size="sm">
            {isEmail &&
              `An email was sent to ${value} containing a six digit code.`}
            {isPhone &&
              `A text was sent to ${value} containing a six digit code.`}{" "}
            Enter the six digit code below.
          </Text>
          <StyledInputs>
            <CodeInput value={codeValue} onChange={(e) => setCodeValue(e)} />
          </StyledInputs>
          <Group justify="center">
            <Button
              variant="subtle"
              color="gray"
              size="xs"
              mt="lg"
              onClick={() => setRemoteCodeId(null)}
            >
              Back
            </Button>
          </Group>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Button
            loading={loading}
            onClick={sendCodeToMethod}
            disabled={!value}
          >
            Send verification code
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const StyledInputs = styled.div`
  input {
    border: 1px solid #000;
    background: #eee;
  }
`;

export default ContactMethodVerification;
