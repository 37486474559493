import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);

const meterConfig = {
  min: 0,
  max: 20,
  color_red: "var(--mantine-color-red-5)",
  plot_band_start: 15,
  plot_band_end: 20,
};

export default function VuMeter({
  leftValue,
  rightValue,
  leftTitle,
  rightTitle,
  subtitle,
  title,
  micrositeMeterConfig = null,
}) {
  if (micrositeMeterConfig) {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            backgroundColor: "transparent",
            // backgroundColor: "var(--mantine-color-dark-9)",
            animation: {
              duration: 500,
            },
            style: {
              fontFamily: "inherit",
              backgroundColor: "transparent",
              borderRadius: "2px",
            },
            type: "gauge",
            // plotBorderWidth: 0,
            // plotBackgroundColor: {
            //   linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            //   stops: [
            //     [0, "#FFF4C6"],
            //     [0.3, "#FFFFFF"],
            //     [1, "#FFF4C6"],
            //   ],
            // },
            // plotBackgroundImage: null,
            height: 200,
            width: 450,
          },

          title: {
            text: title,
          },
          subtitle: {
            text: subtitle,
          },
          pane: [
            {
              startAngle: -48,
              endAngle: 48,
              background: null,
              center: ["50%", "165%"],
              size: 450,
            },
          ],

          exporting: {
            enabled: false,
          },

          tooltip: {
            enabled: false,
          },

          yAxis: [
            {
              min: micrositeMeterConfig.min,
              max: micrositeMeterConfig.max,
              minorTickPosition: "outside",
              tickPosition: "outside",
              labels: {
                rotation: "auto",
                distance: 20,
                style: {
                  color: "var(--mantine-color-gray-5)",
                },
              },
              plotBands: micrositeMeterConfig.plotBands || [
                {
                  from: meterConfig.plot_band_start,
                  to: meterConfig.plot_band_end,
                  color: meterConfig.color_red,
                  innerRadius: "100%",
                  outerRadius: "105%",
                },
              ],
              pane: 0,
            },
          ],

          plotOptions: {
            gauge: {
              dataLabels: {
                enabled: false,
              },
              dial: {
                radius: "100%",
                backgroundColor: "var(--mantine-color-gray-5)",
                baseWidth: 5,
              },
            },
          },

          series: [
            {
              name: "Channel A",
              data: [
                leftValue > micrositeMeterConfig.max
                  ? micrositeMeterConfig.max
                  : leftValue,
              ],
              yAxis: 0,
            },
          ],
          credits: {
            enabled: false,
          },
        }}
      />
    );
  }
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          // backgroundColor: "var(--mantine-color-dark-9)",
          animation: {
            duration: 500,
          },
          style: {
            fontFamily: "inherit",
            backgroundColor: "transparent",
            borderRadius: "2px",
          },
          type: "gauge",
          plotBorderWidth: 0,
          plotBackgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, "#FFF4C6"],
              [0.3, "#FFFFFF"],
              [1, "#FFF4C6"],
            ],
          },
          plotBackgroundImage: null,
          height: 200,
        },

        title: {
          text: title,
        },
        subtitle: {
          text: subtitle,
        },
        pane: [
          {
            startAngle: -45,
            endAngle: 45,
            background: null,
            center: ["25%", "155%"],
            size: 450,
          },
          {
            startAngle: -45,
            endAngle: 45,
            background: null,
            center: ["75%", "155%"],
            size: 450,
          },
        ],

        exporting: {
          enabled: false,
        },

        tooltip: {
          enabled: false,
        },

        yAxis: [
          {
            min: meterConfig.min,
            max: meterConfig.max,
            minorTickPosition: "outside",
            tickPosition: "outside",
            labels: {
              rotation: "auto",
              distance: 20,
            },
            plotBands: [
              {
                from: meterConfig.plot_band_start,
                to: meterConfig.plot_band_end,
                color: meterConfig.color_red,
                innerRadius: "100%",
                outerRadius: "105%",
              },
            ],
            pane: 0,
            title: {
              text: `${leftValue}%<br/><span style="font-size:8px">${leftTitle}</span>`,
              y: -40,
            },
          },
          {
            min: meterConfig.min,
            max: meterConfig.max,
            minorTickPosition: "outside",
            tickPosition: "outside",
            labels: {
              rotation: "auto",
              distance: 20,
            },
            plotBands: [
              {
                from: meterConfig.plot_band_start,
                to: meterConfig.plot_band_end,
                color: meterConfig.color_red,
                innerRadius: "100%",
                outerRadius: "105%",
              },
            ],
            pane: 1,
            title: {
              text: `${rightValue}%<br/><span style="font-size:8px">${rightTitle}</span>`,
              y: -40,
            },
          },
        ],

        plotOptions: {
          gauge: {
            dataLabels: {
              enabled: false,
            },
            dial: {
              radius: "100%",
            },
          },
        },

        series: [
          {
            name: "Channel A",
            data: [leftValue > meterConfig.max ? meterConfig.max : leftValue],
            yAxis: 0,
          },
          {
            name: "Channel B",
            data: [rightValue > meterConfig.max ? meterConfig.max : rightValue],
            yAxis: 1,
          },
        ],
        credits: {
          enabled: false,
        },
      }}
    />
  );
}
