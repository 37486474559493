import React from "react";
import { shallow } from "zustand/shallow";
import { Box, SegmentedControl, Input } from "@mantine/core";

import { CustomTooltip } from "@components/shared";

import useAppStore from "./report-recipe-runner-store";

export default function QbSegmentedControl({ field }) {
  const { queryData, queryComponents, setQueryData } = useAppStore(
    (state) => ({
      queryData: state.query_data,
      queryComponents: state.query_components,
      setQueryData: state.setQueryData,
    }),
    shallow
  );

  return (
    <Input.Wrapper
      labelProps={{
        labelElement: "div",
        style: {
          display: "flex",
          alignItems: "center",
          gap: "6px",
        },
      }}
      label={
        field.tooltip_text ? (
          <CustomTooltip label={field.tooltip_text}>
            <label>{field.label}</label>
          </CustomTooltip>
        ) : (
          <label>{field.label}</label>
        )
      }
      required={field.required}
    >
      <Box mt="6px">
        <SegmentedControl
          data={field.options.map((m) => ({
            ...m,
            value: `${m.value}`,
          }))}
          value={
            queryData[field.clientName] ? `${queryData[field.clientName]}` : ""
          }
          label={field.label}
          onChange={(e) =>
            setQueryData({
              ...queryData,
              [field.clientName]: `${e}`,
            })
          }
        />
      </Box>
    </Input.Wrapper>
  );
}
