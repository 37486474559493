import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button, Card, Select, Title } from "@mantine/core";
import toast from "react-hot-toast";

import { ContestCreate, ContestForm, ContestList } from "./";
import { AssignCreateToggle, ManagementLayout } from "@components/shared";

export default function ContestManagement({
  campaignId,
  effortId,
  endDate = null,
  locationId,
  organizationId,
  showCreate = true,
  showVarietyFilters = true,
}) {
  const [contestId, setContestId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const createable =
    (locationId || organizationId) && !campaignId && !effortId ? true : false;
  const assignable = campaignId || effortId ? true : false;

  const isManager = useSelector((state) => state.manager);

  const req = {
    refresh,
  };

  if (effortId) req.campaign_effort_id = effortId;
  if (campaignId) req.campaign_id = campaignId;
  if (organizationId) req.organization_id = organizationId;
  if (locationId) req.location_id = locationId;

  useEffect(() => {
    if (organizationId || locationId) {
      setCreating(true);
    } else {
      setCreating(false);
    }
  }, [organizationId, locationId, campaignId, effortId]);

  function onAssignSubmit() {
    setLoading(true);

    const req = {
      contest_id: contestId,
    };

    if (effortId) req.campaign_effort_id = effortId;
    if (organizationId) req.organization_id = organizationId;
    if (locationId) req.location_id = locationId;
    if (campaignId) req.campaign_id = campaignId;

    axios
      .post(`/contests/${contestId}/add-participant/`, req)
      .then(() => {
        setRefresh(!refresh);
        setLoading(false);
        setContestId(null);
        setSuccess(!success);
        setTimeout(() => {
          setSuccess(false);
        }, 1);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  // const showCreate =
  //   (isManager && locationId) || (organizationId && !locationId) ? true : false;

  if (
    (!organizationId && !effortId && !locationId && !campaignId) ||
    !showCreate
  ) {
    return (
      <ContestList
        isManager={isManager}
        hiddenFilters={organizationId ? ["owned_by"] : []}
        refresh={refresh}
        requestInfo={{ url: `/retrieve-contests/`, data: req }}
        showVarietyFilters={showVarietyFilters}
      />
    );
  }

  if (true) {
    return (
      <ContestList
        campaignId={campaignId}
        effortId={effortId}
        hiddenFilters={organizationId ? ["owned_by"] : []}
        isManager={isManager}
        refresh={refresh}
        requestInfo={{ url: `/retrieve-contests/`, data: req }}
        showVarietyFilters={showVarietyFilters}
      />
    );
  }

  const showCreateAssign =
    locationId || organizationId || campaignId || effortId;

  return (
    <ManagementLayout
      createComponent={
        showCreateAssign && (
          <Card
            style={{
              overflow: "visible",
            }}
          >
            {(organizationId || locationId) && !effortId && !campaignId ? (
              <Title order={4}>Create Contest</Title>
            ) : (
              <Title order={4}>Assign Contest</Title>
            )}
            {/* {(effortId || campaignId) && (
              <AssignCreateToggle
                active={creating}
                buttons={[
                  { value: false, text: "Assign Contest " },
                  { value: true, text: "Create Contest " },
                ]}
                onClick={(e) => setCreating(e)}
              />
            )} */}
            {creating && !success && (
              <ContestCreate
                effortId={effortId}
                campaignId={campaignId}
                organizationId={organizationId}
                locationId={locationId}
                endDate={endDate}
                reqData={req}
                onSuccess={() => {
                  setSuccess(!success);
                  setRefresh(!refresh);
                  setTimeout(() => {
                    setSuccess(false);
                  }, 1);
                }}
              />
            )}
            {!creating && (effortId || campaignId) && (
              <React.Fragment>
                {!success && (
                  <ContestSelect
                    onChange={(e) => setContestId(e)}
                    value={contestId}
                    disabledIds={[]}
                    reqData={{
                      ...req,
                      ignore_joins:
                        effortId || campaignId || isManager ? true : false,
                    }}
                  />
                )}
                <Button
                  disabled={!contestId || loading}
                  onClick={onAssignSubmit}
                  loading={loading}
                  mt="sm"
                  fullWidth
                >
                  Add
                </Button>
              </React.Fragment>
            )}
          </Card>
        )
      }
      dataComponent={
        <div>
          <ContestList
            campaignId={campaignId}
            effortId={effortId}
            hiddenFilters={organizationId ? ["owned_by"] : []}
            isManager={isManager}
            refresh={refresh}
            requestInfo={{ url: `/retrieve-contests/`, data: req }}
            showVarietyFilters={showVarietyFilters}
          />
        </div>
      }
    />
  );
}

export const ContestSelect = ({
  label = "Select a contest",
  reqData = {},
  onChange,
  disabledIds = [],
  value,
}) => {
  const [contests, setContests] = useState([]);
  const isManager = useSelector((state) => state.manager);

  useEffect(() => {
    fetchData();
    onChange(null);
  }, [JSON.stringify(reqData)]);

  function fetchData() {
    const req = {
      ...reqData,
      pagination: false,
    };

    axios
      .post(`/retrieve-contests/`, req)
      .then(({ data }) => {
        setContests(
          data.response
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((m) => ({
              label: m.name,
              value: m.id,
            }))
        );
      })
      .catch((err) => {
        setContests([]);
      });
  }

  const formattedOptions = contests.map((m) => ({
    ...m,
    disabled: disabledIds.includes(m.value),
  }));

  return (
    <Select
      searchable
      label={label}
      placeholder="Select one..."
      data={formattedOptions}
      onChange={(e) => onChange(e)}
      value={value}
    />
  );
};
