import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Space } from "@mantine/core";

import { EntitySubtitles, ErrorView, PageTitle } from "@components/shared";

import entityIcon from "@util/entityIcon";

import { LocationSongSongQuery } from "@components/SongQuery";
import { formatArtistList } from "@components/Curation/helpers";
import { songQueryVarieties } from "@components/SongQuery/helpers";

export default function LocationSongDetail({ isAdmin }) {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/location-songs/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  return (
    <div>
      <PageTitle
        title={info.name}
        subtitle={
          <React.Fragment>
            Song from{" "}
            {info.artist ? formatArtistList([{ ...info.artist }]) : ""}
          </React.Fragment>
        }
        icon={entityIcon.song()}
        style={{ marginBottom: 0 }}
      />
      {isAdmin && (
        <EntitySubtitles
          info={{
            ...info,
          }}
        />
      )}
      <Space mb="sm" />
      <LocationSongSongQuery
        currentValue={info.in_rotation_category}
        locationId={info.location_id}
        locationSongId={id}
        onSuccess={fetchData}
        title="Rotation Category"
        variety={songQueryVarieties.rotation_category}
      />
      <Space mt="sm" />
      <LocationSongSongQuery
        currentValue={info.in_sound_code}
        locationId={info.location_id}
        locationSongId={id}
        onSuccess={fetchData}
        title="Sound Code"
        variety={songQueryVarieties.sound_code}
      />
    </div>
  );
}
