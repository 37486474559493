import React from "react";
import {
  IconComponents,
  IconLink,
  IconPalette,
  IconAbc,
  IconPhoto,
  IconSettings,
} from "@tabler/icons-react";

const defaultSize = 18;

export const Color = ({ size = defaultSize }) => <IconPalette size={size} />;

export const Text = ({ size = defaultSize }) => <IconAbc size={size} />;

export const Asset = ({ size = defaultSize }) => <IconPhoto size={size} />;

export const Component = ({ size = defaultSize }) => (
  <IconComponents size={size} />
);

export const Link = ({ size = defaultSize }) => <IconLink size={size} />;

export const Settings = ({ size = defaultSize }) => (
  <IconSettings size={size} />
);
