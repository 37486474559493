import React from "react";
import { Button, Flex, Text } from "@mantine/core";
import useAppStore from "./report-recipe-runner-store";
import { shallow } from "zustand/shallow";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const formatter = new Intl.ListFormat("en", {
  style: "long",
  type: "conjunction",
});

export default function SavedRecipeMeta() {
  const { locations, orgs, reset, savedRecipe } = useAppStore(
    (state) => ({
      error: state.error,
      locations: state.locations,
      orgs: state.orgs,
      queryComponents: state.query_components,
      queryData: state.query_data,
      recipeInfo: state.recipe_info,
      reportData: state.report_data,
      reportDataQuery: state.report_data_query,
      reset: state.reset,
      savedRecipe: state.saved_recipe,
      snapshot: state.snapshot,
      setError: state.setError,
      setQueryData: state.setQueryData,
      setReportData: state.setReportData,
      setReportDataQuery: state.setReportDataQuery,
      setSavedRecipe: state.setSavedRecipe,
      setSnapshot: state.setSnapshot,
    }),
    shallow
  );

  const queryClient = useQueryClient();

  return (
    <>
      <Text fw={600}>{savedRecipe.title} (saved recipe)</Text>
      {savedRecipe.description && (
        <Text mb="xs" w={"65%"}>
          {savedRecipe.description}
        </Text>
      )}
      {savedRecipe.query_data && (
        <>
          {locations && locations.length > 0 && (
            <Text>
              <b>Locations:</b> {formatter.format(locations.map((m) => m.name))}
            </Text>
          )}
          {orgs && orgs.length > 0 && (
            <Text>
              <b>Organizations:</b> {formatter.format(orgs.map((m) => m.name))}
            </Text>
          )}
        </>
      )}
      <Button
        size="xs"
        variant="subtle"
        color="gray"
        style={{ width: "fit-content" }}
        p={0}
        onClick={() => {
          reset().then(() => {
            queryClient.invalidateQueries([`snapshots`]);
          });
        }}
      >
        exit saved recipe
      </Button>
    </>
  );
}
