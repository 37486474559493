import { shallow } from "zustand/shallow";
import useAppStore from "./report-recipe-runner-store";

export default function qbValidator() {
  const { locations, orgs, queryData, queryComponents } = useAppStore(
    (state) => ({
      locations: state.locations,
      orgs: state.orgs,
      queryData: state.query_data,
      queryComponents: state.query_components,
    }),
    shallow
  );
  // if (!queryComponents) return true;
  let conditions = [];

  const hasLocations = locations.length > 0;
  const hasOrgs = orgs.length > 0;

  queryComponents
    .filter((f) => {
      if (!f.show_on) {
        return true;
      }
      let shouldShow = true;
      f.show_on.forEach((f) => {
        if (["location_ids", "organization_ids"].includes(f)) {
          if (f === "location_ids") {
            if (!hasLocations) shouldShow = false;
          } else {
            if (!hasOrgs) shouldShow = false;
          }
        } else {
          const qc = queryComponents.find((ff) => ff.server_name === f);
          if (!qc) return;
          if (qc.type === "select") {
            if (!queryData[f].length) {
              shouldShow = false;
            }
          } else {
            shouldShow = queryData[f] ? true : false;
          }
        }
      });
      return shouldShow;
    })
    .forEach((qc) => {
      if (!qc.required) return;
      if (qc.type === "select") {
        conditions.push(queryData[qc.clientName].length > 0);
      } else if (qc.type === "numberInput") {
        if (queryData[qc.clientName] !== "") {
          return true;
        }
      } else {
        conditions.push(queryData[qc.clientName] ? true : false);
      }
    });

  return conditions.includes(false) ? false : true;
}
