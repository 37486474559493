import React, { useState } from "react";
import { Button, Text } from "@mantine/core";
import { shallow } from "zustand/shallow";
import axios from "axios";
import toast from "react-hot-toast";

import useAppStore from "./report-recipe-runner-store";

import qbGenerateRequest from "./qb-generate-request";

export default function ReportRecipeFallback() {
  const [loading, setLoading] = useState(false);
  const [requested, setRequested] = useState(false);
  const { locations, orgs, queryComponents, queryData, recipeInfo } =
    useAppStore(
      (state) => ({
        locations: state.locations,
        orgs: state.orgs,
        queryComponents: state.query_components,
        queryData: state.query_data,
        recipeInfo: state.recipe_info,
      }),
      shallow
    );

  function onClick() {
    setLoading(true);

    const req = {
      ...qbGenerateRequest(queryComponents, queryData),
      report_recipe_id: recipeInfo.id,
      async_csv: true,
    };

    if (locations.length) {
      req.location_ids = locations.map((m) => m.id);
    }

    if (orgs.length) {
      req.organization_ids = orgs.map((m) => m.id);
    }

    axios
      .post(`/report-recipes/${recipeInfo.id}/run-report/`, req)
      .then(() => {
        setLoading(false);
        // toast.success("Be on the lookout for an email");
        setRequested(true);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  if (requested) {
    return (
      <div>
        <Text fw={600}>
          Request received. You will receive an email when your report is ready.
        </Text>
      </div>
    );
  }

  return (
    <div>
      <Text mb="lg" fw={600}>
        We need a little bit more time to prepare this report. Click the button
        below and you will receive an email containing the data you requested.
      </Text>
      <Button onClick={onClick} loading={loading}>
        Prepare report
      </Button>
    </div>
  );
}
