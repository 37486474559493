import React, { useState } from "react";
import { Button } from "@mantine/core";

import { UserCreate, UserManagement } from "@components/User";
import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function UserIndex({ isManager = false }) {
  const [creating, setCreating] = useState(false);
  return (
    <div>
      {!isManager && (
        <Button
          color={entityColor.user}
          leftSection={entityIcon.user()}
          size="xs"
          variant="light"
          mb="xl"
          onClick={() => setCreating(!creating)}
        >
          Create User
        </Button>
      )}
      {creating ? (
        <React.Fragment>
          <UserCreate />
        </React.Fragment>
      ) : (
        <UserManagement />
      )}
    </div>
  );
}
