import React from "react";

import { EmailServerList } from "@components/EmailServer";

export default function EmailServerIndex() {
  return (
    <div>
      <EmailServerList />
    </div>
  );
}
