import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Badge,
  Button,
  Divider,
  Group,
  Card,
  Grid,
  Space,
  Text,
} from "@mantine/core";
import toast from "react-hot-toast";

import { Counter } from "@components/shared";

import { LootItemJoins } from "./";

export default function LootItemQuantity({
  id,
  allocated = 0,
  available = 0,
  joinCount = 0,
  fetchData,
  isForContestDuplication = false,
}) {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(joinCount);
  }, [joinCount]);

  function onSubmit() {
    setLoading(true);

    const req = {
      loot_item_id: id,
      add: value >= joinCount,
      quantity: value <= joinCount ? joinCount - value : value - joinCount,
    };

    axios
      .post(`/loot-items/${id}/adjust-quantity/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Quantity Updated!");
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  if (isForContestDuplication) {
    return (
      <Group mb="xs">
        <div style={{ flexGrow: 1 }}>
          <Counter
            minValue={allocated}
            unlimited={false}
            value={value}
            onChange={(e) => setValue(e)}
          />
        </div>
        <Button
          variant="light"
          size="xs"
          loading={loading}
          onClick={onSubmit}
          ml="lg"
        >
          Save
        </Button>
      </Group>
    );
  }

  return (
    <React.Fragment>
      <Grid>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Card p="xs">
            <Group mb="xs">
              <div style={{ flexGrow: 1 }}>
                <Counter
                  minValue={allocated}
                  unlimited={false}
                  value={value}
                  onChange={(e) => setValue(e)}
                />
              </div>
              <Button
                variant="light"
                size="xs"
                loading={loading}
                onClick={onSubmit}
                ml="lg"
              >
                Save
              </Button>
            </Group>
            <Divider mb="xs" />
            <Group>
              <Badge
                variant="light"
                color={allocated > available ? "red" : "gray"}
              >
                {allocated} allocated
              </Badge>
              <Badge
                variant="light"
                color={allocated > available ? "red" : "gray"}
              >
                {available} available
              </Badge>
              {/* <Badge color="gray">{joinCount} joins</Badge> */}
            </Group>
          </Card>
        </Grid.Col>
      </Grid>
      <Space mt="lg" />
      <LootItemJoins
        lootItemId={id}
        available={joinCount}
        fetchData={fetchData}
      />
    </React.Fragment>
  );
}
