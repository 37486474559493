import React, { useState, useRef } from "react";
import {
  Button,
  Box,
  Flex,
  Table,
  LoadingOverlay,
  TextInput,
  ActionIcon,
} from "@mantine/core";
import axios from "axios";
import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  flexRender,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IconPlayerPlay } from "@tabler/icons-react";

export default function SavedReportRecipeList({
  recipeId,
  onSelect,
  selectedId,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(-1);
  const queryKey = `savedRecipeList`;
  const queryClient = useQueryClient();
  const fetchSavedRecipes = () => queryClient.invalidateQueries([queryKey]);

  const columns = [
    {
      header: () => "",
      accessorKey: "id",
      id: "actions",
      width: 10,
      cell: (info) => (
        <Button
          size="xs"
          color="green"
          radius="xl"
          disabled={selectedId === info.row.original.id}
          onClick={() => {
            onSelect(info.row.original);
          }}
        >
          View
        </Button>
      ),
    },
    {
      header: () => "Title",
      width: 250,
      accessorKey: "title",
      cell: (info) => info.getValue(),
    },
    {
      header: () => "Description",
      // width: 600,
      accessorKey: "description",
      cell: (info) => info.getValue(),
    },
  ];

  const table = useReactTable({
    manualPagination: true,
    pageCount,
    columns,
    data: results,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const { failureReason: error, data: info } = useQuery({
    queryKey: [queryKey, searchValue],
    // refetchOnMount: false,
    queryFn: async () => {
      setLoading(true);
      return axios
        .post(
          `/report-recipes/${recipeId}/saved-recipes/`,
          {
            page_size: table.getState().pagination.pageSize,
            page:
              table.getState().pagination.pageIndex === -1
                ? 0
                : table.getState().pagination.pageIndex,
            search: searchValue,
          },
          {
            cancelToken: new CancelToken(function executor(c) {
              cancelRef.current = c;
            }),
          }
        )
        .then(({ data }) => {
          setLoading(false);
          setResults(
            data.response[0].data.map((m) => ({
              ...m,
            }))
          );
          setPageCount(data.response[0].page_count);
          setTotal(data.response[0].total);
          return data.response[0];
        })
        .catch((err) => {
          setLoading(false);
        });
    },
  });

  return (
    <div style={{ position: "relative" }}>
      <LoadingOverlay
        visible={loading}
        loaderProps={{ color: "dark" }}
        zIndex={99}
      />
      <TextInput
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        placeholder="Search saved recipes"
        mb="sm"
      />
      <Box
        style={{
          maxHeight: "250px",
          minHeight: "250px",
          height: "250px",
          overflowY: "auto",
        }}
      >
        <Table striped>
          {/* <Table.Thead>
          <Table.Tr>
            {table.getFlatHeaders().map((header) => (
              <Table.Th
                key={header.id}
                style={{
                  width: header.column.columnDef.width,
                }}
              >
                <div
                  {...{
                    onClick: header.column.getToggleSortingHandler(),
                    style: {
                      cursor: header.column.getCanSort() ? "pointer" : "",
                    },
                  }}
                >
                  <Group>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Group>
                </div>
              </Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead> */}
          <Table.Tbody>
            {results.length === 0 && (
              <Table.Tr>
                <Table.Td>No saved recipes yet</Table.Td>
              </Table.Tr>
            )}
            {table.getRowModel().rows.map((row) => (
              <Table.Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Table.Td
                    key={cell.id}
                    style={{
                      width: `${cell.column.columnDef.width}px`,
                      // maxWidth: `${cell.column.columnDef.minSize}px`,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Td>
                ))}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Box>
      {pageCount > 1 && (
        <Flex align="center" mt="lg" gap="xs">
          <Button
            size="xs"
            variant="light"
            fullWidth
            disabled={table.getState().pagination.pageIndex === 0}
            onClick={() =>
              table.setPageIndex(table.getState().pagination.pageIndex - 1)
            }
          >
            Back
          </Button>
          <Button
            size="xs"
            fullWidth
            variant="light"
            disabled={table.getState().pagination.pageIndex === pageCount - 1}
            onClick={() =>
              table.setPageIndex(table.getState().pagination.pageIndex + 1)
            }
          >
            Next
          </Button>
        </Flex>
      )}
    </div>
  );
}
