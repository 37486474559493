export const entryMethods = {
  form: "form",
  keyword: "keyword",
  checkin: "checkin",
};

export const entryOptions = [
  { label: "Form", value: entryMethods.form },
  { label: "Keyword (No registration form!)", value: entryMethods.keyword },
  // { label: "Check-in", value: "checkin" },
];
