import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Group,
  Card,
  Modal,
  UnstyledButton,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { EmailValidationHistory, EmailValidationNameplate } from "./";

export default function EmailValidationItem({ id, data, fetchData }) {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
    fetchData();
  }

  return (
    <Card
      component={UnstyledButton}
      style={{
        overflow: "visible",
      }}
    >
      <UnstyledButton onClick={() => setOpen(true)}>
        <EmailValidationNameplate
          title={data.address}
          subtitle={`last checked ${new Date(
            data.last_checked
          ).toLocaleString()}`}
          allowed={data.allowed}
        />
      </UnstyledButton>
      {isOpen && (
        <ItemDetail
          id={id}
          title={data.address}
          fetchData={fetchData}
          onClose={onClose}
        />
      )}
    </Card>
  );
}

const buttonProps = {
  size: "xs",
  color: "gray",
  variant: "light",
};

const ItemDetail = ({ id, onClose }) => {
  const [info, setInfo] = useState(null);

  if (!id) return null;

  useEffect(() => {
    fetchDetail();
  }, []);

  function fetchDetail() {
    axios
      .get(`/email-validations/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setInfo(null);
      });
  }

  function onToggleClick() {
    axios
      .put(`/email-validations/${id}/toggle-allowed/`)
      .then(() => {
        toast.success("Done!");
        fetchDetail();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onValidateClick() {
    axios
      .post(`/email-validations/${id}/re-validate/`)
      .then(() => {
        toast.success("Done!");
        fetchDetail();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onSendTestClick() {
    axios
      .post(`/email-validations/${id}/direct-validation/`)
      .then(() => {
        toast.success("Done!");
        fetchDetail();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Modal title="Email Detail" onClose={onClose} size="xl" opened>
      {info && (
        <React.Fragment>
          <EmailValidationNameplate
            title={info.address}
            allowed={info.allowed}
            subtitle={`last checked ${new Date(
              info.last_checked
            ).toLocaleString()}`}
          />
          <Divider mt="sm" mb="xs" variant="dashed" />
          <Group>
            <Button {...buttonProps} onClick={onToggleClick}>
              {info.allowed ? "Blacklist" : "Whitelist"}
            </Button>
            <Button {...buttonProps} onClick={onValidateClick}>
              Validate
            </Button>
            <Button {...buttonProps} onClick={onSendTestClick}>
              Send Test
            </Button>
          </Group>
          {info.history && info.history.length > 0 && (
            <React.Fragment>
              <Divider mt="sm" mb="xs" variant="dashed" />
              <EmailValidationHistory items={info.history} />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Modal>
  );
};
