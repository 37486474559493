import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button, Card, Select, Alert, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { ContestCreate, ContestForm, ContestList } from "@components/Contest";
import { AssignCreateToggle, ManagementLayout } from "@components/shared";

export default function ContestReplicationIndex() {
  const navigate = useNavigate();

  return (
    <div>
      <ManagementLayout
        createComponent={
          <Card>
            <Title order={4}>Create Contest for Duplication</Title>
            <ContestForm
              onSuccess={(contestId) => navigate(`/contests/${contestId}`)}
            />
          </Card>
        }
        dataComponent={
          <>
            <Alert color="yellow" title="Heads Up!" mb="lg">
              The list below contain contests that have been created for the
              purposes of future duplication. The contests contain no
              organization, location, campaign, or effort information.
            </Alert>
            <ContestList forDuplication />
          </>
        }
      />
    </div>
  );
}
