import React, { useState } from "react";
import axios from "axios";
import { Button, Divider, Group, Modal, Text } from "@mantine/core";
import toast from "react-hot-toast";

import { CurationTitleizeButton } from "./";
import { ArtistForm } from "@components/Artist";

export default function CurateArtist({ artist, onSuccess }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newArtistId, setNewArtistId] = useState(null);

  function onClose() {
    setNewArtistId(null);
    setOpen(false);
    setLoading(false);
  }

  function onSubmit() {
    const req = {
      artist_ids: [artist.id],
    };

    setLoading(true);

    axios
      .post(`/curation-affirmation/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Curated!");
        onClose();
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Button
        color="gray"
        variant="light"
        size="xs"
        onClick={() => setOpen(true)}
      >
        Curate Artist
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <ArtistForm
          isCuration
          name={artist.name}
          id={artist.id}
          onSuccess={() => {
            onClose();
            onSuccess();
          }}
        />
        <Group justify="center" mt="lg">
          <CurationTitleizeButton
            buttonProps={{
              color: "gray",
              size: "xs",
              variant: "subtle",
            }}
            reqData={{
              artist_id: artist.id,
            }}
            onSuccess={() => {
              onClose();
              onSuccess();
            }}
            id={artist.id}
            value={artist.name}
          />
        </Group>
        <Divider mt="lg" mb="lg" />
        <Text mb="lg" size="xl" align="center">
          <b>{artist.name}</b> hasn't been curated yet. Click below to curate.
        </Text>
        <Button fullWidth loading={loading} onClick={onSubmit}>
          Curate Artist
        </Button>
      </Modal>
    </div>
  );
}
