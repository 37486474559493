import React from "react";
import { Flex, Title, ThemeIcon, Text } from "@mantine/core";

export default function PageTitle({
  title,
  subtitle,
  icon,
  iconColor,
  style = {
    marginBottom: "1.5em",
  },
}) {
  return (
    <div style={{ ...style }}>
      <Title order={2}>{title}</Title>
      {(subtitle || icon) && (
        <Flex align="center">
          {icon && (
            <ThemeIcon mr="xs" size="sm" variant="light" color={iconColor}>
              {icon}
            </ThemeIcon>
          )}
          <Text size="sm">{subtitle}</Text>
        </Flex>
      )}
    </div>
  );
}
