import React, { useState } from "react";
import { Button, Divider, Group, Modal, Tabs, Text } from "@mantine/core";

import { CurateSongs, TitlelizeSongs, TransferSongs } from "./";
import entityIcon from "@util/entityIcon";

export default function CurationBulkSongActions({
  items = [],
  onSuccess,
  artistName,
  artistId,
  showCurate = true,
}) {
  const [isOpen, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("transfer");

  function onClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button
        color="gray"
        variant="light"
        size="xs"
        onClick={() => setOpen(true)}
      >
        Select songs
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <Group align="flex-start">
          <div>{entityIcon.artist(36)}</div>
          <div style={{ flexGrow: 1 }}>
            <Text size="md" fw={600}>
              {artistName}
            </Text>
            <Text size="sm" c="dimmed">
              Current Artist
            </Text>
          </div>
        </Group>
        <Divider mt="lg" mb="lg" />
        <Tabs
          value={activeTab}
          onChange={(e) => setActiveTab(e)}
          color="dark"
          keepMounted={false}
        >
          <Tabs.List>
            <Tabs.Tab value="transfer">Transfer</Tabs.Tab>
            {showCurate && <Tabs.Tab value="curate">Curate</Tabs.Tab>}
            <Tabs.Tab value="titleize">Titlelize</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="transfer" mt="lg">
            <TransferSongs
              artistId={artistId}
              artistName={artistName}
              items={items}
              onSuccess={() => {
                onSuccess();
                onClose();
              }}
            />
          </Tabs.Panel>
          {showCurate && (
            <Tabs.Panel value="curate" mt="lg">
              <CurateSongs
                artistId={artistId}
                artistName={artistName}
                items={items}
                onSuccess={() => {
                  onSuccess();
                  onClose();
                }}
              />
            </Tabs.Panel>
          )}
          <Tabs.Panel value="titleize" mt="lg">
            <TitlelizeSongs
              artistId={artistId}
              artistName={artistName}
              items={items}
              onSuccess={() => {
                onSuccess();
                onClose();
              }}
            />
          </Tabs.Panel>
        </Tabs>
      </Modal>
    </div>
  );
}
