import React, { useState, useEffect } from "react";
import { Group, Card, Space, Button, Divider } from "@mantine/core";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { AssetList, AssetUpload } from "./";
import { Header } from "@components/shared";
import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";

export default function AssetManagement({
  campaignId,
  children,
  contestId,
  effortId,
  items = [],
  isIndex,
  library,
  limit = null,
  locationId,
  lootItemId,
  onExternalUrlUpdate = (itemId, newUrl) => true,
  onRemoveSuccess = (itemId) => true,
  onRenameSuccess = (itemId, newName) => true,
  onUploadSuccess = (itemId) => true,
  organizationId,
  preview = null,
  showExternalUrl = true,
  shouldFetch = true,
  title = "Assets",
  lookAndFeelTemplateId,
  uploadOnly = false,
}) {
  const managerInfo = useSelector((state) => state.manager);
  const filteredLibraryVariety = [1, 2, 3, 4, 5, 6].filter((f) =>
    managerInfo ? ![5].includes(f) : true
  );
  const [libraryVariety, setLibraryVariety] = useState(filteredLibraryVariety);
  const [refresh, setRefresh] = useState(false);

  const assets = [...items];

  const filterButtons = [
    {
      text: "Contest",
      library_variety: 1,
      color: entityColor.contest,
      icon: entityIcon.contest(),
    },
    {
      text: "Campaign",
      library_variety: 2,
      color: entityColor.campaign,
      icon: entityIcon.campaign(),
    },
    {
      text: "Effort",
      library_variety: 3,
      color: entityColor.effort,
      icon: entityIcon.effort(),
    },
    {
      text: "General",
      library_variety: 4,
      color: entityColor.general,
      icon: entityIcon.general(),
    },
    {
      text: "Trouble Ticket",
      library_variety: 5,
      color: entityColor.ticket,
      icon: entityIcon.ticket(),
    },
    {
      text: "Email Template",
      library_variety: 6,
      // color: entityColor.ticket,
      // icon: entityIcon.ticket(),
    },
  ]
    .filter((f) => (managerInfo ? ![5].includes(f.library_variety) : true))
    .map((m, i) => ({
      ...m,
      variant:
        libraryVariety.length !== filteredLibraryVariety.length &&
        libraryVariety.includes(m.library_variety)
          ? "filled"
          : "outline",
      key: i,
    }));

  const ids = {
    campaign_id: campaignId,
    contest_id: contestId,
    campaign_effort_id: effortId,
    location_id: locationId,
    organization_id: organizationId,
    loot_item_id: lootItemId,
    look_and_feel_template_id: lookAndFeelTemplateId,
    // variety,
  };

  function onRemove(itemId) {
    onRemoveSuccess(itemId);
    setRefresh(!refresh);
  }

  const reqData = Object.keys(ids).reduce((acc, cur) => {
    if (ids[cur]) {
      acc[cur] = ids[cur];
    }
    return acc;
  }, {});

  function setFilter(b) {
    let newVariety = [...libraryVariety];

    if (newVariety.length === filteredLibraryVariety.length) {
      return setLibraryVariety([b]);
    }

    if (JSON.stringify(newVariety) === JSON.stringify([b])) {
      return setLibraryVariety(filteredLibraryVariety);
    }

    if (newVariety.includes(b)) {
      newVariety = newVariety.filter((f) => f !== b);
    } else {
      newVariety = [...newVariety, b];
    }

    if (newVariety.length > 0) {
      setLibraryVariety(newVariety);
    }
  }

  if (uploadOnly) {
    return (
      <Group>
        <AssetUpload
          library={library}
          reqData={reqData}
          onSuccess={(newAsset) => {
            // fetchData();
            onUploadSuccess(newAsset.id);
          }}
        />
        {children && <React.Fragment>{children}</React.Fragment>}
      </Group>
    );
  }

  return (
    <StyledAssets>
      <Group mt="xl">
        {preview && <React.Fragment>{preview(assets)}</React.Fragment>}
        <Header>{title}</Header>
        {(!limit || (limit && assets.length < limit)) && (
          <AssetUpload
            library={library}
            reqData={reqData}
            onSuccess={(newAsset) => {
              setRefresh(!refresh);
              onUploadSuccess(newAsset.id);
            }}
          />
        )}
        {children && <React.Fragment>{children}</React.Fragment>}
      </Group>
      <Divider mt="lg" mb="sm" />
      {library && (
        <Group
          mb="sm"
          pt="xl"
          position="start"
          spacing="xs"
          title="Filter Assets"
        >
          <Button
            size="xs"
            onClick={() =>
              setLibraryVariety(filterButtons.map((m) => m.library_variety))
            }
            variant={
              libraryVariety.length === filterButtons.length
                ? "filled"
                : "outline"
            }
          >
            All
          </Button>
          {filterButtons.map((b, i) => (
            <Button
              key={i}
              variant={b.variant}
              size="xs"
              color={b.color}
              onClick={() => setFilter(b.library_variety)}
            >
              {b.icon && <div style={{ marginRight: "3px" }}>{b.icon}</div>}
              {b.text}
            </Button>
          ))}
        </Group>
      )}
      <Card
        style={{
          overflow: "visible",
        }}
      >
        <AssetList
          assets={
            library
              ? assets.filter((f) => libraryVariety.includes(f.variety))
              : assets
          }
          campaignId={campaignId}
          contestId={contestId}
          effortId={effortId}
          isIndex={isIndex}
          library={library}
          limit={limit}
          locationId={locationId}
          lootItemId={lootItemId}
          organizationId={organizationId}
          refresh={refresh}
          shouldFetch={shouldFetch}
          lookAndFeelTemplateId={lookAndFeelTemplateId}
          variety={libraryVariety}
          showExternalUrl={showExternalUrl}
          onRemoveSuccess={(itemId) => onRemove(itemId)}
          onRenameSuccess={(itemId, newName) =>
            onRenameSuccess(itemId, newName)
          }
          onExternalUrlUpdate={(itemId, newUrl) =>
            onExternalUrlUpdate(itemId, newUrl)
          }
          onRefresh={() => setRefresh(!refresh)}
        />
      </Card>
    </StyledAssets>
  );
}

const StyledAssets = styled.div`
  & + & {
    margin-top: 2em;
  }
`;
