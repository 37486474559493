import React, { useState, useRef } from "react";
import {
  Avatar,
  Button,
  Box,
  Divider,
  Group,
  Image,
  FileButton,
  Modal,
} from "@mantine/core";
import toast from "react-hot-toast";
import axios from "axios";
import AvatarEditor from "react-avatar-editor";

export default function AvatarManagement({
  asset,
  adminId,
  userLocationId,
  managerId,
  onSuccess,
}) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cropSettings, setCropSettings] = useState({
    scale: 1.2,
  });

  const imageWidth = userLocationId ? 125 : 80;

  function onClose() {
    if (loading) return;
    setFile(null);
  }

  const editor = useRef(null);

  function onSaveClick() {
    if (!editor) return;
    const formData = new FormData();
    const canvasScaled = editor.current.getImageScaledToCanvas();

    const blob = dataURItoBlob(canvasScaled.toDataURL());
    const tempfile = new File([blob], "profile.png", {
      type: "image/png",
    });

    formData.append("tempfile", tempfile);
    formData.append("variety", 9);
    if (adminId) formData.append("admin_id", adminId);
    if (managerId) formData.append("manager_id", managerId);
    if (userLocationId) formData.append("user_location_id", userLocationId);

    setLoading(true);

    axios
      .post(`/assets/`, formData)
      .then(() => {
        if (onSuccess) {
          toast.success("Uploaded!");
          setLoading(false);
          onClose();
          return onSuccess();
        }

        window.location.reload();
        // toast.success("Uploaded!");
        // setLoading(false);
        // onClose();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <div>
      <Modal opened={file ? true : false} onClose={onClose}>
        {file && (
          <React.Fragment>
            <React.Fragment>
              <Box>
                <Group justify="center">
                  <AvatarEditor
                    ref={editor}
                    image={file}
                    width={250}
                    height={250}
                    border={5}
                    color={[255, 1, 1, 0.49]} // RGBA
                    scale={cropSettings.scale}
                    rotate={0}
                  />
                </Group>
              </Box>
              <Box mt="md">
                <Group>
                  Zoom{" "}
                  <input
                    name="scale"
                    type="range"
                    onChange={(e) => {
                      setCropSettings({
                        ...cropSettings,
                        scale: e.target.value,
                      });
                    }}
                    min="1"
                    max="2"
                    step="0.01"
                    defaultValue="1"
                  />
                </Group>
              </Box>
              <Divider mt="lg" mb="lg" />
              <Group>
                <Button loading={loading} onClick={onSaveClick} fullWidth>
                  Save
                </Button>
              </Group>
              <Group justify="center" mt="md">
                <Button
                  color="gray"
                  onClick={() => setFile(null)}
                  variant="subtle"
                  size="xs"
                >
                  Cancel
                </Button>
              </Group>
            </React.Fragment>
          </React.Fragment>
        )}
      </Modal>
      <FileButton onChange={setFile} accept="image/png,image/jpeg">
        {(props) => {
          return (
            <Avatar
              height={imageWidth}
              width={imageWidth}
              radius="100%"
              size="xl"
              src={asset ? asset.filename_url : ""}
              style={{
                cursor: "pointer",
              }}
              {...props}
            />
          );
        }}
      </FileButton>
    </div>
  );
}

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}
