import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CheckIcon,
  Divider,
  Flex,
  Group,
  ColorSwatch,
  LoadingOverlay,
  Switch,
  Title,
  useMantineTheme,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { AssetUpload, AssetLibraryPicker, AssetList } from "@components/Asset";
import { StationStreamManagement } from "@components/Station";
import { CustomTooltip, EntityBannerImages } from "@components/shared";

const titleProps = {
  mb: "sm",
  order: 4,
};

export default function EntitySettingsCode({ locationId, organizationId }) {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [settings, setSettings] = useState(defaultSettings);
  const [bannerImages, setBannerImages] = useState([]);
  const [timezone, setTimezone] = useState(null);
  const theme = useMantineTheme();

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    setLoading(true);

    axios
      .get(
        organizationId
          ? `/organizations/${organizationId}/`
          : `/locations/${locationId}/`
      )
      .then(({ data }) => {
        if (data.response[0].settings_code) {
          setSettings({
            ...settings,
            ...data.response[0].settings_code.settings.settings,
          });
        }
        if (data.response[0].banner_images) {
          setBannerImages(data.response[0].banner_images);
        }
        if (data.response[0].timezone) {
          setTimezone(data.response[0].timezone);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function onSaveClick() {
    const req = {
      location_id: locationId,
      organization_id: organizationId,
      settings,
    };

    setSaving(true);

    axios
      .put(
        organizationId
          ? `/organizations/${organizationId}/site-settings/`
          : `/locations/${locationId}/site-settings/`,
        req
      )
      .then(() => {
        setSaving(false);
        toast.success("Saved!");
      })
      .then(() => {
        fetchSettings();
      })
      .catch((err) => {
        setSaving(false);
        toast.error(err);
      });
  }

  const swatches = Object.keys(theme.colors)
    .filter((f) => f !== "dark")
    .map((color) => (
      <ColorSwatch
        component="button"
        onClick={() => {
          setSettings({
            ...settings,
            color: color,
          });
        }}
        style={{
          cursor: "pointer",
        }}
        key={color}
        color={theme.colors[color][6]}
      >
        {settings.color === color && <CheckIcon width="12px" c="white" />}
      </ColorSwatch>
    ));

  return (
    <div>
      <Card pos="relative">
        <LoadingOverlay visible={loading} />
        <Flex>
          <Title {...titleProps}>Button & Action Color</Title>
          <Button
            variant="subtle"
            size="xs"
            color="gray"
            onClick={() =>
              setSettings({
                ...settings,
                color: "blue",
              })
            }
          >
            revert to default
          </Button>
        </Flex>
        <Group spacing="xs">{swatches}</Group>
        <Divider mt="lg" mb="lg" />
        <CustomTooltip label="These banners will scroll across the top of a member's profile page. Suggested size is 1200x600 or 1200x800.">
          <Title {...titleProps} mb={0}>
            Banner Images
          </Title>
        </CustomTooltip>
        <EntityBannerImages
          bannerImages={bannerImages}
          fetchSettings={fetchSettings}
          locationId={locationId}
          organizationId={organizationId}
          timezone={timezone}
        />
        {locationId && (
          <>
            <Divider mt="lg" mb="lg" />
            <StationStreamManagement
              value={settings.stream_url}
              onChange={(e) =>
                setSettings({
                  ...settings,
                  stream_url: e,
                })
              }
            />
            <Switch
              label="Autoplay Stream"
              mt="sm"
              checked={settings.autoplay_stream === true}
              // value={settings.autoplay_stream}
              onChange={() => {
                setSettings({
                  ...settings,
                  autoplay_stream: [undefined, false].includes(
                    settings.autoplay_stream
                  )
                    ? true
                    : false,
                });
              }}
            />
          </>
        )}
      </Card>
      <Button mt="lg" loading={saving} onClick={onSaveClick}>
        Save
      </Button>
    </div>
  );
}

export const defaultSettings = {
  autoplay_stream: false,
  color: "blue",
  stream_url: "",
};
