import React, { useState } from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Title,
  Modal,
  Grid,
  Text,
  TypographyStylesProvider,
} from "@mantine/core";
import entityIcon from "@util/entityIcon";
import styled from "styled-components";
import { useMediaQuery } from "@mantine/hooks";
import { IconPhoto } from "@tabler/icons-react";

import { HorizontalList } from "@components/shared";

export default function GalleryEffortList({ items = [] }) {
  const matches = useMediaQuery("(min-width: 900px)");

  if (!items.length)
    return (
      <Text align="center" fw={600}>
        Check back soon for content!
      </Text>
    );

  return (
    <Grid gap="xl">
      {items.map((item, i) => (
        <Item
          key={item.id}
          badgeText={`${i + 1} of ${items.length}`}
          image={item.featured_image_url}
          title={item.title}
          dates={`${item.start_date_formatted}-${item.end_date_formatted}`}
          url={`/entry/${item.url_code}`}
          description={item.description}
        />
      ))}
    </Grid>
  );
}

const StyledItem = styled.div`
  position: relative;

  img {
    max-height: 350px;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .mantine-Badge-root {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "&hellip;" : str;
}

const Item = ({ image, badgeText, title, dates, url, description }) => {
  const [isOpen, setOpen] = useState(false);

  const LinkButton = () => (
    <Button
      component="a"
      href={url}
      target="_blank"
      fullWidth
      size="sm"
      // variant="light"
    >
      Enter
    </Button>
  );

  return (
    <Grid.Col span={{ base: 12, md: 4 }} mb="sm">
      <Modal opened={isOpen} onClose={() => setOpen(false)}>
        <Title mb="sm" order={4} align="center">
          {title}
        </Title>
        {description && (
          <TypographyStylesProvider
            style={{
              paddingLeft: "0",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </TypographyStylesProvider>
        )}
        <Divider mt="sm" mb="sm" />
        <LinkButton />
      </Modal>
      <StyledItem>
        <Card
          style={{
            overflow: "hidden",
            [`--card-padding`]: 0,
          }}
        >
          <Badge>{badgeText}</Badge>
          <Card.Section mb="xs">
            {image ? (
              <img
                src={image}
                style={{
                  height: "300px",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center center",
                }}
              />
            ) : (
              <div
                style={{
                  height: "306px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconPhoto />
              </div>
            )}
          </Card.Section>
          <Flex gap="lg" direction={"column"}>
            <Box>
              <Text fw={600} size="xl" align="center">
                {title}
              </Text>
              <Flex mb="sm" align="center" gap="xs" justify="center">
                {entityIcon.calendar()}
                <Text size="xs" c="dimmed">
                  {dates}
                </Text>
              </Flex>
              {/* {item.description ? (
              <div style={{ height: "100px", overflow: "hidden" }}>
                <TypographyStylesProvider
                  style={{
                    paddingLeft: "0",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                </TypographyStylesProvider>
              </div>
            ) : (
              <div
                style={{ height: "100px", overflow: "hidden" }}
              ></div>
            )} */}
              <Divider mt="sm" mb="sm" />
              <Flex align="center" gap="xs">
                <Button
                  onClick={() => setOpen(true)}
                  variant="light"
                  fullWidth
                  size="sm"
                  p={0}
                >
                  Read More
                </Button>
                <LinkButton />
              </Flex>
            </Box>
          </Flex>
        </Card>
      </StyledItem>
    </Grid.Col>
  );
};
