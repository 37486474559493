import React, { useState } from "react";

import { ApiUseList } from "./";
import { PageTitle } from "@components/shared";

export default function ApiUseManagement() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const reqData = {};

  return (
    <div>
      <PageTitle
        title="Third Party Api Requests"
        style={{ marginBottom: "0" }}
      />
      <ApiUseList
        loading={loading}
        refresh={refresh}
        requestInfo={{
          url: `/retrieve-api-uses/`,
          data: reqData,
        }}
      />
    </div>
  );
}
