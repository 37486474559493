import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Badge, Flex, Card, Select, Title } from "@mantine/core";
import toast from "react-hot-toast";
import axios from "axios";

export default function LocationSongSongQuery({
  locationId,
  locationSongId,
  onSuccess,
  currentValue,
  variety,
  title,
}) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchOptions();

    if (currentValue) {
      setValue(currentValue.id);
    }
  }, [JSON.stringify(currentValue)]);

  function fetchOptions() {
    const req = {
      location_id: locationId,
      pagination: true,
      search_value: "",
      variety,
      page_size: 500,
      page: 0,
      status: 1,
    };

    axios
      .post(`/retrieve-song-queries/`, req)
      .then(({ data }) => {
        setOptions(
          data.response[0].data.map((d) => ({
            label: d.name,
            value: d.id,
          }))
        );
      })
      .catch((err) => {
        setOptions([]);
      });
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      song_query_id: value,
      location_song_join: locationSongId,
    };

    axios
      .post(`/add-song-to-query/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Added");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Card>
      <Flex gap="xs" mb="xs" align="center">
        <Title order={4}>{title}</Title>
        {currentValue && (
          <Badge
            size="xs"
            component={Link}
            to={`/song-categories/${currentValue.id}`}
            color="gray"
            variant="light"
            style={{ cursor: "pointer" }}
          >
            view {currentValue.name}
          </Badge>
        )}
      </Flex>
      <Flex gap="xs">
        <Select
          searchable
          placeholder="Pick one..."
          value={value}
          onChange={(e) => setValue(e)}
          data={options}
          style={{ flexGrow: 1 }}
          allowDeselect={false}
        />
        <Button
          style={{
            width: "fit-content",
          }}
          loading={loading}
          onClick={onSubmit}
          disabled={!value}
        >
          Save
        </Button>
      </Flex>
    </Card>
  );
}
