import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingModule from "highcharts/modules/exporting";

exportingModule(Highcharts);

export default function PieChart({
  seriesData = [],
  subtitle = "",
  title = "",
}) {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            height: "350px",
            type: "pie",
            style: {
              fontFamily: "inherit",
            },
          },
          title: {
            text: title,
          },
          tooltip: {
            valueSuffix: "",
          },
          subtitle: {
            text: subtitle,
          },
          plotOptions: {
            series: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: [
                {
                  enabled: true,
                  distance: 20,
                },
                {
                  enabled: true,
                  distance: -40,
                  format: "{point.percentage:.1f}%",
                  style: {
                    fontSize: "1.2em",
                    textOutline: "none",
                    opacity: 0.7,
                  },
                  filter: {
                    operator: ">",
                    property: "percentage",
                    value: 10,
                  },
                },
              ],
            },
          },
          series: seriesData,
          credits: {
            enabled: false,
          },
        }}
      />
    </div>
  );
}
