import React, { useState } from "react";
import axios from "axios";
import { Button, Divider, Group, Modal, Text } from "@mantine/core";
import toast from "react-hot-toast";

import { CurationSearch } from "./";
import entityIcon from "@util/entityIcon";

export default function TransferSong({
  artist,
  onSuccess,
  redirect,
  songId,
  songInfo,
  songName,
}) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newArtistId, setNewArtistId] = useState(null);
  const [newSongId, setNewSongId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  function onClose() {
    setNewArtistId(null);
    setNewSongId(null);
    setOpen(false);
    setLoading(false);
    setShowConfirm(false);
  }

  function onSubmit() {
    const req = {};

    setLoading(true);

    const url = newSongId
      ? `/curation-associate-song/`
      : "/curation-associate-songs-to-artist/";

    if (newSongId) {
      req.from_song_id = songId;
      req.to_song_id = newSongId;
    }

    if (newArtistId) {
      req.song_ids = [songId];
      req.to_artist_id = newArtistId;
      req.from_artist_id = artist.id;
    }

    axios
      .post(url, req)
      .then(() => {
        if (newArtistId || redirect === false) {
          setLoading(false);
          toast.success("Transferred!");
          onClose();
          onSuccess();
        } else {
          window.location = `/curation`;
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Button
        color="gray"
        variant="light"
        size="xs"
        onClick={() => setOpen(true)}
      >
        Transfer
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <>
          <Group align="flex-start">
            <div>{entityIcon.song(36)}</div>
            <div style={{ flexGrow: 1 }}>
              <Text size="md" fw={600}>
                {songName}
              </Text>
              {artist && (
                <Text size="sm" c="dimmed">
                  {artist.name}
                </Text>
              )}
            </div>
          </Group>
          <Divider mt="lg" mb="lg" />
          <CurationSearch
            isSong
            onChange={(e) => {
              setNewSongId(e);
              setNewArtistId(null);
              setShowConfirm(false);
            }}
            songId={songId}
            value={newSongId}
          />
          <Divider mt="lg" mb="lg" />
          <CurationSearch
            artistId={artist.id}
            isArtist
            onChange={(e) => {
              setNewSongId(null);
              setNewArtistId(e);
              setShowConfirm(false);
            }}
            value={newArtistId}
          />
          <Divider mt="lg" mb="lg" />
          {showConfirm ? (
            <>
              <Text fw={600} mb="sm" align="center">
                Are you sure you want to do this?
              </Text>
              {songInfo && (
                <Text mb="lg" align="center">
                  You will{" "}
                  <b>
                    transfer {songInfo.plays_count} play(s) and{" "}
                    {songInfo.ratings_count} rating(s)
                  </b>{" "}
                  from {songName} to the selected option.
                </Text>
              )}
              <Button fullWidth color="red" mb="sm" onClick={onClose}>
                Get me out of here!
              </Button>
              <Group justify="center">
                <Button
                  size="xs"
                  variant="subtle"
                  color="gray"
                  onClick={onSubmit}
                  loading={loading}
                >
                  I'm sure
                </Button>
              </Group>
            </>
          ) : (
            <Button
              fullWidth
              loading={loading}
              onClick={() => setShowConfirm(true)}
              disabled={!newArtistId && !newSongId}
            >
              Transfer
            </Button>
          )}
        </>
      </Modal>
    </div>
  );
}
