import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select } from "@mantine/core";

import SectionTitle from "./SectionTitle";
import entityIcon from "@util/entityIcon";

import { iconSize } from "./helpers";

const EffortSelect = ({ onChange, value = null, campaignId }) => {
  const [efforts, setEfforts] = useState([]);

  useEffect(() => {
    fetchData();
  }, [campaignId]);

  function fetchData() {
    axios
      .post(`/legacy-report-efforts/`, {
        campaign_id: campaignId,
        page: 0,
        page_size: 1000,
        engagement: "all",
        award: "all",
        status: "entire",
        variety: "all",
      })
      .then(({ data }) => {
        const arr = data.response;
        // if (arr.length) {
        //   const liveEffort = arr.find((f) => f.is_live);
        //   onChange(liveEffort ? liveEffort.id : arr[0].id);
        // }
        setEfforts(
          arr.map((m) => ({
            label: m.title,
            value: m.id,
          }))
        );
      })
      .catch((err) => {
        setEfforts([]);
      });
  }

  return (
    <React.Fragment>
      {/* <SectionTitle title="Effort" icon={entityIcon.effort(iconSize)} /> */}
      <Select
        searchable
        clearable
        data={efforts}
        limit={25}
        label="Start typing to search efforts"
        onClear={() => onChange("")}
        placeholder="Search efforts"
        onChange={(e) => onChange(e)}
        value={value}
        size="sm"
      />
    </React.Fragment>
  );
};

export default EffortSelect;
