import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingModule from "highcharts/modules/exporting";

exportingModule(Highcharts);

export default function LineChart({
  markerEnabled,
  pointInterval,
  pointIntervalUnit,
  pointStart,
  seriesData = [],
  subtitle = "",
  timezone = "America/New_York",
  title = "",
  yAxisTitle = "",
}) {
  Highcharts.setOptions({
    time: {
      timezone,
    },
  });

  const options = {
    chart: {
      style: {
        fontFamily: "inherit",
      },
    },
    title: {
      text: title,
      align: "left",
    },
    subtitle: {
      text: subtitle,
      align: "left",
    },
    xAxis: {
      type: "datetime",
      // labels: {
      //   formatter: function () {
      //     const hours = Highcharts.dateFormat("%H", this.value),
      //       suf = hours < 12 ? "AM" : "PM";

      //     return Highcharts.dateFormat("%l", this.value) + " " + suf;
      //   },
      // },
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },
    legend: {
      enabled: seriesData.length > 1,
      layout: "vertical",
      align: "center",
      verticalAlign: "bottom",
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        pointStart: pointStart,
        pointInterval: pointInterval,
        pointIntervalUnit: pointIntervalUnit,
        lineWidth: 3,
        animation: {
          duration: 1000,
        },
        marker: {
          enabled: markerEnabled ? markerEnabled : true,
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
