export default function qbGenerateRequest(queryComponents, queryData) {
  // if (!queryComponents) return true;
  const req = {
    query_options: 1,
    visualizer: queryData.visualizer,
  };

  queryComponents.forEach((qc) => {
    if (Array.isArray(queryData[qc.clientName])) {
      req[qc.clientName] = queryData[qc.clientName].map((m) => {
        if (typeof m === "object") {
          return m.value;
        } else {
          return m;
        }
      });
    } else {
      req[qc.clientName] = queryData[qc.clientName];
    }
  });

  return req;
}
