import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Modal, Flex, Text, Button } from "@mantine/core";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { IconExternalLink } from "@tabler/icons-react";

import { LookAndFeelForm } from "@components/shared/LookAndFeelManagement";
import { ErrorView, PageTitle, MicrositeSettings } from "@components/shared";

export default function LookAndFeelDetail() {
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [info, setInfo] = useState(null);
  const [saving, setSaving] = useState(false);
  const params = useParams();
  const { id } = params;
  let [searchParams, setSearchParams] = useSearchParams();

  const refererIds = {
    effortId: searchParams.get("effortId"),
    campaignId: searchParams.get("campaignId"),
    contesetId: searchParams.get("contestId"),
  };

  const hasRefererId = Object.keys(refererIds)
    .map((m) => refererIds[m])
    .find((f) => f !== undefined)
    ? true
    : false;

  const refererConfig = hasRefererId
    ? refererIds.effortId
      ? {
          entity: "effort",
          path: `/efforts/${refererIds.effortId}`,
        }
      : refererIds.campaignId
      ? {
          entity: "campaign",
          path: `/campaigns/${refererIds.campaignId}`,
        }
      : refererIds.contesetId
      ? {
          entity: "contest",
          path: `/contests/${refererIds.contestId}`,
        }
      : null
    : null;

  useEffect(() => {
    fetchData();
  }, [id]);

  function fetchData() {
    axios
      .get(`/look-and-feel-templates/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  function onUpdate(formData) {
    const req = {
      ...formData,
      look_and_feel_template_id: id,
    };

    setSaving(true);

    axios
      .put(`/look-and-feel-templates/${id}/meta-data/`, req)
      .then(() => {
        toast.success("Updated!");
        setSaving(false);
        onClose();
        fetchData();
      })
      .catch((err) => {
        setSaving(false);
        toast.error(err);
      });
  }

  function onClose() {
    setEditing(false);
    setSaving(false);
  }

  return (
    <div>
      <Modal opened={editing} onClose={onClose}>
        <LookAndFeelForm
          title={info.title}
          description={info.description}
          onSubmit={onUpdate}
          loading={saving}
        />
      </Modal>
      <PageTitle title={info.title} subtitle={"Look And Feel Template"} />
      <Text
        style={{
          width: "80%",
        }}
      >
        {info.description}
      </Text>
      <MicrositeSettings lookAndFeelTemplateId={id} onUpdate={() => true} />
      <Flex gap="sm">
        <Button
          variant="light"
          color="gray"
          size="xs"
          mt="lg"
          onClick={() => setEditing(true)}
        >
          Edit Name & Description
        </Button>
        {hasRefererId && (
          <Button
            component={Link}
            color="gray"
            variant="light"
            to={refererConfig.path}
            mt="lg"
            size="xs"
            rightSection={<IconExternalLink size={16} />}
          >
            Return to {refererConfig.entity}
          </Button>
        )}
      </Flex>
    </div>
  );
}
