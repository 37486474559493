import React, { useState } from "react";
import { Box, Modal, Button, Divider, Flex, Text, Table } from "@mantine/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { BulkCommunicationButton } from "./";

export default function BulkCommunicationStatus({
  id,
  fetchData,
  fetchStatus,
  status,
  statusInfo,
  info,
}) {
  return (
    <>
      <Flex
        direction="column"
        style={{
          height: "100%",
        }}
      >
        <Flex gap="xs">
          {statusInfo.buttons_to_show.includes("start") && (
            <>
              <BulkCommunicationButton.Schedule
                id={id}
                isStart
                onSuccess={fetchData}
                timezone={info.timezone}
                reqData={{
                  bulk_communication_id: id,
                  timezone: info.timezone,
                }}
              />
            </>
          )}
          {statusInfo.buttons_to_show.includes("remove-from-queue") && (
            <BulkCommunicationButton.Enqueue
              id={id}
              onSuccess={() => {
                fetchData();
                fetchStatus();
              }}
              dequeue
              reqData={{
                bulk_communication_id: id,
              }}
            />
          )}
          {statusInfo.buttons_to_show.includes("enqueue") && (
            <BulkCommunicationButton.Enqueue
              id={id}
              onSuccess={() => {
                fetchData();
                fetchStatus();
              }}
              disabled={!statusInfo.template_data_validated}
              reqData={{
                bulk_communication_id: id,
              }}
            />
          )}
          {statusInfo.buttons_to_show.includes("stop") && (
            <BulkCommunicationButton.Stop
              id={id}
              onSuccess={() => {
                fetchData();
                fetchStatus();
              }}
              reqData={{
                bulk_communication_id: id,
              }}
            />
          )}
        </Flex>
        {statusInfo.buttons_to_show.length > 0 && <Divider mt="sm" mb="lg" />}
        <Errors items={info.error_data.errors} />
        <div style={{ flexGrow: 1 }}>
          <Text fw={600} mb="xs" size="xs">
            Additional Info
          </Text>
          <Table striped>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td>Variety</Table.Td>
                <Table.Td>{info.variety_formatted}</Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Timezone</Table.Td>
                <Table.Td>{info.timezone}</Table.Td>
              </Table.Tr>
              {statusInfo.bulk_communicator_messages_count !== 0 && (
                <Table.Tr>
                  <Table.Td>Messages</Table.Td>
                  <Table.Td>
                    {statusInfo.bulk_communicator_messages_count}
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Tbody>
          </Table>
        </div>
        {statusInfo.enqueued_at && (
          <div style={{ flexGrow: 1 }}>
            <Text fw={600} mb="xs" size="xs">
              Important events
            </Text>
            <Table striped>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td>Enqueued</Table.Td>
                  <Table.Td>
                    {new Date(statusInfo.enqueued_at).toLocaleString("en-US", {
                      timeZone: info.timezone,
                    })}
                    {statusInfo.enqueued_by.full_name
                      ? ` by ${statusInfo.enqueued_by.full_name} (${statusInfo.enqueued_by.class_name})`
                      : ""}
                  </Table.Td>
                </Table.Tr>
                {statusInfo.scheduled_at && (
                  <Table.Tr>
                    <Table.Td>Scheduled</Table.Td>
                    <Table.Td>
                      {new Date(statusInfo.scheduled_at).toLocaleString(
                        "en-US",
                        { timeZone: info.timezone }
                      )}
                    </Table.Td>
                  </Table.Tr>
                )}
                {statusInfo.started_at && (
                  <Table.Tr>
                    <Table.Td>Started</Table.Td>
                    <Table.Td>
                      {new Date(statusInfo.started_at).toLocaleString("en-US", {
                        timeZone: info.timezone,
                      })}
                      {statusInfo.started_by.full_name
                        ? ` by ${statusInfo.started_by.full_name} (${statusInfo.started_by.class_name})`
                        : ""}
                    </Table.Td>
                  </Table.Tr>
                )}
              </Table.Tbody>
            </Table>
          </div>
        )}
      </Flex>
    </>
  );
}

const Errors = ({ items = [] }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  if (items.length === 0) return null;

  const chartDataObject = [...items].reduce((acc, cur) => {
    if (!acc[cur.ErrorCode]) {
      acc[cur.ErrorCode] = {
        name: cur.ErrorCode,
        y: 1,
      };
    } else {
      acc[cur.ErrorCode].y += 1;
    }
    return acc;
  }, {});
  const chartData = Object.keys(chartDataObject).map((m) => ({
    name: m,
    y: chartDataObject[m].y,
  }));

  return (
    <Box mb="xs">
      <Button
        onClick={() => setOpen(true)}
        variant="subtle"
        c="red"
        p={0}
        size="xs"
      >
        We found {items.length} error{items.length !== 1 ? "s" : ""}!
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <Text fw={600} size="xs">
          Error Breakdown
        </Text>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              height: "300px",
              type: "pie",
              style: {
                fontFamily: "inherit",
              },
            },
            title: {
              text: "",
            },
            tooltip: {
              valueSuffix: "",
            },
            subtitle: {
              text: "",
            },
            plotOptions: {
              series: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: [
                  {
                    enabled: true,
                    distance: 20,
                  },
                  {
                    enabled: true,
                    distance: -40,
                    format: "{point.percentage:.1f}%",
                    style: {
                      fontSize: "1.2em",
                      textOutline: "none",
                      opacity: 0.7,
                    },
                    filter: {
                      operator: ">",
                      property: "percentage",
                      value: 10,
                    },
                  },
                ],
              },
            },
            series: [
              {
                name: "Total",
                colorByPoint: true,
                data: chartData,
              },
            ],
            credits: {
              enabled: false,
            },
          }}
        />
        <Divider mt="lg" mb="lg" />
        {items.map((e, i) => (
          <div key={i}>
            <Text size="sm" mb="xs">
              {e.Message}
            </Text>
            <Text size="sm" c="dimmed" align="right">
              Error Code {e.ErrorCode}
            </Text>
            {i < items.length - 1 && <Divider mt="lg" mb="lg" />}
          </div>
        ))}
      </Modal>
    </Box>
  );
};
