import React from "react";
import { useSelector } from "react-redux";

import { EffortManagement } from "@components/Effort";

export default function EffortIndex() {
  const managerInfo = useSelector((state) => state.manager);

  return (
    <React.Fragment>
      <EffortManagement
        organizationId={managerInfo.organization_id}
        locationId={managerInfo.location_id}
      />
    </React.Fragment>
  );
}
