import React from "react";

import { Group, Text } from "@mantine/core";
import entityIcon from "@util/entityIcon";

export default function ContestPerson({
  badge,
  email,
  locationName,
  name,
  phone,
}) {
  return (
    <div>
      <Group mb="5px">
        <Text size="lg" fw={600}>
          {name}
        </Text>
        {badge && <React.Fragment>{badge}</React.Fragment>}
      </Group>
      <Group>
        {entityIcon.phone()}
        <Text>{phone}</Text>
      </Group>
      <Group>
        {entityIcon.email()}
        <Text>{email}</Text>
      </Group>
      {locationName && (
        <Group>
          {entityIcon.location()}
          <Text>{locationName}</Text>
        </Group>
      )}
    </div>
  );
}
