import React, { useContext, useState, useEffect } from "react";
import { Group } from "@mantine/core";

import { Counter } from "@components/shared";
import { Context } from "./";

export default function GeneralSettings({
  pointsOnly,
  onUpdate,
  wizardSettings,
}) {
  const isFormRegistration = wizardSettings.registration_method === "form";
  const { total_entries_allowed: totalEntriesAllowed, value } =
    useContext(Context);

  const counterFields = [
    {
      title: "Total Points Allowed",
      keyName: "total_entries_allowed",
      value: totalEntriesAllowed,
    },
    {
      title: "Points awarded",
      keyName: "value",
      value,
      max: isFormRegistration ? 30 : 20,
    },
  ]
    .filter((f) =>
      pointsOnly ? f.title === "Points awarded" : f.title !== "Points awarded"
    )
    .map((m, i) => ({
      ...m,
      key: i + 1,
    }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            value={c.value}
            unlimited={false}
            maxValue={c.max}
            onChange={(e) => {
              if (c.keyName === "value") {
                if (isFormRegistration) {
                  if (e > 30) {
                    onUpdate({
                      [c.keyName]: 30,
                    });
                  } else {
                    onUpdate({
                      [c.keyName]: e,
                    });
                  }
                } else {
                  if (e > 20) {
                    onUpdate({
                      [c.keyName]: 20,
                    });
                  } else {
                    onUpdate({
                      [c.keyName]: e,
                    });
                  }
                }
              } else {
                onUpdate({
                  [c.keyName]: e,
                });
              }
            }}
          />
          <h3 style={{ margin: "0" }}>{c.title}</h3>
        </Group>
      ))}
    </div>
  );
}
