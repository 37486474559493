const getGeoLocation = () => {
  return new Promise(function (resolve, reject) {
    // Promisifying the geolocation API
    navigator.geolocation.getCurrentPosition(
      (position) =>
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }),
      (error) => resolve(null),
      {
        enableHighAccuracy: true,
        maximumAge: 0,
      }
    );
  });
};

export default getGeoLocation;
