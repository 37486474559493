import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "@mantine/core";

export default function CurateButton({
  buttonProps = {
    color: "green",
    mt: "lg",
  },
  buttonText = "Curate",
  onSuccess,
  reqData,
}) {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);
    const req = { ...reqData };

    axios
      .post(`/curation-affirmation/`, req)
      .then(() => {
        toast.success("Curated!");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button {...buttonProps} onClick={onClick} loading={loading}>
      {buttonText}
    </Button>
  );
}
