import React from "react";
import { ColorPicker, TextInput, Stack } from "@mantine/core";

export default function index({ value, onChange, swatches = [] }) {
  return (
    <Stack>
      <TextInput onChange={(e) => onChange(e.target.value)} value={value} />
      <ColorPicker
        value={value}
        onChange={onChange}
        size="lg"
        swatches={swatches}
        fullWidth
      />
    </Stack>
  );
}
