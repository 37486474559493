import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Button, Grid } from "@mantine/core";

import { getUuid } from "@util/getUuid";
import { fieldTypeDefs, getFieldType } from "./helpers";
import { Context } from "./";

import stateOptions from "@lib/usStates";

export const addressKeys = [
  "address_1",
  "address_2",
  "city",
  "state_province",
  "country",
];

const stateValues = stateOptions.map((m) => m.value);
const caStateValues = stateOptions.filter((f) => f.ca).map((m) => m.value);
const usStates = stateOptions.filter((f) => !f.ca).map((m) => m.value);
const otherUsStateValues = ["AS", "DC", "FM", "GU", "MH", "PW", "PR", "VI"];
const usStateValues = [...usStates, ...otherUsStateValues];

export default function FieldLibrary({ onClick, onAddressSelect }) {
  const [libFields, setLibFields] = useState([]);
  const { fields } = useContext(Context);
  const dbFields = fields
    .filter((f) => f.db_property)
    .map((m) => m.db_property);

  useEffect(() => {
    fetchFields();
  }, []);

  function fetchFields() {
    axios
      .post(`/retrieve-user-engagement-items/`)
      .then(({ data }) => {
        setLibFields(
          data.response.map((m) => ({
            text: m.interrogative,
            uuid: getUuid(),
            field_info: {
              uuid: getUuid(),
              name: m.db_property,
              required: true,
              label: m.interrogative,
              addressLookup: m.db_property === "address_1",
              db_property: m.db_property,
              options:
                m.select_items && m.select_items.length
                  ? m.select_items.map((m) => ({
                      key: m.id,
                      text: m.label,
                      value:
                        m.db_property === "state_province" ? m.label : m.id,
                    }))
                  : [],
              type:
                m.select_items && m.select_items.length
                  ? fieldTypeDefs.select
                  : fieldTypeDefs.text,
            },
          }))
        );
      })
      .catch((err) => {
        setLibFields([]);
      });
  }

  function onAddressClick(country) {
    const addressFields = libFields
      .filter((f) => addressKeys.includes(f.field_info.db_property))
      .map((m) => {
        if (m.field_info.name === "state_province") {
          return {
            ...m.field_info,
            label:
              country === "ca"
                ? "Province"
                : country === "both"
                ? "State/Province"
                : "State",
            // : m.field_info.label,
            required: m.field_info.required,
            options:
              country === "us"
                ? m.field_info.options
                    .filter((f) => !caStateValues.includes(f.text))
                    .sort((a, b) => a.text.localeCompare(b.text))
                : country === "ca"
                ? m.field_info.options
                    .filter((f) => !usStateValues.includes(f.text))
                    .sort((a, b) => a.text.localeCompare(b.text))
                : m.field_info.options.sort((a, b) =>
                    a.text.localeCompare(b.text)
                  ),
          };
        } else if (
          m.field_info.name === "country" &&
          m.field_info.type === "select"
        ) {
          return {
            ...m.field_info,
            options:
              country === "us"
                ? m.field_info.options.filter((f) => f.text === "US")
                : country === "ca"
                ? m.field_info.options.filter((f) => f.text === "CA")
                : m.field_info.options,
          };
        } else {
          return {
            ...m.field_info,
            required:
              m.field_info.db_property === "address_2"
                ? false
                : m.field_info.required,
          };
        }
      });

    onAddressSelect(addressFields, country);
  }

  const filteredFields = libFields.filter(
    (f) => ![...addressKeys].includes(f.field_info.db_property)
  );

  const fieldNames = fields.map((f) => f.name);

  return (
    <Grid>
      {filteredFields.map((f) => (
        <Grid.Col key={f.uuid} span={{ base: 12, md: 6 }}>
          <Button
            size="xs"
            fullWidth
            variant="light"
            disabled={dbFields.includes(f.field_info.db_property)}
            onClick={() => onClick(f.field_info)}
          >
            {f.text}
          </Button>
        </Grid.Col>
      ))}
      <Grid.Col span={{ base: 12, md: 6 }}>
        <Button
          size="xs"
          fullWidth
          variant="light"
          disabled={
            addressKeys.some((r) => fieldNames.includes(r)) ? true : false
          }
          onClick={() => onAddressClick("us")}
        >
          Address (🇺🇸 US ONLY)
        </Button>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6 }}>
        <Button
          size="xs"
          fullWidth
          variant="light"
          disabled={
            addressKeys.some((r) => fieldNames.includes(r)) ? true : false
          }
          onClick={() => onAddressClick("ca")}
        >
          Address (🇨🇦 CA ONLY)
        </Button>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6 }}>
        <Button
          size="xs"
          fullWidth
          variant="light"
          disabled={
            addressKeys.some((r) => fieldNames.includes(r)) ? true : false
          }
          onClick={() => onAddressClick("both")}
        >
          Address (🇺🇸 US & 🇨🇦 CA)
        </Button>
      </Grid.Col>
    </Grid>
  );
}
