import React, { createContext, useContext, useState, useEffect } from "react";
import { Button, Tabs } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

import { initialState } from "./helpers";
import GeneralSettings from "./GeneralSettings";
import TextSettings from "./TextSettings";
import Intervals from "./Intervals";

const tabDefs = {
  general: "general",
  text: "text",
  intervals: "intervals",
};

export const Context = createContext(null);

export default function KeywordSettings({
  beginDate,
  canEditWinnerRules,
  keywordId,
  endDate,
  fetchContest,
  wizardSettings = {},
}) {
  const queryClient = useQueryClient();
  const [state, setState] = useState({
    ...initialState,
    contestDates: {
      begin: beginDate,
      end: endDate,
    },
  });
  const [settings, setSettings] = useState({});

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    axios
      .get(`/keywords/${keywordId}/settings/`)
      .then(({ data }) => {
        setState({ ...state, ...data.response[0].settings });
      })
      .catch((err) => {
        console.log(err);
        setSettings({});
      });
  }

  function updateSettings(newState) {
    const req = {
      contest_id: keywordId,
      settings: newState ? newState : state,
    };

    axios
      .post(`/keywords/${keywordId}/settings/`, req)
      .then(() => {
        queryClient.invalidateQueries([`keywords${keywordId}`]);
      })
      .then(() => {
        fetchSettings();
        if (!newState) {
          toast.success("Updated!");
        }
      })
      .then(() => fetchContest())
      .catch((err) => {});
  }

  function onUpdate(newState, updateAfter = true) {
    setState({ ...state, ...newState });
    if (updateAfter) {
      updateSettings({ ...state, ...newState });
    }
  }

  return (
    <Context.Provider value={state}>
      <Settings
        beginDate={beginDate}
        canEditWinnerRules={canEditWinnerRules}
        endDate={endDate}
        fetchContest={fetchContest}
        onUpdate={onUpdate}
        wizardSettings={wizardSettings}
        updateSettings={updateSettings}
      />
    </Context.Provider>
  );
}

function Settings({
  beginDate,
  endDate,
  onUpdate,
  updateSettings,
  wizardSettings,
}) {
  const { activeTab, intervals: intervalData } = useContext(Context);

  return (
    <div>
      <Tabs onChange={(e) => onUpdate({ activeTab: e })} value={activeTab}>
        <Tabs.List mb="sm">
          <Tabs.Tab value={tabDefs.general}>General</Tabs.Tab>
          <Tabs.Tab value={tabDefs.text}>Text</Tabs.Tab>
          <Tabs.Tab value={tabDefs.intervals}>Intervals</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={tabDefs.general}>
          <GeneralSettings onUpdate={(e) => onUpdate({ ...e })} />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.text}>
          <TextSettings onUpdate={(e) => onUpdate({ ...e }, false)} />
          <Button mt="sm" fullWidth onClick={() => updateSettings()}>
            Save
          </Button>
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.intervals}>
          <Intervals
            dates={{
              begin: beginDate,
              end: endDate,
            }}
            onChange={(newIntervals) => onUpdate({ intervals: newIntervals })}
            items={intervalData}
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}
