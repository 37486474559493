import React from "react";

import { AdminAppConfig } from "@components/AppConfig";

export default function AppConfigIndex() {
  return (
    <div>
      <AdminAppConfig />
    </div>
  );
}
