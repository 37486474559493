export const initialState = {
  activeTab: "general",
  intervals: [],
  members_only: false,
  member_rebound_failure_message:
    "Hmm, that didn't work. Did you already redeem this keyword?",
  member_rebound_message: "You've earned points!",
  non_member_rebound_failure_message:
    "Hmm, that didn't work. Did you already redeem this keyword?",
  non_member_rebound_message:
    "You've earned points! Become a member and put your points to work!",
  point_value: 15,
  recurring: false,
};
