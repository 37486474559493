import React from "react";
import { Text } from "@mantine/core";

export default function LocationReadOnly({ info }) {
  return (
    <div>
      {info.address_1 && <Text size="xl">{info.address_1}</Text>}
      {info.city && (
        <Text size="xl">
          {info.city}, {info.state_province} {info.zip} ({info.timezone})
        </Text>
      )}
    </div>
  );
}
