import React from "react";
import { Box } from "@mantine/core";

export default function BoxWrapper({ children, maw = 1200, style = {} }) {
  return (
    <Box
      maw={maw}
      p="sm"
      style={{
        ...style,
        display: "block",
        margin: "0 auto",
      }}
    >
      {children}
    </Box>
  );
}
