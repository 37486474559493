import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Space, Divider, Card, Title } from "@mantine/core";
import { useSelector } from "react-redux";

import { SongRating } from "@components/Song";
import { VuMeter } from "@components/DataViz";
import useInterval from "@util/useInterval";

export default function DashboardPlays() {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  useInterval(() => {
    fetchData();
  }, 5000);

  function fetchData() {
    setLoading(true);

    axios
      .post(`/dashboard/`, {
        context: "play_data",
      })
      .then(({ data }) => {
        setLoading(false);
        setStats(data.response[0].play_data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  if (!stats) return null;

  const playingNow = stats ? stats.find((f) => f.playing_now) : null;

  return (
    <>
      {!playingNow && (
        <>
          <Card mb="sm">
            <VuMeter
              leftValue={0}
              rightValue={0}
              leftTitle="Current Burn Rate"
              rightTitle="All-Time Burn Rate"
            />
            <Divider mt="xs" mb="lg" />
            <Title order={5} mb="sm">
              Nothing playing at the moment
            </Title>
          </Card>
        </>
      )}
      {playingNow && (
        <>
          {" "}
          <Title order={5} mb="sm">
            Now Playing
          </Title>
          <Card mb="sm">
            <VuMeter
              leftValue={playingNow.current_burn_rate || 0}
              rightValue={playingNow.all_time_burn_rate}
              leftTitle="Current Burn Rate"
              rightTitle="All-Time Burn Rate"
              // title={playingNow.song_name}
              // subtitle={playingNow.artist_name}
            />
            <Divider mt="xs" mb="lg" />
            <SongRating info={playingNow} />
          </Card>
        </>
      )}
      <Title order={5} mb="xs">
        Last played
      </Title>
      {stats
        .filter((f) => !f.playing_now)
        .map((p, i) => (
          <Card key={i} mb="sm">
            <SongRating info={p} />
          </Card>
        ))}
    </>
  );
}
