import React from "react";

import {
  IconTable,
  IconMap2,
  IconChartLine,
  IconChartBar,
  IconQuestionMark,
} from "@tabler/icons-react";

export default function ReportRecipeVizIcon({ vizName, iconProps }) {
  switch (vizName) {
    case "table":
      return <IconTable {...iconProps} />;
    case "mapping":
      return <IconMap2 {...iconProps} />;
    case "bar-chart":
      return <IconChartBar {...iconProps} />;
    case "line-chart":
      return <IconChartLine {...iconProps} />;
    default:
      return null;
  }
}
