import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loader, Box, Button, Text, Group, ActionIcon } from "@mantine/core";
import { IconCircleMinus } from "@tabler/icons-react";
import styled from "styled-components";

import { OrgGalleryLocationSelect, GalleryEffortList } from "./";

import { size } from "@util/breakpoints";

export default function OrgGallery({ orgId }) {
  const [location, setLocation] = useState(null);
  const [fetchingEfforts, setFetchingEfforts] = useState([]);
  const [efforts, setEfforts] = useState([]);

  useEffect(() => {
    if (!location) return;
    fetchLocationEfforts();
    setEfforts([]);
  }, [JSON.stringify(location)]);

  function fetchLocationEfforts() {
    if (!location) return;
    setFetchingEfforts(true);

    const req = {
      location_id: location.id,
      organization_id: orgId,
    };

    axios
      .post(`/gallery/retrieve-location-efforts/`, req)
      .then(({ data }) => {
        setEfforts(data.response);
        setFetchingEfforts(false);
      })
      .catch((err) => {
        setEfforts([]);
        setFetchingEfforts(false);
      });
  }

  return (
    <div>
      <Layout>
        {!location && (
          <div>
            <OrgGalleryLocationSelect
              orgId={orgId}
              onLocationSelect={(loc) => setLocation(loc)}
              reqData={{
                organization_id: orgId,
              }}
            />
          </div>
        )}
      </Layout>
      {location && (
        <div>
          <Group justify="center">
            <Button
              radius="xl"
              onClick={() => setLocation(null)}
              rightSection={<IconCircleMinus size={16} />}
              variant="light"
              size="lg"
            >
              {location.name}
            </Button>
          </Group>
          {fetchingEfforts && (
            <Group justify="center" gap="xl" mt="xl">
              <Loader />
              <Text size="lg" fw={600}>
                Fetching {location.name} campaigns...
              </Text>
            </Group>
          )}
          {!fetchingEfforts && (
            <Box mt="lg">
              <GalleryEffortList
                items={efforts.sort((a, b) =>
                  new Date(a.start_date) > new Date(b.start_date) ? -1 : 1
                )}
              />
            </Box>
          )}
        </div>
      )}
    </div>
  );
}

export const Layout = styled.div`
  width: 600px;
  margin: 0 auto;
  word-wrap: break-word;

  label {
    color: whitesmoke;
  }

  @media (max-width: ${size.tablet}) {
    width: 95%;
    padding-top: 20px;
  }
`;
