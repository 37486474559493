import React from "react";
import { Link } from "react-router-dom";
import { Badge, Card, Image, Text } from "@mantine/core";

import { BasicGrid } from "@components/shared";

export default function LinkList({ items = [], columns = 3 }) {
  return (
    <BasicGrid columns={columns}>
      {items.map((item) => (
        <Card component={Link} to={item.link_url} key={item.id}>
          {item.image && (
            <Card.Section>
              <Image
                src={item.image}
                height={250}
                alt={`${item.title} photo`}
              />
            </Card.Section>
          )}
          <Text fw={700}>{item.title}</Text>
          {item.description && <Text>{item.description}</Text>}
          {item.badge_text && (
            <Badge variant="light" style={{ marginTop: "0.5em" }}>
              {item.badge_text}
            </Badge>
          )}
          {item.children && <React.Fragment>{item.children}</React.Fragment>}
        </Card>
      ))}
    </BasicGrid>
  );
}
