import React, { useState } from "react";
import { Button, Divider, Text, Modal } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

export default function RequestApiConnectionButton({
  locationId,
  organizationId,
}) {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
    setLoading(false);
  }

  function onClick() {
    const req = {};

    setLoading(true);

    if (locationId) req.location_id = locationId;
    if (organizationId) req.organization_id = organizationId;

    axios
      .post(`/request-api-connection/`, req)
      .then(() => {
        toast.success("Request Made!");
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <>
      <Button
        size="xs"
        variant="subtle"
        p={0}
        color="gray"
        onClick={() => setOpen(true)}
      >
        Request API Connection
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <Text>Request an API Connection with the button below.</Text>
        <Divider mt="lg" mb="lg" />
        <Button fullWidth onClick={onClick} loading={loading}>
          Make Request
        </Button>
      </Modal>
    </>
  );
}
