export default function qbPopulateQueryComponents(queryData, queryComponents) {
  return queryComponents;
  // const newQc = { ...queryComponents };

  // const hasEfforts = queryData.efforts.length > 0;

  // Object.keys(newQc).forEach((k) => {
  //   if (Array.isArray(newQc[k])) {
  //     // newQc[k].forEach((comp) => {
  //     //   if (comp.needs_effort) {
  //     //     comp.show = hasEfforts;
  //     //   }
  //     // });

  //     newQc[k] = [...newQc[k]].map((m) => {
  //       const obj = { ...m };
  //       if (obj.needs_effort) {
  //         obj.show = hasEfforts;
  //       }
  //       return obj;
  //     });
  //   }

  //   if (typeof newQc[k] === "object") {
  //     if (newQc[k].needs_effort) {
  //       newQc[k].show = hasEfforts;
  //     }
  //   }
  // });

  // return newQc;
}
