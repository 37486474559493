import React from "react";

import { GeospotItem } from "./";

export default function GeospotList({ editable = true, items, fetchData }) {
  return (
    <div>
      {items.map((item) => (
        <GeospotItem
          key={item.id}
          data={item}
          id={item.id}
          fetchData={fetchData}
          editable={editable}
        />
      ))}
    </div>
  );
}
