import React, { useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import {
  Card,
  Group,
  Button,
  Text,
  Badge,
  Flex,
  Title,
  Loader,
  SegmentedControl,
  Menu,
  TextInput,
} from "@mantine/core";

import { IconCheck, IconMapPin } from "@tabler/icons-react";
import { DateField, PaginatedList } from "@components/shared";
import entityIcon from "@util/entityIcon";

const today = dayjs().format("YYYY-MM-DDTHH:mm:ss");

const dateRangeOptions = [
  {
    text: "All Time (last 90 days)",
    value: "all",
    dates: {
      start: getStartDate(90),
      end: today,
    },
  },
  {
    text: "Today",
    value: 0,
    dates: {
      start: getStartDate(0),
      end: today,
    },
  },
  {
    text: "Last 7 days",
    value: 7,
    dates: {
      start: getStartDate(7),
      end: today,
    },
  },
  {
    text: "Last 28 days",
    value: 28,
    dates: {
      start: getStartDate(28),
      end: today,
    },
  },
  {
    text: "Last 45 days",
    value: 45,
    dates: {
      start: getStartDate(45),
      end: today,
    },
  },
];

export const formatRecipients = new Intl.ListFormat("en", {
  style: "long",
  type: "conjunction",
});

export default function ApiRequestList({
  serverSessionId,
  requestInfo = {
    url: `/retrieve-api-requests/`,
    data: {},
  },
}) {
  const [loading, setLoading] = useState(false);
  const [activeScope, setActiveScope] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [dateValue, setDateValue] = useState(0);
  const [dates, setDates] = useState({
    start: getStartDate(0),
    end: today,
  });
  const [dateMenuOpen, setDateMenuOpen] = useState(false);

  const scopeOptions = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "password",
      label: "Password Errors",
    },
    {
      value: "email",
      label: "Email Errors",
    },
  ];

  const reqData = serverSessionId
    ? {
        server_session_id: serverSessionId,
        search_value: searchValue,
        scope: activeScope,
      }
    : {
        search_value: searchValue,
        scope: activeScope,
        start_date: dates.start,
        end_date: dates.end,
        server_session_id: null,
      };

  const selectedDateOption = dateRangeOptions.find(
    (f) => f.value === dateValue
  );

  const reqColors = {
    POST: "indigo",
    GET: "green",
    PUT: "yellow",
    DELETE: "red",
  };

  return (
    <div>
      {!serverSessionId && (
        <Flex gap="xs" align="center" mb="sm">
          <Title order={4}>Api Requests</Title>
          {loading && <Loader size="xs" />}
        </Flex>
      )}
      {!serverSessionId && (
        <div>
          <Flex
            style={{ width: "100%" }}
            gap="md"
            align="start"
            direction="column"
            mb="lg"
          >
            <Menu
              opened={dateMenuOpen}
              onChange={() => setDateMenuOpen(!dateMenuOpen)}
              closeOnItemClick={false}
            >
              <Menu.Target>
                <Button
                  style={{ minWidth: "160px" }}
                  variant="light"
                  leftSection={entityIcon.calendar()}
                  color="gray"
                >
                  {selectedDateOption ? selectedDateOption.text : "Custom"}
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                {dateRangeOptions.map((item, i) => (
                  <Menu.Item
                    key={i}
                    leftSection={item.value === dateValue ? <IconCheck /> : ""}
                    onClick={() => {
                      setDateValue(item.value);
                      setDates(item.dates);
                      setDateMenuOpen(false);
                    }}
                  >
                    {item.text}
                  </Menu.Item>
                ))}
                <Menu.Item>
                  <Flex gap="xs" align="center">
                    <DateField
                      min={getStartDate(90)}
                      label="From"
                      value={dates.start}
                      onChange={(e) => {
                        setDateValue("");
                        setDates({
                          ...dates,
                          start: e,
                        });
                      }}
                    />
                    <DateField
                      label="To"
                      value={dates.end}
                      onChange={(e) => {
                        setDateValue("");
                        setDates({
                          ...dates,
                          end: e,
                        });
                      }}
                    />
                  </Flex>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
          <Text size="xs" mt="xs">
            *All time and dates are in Eastern Time Zone
          </Text>
        </div>
      )}
      <SegmentedControl
        onChange={(e) => setActiveScope(e)}
        value={activeScope}
        data={scopeOptions}
        mb="sm"
        mt="sm"
      />
      <TextInput
        placeholder="Search by path"
        size="lg"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <PaginatedList
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/incoming-api-requests/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <Card
            component={Link}
            to={`/incoming-api-requests/${item.original.id}`}
            key={item.original.id}
          >
            <Flex direction="column">
              <Group
                bg={reqColors[item.original.request_method]}
                mb="xs"
                pl="sm"
                pr="sm"
                style={{ borderRadius: "5px" }}
              >
                <Badge
                  variant="subtle"
                  // leftSection={entityIcon.request(24)}
                  p="md"
                  size="xl"
                  pl={0}
                  color="white"
                >
                  {item.original.request_method}
                </Badge>
                <Text fw={700} c="white" size="lg">
                  {item.original.path}
                </Text>
              </Group>
              <Text fw={700}>
                Status: {item.original.response_status || "?"}
              </Text>
              <Text>
                <IconMapPin size={16} /> {item.original.ip_address}
              </Text>
              <Text>User Agent: {item.original.user_agent}</Text>
              <Text c="dimmed">
                {new Date(item.original.created_at).toLocaleDateString()}{" "}
                {new Date(item.original.created_at).toLocaleTimeString()}
              </Text>
            </Flex>
          </Card>
        )}
      />
    </div>
  );
}

function getStartDate(daysToSubtract) {
  return dayjs()
    .subtract(daysToSubtract, "day")
    .hour(0)
    .minute(0)
    .second(1)
    .format("YYYY-MM-DDTHH:mm:ss");
}
