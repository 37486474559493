import React from "react";
import { Title, Text } from "@mantine/core";

export default function ErrorView({ text }) {
  return (
    <div>
      <Title color="red">Yikes!</Title>
      <Text>{text}</Text>
    </div>
  );
}
