export function renameFileInConfig(jsonData, fileInfo) {
  const newConfig = { ...jsonData };
  const newAssets = [...newConfig.assets];
  const newItem = newAssets.find((f) => f.id === fileInfo.id);
  if (!newItem) return;
  newItem.original_filename = fileInfo.name;
  return newConfig;
}

export function removeFileInConfig(jsonData, assetId) {
  return {
    ...jsonData,
    assets: [...jsonData.assets].filter((f) => f.id !== assetId),
  };
}

export function addFileToConfig(jsonData = null, newId, placementValue) {
  if (!jsonData || jsonData === "{}") {
    return {
      assets: [{ id: newId, placement: placementValue }],
    };
  } else {
    const newConfig = { ...jsonData };

    if (!newConfig.assets) {
      newConfig.assets = [{ id: newId, placement: placementValue }];
    } else {
      newConfig.assets = [
        ...newConfig.assets,
        { id: newId, placement: placementValue },
      ];
    }

    return newConfig;
  }
}

export function formatPlacements(placements, jsonData, assets) {
  return [...placements]
    .map((m) => ({
      ...m,
      assets:
        jsonData && jsonData.assets
          ? jsonData.assets
              .filter((f) => f.placement === m.value)
              .map((mm) => ({
                ...mm,
                asset: assets.find((f) => f.id === mm.id),
              }))
              .filter((f) => f.asset)
          : [],
    }))
    .map((m) => ({
      ...m,
      assets: m.assets.map((mm) => ({
        ...mm,
        ...mm.asset,
      })),
    }));
}

export function getPlacementImage(config, assets, placementValue) {
  if (!config) return null;
  if (!config.assets) return null;
  if (config.assets.constructor !== Array) return null;
  if (!assets) return null;

  // const asset = config.assets.findLast((f) => f.placement === placementValue);
  const asset = config.assets
    .slice()
    .reverse()
    .find((f) => f.placement === placementValue);
  if (!asset) return null;

  const assetObj = assets.find((f) => f.id === asset.id);

  return assetObj ? assetObj.filename_url : null;
}
