import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Card,
  Modal,
  Checkbox,
  FileInput,
  Divider,
  Flex,
  List,
  Button,
  Text,
  Table,
} from "@mantine/core";
import toast from "react-hot-toast";

import { intervalValueStrings } from "@components/shared/Intervals";
import { PrizeCard } from "@components/Prize";

const checkboxes = [
  { label: "Assets are in place", key: "assets" },
  { label: "Settings are configured", key: "settings" },
  { label: "Prizes are configured", key: "prizing" },
  // { label: "Keywords are configured", key: "confirm3" },
  // { label: "Email is configured", key: "confirm4" },
].map((m) => ({
  ...m,
  value: false,
}));

const defaultCheckboxData = checkboxes.reduce((acc, cur) => {
  acc[cur.key] = cur.value;
  return acc;
}, {});

export default function ContestReplicate({
  contestId,
  onSuccess,
  disabled = false,
}) {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirm2, setConfirm2] = useState(false);
  const [checkboxData, setCheckboxData] = useState(defaultCheckboxData);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (!isOpen) return;
    fetchData();
  }, [isOpen]);

  function onClose() {
    setOpen(false);
    setDownloading(false);
    setUploading(false);
    setValue(null);
    setConfirm(false);
    setConfirm2(false);
    setCheckboxData(defaultCheckboxData);
    setInfo(null);
  }

  function onConfirmClick() {
    setConfirm(true);
  }

  function onUploadClick() {
    setUploading(true);

    const formData = new FormData();
    formData.append("file", value);
    formData.append("contest_id", contestId);
    formData.append("context", "rep_contest_to_loc");

    axios
      .post(`/upload-association-csv/`, formData)
      .then(() => {
        toast.success("Success!");
        setUploading(false);
        onSuccess();
        onClose();
      })
      .catch((err) => {
        setUploading(false);
        toast.error(err);
      });
  }

  function fetchData() {
    axios
      .post(`/duplication-confirmation-data/`, { contest_id: contestId })
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setInfo(null);
      });
  }

  const uploadEnabled = Object.keys(checkboxData)
    .map((m) => checkboxData[m])
    .includes(false);

  const settingsTableData = info
    ? [
        {
          text: "Total entries allowed",
          value:
            info.settings.total_entries_allowed < 0
              ? "Unlimited"
              : info.settings.total_entries_allowed,
        },
        {
          text: "Initial Entry",
          value: info.settings.initial_entry.entry_count,
        },
      ]
    : [];

  return (
    <Card>
      <Text mb="sm" fw={600}>
        You can now replicate this contest across multiple locations. Click
        below to get started.
      </Text>
      <Button
        disabled={disabled}
        style={{ width: "fit-content" }}
        onClick={() => setOpen(true)}
      >
        Replicate Contest
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <>
          <Text mb="sm" fw={600} size="sm">
            Upload a CSV of locations
          </Text>
          <FileInput
            label="CSV File"
            placeholder="Select a file to upload..."
            accept=".csv"
            required
            value={value}
            onChange={setValue}
            mb="sm"
          />
          {confirm ? (
            <>
              <Text mb="lg" fw={600} size="sm">
                You're almost ready to replicate. Before continuing, please
                acknowledge the following:
              </Text>
              {checkboxes.map((m, i) => (
                <Box>
                  <Checkbox
                    key={i}
                    size="md"
                    label={m.label}
                    checked={checkboxData[m.key]}
                    onChange={() =>
                      setCheckboxData({
                        ...checkboxData,
                        [m.key]: !checkboxData[m.key],
                      })
                    }
                  />
                  {m.key === "assets" && (
                    <Box mt="sm">
                      {info.assets.map((mm, ii) => (
                        <Box mb="sm">
                          <img
                            key={ii}
                            src={mm.filename_url}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "200px",
                              objectFit: "contain",
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                  {m.key === "settings" && (
                    <Box mt="sm">
                      <Table striped>
                        <Table.Tbody>
                          {settingsTableData.map((mm, ii) => (
                            <Table.Tr key={ii}>
                              <Table.Td>{mm.text}</Table.Td>
                              <Table.Td>{mm.value}</Table.Td>
                            </Table.Tr>
                          ))}
                          <Table.Tr>
                            <Table.Td>Check-In Value</Table.Td>
                            <Table.Td>
                              {info.settings.check_ins.check_in_value}
                            </Table.Td>
                          </Table.Tr>
                          <Table.Tr>
                            <Table.Td>Keyword Value</Table.Td>
                            <Table.Td>
                              {info.settings.keywords.keyword_value}
                            </Table.Td>
                          </Table.Tr>
                          <Table.Tr>
                            <Table.Td>Keyword Total Entries</Table.Td>
                            <Table.Td>
                              {info.settings.keywords.total_entries_allowed < 0
                                ? "Unlimited"
                                : info.settings.keywords.total_entries_allowed}
                            </Table.Td>
                          </Table.Tr>
                          <Table.Tr>
                            <Table.Td>Keyword Use Total</Table.Td>
                            <Table.Td>
                              {info.settings.keywords.keyword_use_total < 0
                                ? "Unlimited"
                                : info.settings.keywords.keyword_use_total}
                            </Table.Td>
                          </Table.Tr>
                        </Table.Tbody>
                      </Table>
                      <Text size="sm" mt="lg" mb="sm" fw={600}>
                        Check-In Intervals
                      </Text>
                      <Table striped>
                        <Table.Tbody>
                          {info.settings.check_ins.intervals.length > 0 ? (
                            <>
                              {info.settings.check_ins.intervals.map(
                                (int, mmm) => (
                                  <Table.Tr key={mmm}>
                                    <Table.Td>
                                      Check-In{" "}
                                      {intervalValueStrings[int.interval - 1]}{" "}
                                      interval
                                    </Table.Td>
                                    <Table.Td>
                                      {int.frequency < 0
                                        ? "Unlimited"
                                        : int.frequency}
                                    </Table.Td>
                                  </Table.Tr>
                                )
                              )}
                            </>
                          ) : (
                            <Table.Tr>
                              <Table.Td>Check-In Intervals</Table.Td>
                              <Table.Td>None</Table.Td>
                            </Table.Tr>
                          )}
                        </Table.Tbody>
                      </Table>
                      <Text size="sm" mt="lg" mb="sm" fw={600}>
                        Keyword Intervals
                      </Text>
                      <Table striped>
                        <Table.Tbody>
                          {info.settings.keywords.intervals.length > 0 ? (
                            <>
                              {info.settings.keywords.intervals.map(
                                (int, mmm) => (
                                  <Table.Tr key={mmm}>
                                    <Table.Td>
                                      Keyword{" "}
                                      {intervalValueStrings[int.interval - 1]}{" "}
                                      interval
                                    </Table.Td>
                                    <Table.Td>
                                      {int.frequency < 0
                                        ? "Unlimited"
                                        : int.frequency}
                                    </Table.Td>
                                  </Table.Tr>
                                )
                              )}
                            </>
                          ) : (
                            <Table.Tr>
                              <Table.Td>Keyword Intervals</Table.Td>
                              <Table.Td>None</Table.Td>
                            </Table.Tr>
                          )}
                        </Table.Tbody>
                      </Table>
                    </Box>
                  )}
                  {m.key === "prizing" && (
                    <Box mt="md">
                      {info.prizing.map((mm, ii) => (
                        <Box mb="sm" key={ii}>
                          <PrizeCard prizeData={mm}>
                            <Text size="sm">
                              Finalists enabled:{" "}
                              {mm.settings.finalists ? "yes" : "no"}
                            </Text>
                            {true && (
                              <>
                                <Text size="sm">
                                  Finalists: {mm.settings.number_of_finalists}
                                </Text>
                                <Text size="sm">
                                  Draw winner from finalists:{" "}
                                  {mm.settings.draw_winner_from_finalists
                                    ? "yes"
                                    : "no"}
                                </Text>
                              </>
                            )}
                          </PrizeCard>
                        </Box>
                      ))}
                    </Box>
                  )}
                  {i + 1 < checkboxes.length && <Divider mt="lg" mb="lg" />}
                </Box>
              ))}
              {confirm2 && (
                <Text mt="lg" fw={600} size="sm">
                  This is your last chance to confirm the above before
                  replicating this contest. Please do your due dilligence or
                  face many headaches later.
                </Text>
              )}
              {confirm2 ? (
                <Button
                  loading={uploading}
                  mt="lg"
                  fullWidth
                  disabled={uploadEnabled}
                  onClick={onUploadClick}
                >
                  Upload
                </Button>
              ) : (
                <Button
                  loading={uploading}
                  mt="lg"
                  fullWidth
                  disabled={uploadEnabled}
                  onClick={() => setConfirm2(true)}
                >
                  Continue
                </Button>
              )}
            </>
          ) : (
            <Button
              loading={uploading}
              mt="sm"
              fullWidth
              disabled={!value}
              onClick={onConfirmClick}
            >
              Next
            </Button>
          )}
          <Flex mt="sm" justify="center">
            <Button size="xs" color="gray" variant="subtle" onClick={onClose}>
              Get me out of here
            </Button>
          </Flex>
        </>
      </Modal>
    </Card>
  );
}
