import React, { useState } from "react";
import {
  Anchor,
  Box,
  Radio,
  Title,
  Button,
  Tooltip,
  Flex,
} from "@mantine/core";
import { IconHelp } from "@tabler/icons-react";
import { shallow } from "zustand/shallow";

import { CustomTooltip } from "@components/shared";
import useAppStore from "./wizard-store";

export const SelectOption = ({ active, onChange, label, tooltip }) => {
  if (tooltip) {
    return (
      <CustomTooltip label={tooltip}>
        <Radio
          label={label}
          size="md"
          mb="xs"
          mt="xs"
          checked={active ? true : false}
          onChange={onChange}
        />
      </CustomTooltip>
    );
  } else {
    return (
      <Radio
        label={label}
        size="md"
        mb="xs"
        mt="xs"
        checked={active ? true : false}
        onChange={onChange}
      />
    );
  }

  return (
    <Button
      checked={active}
      onClick={onChange}
      label={label}
      variant={active ? "light" : "subtle"}
    >
      {label}
    </Button>
  );

  return <Radio label={label} checked={active} onChange={onChange} />;
};

export const OptionList = ({ options }) => {
  return (
    <Box mt="sm">
      {options.map((opt, i) => (
        <SelectOption key={i} {...opt} />
      ))}
    </Box>
  );
};

export const SectionTitle = ({ text }) => <Title order={4}>{text}</Title>;

export const MembersOnlyQuestion = () => {
  const { memberOnly, setMemberOnly, setMicrosite } = useAppStore(
    (state) => ({
      memberOnly: state.member_only,
      setMemberOnly: state.setMemberOnly,
      setMicrosite: state.setMicrosite,
    }),
    shallow
  );

  return (
    <div>
      <SectionTitle text="Is this for members only?" />
      <OptionList
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ].map((opt) => ({
          label: opt.label,
          onChange: () => {
            setMemberOnly(opt.value);
            if (opt.value === false) {
              setMicrosite(true);
            } else {
              setMicrosite(null);
            }
          },
          active: memberOnly === opt.value,
        }))}
      />
    </div>
  );
};

export const MicrositeQuestion = () => {
  const { microsite, setMicrosite } = useAppStore(
    (state) => ({
      microsite: state.microsite,
      setMicrosite: state.setMicrosite,
    }),
    shallow
  );

  return (
    <div>
      <SectionTitle text="Is the microsite needed?" />
      <OptionList
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ].map((opt) => ({
          label: opt.label,
          onChange: () => setMicrosite(opt.value),
          active: microsite === opt.value,
        }))}
      />
    </div>
  );
};

export const AllowCheckinQuestion = () => {
  const { allowCheckin, setAllowCheckin } = useAppStore(
    (state) => ({
      allowCheckin: state.allow_checkin,
      setAllowCheckin: state.setAllowCheckin,
    }),
    shallow
  );

  return (
    <div>
      <SectionTitle text="Will check-ins be allowed?" />
      <OptionList
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ].map((opt) => ({
          label: opt.label,
          onChange: () => {
            setAllowCheckin(opt.value);
          },
          active: allowCheckin === opt.value,
        }))}
      />
    </div>
  );
};

export const AllowKeywordQuestion = () => {
  const { allowKeyword, setAllowKeyword } = useAppStore(
    (state) => ({
      allowKeyword: state.allow_keyword,
      setAllowKeyword: state.setAllowKeyword,
    }),
    shallow
  );

  return (
    <div>
      <SectionTitle text="Will keywords be allowed?" />
      <OptionList
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ].map((opt) => ({
          label: opt.label,
          onChange: () => {
            setAllowKeyword(opt.value);
          },
          active: allowKeyword === opt.value,
        }))}
      />
    </div>
  );
};

export const AllowGeospotQuestion = () => {
  const { allowGeospot, setAllowGeospot } = useAppStore(
    (state) => ({
      allowGeospot: state.allow_geospot,
      setAllowGeospot: state.setAllowGeospot,
    }),
    shallow
  );

  return (
    <div>
      <Flex align="center" gap="xs">
        <SectionTitle text="Do you need geospots?" />
        <ControlledTooltip
          label={
            <Box>
              Geospots are physical locations associated with your campaign that
              are geofenced, allowing users to check-in and receive a points or
              entry reward. They are different than your business location.
            </Box>
          }
        />
      </Flex>
      <OptionList
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ].map((opt) => ({
          label: opt.label,
          onChange: () => {
            setAllowGeospot(opt.value);
          },
          active: allowGeospot === opt.value,
        }))}
      />
    </div>
  );
};

export const AllowLocationCheckin = () => {
  const { value, onChange, allowGeospot, setAllowGeospot } = useAppStore(
    (state) => ({
      allowGeospot: state.allow_geospot,
      value: state.allow_location_checkin,
      onChange: state.setAllowLocationCheckin,
      setAllowGeospot: state.setAllowGeospot,
    }),
    shallow
  );

  return (
    <div>
      <SectionTitle text="Can you check-in at the location?" />
      <OptionList
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ].map((opt) => ({
          label: opt.label,
          onChange: () => {
            onChange(opt.value);
            if (opt.value === false) {
              setAllowGeospot(true);
            } else {
              setAllowGeospot(allowGeospot);
            }
          },
          active: value === opt.value,
        }))}
      />
    </div>
  );
};

const ControlledTooltip = ({ label }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Tooltip
      label={label}
      color="black"
      inline
      multiline
      withArrow
      w={400}
      arrowSize={8}
      keepMounted
      // opened={isOpen}
    >
      <IconHelp size={18} color="var(--mantine-color-blue-5)" />
    </Tooltip>
  );
};
