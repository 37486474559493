import { Badge } from "@mantine/core";

export default function KeywordStatusBadge({ status, text }) {
  return (
    <Badge
      variant="light"
      color={status === 1 ? "yellow" : status === 2 ? "green" : "red"}
    >
      {text}
    </Badge>
  );
}
