import React, { useEffect, useState } from "react";
import { Button, Divider, Modal, TextInput } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { PhoneValidationNameplate } from "./";

import { ContactMethodVerification } from "@components/shared";

export default function PhoneValidationWidget() {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (value.length === 10) {
      onSubmit();
    }
  }, [value]);

  function onClose() {
    setOpen(false);
    setLoading(false);
    setValue("");
    setInfo(null);
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      number: value,
    };

    axios
      .post(`/phones/single-validation/`, req)
      .then(({ data }) => {
        setLoading(false);
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Button onClick={() => setOpen(true)} size="xs" mb="sm" variant="light">
        Check Phone
      </Button>
      <Modal
        opened={isOpen}
        onClose={onClose}
        size={"sm"}
        title="Phone Verification"
      >
        {info ? (
          <React.Fragment>
            <PhoneValidationNameplate
              title={info.national_format}
              allowed
              data={info}
              // subtitle={`last checked ${info.last_checked}`}
              // allowed={info.allowed}
            />
            <Divider mt="lg" mb="lg" />
            <div>
              <ContactMethodVerification isPhone value={value} />
            </div>
            {/* <PhoneValidationHistory items={info.history} /> */}
            <Button
              size="xs"
              mt="xl"
              color="gray"
              variant="light"
              onClick={() => {
                setValue("");
                setInfo(null);
              }}
            >
              Back
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TextInput
              placeholder="phone"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              size="md"
              label="Number (numbers only)"
            />
            {/* <Button
              fullWidth
              mt="sm"
              disabled={!value || value.length !== 10}
              loading={loading}
              onClick={onSubmit}
            >
              Submit
            </Button> */}
          </React.Fragment>
        )}
      </Modal>
    </div>
  );
}
