import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Button,
  Text,
  Checkbox,
  TextInput,
  PasswordInput,
} from "@mantine/core";
import styled from "styled-components";

import { CodeInput } from "@components/Auth";

export default function LoginForm({ isAdmin = false, isManager, isUser }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mfaInfo, setMfaInfo] = useState(null);
  const [remember, setRemember] = useState(false);

  const emailKey = `mixer${
    isAdmin ? "Admin" : isUser ? "User" : "Manager"
  }Email`;

  const emailRef = useRef(null);
  const pwRef = useRef(null);

  useEffect(() => {
    checkLocalStorage();
  }, []);

  function onRememberChange() {
    const newRemember = !remember;

    setRemember(newRemember);

    if (!newRemember) {
      localStorage.removeItem(emailKey);
    } else {
      localStorage.setItem(emailKey, email);
    }
  }

  function checkLocalStorage() {
    const email = localStorage.getItem(emailKey);

    if (email) {
      setEmail(email);
      setRemember(true);
      pwRef.current.focus();
    } else {
      setRemember(false);
      emailRef.current.focus();
    }
  }

  function onSubmit(e) {
    e.preventDefault();

    if (remember) {
      localStorage.setItem(emailKey, email);
    }

    const req = {
      email,
      password,
    };

    setLoading(true);

    axios
      .post(
        isAdmin ? "/admin-login/" : isUser ? `/user-login/` : "/manager-login/",
        req
      )
      .then(({ data }) => {
        localStorage.setItem(
          "mixer-entry-point",
          isAdmin ? "/admin" : "/manager"
        );
        if (data.response[0] && data.response[0].mfa) {
          setMfaInfo(data.response[0]);
        } else {
          window.location = "/";
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  if (mfaInfo) {
    return (
      <React.Fragment>
        <MFA
          phoneId={mfaInfo.phone_id}
          adminId={mfaInfo.admin_id}
          managerId={mfaInfo.manager_id}
          userId={mfaInfo.user_id}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <StyledForm onSubmit={onSubmit}>
        <TextInput
          type="email"
          maxLength={100}
          value={email}
          ref={emailRef}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="email"
          size="md"
          style={{ marginBottom: "10px" }}
        />
        <PasswordInput
          ref={pwRef}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="password"
          size="md"
        />
        <Button
          type="submit"
          disabled={!email || !password || loading}
          onClick={onSubmit}
        >
          Submit
        </Button>
        <Checkbox
          label="Remember me"
          style={{ color: "#fff" }}
          checked={remember}
          onChange={onRememberChange}
        />
      </StyledForm>
    </React.Fragment>
  );
}

function MFA({ phoneId, adminId, managerId, userId, onSuccess }) {
  const [code, setCode] = useState("");

  useEffect(() => {
    if (code.length === 6) {
      onSubmit();
    }
  }, [code]);

  function onSubmit() {
    const req = {
      code,
      phone_id: phoneId,
    };

    if (adminId) req.admin_id = adminId;
    if (managerId) req.manager_id = managerId;
    if (userId) req.user_id = userId;

    axios
      .post(`/verify-text-code/`, req)
      .then(() => {
        if (adminId) {
          window.location = `/campaigns`;
        } else {
          window.location = `/campaigns`;
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <div>
      <Text mb="lg">Enter the six digit code sent to your mobile phone.</Text>
      <CodeInput value={code} onChange={(e) => setCode(e)} />
    </div>
  );

  return (
    <StyledForm>
      <TextInput
        placeholder="Code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <Button disabled={!code || code.length !== 6} onClick={onSubmit}>
        Submit
      </Button>
    </StyledForm>
  );
}

const StyledForm = styled.form`
  margin: 0 auto;
  width: 300px;

  .mantine-Checkbox-labelWrapper {
    label {
      color: #fff;
    }
  }
`;
