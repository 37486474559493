import React from "react";
import { Table } from "@mantine/core";

import { ReportRecipeUnpinButton } from "./";

export default function ReportRecipePinnedList({ items = [], fetchData }) {
  return (
    <div>
      {/* <Text fw={600}>Pinned to</Text> */}
      <Table striped>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Title</Table.Th>
            <Table.Th>Pinned to</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {items.length === 0 && (
            <Table.Tr>
              <Table.Td colSpan={3}>No pins yet</Table.Td>
            </Table.Tr>
          )}
          {items.map((item) => (
            <Table.Tr key={item.id}>
              <Table.Td>{item.title || item.report_title}</Table.Td>
              <Table.Td>{item.pinned_to}</Table.Td>
              <Table.Td>
                <ReportRecipeUnpinButton
                  pinId={item.id}
                  onSuccess={fetchData}
                />
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </div>
  );
}
