import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Flex, Input, Tabs, Title, TextInput } from "@mantine/core";
import toast from "react-hot-toast";
import InputMask from "react-input-mask";
import styled from "styled-components";

import { UserLockModal } from "@components/User";
import { ResendLink, SignupFlow } from "./UserAuth";
import { CodeInput } from "@components/Auth";

const defaultFormState = {
  email: "",
  password: "",
};

export default function UserLoginForm({ locationId }) {
  const [formValues, setFormValues] = useState(defaultFormState);
  const [phoneValue, setPhoneValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [scenario, setScenario] = useState(null);
  const [codeData, setCodeData] = useState(null);
  const [codeValue, setCodeValue] = useState("");
  const [activeTab, setActiveTab] = useState("phone");
  const [lockData, setLockData] = useState(null);

  useEffect(() => {
    setLoading(false);
    setCodeData(null);
    setCodeValue("");
    setPhoneValue("");
    setFormValues(defaultFormState);
  }, [activeTab]);

  useEffect(() => {
    if (codeValue.length === 6) {
      verifyCode();
    }
  }, [codeValue]);

  function onSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const req = {
      location_id: locationId,
      email: formValues.email,
      password: formValues.password,
    };

    axios
      .post(`/user-login/`, req)
      .then(({ data }) => {
        const res = data.response[0];

        if (res.modal) {
          return setLockData(res);
        }

        if (res.need_email_verified) {
          setScenario(res);
        } else if (res.migration) {
          setScenario(res);
        } else {
          localStorage.setItem("mixer-entry-point", window.location.pathname);
          window.location = `/`;
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onPhoneSubmit() {
    const req = {
      location_id: locationId,
      mobile_phone: phoneValue,
    };

    setLoading(true);

    axios
      .post(`/send-otp-code/`, req)
      .then(({ data }) => {
        setCodeData(data.response[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function verifyCode() {
    const req = {
      code: codeValue,
      phone_id: codeData.phone_id,
      user_location_id: codeData.user_location_id,
    };

    axios
      .post(`/verify-text-code/`, req)
      .then(({ data }) => {
        const res = data.response[0];

        if (res.modal) {
          return setLockData(res);
        }

        if (res.need_email_verified) {
          setScenario(res);
        } else if (res.migration) {
          setScenario(res);
        } else {
          localStorage.setItem("mixer-entry-point", window.location.pathname);
          window.location = `/`;
        }
      })
      .catch((err) => {
        setCodeValue("");
        toast.error(err);
      });
  }

  if (scenario) {
    if (scenario.need_signup_form || scenario.migration) {
      return (
        <SignupFlow
          email={formValues.email || scenario.email}
          firstName={scenario.first_name}
          lastName={scenario.last_name}
          locationId={locationId}
          locationName={scenario.location_name}
          migrationScenario={scenario}
          mobilePhone={scenario.mobile_phone || phoneValue.replace(/\D/g, "")}
          zip={scenario.zip}
        />
      );
    }
    if (scenario.need_email_verified) {
      return (
        <ResendLink
          reqData={{
            user_location_id: scenario.user_location_id,
          }}
        />
      );
    }
  }

  return (
    <div>
      {/* <UserLockModal data={lockData} /> */}
      <Title mb="lg" align="center" order={2}>
        Login
      </Title>
      <Tabs value={activeTab} onChange={(e) => setActiveTab(e)}>
        <Tabs.List
          mb="sm"
          style={{
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Tabs.Tab value="phone">Phone</Tabs.Tab>
          <Tabs.Tab value="email">Email</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="phone">
          <React.Fragment>
            {codeData ? (
              <React.Fragment>
                <StyledInputs>
                  <Title order={4} align="center" mb="sm">
                    Enter code
                  </Title>
                  <CodeInput
                    value={codeValue}
                    onChange={(e) => setCodeValue(e)}
                  />
                </StyledInputs>
                <Flex justify="center">
                  <Button
                    variant="subtle"
                    mt="xl"
                    size="xs"
                    onClick={() => {
                      setCodeData(null);
                    }}
                  >
                    Didn't receive code?
                  </Button>
                </Flex>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Input.Wrapper>
                  <Input
                    size="lg"
                    component={InputMask}
                    placeholder="(XXX) XXX-XXXX"
                    mask="(999) 999-9999"
                    onChange={(e) => {
                      setPhoneValue(e.target.value);
                    }}
                    value={phoneValue}
                  />
                </Input.Wrapper>
                <Button
                  disabled={phoneValue.length !== 14}
                  fullWidth
                  mt="md"
                  type="submit"
                  onClick={onPhoneSubmit}
                  loading={loading}
                >
                  Get Code
                </Button>
              </React.Fragment>
            )}
          </React.Fragment>
        </Tabs.Panel>
        <Tabs.Panel value="email">
          <form onSubmit={onSubmit}>
            <TextInput
              mb="xs"
              placeholder="email"
              enterKeyHint="next"
              value={formValues.email}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  email: e.target.value,
                })
              }
            />
            <TextInput
              enterKeyHint="done"
              type="password"
              placeholder="password"
              value={formValues.password}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  password: e.target.value,
                })
              }
            />
            <Button
              disabled={!formValues.email || !formValues.password}
              fullWidth
              mt="md"
              type="submit"
              onClick={onSubmit}
              loading={loading}
            >
              Submit
            </Button>
          </form>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}

const StyledInputs = styled.div`
  input {
    height: 70px;
    width: 50px;
  }
`;
