import dayjs from "dayjs";

export function formatDate(date) {
  return dayjs(date).format("DD/MM/YYYY");
}

export function formatTime(date) {
  return dayjs(date).format("HH:mm");
}

export function formatInputDate(date) {
  return dayjs(date).format("YYYY-MM-DD");
}

export const todayStart = formatInputDate(new Date());
export const todayEnd = formatInputDate(new Date());
export const dayStart = "00:00";
export const dayEnd = "23:59";
