import React from "react";

import { CampaignReport } from "@components/Report";

export default function ReportIndex() {
  return (
    <div>
      <CampaignReport />
    </div>
  );
}
