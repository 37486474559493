import React, { useState, useEffect } from "react";
import axios from "axios";
import { IconPencil, IconCaretUp, IconCaretDown } from "@tabler/icons-react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Button, Text, useMantineTheme } from "@mantine/core";
import toast from "react-hot-toast";

import useInterval from "@util/useInterval";
import { size } from "@util/breakpoints";

const NotesWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 12px;
  width: 350px;
  z-index: 99999999999;

  @media (max-width: ${size.tablet}) {
    right: 0;
    width: 100%;
  }

  button.title {
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px 4px 0 0;
    background: ${(props) => props.colors.dark[8]};
    color: #fff;
    font-weight: bold;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    font-family: inherit;

    @media (max-width: ${size.tablet}) {
      text-align: center;
    }

    svg {
      margin-left: 5px;
    }
  }

  .content {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    background: ${(props) => props.colors.dark[8]};
  }

  form {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    flex-grow: 1;
  }

  textarea {
    display: block;
    padding: 4px;
    font-size: 14px;
    font-family: inherit;
    border: 2px solid transparent;
    /* border: 2px solid #eee; */
    outline: none;
    background: #fff;
    border-radius: 3px;
    min-height: 65px;
    height: 65px;
    max-height: 120px;
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;

    &:focus {
      border: 2px solid #eee;
    }
  }

  .actions {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .list-wrapper {
    flex-grow: 2;
    background: ${(props) => props.colors.gray[1]};
    overflow: auto;
    max-height: 350px;
    min-height: 350px;
    position: relative;
    border-left: 2px solid #eee;
  }

  .empty {
    text-align: center;
    height: 350px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    text-align: center;
    align-items: center;

    svg {
      fill: #eee;
      /* font-size: 40px; */
      /* margin-bottom: 15px; */
    }

    p {
      font-size: 14px;
    }
  }
`;

const AdminOnlyButton = styled.button`
  opacity: ${(props) => (props.active ? 1 : 0.25)};
  background: none;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  outline: none;
  border: none;
`;

function Notes({
  additionalCreateData = null,
  additionalFetchData = null,
  admin,
  fetchUrl = "/retrieve-notes/",
  title = "Notes",
  user,
}) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // form values
  const [adminOnly, setAdminOnly] = useState(true);
  const [noteValue, setNoteValue] = useState("");
  const [subjectValue, setSubjectValue] = useState("");
  // the notes
  const [items, setItems] = useState([]);
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [notesLoading, setNotesLoading] = useState(false);

  const theme = useMantineTheme();

  useEffect(() => {
    fetchNotes();
  }, []);

  useInterval(() => {
    fetchNotes();
  }, 60000);

  function fetchNotes() {
    if (!additionalFetchData) return;

    setNotesLoading(true);

    const req = {
      ...additionalFetchData,
      admin_only: false,
    };

    axios
      .post(fetchUrl, req)
      .then(({ data }) => {
        setItems(
          data.response.sort((a, b) =>
            new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
          )
        );
        setNotesLoading(false);
      })
      .catch((err) => {
        setNotesLoading(false);
        setItems([]);
      });
  }

  function onSubmit() {
    const req = {
      ...additionalCreateData,
      message: noteValue,
      subject: subjectValue,
      admin_only: user ? false : adminOnly,
    };

    setLoading(true);

    axios
      .post(`/notes/`, req)
      .then(() => {
        setLoading(false);
        resetForm();
        fetchNotes();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function resetForm() {
    setNoteValue("");
    setSubjectValue("");
    setAdminOnly(true);
  }

  return (
    <NotesWrapper colors={theme.colors}>
      <button className="title" onClick={() => setOpen(!isOpen)}>
        {title} {isOpen ? <IconCaretDown /> : <IconCaretUp />}
      </button>
      {isOpen && (
        <div className="content">
          <div className="list-wrapper">
            <NoteList notes={items} />
            {!loading && !items.length && (
              <div className="empty">
                <IconPencil size={40} />
                <p>No notes yet</p>
              </div>
            )}
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <textarea
              value={noteValue}
              placeholder="Your note here..."
              onChange={(e) => {
                setNoteValue(e.target.value);
              }}
            />
            <div className="actions">
              {admin && (
                <div
                  style={{
                    flexGrow: "1",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <AdminOnlyButton
                    active={adminOnly}
                    type="button"
                    onClick={() => setAdminOnly(!adminOnly)}
                  >
                    Admin Only
                  </AdminOnlyButton>
                </div>
              )}
              <div
                style={{
                  flexGrow: "1",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit();
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </NotesWrapper>
  );
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
    user: state.user,
  };
}
export default connect(mapStateToProps)(Notes);

const Note = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 4px;
  background: #fff;

  &:nth-of-type(even) {
    background: #eee;
  }

  p {
    margin: 0;
  }

  time {
    font-size: 12px;
    color: #717171;
  }
`;

const List = styled.div`
  /* max-height: 200px; */
  overflow: auto;
`;

function NoteList({ notes = [] }) {
  if (!notes.length) return null;

  return (
    <List>
      {notes.map(
        ({
          id,
          subject,
          message,
          admin_only,
          owned_by,
          created_at_formatted,
        }) => (
          <Note key={id}>
            <div>
              <Text style={{ marginBottom: "0", marginRight: "10px" }}>
                <b>{owned_by}</b>
                {admin_only && (
                  <span style={{ fontSize: "0.75em", marginLeft: "5px" }}>
                    {admin_only && `(admin only)`}
                  </span>
                )}
              </Text>
              <time>{created_at_formatted}</time>
            </div>
            <div>
              {subject && <Text>{subject}</Text>}
              <Text>{message}</Text>
            </div>
          </Note>
        )
      )}
    </List>
  );
}
