import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Text, TextInput } from "@mantine/core";

import { DataFilters, PaginatedList } from "@components/shared";

export default function PrizePoolList({
  hiddenFilters = [],
  isIndex = false,
  isManager = false,
  items = null,
  requestInfo = {
    url: `/retrieve-contests/`,
    data: {},
  },
  refresh,
}) {
  const filterArr = [
    {
      text: "Crafted By",
      key_name: "owned_by",
      default_value: true,
      radio: true,
      options: [
        { text: "this entity", value: true },
        { text: "someone else", value: false },
      ],
    },
    {
      text: "Status",
      key_name: "status",
      default_value: "entire",
      radio: true,
      options: [
        { text: "All", value: "entire" },
        { text: "Active", value: "active" },
        { text: "Upcoming", value: "upcoming" },
        { text: "Expired", value: "expired" },
      ],
    },
  ]
    .filter((f) => (isManager || isIndex ? f.key_name !== "owned_by" : true))
    .map((m, i) => ({
      ...m,
      all_values: m.options.map((mm) => mm.value),
      key: i + 1,
    }));

  const defaultFilters = filterArr.reduce((acc, cur) => {
    acc[cur.key_name] = cur.default_value;
    return acc;
  }, {});

  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState(defaultFilters);
  const [refresh2, setRefresh2] = useState(false);

  const formattedFilters = Object.keys(filters).reduce((acc, cur) => {
    const obj = filterArr.find((f) => f.key_name === cur);
    if (obj.radio) {
      acc[cur] = filters[cur];
    } else {
      acc[cur] = filters[cur].length ? filters[cur] : obj.all_values;
    }
    return acc;
  }, {});

  const reqData = {
    ...formattedFilters,
    owned_by: isManager ? true : formattedFilters.owned_by,
    ...requestInfo.data,
    search_value: searchValue,
    refresh,
    refresh2,
    variety: 2,
  };

  function onFilterChange(keyName, value) {
    if (typeof value === "object") {
      setFilters({
        ...filters,
        [keyName]: filters[keyName].includes(value)
          ? [...filters[keyName]].filter((f) => f !== value)
          : [...filters[keyName], value],
      });
    } else {
      setFilters({
        ...filters,
        [keyName]: value,
      });
    }
  }

  return (
    <div>
      {requestInfo && (
        <React.Fragment>
          <TextInput
            placeholder="Search..."
            size="lg"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <DataFilters
            onChange={onFilterChange}
            values={filters}
            items={filterArr.filter((f) => !hiddenFilters.includes(f.key_name))}
          />
        </React.Fragment>
      )}
      <PaginatedList
        tableData={items}
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            entity_id: m.organization_id || m.location_id,
            link_url: `/prize-pools/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <PrizePoolItem contestInfo={item.original} />
        )}
      />
    </div>
  );
}

const PrizePoolItem = ({ contestInfo }) => {
  return (
    <Card>
      <Text size="xl" fw={700} component={Link} to={contestInfo.link_url}>
        {contestInfo.name}
      </Text>
    </Card>
  );
};
