import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Anchor, Box, Card, Tabs, Space } from "@mantine/core";

import { PageTitle, ErrorView } from "@components/shared";
import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";
import { StatBlocks } from "@components/DataViz";
import { getUuid } from "@util/getUuid";
import {
  UserInteractionEffortSelect,
  UserInteractionEffort,
  UserInteractionEdit,
} from "@components/UserInteraction";
import { UserEmailStreams } from "@components/Email";
import { UserLockManagement } from "@components/User";
import { PointLedger } from "@components/Point";

export const tabs = [
  { title: "Rollup", value: "rollup", icon: entityIcon.user() },
  { title: "Efforts", value: "efforts", icon: entityIcon.effort() },
  { title: "Edit", value: "edit" },
  { title: "Points", value: "points" },
  { title: "Email Streams", value: "email_streams" },
].map((m) => ({
  ...m,
  key: getUuid(),
}));

export default function UserJoinDetail({ organization, location }) {
  const [info, setInfo] = useState(null);
  const [effortId, setEffortId] = useState(null);
  const [joinMetaData, setJoinMetaData] = useState(null);
  const [error, setError] = useState(null);

  const params = useParams();

  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/user-${location ? "locations" : "organizations"}/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
        setJoinMetaData([
          {
            value: data.response[0].interaction_count,
            text: "Total Interactions",
          },
          {
            value: data.response[0].entry_count,
            text: "Contest Entries",
          },
          {
            value: data.response[0].check_in_count,
            text: "Total Check-Ins",
          },
          {
            value: data.response[0].keyword_count,
            text: "Total Keywords",
          },
          {
            value: data.response[0].contest_won_count,
            text: "Contests Won",
          },
        ]);
      })
      .catch((err) => {
        setError(err);
        setInfo([]);
        setJoinMetaData([]);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  return (
    <div>
      <PageTitle
        title={location ? "User Location" : "User Organization"}
        subtitle={
          info.user_id ? (
            <Anchor
              href={`/users/${info.user_id}`}
              target="_blank"
              style={{
                color: "initial",
              }}
            >
              {info.full_name || "Unidentified"}
            </Anchor>
          ) : (
            info.full_name
          )
        }
        icon={entityIcon.user()}
        iconColor={entityColor.user}
        style={{ marginBottom: "0" }}
      />
      {info.last_interacted && (
        <PageTitle
          style={{ margin: "0" }}
          subtitle={new Date(info.last_interacted).toLocaleString()}
          icon={entityIcon.calendar()}
        />
      )}
      {info.location_name && (
        <PageTitle
          style={{ marginBottom: 0 }}
          subtitle={
            <Anchor
              style={{ color: "inherit" }}
              href={`/locations/${info.location_id}`}
              target="_blank"
            >
              {info.location_name}
            </Anchor>
          }
          icon={entityIcon.location()}
          iconColor={entityColor.location}
        />
      )}
      {info.organization_name && (
        <PageTitle
          style={{ marginBottom: 0 }}
          subtitle={
            <Anchor
              style={{ color: "inherit" }}
              href={`/organizations/${info.organization_id}`}
              target="_blank"
            >
              {info.organization_name}
            </Anchor>
          }
          icon={entityIcon.location()}
          iconColor={entityColor.location}
        />
      )}
      {info.user_lock && (
        <Box mt="xl">
          <UserLockManagement lockData={info.user_lock} fetchData={fetchData} />
        </Box>
      )}
      <Tabs defaultValue="edit" mt="lg">
        <Tabs.List mb="lg">
          {tabs.map((tab) => (
            <Tabs.Tab key={tab.key} value={tab.value} icon={tab.icon}>
              {tab.title}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Tabs.Panel value="rollup">
          <StatBlocks data={joinMetaData} span={3} />
        </Tabs.Panel>
        <Tabs.Panel value="points">
          <Card position="relative">
            <PointLedger
              userLocationId={location ? id : null}
              userOrgId={organization ? id : null}
              actionable
            />
          </Card>
        </Tabs.Panel>
        <Tabs.Panel value="efforts">
          <UserInteractionEffortSelect
            id={id}
            onChange={(e) => setEffortId(e)}
            value={effortId}
            location={location}
          />
          <Space mt="xs" />
          <UserInteractionEffort
            joinId={id}
            location={location}
            effortId={effortId}
          />
        </Tabs.Panel>
        <Tabs.Panel value="edit">
          <UserInteractionEdit
            joinId={params.id}
            fetchData={fetchData}
            rawData={{
              ...info,
              is_location: location ? true : false,
              is_org: organization ? true : false,
            }}
          />
        </Tabs.Panel>
        <Tabs.Panel value="email_streams">
          <Card>
            <UserEmailStreams userLocationId={params.id} />
          </Card>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}
