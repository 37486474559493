import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Image,
  Divider,
  Flex,
  Group,
  Loader,
  Modal,
  Tabs,
  Text,
  Title,
  TextInput,
} from "@mantine/core";
import { IconThumbUp, IconMapPin, IconKey } from "@tabler/icons-react";
import axios from "axios";
import toast from "react-hot-toast";
import { shallow } from "zustand/shallow";

import { StationNowPlaying } from "./";
import { SongRatingSelections } from "@components/Messaging/MessageForm";
import { formatArtistList } from "@components/Curation/helpers";
import { KeywordSubmitSummary } from "@components/Keyword";
import useInterval from "@util/useInterval";
import stationUserAppStore from "@duck/station-user-app-store";
import getGeoLocation from "@util/getGeoLocation";

export default function StationUserInteract({
  children,
  locationId,
  name,
  logo,
}) {
  const [isOpen, setOpen] = useState(false);
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [locating, setLocating] = useState(true);

  const { coords } = stationUserAppStore(
    (state) => ({
      coords: state.coords,
    }),
    shallow
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchGeolocation();
  }, []);

  useInterval(() => {
    fetchData();
  }, 10000);

  function fetchData() {
    axios
      .post(`/location-now-playing/`, { location_id: locationId })
      .then(({ data }) => {
        const res = data.response[0];
        if (res) {
          setInfo(res);
        }
      })
      .catch((err) => {
        setInfo(null);
      });
  }

  function fetchLocationsFromGeo(newCoords) {}

  function fetchGeolocation() {
    // setLocating(true);
    // const successCallback = (position) => {
    //   const lat = position.coords.latitude;
    //   const lng = position.coords.longitude;
    //   setCoords({
    //     latitude: lat,
    //     longitude: lng,
    //   });
    //   fetchLocationsFromGeo({
    //     latitude: lat,
    //     longitude: lng,
    //   });
    //   setLocating(false);
    // };
    // const errorCallback = (error) => {
    //   setLocating(false);
    //   setError("You must have geolocation on to check-in");
    // };
    // navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
    //   enableHighAccuracy: false,
    //   timeout: 5000,
    //   maximumAge: Infinity,
    // });
  }

  function onClose() {
    setOpen(false);
  }

  return (
    <Box p="md">
      <Flex mb="md" align="center">
        {logo && (
          <img
            fit="contain"
            alt="Logo"
            mr="sm"
            src={logo}
            style={{
              width: "100px",
              height: "auto",
              maxHeight: "60px",
              objectFit: "contain",
            }}
          />
        )}
        <Group
          align="flex-end"
          position="right"
          justify="flex-end"
          style={{
            flexGrow: 1,
          }}
        >
          <Button size="xs" variant="light" onClick={() => setOpen(true)}>
            INTERACT
          </Button>
        </Group>
      </Flex>
      <Box mt="sm">
        {children}
        {info && <StationNowPlaying info={info} />}
      </Box>
      <Modal opened={isOpen} onClose={onClose} title="Interact with us">
        <Tabs defaultValue="rate" keepMounted={false}>
          <Tabs.List mb="sm">
            <Tabs.Tab icon={<IconThumbUp size={12} />} value="rate">
              Rate
            </Tabs.Tab>
            {/* <Tabs.Tab icon={<IconMapPin size={12} />} value="checkin">
              Check-In
            </Tabs.Tab> */}
            <Tabs.Tab icon={<IconKey size={12} />} value="keyword">
              Keyword
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="rate">
            <UserRate nowPlayingInfo={info} coords={coords} />
          </Tabs.Panel>
          <Tabs.Panel value="checkin">
            <UserCheckin locating={locating} error={error} coords={coords} />
          </Tabs.Panel>
          <Tabs.Panel value="keyword">
            <UserKeyword coords={coords} />
          </Tabs.Panel>
        </Tabs>
      </Modal>
    </Box>
  );
}

const defaultRating = {
  letter: "",
  number: "",
};

const UserRate = ({ nowPlayingInfo, coords }) => {
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(defaultRating);
  const [results, setResults] = useState(null);
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    fetchRatings();
  }, []);

  useEffect(() => {
    if (nowPlayingInfo) {
      setRating(defaultRating);
      setResults(null);
    }
  }, [JSON.stringify(nowPlayingInfo)]);

  function fetchRatings() {
    const req = {
      page: 0,
      page_size: 10,
      sorting: [],
    };

    axios
      .post(`/retrieve-member-ratings/`, req)
      .then(({ data }) => {
        setRatings(data.response[0].data);
      })
      .catch((err) => {
        setRatings([]);
      });
  }

  const Ratings = () => {
    if (ratings.length === 0) return null;

    return (
      <>
        <Divider mt="lg" mb="lg" />
        <Text mb="sm" fw={600}>
          My last few ratings
        </Text>
        {ratings.map((r, i) => (
          <div key={i}>
            <Flex align="center" gap="sm">
              <Title order={3} miw={40}>
                {r.result_formatted}
              </Title>
              <div>
                {r.song && <Text size="sm">{r.song.name}</Text>}
                <Text size="sm" c="dimmed">
                  {formatArtistList(r.artists)}
                </Text>
              </div>
            </Flex>
            {i + 1 < ratings.length && (
              <Divider mt="xs" mb="xs" opacity={0.25} />
            )}
          </div>
        ))}
      </>
    );
  };

  if (!nowPlayingInfo || !nowPlayingInfo.play_id)
    return (
      <React.Fragment>
        <Text mt="xl">There's nothing to rate at the moment</Text>
        <Ratings />
      </React.Fragment>
    );

  function onSubmit() {
    const req = {
      message_text: `${rating.number}${rating.letter}`,
      play_id: nowPlayingInfo.play_id,
    };

    if (nowPlayingInfo) req.play_id = nowPlayingInfo.play_id;
    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    setLoading(true);

    getGeoLocation().then((newCoords) => {
      if (newCoords) {
        req.latitude = newCoords.latitude;
        req.longitude = newCoords.longitude;
      }

      axios
        .post(`/member-rate-now-playing/`, req)
        .then(({ data }) => {
          toast.success("Rated!");
          setRating(defaultRating);
          setLoading(false);
          if (data.response[0].message) {
            setResults(data.response[0].message);
          }
          fetchRatings();
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    });
  }

  if (results) {
    return (
      <Text align="center" size="xl" mt="sm">
        {results}
      </Text>
    );
  }

  return (
    <Box
      style={{
        maxWidth: "100%",
      }}
    >
      <Text align="center" size="xl" fw={600}>
        {nowPlayingInfo.name}
      </Text>
      <Text align="center" mb="lg" c="dimmed">
        {nowPlayingInfo.artist}
      </Text>
      <SongRatingSelections
        letterValue={rating.letter}
        numberValue={rating.number}
        direction="column"
        onLetterChange={(e) =>
          setRating({
            ...rating,
            letter: e,
          })
        }
        onNumberChange={(e) =>
          setRating({
            ...rating,
            number: e,
          })
        }
      />
      <Button
        mt="sm"
        fullWidth
        loading={loading}
        onClick={onSubmit}
        disabled={!rating.letter || !rating.number}
      >
        Rate
      </Button>
      <Ratings />
    </Box>
  );
};

const UserCheckin = ({ coords, locating, error }) => {
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    const req = {};

    setLoading(true);

    axios
      .post(`/somewhere/`, req)
      .then(() => {})
      .catch((err) => {
        toast.error(err);
      });
  }

  if (locating) {
    return (
      <Group
        justify="center"
        style={{
          padding: "40px 0",
        }}
      >
        <Loader size="xl" variant="dots" color="gray" />
      </Group>
    );
  }

  if (error) {
    return <Text>{error}</Text>;
  }

  return (
    <div>
      <Button fullWidth loading={loading} onClick={onSubmit}>
        Check-In now
      </Button>
    </div>
  );
};

const UserKeyword = ({ coords }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [results, setResults] = useState(null);
  const [enteredKeywords, setEnteredKeywords] = useState([]);

  useEffect(() => {
    fetchEnteredKeywords();
  }, []);

  function fetchEnteredKeywords() {
    const req = {
      context: "keyword",
      page: 0,
      page_size: 10,
    };

    axios
      .post(`/retrieve-member-keywords/`, req)
      .then(({ data }) => {
        setEnteredKeywords(
          data.response.sort((a, b) => new Date(a.time) < new Date(b.time))
        );
      })
      .catch((err) => {
        setEnteredKeywords([]);
      });
  }

  const Keywords = () => {
    if (enteredKeywords.length === 0) return null;

    return (
      <>
        <Divider mt="lg" mb="lg" />
        <Text mb="sm" fw={600}>
          My last few keywords
        </Text>
        {enteredKeywords.map((r, i) => (
          <div key={i}>
            <Text size="sm">{r.message}</Text>
            <Text size="xs" c="dimmed">
              {new Date(r.time).toLocaleString()}
            </Text>
            {i + 1 < enteredKeywords.length && (
              <Divider mt="xs" mb="xs" opacity={0.25} />
            )}
          </div>
        ))}
      </>
    );
  };

  if (results) {
    return (
      <React.Fragment>
        <KeywordSubmitSummary results={results} />
        <Group position="center">
          <Button
            size="xs"
            variant="subtle"
            mt="sm"
            onClick={() => {
              setResults(null);
              fetchEnteredKeywords();
            }}
          >
            Enter another keyword
          </Button>
        </Group>
      </React.Fragment>
    );
  }

  function onSubmit() {
    const req = {
      body: value.trim(),
    };

    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    setLoading(true);

    getGeoLocation().then((newCoords) => {
      if (newCoords) {
        req.latitude = newCoords.latitude;
        req.longitude = newCoords.longitude;
      }

      axios
        .post(`/submit-member-keyword/`, req)
        .then(({ data }) => {
          setValue("");
          setLoading(false);
          setResults(data.response[0]);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    });
  }

  return (
    <div>
      <TextInput
        value={value}
        onChange={(e) => setValue(e.target.value.toLowerCase())}
        placeholder="Enter keyword"
        size="lg"
        mb="sm"
      />
      <Button
        size="md"
        fullWidth
        loading={loading}
        onClick={onSubmit}
        disabled={!value}
      >
        Submit
      </Button>
      <Keywords />
    </div>
  );
};
