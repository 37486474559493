import React, { useContext, useState, useEffect } from "react";
import { Group } from "@mantine/core";

import { Counter } from "@components/shared";
import { Context } from "./";

export default function GeneralSettings({ onUpdate }) {
  const {
    song_rating_value: songRatingValue,
    song_rating_frequency_in_days: songRatingFrequencyInDays,
    value,
  } = useContext(Context);

  const counterFields = [
    {
      title: "Song Rating Value",
      keyName: "song_rating_value",
      value: songRatingValue,
      maxValue: 7,
    },
    {
      title: "Song Rating Frequency (Days)",
      keyName: "song_rating_frequency_in_days",
      value: songRatingFrequencyInDays,
    },
    // {
    //   title: "Value",
    //   keyName: "value",
    //   value,
    // },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
  }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            value={c.value}
            unlimited={false}
            maxValue={c.maxValue || null}
            onChange={(e) => {
              if (c.keyName === "song_rating_value") {
                if (e > 7) {
                  onUpdate({
                    [c.keyName]: 7,
                  });
                } else {
                  onUpdate({
                    [c.keyName]: e,
                  });
                }
              } else {
                onUpdate({
                  [c.keyName]: e,
                });
              }
            }}
          />
          <h3 style={{ margin: "0" }}>{c.title}</h3>
        </Group>
      ))}
    </div>
  );
}
