import React, { useState, useEffect } from "react";
import axios from "axios";
import { Paper, Table, Space, Text } from "@mantine/core";

import { StatBlocks } from "@components/DataViz";
import HeatMapReport from "./HeatMapReport";
import HourReport from "./HourReport";

const EffortReport = ({ reportType, campaignId, effortId }) => {
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    if ([3, 4].includes(reportType)) return;
    fetchData();
  }, [reportType, effortId]);

  function fetchData() {
    axios
      .post(`/retrieve-report-data/`, {
        report: reportType,
        campaign_effort_id: effortId,
      })
      .then(({ data }) => {
        setReportData(data.response[0]);
      })
      .catch((err) => {
        setReportData(null);
      });
  }

  if (reportType === 3) {
    return <HeatMapReport effortId={effortId} />;
  }

  if (reportType === 4) {
    return <HourReport effortId={effortId} />;
  }

  if (!reportData) return null;

  if (reportType === 2) {
    return (
      <div>
        <Space mt="md" />
        <StatBlocks
          data={[{ text: "Submissions", value: reportData.enagament_count }]}
        />
        <Space mt="md" />
        {reportData.engagement_item_results.map((m, i) => (
          <div key={i}>
            <Text size="lg" mb="sm" fw={700}>
              {m.interrogative}
            </Text>
            <Paper p="lg">
              <Table
                highlightOnHover
                style={{
                  tableLayout: "fixed",
                  borderCollapse: "collapse",
                  width: "100%",
                }}
              >
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th colSpan={10}>Answer</Table.Th>
                    <Table.Th colSpan={2}>Responses</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {m.results
                    .sort((a, b) => b.responses - a.responses)
                    .map((mm, ii) => (
                      <Table.Tr key={ii}>
                        <Table.Td colSpan={10}>{mm.label}</Table.Td>
                        <Table.Td colSpan={2}>{mm.responses}</Table.Td>
                      </Table.Tr>
                    ))}
                </Table.Tbody>
              </Table>
            </Paper>
            <Space mt="md" />
          </div>
        ))}
      </div>
    );
  }

  return <div></div>;
};

export default EffortReport;
