import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Anchor, Flex, Grid, Title } from "@mantine/core";

import { IconStarFilled } from "@tabler/icons-react";

export default function Nav() {
  return (
    <Grid>
      {links.map((l, i) => (
        <Grid.Col span={{ base: 6 }} key={i}>
          <Anchor component={Link} to={l.to}>
            <Flex gap="sm" align="center">
              <span>{l.icon}</span>
              <Title order={3}>{l.text}</Title>
            </Flex>
          </Anchor>
        </Grid.Col>
      ))}
    </Grid>
  );
}

const links = [
  {
    text: "Favorite Messages",
    icon: <IconStarFilled size={20} />,
    to: "/messaging-favorites",
  },
].map((m, i) => ({
  ...m,
  key: i,
}));
