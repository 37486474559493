import React, { useState, useEffect, useRef } from "react";
import { Flex, Button, Text } from "@mantine/core";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { shallow } from "zustand/shallow";

import useAppStore from "./report-recipe-runner-store";

export default function ReportSnapshotList({ onSelect, selectedId }) {
  const [loading, setLoading] = useState(true);
  const { recipeInfo, savedRecipe } = useAppStore(
    (state) => ({
      recipeInfo: state.recipe_info,
      savedRecipe: state.saved_recipe,
    }),
    shallow
  );

  function generateRequest() {
    return {
      saved_report_recipe_id: savedRecipe?.id,
      report_recipe_id: !savedRecipe ? recipeInfo.id : null,
    };
  }

  const { failureReason: error, data: snapshots } = useQuery({
    queryKey: ["snapshots"],
    queryFn: async () =>
      axios
        .post(`/retrieve-report-snapshots/`, generateRequest())
        .then(({ data }) => {
          setLoading(false);
          return data.response;
        })
        .catch((err) => {
          setLoading(false);
          return err;
        }),
  });

  if (!snapshots) return null;

  return (
    <div>
      {snapshots.length === 0 && <Text size="sm">No snapshots yet.</Text>}
      <Flex gap="xs" wrap="wrap">
        {snapshots.map((result) => (
          <Button
            key={result.id}
            size="xs"
            color={selectedId === result.id ? "green" : "gray"}
            variant={selectedId === result.id ? "filled" : "light"}
            // disabled={selectedId === result.id}
            onClick={() => {
              onSelect(result);
            }}
          >
            {result.title}
          </Button>
        ))}
      </Flex>
    </div>
  );
}
