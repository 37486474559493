import React, { useState, useEffect, useRef } from "react";
import { Select } from "@mantine/core";
import axios from "axios";

export default function LocationSelect({
  disabled = false,
  disabledIds = [],
  fetchUrl = `/retrieve-locations/`,
  label = "Location",
  onChange,
  objectOnChange = false,
  reqData = {},
  value = null,
}) {
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    if (!searchValue && value) return;
    fetchData();
  }, [JSON.stringify(reqData), searchValue]);

  useEffect(() => {
    if (!value) {
      setSearchValue("");
    }
  }, [value]);

  function fetchData() {
    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      ...reqData,
      page_size: 20,
      page: 0,
    };

    if (searchValue) req.search_value = searchValue;

    axios
      .post(fetchUrl, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setOptions(
          data.response[0].data
            .filter((f) => f.status === 1)
            .sort((a, b) => (a.name > b.name ? 1 : -1))
        );
      })
      .catch((err) => {
        setOptions([]);
      });
  }

  const formattedOptions = options.map((m) => ({
    label: m.name || "Untitled",
    value: m.id,
    key: m.id,
    disabled: disabledIds.includes(m.id),
  }));

  return (
    <Select
      label={label}
      disabled={disabled}
      // size="lg"
      searchValue={searchValue}
      onSearchChange={(e) => setSearchValue(e)}
      data={formattedOptions}
      onChange={(e) => {
        if (e === null) {
          onChange(e);
        }
        if (objectOnChange) {
          const found = options.find((f) => f.id === e);
          if (found) {
            onChange(found);
          }
        } else {
          onChange(e);
        }
      }}
      allowDeselect
      searchable
      limit={20}
      placeholder={"Select a location"}
      value={value}
      clearable
    />
  );
}
