import React, { useState, useEffect } from "react";
import {
  ActionIcon,
  ThemeIcon,
  Anchor,
  Button,
  Divider,
  Flex,
  Group,
  Menu,
  Modal,
  Select,
  Switch,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconTrash,
  IconDots,
  IconLink,
  IconExternalLink,
  IconKeyboard,
  IconX,
  IconCheck,
} from "@tabler/icons-react";
import toast from "react-hot-toast";
import axios from "axios";

import { AssetUpload, AssetLibraryPicker } from "@components/Asset";
import { DateField, TimeField } from "@components/shared";
import timezones from "@lib/timezones";

export default function EntityBannerImages({
  fetchSettings,
  organizationId,
  locationId,
  bannerImages = [],
  timezone = null,
}) {
  function onLibrarySelection(assetId) {
    const req = {
      location_id: locationId,
      organization_id: organizationId,
      asset_id: assetId,
      variety: 8,
    };

    axios
      .post(`/replicate-library-asset/`, req)
      .then(({ data }) => {
        toast.success("Added!");
        fetchSettings();
      })
      .catch((err) => {
        fetchSettings();
        toast.error(err);
      });
  }

  function onExternalUrlUpdate() {
    return true;
  }

  return (
    <Group mt="sm">
      <Group>
        <AssetUpload
          reqData={{
            location_id: locationId,
            organization_id: organizationId,
            variety: 8,
            timezone,
          }}
          onSuccess={() => fetchSettings()}
        />
        <AssetLibraryPicker
          onClose={() => fetchSettings()}
          onSelect={(assetId) => onLibrarySelection(assetId)}
          reqData={{
            variety: [4],
            location_id: locationId,
            organization_id: organizationId,
            filtered: false,
          }}
        />
      </Group>
      {bannerImages.length > 0 && (
        <AssetList
          items={bannerImages}
          locationId={locationId}
          organizationId={organizationId}
          shouldFetch={false}
          fetchData={() => fetchSettings()}
          onRefresh={() => fetchSettings()}
          onExternalUrlUpdate={onExternalUrlUpdate}
          onRemoveSuccess={() => fetchSettings()}
          onRenameSuccess={() => fetchSettings()}
          timezone={timezone}
        />
      )}
    </Group>
  );
}

const AssetList = ({ items = [], timezone, fetchData }) => {
  return (
    <Table striped highlightOnHover>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Asset</Table.Th>
          <Table.Th>Name</Table.Th>
          <Table.Th>Member App</Table.Th>
          <Table.Th>Gallery</Table.Th>
          <Table.Th>Start</Table.Th>
          <Table.Th>End</Table.Th>
          <Table.Th>Actions</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {items.length === 0 && (
          <Table.Tr>
            <Table.Td></Table.Td>
            <Table.Td>No items</Table.Td>
            {/* <Table.Td></Table.Td> */}
            <Table.Td></Table.Td>
          </Table.Tr>
        )}
        {items.map((item) => (
          <AssetItem
            contentType={item.content_type}
            externalUrl={item.external_link_url}
            fetchData={fetchData}
            filename={item.original_filename}
            id={item.id}
            key={item.id}
            item={item}
            showInGallery={item.show_in_gallery}
            url={item.filename_url}
            timezone={timezone}
          />
        ))}
      </Table.Tbody>
    </Table>
  );
};

const AssetItem = ({
  contentType,
  externalUrl = "",
  fetchData,
  filename,
  id,
  item,
  timezone = null,
  url,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    end_date: "",
    end_time: "",
    external_link_url: "",
    original_filename: "",
    show_in_gallery: false,
    show_in_user_app: false,
    start_date: "",
    start_time: "",
    timezone: null,
  });

  useEffect(() => {
    setFormValues({
      end_date: item.display_end_date || "",
      end_time: item.display_end_time || "",
      external_link_url: item.external_link_url || "",
      original_filename: item.original_filename,
      show_in_gallery: item.show_in_gallery || false,
      show_in_user_app: item.show_in_user_app || false,
      start_date: item.display_start_date || "",
      start_time: item.display_start_time || "",
      timezone,
    });
  }, [JSON.stringify(item)]);

  function truncateString(str, num) {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  function onRemoveClick() {
    const url = `/assets/${id}/remove/`;

    axios
      .post(url)
      .then(() => {
        fetchData();
        toast.success("Removed!");
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onSaveClick() {
    const req = {
      asset_id: id,
      ...formValues,
    };

    setLoading(true);

    axios
      .put(`/assets/${id}/`, req)
      .then(() => {
        toast.success("Updated!");
        setOpen(false);
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  const submitDisabled =
    !formValues.original_filename || !datesAndTimeValid(formValues);

  return (
    <React.Fragment>
      <Modal
        size={400}
        padding="20px"
        opened={isOpen}
        onClose={() => setOpen(false)}
        title="Edit Asset"
      >
        <TextInput
          label="Filename"
          required
          value={formValues.original_filename}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              original_filename: e.target.value,
            })
          }
        />
        <TextInput
          label="External URL"
          value={formValues.external_link_url}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              external_link_url: e.target.value,
            })
          }
        />
        <Switch
          label="Show in Member App"
          mt="lg"
          checked={formValues.show_in_user_app}
          onChange={(e) => {
            setFormValues({
              ...formValues,
              show_in_user_app: !formValues.show_in_user_app,
            });
          }}
        />
        <Switch
          label="Show in Gallery"
          mt="lg"
          checked={formValues.show_in_gallery}
          onChange={(e) => {
            setFormValues({
              ...formValues,
              show_in_gallery: !formValues.show_in_gallery,
            });
          }}
        />
        <Divider mt="lg" mb="lg" />
        <Text size="sm" mb="lg">
          The following section is optional. If you wish to show this banner on
          a certain date and time and/or wish to stop showing this banner on a
          certain date and time you may do so below.
        </Text>
        <DateField
          label="Show this banner starting on"
          value={formValues.start_date}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              start_date: e,
            })
          }
        />
        <TimeField
          label="Start Time"
          value={formValues.start_time}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              start_time: e,
            })
          }
        />
        <DateField
          label="Stop showing this banner on"
          value={formValues.end_date}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              end_date: e,
            })
          }
        />
        <TimeField
          label="Stop Time"
          value={formValues.end_time}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              end_time: e,
            })
          }
        />
        <Select
          label="Timezone"
          placeholder="Select one"
          data={timezones}
          disabled
          value={formValues.timezone}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              timezone: e,
            })
          }
        />
        <Button
          onClick={() => {
            setFormValues({
              ...formValues,
              start_date: "",
              start_time: "",
              end_date: "",
              end_time: "",
            });
          }}
          size="xs"
          color="gray"
          variant="subtle"
          p={0}
          mt="xs"
        >
          clear dates and time
        </Button>
        <Divider mt="lg" mb="lg" />
        <Button
          fullWidth
          mt="sm"
          disabled={submitDisabled}
          onClick={onSaveClick}
          loading={loading}
        >
          Save
        </Button>
      </Modal>
      <Table.Tr>
        <Table.Td style={{ width: 150 }}>
          {[
            "image/jpg",
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/webp",
          ].includes(contentType) && (
            <a href={url} target="_blank">
              <img
                src={url}
                style={{ width: "80px", height: "80px", objectFit: "cover" }}
              />
            </a>
          )}
        </Table.Td>
        <Table.Td>
          <Flex align="center" gap="xs">
            <Anchor href={url} target="_blank" weight={600}>
              <Tooltip label={filename}>
                <Text size="sm">{truncateString(filename, 40)}</Text>
              </Tooltip>
            </Anchor>
            {externalUrl && <IconLink title="External URL" size={18} />}
          </Flex>
        </Table.Td>
        <Table.Td>{item.show_in_user_app ? <CheckIcon /> : <RedX />}</Table.Td>
        <Table.Td>{item.show_in_gallery ? <CheckIcon /> : <RedX />}</Table.Td>
        <Table.Td>
          {item.display_start_date && (
            <>
              {item.display_start_date} {item.display_start_time}
            </>
          )}
        </Table.Td>
        <Table.Td>
          {item.display_end_date && (
            <>
              {item.display_end_date} {item.display_end_time}
            </>
          )}
        </Table.Td>
        <Table.Td>
          <Menu>
            <Menu.Target>
              <ActionIcon variant="subtle" color="gray">
                <IconDots />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                component="a"
                href={url}
                target="_blank"
                icon={<IconExternalLink />}
              >
                Open
              </Menu.Item>
              <Menu.Item
                component="button"
                onClick={() => setOpen(true)}
                icon={<IconKeyboard />}
              >
                Edit
              </Menu.Item>
              <React.Fragment>
                <Menu.Divider />
                <Menu.Item
                  component="button"
                  onClick={onRemoveClick}
                  icon={<IconTrash />}
                >
                  Remove
                </Menu.Item>
              </React.Fragment>
            </Menu.Dropdown>
          </Menu>
        </Table.Td>
      </Table.Tr>
    </React.Fragment>
  );
};

function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}

function datesAndTimeValid(formValues) {
  const keys = ["start_date", "start_time", "end_date", "end_time"];
  let arr = [];
  keys.forEach((k) => {
    if (formValues[k]) {
      arr.push(formValues[k]);
    }
  });
  if (!arr.length || arr.length === keys.length) return true;
  return false;
}

const CheckIcon = () => (
  <ThemeIcon size="sm" radius="xl" color="green">
    <IconCheck size={18} />
  </ThemeIcon>
);

const RedX = () => (
  <ThemeIcon size="sm" radius="xl" color="red">
    <IconX size={18} />
  </ThemeIcon>
);
