import React, { useState, useEffect } from "react";
import {
  Button,
  Text,
  Modal,
  UnstyledButton,
  Flex,
  TextInput,
  Textarea,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { IconDeviceFloppy } from "@tabler/icons-react";

export default function SavedReportRecipeCreate({ recipeId, reqData }) {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const queryClient = useQueryClient();

  function onClose() {
    setOpen(false);
    setTitle("");
    setDescription("");
  }

  function onSubmit() {
    const req = {
      ...reqData,
      title,
      description,
    };

    setLoading(true);

    axios
      .post(`/report-recipes/${recipeId}/save-recipe/`, req)
      .then(() => {
        setTitle("");
        queryClient.invalidateQueries([`savedRecipeList`]);
        toast.success("Saved");
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <div>
      <UnstyledButton onClick={() => setOpen(true)}>
        <Flex gap="xs" align="center">
          <IconDeviceFloppy />
          <Text fw={600} size="sm">
            Want to use this configuration later? Click here.
          </Text>
        </Flex>
      </UnstyledButton>
      <Modal opened={isOpen} onClose={onClose}>
        <TextInput
          label="Give a name to this configuration"
          onChange={(e) => setTitle(e.target.value)}
          required
          value={title}
          mb="sm"
        />
        <Textarea
          label="A reminder of why you are saving this (optional)"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          mb="sm"
        />
        <Button
          // variant="subtle"
          loading={loading}
          fullWidth
          disabled={!title}
          onClick={onSubmit}
        >
          Save
        </Button>
      </Modal>
    </div>
  );
}
