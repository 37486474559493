import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Space } from "@mantine/core";

import { SongQuerySongAssignment, SongQueryEdit } from "@components/SongQuery";
import { EntitySubtitles, ErrorView, PageTitle } from "@components/shared";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function SongQueryDetail({ isAdmin }) {
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    fetchData();
  }, [id]);

  function fetchData() {
    axios
      .get(`/song-queries/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  return (
    <React.Fragment>
      <PageTitle
        title={info.name}
        subtitle={info.variety_formatted}
        icon={entityIcon.song()}
        iconColor={entityColor.song}
        style={{ marginBottom: "0" }}
      />
      {isAdmin && (
        <EntitySubtitles
          info={{
            ...info,
            campaign_id: null,
          }}
        />
      )}
      <Space mb="sm" />
      <SongQueryEdit
        id={id}
        name={info.name}
        status={info.status}
        onSuccess={fetchData}
      />
      <Space mb="sm" />
      <SongQuerySongAssignment
        fetchData={fetchData}
        locationId={info.location_id}
        songQueryId={id}
        songQueryName={info.name}
        songQueryVariety={info.variety}
      />
    </React.Fragment>
  );
}
