import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Button, Text } from "@mantine/core";

import { BasicForm } from "@components/shared";
import { initialState as contestInitialSettings } from "./ContestSettings/helpers";
import { currencyValidation } from "@util/validation";
import { dollarsToPennies } from "@util/formatters";
import timezones from "@lib/timezones";
import { todayStart, formatInputDate, dayStart, dayEnd } from "@util/dates";

import { contestWizardSettings } from "./helpers";

export default function ContestForm({
  allowLocationAccess = true,
  campaignId,
  contactName = "",
  contactNumber = "",
  contactEmail = "",
  effortId,
  endDate = "",
  endTime = dayEnd,
  id,
  internalName,
  isForDuplication = false,
  locationId,
  name = "",
  onSuccess,
  organizationId,
  pickupDate = "",
  pickupTime = "",
  pickupTimezone = null,
  retailValue,
  startDate = todayStart,
  startTime = dayStart,
  timezone = null,
  wizardSetup = false,
}) {
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(false);

  const managerInfo = useSelector((state) => state.manager);

  function onSubmit(formData) {
    setLoading(true);

    const formatted = {
      ...formData,
    };

    // if (!wizardSetup)
    //   formatted.retail_value_in_pennies = dollarsToPennies(
    //     !id && retailValue ? retailValue : formData.retail_value
    //   );

    if (wizardSetup) {
      return onWizardDateSubmit(formatted);
    }

    if (id) {
      return onUpdate(formatted);
    }

    return onCreate(formatted);
  }

  function onWizardDateSubmit(formData) {
    return onUpdate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      // name,
      allow_location_access: allowLocationAccess,
      settings: {
        ...contestInitialSettings,
        contestDates: {
          begin: formData.start_date,
          end: formData.end_date,
        },
      },
      variety: 1,
      wizard_settings: {
        ...contestWizardSettings,
        contest_keyword_entries_allowed_required: true,
        contest_keyword_use_total_required: true,
        contest_keyword_value_required: true,
        dates_required: true,
        featured_image_required: true,
        initial_entry_setting_required: true,
        keyword_required: false,
        total_entries_allowed_required: true,
      },
    };

    // if (effortId) req.campaign_effort_id = effortId;
    // if (campaignId) req.campaign_id = campaignId;
    // if (organizationId) req.organization_id = organizationId;
    // if (locationId) req.location_id = locationId;

    // if (managerInfo && managerInfo.location_id)
    //   req.allow_location_access = true;

    axios
      .post(`/add-contest-for-duplication/`, req)
      .then(({ data }) => {
        setLoading(false);
        toast.success("Contest Created!");
        onSuccess(data.response[0].contest_id);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = { ...formData };

    axios
      .put(`/contests/${id}/`, req)
      .then(() => {
        toast.success("Contest Updated!");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "name",
      label: "Name",
      initialValue: name,
      required: true,
      schema: () => Yup.string().max(100, "Too long!").required("Required"),
    },
    // {
    //   name: "variety",
    //   initialValue: "",
    //   required: true,
    //   select: true,
    //   schema: () => Yup.string("Required").nullable().required("Required"),
    //   label: "Variety",
    //   options: [
    //     { label: "Sweepstakes", value: "1" },
    //     { label: "Prizepool", value: "2" },
    //     { label: "Auction", value: "3" },
    //   ],
    // },
    {
      name: "internal_name",
      label: "Internal Name",
      initialValue: internalName,
      required: true,
      schema: () => Yup.string().max(100, "Too long!").required("Required"),
    },
    // {
    //   name: "retail_value",
    //   label: "Estimated Retail Value (xx.xx)",
    //   initialValue: retailValue,
    //   required: true,
    //   schema: () =>
    //     Yup.string()
    //       .matches(currencyValidation, "Not a valid amount")
    //       .required("Required"),
    // },
    {
      name: "start_date",
      initialValue: startDate,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: "Start Date",
      datepicker: {
        min: !id ? formatInputDate(new Date()) : "",
      },
    },
    {
      name: "start_time",
      initialValue: startTime,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: `Start Time${!id ? " (defaults to start of day)" : ""}`,
      timepicker: {},
    },
    {
      name: "end_date",
      initialValue: endDate,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: "End Date",
      datepicker: {
        min: !id ? formatInputDate(new Date()) : "",
      },
    },
    {
      name: "end_time",
      initialValue: endTime,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: `End Time${!id ? " (defaults to end of day)" : ""}`,
      timepicker: {},
    },
    {
      name: "timezone",
      initialValue: timezone,
      required: true,
      select: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: "Timezone",
      options: timezones,
    },
    {
      name: "contact_name",
      label: "Contact for Prize Pickup Name",
      initialValue: contactName,
      required: true,
      schema: () => Yup.string().max(100, "Too long!").required("Required"),
    },
    {
      name: "contact_number",
      label: "Contact for Prize Pickup Number",
      mask: {
        pattern: "(999) 999-9999",
        placeholder: "(XXX) XXX-XXXX",
      },
      initialValue: contactNumber,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required"),
    },
    {
      name: "contact_email",
      label: "Contact for Prize Pickup Email",
      initialValue: contactEmail,
      required: true,
      schema: () =>
        Yup.string()
          .max(50, "Too long!")
          .email("Not a valid email")
          .required("Required"),
    },
    {
      name: "pickup_date",
      initialValue: pickupDate,
      label: "Pickup Date",
      required: true,
      schema: () => Yup.string().required("Required"),
      datepicker: {},
    },
    {
      name: "pickup_time",
      initialValue: pickupTime,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: `Pickup Time`,
      timepicker: {},
    },
    {
      name: "pickup_timezone",
      initialValue: pickupTimezone,
      required: true,
      select: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: "Pickup Timezone",
      options: timezones,
    },
    {
      name: "allow_location_access",
      toggle: true,
      label: "Allow Location Access",
      initialValue: allowLocationAccess,
      required: false,
      schema: () => null,
    },
  ]
    .filter((f) =>
      wizardSetup
        ? ["start_date", "start_time", "end_date", "end_time"].includes(f.name)
        : true
    )
    .filter((f) =>
      managerInfo || locationId
        ? !["allow_location_access"].includes(f.name)
        : true
    )
    .filter((f) =>
      isForDuplication
        ? ![
            "allow_location_access",
            "contact_name",
            "contact_number",
            "contact_email",
            "pickup_date",
            "pickup_time",
            "pickup_timezone",
          ].includes(f.name)
        : true
    )
    .filter((f) =>
      !id
        ? ![
            "internal_name",
            // "name",
            "retail_value",
            "allow_location_access",
            // "timezone",
            "contact_name",
            "contact_number",
            "contact_email",
            "pickup_date",
            "pickup_time",
            "pickup_timezone",
          ].includes(f.name)
        : !["variety"].includes(f.name)
    );

  if (formState) {
    return (
      <ManagerConfirm
        onAccept={() => {
          onSubmit(formState);
          setFormState(null);
        }}
        onReject={() => setFormState(null)}
      />
    );
  }

  return (
    <BasicForm
      fields={fields}
      loading={loading}
      onSubmit={(d) => {
        if (managerInfo && !id) {
          setFormState(d);
        } else {
          onSubmit(d);
        }
      }}
      buttonProps={{
        fullWidth: id && !wizardSetup ? false : true,
      }}
    />
  );
}

export const ManagerConfirm = ({ onAccept, onReject }) => {
  return (
    <React.Fragment>
      <Text mb="sm" mt="sm" size="sm">
        Sweepstakes laws varys by state. Check with your legal counsel and
        follow applicable laws before launching any contest or marketing
        campaign.
      </Text>
      <Button color="green" onClick={onAccept} mr="sm">
        Acknowledge
      </Button>
      <Button color="red" variant="light" onClick={onReject}>
        Cancel
      </Button>
    </React.Fragment>
  );
};
