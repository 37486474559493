import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Switch, Title } from "@mantine/core";
import toast from "react-hot-toast";

export default function EntityConfigSettings({
  campaignId = null,
  effortId = null,
  onUpdate,
  useKeywords = false,
  useCheckins = false,
}) {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    use_keywords: true,
    use_checkins: true,
  });

  useEffect(() => {
    setFormValues({
      ...formValues,
      use_keywords: useKeywords,
      use_checkins: useCheckins,
    });
  }, []);

  function onSubmit() {
    setLoading(true);

    const req = {
      ...formValues,
    };

    if (effortId) req.campaign_effort_id = effortId;
    if (campaignId) req.campaign_id = campaignId;

    axios
      .post(`/update-settings/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Saved");
        onUpdate();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Title order={4} mb="xs">
        Setup
      </Title>
      <Switch
        label="Allow Keyword Entry"
        size="sm"
        mb="md"
        onChange={(e) =>
          setFormValues({
            ...formValues,
            use_keywords: !formValues.use_keywords,
          })
        }
        checked={formValues.use_keywords === true}
      />
      <Switch
        label="Allow Check In"
        size="sm"
        mt="md"
        onChange={(e) =>
          setFormValues({
            ...formValues,
            use_checkins: !formValues.use_checkins,
          })
        }
        checked={formValues.use_checkins === true}
      />
      <Button loading={loading} mt="lg" size="sm" onClick={onSubmit}>
        Save
      </Button>
    </div>
  );
}
