import React from "react";
import { Button } from "@mantine/core";

export default function AssignCreateToggle({
  active = null,
  buttons = [],
  onClick,
}) {
  return (
    <Button.Group mb="sm">
      {buttons.map((button, i) => (
        <Button
          key={i}
          size="xs"
          variant={active === button.value ? "filled" : "light"}
          onClick={() => onClick(button.value)}
        >
          {button.text}
        </Button>
      ))}
    </Button.Group>
  );
}
