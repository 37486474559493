import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  Flex,
  Grid,
  Divider,
  LoadingOverlay,
  Text,
  Tabs,
  Space,
  Button,
  Group,
  Overlay,
  UnstyledButton,
} from "@mantine/core";
import { shallow } from "zustand/shallow";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
  IconSparkles,
  IconTool,
  IconDeviceFloppy,
  IconCamera,
  IconPin,
  IconCaretUp,
  IconCaretDown,
  IconFlask,
  IconTrash,
} from "@tabler/icons-react";
import toast from "react-hot-toast";

import { ReportRecipePinnedList } from "@components/ReportRecipe";
import ReportRecipeEntityPicker from "./ReportRecipeEntityPicker";
import ReportRecipeFallback from "./ReportRecipeFallback";
import ReportRecipeOptions from "./ReportRecipeOptions";
import ReportRecipeQueryComponents from "./ReportRecipeQueryComponents";
import ReportRecipeVisualizer from "./ReportRecipeVisualizer";
import ReportSnapshotCreate from "./ReportSnapshotCreate";
import ReportSnapshotList from "./ReportSnapshotList";
import SnapshotView from "./SnapshotView";
import SavedRecipeMeta from "./SavedRecipeMeta";
import SavedReportRecipeCreate from "./SavedReportRecipeCreate";
import SavedReportRecipeList from "./SavedReportRecipeList";
import ReportRecipePinnableOptions from "./ReportRecipePinnableOptions";
import qbValidator from "./qb-validator";
import qbGenerateRequest from "./qb-generate-request";
import qbInit from "./qb-init";
import qbHasChanged from "./qb-has-changed";

import useAppStore from "./report-recipe-runner-store";

export default function ReportRecipeRunner({ info, id }) {
  const reportDataRef = useRef(null);
  const adminInfo = useSelector((state) => state.admin);
  const managerInfo = useSelector((state) => state.manager);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const [collapsed, setCollapsed] = useState(false);
  const [resetting, setResetting] = useState(false);
  const queryComponentsValid = qbValidator();
  const userParams = {
    savedRecipeId: searchParams.get("savedReportRecipeId") || "",
  };

  const fetchRecipeDetail = () =>
    queryClient.invalidateQueries([`reportRecipeDetail`, id, userParams]);
  const fetchSnapshots = () => queryClient.invalidateQueries([`snapshots`]);

  const isAdmin = adminInfo ? true : false;
  const isOrgManager = managerInfo?.organization_id ? true : false;
  const isLocationManager = managerInfo?.location_id ? true : false;

  const {
    activeTab,
    error,
    initialQueryData,
    locations,
    orgs,
    loading,
    managerQueryEntity,
    queryComponents,
    queryData,
    recipeInfo,
    reportData,
    reportDataQuery,
    reset,
    snapshot,
    setError,
    setLoading,
    setLocations,
    setOrgs,
    setReportData,
    setQueryData,
    savedRecipe,
    setActiveTab,
    setInitialQueryData,
    setManagerQueryEntity,
    setQueryComponents,
    setRecipeInfo,
    setReportDataQuery,
    setSavedRecipe,
    setSnapshot,
    statBlocks,
    tableConfig,
  } = useAppStore(
    (state) => ({
      activeTab: state.active_tab,
      error: state.error,
      loading: state.loading,
      locations: state.locations,
      orgs: state.orgs,
      managerQueryEntity: state.manager_query_entity,
      queryComponents: state.query_components,
      queryData: state.query_data,
      initialQueryData: state.initial_query_data,
      recipeInfo: state.recipe_info,
      reportData: state.report_data,
      reportDataQuery: state.report_data_query,
      reset: state.reset,
      savedRecipe: state.saved_recipe,
      snapshot: state.snapshot,
      setActiveTab: state.setActiveTab,
      setError: state.setError,
      setInitialQueryData: state.setInitialQueryData,
      setOrgs: state.setOrgs,
      setLoading: state.setLoading,
      setLocations: state.setLocations,
      setManagerQueryEntity: state.setManagerQueryEntity,
      setQueryData: state.setQueryData,
      setQueryComponents: state.setQueryComponents,
      setRecipeInfo: state.setRecipeInfo,
      setReportData: state.setReportData,
      setReportDataQuery: state.setReportDataQuery,
      setSavedRecipe: state.setSavedRecipe,
      setSnapshot: state.setSnapshot,
      statBlocks: state.stat_blocks,
      tableConfig: state.table_config,
    }),
    shallow
  );

  const isOrgManagerWithoutLocations =
    isOrgManager && managerInfo.locations.length === 0;

  useEffect(() => {
    return () => {
      reset(true);
    };
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setTimeout(() => {
        setError(null);
      }, 1000);
    }
  }, [error]);

  useEffect(() => {
    setupRunner();
    setActiveTab("form");
  }, [JSON.stringify(info)]);

  function setupRunner() {
    setRecipeInfo({
      ...info,
    });

    const formattedQueryComponents = info.query_components
      .map((m) => ({
        ...m,
        clientName:
          m.type === "effortPicker" ? "campaign_effort_ids" : m.server_name,
      }))
      .sort((a, b) => a.index - b.index);

    setQueryComponents(formattedQueryComponents);
    const queryDataChanges = {
      ...qbInit(formattedQueryComponents),
    };

    if (info.visualizer_options.length === 1) {
      queryDataChanges.visualizer = info.visualizer_options[0].value;
    }

    const qd = {
      ...queryData,
      ...queryDataChanges,
    };

    setQueryData(qd);
    setInitialQueryData(qd);

    if (searchParams.get("savedReportRecipeId")) {
      setSavedRecipe({
        id: searchParams.get("savedReportRecipeId"),
        title: "",
      });
    }

    if (isOrgManagerWithoutLocations) {
      setManagerQueryEntity("organization", true);
    }
  }

  useEffect(() => {
    setError(null);
  }, [JSON.stringify(queryData)]);

  useEffect(() => {
    if (!savedRecipe || savedRecipe.detailed) return;
    fetchSavedRecipe();
  }, [JSON.stringify(savedRecipe)]);

  useEffect(() => {
    if (!reportData) return;
    runReport();
  }, [queryData.visualizer]);

  function runReport() {
    if (
      adminInfo &&
      !locations.length &&
      !orgs.length &&
      recipeInfo.show_org_loc_select
    )
      return reset();

    const queryReq = qbGenerateRequest(queryComponents, queryData);
    setReportDataQuery(queryData);

    const req = {
      ...queryReq,
    };

    if (locations.length) {
      req.location_ids = locations.map((m) => m.id);
    }

    if (orgs.length) {
      req.organization_ids = orgs.map((m) => m.id);
    }

    if (managerQueryEntity === "organization") {
      req.use_organization_for_query = true;
    }

    setLoading(true);

    axios
      .post(`/report-recipes/${id}/run-report/`, req)
      .then(({ data }) => {
        if (data.response[0].response_message) {
          setReportData(null);
          return setError(data.response[0].response_message);
        }
        setReportData(data.response[0]);
        if (reportDataRef.current) {
          reportDataRef.current.scrollIntoView({
            behavior: "smooth",
          });
        }
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setReportData(null);
        setLoading(false);
      });
  }

  function fetchSavedRecipe() {
    if (!savedRecipe) return;
    axios
      .get(`/saved-report-recipes/${savedRecipe.id}/`)
      .then(({ data }) => {
        const res = data.response[0];
        setSavedRecipe({
          ...res,
          detailed: true,
        });
        if (res.query_data) {
          if (res.query_data.saved_recipe_locations) {
            setLocations(res.query_data.saved_recipe_locations);
          }
          if (res.query_data.saved_recipe_orgs) {
            setOrgs(res.query_data.saved_recipe_orgs);
          }
        }
        return res.query_data;
      })
      .then((qd) => {
        if (qd) {
          setQueryData(qd);
        }
      })
      .catch((err) => {
        setError(err);
      });
  }

  async function onSavedRecipeSelect(savedReportItem) {
    setSavedRecipe(savedReportItem);
    setActiveTab("form");
    if (savedReportItem.query_data) {
      setQueryData({
        ...queryData,
        ...savedReportItem.query_data,
      });
    }
  }

  if (!recipeInfo) return null;

  const queryChanged = qbHasChanged(queryData, reportDataQuery);

  const reportRunnable =
    queryComponentsValid && queryData.visualizer ? true : false;

  const hasQueryComponents = JSON.stringify(queryComponents) !== "[]";
  // const showEntityPicker = recipeInfo.show_org_loc_select && !isLocationManager;
  const showEntityPicker =
    recipeInfo.show_loc_select ||
    recipeInfo.show_org_select ||
    recipeInfo.show_org_loc_select;
  const entityRequirementsSatisfied =
    managerQueryEntity === "organization" ||
    !showEntityPicker ||
    orgs.length > 0 ||
    locations.length > 0;

  const showSavedRecipes =
    (adminInfo && !recipeInfo.snapshot_only_admin) ||
    (managerInfo &&
      managerInfo.location_id &&
      !recipeInfo.snapshot_only_location) ||
    (managerInfo &&
      managerInfo.organization_id &&
      !recipeInfo.snapshot_only_organization);

  return (
    <>
      <Grid>
        <Grid.Col span={{ base: 12, md: 12 }}>
          <Card
            style={{
              position: "relative",
            }}
          >
            <LoadingOverlay visible={loading} zIndex={99} />
            {savedRecipe && (
              <>
                <SavedRecipeMeta />
                <Divider mt="lg" mb="lg" />
              </>
            )}
            <Tabs
              value={activeTab}
              onChange={(e) => {
                setActiveTab(e);
                setCollapsed(false);
              }}
            >
              <Tabs.List mb="md">
                <Tabs.Tab
                  leftSection={<IconTool size={24} />}
                  value="form"
                  disabled={snapshot ? true : false}
                >
                  Builder
                </Tabs.Tab>
                {showSavedRecipes && (
                  <Tabs.Tab
                    leftSection={<IconDeviceFloppy size={24} />}
                    value="saved"
                  >
                    Saved
                  </Tabs.Tab>
                )}
                <Tabs.Tab
                  leftSection={<IconCamera size={24} />}
                  value="snapshots"
                >
                  Snapshots
                </Tabs.Tab>
                <Tabs.Tab leftSection={<IconPin size={24} />} value="pins">
                  Pins
                </Tabs.Tab>
              </Tabs.List>
              <CollapsiblePanel collapsed={collapsed}>
                <Tabs.Panel value="form">
                  {!savedRecipe &&
                    showEntityPicker &&
                    !isOrgManagerWithoutLocations && (
                      <>
                        <ReportRecipeEntityPicker />
                        {entityRequirementsSatisfied && (
                          <Divider mt="lg" mb="lg" />
                        )}
                      </>
                    )}
                  {entityRequirementsSatisfied && (
                    <>
                      {hasQueryComponents && !resetting && (
                        <>
                          <ReportRecipeQueryComponents />
                          <Divider mt="lg" mb="lg" />
                        </>
                      )}
                      <>
                        <ReportRecipeOptions />
                        <Divider mt="lg" mb="lg" />
                      </>
                    </>
                  )}
                  {entityRequirementsSatisfied && (
                    <>
                      <Button
                        color="green"
                        size="md"
                        leftSection={<IconSparkles />}
                        onClick={runReport}
                        disabled={!reportRunnable}
                        fullWidth
                      >
                        Run Report
                      </Button>
                      {!savedRecipe && showSavedRecipes && (
                        <Box mt="lg">
                          <SavedReportRecipeCreate
                            recipeId={id}
                            reqData={{
                              report_recipe_id: id,
                              // orgs,
                              // locations,
                              query_data: {
                                ...queryData,
                                saved_recipe_orgs: orgs,
                                saved_recipe_locations: locations,
                                organization_ids: orgs.map((m) => m.id),
                                location_ids: locations.map((m) => m.id),
                              },
                            }}
                          />
                          <UnstyledButton
                            // style={{
                            //   color: "var(--mantine-color-gray-5)",
                            // }}
                            mt="md"
                            onClick={() => {
                              reset();
                              setResetting(true);
                              setTimeout(() => {
                                setResetting(false);
                              }, 1);
                            }}
                          >
                            <Flex gap="xs" align="center">
                              <IconTrash />
                              <Text fw={600} size="sm">
                                Clear form selections
                              </Text>
                            </Flex>
                          </UnstyledButton>
                        </Box>
                      )}
                    </>
                  )}
                </Tabs.Panel>
                <Tabs.Panel value="saved">
                  {showSavedRecipes && (
                    <>
                      <Box>
                        <SavedReportRecipeList
                          reqData={{
                            report_recipe_id: id,
                          }}
                          recipeId={id}
                          selectedId={savedRecipe ? savedRecipe.id : null}
                          onSelect={(savedReportItem) => {
                            onSavedRecipeSelect(savedReportItem).then(() => {
                              fetchSnapshots();
                            });
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Tabs.Panel>
                <Tabs.Panel value="snapshots">
                  <ReportSnapshotList
                    onSelect={(selectedSnapshot) => {
                      if (selectedSnapshot.visualizer) {
                        setQueryData({
                          ...queryData,
                          visualizer: selectedSnapshot.visualizer,
                        });
                      }
                      setSnapshot(selectedSnapshot);
                    }}
                    selectedId={snapshot ? snapshot.id : null}
                  />
                </Tabs.Panel>
                <Tabs.Panel value="pins">
                  {!snapshot && (
                    <div>
                      <ReportRecipePinnableOptions
                        divider={false}
                        fetchSavedRecipe={() => fetchSavedRecipe()}
                      />
                      <Divider mt="lg" mb="lg" />
                    </div>
                  )}
                  <ReportRecipePinnedList
                    fetchData={() => {
                      if (savedRecipe) {
                        fetchSavedRecipe();
                      } else {
                        fetchRecipeDetail();
                      }
                    }}
                    items={
                      savedRecipe ? savedRecipe.pinned_to : recipeInfo.pinned_to
                    }
                  />
                </Tabs.Panel>
              </CollapsiblePanel>
            </Tabs>
            <Group justify="center" mt={collapsed ? 0 : "lg"}>
              <Button
                color="gray"
                variant="subtle"
                size="xs"
                leftSection={
                  collapsed ? (
                    <IconCaretDown
                      color="var(--mantine-color-gray-5)"
                      fill="var(--mantine-color-gray-5)"
                      size={16}
                    />
                  ) : (
                    <IconCaretUp
                      color="var(--mantine-color-gray-5)"
                      fill="var(--mantine-color-gray-5)"
                      size={16}
                    />
                  )
                }
                onClick={() => setCollapsed(!collapsed)}
              >
                {collapsed ? "Expand section" : "Collapse section"}
              </Button>
            </Group>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12 }} ref={reportDataRef}>
          <Card style={{ minHeight: "450px" }}>
            {snapshot && <SnapshotView fetchSnapshots={fetchSnapshots} />}
            {!reportData && !snapshot && (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  align: "center",
                  textAlign: "center",
                  flexGrow: 1,
                }}
              >
                <IconFlask size={80} />
                <Text size="xl" fw={600}>
                  What insights will you gain next?
                </Text>
              </div>
            )}
            {reportData && !snapshot && (
              <Box>
                {queryChanged && (
                  <Overlay
                    color="#fff"
                    blur={0}
                    backgroundOpacity={0.85}
                    zIndex={99}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        flexDirection: "column",
                        width: "450px",
                        margin: "0 auto",
                        textAlign: "center",
                      }}
                    >
                      <Box
                        style={{
                          border: "2px solid var(--mantine-color-dark-9)",
                          background: "#fff",
                        }}
                        p="xl"
                      >
                        <Text fw={600} size="xl">
                          You have edited your report criteria
                        </Text>
                        <Button
                          size="lg"
                          mb="lg"
                          mt="lg"
                          fullWidth
                          onClick={runReport}
                          disabled={!reportRunnable}
                        >
                          Get results
                        </Button>
                        <Text fw={600}>or keep editing your report</Text>
                        <Button
                          size="xs"
                          variant="subtle"
                          color="gray"
                          mt="sm"
                          onClick={() => setQueryData(reportDataQuery)}
                        >
                          clear changes
                        </Button>
                      </Box>
                    </Box>
                  </Overlay>
                )}
                {reportData.fallback ? (
                  <div>
                    <ReportRecipeFallback />
                  </div>
                ) : (
                  <ReportRecipeVisualizer queryChanged={queryChanged} />
                )}
                <Divider mt="lg" mb="lg" />
                <Flex gap="xl">
                  {!snapshot && reportData && (
                    <div style={{ flexGrow: 1 }}>
                      <ReportSnapshotCreate
                        recipeId={id}
                        reqData={{
                          chart_data: reportData,
                          report_recipe_id: !savedRecipe ? id : null,
                          visualizer: queryData.visualizer,
                          saved_report_recipe_id: savedRecipe
                            ? savedRecipe.id
                            : null,
                          table_config: tableConfig,
                          meta_block_data: statBlocks,
                        }}
                      />
                    </div>
                  )}
                  <Button
                    variant="subtle"
                    color="gray"
                    onClick={() => {
                      setReportData(null);
                    }}
                  >
                    Clear Data
                  </Button>
                </Flex>
              </Box>
            )}
          </Card>
        </Grid.Col>
      </Grid>
    </>
  );
}

const CollapsiblePanel = styled.div`
  display: ${(props) => (props.collapsed ? "none" : "block")};
`;
