import React, { useState, useEffect } from "react";
import axios from "axios";
import { Group, Text, Badge, Anchor } from "@mantine/core";
import { IconWorld, IconHome } from "@tabler/icons-react";
import UserInteractionAccordion from "./UserInteractionAccordion";
import entityIcon from "@util/entityIcon";

export default function UserInteractionMiniDetail({ interactionId }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, [interactionId]);

  if (!data) return;

  function fetchData() {
    if (!interactionId) return;

    axios
      .get(`/user-interactions/${interactionId}/`)
      .then(({ data }) => {
        setData(data.response[0]);
      })
      .catch((err) => {
        setData(null);
      });
  }

  const interactionData = [
    {
      text: `${data.first_name} ${data.last_name}`,
      icon: entityIcon.user(),
      badge: (
        <Badge variant="light" size="md">
          {data.variety}
        </Badge>
      ),
      display: data.first_name,
    },
    {
      text: new Date(data.occurred_at).toLocaleString(),
      icon: entityIcon.calendar(),
      badge: null,
      display: data.occurred_at,
    },
    {
      text: `${data.entry_count} Entry(s)`,
      icon: entityIcon.entry(),
      badge: null,
      display: data.entry_count,
    },
    {
      text: data.keyword_text,
      icon: entityIcon.keyword(),
      badge: data.user_created ? (
        <Badge variant="light" color="green">
          User Created
        </Badge>
      ) : null,
      display: data.keyword_text,
    },
    {
      text: data.location_name,
      icon: entityIcon.location(),
      badge: null,
      display: data.location_name,
    },
    {
      text: data.campaign_name,
      icon: entityIcon.campaign(),
      badge: null,
      display: data.campaign_name,
    },
    {
      text: data.campaign_effort_name,
      icon: entityIcon.effort(),
      badge: null,
      display: data.campaign_effort_name,
    },
    {
      text:
        data.address_data && data.address_data.geocode_data
          ? data.address_data.geocode_data.formatted_address
          : "",
      icon: <IconHome size={16} />,
      badge: null,
      display:
        data.address_data &&
        data.address_data.geocode_data &&
        data.address_data.geocode_data.formatted_address,
    },
    {
      text: (
        <Anchor
          component="a"
          href={`https://www.google.com/maps/@${data.latitude},${data.longitude},14z`}
          target="_blank"
        >
          {data.latitude}, {data.longitude}
        </Anchor>
      ),
      icon: <IconWorld size={16} />,
      badge: null,
      display: data.latitude && data.longitude,
    },
  ].filter((f) => f.display);

  return (
    <div>
      {interactionData.map((m, i) => (
        <Group mb="sm" key={i}>
          {m.icon}
          <Text size="lg">{m.text}</Text>
          {m.badge}
        </Group>
      ))}
      <UserInteractionAccordion
        emailData={data.email_data}
        phoneData={data.phone_data}
      />
    </div>
  );
}
