import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { TextInput, Button } from "@mantine/core";

export default function CurationEdit({ id, value, fetchData, reqData = {} }) {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  function onSubmit() {
    // const req = {
    //   ...reqData,
    //   name: inputValue,
    // };

    const req = {};

    if (reqData.song_id) {
      req.songs = [{ song_id: reqData.song_id, name: inputValue }];
    } else {
      return;
    }

    const url = reqData.song_id ? `/songs/${id}/` : `/artists/${id}/`;

    setLoading(true);

    axios
      .put(url, req)
      .then(() => {
        toast.success("Updated!");
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <div>
      <TextInput
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Name"
        maxLength={100}
      />
      <Button
        disabled={!inputValue}
        onClick={onSubmit}
        loading={loading}
        fullWidth
        mt="lg"
      >
        Save
      </Button>
    </div>
  );
}
