import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Avatar,
  Flex,
  TextInput,
  Text,
  Button,
  ActionIcon,
  Anchor,
  Image,
  Group,
  Box,
} from "@mantine/core";
import {
  IconBellOff,
  IconCircleFilled,
  IconBan,
  IconBell,
  IconX,
  IconCircleCheck,
} from "@tabler/icons-react";

import useInterval from "@util/useInterval";

const FILTER_ACTIVE = "flag";
const FILTER_BLOCK = "block";
// const FILTER_MUTE = "mute";

export default function ContactList({ refresh, onDismissClick }) {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [activeFilter, setActiveFilter] = useState(FILTER_ACTIVE);

  const locationSettings = useSelector((state) => state.locationSettings);

  const btns = [
    // { text: "active", value: FILTER_ACTIVE },
    {
      text: "show blocked",
      value: activeFilter === FILTER_BLOCK ? FILTER_ACTIVE : FILTER_BLOCK,
    },
    // { text: "muted", value: FILTER_MUTE },
  ].map((m, i) => ({ ...m, key: i }));

  useEffect(() => {
    fetchContacts();
  }, [activeFilter, refresh]);

  useInterval(() => {
    fetchContacts();
  }, 10000);

  function fetchContacts() {
    setLoading(true);

    axios
      .post(`/retrieve-entity-conversation-list/`, {
        blocked: activeFilter === FILTER_BLOCK,
      })
      .then(({ data }) => {
        setLoading(false);
        setContacts(
          data.response
            .map((m) => ({
              ...m,
              blocked: activeFilter === FILTER_BLOCK,
              id: m.user_location_id || m.user_organization_id,
              join_id: m.user_location_id || m.user_organization_id,
              name: m.nickname || m.name || m.number,
              description: m.last_message
                ? new Date(m.last_message).toLocaleString()
                : "",
            }))
            .sort((a, b) =>
              new Date(a.last_message) > new Date(b.last_message) ? 1 : -1
            )
            .sort((a, b) =>
              new Date(a.last_message) < new Date(b.last_message) ? 1 : -1
            )
        );
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const filteredContacts = [...contacts].filter((f) =>
    searchValue
      ? f.name.toLowerCase().includes(searchValue.toLowerCase())
      : true
  );

  const nonMutedContacts = [...filteredContacts].filter((f) => !f.muted);
  const mutedContacts = [...filteredContacts].filter((f) => f.muted);

  const FormattedContacts = ({ people }) => (
    <React.Fragment>
      {people.map((c) => (
        <Contact
          key={c.id}
          avatarUrl={c.avatar_url}
          name={c.name}
          muted={c.muted}
          flagged={c.flagged}
          blocked={c.blocked}
          id={c.id}
          active={
            window.location.pathname ===
            `/messaging-interactions/${c.contact_list_id}`
          }
          description={c.description}
          onDismiss={() => {
            fetchContacts();
            onDismissClick(c.id);
          }}
          fetchData={fetchContacts}
          userLocationId={c.user_location_id}
          userOrgId={c.user_organization_id}
        />
      ))}
    </React.Fragment>
  );

  return (
    <Layout>
      <div className="sticky">
        <Group justify="center">
          <Link to="/messaging">
            <Image
              width={90}
              pos="center"
              src={
                locationSettings && locationSettings.logo_url
                  ? locationSettings.logo_url
                  : "https://mixer-public-assets.s3.amazonaws.com/logo-white.png"
              }
              alt="Logo"
            />
          </Link>
        </Group>
        <div style={{ marginBottom: "8px" }}>
          {btns.map((m) => (
            <Button
              variant={activeFilter === FILTER_BLOCK ? "light" : "subtle"}
              key={m.key}
              size="xs"
              onClick={() => setActiveFilter(m.value)}
            >
              {m.text}
            </Button>
          ))}
        </div>
        <TextInput
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          placeholder="Find a conversation"
        />
      </div>
      <Box p="sm">
        <FormattedContacts people={nonMutedContacts} />
        <FormattedContacts people={mutedContacts} />
        {filteredContacts.length === 0 && searchValue && (
          <Text>Nothing matching "{searchValue}"</Text>
        )}
        {filteredContacts.length === 0 && (
          <div>
            <Text>Your conversations will appear here</Text>
          </div>
        )}
      </Box>
    </Layout>
  );
}

const Layout = styled.div`
  display: block;

  .sticky {
    background: var(--backgroundSecondaryColor);
    position: sticky;
    top: 0;
    padding: 1em;
    border-bottom: 2px solid #262626;
    /* z-index: 9999; */
    z-index: 4;
  }

  img {
    max-width: 100%;
    width: 200px;
    margin: 0 auto 1.5em auto;
    display: block;
  }
`;

const Contact = ({
  active,
  avatarUrl,
  blocked = false,
  description = "",
  fetchData,
  flagged = false,
  muted = false,
  name = "",
  onDismiss,
  userLocationId,
  userOrgId,
}) => {
  const linkPath = `/messaging-interactions/${
    userLocationId ? userLocationId : userOrgId
  }?entity_join_type=${
    userLocationId ? "user_location_id" : "user_organization_id"
  }`;

  const entityInfo = {
    user_location_id: userLocationId,
    user_organization_id: userOrgId,
  };

  function onDismissClick() {
    axios
      .post(`/member-dismiss/`, entityInfo)
      .then(() => onDismiss())
      .catch((err) => fetchData());
  }

  function onMuteClick() {
    axios
      .post(`/member-toggle-mute/`, entityInfo)
      .then(() => fetchData())
      .catch((err) => fetchData());
  }

  function onBlockClick() {
    axios
      .post(`/member-toggle-block/`, entityInfo)
      .then(() => fetchData())
      .catch((err) => fetchData());
  }

  return (
    <StyledContact muted={muted} active={active}>
      <Flex>
        <Anchor
          component={Link}
          to={linkPath}
          style={{
            textDecoration: "none",
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          <Flex
            gap="sm"
            align="center"
            style={{
              width: "100%",
            }}
          >
            {/* <Image width={50} height={50} radius="xl" src={avatarUrl} /> */}
            <Avatar src={avatarUrl} radius="xl" size="lg" />
            <Box style={{ flexGrow: 1 }}>
              <Text fw={600}>{name}</Text>
              {description && <Text size="xs">{description}</Text>}
            </Box>
            {flagged && (
              <div>
                <IconCircleFilled size={16} />
              </div>
            )}
          </Flex>
        </Anchor>
      </Flex>
      <Flex mt="xs" gap="lg">
        {!blocked && (
          <React.Fragment>
            <ActionIcon
              onClick={onDismissClick}
              icon={<IconX size={24} />}
              title="Hide this conversation"
              variant="subtle"
              size="xs"
            >
              <IconX size={24} />
            </ActionIcon>
            <ActionIcon
              onClick={onMuteClick}
              title={`${muted ? `Unmute` : "Mute"} this conversation`}
              variant="subtle"
              size="xs"
            >
              {!muted ? <IconBell size={24} /> : <IconBellOff size={24} />}
            </ActionIcon>
          </React.Fragment>
        )}
        <ActionIcon
          variant="subtle"
          size="xs"
          onClick={onBlockClick}
          title={`${blocked ? "Unblock" : "Block"} this conversation`}
        >
          {blocked ? <IconCircleCheck size={26} /> : <IconBan size={22} />}
        </ActionIcon>
      </Flex>
    </StyledContact>
  );
};

const StyledContact = styled.div`
  padding: 0.5em;
  border-radius: 4px;
  opacity: ${(props) => (props.muted ? 0.5 : 1)};
  transition: all 0.15s ease-in-out;
  background: ${(props) =>
    props.active ? "var(--background-modifier-hover)" : "transparent"};

  img {
    margin-bottom: 0;
  }
`;
