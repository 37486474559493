import React, { useState, useEffect } from "react";
import { Button, Divider, Modal, TextInput, Textarea } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { IconShare } from "@tabler/icons-react";
import { useSelector } from "react-redux";

import { emailValidation } from "@util/validation";

const initialFormState = {
  recipient_email: "",
  recipient_name: "",
  additional_info: "Check out this custom report!",
  sender_name: "",
  sender_business_name: "",
};

export default function ShareReportSnapshot({ reqData, text = "Share" }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialFormState);

  const managerInfo = useSelector((state) => state.manager);
  const adminInfo = useSelector((state) => state.admin);

  useEffect(() => {
    if (!isOpen) return;
    if (adminInfo) {
      setFormValues({
        ...formValues,
        sender_name: adminInfo.full_name,
        sender_business_name: `Mixer`,
      });
    }

    if (managerInfo) {
      const activeLocation = managerInfo.locations.find(
        (f) => f.location_id === managerInfo.location_id
      );
      const activeOrg = managerInfo.organizations.find(
        (f) => f.organization_id === managerInfo.organization_id
      );
      const entityName = activeLocation
        ? activeLocation.name
        : activeOrg
        ? activeOrg.name
        : "";
      setFormValues({
        ...formValues,
        sender_name: managerInfo.full_name,
        sender_business_name: entityName,
      });
    }
  }, [JSON.stringify(isOpen)]);

  function onClose() {
    setOpen(false);
    setLoading(false);
    setFormValues(initialFormState);
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      ...reqData,
      shared_report_data: {
        ...formValues,
      },
    };

    axios
      .post(`/report-snapshots/share-snapshot/`, req)
      .then(() => {
        onClose();
        setLoading(false);
        toast.success("Shared!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const submitDisabled =
    !emailValidation.test(formValues.recipient_email) ||
    !formValues.recipient_name ||
    !formValues.additional_info ||
    !formValues.sender_business_name ||
    !formValues.sender_name;

  return (
    <>
      <Button
        leftSection={<IconShare size={16} />}
        variant="subtle"
        color="gray"
        size="xs"
        onClick={() => setOpen(true)}
      >
        {text}
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <TextInput
          label="Recipient Email"
          required
          mb="xs"
          value={formValues.recipient_email}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              recipient_email: e.target.value,
            })
          }
        />
        <TextInput
          label="Recipient Name"
          required
          mb="xs"
          value={formValues.recipient_name}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              recipient_name: e.target.value,
            })
          }
        />
        <Textarea
          label="Provide some context for the recipient"
          required
          mb="xs"
          value={formValues.additional_info}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              additional_info: e.target.value,
            })
          }
        />
        <TextInput
          label="Your name"
          mb="xs"
          required
          value={formValues.sender_name}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              sender_name: e.target.value,
            })
          }
        />
        <TextInput
          label="Your company"
          mb="xs"
          required
          value={formValues.sender_business_name}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              sender_business_name: e.target.value,
            })
          }
        />
        <Divider mt="lg" mb="lg" />
        <Button
          leftSection={<IconShare size={16} />}
          fullWidth
          onClick={onSubmit}
          loading={loading}
          disabled={submitDisabled}
        >
          {text}
        </Button>
      </Modal>
    </>
  );
}
