import React, { useState } from "react";
import { Button, Divider } from "@mantine/core";

import { CurationSongSelect } from "./";
import axios from "axios";
import toast from "react-hot-toast";

export default function CurateSongs({ items = [], onSuccess, artistId }) {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  function onClose() {
    setLoading(false);
    setSelected([]);
  }

  function onSubmit() {
    const req = {
      song_ids: selected,
    };

    setLoading(true);

    axios
      .post(`/curation-affirmation/`, req)
      .then(() => {
        toast.success("Curated!");
        setLoading(false);
        onClose();
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <CurationSongSelect
        items={items}
        title="Select songs to curate"
        selected={selected}
        onChange={(e) => setSelected(e)}
      />
      <Divider mt="lg" mb="lg" />
      <Button
        fullWidth
        loading={loading}
        onClick={onSubmit}
        disabled={selected.length === 0}
      >
        Curate {selected.length} song{selected.length === 1 ? "" : "s"}
      </Button>
    </div>
  );
}
