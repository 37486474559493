import React, { useState, useEffect } from "react";
import axios from "axios";
import { Anchor, Box, Space } from "@mantine/core";
import { useParams, Route, Routes, Link } from "react-router-dom";

import { PostmarkServerStreamList } from "@components/Email";
import { ErrorView, PageTitle, EntitySubtitles } from "@components/shared";
import { PostmarkServerStreamDetail } from "@pages/Admin";
import { EmailServerEntityManagement } from "@components/EmailServer";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function PostmarkServerDetail() {
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    fetchData();
  }, [id]);

  function fetchData() {
    axios
      .get(`/postmark/servers/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  const apiToken =
    info.ApiTokens && info.ApiTokens.length > 0 ? info.ApiTokens[0] : null;

  const ownedByLocation =
    info.owned_by && info.owned_by.class === "Location"
      ? {
          location_name: info.owned_by.name,
          location_id: info.owned_by.id,
        }
      : null;

  const ownedByOrganization =
    info.owned_by && info.owned_by.class === "Organization"
      ? {
          organization_name: info.owned_by.name,
          organization_id: info.owned_by.id,
        }
      : null;

  return (
    <div>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <PageTitle
                title={info.Name}
                subtitle={"Postmark Email Server"}
                icon={entityIcon.email()}
                iconColor={entityColor.email}
                style={{
                  marginBottom: "0",
                }}
              />
              {info.owned_by && (
                <EntitySubtitles
                  info={{
                    ...info.owned_by,
                    location_name: ownedByLocation ? ownedByLocation.name : "",
                    location_id: ownedByLocation ? ownedByLocation.id : "",
                    organization_name: ownedByOrganization
                      ? info.owned_by.name
                      : "",
                    organization_id: ownedByOrganization
                      ? info.owned_by.id
                      : "",
                  }}
                />
              )}
              <Space mt="lg" />
              <PostmarkServerStreamList serverId={id} apiToken={apiToken} />
              {info.owned_by && (
                <>
                  <Space mt="lg" />
                  <EmailServerEntityManagement
                    locationId={
                      ownedByLocation ? ownedByLocation.location_id : null
                    }
                    organizationId={
                      ownedByOrganization
                        ? ownedByOrganization.organization_id
                        : null
                    }
                    fetchData={fetchData}
                    postmarkServerId={id}
                    postmarkServerName={info.Name}
                    postmarkServers={info.postmark_servers}
                  />
                </>
              )}
            </>
          }
        />
        <Route
          path="streams/:streamId/*"
          element={
            <>
              <Box mb="sm">
                <Anchor
                  component={Link}
                  to={`/postmark/${id}`}
                  c="dimmed"
                  size="xs"
                >
                  back to {info.Name} Server
                </Anchor>
              </Box>
              <PostmarkServerStreamDetail serverId={id} apiToken={apiToken} />
            </>
          }
        />
      </Routes>
    </div>
  );
}
