import React from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLink, Text, UnstyledButton, Group, ThemeIcon } from "@mantine/core";

export default function Nav({ links, isAdmin }) {
  return (
    <React.Fragment>
      {links.map((l, i) => (
        <CustomLink
          key={i}
          to={l.to}
          text={l.text}
          icon={l.icon}
          color={l.color}
        />
      ))}
    </React.Fragment>
  );
}

export const CustomLink = ({ to, color, icon, text }) => {
  const location = useLocation();

  return (
    <UnstyledButton
      component={Link}
      to={to}
      style={{
        display: "block",
        width: "100%",
        padding: "var(--mantine-spacing-xs)",
        backgroundColor:
          to === location.pathname ? "rgba(239,239,236,.35)" : "transparent",
        borderRadius: "var(--mantine-radius-sm)",
        "&:hover": {
          backgroundColor: "var(--mantine-color-dark-6)",
          // backgroundColor:
          //   theme.colorScheme === "dark"
          //     ? theme.colors.dark[6]
          //     : theme.colors.gray[0],
        },
      }}
    >
      <Group>
        <ThemeIcon variant="light" color={color}>
          {icon}
        </ThemeIcon>
        <Text size="sml">{text}</Text>
      </Group>
    </UnstyledButton>
  );
};
