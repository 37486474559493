import React from "react";

import { LocationList } from "@components/Location";

export default function LocationIndex({ organizationId }) {
  return (
    <LocationList
      isIndex
      requestInfo={{
        url: `/retrieve-locations/`,
        data: {
          organization_ids: [organizationId],
        },
      }}
    />
  );
}
