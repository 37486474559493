import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Divider,
  Group,
  Loader,
  Text,
  Modal,
  Radio,
  Tabs,
} from "@mantine/core";

import { formatEmail, formatPhone } from "./UserContactManagement";

import { UserContactTransfer } from "./";

const UserContactSelect = ({
  activeId,
  editable = true,
  emailData,
  isEmail,
  isOpen,
  isPhone,
  loading,
  onChange,
  onContactMethodCreate,
  onSubmit,
  onTransferSubmit,
  phoneData,
  selected,
  setOpen,
  userId,
}) => {
  const [items, setItems] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [tabValue, setTabValue] = useState("swap");

  // clear selected on tab change
  useEffect(() => {
    onChange(null);
  }, [tabValue]);

  useEffect(() => {
    if (!isOpen) {
      setTabValue("swap");
    }
  }, [isOpen]);

  useEffect(() => {
    if (emailData && isOpen) fetchEmails();
    if (phoneData && isOpen) fetchPhones();
  }, [JSON.stringify(phoneData), JSON.stringify(emailData), isOpen]);

  function onClose() {
    setOpen(false);
  }

  function fetchPhones() {
    axios
      .post(`/users/${userId}/retrieve-phones/`, {
        user_id: userId,
        scope: "active",
      })
      .then(({ data }) => {
        setItems(data.response.map((m) => formatPhone(m)));
        setResultsLoading(false);
      })
      .catch((err) => {
        setItems([]);
        setResultsLoading(false);
      });
  }

  function fetchEmails() {
    axios
      .post(`/users/${userId}/retrieve-emails/`, {
        user_id: userId,
        scope: "active",
      })
      .then(({ data }) => {
        setItems(data.response.map((m) => formatEmail(m)));
        setResultsLoading(false);
      })
      .catch((err) => {
        setItems([]);
        setResultsLoading(false);
      });
  }

  return (
    <div>
      {editable && (
        <Button
          size="xs"
          variant="light"
          onClick={() => setOpen(true)}
          color="gray"
        >
          Change
        </Button>
      )}
      <Modal opened={isOpen} onClose={onClose}>
        <Tabs value={tabValue} onChange={(e) => setTabValue(e)}>
          <Tabs.List>
            <Tabs.Tab value="swap">User Contact Methods</Tabs.Tab>
            <Tabs.Tab value="create">Other Contact Methods</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="swap" mt="lg">
            <Group>{resultsLoading && <Loader size="sm" />}</Group>
            {!resultsLoading && items.length === 0 && (
              <Text fw={600}>This user has no contact methods.</Text>
            )}
            {items.map((s) => (
              <Box mb="sm">
                <Group>
                  <Radio
                    key={s.id}
                    name="contact"
                    onChange={() => onChange(s.id)}
                    checked={s.id === selected}
                    mt="4px"
                    disabled={activeId === s.id}
                  />
                  <div>
                    <Text size="sm" fw={activeId === s.id ? 600 : 400}>
                      {s.value}
                      {activeId === s.id && " (saved)"}
                    </Text>
                  </div>
                </Group>
              </Box>
            ))}
            <Button
              mt="xl"
              fullWidth
              disabled={!selected}
              onClick={() => onSubmit()}
              loading={loading}
            >
              Swap
            </Button>
          </Tabs.Panel>
          <Tabs.Panel value="create" mt="lg">
            <UserContactTransfer
              userId={userId}
              setSelected={(id) => {
                onChange(id);
              }}
              onContactMethodCreate={onContactMethodCreate}
              emailData={emailData}
              loading={loading}
              phoneData={phoneData}
              selected={selected}
              isPhone={isPhone}
              isEmail={isEmail}
              onSubmit={onSubmit}
              onTransferSubmit={onTransferSubmit}
            />
          </Tabs.Panel>
        </Tabs>
      </Modal>
    </div>
  );
};

export default UserContactSelect;
