import React, { useState } from "react";
import axios from "axios";
import { Button, Divider, Group, Modal, Text } from "@mantine/core";
import toast from "react-hot-toast";

import { CurationSearch } from "./";
import entityIcon from "@util/entityIcon";

export default function TransferArtist({ artist, onSuccess }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newArtistId, setNewArtistId] = useState(null);

  function onClose() {
    setNewArtistId(null);
    setOpen(false);
    setLoading(false);
  }

  function onSubmit() {
    const req = {
      from_artist_id: artist.id,
      to_artist_id: newArtistId,
      destroy_artist: true,
    };

    setLoading(true);

    axios
      .post(`/curation-merge-artists/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Migrated!");
        window.location = `/artists/${newArtistId}`;
        // onClose();
        // onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Button
        color="gray"
        variant="light"
        size="xs"
        onClick={() => setOpen(true)}
      >
        Migrate Artist
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <Group align="flex-start">
          <div>{entityIcon.artist(36)}</div>
          <div style={{ flexGrow: 1 }}>
            <Text size="md" fw={600}>
              {artist.name}
            </Text>
            <Text size="sm" c="dimmed">
              Current Artist
            </Text>
          </div>
        </Group>
        <Divider mt="lg" mb="lg" />
        <CurationSearch
          text={`Choose an artist to migrate to`}
          artistId={artist.id}
          isArtist
          onChange={(e) => {
            setNewArtistId(e);
          }}
          value={newArtistId}
        />
        <Divider mt="lg" mb="lg" />
        <Button
          fullWidth
          loading={loading}
          onClick={onSubmit}
          disabled={!newArtistId}
        >
          Migrate
        </Button>
      </Modal>
    </div>
  );
}
