import React, { useState, useEffect } from "react";
import axios from "axios";

import { EmailAppConfig } from "./";

export default function AdminAppConfig() {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/app-configuration-retrieval/`)
      .then(({ data }) => {
        setConfig(data.response[0]);
      })
      .catch((err) => {
        setConfig(null);
      });
  }

  if (!config) return null;

  return (
    <div>
      <EmailAppConfig configId={config.id} />
    </div>
  );
}
