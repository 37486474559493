import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export function getRelativeTime(dateObj) {
  const endAfterNow = dayjs(dayjs(dateObj.end)).isAfter(dayjs(new Date()));
  const startAfterNow = dateObj.start
    ? dayjs(dayjs(dateObj.start)).isAfter(dayjs(new Date()))
    : false;

  return `${
    startAfterNow ? "starts in" : endAfterNow ? "ends in" : "ended"
  } ${dayjs(dateObj.end).fromNow(true)}${!endAfterNow ? " ago" : ""}`;
}
