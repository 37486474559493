import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Box, LoadingOverlay, Divider, Tabs, Space } from "@mantine/core";

import CampaignMeta from "./CampaignMeta";
import CampaignSelect from "./CampaignSelect";
import EffortMeta from "./EffortMeta";
import EffortReport from "./EffortReport";
import EffortSelect from "./EffortSelect";
import HeatMapReport from "./HeatMapReport";
import HourReport from "./HourReport";
import ReportSelect from "./ReportSelect";
import SectionTitle from "./SectionTitle";
import { PageTitle } from "@components/shared";

import entityIcon from "@util/entityIcon";
import { iconSize } from "./helpers";

export default function CampaignReport() {
  const [campaignId, setCampaignId] = useState(null);
  const [effortId, setEffortId] = useState(null);
  const [campaignMeta, setCampaignMeta] = useState(null);
  const [effortMeta, setEffortMeta] = useState(null);
  const [effortReportValue, setEffortReportValue] = useState(2);
  const [campaignReportValue, setCampaignReportValue] = useState(3);
  const [loading, setLoading] = useState(false);

  const managerInfo = useSelector((state) => state.manager);

  useEffect(() => {
    if (managerInfo && managerInfo.location_id) {
      setEffortReportValue(3);
    }
  }, []);

  useEffect(() => {
    if (!campaignId) return;

    fetchCampaignMeta();
  }, [campaignId]);

  function resetEffort() {
    setEffortId(null);
    setEffortMeta(null);
  }

  useEffect(() => {
    if (!effortId) return;

    fetchEffortMeta();
  }, [effortId]);

  function fetchCampaignMeta() {
    setLoading(true);
    axios
      .post(`/retrieve-campaign-meta/`, { campaign_id: campaignId })
      .then(({ data }) => {
        setCampaignMeta(data.response[0].report_meta);
        setLoading(false);
      })
      .catch((err) => {
        setCampaignMeta(null);
        setLoading(false);
      });
  }

  function fetchEffortMeta() {
    setLoading(true);
    axios
      .post(`/retrieve-campaign-effort-meta/`, { campaign_effort_id: effortId })
      .then(({ data }) => {
        setEffortMeta(data.response[0].report_meta);
        setLoading(false);
      })
      .catch((err) => {
        setEffortMeta(null);
        setLoading(false);
      });
  }

  return (
    <div>
      {loading && (
        <Box
          style={{
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            position: "fixed",
            zIndex: 9999,
          }}
        >
          <LoadingOverlay visible overlayBlur={2} />
        </Box>
      )}
      {/* <PageTitle title="Reports" style={{ marginBottom: "1em" }} /> */}
      <SectionTitle title="Effort Report" icon={entityIcon.effort(iconSize)} />
      {/* <CampaignSelect
        value={campaignId}
        onChange={(e) => {
          setCampaignId(e);
          resetEffort();
        }}
      /> */}
      {/* {campaignMeta && (
        <React.Fragment>
          <Space h="sm" />
          <CampaignMeta info={campaignMeta} />
          <Space h="sm" />
        </React.Fragment>
      )} */}
      <EffortSelect
        // campaignId={campaignId}
        value={effortId}
        onChange={(e) => setEffortId(e)}
      />
      {effortMeta && effortId && (
        <React.Fragment>
          <Space h="sm" />
          <EffortMeta info={effortMeta} />
          <Space h="sm" />
          <Divider mb="sm" />
        </React.Fragment>
      )}
      {effortId && (
        <React.Fragment>
          <ReportSelect
            value={effortReportValue}
            onChange={(e) => setEffortReportValue(e)}
          />
          <EffortReport reportType={effortReportValue} effortId={effortId} />
        </React.Fragment>
      )}
      {campaignId && (
        <Tabs keepMounted={false} defaultValue="campaign">
          <Tabs.List>
            <Tabs.Tab value="campaign">Campaign</Tabs.Tab>
            <Tabs.Tab value="effort">Effort</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="campaign">
            <Space h="md" />
            <ReportSelect
              isCampaign
              value={campaignReportValue}
              onChange={(e) => setCampaignReportValue(e)}
            />
            {campaignReportValue === 4 && (
              <HourReport campaignId={campaignId} />
            )}
            {campaignReportValue === 3 && (
              <HeatMapReport campaignId={campaignId} />
            )}
            {campaignReportValue === 5 && (
              <React.Fragment>
                <Space h="md" />
                <KeywordReport campaignId={campaignId} />
              </React.Fragment>
            )}
          </Tabs.Panel>
          <Tabs.Panel value="effort">
            <Space mt="lg" />
            <EffortSelect
              campaignId={campaignId}
              value={effortId}
              onChange={(e) => setEffortId(e)}
            />
            {effortMeta && (
              <React.Fragment>
                <Space h="sm" />
                <EffortMeta info={effortMeta} />
                <Space h="sm" />
                <Divider mb="sm" />
              </React.Fragment>
            )}
            {effortId && (
              <React.Fragment>
                <ReportSelect
                  value={effortReportValue}
                  onChange={(e) => setEffortReportValue(e)}
                />
                <EffortReport
                  reportType={effortReportValue}
                  effortId={effortId}
                />
              </React.Fragment>
            )}
          </Tabs.Panel>
        </Tabs>
      )}
    </div>
  );
}
