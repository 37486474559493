import React, { useState } from "react";
import axios from "axios";
import {
  Anchor,
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Text,
  Title,
} from "@mantine/core";
import { IconLock } from "@tabler/icons-react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function UserLockManagement({ lockData = null, fetchData }) {
  if (!lockData) return null;

  const managerInfo = useSelector((state) => state.manager);
  return (
    <Card>
      <Flex align="center" gap="xs">
        <IconLock />
        <Title order={4}>User Lock</Title>
        <Badge variant="light" color="red">
          {lockData.variety_formatted}
        </Badge>
      </Flex>
      {!managerInfo && <Text>{lockData.comment}</Text>}
      <Text size="xs" c="dimmed" mt="sm">
        {new Date(lockData.created_at).toLocaleString()}
      </Text>
      {!managerInfo && (
        <Box mt="xs">
          <Flex gap="sm" align="center">
            {lockData.primary_user_id && (
              <Text size="sm">
                <Anchor
                  href={`/users/${lockData.primary_user_id}`}
                  target="_blank"
                >
                  View Primary User
                </Anchor>
              </Text>
            )}
            {lockData.secondary_user_id && (
              <Text size="sm">
                <Anchor
                  href={`/users/${lockData.secondary_user_id}`}
                  target="_blank"
                >
                  View Secondary User
                </Anchor>
              </Text>
            )}
          </Flex>
          <Divider mt="sm" mb="sm" />
          <Flex gap="xs">
            {lockData.primary_user_id && (
              <UnlockButton
                text={`Unlock Primary`}
                onSuccess={fetchData}
                reqInfo={{
                  lock_id: lockData.id,
                  primary: true,
                  secondary: false,
                }}
              />
            )}
            {lockData.secondary_user_id && (
              <UnlockButton
                text={`Unlock Secondary`}
                onSuccess={fetchData}
                reqInfo={{
                  lock_id: lockData.id,
                  primary: false,
                  secondary: true,
                }}
              />
            )}
            {lockData.primary_user_id && lockData.secondary_user_id && (
              <UnlockButton
                text={`Unlock Both`}
                onSuccess={fetchData}
                reqInfo={{
                  lock_id: lockData.id,
                  primary: true,
                  secondary: true,
                }}
              />
            )}
          </Flex>
        </Box>
      )}
    </Card>
  );
}

const UnlockButton = ({ text, reqInfo = {}, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = { ...reqInfo };

    axios
      .post(`/users/unlock/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Unlocked!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      variant="light"
      color="gray"
      size="xs"
      loading={loading}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};
