import React from "react";

import { OrganizationManagement } from "@components/Organization";

export default function OrganizationIndex() {
  return (
    <React.Fragment>
      <OrganizationManagement />
    </React.Fragment>
  );
}
