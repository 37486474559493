import React, { useState } from "react";
import { Button } from "@mantine/core";

import { StatBlocks } from "@components/DataViz";

const EffortMeta = ({ info }) => {
  const [period, setPeriod] = useState("allTime");
  const dayStats = [
    { text: "Registrations", value: info.registrations_24 },
    { text: "Check In", value: info.check_ins_24 },
    { text: "Keywords Claimed", value: info.keywords_claimed_24 },
    { text: "Total Engagements", value: info.total_engagements_24 },
    { text: "Avg Engagements/Customer", value: info.average_engagements_24 },
  ].map((m, i) => ({
    ...m,
    key: i,
    text: `${m.text}`,
    value: m.value === undefined ? "-" : m.value,
  }));
  const allTimeStats = [
    { text: "Registrations", value: info.registrations_all_time },
    { text: "Check In", value: info.check_ins_all_time },
    { text: "Keywords Claimed", value: info.keywords_claimed_all_time },
    { text: "Total Engagements", value: info.engagements_all_time },
    {
      text: "Avg Engagements/Customer",
      value: info.average_engagements_all_time,
    },
  ].map((m, i) => ({
    ...m,
    key: i,
    value: m.value === undefined ? "-" : m.value,
  }));
  return (
    <div>
      <Button.Group mb="sm">
        <Button
          onClick={() => setPeriod("daily")}
          size="xs"
          variant={period === "daily" ? "filled" : "light"}
        >
          24H
        </Button>
        <Button
          onClick={() => setPeriod("allTime")}
          size="xs"
          variant={period === "allTime" ? "filled" : "light"}
        >
          All Time
        </Button>
      </Button.Group>
      {period === "daily" && <StatBlocks data={[...dayStats]} />}
      {period === "allTime" && <StatBlocks data={[...allTimeStats]} />}
    </div>
  );
};

export default EffortMeta;
