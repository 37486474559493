import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Anchor, Grid, Text, Title } from "@mantine/core";

export default function EmailServerTemplateList({
  postmarkServerId,
  postmarkServerName,
  locationId,
  organizationId,
}) {
  const [templates, setTemplates] = useState([]);

  if (!postmarkServerId) return null;

  useEffect(() => {
    fetchTemplates();
  }, []);

  function fetchTemplates() {
    const req = {};

    if (organizationId) req.organization_id = organizationId;
    if (locationId) req.location_id = locationId;

    axios
      .post(`/postmark-servers/retrieve-templates/`, req)
      .then(({ data }) => {
        setTemplates(data.response.filter((f) => f.TemplateType !== "Layout"));
      })
      .catch((err) => {
        setTemplates([]);
      });
  }

  return (
    <div>
      <Title
        order={4}
        component={Anchor}
        target="_blank"
        c="dark"
        href={`https://account.postmarkapp.com/servers/${postmarkServerId}/streams`}
      >
        {postmarkServerName} Templates
      </Title>
      <Grid mt="sm">
        {templates.map((t) => (
          <Grid.Col span={{ base: 12, md: 4 }} key={t.TemplateId}>
            <Card>
              <Text
                fw={600}
                component="a"
                href={`https://account.postmarkapp.com/servers/${postmarkServerId}/templates/${t.TemplateId}/edit`}
                target="_blank"
              >
                {t.Name}
              </Text>
              <Text c="dimmed">{t.Alias}</Text>
              <Text c="dimmed">{t.TemplateId}</Text>
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
}
