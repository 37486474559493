import React, { useState } from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  Grid,
  Group,
  Modal,
  Table,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { Link } from "react-router-dom";

import {
  CurateButton,
  CurationBulkSongActions,
  CurationEdit,
  CurationTitleizeButton,
  TransferSong,
} from "./";
import entityIcon from "@util/entityIcon";

export default function SongList({ artist, items = [], fetchData }) {
  const filteredItems = [...items];
  const curatedSongs = items
    .filter((f) => !f.curate)
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  const uncuratedSongs = items
    .filter((f) => f.curate)
    .sort((a, b) => b.ratings_count - a.ratings_count);
  // .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  return (
    <div>
      <Grid>
        <Grid.Col span={{ base: 6 }}>
          <Group mb="sm">
            <Badge variant="light" color="yellow">
              Uncurated Songs ({uncuratedSongs.length})
            </Badge>
            {uncuratedSongs.length > 0 && (
              <CurationBulkSongActions
                items={uncuratedSongs}
                artistId={artist.id}
                artistName={artist.name}
                onSuccess={fetchData}
              />
            )}
          </Group>
          {uncuratedSongs.length > 0 && (
            <SongsContainer>
              <SongTable>
                {uncuratedSongs.map((item) => (
                  <SongContainer
                    key={item.id}
                    artist={artist}
                    songName={item.name}
                    artistName={artist.name}
                    id={item.id}
                    fetchData={fetchData}
                    songData={item}
                  >
                    <CurationTitleizeButton
                      onSuccess={fetchData}
                      value={item.name}
                      reqData={{
                        song_id: item.id,
                      }}
                      id={item.id}
                    />
                    <TransferSong
                      items={filteredItems}
                      artist={artist}
                      onSuccess={fetchData}
                      songId={item.id}
                      songName={item.name}
                      redirect={false}
                    />
                    {!artist.curate && (
                      <CurateButton
                        buttonProps={{
                          size: "xs",
                          color: "gray",
                          variant: "light",
                        }}
                        onSuccess={fetchData}
                        reqData={{
                          song_ids: [item.id],
                        }}
                      />
                    )}
                  </SongContainer>
                ))}
              </SongTable>
            </SongsContainer>
          )}
        </Grid.Col>
        <Grid.Col span={{ base: 6 }}>
          <Group mb="sm">
            <Badge variant="light" color="green">
              Curated Songs ({curatedSongs.length})
            </Badge>
            {curatedSongs.length > 0 && (
              <CurationBulkSongActions
                artistId={artist.id}
                artistName={artist.name}
                items={curatedSongs}
                onSuccess={fetchData}
                showCurate={false}
              />
            )}
          </Group>
          {curatedSongs.length > 0 && (
            <SongsContainer>
              <SongTable>
                {curatedSongs.map((item) => (
                  <SongContainer
                    key={item.id}
                    id={item.id}
                    fetchData={fetchData}
                    songName={item.name}
                    artistName={artist.name}
                    artist={artist}
                    isCurated
                    songData={item}
                  >
                    <CurationTitleizeButton
                      onSuccess={fetchData}
                      value={item.name}
                      reqData={{
                        song_id: item.id,
                      }}
                      id={item.id}
                    />
                    <TransferSong
                      items={filteredItems}
                      artist={artist}
                      onSuccess={fetchData}
                      songId={item.id}
                      songName={item.name}
                    />
                  </SongContainer>
                ))}
              </SongTable>
            </SongsContainer>
          )}
        </Grid.Col>
      </Grid>
    </div>
  );
}

const SongsContainer = ({ children }) => (
  <Card
    mb="sm"
    p="4px"
    style={{
      maxHeight: "600px",
      overflowY: "scroll",
    }}
  >
    {children}
  </Card>
);

const SongTable = ({ children }) => (
  <Table verticalSpacing="sm">
    <Table.Tbody>{children}</Table.Tbody>
  </Table>
);

const SongContainer = ({
  id,
  fetchData,
  songName,
  artist,
  artistName,
  songData,
  children,
  isCurated = false,
}) => {
  const [editing, setEditing] = useState(false);

  return (
    <Table.Tr>
      <Table.Td>
        <div>
          <UnstyledButton onClick={() => setEditing(true)}>
            <Text fw={600} size="md">
              {songName}
            </Text>
          </UnstyledButton>
          <Group>
            <Text size="xs" c="dimmed">
              {artist.name}
            </Text>
          </Group>
          {songData && (
            <Box mb="lg">
              <Text size="xs" c="dimmed">
                {songData.plays_count} play
                {songData.plays_count === 1 ? "" : "s"}
              </Text>
              <Text size="xs" c="dimmed">
                {songData.ratings_count} rating
                {songData.ratings_count === 1 ? "" : "s"}
              </Text>
            </Box>
          )}
          <Group mt="xs">{children}</Group>
        </div>
      </Table.Td>
      <Modal opened={editing} onClose={() => setEditing(false)}>
        <CurationEdit
          id={id}
          value={songName}
          fetchData={() => {
            setEditing(false);
            fetchData();
          }}
          reqData={{
            song_id: id,
          }}
        />
        <Group justify="center">
          <Button
            component={Link}
            to={isCurated ? `/songs/${id}` : `/curation-song/${id}`}
            variant="subtle"
            color="gray"
            mt="md"
            size="xs"
          >
            {isCurated ? "View song" : "View curation item"}
          </Button>
        </Group>
      </Modal>
    </Table.Tr>
  );
};
