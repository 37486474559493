import React, { useState } from "react";
import { Group, TextInput } from "@mantine/core";

import { EmailValidationItem, EmailValidationWidget } from "./";
import { PhoneValidationWidget } from "@components/PhoneValidation";
import { PaginatedList } from "@components/shared";

export default function EmailValidationList() {
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);

  const reqData = {
    search_value: searchValue,
    refresh,
  };

  return (
    <div>
      <Group>
        <EmailValidationWidget
          fetchData={() => {
            setRefresh(!refresh);
          }}
        />
        <PhoneValidationWidget />
      </Group>
      <TextInput
        placeholder="Search..."
        size="lg"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <PaginatedList
        requestInfo={{
          url: `/email-validations/retrieve-validations/`,
          data: reqData,
        }}
        responseMapping={(r) => r.map((m) => ({ ...m }))}
        displayFormatter={(item) => (
          <EmailValidationItem
            key={item.original.id}
            id={item.original.id}
            data={item.original}
            fetchData={() => {
              setRefresh(!refresh);
            }}
          />
        )}
      />
    </div>
  );
}
