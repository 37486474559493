import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { BasicForm } from "@components/shared";

export default function AdminForm({
  id = null,
  email = "",
  firstName = "",
  lastName = "",
  mfa = false,
  onSuccess = () => {},
}) {
  const [loading, setLoading] = useState(false);

  function onSubmit(formData) {
    setLoading(true);

    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    axios
      .post(`/admins/`, { ...formData })
      .then(() => setLoading(false))
      .then(() => toast.success("Created!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      admin_id: id,
    };

    axios
      .put(`/admins/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "first_name",
      label: "First Name",
      initialValue: firstName,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required"),
    },
    {
      name: "last_name",
      label: "Last Name",
      initialValue: lastName,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required"),
    },
    {
      name: "email",
      label: "Email",
      initialValue: email,
      required: true,
      schema: () => Yup.string().max(100, "Too long!").required("Required"),
    },
    // {
    //   name: "mfa",
    //   label: "Multi-Factor Authentication",
    //   initialValue: mfa,
    //   required: false,
    //   schema: () => null,
    //   toggle: true,
    // },
  ];

  return (
    <BasicForm
      buttonProps={{
        fullWidth: !id ? true : false,
      }}
      loading={loading}
      onSubmit={onSubmit}
      fields={fields}
    />
  );
}
