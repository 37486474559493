import React, { useState, useEffect } from "react";
import { MultiSelect } from "@mantine/core";
import axios from "axios";

export default function SongQuerySelect({ value, onChange, reqData }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  function fetchOptions() {
    const req = {
      ...reqData,
      pagination: true,
      search_value: "",
      page_size: 500,
      page: 0,
      status: 1,
    };

    axios
      .post(`/retrieve-song-queries/`, req)
      .then(({ data }) => {
        setOptions(
          data.response[0].data.map((d) => ({
            label: d.name,
            value: d.id,
          }))
        );
      })
      .catch((err) => {
        setOptions([]);
      });
  }

  return (
    <MultiSelect
      label="Categories (choose at least one)"
      value={value}
      data={options}
      onChange={(e) => onChange(e)}
      placeholder="Select..."
      searchable
    />
  );
}
