import React from "react";

import { ApiRequestList } from "@components/ApiRequest";

export default function ApiRequestIndex() {
  return (
    <div>
      <ApiRequestList />
    </div>
  );
}
