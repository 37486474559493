import React from "react";
import { Anchor, Text, Group } from "@mantine/core";
import { IconLink } from "@tabler/icons-react";

export default function LinkDisplay({ links = [] }) {
  if (!links || links.length === 0) return null;

  return (
    <Group justify="center" mt="lg" spacing="xl">
      {links.map((link) => (
        <Text key={link.id} fw={600}>
          <Anchor key={link.id} href={link.url} target="_blank" c="dimmed">
            {link.text}{" "}
            <IconLink style={{ top: "5px", position: "relative" }} />
          </Anchor>
        </Text>
      ))}
    </Group>
  );
}
