import React, { useState } from "react";
import { Button } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

export default function StationFeedLoaderButton({ locationId }) {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {
      location_id: locationId,
    };

    axios
      .post(`/feed-loader/initiate/`, req)
      .then(() => {
        toast.success("Initiated!");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <Button
      size="xs"
      mb="lg"
      onClick={onClick}
      loading={loading}
      color="gray"
      variant="subtle"
    >
      Build feed loader
    </Button>
  );
}
