import React from "react";
import { Input } from "@mantine/core";

export default function TimeField({
  label = "Time",
  required = false,
  value = "",
  onChange,
  disabled = false,
}) {
  return (
    <Input.Wrapper label={label} required={required}>
      <Input
        type="time"
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </Input.Wrapper>
  );
}
