export function formatArtistList(artists) {
  if (!Array.isArray(artists)) return "No artist data";
  return new Intl.ListFormat("en", {
    style: "short",
    type: "conjunction",
  }).format(artists.map((m) => m.name));
}

export function titleize(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
