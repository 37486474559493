import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import axios from "axios";
import { Provider } from "react-redux";
import { MantineProvider } from "@mantine/core";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/tiptap/styles.css";

import { store } from "./duck";

import App from "./App";

import "@style/main.scss";
import axiosDefaults from "@util/axiosDefaults";

axios.defaults.baseURL = "/api";

if (import.meta.env.VITE_ENV !== "dev") {
  Sentry.init({
    dsn: "https://2af8e8f939e70b79b5fb6048ca473f53@o4504533135392768.ingest.sentry.io/4506230680780800",
    environment: import.meta.env.VITE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
      Sentry.breadcrumbsIntegration({
        console: true,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function ReactApp() {
  return (
    <MantineProvider
      theme={{
        components: {
          Card: {
            defaultProps: {
              radius: "md",
              p: "sm",
              withBorder: true,
              style: {
                overflow: "visible",
              },
            },
          },
          Switch: {
            defaultProps: {
              style: {
                label: {
                  marginRight: "5px",
                },
              },
            },
          },
          // Paper: {
          //   styles: {
          //     root: {
          //       overflowY: "initial !important",
          //     },
          //   },
          // },
        },
        fontFamily: "DM Sans",
        fontFamilyMonospace: "DM Sans, monospace",
        headings: { fontFamily: "DM Sans, sans-serif" },
      }}
    >
      <Provider store={store}>
        <App style={{ height: "100%" }} />
      </Provider>
    </MantineProvider>
  );
}

const container = document.getElementById("app");
const root = createRoot(container);

root.render(<ReactApp />);
