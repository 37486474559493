import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Button,
  Card,
  Group,
  Title,
  Divider,
  Space,
  Text,
  List,
  Flex,
  ThemeIcon,
} from "@mantine/core";
import { IconCheck, IconX, IconBell } from "@tabler/icons-react";

const btns = [
  // { text: "created", value: 1, color: "yellow" },
  { text: "on", value: 2, color: "green" },
  { text: "off", value: 3, color: "red" },
  { text: "deleted", value: 4, color: "yellow" },
].map((m) => ({
  ...m,
  key: m.value,
}));

export default function CampaignStatus({
  campaignId,
  fetchData,
  status = 1,
  liveStatus = {},
  hasChangesToPublish = false,
  isLive = false,
}) {
  const [loading, setLoading] = useState(false);

  function onClick(newStatus) {
    const req = {
      campaign_id: campaignId,
      status: newStatus,
    };

    setLoading(true);

    axios
      .put(`/campaigns/${campaignId}/set-status/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Status changed!");
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const items = [
    { text: "active", value: liveStatus.active },
    { text: "live", value: liveStatus.is_live },
    { text: "turned on", value: liveStatus.turned_on },
    // {
    //   text: "published configuration",
    //   value: liveStatus.published_configuration,
    // },
  ];

  const SectionDivider = ({ text }) => <Divider my="sm" label={text} />;

  return (
    <Card>
      <SectionDivider text="Campaign Checkup" />
      <div>
        {items.map((item, i) => (
          <Flex key={i} align="center" gap="xs" mb="xs">
            <ThemeIcon
              variant="filled"
              radius="xl"
              size="sm"
              color={item.value ? "green" : "red"}
            >
              {item.value ? <IconCheck /> : <IconX />}{" "}
            </ThemeIcon>
            <Text>{item.text}</Text>
          </Flex>
        ))}
      </div>
      <SectionDivider text="Status" />
      <Group spacing="xs">
        {btns.map((b) => (
          <Button
            key={b.key}
            onClick={() => onClick(b.value)}
            color={b.color}
            size="xs"
            variant={status === b.value ? "filled" : "light"}
          >
            {b.text}
          </Button>
        ))}
      </Group>
      <SectionDivider text="Publish" />
      <PublishButton campaignId={campaignId} onSuccess={fetchData} />
      {hasChangesToPublish && (
        <Text
          style={{ display: "flex", alignItems: "center" }}
          mt="xs"
          size="xs"
        >
          <ThemeIcon
            // variant="filled"
            variant="light"
            radius="xl"
            mr="xs"
            size="sm"
            color="pink.4"
          >
            <IconBell />
          </ThemeIcon>{" "}
          Unpublished Changes
        </Text>
      )}
    </Card>
  );
}

const PublishButton = ({ campaignId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);
    axios
      .put(`/campaigns/${campaignId}/publish-app-config/`, {})
      .then(() => {
        setLoading(false);
        toast.success("Published");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button loading={loading} onClick={onClick} size="xs" color="green">
      Publish campaign
    </Button>
  );
};
