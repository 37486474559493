import React, { useState } from "react";
import axios from "axios";
import { Group, Button } from "@mantine/core";
import toast from "react-hot-toast";

import { IconRefresh, IconMoodSmile, IconChecklist } from "@tabler/icons-react";

import { colors } from "./helpers";

export default function TicketStatus({ id, status, fetchData }) {
  const [loading, setLoading] = useState(false);

  const buttons = [
    {
      text: "Reset ticket",
      value: 1,
      color: "yellow",
      icon: <IconRefresh size={16} />,
    },
    {
      text: "Claim ticket",
      value: 2,
      color: "blue",
      icon: <IconChecklist size={16} />,
    },
    {
      text: "Close ticket",
      value: 3,
      color: "teal",
      icon: <IconMoodSmile size={16} />,
    },
  ];

  function onClick(val) {
    const req = {
      ticket_id: id,
      status: val,
    };

    axios
      .put(`/trouble-tickets/${id}/`, req)
      .then(() => {
        toast.success("Updated!");
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Group>
      {buttons.map((b) => (
        <Button
          size="xs"
          key={b.value}
          onClick={() => onClick(b.value)}
          variant={"subtle"}
          color={b.color}
          disabled={b.value === status}
        >
          <Group>
            {b.icon}
            {b.text}
          </Group>
        </Button>
      ))}
    </Group>
  );
}
