import React from "react";

import { EffortManagement } from "@components/Effort";

export default function EffortIndex() {
  return (
    <React.Fragment>
      <EffortManagement />
    </React.Fragment>
  );
}
