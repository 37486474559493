import React, { useState, useEffect } from "react";
import { Box, Badge, Button, Modal, Divider } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { ContestPerson } from "./";

function toggleDisabled(cls, disabled) {
  const els = document.getElementsByClassName(cls);

  if (!els) return;

  Array.from(els).forEach((el) => {
    el.disabled = disabled;
  });
}

export const DrawFinalistsButton = ({
  isRedraw = false,
  entryId,
  contestId,
  prizeId,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {
      contest_id: contestId,
      prize_id: prizeId,
    };

    if (entryId) req.entry_id = entryId;

    axios
      .post(`/contests/${contestId}/conduct-draw/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      color="gray"
      variant="light"
      onClick={onClick}
      loading={loading}
      size="xs"
      mr="sm"
    >
      {isRedraw ? "redraw" : "draw finalists"}
    </Button>
  );
};

export const DrawWinnerButton = ({ prizeId, contestId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    toggleDisabled("draw-btn", true);

    setLoading(true);

    const req = {
      prize_id: prizeId,
      contest_id: contestId,
      draw_winner: true,
    };

    axios
      .post(`/contests/${contestId}/conduct-draw/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        toggleDisabled("draw-btn", false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
        toggleDisabled("draw-btn", false);
      });
  }

  return (
    <Button
      size="xs"
      variant="light"
      mr="sm"
      color="gray"
      onClick={onClick}
      loading={loading}
      className="draw-btn"
    >
      draw winner
    </Button>
  );
};

export const NotifyWinnerButton = ({
  entryId,
  contestId,
  prizeId,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {
      contest_id: contestId,
      prize_id: prizeId,
      contest_entry_id: entryId,
    };

    axios
      .post(`/contests/${contestId}/notify-winner/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        toast.success("Winner Notified!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      size="xs"
      variant="light"
      mr="sm"
      color="gray"
      onClick={onClick}
      loading={loading}
    >
      notify winner
    </Button>
  );
};

export const StatusButton = ({
  contestId,
  onSuccess,
  reqData = {},
  buttonText = "button",
}) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    toggleDisabled("draw-btn", true);

    setLoading(true);

    const req = {
      ...reqData,
      contest_id: contestId,
    };

    axios
      .post(`/contests/${contestId}/update-entry/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        toggleDisabled("draw-btn", false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
        toggleDisabled("draw-btn", false);
      });
  }

  return (
    <Button
      size="xs"
      variant="light"
      mr="sm"
      color="gray"
      onClick={onClick}
      loading={loading}
    >
      {buttonText}
    </Button>
  );
};

export const DemotedEntrants = ({
  prizeHasWinner,
  items = [],
  contestId,
  fetchData,
}) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  if (!items.length) return null;

  return (
    <React.Fragment>
      <Button
        size="xs"
        variant="light"
        mr="sm"
        color="gray"
        onClick={() => setOpen(true)}
      >
        view excluded entrants
      </Button>
      <Modal opened={isOpen} onClose={onClose} title="Excluded Entrants">
        {items.map((item, i) => (
          <div key={item.entry_id}>
            <ContestPerson
              key={item.entry_id}
              name={item.name}
              email={item.email}
              phone={item.phone}
              locationName={item.location_name}
              badge={
                <React.Fragment>
                  <Badge
                    variant="light"
                    color={item.eligible ? "green" : "red"}
                  >
                    {item.eligible ? "eligible" : "ineligible"}
                  </Badge>
                </React.Fragment>
              }
            />
            <Box mt="sm">
              {/* <StatusButton
                contestId={contestId}
                buttonText="demote"
                onSuccess={() => {
                  fetchData();
                  onClose();
                }}
                reqData={{
                  contest_entry_id: item.entry_id,
                  demote: true,
                }}
              /> */}
              {item.eligible && !prizeHasWinner && (
                <StatusButton
                  contestId={contestId}
                  buttonText="restore"
                  onSuccess={() => {
                    fetchData();
                    onClose();
                  }}
                  reqData={{
                    contest_entry_id: item.entry_id,
                    promote: true,
                  }}
                />
              )}
              <StatusButton
                contestId={contestId}
                buttonText={item.eligible ? "make ineligible" : "make eligible"}
                onSuccess={() => {
                  fetchData();
                  onClose();
                }}
                reqData={{
                  contest_entry_id: item.entry_id,
                  ineligible: item.eligible ? true : false,
                  eligible: item.eligible ? false : true,
                }}
              />
            </Box>
            {i < items.length - 1 && <Divider mt="sm" mb="sm" />}
          </div>
        ))}
      </Modal>
    </React.Fragment>
  );
};
