import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  ActionIcon,
  Divider,
  Box,
  Card,
  Flex,
  Loader,
  Title,
  TextInput,
  Pagination,
  Button,
  Menu,
  Select,
  SegmentedControl,
  Skeleton,
  Badge,
  Table,
  Text,
  Anchor,
} from "@mantine/core";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { IconCheck, IconSearch } from "@tabler/icons-react";
import dayjs from "dayjs";

import { OrganizationSelect } from "@components/Organization";
import { LocationSelect } from "@components/Location";
import { DateField } from "@components/shared";
import entityIcon from "@util/entityIcon";

const today = dayjs().format("YYYY-MM-DDTHH:mm:ss");

const searchByOptions = [
  { text: "To", value: "to" },
  { text: "From", value: "from" },
  { text: "Tag", value: "tag" },
  { text: "Subject", value: "subject" },
];

const dateRangeOptions = [
  {
    text: "All Time",
    value: "all",
    dates: {
      start: "",
      end: "",
    },
  },
  {
    text: "Today",
    value: 0,
    dates: {
      start: getStartDate(0),
      end: today,
    },
  },
  {
    text: "Last 7 days",
    value: 7,
    dates: {
      start: getStartDate(7),
      end: today,
    },
  },
  {
    text: "Last 28 days",
    value: 28,
    dates: {
      start: getStartDate(28),
      end: today,
    },
  },
  {
    text: "Last 45 days",
    value: 45,
    dates: {
      start: getStartDate(45),
      end: today,
    },
  },
];

export const formatRecipients = new Intl.ListFormat("en", {
  style: "long",
  type: "conjunction",
});

export default function EmailActivityList({ locationId, organizationId }) {
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  const [dateValue, setDateValue] = useState("all");
  const [searchByValue, setSearchByValue] = useState("to");
  const [organizationIdValue, setOrganizationIdValue] = useState("");
  const [locationIdValue, setLocationIdValue] = useState("");
  const [dates, setDates] = useState({
    start: "",
    end: "",
  });
  const [dateMenuOpen, setDateMenuOpen] = useState(false);
  const [messageType, setMessageType] = useState("email");
  const [messageSource, setMessageSouce] = useState("all");
  const [columnVisibility, setColumnVisibility] = useState(
    getFilteredVisibility("email")
  );
  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    setLocationIdValue(locationId);
  }, [locationId]);

  useEffect(() => {
    setOrganizationIdValue(organizationId);
  }, [organizationId]);

  const table = useReactTable({
    data: items,
    columns,
    manualPagination: true,
    enableMultiSort: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  // useEffect(() => {
  //   fetchData();
  // }, [locationId, organizationId]);

  useEffect(() => {
    if (
      (locationId || organizationId) &&
      !locationIdValue &&
      !organizationIdValue
    )
      return;
    let p = new Promise((resolve, reject) => {
      resolve(table.setPageIndex(0));
    });
    p.then((result) => {
      fetchData();
    }).catch((error) => {
      console.log(error);
    });
  }, [
    JSON.stringify(dates),
    statusValue,
    searchByValue,
    messageSource,
    locationIdValue,
    organizationIdValue,
  ]);

  useEffect(() => {
    if (!locationIdValue && !organizationIdValue) return;
    fetchData();
  }, [JSON.stringify(table.getState().pagination)]);

  function onSearchSubmit() {
    let p = new Promise((resolve, reject) => {
      resolve(table.setPageIndex(0));
    });
    p.then(() => {
      fetchData();
    }).catch((error) => {
      console.log(error);
    });
  }

  function fetchData() {
    if (!locationIdValue && !organizationIdValue) return;
    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      page_size: table.getState().pagination.pageSize,
      page_count:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
      value: searchValue,
      [searchByValue]: true,
      organization_id: organizationIdValue,
      location_id: locationIdValue,
    };

    if (statusValue !== "all") req.status = statusValue;
    if (dates.start) req.from_date = dates.start;
    if (dates.end) req.to_date = dates.end;

    axios
      .post(`/somewhere/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setItems(data.response[0].Messages);
        if (data.response[0].TotalCount !== undefined) {
          setTotal(data.response[0].TotalCount);
          setPageCount(
            Math.round(
              data.response[0].TotalCount / table.getState().pagination.pageSize
            )
          );
        } else {
          setTotal(data.response[0].Messages.length);
        }
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  function onMessageSourceChange(e) {
    let p = new Promise((resolve, reject) => {
      resolve();
    });
    p.then(() => {
      setMessageType(e);
      setColumnVisibility(getFilteredVisibility(e));
      setMessageSouce("all");
      table.setPageIndex(0);
    })
      .then(() => fetchData())
      .catch((error) => {
        console.log(error);
      });
  }

  const selectedDateOption = dateRangeOptions.find(
    (f) => f.value === dateValue
  );

  const selectedSearchByOption = searchByOptions.find(
    (f) => f.value === searchByValue
  );

  const selectedMessageType = messageTypeOptions.find(
    (f) => f.value === messageType
  );

  return (
    <div>
      <Flex gap="xs" align="center" mb="sm">
        <Title order={4}>Messages</Title>
        {loading && <Loader size="xs" />}
      </Flex>
      {!organizationId && !locationId && (
        <Flex gap="xs" mb="sm">
          <Box style={{ flexGrow: 1 }}>
            <OrganizationSelect
              label="Organization"
              value={organizationIdValue}
              onChange={(e) => {
                setOrganizationIdValue(e);
              }}
              disabled={locationIdValue ? true : false}
            />
          </Box>
          <Box style={{ flexGrow: 1 }}>
            <LocationSelect
              label="Location"
              value={locationIdValue}
              onChange={(e) => setLocationIdValue(e)}
              disabled={organizationIdValue ? true : false}
            />
          </Box>
        </Flex>
      )}
      <Skeleton
        visible={!locationIdValue && !organizationIdValue ? true : false}
        height={"600px"}
      >
        <Flex gap="xs" mb="sm">
          <SegmentedControl
            value={messageType}
            onChange={(e) => onMessageSourceChange(e)}
            data={messageTypeOptions.map((m) => ({
              label: m.text,
              value: m.value,
            }))}
          />
          {/* {messageTypeOptions.map((m, i) => (
            <Button
              key={i}
              variant={m.value === messageType ? "filled" : "light"}
              size="xs"
              color={m.value === messageType ? "blue" : "gray"}
              onClick={() => {
                onMessageSourceChange(m.value);
              }}
            >
              {m.text}
            </Button>
          ))} */}
          <Text
            ml="sm"
            mr="sm"
            color="gray"
            style={{ opacity: 0.45, fontSize: "24px" }}
          >
            |
          </Text>
          {selectedMessageType && (
            <SegmentedControl
              value={messageSource}
              onChange={(e) => setMessageSouce(e)}
              data={selectedMessageType.options.map((m) => ({
                label: m.text,
                value: m.value,
              }))}
            />
          )}
          {/* {selectedMessageType && (
            <Flex gap="xs">
              {selectedMessageType.options.map((m, i) => (
                <Button
                  key={i}
                  variant={m.value === messageSource ? "filled" : "light"}
                  size="xs"
                  onClick={() => setMessageSouce(m.value)}
                  color={m.value === messageSource ? "blue" : "gray"}
                >
                  {m.text}
                </Button>
              ))}
            </Flex>
          )} */}
        </Flex>
        <TextInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={selectedMessageType.search_text}
          size="md"
          style={{
            flexGrow: 1,
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearchSubmit();
            }
          }}
        />
        {/* <Flex style={{ width: "100%" }} gap="xs" align="center">
        <TextInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search..."
          style={{
            flexGrow: 1,
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearchSubmit();
            }
          }}
        />
        <Menu>
          <Menu.Target>
            <Button
              style={{ minWidth: "110px" }}
              variant="light"
              color="gray"
              leftSection={<IconSearch size={16} />}
            >
              {selectedSearchByOption ? selectedSearchByOption.text : "Custom"}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {searchByOptions.map((item, i) => (
              <Menu.Item
                key={i}
                leftSection={item.value === searchByValue ? <IconCheck /> : ""}
                onClick={() => {
                  setSearchByValue(item.value);
                }}
              >
                {item.text}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
        <Select
          // label="Status"
          value={statusValue}
          onChange={(e) => setStatusValue(e)}
          data={[
            { label: "All", value: "all" },
            { label: "Queued", value: "queued" },
            { label: "Sent", value: "sent" },
          ]}
        />
        <Menu
          opened={dateMenuOpen}
          onChange={() => setDateMenuOpen(!dateMenuOpen)}
          closeOnItemClick={false}
        >
          <Menu.Target>
            <Button
              style={{ minWidth: "160px" }}
              variant="light"
              leftSection={entityIcon.calendar()}
              color="gray"
            >
              {selectedDateOption ? selectedDateOption.text : "Custom"}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {dateRangeOptions.map((item, i) => (
              <Menu.Item
                key={i}
                leftSection={item.value === dateValue ? <IconCheck /> : ""}
                onClick={() => {
                  setDateValue(item.value);
                  setDates(item.dates);
                  setDateMenuOpen(false);
                }}
              >
                {item.text}
              </Menu.Item>
            ))}
            <Menu.Item>
              <Flex gap="xs" align="center">
                <DateField
                  label="From"
                  value={dates.start}
                  onChange={(e) => {
                    setDateValue("");
                    setDates({
                      ...dates,
                      start: e,
                    });
                  }}
                />
                <DateField
                  label="To"
                  value={dates.end}
                  onChange={(e) => {
                    setDateValue("");
                    setDates({
                      ...dates,
                      end: e,
                    });
                  }}
                />
              </Flex>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex> */}
        <Card mt="sm">
          <Table.ScrollContainer minWidth={1000}>
            <Table striped>
              <Table.Thead>
                <Table.Tr>
                  {table.getFlatHeaders().map((header) => (
                    <Table.Th key={header.id}>
                      {header.column.columnDef.header}
                    </Table.Th>
                  ))}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <Table.Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Table.Td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Td>
                        );
                      })}
                    </Table.Tr>
                  );
                })}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </Card>
        <Pagination
          value={table.getState().pagination.pageIndex + 1}
          onChange={(e) => {
            table.setPageIndex(e - 1);
          }}
          total={pageCount}
          mt="lg"
        />
      </Skeleton>
    </div>
  );
}

function getStartDate(daysToSubtract) {
  return dayjs()
    .subtract(daysToSubtract, "day")
    .hour(0)
    .minute(0)
    .second(1)
    .format("YYYY-MM-DDTHH:mm:ss");
}

const columns = [
  {
    header: "Event",
    accessorKey: "Status",
    enableSorting: false,
    id: "emailEvent",
    email: true,
  },
  {
    header: "Recipient",
    enableSorting: false,
    id: "emailRecipient",
    email: true,
    cell: ({ row }) => {
      if (row.original.Recipients && row.original.Recipients.length > 0) {
        return formatRecipients.format(row.original.Recipients);
      } else {
        return "";
      }
    },
  },
  {
    header: "Subject",
    accessorKey: "Subject",
    enableSorting: false,
    id: "emailSubject",
    email: true,
    cell: ({ row }) => {
      return (
        <Anchor component={Link} to={`/`} c="dark" fw={600}>
          {row.original.Subject}
        </Anchor>
      );
    },
  },
  {
    header: "Tag",
    accessorKey: "Tag",
    id: "emailTag",
    email: true,
    cell: ({ row }) => {
      if (row.original.Tag) {
        return (
          <Badge variant="light" size="xs" style={{ textTransform: "none" }}>
            {row.original.Tag}
          </Badge>
        );
      } else {
        return "";
      }
    },
  },
  {
    header: "Date & Time",
    id: "emailDate",
    email: true,
    cell: ({ row }) => {
      return new Date(row.original.ReceivedAt).toLocaleString();
    },
  },
  {
    header: "Phone",
    id: "phone",
    phone: true,
    accessor: "phone",
  },
  {
    header: "Internal",
    id: "internal",
    internal: true,
    accessor: "internal",
  },
  {
    header: "Date",
    id: "date",
    accessor: "date",
    phone: true,
  },
];

const emailColumns = columns
  .filter((f) => f.email)
  .reduce((acc, cur) => {
    acc[cur.id] = true;
    return acc;
  }, {});

const phoneColumns = columns
  .filter((f) => f.phone)
  .reduce((acc, cur) => {
    acc[cur.id] = true;
    return acc;
  }, {});

const internalColumns = columns
  .filter((f) => f.internal)
  .reduce((acc, cur) => {
    acc[cur.id] = true;
    return acc;
  }, {});

const messageTypeOptions = [
  {
    text: "Email",
    value: "email",
    search_text: "Search by subject, to, from...",
    column_visibility: emailColumns,
    options: [
      { text: "All", value: "all" },
      { text: "Postmark", value: "Postmark" },
    ],
  },
  {
    text: "Phone",
    value: "phone",
    column_visibility: phoneColumns,
    search_text: "Search by number...",
    options: [
      { text: "All", value: "all" },
      { text: "Twilio", value: "twilio" },
      { text: "Vonage", value: "vonage" },
    ],
  },
  {
    text: "Internal",
    search_text: "Search...",
    column_visibility: internalColumns,
    value: "internal",
    options: [{ text: "All", value: "all" }],
  },
];

const initialColumnVisibility = {
  ...emailColumns,
  ...phoneColumns,
  ...internalColumns,
};

function getFilteredVisibility(visibilityFilter) {
  const falseEmail = Object.assign({}, emailColumns);
  const falsePhone = Object.assign({}, phoneColumns);
  const falseInternal = Object.assign({}, internalColumns);
  Object.keys(falseEmail).forEach((key) => {
    falseEmail[key] = false;
  });
  Object.keys(falsePhone).forEach((key) => {
    falsePhone[key] = false;
  });
  Object.keys(falseInternal).forEach((key) => {
    falseInternal[key] = false;
  });
  switch (visibilityFilter) {
    case "email":
      return {
        ...emailColumns,
        ...falsePhone,
        ...falseInternal,
      };
    case "phone":
      return {
        ...phoneColumns,
        ...falseEmail,
        ...falseInternal,
      };
    case "internal":
      return {
        ...internalColumns,
        ...falseEmail,
        ...falsePhone,
      };
    default:
      return initialColumnVisibility;
  }
}
