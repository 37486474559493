import React from "react";
import { shallow } from "zustand/shallow";
import { TextInput } from "@mantine/core";

import { CustomTooltip } from "@components/shared";

import useAppStore from "./report-recipe-runner-store";

export default function QbTextInput({ field }) {
  const { queryData, queryComponents, setQueryData } = useAppStore(
    (state) => ({
      queryData: state.query_data,
      queryComponents: state.query_components,
      setQueryData: state.setQueryData,
    }),
    shallow
  );

  return (
    <TextInput
      labelProps={{
        labelElement: "div",
        style: {
          display: "flex",
          alignItems: "center",
          gap: "6px",
        },
      }}
      label={
        field.tooltip_text ? (
          <CustomTooltip label={field.tooltip_text}>
            <label>{field.label}</label>
          </CustomTooltip>
        ) : (
          <label>{field.label}</label>
        )
      }
      required={field.required}
      value={queryData[field.clientName]}
      onChange={(e) =>
        setQueryData({
          ...queryData,
          [field.clientName]: e.target.value,
        })
      }
    />
  );
}
