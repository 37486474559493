import React, { useState, useEffect } from "react";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import { connect, useSelector } from "react-redux";
import { Button, Image, MantineProvider, useMantineTheme } from "@mantine/core";
import { Route, Routes, useNavigate } from "react-router-dom";
import axios from "axios";

import {
  ContactList,
  MessagingNav,
  FavoriteMessages,
} from "@components/Messaging";
import { InteractionDetail } from "@pages/Messaging";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 100vh;
  background: var(--backgroundPrimaryColor);
  color: var(--textPrimaryColor);
  /* padding: 1em; */

  main {
    padding: 1em 2.5em;
  }

  aside {
    /* padding: 1em; */
    max-height: 100%;
    overflow-y: auto;
    background: var(--backgroundSecondaryColor);
  }
`;

const LayoutAlt = styled.div`
  padding: 1em;
`;

const MessagingContainer = (props) => {
  const [refreshContacts, setRefreshContacts] = useState(false);
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const userInfo = useSelector((state) => state.user);
  const settings = useSelector((state) => state.locationSettings);

  if (!settings) return null;

  const Wrapper = ({ children }) => (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        components: {
          Card: {
            defaultProps: {
              radius: "md",
              p: "sm",
              withBorder: true,
            },
          },
        },
        defaultGradient: {
          from: settings.color || "blue",
          to: "red",
          deg: 45,
        },
        fontFamily: theme.fontFamily,
        fontFamilyMonospace: theme.fontFamilyMonospace,
        headings: {
          ...theme.headings,
          fontFamily: theme.fontFamily,
        },
        // colorScheme: colorScheme,
        primaryColor:
          settings && settings.settings && settings.settings.settings
            ? settings.settings.settings.color
            : "blue",
        // primaryColor: colors ? colors.base : "orange",
      }}
    >
      {children}
    </MantineProvider>
  );

  if (userInfo) {
    return (
      <LayoutAlt>
        <Toaster />
        <InteractionDetail
          onMessageSend={(e) => {
            true;
          }}
        />
      </LayoutAlt>
    );
  }

  return (
    <Wrapper>
      <Layout>
        <Toaster />
        <aside>
          <TogglePhoneService />
          <ContactList
            onDismissClick={(dismissedId) => {
              if (
                window.location.pathname ===
                `/messaging/interactions/${dismissedId}`
              ) {
                navigate(`/`);
              }
            }}
            refresh={refreshContacts}
          />
        </aside>
        <main>
          <Routes>
            <Route path="/messaging" element={<MessagingNav />} />
            <Route
              path="/messaging-favorites"
              element={
                <MainWrapper>
                  <div className="main__chat">
                    <FavoriteMessages />
                  </div>
                </MainWrapper>
              }
            />
            <Route
              path="/messaging-interactions/:id"
              element={
                <InteractionDetail
                  onMessageSend={(e) => {
                    // setRefreshContacts(!refreshContacts);
                  }}
                />
              }
            />
          </Routes>
        </main>
      </Layout>
    </Wrapper>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(MessagingContainer);

const TogglePhoneService = () => {
  function onClick() {
    axios
      .get(`/testing/toggle-sms/`)
      .then(({ data }) => {
        if (data.provider) {
          toast.success(`Now Using: ${data.provider}`.toLocaleUpperCase());
        }
      })
      .catch((err) => {});
  }

  return (
    <Button size="xs" variant="subtle" color="gray" onClick={onClick}>
      Toggle Twilio/Vonage
    </Button>
  );
};

const MainWrapper = styled.div`
  display: grid;
  grid-template-rows: 3rem 1fr 6rem;
  height: 100%;

  .main__channel {
    border-bottom: 2px solid var(--backgroundSecondaryColor);
  }

  .main__chat {
    height: calc(100vh - 5rem);
    overflow-y: auto;
    /* display: flex;
    justify-content: flex-end;
    align-items: flex-end; */
    /* padding: 1rem; */
  }

  .main__input {
  }
`;
