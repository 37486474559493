import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { TextInput, Space, Button } from "@mantine/core";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";

import ContestSlider from "./ContestSlider";
import EntrySummary from "./EntrySummary";
import LinkDisplay from "./LinkDisplay";
import entityIcon from "@util/entityIcon";
import getGeoLocation from "@util/getGeoLocation";
import useAppStore from "./microsite-store";

export default function UserEffortView({
  contests = [],
  effort,
  onSlideChange,
  fetchData,
}) {
  const entityInfo = useAppStore((state) => state.entityInfo);

  const effortConfig =
    effort && effort.draft_configuration ? effort.draft_configuration : null;

  return (
    <div>
      {entityInfo && entityInfo.use_keywords && (
        <React.Fragment>
          <KeywordForm effortId={effort.id} onSuccess={fetchData} />
          <Space h="md" />
        </React.Fragment>
      )}
      <ContestSlider onSlideChange={onSlideChange} contests={contests} />
      {effortConfig && <LinkDisplay links={effortConfig.links} />}
    </div>
  );
}

const KeywordForm = ({ onSuccess, effortId }) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(null);

  const { t } = useTranslation();

  const { coords, entityInfo, user } = useAppStore(
    (state) => ({
      coords: state.coords,
      entityInfo: state.entityInfo,
      user: state.user,
    }),
    shallow
  );

  if (!effortId) return null;

  function onSubmit() {
    if (!value) {
      return;
    }

    const req = {
      keyword: value.trim(),
      campaign_effort_id: effortId,
      user_id: user.id,
    };

    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    if (entityInfo.location) {
      req.location_id = entityInfo.location.id;
    }

    setLoading(true);

    getGeoLocation().then((newCoords) => {
      if (newCoords) {
        req.latitude = newCoords.latitude;
        req.longitude = newCoords.longitude;
      }

      axios
        .post(`/microsite/keyword-submit/`, req)
        .then(({ data }) => {
          setLoading(false);
          if (data.response[0]) {
            setSummary(data.response[0]);
          } else {
            toast.success(t("keyword_entry_success"));
            onSuccess();
            setValue("");
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    });
  }

  return (
    <div>
      {summary && (
        <EntrySummary
          keyword={value}
          results={summary}
          onClose={() => {
            setValue("");
            onSuccess();
            setSummary(null);
          }}
        />
      )}
      <TextInput
        placeholder={t("keyword_input_placeholder")}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        size="lg"
      />
      <Button
        leftSection={entityIcon.keyword(24)}
        disabled={loading}
        onClick={onSubmit}
        loading={loading}
        mt="sm"
        size="lg"
        fullWidth
      >
        {t("keyword_submit_button")}
      </Button>
    </div>
  );
};
