import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@mantine/core";

import SectionTitle from "./SectionTitle";
import entityIcon from "@util/entityIcon";

import { iconSize } from "./helpers";

const reportTypes = [
  { label: "Responses", value: 2 },
  { label: "Demographics", value: 1 },
  { label: "Heat Map", value: 3 },
  { label: "By Hour", value: 4 },
];

const ReportSelect = ({ value, isCampaign = false, onChange }) => {
  const managerInfo = useSelector((state) => state.manager);

  return (
    <React.Fragment>
      <SectionTitle title="Report Type" icon={entityIcon.report(iconSize)} />
      <Button.Group>
        {[...reportTypes]
          .filter((f) =>
            (managerInfo && managerInfo.location_id) || isCampaign
              ? f.value !== 2
              : true
          )
          .filter((f) =>
            (managerInfo && managerInfo.location_id) || !isCampaign
              ? f.value !== 1
              : true
          )
          .map((m) => (
            <Button
              size="xs"
              key={m.value}
              variant={value === m.value ? "filled" : "light"}
              onClick={() => onChange(m.value)}
            >
              {m.label}
            </Button>
          ))}
      </Button.Group>
    </React.Fragment>
  );
};
export default ReportSelect;
