import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  PasswordInput,
  Progress,
  Group,
  Text,
  Center,
  Box,
  Button,
} from "@mantine/core";
import axios from "axios";
import { IconCheck, IconX } from "@tabler/icons-react";

function PasswordRequirement({ meets, label }) {
  return (
    <Text color={meets ? "teal" : "red"} mt={5} size="sm">
      <Center inline>
        {meets ? (
          <IconCheck size={14} stroke={1.5} />
        ) : (
          <IconX size={14} stroke={1.5} />
        )}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
}

const requirements = [
  { re: /[0-9]/, label: "Includes number" },
  { re: /[a-z]/, label: "Includes lowercase letter" },
  { re: /[A-Z]/, label: "Includes uppercase letter" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
];

export default function PasswordForm({
  additionalReqData = {},
  fullWidth = false,
  updating,
  returnEarly,
  onSuccess,
}) {
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [loading, setLoading] = useState(false);

  function onSubmit(e) {
    e.preventDefault();

    if (returnEarly) {
      return returnEarly({
        password,
        confirmed_password: confirmedPassword,
      });
    }

    const req = {
      ...additionalReqData,
      password,
      confirmed_password: confirmedPassword,
    };

    if (currentPassword) {
      req.current_password = currentPassword;
    }

    axios
      .post(`/password/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Password Saved");
        if (updating) {
          setPassword("");
          setConfirmedPassword("");
          setCurrentPassword("");
        }

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const strength = getStrength(password);

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(password)}
    />
  ));

  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ bar: { transitionDuration: "0ms" } }}
        value={
          password.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
            ? 100
            : 0
        }
        color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
        key={index}
        size={4}
      />
    ));

  return (
    <form>
      {updating && (
        <PasswordInput
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          label="Current Password"
        />
      )}
      <PasswordInput
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        label="New Password"
      />
      <PasswordInput
        value={confirmedPassword}
        onChange={(e) => setConfirmedPassword(e.target.value)}
        label="New Password, again"
      />
      <Group spacing={5} grow mt="xs" mb="md">
        {bars}
      </Group>

      <PasswordRequirement
        label="Has at least 6 characters"
        meets={password.length > 5}
      />
      {checks}
      <Button
        onClick={onSubmit}
        fullWidth={fullWidth || updating ? true : false}
        variant={updating ? "light" : null}
        disabled={
          loading ||
          !password ||
          !confirmedPassword ||
          password !== confirmedPassword ||
          (updating && !currentPassword) ||
          strength < 50
        }
        style={{ marginTop: "1em" }}
      >
        Save
      </Button>
    </form>
  );
}

function getStrength(password) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}
