import React from "react";
import { Link } from "react-router-dom";
import { Button, Flex } from "@mantine/core";
export default function Subnav({ buttonColor = "blue", links = [] }) {
  if (!links.length) return null;

  return (
    <React.Fragment>
      <Flex
        mb="sm"
        style={{
          overflowX: "auto",
          paddingBottom: "5px",
        }}
      >
        {links.map((l, i) => (
          <Button
            key={i}
            // color={buttonColor}
            color={l.to === window.location.pathname ? buttonColor : "gray"}
            component={Link}
            to={l.to}
            radius="lg"
            mr="sm"
            size="xs"
            variant={`subtle`}
            style={{
              overflow: "visible",
            }}
          >
            {l.text}
          </Button>
        ))}
      </Flex>
    </React.Fragment>
  );
}
