import React, { useState } from "react";
import {
  Button,
  Box,
  Divider,
  ThemeIcon,
  Text,
  Title,
  Flex,
  Group,
} from "@mantine/core";
import {
  VictoryCursorContainer,
  VictoryVoronoiContainer,
  VictoryLabel,
  VictoryAxis,
  VictoryTooltip,
  VictoryChart,
  VictoryLine,
} from "victory";
import {
  IconFlame,
  IconStarFilled,
  IconPlayerPlayFilled,
} from "@tabler/icons-react";

const dimensions = {
  height: 300,
  width: 800,
};

const styles = {
  background: "#181818",
};

const iconProps = {
  radius: "xl",
  size: "lg",
  variant: "light",
};

export default function RatingEkg() {
  const [statRange, setStatRange] = useState("current");

  const items = [
    {
      text: "Sample",
      value: 4,
      icon: (
        <ThemeIcon {...iconProps}>
          <IconPlayerPlayFilled />
        </ThemeIcon>
      ),
    },
    {
      text: "Score",
      value: 4,
      icon: (
        <ThemeIcon {...iconProps} color="green">
          <IconStarFilled />
        </ThemeIcon>
      ),
    },
    {
      text: "Burn",
      value: 4,
      icon: (
        <ThemeIcon {...iconProps} color="red">
          <IconFlame />
        </ThemeIcon>
      ),
    },
  ];

  return (
    <>
      <Flex
        gap="3em"
        align="flex-start"
        p="sm"
        style={{
          background: styles.background,
        }}
      >
        <Box mb="md">
          <Title>The Song Name</Title>
          <Text>The Artist Name</Text>
          <Button
            variant="subtle"
            mt="sm"
            style={{
              padding: 0,
            }}
            size="xs"
            onClick={() =>
              setStatRange(statRange === "current" ? "lifetime" : "current")
            }
          >
            view {statRange === "current" ? "current" : "lifetime"} stats
          </Button>
        </Box>
        {items.map((item, i) => (
          <Flex key={i} direction="column" justify="center" align="center">
            <Flex gap="sm">
              <>{item.icon}</>
              <Title>{item.value}</Title>
            </Flex>
            <Text size="xl">{item.text}</Text>
          </Flex>
        ))}
      </Flex>
      <Divider />
      <Chart />
    </>
  );
}

const burnData = [
  { x: 0, y: 5 },
  { x: 1, y: 2 },
  { x: 2, y: 3 },
  { x: 3, y: 5 },
  { x: 4, y: 4 },
  { x: 5, y: 4 },
  { x: 6, y: 0 },
  { x: 7, y: 2 },
  { x: 8, y: 3 },
  { x: 9, y: 5 },
  { x: 10, y: 4 },
  { x: 11, y: 6 },
  { x: 12, y: 2 },
];

const ratingData = [
  { x: 0, y: 80 },
  { x: 1, y: 75 },
  { x: 2, y: 82 },
  { x: 3, y: 80 },
  { x: 4, y: 60 },
  { x: 5, y: 65 },
  { x: 6, y: 89 },
  { x: 7, y: 92 },
  { x: 8, y: 60 },
  { x: 9, y: 50 },
  { x: 10, y: 40 },
  { x: 11, y: 60 },
  { x: 12, y: 95 },
].sort((a, b) => b.y - a.y);

const minMaxes = {
  burn_min: burnData.map((a) => a.y).sort((a, b) => a - b)[0],
  burn_max: burnData.map((a) => a.y).sort((a, b) => b - a)[0],
  rating_min: ratingData.map((a) => a.y).sort((a, b) => a - b)[0],
  rating_max: ratingData.map((a) => a.y).sort((a, b) => b - a)[0],
};

function Chart() {
  const [selectedDataset, setSelectedDataset] = useState(null);

  return (
    <Box
      style={{
        // height: `${dimensions.height}px`,
        width: "100%",
        background: styles.background,
      }}
    >
      <VictoryChart
        domainPadding={{ x: 25, y: 10 }}
        padding={{ top: 20, bottom: 15, right: 0, left: 50 }}
        domain={
          !selectedDataset
            ? {
                x: [0, 12],
                y: [-5, 100],
              }
            : {
                x: [0, 12],
                y: [
                  minMaxes[`${selectedDataset}_min`],
                  minMaxes[`${selectedDataset}_max`],
                ],
              }
        }
        containerComponent={
          <VictoryVoronoiContainer
            labels={(d) => {
              // return `${d.datum.childName === "rating" ? "Rating" : "Burn"} ${
              //   d.datum.y
              // }`;
              return d.datum.y;
            }}
            style={{
              labels: {
                fontFamily: "inherit",
              },
            }}
            // labelComponent={
            //   <VictoryLabel
            //     style={{
            //       fontFamily: "inherit",
            //       fill: "#fff",
            //       background: "red",
            //     }}
            //   />
            // }
          />
        }
        width={dimensions.width}
        // height={dimensions.height}
        style={{
          background: {
            fill: styles.background,
          },
        }}
      >
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: "rgba(255, 255, 255, 0.01)", strokeWidth: 3 },
            // grid: { stroke: ({ tick }) => (tick > 0.5 ? "red" : "grey") },
            tickLabels: {
              fill: "rgba(255, 255, 255, 0.4)",
              fontFamily: "inherit",
            },
          }}
        />
        {selectedDataset !== "rating" && (
          <VictoryLine
            style={{
              data: {
                stroke: "red",
                strokeWidth: 6,
                fontFamily: "inherit",
                cursor: "pointer",
              },
            }}
            interpolation="natural"
            name="burn"
            data={burnData}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: () => {
                    setSelectedDataset(
                      selectedDataset !== "burn" ? "burn" : null
                    );
                  },
                },
              },
            ]}
          />
        )}
        {selectedDataset !== "burn" && (
          <VictoryLine
            style={{
              data: {
                stroke: "green",
                strokeWidth: 6,
                fontFamily: "inherit",
                cursor: "pointer",
              },
            }}
            name="rating"
            interpolation="natural"
            data={ratingData}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: () => {
                    setSelectedDataset(
                      selectedDataset !== "rating" ? "rating" : null
                    );
                  },
                },
              },
            ]}
          />
        )}
      </VictoryChart>
    </Box>
  );
}
