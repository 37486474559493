import React from "react";

import { EmailValidationList } from "@components/EmailValidation";

export default function EmailValidationIndex() {
  return (
    <React.Fragment>
      <EmailValidationList />
    </React.Fragment>
  );
}
