import React, { useEffect } from "react";
import toast from "react-hot-toast";

import { Button, FileButton, List, Group, Text } from "@mantine/core";
import {
  IconFileUpload,
  IconTrash,
  IconSend,
  IconPaperclip,
} from "@tabler/icons-react";
import { AssetLibraryPicker } from "@components/Asset";

export default function TicketAssetUpload({
  setLibraryAssetId,
  sendMessage,
  setFiles,
  files,
  removeFile,
  fetchData,
  disabled,
  libraryAssetId,
}) {
  function truncateString(str, num) {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  function addFile(file) {
    if (
      ![
        "png",
        "jpeg",
        "jpg",
        "png",
        "gif",
        "pdf",
        // "webp",
      ].includes(file.type.split("/")[1])
    ) {
      toast.error("This file type is not supported.");
    } else {
      const newFiles = files;
      newFiles.push(file);

      setLibraryAssetId(null);
      setFiles(newFiles);
      fetchData();
    }
  }

  function setAssetId(id) {
    setLibraryAssetId(id);
    setFiles([]);
  }

  return (
    <div>
      <Group>
        <FileButton onChange={(e) => addFile(e)}>
          {(props) => (
            <Button
              {...props}
              variant="light"
              color="blue"
              leftSection={<IconPaperclip />}
              size="xs"
            >
              Attach files
            </Button>
          )}
        </FileButton>
        <AssetLibraryPicker
          title="Attach Support Document"
          reqData={{ variety: [5] }}
          onSelect={(e) => setAssetId(e)}
        />
        <Button
          disabled={disabled}
          leftSection={<IconSend />}
          onClick={() => sendMessage()}
          size="xs"
          variant="filled"
          color="green"
        >
          Send Message
        </Button>
      </Group>
      {libraryAssetId && (
        <Text size="sm" c="dimmed" mb="sm" position="left" mt="xl">
          You have attached a support document to this message
        </Text>
      )}
      {files.length > 0 && (
        <Text size="sm" c="dimmed" mb="sm" position="left" mt="xl">
          Attached file(s):
        </Text>
      )}
      {files.length > 0 && (
        <List size="sm" center={false}>
          {files.map((m, i) => (
            <List.Item span={4} m={0} key={i}>
              <Group justify="center">
                <Text size="lg" fw={300}>
                  {truncateString(m.name, 45)}
                </Text>
                <Button
                  onClick={() => removeFile(m)}
                  color="red"
                  size="xs"
                  variant="subtle"
                >
                  <IconTrash size={16} />
                </Button>
              </Group>
            </List.Item>
          ))}
        </List>
      )}
    </div>
  );
}
