import { useState, useEffect } from "react";
import axios from "axios";
import {
  Text,
  Modal,
  Box,
  Flex,
  Badge,
  Anchor,
  Button,
  UnstyledButton,
} from "@mantine/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { ContestWizardStatus, ContestAddCreate } from "@components/Contest";
import { Link } from "react-router-dom";
import { MultiSweepsEffortAwards } from "./";

export default function EffortWizardSweepstakes({
  effortId,
  effortName,
  effortVariety,
  fetchData,
  items = [],
  organizationId,
  locationId,
  wizardStatus,
}) {
  // multi sweeps
  if (effortVariety === 5) {
    if (wizardStatus.contest_required_status === false) {
      return (
        <ContestAddCreate
          effortId={effortId}
          effortName={effortName}
          organizationId={organizationId}
          locationId={locationId}
          onSuccess={fetchData}
          wizardSetup
        />
      );
    } else {
      return (
        <div>
          <MultiSweepsEffortAwards
            effortId={effortId}
            effortName={effortName}
            locationId={locationId}
            organizationId={organizationId}
            showCreate={false}
          />
        </div>
      );
    }
  }

  if (!items) return <Text>No sweepstakes associated yet!</Text>;

  return (
    <div>
      {items.map((item) => (
        <Item
          belongsToEffort={item.belongs_to_this_campaign_effort}
          contestId={item.id}
          effortId={effortId}
          fetchData={fetchData}
          key={item.id}
          liveStatus={item.live_status}
          name={item.name}
          wizardComplete={item.wizard_complete}
        />
      ))}
      <Button
        size="xs"
        variant="subtle"
        color="gray"
        onClick={fetchData}
        style={{
          padding: "0",
        }}
      >
        refresh sweepstakes
      </Button>
    </div>
  );
}

const Item = ({
  belongsToEffort,
  contestId,
  effortId,
  fetchData,
  liveStatus,
  name,
  wizardComplete,
}) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
    fetchData();
  }

  return (
    <Box mb="xs">
      <Flex align="center" gap="xs">
        <Text fw={600}>
          {belongsToEffort ? (
            wizardComplete ? (
              <Anchor
                to={`/contests/${contestId}`}
                target="_blank"
                component={Link}
                style={{
                  color: "inherit",
                }}
              >
                {name}
              </Anchor>
            ) : (
              <UnstyledButton
                style={{
                  fontWeight: 600,
                }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                {name}
              </UnstyledButton>
            )
          ) : (
            name
          )}
        </Text>
        {belongsToEffort && !wizardComplete && (
          <Badge variant="light" size="xs" color="red">
            Click to configure
          </Badge>
        )}
        {!wizardComplete && (
          <Badge variant="light" size="xs" color="yellow">
            waiting on wizard completion
          </Badge>
        )}
        {wizardComplete && liveStatus === false && (
          <Badge variant="light" size="xs" color="yellow">
            not live
          </Badge>
        )}
        {wizardComplete && liveStatus === true && (
          <Badge variant="light" size="xs" color="green">
            live
          </Badge>
        )}
      </Flex>
      <Modal opened={isOpen} onClose={onClose} size="lg">
        <Contest id={contestId} effortId={effortId} />
      </Modal>
    </Box>
  );
};

export function Contest({ id, effortId, locationId, organizationId }) {
  // const [info, setInfo] = useState(null);
  const queryClient = useQueryClient();
  const queryKey = `contests${id}`;

  const fetchData = () => queryClient.invalidateQueries([queryKey]);

  useEffect(() => {
    fetchData();
  }, [id]);

  const { failureReason: error, data: info } = useQuery({
    queryKey: [queryKey],
    queryFn: async () =>
      axios
        .get(`/contests/${id}/`)
        .then(({ data }) => {
          return data.response[0];
        })
        .catch((err) => {
          throw err;
        }),
  });

  // function fetchData() {
  //   axios
  //     .get(`/contests/${id}/`)
  //     .then(({ data }) => {
  //       setInfo(data.response[0]);
  //     })
  //     .catch((err) => {
  //       setInfo(null);
  //     });
  // }

  if (!info) return null;

  const { wizard_settings: wizardSettings, wizard_status: wizardStatus } = info;
  const wizardComplete = wizardStatus.wizard_complete;

  return (
    <div>
      <ContestWizardStatus
        effortId={effortId}
        fetchData={fetchData}
        id={id}
        wizardComplete={wizardComplete}
        wizardSettings={wizardSettings}
        wizardStatus={wizardStatus}
        locationId={info.location_id || locationId}
        minimal
        organizationId={info.organization_id || organizationId}
        prize={info.prizes[0]}
        status={info.status}
        contestDates={
          info.start_date && info.end_date
            ? {
                start_date: info.start_date,
                start_time: info.start_time,
                start_date_formatted: info.start_date_formatted,
                end_date: info.end_date,
                end_date_formatted: info.end_date_formatted,
                end_time: info.end_time,
              }
            : null
        }
      />
    </div>
  );
}
