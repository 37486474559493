import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from "yup";

import { BasicForm } from "@components/shared";

export default function BulkCommunicationForm({
  id = null,
  locationId = null,
  title = "",
  onSuccess = () => {},
  orgId = null,
}) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function onSubmit(formData) {
    setLoading(true);

    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      location_id: locationId,
      organization_id: orgId,
      entire_db: true,
    };

    axios
      .post(`/bulk-communicators-email/`, req)
      .then(({ data }) => {
        toast.success("Created");
        setLoading(false);
        navigate(
          `/bulk-communication/${data.response[0].bulk_communicator_id}/template`
        );
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
    };

    axios
      .put(`/bulk-communicators/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "title",
      label: "Title",
      initialValue: title,
      required: true,
      schema: () => Yup.string().max(100, "Too long!").required("Required"),
    },
  ];

  return (
    <React.Fragment>
      <BasicForm
        buttonProps={{
          fullWidth: !id ? true : false,
        }}
        loading={loading}
        onSubmit={onSubmit}
        fields={fields}
      />
    </React.Fragment>
  );
}
