import axios from "axios";
import React from "react";
import { Text, Switch } from "@mantine/core";
import toast from "react-hot-toast";

export default function BulkCommunicationAllowToggle({
  locationId,
  organizationId,
  fetchData,
  value,
  disabled,
}) {
  function changeStatus() {
    const req = {};
    if (locationId) req.location_id = locationId;
    if (organizationId) req.organization_id = organizationId;

    axios
      .post(`/bulk-communicators/toggle-email-marketing/`, req)
      .then(() => {
        toast.success("Updated!");
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      {disabled && (
        <Text fw={600} mb="sm" size="sm">
          To access this setting, create a Postmark server first.
        </Text>
      )}
      <Switch
        color="green"
        label="Allow Email Marketing"
        disabled={disabled}
        onClick={() => changeStatus()}
        checked={value}
      />
    </React.Fragment>
  );
}
