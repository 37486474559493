export const tableCategories = [
  { text: "user", value: "1", color: "teal" },
  { text: "effort", value: "2", color: "orange" },
  { text: "song", value: "3", color: "teal" },
  { text: "artist", value: "4", color: "blue" },
  { text: "song rating", value: "5", color: "violet" },
  { text: "play", value: "6", color: "grape" },
  { text: "contest", value: "7", color: "pink" },
  // { text: "geo", value: "8", color: "teal" },
  // { text: "location", value: "8", color: "green" },
  // { text: "organization", value: "9", color: "blue" },
  // { text: "check-in", value: "11", color: "lime" },
  // { text: "system", value: "13", color: "orange" },
  // { text: "keyword", value: "3", color: "yellow" },
];
