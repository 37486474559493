import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Select,
  SegmentedControl,
  Group,
  Text,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { DataFilters, ManagementLayout } from "@components/shared";
import { CampaignList } from "@components/Campaign";
import { EffortList } from "@components/Effort";
import entityIcon from "@util/entityIcon";

const CAMPAIGN_VALUE = "campaign";
const EFFORT_VALUE = "effort";

const entityInfo = {
  campaign: {
    title: "Campaigns",
    key: CAMPAIGN_VALUE,
  },
  effort: {
    title: "Efforts",
    key: EFFORT_VALUE,
  },
};

const entityButtons = Object.keys(entityInfo).map((m) => ({
  value: entityInfo[m].key,
  text: entityInfo[m].title,
}));

export default function ContestParticipants({ contestId, locationId, orgId }) {
  const [entity, setEntity] = useState(EFFORT_VALUE);
  const [participants, setParticipants] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchCampaigns();
  }, [orgId, locationId, entity]);

  useEffect(() => {
    fetchData();
  }, [entity]);

  function fetchCampaigns() {
    if (!orgId && !locationId) return;

    const req = {
      page: 0,
      page_size: 2000,
      search_value: "",
      context: "owned_participating",
      status: "entire",
      contest_id: contestId,
      effort: true,
    };

    if (orgId) req.organization_id = orgId;
    if (locationId) req.location_id = locationId;

    axios
      .post(`/retrieve-campaigns/`, req)
      .then(({ data }) => {
        setCampaigns(data.response[0].data);
      })
      .catch((err) => {
        setCampaigns([]);
      });
  }

  function fetchData() {
    axios
      .get(`/contests/${contestId}/retrieve-participants/`)
      .then(({ data }) => {
        setParticipants([
          ...data.response[0].campaigns.map((m) => ({
            ...m,
            link_url: `/campaigns/${m.id}`,
            campaign_id: m.id,
            children: () => (
              <RemoveButton
                reqInfo={{ campaign_id: m.id, contest_id: contestId }}
                onSuccess={fetchData}
              />
            ),
          })),
          ...data.response[0].campaign_efforts.map((m) => ({
            ...m,
            link_url: `/efforts/${m.id}`,
            effort_id: m.id,
            children: () => (
              <RemoveButton
                reqInfo={{ campaign_effort_id: m.id, contest_id: contestId }}
                onSuccess={fetchData}
              />
            ),
          })),
        ]);
      })
      .catch((err) => {
        setParticipants([]);
      });
  }

  const activeCampaigns = participants.filter((f) => f.campaign_id);
  const activeEfforts = participants
    .filter((f) => f.effort_id)
    .map((m) => ({
      ...m,
      original: m,
    }));

  return (
    <React.Fragment>
      <ManagementLayout
        dataComponent={
          <div>
            {/* <DataFilters
              onChange={(e, d) => setEntity(d)}
              values={{ entity }}
              items={[
                {
                  text: "Filter by",
                  key_name: "entity",
                  radio: true,
                  options: [
                    { text: "Campaign", value: CAMPAIGN_VALUE },
                    { text: "Effort", value: EFFORT_VALUE },
                  ],
                },
              ]}
            /> */}
            <div>
              <SegmentedControl
                value={entity}
                data={[
                  { label: "Campaigns", value: CAMPAIGN_VALUE },
                  { label: "Efforts", value: EFFORT_VALUE },
                ]}
                onChange={(e) => setEntity(e)}
                color="pink"
                mb="lg"
              />
            </div>
            {entity === CAMPAIGN_VALUE && (
              <CampaignList items={activeCampaigns} hideFilters />
            )}
            {entity === EFFORT_VALUE && (
              <EffortList
                shouldFetch={false}
                items={activeEfforts}
                contestId={contestId}
                requestInfo={{
                  url: `/retrieve-efforts/`,
                  data: {
                    contest_id: contestId,
                    refresh,
                  },
                }}
              />
            )}
          </div>
        }
        createComponent={
          <Card
            style={{
              overflow: "visible",
            }}
          >
            <h3 style={{ margin: "0 0 5px 0" }}>Add {entity}</h3>
            <AddForm
              contestId={contestId}
              entityToAdd={entity}
              onSuccess={() => {
                fetchData();
                setRefresh(!refresh);
              }}
              disabledIds={
                entity === CAMPAIGN_VALUE
                  ? activeCampaigns.map((m) => m.id)
                  : activeEfforts.map((m) => m.id)
              }
              campaignOptions={
                orgId || locationId ? campaigns : activeCampaigns
              }
              locationId={locationId}
              orgId={orgId}
            />
          </Card>
        }
      />
    </React.Fragment>
  );
}

const AddForm = ({
  campaignOptions = [],
  contestId,
  disabledIds = [],
  entityToAdd,
  onSuccess,
  locationId,
  orgId,
}) => {
  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);

  useEffect(() => {
    fetchOptions();
    setSelected(null);
    setSelectedCampaignId(null);
  }, [entityToAdd]);

  useEffect(() => {
    fetchEfforts();
  }, [selectedCampaignId]);

  function fetchOptions() {
    if (entityToAdd === CAMPAIGN_VALUE) {
      fetchCampaigns();
    }
  }

  function fetchCampaigns() {
    const req = {
      page: 0,
      page_size: 2000,
      search_value: "",
      context: "owned_participating",
      contest_id: contestId,
      effort: false,
    };

    if (locationId) req.location_id = locationId;
    if (orgId) req.organization_id = orgId;

    axios
      .post(`/retrieve-campaigns/`, req)
      .then(({ data }) => {
        setOptions(
          data.response[0].data.map((m) => ({
            label: m.title,
            value: m.id,
            disabled: disabledIds.includes(m.id),
          }))
        );
      })
      .catch((err) => {});
  }

  function fetchEfforts() {
    if (!selectedCampaignId) return;

    axios
      .post(`/retrieve-efforts/`, {
        campaign_id: selectedCampaignId,
        page: 0,
        page_size: 1000,
        engagement: "all",
        award: "all",
        status: "entire",
        variety: "all",
      })
      .then(({ data }) => {
        setOptions(
          data.response[0].data.map((m) => ({
            label: m.title,
            value: m.id,
            disabled: disabledIds.includes(m.id),
          }))
        );
      })
      .catch((err) => {
        setOptions([]);
      });
  }

  function onSubmit() {
    const req = {
      contest_id: contestId,
    };

    if (entityToAdd === CAMPAIGN_VALUE) req.campaign_id = selected;
    if (entityToAdd === EFFORT_VALUE) req.campaign_effort_id = selected;

    axios
      .post(`/contests/${contestId}/add-participant/`, req)
      .then(() => {
        toast.success("Added!");
        setSelected(null);
        setSelectedCampaignId(null);
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  const showMainPicker =
    entityToAdd === CAMPAIGN_VALUE ||
    (entityToAdd === EFFORT_VALUE && selectedCampaignId);

  return (
    <div>
      {entityToAdd === EFFORT_VALUE && (
        <Select
          label="Select a campaign first"
          placeholder="Select one..."
          data={campaignOptions.map((m) => ({
            label: m.title,
            value: m.id,
          }))}
          searchable
          onChange={(e) => setSelectedCampaignId(e)}
          value={selectedCampaignId}
        />
      )}
      {showMainPicker && (
        <Select
          placeholder="Select one..."
          data={options}
          value={selected}
          searchable
          label={entityToAdd === EFFORT_VALUE ? "Effort" : ""}
          onChange={(e) => setSelected(e)}
        />
      )}
      <Button
        mt="md"
        fullWidth
        disabled={
          !selected || (entityToAdd === EFFORT_VALUE && !selectedCampaignId)
        }
        onClick={onSubmit}
      >
        Add
      </Button>
    </div>
  );
};

const RemoveButton = ({ reqInfo, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    const req = {
      ...reqInfo,
    };

    setLoading(true);

    axios
      .post(`/remove-participant-from-contest/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Removed!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      mt="xs"
      onClick={onClick}
      size="xs"
      variant="light"
      color="red"
      loading={loading}
      leftSection={entityIcon.trash()}
      radius="lg"
    >
      Remove
    </Button>
  );
};
