import React from "react";
import { Divider, Flex } from "@mantine/core";
import { shallow } from "zustand/shallow";
import { useQueryClient } from "@tanstack/react-query";

import { PinReportRecipe } from "@components/ReportRecipe";

import useAppStore from "./report-recipe-runner-store";

export default function ReportRecipePinnableOptions({
  fetchSavedRecipe,
  divider = true,
}) {
  const queryClient = useQueryClient();

  const { recipeInfo, savedRecipe } = useAppStore(
    (state) => ({
      recipeInfo: state.recipe_info,
      savedRecipe: state.saved_recipe,
    }),
    shallow
  );

  if (recipeInfo.pinnable_to_options.length === 0) return null;

  return (
    <>
      {divider && <Divider mt="lg" mb="lg" />}
      <Flex gap="xs" wrap="wrap">
        {recipeInfo.pinnable_to_options.map((m, i) => (
          <PinReportRecipe
            key={i}
            reqData={{
              report_recipe_id: savedRecipe ? null : recipeInfo.id,
              saved_report_recipe_id: savedRecipe ? savedRecipe.id : null,
              pin_to: m.value,
            }}
            onSuccess={() => {
              if (savedRecipe) {
                fetchSavedRecipe();
              } else {
                queryClient.invalidateQueries([`reportRecipes`, recipeInfo.id]);
              }
            }}
            text={`Pin to ${m.label}`}
          />
        ))}
      </Flex>
    </>
  );
}
