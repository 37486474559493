import React, { useState } from "react";
import {
  Button,
  Divider,
  Group,
  Flex,
  Modal,
  Text,
  Title,
  Badge,
  Card,
  LoadingOverlay,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { CurationTagSearch } from "./";

export default function CurationTagManagement({
  items = [],
  artistId,
  songId,
  fetchData,
}) {
  const [creating, setCreating] = useState(false);

  function onClose() {
    setCreating(false);
  }

  return (
    <div>
      {/* <Modal opened={creating} onClose={onClose}>
        <CurationTagSearch artistId={artistId} songId={songId} />
      </Modal> */}
      {/* <Group mb="md">
        <Title order={4}>Tags</Title>
        <Button
          size="xs"
          variant="light"
          radius="xl"
          onClick={() => setCreating(true)}
        >
          add tag
        </Button>
      </Group> */}
      {items.length === 0 && (
        <Badge size="xs" color="gray" variant="light">
          no tags yet
        </Badge>
      )}
      <Flex wrap="wrap" columnGap="10px" rowGap="10px">
        {items.map((item) => (
          <TagItem
            key={item.id}
            name={item.name}
            id={item.id}
            artistId={artistId}
            songId={songId}
            fetchData={fetchData}
          />
        ))}
      </Flex>
    </div>
  );
}

const TagItem = ({ id, artistId, songId, name, fetchData }) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function onClose() {
    setOpen(false);
  }

  function onRemoveClick() {
    setLoading(true);

    const req = {
      tagging_id: id,
    };

    axios
      .post(`/delete-tagging/`, req)
      .then(() => {
        toast.success("Removed");
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      <Button
        size="xs"
        radius="xl"
        variant="light"
        onClick={() => setOpen(true)}
      >
        {name}
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        {/* <CurationTagSearch
          artistId={artistId}
          songId={songId}
          tagId={id}
          fetchData={() => {
            onClose();
            fetchData();
          }}
        /> */}
        <Text align="center" size="xl" mb="xl">
          Are you sure you want to remove the tag
          <br />
          <b>{name}</b>?
        </Text>
        <Group>
          <Button
            onClick={onRemoveClick}
            color="red"
            fullWidth
            size="lg"
            loading={loading}
          >
            Remove tag
          </Button>
        </Group>
      </Modal>
    </React.Fragment>
  );
};
