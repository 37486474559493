import React, { useState, useEffect } from "react";
import axios from "axios";

import { Message } from "./";

export default function FavoriteMessages() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/favorite-messages/`)
      .then(({ data }) => {
        setItems(
          data.response
            .map((m) => ({
              ...m,
              context: "conversational",
              sender_name: m.nickname || m.phone_number_formatted,
            }))
            .sort((a, b) =>
              new Date(a.favorited_at) > new Date(b.favorited_at) ? 1 : -1
            )
        );
      })
      .catch((err) => {
        setItems([]);
      });
  }

  return (
    <React.Fragment>
      {items.map((item) => (
        <Message
          assets={item.assets}
          body={item.body}
          context={item.context}
          createdAt={item.created_at}
          favorited={item.favorited}
          id={item.id}
          incoming={item.incoming}
          // isUser={user ? true : false}
          message={item}
          key={item.id}
          onUpdate={fetchData}
          refId={item.id}
          senderName={item.from_display_name}
          stationInteractionId={item.station_interaction_id}
          linkPath={`/messaging-interactions/${
            item.user_location_id || item.user_organization_id
          }?entity_join_type=${
            item.user_location_id ? "user_location_id" : "user_organization_id"
          }`}
        />
      ))}
    </React.Fragment>
  );
}
