import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Text } from "@mantine/core";
import toast from "react-hot-toast";
import { IconMap2, IconCheck } from "@tabler/icons-react";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";

import EntrySummary from "./EntrySummary";
import useAppStore from "./microsite-store";

export default function CheckInButton({
  onSuccess,
  onNoLocation = () => true,
  onLocation,
}) {
  const [summary, setSummary] = useState(null);
  const [locationId, setLocationId] = useState(null);

  const { t } = useTranslation();

  const { campaignId, effortId, entityInfo, coords, user } = useAppStore(
    (state) => ({
      campaignId: state.campaignId,
      coords: state.coords,
      effortId: state.effortId,
      entityInfo: state.entityInfo,
      user: state.user,
    }),
    shallow
  );

  if (!coords) return null;

  useEffect(() => {
    fetchLocation();
  }, []);

  function fetchLocation() {
    const req = {
      campaign_effort_id: effortId,
      campaign_id: campaignId,
      latitude: coords.latitude,
      longitude: coords.longitude,
    };

    if (entityInfo.location) {
      req.location_id = entityInfo.location.id;
    }

    axios
      .post(`/microsite/location-point-check/`, req)
      .then(({ data }) => {
        if (data.response.length) {
          setLocationId(data.response[0].id);
        } else {
          onNoLocation();
        }
      })
      .catch((err) => {
        setLocationId(null);
      });
  }

  function onClick() {
    const req = {
      ...coords,
      user_id: user.id,
      campaign_id: campaignId,
      campaign_effort_id: effortId,
      location_id: locationId,
    };

    axios
      .post(`/microsite/location-check-in/`, req)
      .then(({ data }) => {
        if (data.response[0].results) {
          setSummary(data.response[0].results);
        } else {
          toast.success("Checked In!");
          onSuccess();
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  if (!locationId) return null;

  if (user && !user.allow_check_in) {
    return (
      <Button variant="light" disabled leftSection={<IconCheck />}>
        {t("checked_in")}
      </Button>
    );
  }

  return (
    <React.Fragment>
      {summary && (
        <EntrySummary
          results={summary}
          checkin
          onClose={() => {
            setSummary(null);
            onSuccess();
          }}
        />
      )}
      <Button variant="light" leftSection={<IconMap2 />} onClick={onClick}>
        {t("check_in_button")}
      </Button>
    </React.Fragment>
  );
}
