import { create } from "zustand";

const initialState = {
  allow_checkin: null,
  allow_location_checkin: null,
  allow_geospot: null,
  allow_keyword: null,
  awarding: null,
  campaignSettings: {
    end_date: "",
    end_time: "",
    id: null,
    start_date: "",
    start_time: "",
    title: "",
  },
  contesting: null,
  contestSettings: {
    name: "",
  },
  effortSettings: {
    end_date: "",
    start_date: "",
    title: "",
  },
  goal: "",
  location: null,
  organization: null,
  member_only: null,
  microsite: null,
  prizing: null,
  recurring: null,
  reengagement: null,
  registration: "",
};

const useAppStore = create((set) => ({
  ...initialState,
  reset: () => {
    set(initialState);
  },
  setAllowLocationCheckin: (allowLocationCheckin) =>
    set(() => ({ allow_location_checkin: allowLocationCheckin })),
  setAllowCheckin: (allowCheckin) =>
    set(() => ({ allow_checkin: allowCheckin })),
  setAllowGeospot: (allowGeospot) =>
    set(() => ({ allow_geospot: allowGeospot })),
  setAllowKeyword: (allowKeyword) =>
    set(() => ({ allow_keyword: allowKeyword })),
  setAwarding: (awarding) => set(() => ({ awarding })),
  setCampaignSettings: (campaignSettings) => set(() => ({ campaignSettings })),
  setContesting: (contesting) => set(() => ({ contesting })),
  setContestSettings: (contestSettings) => set(() => ({ contestSettings })),
  setEffortSettings: (effortSettings) => set(() => ({ effortSettings })),
  setGoal: (goal) => set(() => ({ goal })),
  setLocation: (location) => set(() => ({ location })),
  setMemberOnly: (memberOnly) => set(() => ({ member_only: memberOnly })),
  setMicrosite: (microsite) => set(() => ({ microsite })),
  setOrganization: (organization) => set(() => ({ organization })),
  setPrizing: (prizing) => set(() => ({ prizing })),
  setRecurring: (recurring) => set(() => ({ recurring })),
  setReengagement: (reengagement) => set(() => ({ reengagement })),
  setRegistration: (registration) => set(() => ({ registration })),
}));

export default useAppStore;
