import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const fields = [
  { label: "Waiting", name: "waiting", color: "var(--mantine-color-gray-1)" },
  { label: "Sent", name: "sent", color: "var(--mantine-color-gray-4)" },
  {
    label: "Delivered",
    name: "delivered",
    color: "var(--mantine-color-blue-4)",
  },
  { label: "Opened", name: "opened", color: "var(--mantine-color-green-4)" },
  { label: "Bounced", name: "bounced", color: "var(--mantine-color-orange-3)" },
  { label: "Errored", name: "errored", color: "var(--mantine-color-red-4)" },
];

export default function BulkCommunicationMailerStatus({ mailerStatusInfo }) {
  if (mailerStatusInfo.disposition_formatted === "waiting") return null;

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            height: "350px",
            type: "pie",
            style: {
              fontFamily: "inherit",
            },
          },
          title: {
            text: mailerStatusInfo.disposition_formatted,
            // text: "",
          },
          tooltip: {
            valueSuffix: "",
          },
          subtitle: {
            // text: "",
            text: "current status",
          },
          plotOptions: {
            series: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: [
                {
                  enabled: true,
                  distance: 20,
                },
                {
                  enabled: true,
                  distance: -40,
                  format: "{point.percentage:.1f}%",
                  style: {
                    fontSize: "1.2em",
                    textOutline: "none",
                    opacity: 0.7,
                  },
                  filter: {
                    operator: ">",
                    property: "percentage",
                    value: 10,
                  },
                },
              ],
            },
          },
          series: [
            {
              name: "Amount",
              colorByPoint: true,
              data: fields
                .filter((f) => mailerStatusInfo[f.name] > 0)
                .map((field) => ({
                  name: field.label,
                  y: mailerStatusInfo[field.name],
                  color: field.color,
                })),
            },
          ],
          credits: {
            enabled: false,
          },
        }}
      />
    </div>
  );
}
