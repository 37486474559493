import React from "react";
import { AssetManagement } from "@components/Asset";

export default function AssetLibrary({
  isIndex,
  locationId,
  organizationId,
  library,
}) {
  return (
    <AssetManagement
      title="Asset Library"
      library={library}
      isIndex={isIndex}
      locationId={locationId}
      organizationId={organizationId}
    />
  );
}
