import React, { useState } from "react";
import axios from "axios";
import { Button, Anchor, Title } from "@mantine/core";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function ManagerAcceptInvite({ entityName, remoteCodeId }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const managerInfo = useSelector((state) => state.manager);

  function onClick(decision) {
    const req = {
      remote_code_id: remoteCodeId,
      accepted: decision,
    };

    axios
      .post(`/manager-invite-decision/`, req)
      .then(() => {
        toast.success(decision ? `Accepted!` : "Declined!");
        setSuccess(true);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  if (success) {
    return (
      <>
        <Title order={1}>Thank you. No further action is required.</Title>
        <Button
          component={Anchor}
          href={managerInfo ? `/campaigns` : "/manager"}
          mt="lg"
          variant="subtle"
        >
          {managerInfo ? "View Campaigns" : "Login"}
        </Button>
      </>
    );
  }

  return (
    <div>
      <Title mb="md" order={1}>
        Join {entityName}?
      </Title>
      <div>
        <Button onClick={() => onClick(true)} size="lg">
          Yes
        </Button>
        <Button
          variant="outline"
          color="red"
          ml="xl"
          onClick={() => onClick(false)}
          size="lg"
        >
          No
        </Button>
      </div>
    </div>
  );
}
