import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  MantineProvider,
  useMantineTheme,
  Button,
  Box,
  Center,
  Title,
  Group,
  Image,
  TextInput,
} from "@mantine/core";
import toast from "react-hot-toast";
import { createHash } from "crypto";

function createDigest(val) {
  const secret = import.meta.env.VITE_APP_SECRET;
  return createHash("sha256", secret).update(val).digest("hex");
}

const ColorSchemeProvider = ({ children }) => <div>{children}</div>;

export default function UserConfirmEmail({
  logoUrl,
  codeData,
  locationSettings,
  remoteCodeId,
}) {
  const theme = useMantineTheme();
  const [phoneValue, setPhoneValue] = useState("");
  const [loading, setLoading] = useState(false);

  const color =
    locationSettings && locationSettings.color
      ? locationSettings.color
      : "blue";

  function onError() {
    setLoading(false);
    toast.error("We need the mobile number you used to signup. Try again.");
  }

  function onSubmit() {
    setLoading(true);

    if (createDigest(phoneValue.replace(/\D/g, "")) !== codeData.number) {
      return onError();
    }

    const req = {
      remote_code_id: remoteCodeId,
    };

    axios
      .post(`/members/complete-signup/`, req)
      .then(({ data }) => {
        // setLoading(false);
        window.location = `/`;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      defaultColorScheme="dark"
      theme={{
        components: {
          Card: {
            defaultProps: {
              radius: "md",
              p: "sm",
              withBorder: true,
            },
          },
        },
        defaultGradient: {
          from: color,
          to: "red",
          deg: 45,
        },
        fontFamily: theme.fontFamily,
        fontFamilyMonospace: theme.fontFamilyMonospace,
        headings: {
          ...theme.headings,
          fontFamily: theme.fontFamily,
        },
        colorScheme: "dark",
        primaryColor: color,
        // primaryColor: colors ? colors.base : "orange",
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            width: "400px",
            margin: "0 auto",
          }}
        >
          {logoUrl && (
            <Group justify="center" mb="sm">
              <img
                src={logoUrl}
                style={{
                  width: "150px",
                  maxHeight: "200px",
                  objectFit: "contain",
                }}
              />
            </Group>
          )}
          <Title order={2} align="center" mb="sm">
            Enter phone
          </Title>
          <TextInput
            placeholder="mobile phone"
            value={phoneValue}
            onChange={(e) => setPhoneValue(e.target.value)}
            size="md"
          />
          <Button
            fullWidth
            mt="sm"
            disabled={!phoneValue || phoneValue.length < 10}
            loading={loading}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </MantineProvider>
  );
}
