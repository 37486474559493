import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  Space,
  Flex,
  Group,
  SegmentedControl,
  ThemeIcon,
  Text,
  TextInput,
} from "@mantine/core";
import { useSelector } from "react-redux";
import { IconLanguage, IconExclamationMark } from "@tabler/icons-react";

import entityIcon from "@util/entityIcon";
import { PaginatedList } from "@components/shared";

export default function EffortList({
  shouldFetch = true,
  contestId,
  items = [],
  requestInfo,
}) {
  if (!shouldFetch) {
    return (
      <div>
        {items.map((m, i) => (
          <div key={m.id}>
            <Item key={m.id} original={m.original} />
            {i !== items.length - 1 && <Space mb="sm" />}
          </div>
        ))}
      </div>
    );
  }

  const [filters, setFilters] = useState({
    // award: "all",
    engagement: "all",
    status: "entire",
    variety: "all",
  });
  const [searchValue, setSearchValue] = useState("");
  const managerInfo = useSelector((state) => state.manager);
  const isLocationManager =
    managerInfo && managerInfo.location_id ? true : false;
  const isDealerLocationManager =
    isLocationManager && managerInfo.location_variety === 2;

  return (
    <div>
      <TextInput
        placeholder="Search..."
        size="lg"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        mb="sm"
      />
      <Flex gap="sm" direction={{ base: "column", sm: "row" }} wrap="wrap">
        <SegmentedControl
          value={filters.status}
          data={[
            { label: "All", value: "entire" },
            { label: "Active", value: "active" },
            { label: "Upcoming", value: "upcoming" },
            { label: "Expired", value: "expired" },
          ]}
          onChange={(e) =>
            setFilters({
              ...filters,
              status: e,
            })
          }
        />
        {!contestId && !isDealerLocationManager && (
          <SegmentedControl
            value={filters.variety}
            data={[
              { label: "All", value: "all" },
              { label: "Prizepool", value: "prizepool" },
              { label: "Auction", value: "auction" },
              { label: "Points", value: "points" },
              { label: "Sweepstakes", value: "sweepstakes" },
            ]}
            onChange={(e) =>
              setFilters({
                ...filters,
                variety: e,
              })
            }
          />
          // <SegmentedControl
          //   value={filters.award}
          //   data={[
          //     { label: "All", value: "all" },
          //     { label: "Sweepstakes", value: "sweepstakes" },
          //     { label: "Points", value: "point_award" },
          //   ]}
          //   onChange={(e) =>
          //     setFilters({
          //       ...filters,
          //       award: e,
          //     })
          //   }
          // />
        )}
        <SegmentedControl
          value={filters.engagement}
          data={[
            { label: "All", value: "all" },
            { label: "Recurring", value: "recurring" },
            { label: "Non-Recurring", value: "non_recurring" },
          ]}
          onChange={(e) =>
            setFilters({
              ...filters,
              engagement: e,
            })
          }
        />
      </Flex>
      <PaginatedList
        requestInfo={{
          url: requestInfo.url,
          data: {
            ...requestInfo.data,
            ...filters,
            search_value: searchValue,
          },
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/efforts/${m.id}`,
          }))
        }
        displayFormatter={({ original }) => (
          <Item key={original.id} original={original} />
        )}
      />
    </div>
  );
}

const Item = ({ original }) => {
  return (
    <Card key={original.id}>
      <Flex gap="xs" align="center">
        <Text fw={700} component={Link} to={original.link_url}>
          {original.title}
        </Text>
        {original.show_alert_flag && (
          <ThemeIcon
            radius="xl"
            color="red"
            size="xs"
            title="Alert"
            style={{ position: "relative", bottom: "2px" }}
          >
            <IconExclamationMark
              style={{ width: "90%", height: "90%" }}
              stroke={2.5}
              title="Alert"
            />
          </ThemeIcon>
          // <Badge size="xs" color="red">
          //   alert
          // </Badge>
        )}
      </Flex>
      {original.variety_formatted && (
        <Group gap="xs" align="center">
          <div>{entityIcon.effort()}</div>
          <Text
            size="sm"
            // c="dimmed"
            style={{
              textTransform: "capitalize",
            }}
          >
            {original.recurring_engagement === 1 ? "recurring " : ""}
            {original.variety_formatted} effort
          </Text>
        </Group>
      )}
      {original.start_date_formatted && original.end_date_formatted && (
        <Group gap="xs" align="center">
          <div>{entityIcon.calendar()}</div>
          <Text size="sm">
            {original.start_date_formatted} - {original.end_date_formatted}
          </Text>
        </Group>
      )}
      {original.language && original.language !== "en" && (
        <Group gap="xs">
          <IconLanguage size={16} />
          <Text size="sm">{original.language.toUpperCase()}</Text>
        </Group>
      )}
      {(original.organization_name || original.location_name) && (
        <Group gap="xs">
          {original.organization_name && <div>{entityIcon.organization()}</div>}
          {original.location_name && <div>{entityIcon.location()}</div>}
          <Text size="sm">
            {original.organization_name && `${original.organization_name}`}
            {original.location_name && `${original.location_name}`}
          </Text>
        </Group>
      )}
      {original.author_string && (
        <Group gap="xs" align="center">
          <div>{entityIcon.admin()}</div>
          <Text size="sm">{original.author_string}</Text>
        </Group>
      )}
      {/* <Group mt="xs">
        <Badge variant="light" color={getVarietyColor(original.variety)}>
          {original.variety_formatted}
        </Badge>
        <Badge color="gray" variant="light">
          recurring
        </Badge>
      </Group> */}
      {original.children && <Group>{original.children()}</Group>}
    </Card>
  );
};

function getVarietyColor(variety) {
  switch (variety) {
    case 2:
      return "pink";
    case 3:
      return "orange";
    case 4:
      return "yellow";
    default:
      return "blue";
  }
}
