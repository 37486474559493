import React, { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  ActionIcon,
  Alert,
  Divider,
  Flex,
  Radio,
  Group,
  Notification,
  Select,
  Switch,
  SegmentedControl,
  Text,
  Space,
  TextInput,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

import { KeywordForm } from "@components/Keyword";
import { LocationSelect } from "@components/Location";
import entityIcon from "@util/entityIcon";

export default function ContestKeywordEffortAssociate({
  addOutside = false,
  contestId,
  effortId,
  locationId,
  organizationId,
  smsRequired,
}) {
  const [selectedContestId, setSelectedContestId] = useState("");
  const [selectedEffortId, setSelectedEffortId] = useState("");
  const [effortSource, setEffortSource] = useState("internal");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("effortId")) {
      setSelectedEffortId(searchParams.get("effortId"));
    }
  }, []);

  const selectReqData = effortId
    ? {
        campaign_effort_id: effortId,
      }
    : {
        contest_id: contestId,
      };

  const keywordReqData = effortId
    ? {
        contest_id: selectedContestId,
        location_id: locationId,
        organization_id: organizationId,
      }
    : {
        campaign_effort_id: selectedEffortId,
        location_id: locationId,
        organization_id: organizationId,
        // contest_id_to_join: contestId,
      };

  if (effortSource === "external") {
    selectReqData.wizard_link_associated_efforts = true;
    selectReqData.engagement = "all";
    keywordReqData.contest_id_to_join = contestId;
  } else {
    selectReqData.wizard_link_existing_efforts = true;
    selectReqData.engagement = "recurring";
    if (effortId) {
      keywordReqData.campaign_effort_id = effortId;
    } else {
      keywordReqData.contest_id = contestId;
    }
  }

  return (
    <>
      {!searchParams.get("effortId") && (
        <>
          {!effortId && !organizationId && (
            <SegmentedControl
              style={{ width: "fit-content" }}
              label="Source"
              data={[
                { label: "My Efforts", value: "internal" },
                { label: "Outside Efforts", value: "external" },
              ]}
              value={effortSource}
              onChange={(e) => {
                setEffortSource(e);
                setSelectedEffortId(null);
              }}
              mb="sm"
              size="xs"
            />
          )}
          {effortId ? (
            <ContestSelect
              value={selectedContestId}
              onChange={(e) => setSelectedContestId(e)}
              reqData={selectReqData}
            />
          ) : (
            <EffortSelect
              value={selectedEffortId}
              onChange={(e) => setSelectedEffortId(e)}
              reqData={selectReqData}
            />
          )}
        </>
      )}
      {(selectedEffortId || selectedContestId) && (
        <>
          <Space mt="xs" mb="xs" />
          <Keywords
            addOutside={addOutside}
            contestId={selectedContestId || contestId}
            effortId={selectedEffortId || effortId}
            entityName={effortId ? "contest" : "effort"}
            locationId={locationId}
            organizationId={organizationId}
            reqData={keywordReqData}
            smsRequired={smsRequired}
          />
        </>
      )}
    </>
  );
}

const ContestSelect = ({ value, onChange, reqData }) => {
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(reqData), searchValue]);

  function fetchData() {
    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      ...reqData,
      page: 0,
      page_size: 10,
      // engagement: "all",
      award: "all",
      status: "entire",
      variety: 1,
    };

    if (searchValue) req.search_value = searchValue;

    axios
      .post(`/retrieve-contests/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setOptions(
          data.response[0].data.map((d) => ({
            label: d.name,
            value: d.id,
          }))
        );
      })
      .catch((err) => {
        setOptions([]);
      });
  }

  return (
    <Select
      searchable
      searchValue={searchValue}
      onSearchChange={(e) => setSearchValue(e)}
      data={options}
      placeholder="Select..."
      label="Select a contest"
      onChange={(e) => onChange(e)}
      value={value}
      limit={20}
    />
  );
};

const EffortSelect = ({ value, onChange, reqData }) => {
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(reqData), searchValue]);

  function fetchData() {
    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      ...reqData,
      page: 0,
      page_size: 5,
      // engagement: "all",
      award: "all",
      status: "entire",
      variety: "sweepstakes",
    };

    if (searchValue) req.search_value = searchValue;

    axios
      .post(`/retrieve-efforts/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setOptions(
          data.response[0].data.map((d) => ({
            label: d.title,
            value: d.id,
          }))
        );
        if (data.response[0].data.map((m) => m.id).includes(value)) {
          onChange(value);
        }
      })
      .catch((err) => {
        setOptions([]);
      });
  }

  return (
    <Select
      searchable
      searchValue={searchValue}
      onSearchChange={(e) => setSearchValue(e)}
      data={options}
      placeholder="Select..."
      label="Select an effort"
      onChange={(e) => onChange(e)}
      value={value}
      limit={20}
    />
  );
};

const Keywords = ({
  contestId,
  effortId,
  entityName,
  locationId,
  organizationId,
  reqData,
  smsRequired,
}) => {
  const [keywords, setKeywords] = useState([]);
  const [addedKeywordIds, setAddedKeywordIds] = useState([]);
  const [creating, setCreating] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [searchParams] = useSearchParams();
  const [orgEffortEntityConfig, setOrgEffortEntityConfig] = useState({
    entity: "org",
    selected_location_id: "",
  });
  const searchParamsEffortName = searchParams.get("effortName");

  const queryClient = useQueryClient();

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(reqData)]);

  function fetchData() {
    setFetching(true);
    axios
      .post(`/keywords-for-linking/`, reqData)
      .then(({ data }) => {
        setKeywords(
          data.response[0].keywords.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          )
        );
        setAddedKeywordIds(data.response[0].keyword_ids_contest_added_to);
        setFetching(false);
      })
      .catch((err) => {
        setKeywords([]);
        setAddedKeywordIds([]);
        setFetching(false);
      });
  }

  const filteredKeywords = keywords.filter(
    (f) => f.text.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  );

  const keywordCreateReqData = {
    ...reqData,
    cancel_effort_join: true,
  };

  if (organizationId) {
    if (orgEffortEntityConfig.selected_location_id) {
      delete keywordCreateReqData.organization_id;
      keywordCreateReqData.location_id =
        orgEffortEntityConfig.selected_location_id;
    }
  }

  const showKeywordCreateForm =
    !organizationId ||
    orgEffortEntityConfig.entity === "org" ||
    orgEffortEntityConfig.selected_location_id;

  return (
    <div>
      {/* <Switch
        size="xs"
        label={creating ? "Create keyword" : "View Keywords"}
        onChange={() => setCreating(!creating)}
      /> */}
      <Group>
        <Radio
          onChange={(e) => {
            setCreating(!creating);
          }}
          checked={creating ? false : true}
          // label="View Keywords"
          label={
            searchParamsEffortName ? (
              <>
                View <b>{searchParamsEffortName}</b> Keywords
              </>
            ) : (
              "View Keywords"
            )
          }
        />
        <Radio
          onChange={(e) => {
            setCreating(!creating);
          }}
          checked={creating ? true : false}
          label="Create keyword"
        />
      </Group>
      <Divider mt="sm" mb="sm" />
      {creating ? (
        <>
          {organizationId && (
            <div>
              <Text mb="sm" size="sm" fw={600}>
                I'm creating a keyword for...
              </Text>
              <Radio
                mb="sm"
                label="For this organization"
                checked={orgEffortEntityConfig.entity === "org"}
                onChange={() =>
                  setOrgEffortEntityConfig({
                    ...orgEffortEntityConfig,
                    selected_location_id: "",
                    entity: "org",
                  })
                }
              />
              <Radio
                label="For a participating location"
                checked={orgEffortEntityConfig.entity === "location"}
                mb="sm"
                onChange={() =>
                  setOrgEffortEntityConfig({
                    ...orgEffortEntityConfig,
                    selected_location_id: "",
                    entity: "location",
                  })
                }
              />
              {orgEffortEntityConfig.entity === "location" && (
                <LocationSelect
                  value={orgEffortEntityConfig.selected_location_id}
                  reqData={{
                    campaign_effort_id: effortId,
                    contest_id: contestId,
                  }}
                  onChange={(e) =>
                    setOrgEffortEntityConfig({
                      ...orgEffortEntityConfig,
                      selected_location_id: e,
                    })
                  }
                />
              )}
            </div>
          )}
          {showKeywordCreateForm && (
            <KeywordForm
              locationId={locationId}
              // organizationId={organizationId}
              additionalReqData={keywordCreateReqData}
              effortId={effortId}
              onSuccess={(e) => {
                setCreating(false);
                fetchData();

                if (contestId) {
                  queryClient.invalidateQueries([
                    `contests${contestId}`,
                    `contestModal${contestId}`,
                  ]);
                }

                if (effortId) {
                  queryClient.invalidateQueries([`efforts${effortId}`]);
                }
              }}
              smsRequired={smsRequired}
            />
          )}
        </>
      ) : (
        <>
          {keywords.length > 0 && (
            <>
              <Notification
                withCloseButton={false}
                title="Helpful Keyword Tips"
                mb="xs"
                style={{ boxShadow: "none" }}
              >
                Add keywords to this {entityName} by clicking the plus button.
                Remove keywords from this {entityName} by clicking the trash
                can. The keyword toggle will turn the keyword on or off for all{" "}
                {entityName}s, not just this specific one.
              </Notification>
              <TextInput
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search keywords"
                mb="sm"
              />
            </>
          )}
          <div style={{ maxHeight: "450px", overflowY: "auto" }}>
            {!fetching && keywords.length === 0 && (
              <Text size="sm" c="dimmed">
                No keywords yet
              </Text>
            )}
            {filteredKeywords.map((item, i) => (
              <div key={item.id}>
                <KeywordItem
                  key={item.id}
                  id={item.id}
                  fetchData={() => {
                    fetchData();

                    if (contestId) {
                      queryClient.invalidateQueries([
                        `contests${contestId}`,
                        `contestModal${contestId}`,
                      ]);
                    }

                    if (effortId) {
                      queryClient.invalidateQueries([`efforts${effortId}`]);
                    }
                  }}
                  keywordData={item}
                  added={addedKeywordIds.includes(item.id)}
                  contestId={contestId}
                />
                {i + 1 < filteredKeywords.length && <Divider mb="sm" />}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const KeywordItem = ({ fetchData, added, id, keywordData, contestId }) => {
  const [loading, setLoading] = useState(false);

  function onAddClick() {
    setLoading(true);

    const req = {
      contest_id: contestId,
      keyword_ids: [id],
    };

    axios
      .post(`/contests/${contestId}/add-keywords/`, req)
      .then(() => {
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onRemoveClick() {
    setLoading(true);

    const req = {
      contest_id: contestId,
      keyword_ids: [id],
    };

    axios
      .post(`/contests/${contestId}/remove-keywords/`, req)
      .then(() => {
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onToggleClick() {
    const req = {
      keyword_id: id,
      status: [1, 3].includes(keywordData.status) ? 2 : 3,
    };

    axios
      .post(`/keywords/${id}/set-status/`, req)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Flex align="center" pb="xs" pr="xs">
      {added && (
        <Switch
          checked={keywordData.status === 2}
          onChange={onToggleClick}
          color="green"
          mr="sm"
        />
      )}
      <Text component={Link} fw={600} to={`/keywords/${id}`} target="_blank">
        {keywordData.text}
      </Text>
      <Flex style={{ flexGrow: 1 }} justify="flex-end" align="center" gap="xs">
        {!added ? (
          <ActionIcon
            variant="light"
            size="md"
            radius="xl"
            color="green"
            onClick={onAddClick}
            loading={loading}
          >
            {entityIcon.add()}
          </ActionIcon>
        ) : (
          <>
            <ActionIcon
              onClick={onRemoveClick}
              color="red"
              variant="light"
              size="md"
              radius="xl"
              loading={loading}
            >
              {entityIcon.trash()}
            </ActionIcon>
          </>
        )}
      </Flex>
    </Flex>
  );
};
