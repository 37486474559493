import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Group,
  Flex,
  LoadingOverlay,
  Pagination,
  Select,
  Table,
  Text,
  UnstyledButton,
} from "@mantine/core";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { IconDownload } from "@tabler/icons-react";
import { Parser } from "@json2csv/plainjs";
import axios from "axios";
import { shallow } from "zustand/shallow";
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
} from "@tabler/icons-react";

import useAppStore from "./report-recipe-runner-store";

export default function DynamicTable({
  isShared = false,
  title,
  requestConfig,
  queryChanged,
  manualTableConfig = null,
}) {
  const { tableConfig, setTableConfig } = useAppStore(
    (state) => ({
      tableConfig: state.table_config,
      setTableConfig: state.setTableConfig,
    }),
    shallow
  );

  // const [tableConfig, setTableConfig] = useState({
  //   data: [],
  //   columns: [],
  // });
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const table = useReactTable({
    data: tableConfig.data,
    columns: tableConfig.columns.map((m) => ({
      ...m,
      cell: (info) => info.getValue(),
      enableSorting: m.enableSorting === undefined ? false : m.enableSorting,
    })),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
      sorting,
    },
    onSortingChange: (e) => {
      setSorting(e);
    },
    enableMultiSort: false,
    // manualSorting: true,
    // enableSorting: true,
  });

  useEffect(
    () => () => {
      setTableConfig({
        data: [],
        columns: [],
      });
    },
    []
  );

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(requestConfig), queryChanged]);

  useEffect(() => {
    if (!manualTableConfig) return;

    setTableConfig(manualTableConfig);
    setLoading(false);

    if (table && manualTableConfig.default_sort_key) {
      setSorting([{ id: manualTableConfig.default_sort_key, desc: true }]);
    }
  }, [JSON.stringify(manualTableConfig)]);

  function fetchData() {
    if (queryChanged || manualTableConfig) return;
    setLoading(true);

    axios
      .post(requestConfig.api_endpoint, requestConfig.data)
      .then(({ data }) => {
        const res = data.response[0];
        setTableConfig(res.table_config);
        // if (table && res.table_config.default_sort_key) {
        //   setSorting({
        //     id: res.table_config.default_sort_key,
        //     desc: true,
        //   });
        // }
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setTableConfig({
          columns: [],
          data: [],
        });
      });
  }

  function onCSVClick() {
    const today = dayjs().format("YYYY-MM-DDTHH:mm:ssZ[Z]");
    const parser = new Parser({
      fields: tableConfig.columns.map((m) => ({
        label: m.header,
        value: m.accessorKey,
      })),
    });
    const csv = parser.parse(tableConfig.data);
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${today}-${title}.csv`;
    link.click();
  }

  return (
    <Box style={{ position: "relative", minHeight: "150px" }}>
      <LoadingOverlay visible={loading} zIndex={99} />
      <Table striped>
        <Table.Thead>
          <Table.Tr>
            {table.getFlatHeaders().map((header) => (
              <Table.Th key={header.id}>
                {/* {flexRender(header.column.columnDef.header)} */}
                <UnstyledButton
                  onClick={(e) => {
                    if (header.column.getCanSort()) {
                      header.column.toggleSorting();
                    }
                  }}
                  style={{
                    cursor: header.column.getCanSort() ? "pointer" : "initial",
                  }}
                >
                  <Group>
                    <Text fw={600} fz="xs">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Text>
                    {header.column.getCanSort() && (
                      <Center>
                        {{
                          asc: <IconChevronUp size={14} />,
                          desc: <IconChevronDown size={14} />,
                        }[header.column.getIsSorted()] ?? (
                          <IconSelector size={14} />
                        )}
                      </Center>
                    )}
                  </Group>
                </UnstyledButton>
              </Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {tableConfig.data.length === 0 && (
            <Table.Tr>
              <Table.Td colSpan={tableConfig.columns.length}>
                No results
              </Table.Td>
            </Table.Tr>
          )}
          {table.getRowModel().rows.map((row) => (
            <Table.Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Td>
              ))}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      {tableConfig.data.length > 0 && (
        <Box mt="lg">
          <Flex align="center" gap="xs">
            <Pagination
              value={table.getState().pagination.pageIndex + 1}
              onChange={(e) => table.setPageIndex(e - 1)}
              total={table.getPageCount()}
            />

            <Select
              size="sm"
              style={{ width: "120px" }}
              value={`${table.getState().pagination.pageSize}`}
              onChange={(e) => table.setPageSize(e)}
              data={[5, 10, 20, 30, 40, 50, 100].map((pageSize, i) => ({
                key: i,
                label: `${pageSize} items`,
                value: `${pageSize}`,
                placeholder: "Select a pagesize",
              }))}
            />
            {!isShared && (
              <Button
                leftSection={<IconDownload size={16} />}
                size="sm"
                onClick={onCSVClick}
                variant="light"
                color="gray"
              >
                Download CSV
              </Button>
            )}
          </Flex>
          <Text size="xs" mt="xs">
            Page {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount().toLocaleString()}, {tableConfig.data.length}{" "}
            item
            {tableConfig.data.length === 1 ? "" : "s"} total
          </Text>
        </Box>
      )}
    </Box>
  );
}
