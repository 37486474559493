import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Anchor,
  Badge,
  Box,
  Button,
  Divider,
  Group,
  List,
  Loader,
  Radio,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import styled from "styled-components";

import { UserNewContactMethod } from "./";

export default function UserContactTransfer({
  emailData,
  isEmail = false,
  isPhone = false,
  loading,
  onContactMethodCreate,
  onTransferSubmit,
  phoneData,
  selected,
  setSelected,
  userId,
  newUser = false,
  onVerifySkip,
  onVerifySuccess,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchMethod, setSearchMethod] = useState("phone");
  const [showTransferConfirm, setTransferConfirm] = useState(false);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    if (isEmail) {
      setSearchMethod("email");
    }
  }, [isEmail]);

  useEffect(() => {
    setSearchResults([]);
    setSelected(null);
  }, [searchMethod]);

  useEffect(() => {
    searchByContactMethod();
  }, [searchValue]);

  function searchByContactMethod() {
    if (!searchValue.length) return;

    if (searchValue.length > 3 && !searchLoading && searchResults.length === 0)
      return;

    setSelected(null);
    setSearchLoading(true);

    const req = {
      search_value: searchValue,
      table: searchMethod,
      page: 0,
      page_size: 30,
    };

    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    if (userId) req.user_id = userId;

    const url = newUser
      ? `/retrieve-users/`
      : `/users/management/contacts-minus/`;

    axios
      .post(url, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setSearchLoading(false);
        if (newUser) {
          setSearchResults(data.response[0].data);
        } else {
          setSearchResults(data.response);
        }
      })
      .catch((err) => {
        setSearchLoading(false);
      });
  }

  if (showTransferConfirm && selected) {
    return (
      <TransferConfirm
        isPhone={isPhone}
        isEmail={isEmail}
        joinId={selected}
        onCancel={() => setTransferConfirm(false)}
        onSubmit={onTransferSubmit}
        userId={userId}
        loading={loading}
      />
    );
  }

  return (
    <div>
      {true && (
        <React.Fragment>
          <Group mb="xs">
            <Text size="sm" fw={600}>
              {searchResults.length === 0 ? "Create" : "Enter"} the new contact
              method
            </Text>
            {searchLoading && <Loader size="xs" />}
          </Group>
          <TextInput
            placeholder={`search ${
              searchMethod === "phone" ? "phones" : "emails"
            }...`}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            mb="sm"
          />
          {searchResults.length > 0 && (
            <React.Fragment>
              <StyledRadioList>
                {searchResults.map((s) => (
                  <Box mb="sm">
                    <Group align="flex-start">
                      {!newUser && (
                        <Radio
                          key={s.id}
                          name="contact"
                          onChange={() => setSelected(s.id)}
                          checked={s.id === selected}
                          mt="4px"
                          // disabled={!s.removeable}
                        />
                      )}
                      <div>
                        <Text>
                          {s.user ? (
                            <Anchor
                              target="_blank"
                              component={Link}
                              to={`/users/${s.user.id}`}
                              fw={600}
                              c="dark"
                              title="View User"
                            >
                              {s.number || s.address}
                            </Anchor>
                          ) : (
                            <Text>{s.title}</Text>
                          )}
                        </Text>
                        {s.user && s.user.last_seen && (
                          <Text mt="2px" size="xs" c="dimmed">
                            last seen at {s.user.last_seen}
                          </Text>
                        )}
                        {!newUser && (s.has_interactions || !s.removeable) && (
                          <Box mb="2px" mt="2px">
                            {s.has_interactions && (
                              <Badge
                                variant="light"
                                size="xs"
                                mr="xs"
                                color="red"
                              >
                                has interactions
                              </Badge>
                            )}
                            {!s.removeable && (
                              <Badge variant="light" size="xs" color="red">
                                not removeable
                              </Badge>
                            )}
                          </Box>
                        )}
                      </div>
                    </Group>
                  </Box>
                ))}
              </StyledRadioList>
              {!newUser && (
                <Button
                  mt="sm"
                  fullWidth
                  disabled={!selected}
                  onClick={() => setTransferConfirm(true)}
                  loading={loading}
                >
                  Transfer to user and make active
                </Button>
              )}
              {newUser && searchResults.length > 0 && (
                <Text size="sm" color="red" fw={600}>
                  Cannot do this
                </Text>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      {searchResults.length === 0 && !searchLoading && searchValue && (
        <React.Fragment>
          <UserNewContactMethod
            // id={contactData.id}
            phone={phoneData}
            email={emailData}
            userId={userId}
            isPhone={isPhone}
            isEmail={isEmail}
            // email={contactData.address}
            // phone={contactData.number_formatted}
            onSuccess={(newMethodId) => onContactMethodCreate(newMethodId)}
            value={searchValue}
            newUser={newUser}
            onVerifySkip={onVerifySkip}
            onVerifySuccess={onVerifySuccess}
          />
        </React.Fragment>
      )}
    </div>
  );
}

const StyledRadioList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-top: 15px;
`;

const TransferConfirm = ({
  isEmail = false,
  isPhone = false,
  joinId,
  loading,
  onCancel,
  onSubmit,
  userId,
}) => {
  const [info, setInfo] = useState(null);

  const noun = isEmail ? "email" : "number";
  const theme = useMantineTheme();
  const green = theme.colors.green[6];
  const red = theme.colors.red[6];

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    const req = {
      user_id: userId,
      [isPhone ? "phone_id" : "user_email_id"]: joinId,
      warning: true,
    };

    axios
      .post(`/user-contact-corrections/`, req)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setInfo(null);
      });
  }

  return (
    <div>
      {info && (
        <React.Fragment>
          {/* 1 */}
          {info.joins_to_replace.length > 0 && info.interaction_count > 0 && (
            <React.Fragment>
              <ScenarioNumber number={1} />
              <Text mb="sm">
                You want to add {info.start_with} to this user.
              </Text>
              <Text mb="sm">
                {info.start_with} <b>belongs to another user</b>. Its current
                owner has{" "}
                <ColoredText
                  color={red}
                  text={`${info.interaction_count} ${pluralize(
                    "interaction",
                    info.interaction_count
                  )}`}
                />{" "}
                using this {noun}. We are able to{" "}
                <ColoredText color={green} text="replace" /> it with another{" "}
                {noun}.
              </Text>
              <Text mb="sm">
                {info.start_with} will be <Emphasize>deactivated</Emphasize> for
                its current owner. We will <Emphasize>replace</Emphasize>
                it with {info.end_with} on the following:
              </Text>
            </React.Fragment>
          )}
          {/* 2 */}
          {info.joins_to_break.length > 0 && info.interaction_count > 0 && (
            <React.Fragment>
              <ScenarioNumber number={2} />
              <Text mb="sm">
                You want to add {info.start_with} to this user.
              </Text>
              <Text mb="sm">
                This {noun} <b>belongs to another user</b>. Its current owner
                has{" "}
                <ColoredText
                  color={red}
                  text={`${info.interaction_count} ${pluralize(
                    "interaction",
                    info.interaction_count
                  )}`}
                />{" "}
                using this {noun}. We are{" "}
                <ColoredText text="unable to replace" color={red} /> this {noun}{" "}
                with another.
              </Text>
              <Text mb="sm">
                {info.start_with} will be <Emphasize>deactivated</Emphasize> for
                its current owner. This action will leave{" "}
                <ColoredText text="incomplete contact info" color={red} /> on
                the following:
              </Text>
            </React.Fragment>
          )}
          {/* 3 */}
          {info.joins_to_break.length > 0 && info.interaction_count === 0 && (
            <React.Fragment>
              <ScenarioNumber number={3} />
              <Text mb="sm">
                You want to add {info.start_with} to this user.
              </Text>
              <Text mb="sm">
                This {noun} <b>belongs to another user</b>. Its current owner
                has <span style={{ color: green }}>no interactions</span> with
                this {noun}.
              </Text>
              <Text mb="sm">
                {info.start_with} will be <Emphasize>removed</Emphasize> from
                its current owner. This action will leave{" "}
                <ColoredText color={red} text="incomplete contact info" /> on
                the following:
              </Text>
            </React.Fragment>
          )}
          {/* 4 */}
          {info.joins_to_replace.length > 0 && info.interaction_count === 0 && (
            <React.Fragment>
              <ScenarioNumber number={4} />
              <Text mb="sm">
                You want to add {info.start_with} to this user.
              </Text>
              <Text mb="sm">
                This {noun} <b>belongs to another user</b>. Its owner has{" "}
                <ColoredText text="no interactions" color={green} /> using this{" "}
                {noun} and{" "}
                <ColoredText text={`has another ${noun}`} color={green} /> that
                can be used for contact.
              </Text>
              <Text mb="sm">
                {info.start_with} will be <Emphasize>removed</Emphasize> from
                its current owner and <Emphasize>replaced</Emphasize> with{" "}
                {info.end_with} for the following:
              </Text>
            </React.Fragment>
          )}
          {/* ? */}
          {info.joins_to_break.length === 0 &&
            info.joins_to_replace.length === 0 && (
              <React.Fragment>
                {info.interaction_count > 0 ? (
                  <React.Fragment>
                    <ScenarioNumber number={5} />
                    <Text mb="sm">
                      You want to add {info.start_with} to this user.
                    </Text>
                    <Text mb="sm">
                      This {noun} <b>belongs to another user</b>. There{" "}
                      {info.interaction_count === 1 ? "is" : "are"}{" "}
                      <ColoredText
                        color="red"
                        text={`${info.interaction_count} ${pluralize(
                          "interaction",
                          info.interaction_count
                        )}`}
                      />{" "}
                      using this {noun} and{" "}
                      <ColoredText
                        text="no locations or organizations"
                        color={green}
                      />{" "}
                      will be affected.
                    </Text>
                    <Text mb="sm">
                      We will <Emphasize>create</Emphasize> {info.start_with}{" "}
                      for this user and <Emphasize>deactivate</Emphasize> the{" "}
                      {noun} for its current owner.
                    </Text>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ScenarioNumber number={6} />
                    <Text mb="sm">
                      You want to add {info.start_with} to this user.
                    </Text>
                    <Text mb="sm">
                      This {noun} has{" "}
                      <ColoredText color={green} text="no interactions" />, and{" "}
                      <ColoredText
                        color={green}
                        text="no organizations or locations"
                      />{" "}
                      will be affected by the change.
                    </Text>
                    <Text mb="sm">
                      We will <Emphasize>create</Emphasize> {info.start_with}{" "}
                      for this user and <Emphasize>remove</Emphasize> the {noun}{" "}
                      from its current owner.
                    </Text>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          {info.joins_to_replace.length > 0 && (
            <List>
              {info.joins_to_replace.map((m, i) => (
                <List.Item key={`r${i}`}>
                  {m.name} ({m.organization ? "organization" : "location"})
                </List.Item>
              ))}
            </List>
          )}
          {info.joins_to_break.length > 0 && (
            <List>
              {info.joins_to_break.map((m, i) => (
                <List.Item key={`r${i}`}>
                  {m.name} ({m.organization ? "organization" : "location"})
                </List.Item>
              ))}
            </List>
          )}
          <Divider mt="lg" mb="lg" />
        </React.Fragment>
      )}
      <Button fullWidth mb="sm" onClick={onSubmit} loading={loading}>
        Confirm
      </Button>
      <Group justify="center">
        <Button
          onClick={() => onCancel()}
          size="xs"
          color="gray"
          variant="subtle"
        >
          Back
        </Button>
      </Group>
    </div>
  );
};

export const ColoredText = ({ color, text }) => (
  <span style={{ color }}>{text}</span>
);
export const Emphasize = ({ children }) => (
  <b>
    <i>{children}</i>
  </b>
);

export const ScenarioNumber = ({ number }) => (
  <Text size="xs" color="gray" cx="dimmed">
    #SCN{number}
  </Text>
);

export function pluralize(word, itemLength) {
  return `${word}${itemLength === 1 ? "" : "s"}`;
}
