import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ActionIcon, Flex, Text } from "@mantine/core";
import {
  IconPencil,
  IconHome,
  IconDeviceFloppy,
  IconArrowBack,
  IconStarFilled,
} from "@tabler/icons-react";
import axios from "axios";

export default function Header({
  interactionId,
  name,
  nickname = "",
  phoneNumber,
  title,
  onUpdate,
  onFavoriteClick,
}) {
  const [isOpen, setOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUsername(nickname);
  }, [nickname]);

  useEffect(() => {
    setOpen(false);
    setLoading(false);
  }, [title]);

  useEffect(() => {
    if (isOpen) {
      const el = document.getElementById("usernameEdit");
      if (el) {
        el.focus();
      }
    }
  }, [isOpen]);

  function onSaveClick() {
    const req = {
      station_interaction_id: interactionId,
      nickname: username,
    };

    setLoading(true);

    axios
      .post(`/station-interactions/${interactionId}/add-nickname/`, req)
      .then(() => {
        setOpen(false);
        setLoading(false);
        onUpdate();
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <Flex align="center">
      <Flex
        align="center"
        style={{
          flexGrow: 1,
        }}
        gap="sm"
      >
        {!isOpen && (
          <React.Fragment>
            <Text size="xl" fw={600}>
              {nickname || name || phoneNumber}
            </Text>
            {nickname && <Text c="dimmed">{phoneNumber}</Text>}
            <ActionIcon
              variant="subtle"
              onClick={() => setOpen(true)}
              title="Edit nickname"
            >
              <IconPencil />
            </ActionIcon>
          </React.Fragment>
        )}
        {isOpen && (
          <Flex align="center">
            <StyledInput
              type="text"
              value={username}
              placeholder="Add a nickname"
              onChange={(e) => setUsername(e.target.value)}
              id="usernameEdit"
              disabled={loading}
            />
            <Flex gap="xs">
              <ActionIcon
                onClick={onSaveClick}
                title="Save"
                disabled={loading}
                loading={loading}
                variant="subtle"
              >
                <IconDeviceFloppy />
              </ActionIcon>
              <ActionIcon
                onClick={() => setOpen(false)}
                disabled={loading}
                title="Cancel"
                variant="subtle"
              >
                <IconArrowBack />
              </ActionIcon>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex align="center">
        {/* <ActionIcon onClick={onFavoriteClick} title="Show Favorites">
          <IconStarFilled />
        </ActionIcon> */}
        <ActionIcon component={Link} to="/messaging" variant="subtle">
          <IconHome title="Home" />
        </ActionIcon>
      </Flex>
    </Flex>
  );
}

const StyledInput = styled.input`
  padding: 8px;
  font-size: 1.15em;
  border-radius: 5px;
  /* border: 2px solid #000; */
  border: none;
  color: var(--textThirdColor);
  background: var(--inputBackground);
  width: 250px;
  margin-right: 8px;
  font-family: inherit;

  &:focus {
    outline: none;
  }
`;
