import React from "react";
import { Alert, Flex, Text, Button } from "@mantine/core";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export default function EffortAlerts({ effortId, setShowWizard }) {
  const navigate = useNavigate();

  const { data: alerts } = useQuery({
    initialData: [],
    queryKey: [`efforts${effortId}ConfigAlerts`],
    queryFn: async () =>
      axios
        .post(`/efforts/${effortId}/data-grabber/`, {
          context: `config_alerts`,
          campaign_effort_id: effortId,
        })
        .then(({ data }) => {
          return data.response;
        })
        .catch((err) => {
          throw err;
        }),
  });

  const arr = alerts
    .filter((f) => f.trigger)
    .map((m) => ({
      ...m,
      level: m.color === "yellow" ? 1 : 2,
    }))
    .sort((a, b) => a.level - b.level);

  if (!arr.length) return null;

  return (
    <>
      {arr.map((alert, i) => (
        <Alert key={i} radius="lg" mb="md" color={alert.color}>
          <Flex gap="xs" align="center">
            <Text size="sm" fw={600} style={{ flexGrow: 1 }}>
              {alert.message}
            </Text>
            {alert.context && (
              <Button
                onClick={() => {
                  setShowWizard(false);
                  if (alert.alt_path) {
                    navigate(`/${alert.alt_path}`);
                  } else {
                    navigate(
                      `/efforts/${effortId}${getConfigPathname(alert.context)}`
                    );
                  }
                }}
                size="xs"
                // variant="light"
                color={alert.color}
                radius="lg"
                ml="sm"
              >
                Take me there
              </Button>
            )}
          </Flex>
        </Alert>
      ))}
    </>
  );
}

function getConfigPathname(context) {
  switch (context) {
    case "awards":
      return "/awards";
    case "edit":
      return "";
    case "locations":
      return "/locations";
    case "keyword":
      return "/keywords";
    case "build":
      return "/builder";
    default:
      return "";
  }
}
