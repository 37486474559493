import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import {
  Modal,
  ActionIcon,
  Card,
  Flex,
  Loader,
  Title,
  TextInput,
  Pagination,
  Table,
  SegmentedControl,
} from "@mantine/core";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { IconExclamationMark } from "@tabler/icons-react";
import { useSelector } from "react-redux";

const statusOptions = [
  { label: "All", value: "entire" },
  { label: "Waiting", value: "waiting" },
  { label: "Queued", value: "queued" },
  { label: "Sent", value: "sent" },
  { label: "Delivered", value: "delivered" },
  { label: "Opened", value: "opened" },
  { label: "Bounced", value: "bounced" },
  { label: "Errored", value: "errored" },
];

export default function BulkCommunicationMesssages({ id, refresh }) {
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("entire");
  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const columns = [
    {
      header: "Name",
      // accessorKey: "recipient_name",
      id: "name",
      enableSorting: false,
      cell: (info) => {
        const { original } = info.row;
        const hasErrorData = JSON.stringify(original.error_data) !== "{}";
        if (hasErrorData) {
          return (
            <Flex gap="xs" align="center">
              <ErrorListModal items={[{ ...original.error_data }]} />
              <span>{original.recipient_name}</span>
            </Flex>
          );
        } else {
          return original.recipient_name;
        }
      },
    },
    { header: "Email", accessorKey: "email", enableSorting: false },
    // { header: "Date", accessorKey: "updated_at", enableSorting: false },
    {
      header: "Status",
      accessorKey: "status_formatted",
      enableSorting: false,
    },
  ].filter((f) =>
    statusValue !== "entire" ? !["Status"].includes(f.header) : true
  );

  const table = useReactTable({
    data: items,
    columns,
    manualPagination: true,
    enableMultiSort: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  useEffect(() => {
    let p = new Promise((resolve, reject) => {
      resolve(table.setPageIndex(0));
    });
    p.then((result) => {
      fetchData();
    }).catch((error) => {
      console.log(error);
    });
  }, [statusValue]);

  useEffect(() => {
    table.setPageIndex(0);
    if (table.getState().pagination.pageIndex === 0) {
      fetchData();
    }
  }, [searchValue]);

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(table.getState().pagination), refresh]);

  function onSearchSubmit() {
    let p = new Promise((resolve, reject) => {
      resolve(table.setPageIndex(0));
    });
    p.then(() => {
      fetchData();
    }).catch((error) => {
      console.log(error);
    });
  }

  function fetchData() {
    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      page_size: table.getState().pagination.pageSize,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
      search_value: searchValue,
      status: statusValue,
      bulk_communicator_id: id,
    };

    axios
      .post(`/bulk-communicators/${id}/retrieve-messages/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setItems(data.response[0].data);
        setTotal(data.response[0].total);
        setPageCount(data.response[0].page_count);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
        setTotal(0);
        setPageCount(1);
      });
  }

  return (
    <div>
      <Flex gap="xs" align="center" mb="sm">
        <Title order={4}>Messages</Title>
        {loading && <Loader size="xs" />}
      </Flex>
      <Flex style={{ width: "100%" }} gap="xs" align="center">
        <TextInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search..."
          style={{
            flexGrow: 1,
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearchSubmit();
            }
          }}
        />
      </Flex>
      <SegmentedControl
        value={statusValue}
        data={statusOptions.filter((f) =>
          f.variety !== 2 ? !["queued"].includes(f.value) : true
        )}
        size="xs"
        onChange={(e) => setStatusValue(e)}
        mt="sm"
      />
      <Card mt="sm">
        <Table.ScrollContainer minWidth={1000}>
          <Table striped>
            <Table.Thead>
              <Table.Tr>
                {table.getFlatHeaders().map((header) => (
                  <Table.Th key={header.id}>
                    {header.column.columnDef.header}
                  </Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Table.Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Table.Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Td>
                      );
                    })}
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Card>
      <Pagination
        value={table.getState().pagination.pageIndex + 1}
        onChange={(e) => {
          table.setPageIndex(e - 1);
        }}
        total={pageCount}
        mt="lg"
      />
    </div>
  );
}

const ErrorListModal = ({ items = [] }) => {
  const [isOpen, setOpen] = useState(false);
  const managerInfo = useSelector((state) => state.manager);

  function onClose() {
    setOpen(false);
  }

  return (
    <>
      <ActionIcon
        onClick={() => setOpen(true)}
        size="xs"
        radius="xl"
        color="red"
      >
        <IconExclamationMark />
      </ActionIcon>
      <Modal opened={isOpen} onClose={onClose} size="lg">
        {items.map((item, i) => (
          <div key={i}>
            <Table striped>
              <Table.Tbody>
                {Object.keys(item)
                  .filter((f) =>
                    managerInfo ? ["Description", "message"].includes(f) : true
                  )
                  .map((m, ii) => (
                    <Table.Tr key={ii}>
                      <Table.Td>{m}</Table.Td>
                      <Table.Td>{JSON.stringify(item[m])}</Table.Td>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table>
          </div>
        ))}
      </Modal>
    </>
  );
};
