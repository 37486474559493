import React, { useState, useEffect } from "react";
import { IconEye } from "@tabler/icons-react";
import { Modal, ActionIcon, Table } from "@mantine/core";

import UserInteractionMiniDetail from "./UserInteractionMiniDetail";

const UserInteractionTableBody = ({
  occurredAt = "",
  name = "",
  variety = "",
  entryCount = "",
  interactionId = "",
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Table.Tr>
      <Modal
        size="xl"
        opened={open}
        onClose={() => setOpen(false)}
        title={"User Interaction"}
        centered
      >
        <UserInteractionMiniDetail interactionId={interactionId} />
      </Modal>
      <Table.Td>{occurredAt}</Table.Td>
      <Table.Td>{name}</Table.Td>
      <Table.Td>{variety}</Table.Td>
      <Table.Td>{entryCount}</Table.Td>
      <Table.Td>
        <ActionIcon variant="subtle" color="gray" onClick={() => setOpen(true)}>
          <IconEye size={16} />
        </ActionIcon>
      </Table.Td>
    </Table.Tr>
  );
};

export default UserInteractionTableBody;
