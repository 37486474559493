import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Card, Badge, Text, Group } from "@mantine/core";
import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

import { ErrorView, PageTitle } from "@components/shared";

const varietyColors = {
  1: "green",
  2: "teal",
  3: "blue",
  4: "indigo",
};

export default function ApiUseDetail() {
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/api-uses/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  return (
    <div>
      <PageTitle
        title={info.id}
        subtitle="Api Use"
        icon={entityIcon.api()}
        iconColor={entityColor.api}
      />
      <Card p="lg">
        <Group>
          <Text mb="sm" size="xl" fw={500}>
            {info.context_formatted}
          </Text>
          <Badge
            variant="light"
            p="sm"
            mb="sm"
            color={varietyColors[info.variety]}
          >
            {info.variety_formatted}
          </Badge>
        </Group>
        <Group>
          <Text mb="sm">{new Date(info.created_at).toLocaleDateString()}</Text>
          <Text mb="sm">{new Date(info.created_at).toLocaleTimeString()}</Text>
        </Group>
        {info.location && (
          <Text mb="sm" color={entityColor.location}>
            <Group>
              {entityIcon.location()}
              {info.location.name}
            </Group>
          </Text>
        )}
        {info.organization && (
          <Text mb="sm" color={entityColor.organization}>
            <Group>
              {entityIcon.organization()}
              {info.organization.name}
            </Group>
          </Text>
        )}
        {info.contest && (
          <Text mb="sm" color={entityColor.contest}>
            <Group>
              {entityIcon.contest()}
              {info.contest.name}
            </Group>
          </Text>
        )}
        {info.campaign && (
          <Text mb="sm" color={entityColor.campaign}>
            <Group>
              {entityIcon.campaign()}
              {info.campaign.title}
            </Group>
          </Text>
        )}
        {info.campaign_effort && (
          <Text mb="sm" color={entityColor.effort}>
            <Group>
              {entityIcon.effort()}
              {info.campaign_effort.title}
            </Group>
          </Text>
        )}
      </Card>
    </div>
  );
}
