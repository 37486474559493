import { useState } from "react";
import { IconChevronRight, IconChevronsRight } from "@tabler/icons-react";
import {
  Combobox,
  TextInput,
  useCombobox,
  Checkbox,
  ActionIcon,
  Group,
  Text,
} from "@mantine/core";
import classes from "./TransferList.module.css";

function RenderList({ options, onTransfer, type, title }) {
  const combobox = useCombobox();
  const [value, setValue] = useState([]);
  const [search, setSearch] = useState("");

  const handleValueSelect = (val) =>
    setValue((current) =>
      current.includes(val)
        ? current.filter((v) => v !== val)
        : [...current, val]
    );

  const filteredOptions = options.filter((item) =>
    item.label.toLowerCase().includes(search.toLowerCase().trim())
  );

  const items = filteredOptions.map((item) => (
    <Combobox.Option
      value={item.value}
      key={item.value}
      active={value.includes(item.value)}
      onMouseOver={() => combobox.resetSelectedOption()}
    >
      <Group gap="sm">
        <Checkbox
          checked={value.includes(item.value)}
          onChange={() => {}}
          aria-hidden
          tabIndex={-1}
          style={{ pointerEvents: "none" }}
        />
        <span>{item.label}</span>
      </Group>
    </Combobox.Option>
  ));

  return (
    <div className={classes.renderList} data-type={type}>
      <Text fw={600} mb="sm">
        {title}
      </Text>
      <Combobox store={combobox} onOptionSubmit={handleValueSelect}>
        <Combobox.EventsTarget>
          <Group wrap="nowrap" grow gap={0} className={classes.controls}>
            <TextInput
              placeholder="Search"
              classNames={{ input: classes.input }}
              value={search}
              onChange={(event) => {
                setSearch(event.currentTarget.value);
                combobox.updateSelectedOptionIndex();
              }}
            />
            <ActionIcon
              radius={0}
              variant="default"
              size={36}
              title="Transfer selected"
              disabled={!value.length}
              className={classes.control}
              onClick={() => {
                onTransfer(value);
                setValue([]);
              }}
            >
              <IconChevronRight className={classes.icon} />
            </ActionIcon>
            <ActionIcon
              radius={0}
              variant="default"
              size={36}
              title="Transfer all"
              className={classes.control}
              onClick={() => {
                onTransfer(filteredOptions.map((m) => m.value));
                setValue([]);
              }}
            >
              <IconChevronsRight className={classes.icon} />
            </ActionIcon>
          </Group>
        </Combobox.EventsTarget>

        <div className={classes.list}>
          <Combobox.Options>
            {items.length > 0 ? (
              items
            ) : (
              <Combobox.Empty>Nothing found....</Combobox.Empty>
            )}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
}

export default function TransferList({
  titles = ["Title", "Title"],
  value = [[], []],
  onChange,
}) {
  function handleTransfer(transferFrom, options) {
    // console.log(options);
    return (current) => {
      const transferTo = transferFrom === 0 ? 1 : 0;
      const transferFromData = current[transferFrom].filter(
        (item) => !options.includes(item)
      );
      const transferToData = [...current[transferTo], ...options];

      const result = [];
      result[transferFrom] = transferFromData;
      result[transferTo] = transferToData;
      return result;
    };
  }

  // const handleTransfer = (transferFrom, options) => {
  //   const transferTo = transferFrom === 0 ? 1 : 0;
  //   const transferFromData = current[transferFrom].filter(
  //     (item) => !options.includes(item)
  //   );
  //   const transferToData = [...current[transferTo], ...options];

  //   const result = [];
  //   result[transferFrom] = transferFromData;
  //   result[transferTo] = transferToData;
  //   return onChange(result);
  // };

  return (
    <div className={classes.root}>
      <RenderList
        type="forward"
        options={value[0]}
        // onTransfer={(options) => onChange(handleTransfer(0, options))}
        onTransfer={(options) =>
          onChange([
            [],
            options.map((m) => ({
              value: m,
            })),
          ])
        }
        title={titles[0]}
      />
      <RenderList
        type="backward"
        options={value[1]}
        title={titles[1]}
        onTransfer={(options) =>
          onChange([
            options.map((m) => ({
              value: m,
            })),
            [],
          ])
        }
        // onTransfer={(options) => handleTransfer(1, options)}
        // onTransfer={(options) => onChange(handleTransfer(1, options))}
      />
    </div>
  );
}
