import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Group, Text } from "@mantine/core";
import styled from "styled-components";
import toast from "react-hot-toast";

import { CodeInput } from "@components/Auth";
import { emailValidation, phoneValidation } from "@util/validation";

const UserNewContactMethod = ({
  isPhone = false,
  isEmail = false,
  newUser = false,
  userId,
  onSuccess,
  onVerifySuccess,
  onVerifySkip,
  value,
}) => {
  const [loading, setLoading] = useState(false);
  const [codeValue, setCodeValue] = useState("");
  const [remoteCodeId, setRemoteCodeId] = useState(null);
  const [codeValidated, setCodeValidated] = useState(false);
  const [creating, setCreating] = useState(false);
  const [showConfirm, setConfirm] = useState(false);

  const noun = isPhone ? "phone" : "email";

  function resetState() {
    setCodeValue("");
    setRemoteCodeId(null);
    setCodeValidated(false);
  }

  useEffect(() => {
    if (codeValue.length === 6) {
      verifyCode();
    }
  }, [codeValue]);

  function sendCodeToMethod() {
    setLoading(true);

    const req = {};

    if (isEmail) req.email = value;
    if (isPhone) req.mobile_phone = value;

    axios
      // .post(`/${noun}s/${id}/toggle-${noun}/`, req)
      .post(`/microsite/send-verification/`, req)
      .then(({ data }) => {
        setLoading(false);
        setRemoteCodeId(data.response[0].remote_code_id);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function verifyCode() {
    const req = {
      code: codeValue,
      remote_code_id: remoteCodeId,
    };

    axios
      .post(`/microsite/verify-code/`, req)
      .then(() => {
        if (newUser) {
          return onVerifySuccess(value);
        }

        setCodeValidated(true);
      })
      .catch((err) => {
        toast.error(err);
        setCodeValue("");
      });
  }

  function createNewMethod() {
    if (newUser) {
      return onVerifySkip(value);
    }

    setCreating(true);
    const req = {
      user_id: userId,
      verified: true,
    };

    if (isEmail) req.email = value;
    if (isPhone) req.number = value;

    axios
      .post(`/users/${userId}/${noun}s/`, req)
      .then(({ data }) => {
        toast.success("Created");
        setCreating(false);
        onSuccess(data.response[0].id);
      })
      .catch((err) => {
        toast.error(err);
        setCreating(false);
      });
  }

  const valueValid = isPhone
    ? phoneValidation.test(value)
    : emailValidation.test(value);

  if (codeValidated) {
    return (
      <div>
        <Text mb="sm">We will create the {noun} and add it to this join.</Text>
        <Button fullWidth onClick={createNewMethod} loading={creating}>
          Create {noun}
        </Button>
        <Group justify="center" mt="sm">
          <Button
            color="gray"
            size="xs"
            variant="subtle"
            disabled={creating}
            onClick={() => resetState()}
          >
            Get me out of here
          </Button>
        </Group>
      </div>
    );
  }

  return (
    <React.Fragment>
      {remoteCodeId ? (
        <React.Fragment>
          <Text mb="sm" align="center" fw={600} size="sm">
            {isEmail &&
              `An email was sent to ${value} containing a six digit code.`}
            {isPhone &&
              `A text was sent to ${value} containing a six digit code.`}{" "}
            Enter the six digit code below.
          </Text>
          <StyledInputs>
            <CodeInput value={codeValue} onChange={(e) => setCodeValue(e)} />
          </StyledInputs>
          <Group justify="center">
            <Button
              variant="subtle"
              color="gray"
              size="xs"
              mt="lg"
              onClick={() => setRemoteCodeId(null)}
            >
              Back
            </Button>
          </Group>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!showConfirm && (
            <React.Fragment>
              {!newUser && (
                <Text mb="lg" fw={600} size="sm">
                  If you haven't verified the {noun} {value}, do so now.
                </Text>
              )}
              <Button
                loading={loading}
                onClick={sendCodeToMethod}
                fullWidth
                disabled={!value || !valueValid}
              >
                Verify ownership
              </Button>
            </React.Fragment>
          )}
          <Group mt="sm" justify="center">
            {showConfirm ? (
              <div>
                <Text fw={600} size="sm" mb="xs" align="center">
                  Are you sure you want to create without verification?
                </Text>
                <Group justify="center">
                  <Button
                    mr="sm"
                    size="xs"
                    color="gray"
                    variant="subtle"
                    onClick={createNewMethod}
                    loading={creating}
                  >
                    Yes
                  </Button>
                  <Button
                    size="xs"
                    color="gray"
                    variant="subtle"
                    onClick={() => setConfirm(false)}
                  >
                    No
                  </Button>
                </Group>
              </div>
            ) : (
              <Button
                size="xs"
                color="gray"
                variant="subtle"
                onClick={() => setConfirm(true)}
                disabled={!value || !valueValid}
              >
                Create without verification
              </Button>
            )}
          </Group>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const StyledInputs = styled.div`
  input {
    border: 1px solid #000;
    background: #eee;
  }
`;

const StyledRadioList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-top: 15px;
`;

export default UserNewContactMethod;
