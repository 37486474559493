import React, { useState, useEffect } from "react";
import axios from "axios";
import { Anchor } from "@mantine/core";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import * as Yup from "yup";

import { BasicForm } from "@components/shared";
import { initialCampaignConfiguration } from "./helpers";

import timezones from "@lib/timezones";
import { todayStart, formatInputDate, dayStart, dayEnd } from "@util/dates";

export default function CampaignForm({
  allowManagerEdit = true,
  apiContext = "",
  apiProgram = "",
  endDate = "",
  endTime = dayEnd,
  id = null,
  internalName = "",
  locationId = null,
  onSuccess = () => {},
  organizationId = null,
  startDate = todayStart,
  startTime = dayStart,
  thirdPartyApiEnabled = false,
  timezone = null,
  title = "",
}) {
  const [loading, setLoading] = useState(false);
  const isManager = useSelector((state) => state.manager) ? true : false;

  function onSubmit(formData) {
    setLoading(true);

    const formatted = {
      ...formData,
      // microsite_url: id
      //   ? formData.microsite_url.toLowerCase().replaceAll(" ", "-")
      //   : formData.title.toLowerCase().replaceAll(" ", "-"),
    };

    if (id) return onUpdate(formatted);

    return onCreate(formatted);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      configuration: {
        ...initialCampaignConfiguration,
        settings: {
          ...initialCampaignConfiguration.settings,
          preferred_location: locationId
            ? false
            : initialCampaignConfiguration.settings.preferred_location,
        },
      },
    };

    if (organizationId) req.organization_id = organizationId;

    if (locationId) req.location_id = locationId;

    axios
      .post(`/campaigns/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Created!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      campaign_id: id,
    };

    axios
      .put(`/campaigns/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "title",
      label: "Title",
      initialValue: title,
      required: true,
      schema: () => Yup.string().max(100, "Too long!").required("Required"),
    },
    {
      name: "internal_name",
      label: "Internal Name",
      initialValue: internalName,
      required: true,
      schema: () => Yup.string().max(100, "Too long!").required("Required"),
    },
    // {
    //   name: "microsite_url",
    //   label: "Path (example.com/path-goes-here)",
    //   initialValue: micrositeUrl,
    //   required: true,
    //   schema: () =>
    //     Yup.string()
    //       .matches(/^[a-zA-Z0-9-_]+$/, "Not a valid format")
    //       .required("Required"),
    // },
    {
      name: "start_date",
      initialValue: startDate,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: "Start Date",
      datepicker: {
        min: !id ? formatInputDate(new Date()) : "",
      },
    },
    {
      name: "start_time",
      initialValue: startTime,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: `Start Time${!id ? " (defaults to start of day)" : ""}`,
      timepicker: {},
    },
    {
      name: "end_date",
      initialValue: endDate,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: "End Date",
      datepicker: {
        min: !id ? formatInputDate(new Date()) : "",
      },
    },
    {
      name: "end_time",
      initialValue: endTime,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: `End Time${!id ? " (defaults to end of day)" : ""}`,
      timepicker: {},
    },
    {
      name: "timezone",
      initialValue: timezone,
      required: true,
      select: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: "Timezone",
      options: timezones,
    },
    {
      name: "api_context",
      label: "API Context",
      initialValue: apiContext,
      required: false,
      schema: () => Yup.string().max(50, "Too long!"),
    },
    {
      name: "api_program",
      label: "API Program",
      initialValue: apiProgram,
      required: false,
      schema: () => Yup.string().max(50, "Too long!"),
    },
    {
      name: "third_party_api_enabled",
      label: "Third Party API Enabled",
      initialValue: thirdPartyApiEnabled,
      required: false,
      schema: () => null,
      toggle: true,
    },
    {
      name: "allow_manager_edit",
      label: "Allow manager to edit",
      initialValue: allowManagerEdit,
      required: false,
      schema: () => null,
      toggle: true,
    },
  ]
    .filter((f) =>
      !id
        ? ![
            "timezone",
            "internal_name",
            "api_context",
            "api_program",
            "microsite_url",
            "third_party_api_enabled",
            "allow_manager_edit",
          ].includes(f.name)
        : true
    )
    .filter((f) =>
      isManager
        ? ![
            "third_party_api_enabled",
            "api_context",
            "api_program",
            "allow_manager_edit",
          ].includes(f.name)
        : true
    )
    .filter((f) =>
      !thirdPartyApiEnabled
        ? !["api_context", "api_program"].includes(f.name)
        : true
    );

  return (
    <React.Fragment>
      <BasicForm
        buttonProps={{
          fullWidth: !id ? true : false,
        }}
        loading={loading}
        onSubmit={onSubmit}
        fields={fields}
      />
    </React.Fragment>
  );
}
