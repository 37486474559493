import React from "react";

import { BulkCommunicationManagement } from "@components/BulkCommunication";

export default function BulkCommunicationIndex() {
  return (
    <div>
      <BulkCommunicationManagement />
    </div>
  );
}
