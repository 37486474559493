import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Route, Routes, Link } from "react-router-dom";
import { Anchor, Box } from "@mantine/core";

import { EmailActivityList, EmailSuppressionList } from "@components/Email";
import { ErrorView, Subnav, PageTitle } from "@components/shared";
import { EmailDetail } from "@pages/Admin";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function PostmarkServerStreamDetail({ apiToken, serverId }) {
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const params = useParams();
  const { id, streamId } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .post(`/postmark/message-streams/${streamId}/`, {
        message_stream_id: streamId,
        server_token: apiToken,
      })
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  const Header = () => (
    <>
      <PageTitle
        title={info.Name}
        subtitle={info.Description}
        icon={entityIcon.email()}
        iconColor={entityColor.email}
      />
      <Subnav
        links={[
          {
            text: "Activity",
            to: `/postmark/${id}/streams/${streamId}`,
          },
          {
            text: "Suppressions",
            to: `/postmark/${id}/streams/${streamId}/suppressions`,
          },
        ]}
        buttonColor={entityColor.email}
      />
    </>
  );

  return (
    <div>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Header />
              <EmailActivityList
                apiToken={apiToken}
                serverId={serverId}
                streamId={streamId}
              />
            </>
          }
        />
        <Route
          path="suppressions"
          element={
            <>
              <Header />
              <EmailSuppressionList
                apiToken={apiToken}
                serverId={serverId}
                streamId={streamId}
              />
            </>
          }
        />
        <Route
          path="messages/:messageId/*"
          element={
            <>
              <Box mb="sm">
                <Anchor
                  component={Link}
                  to={`/postmark/${id}/streams/${streamId}`}
                  c="dimmed"
                  size="xs"
                >
                  back to {info.Name} stream
                </Anchor>
              </Box>
              <EmailDetail apiToken={apiToken} />
            </>
          }
        />
      </Routes>
    </div>
  );
}
