import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Alert,
  Anchor,
  Button,
  Card,
  Flex,
  Grid,
  Group,
  Loader,
  Pagination,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";

import { formatArtistList } from "@components/Curation/helpers";
import { songQueryVarieties } from "./helpers";

const buttonColors = {
  remove: "red",
  add: "blue",
  move: "orange",
};

export default function SongQuerySongAssignment({
  locationId,
  songQueryId,
  songQueryName,
  songQueryVariety,
}) {
  const [refresh, setRefresh] = useState(false);

  const guideItems = [
    {
      button_text: "Add",
      button_color: buttonColors.add,
      text: `song to ${songQueryName}`,
    },
    {
      button_text: "Remove",
      button_color: buttonColors.remove,
      text: `song from ${songQueryName}`,
    },
    {
      button_text: "Move",
      button_color: buttonColors.move,
      text: `song to ${songQueryName}`,
    },
  ];

  return (
    <div>
      <Alert
        mb="sm"
        variant="light"
        color="yellow"
        title="Song Assignment Guide"
      >
        <Text mb="sm">
          The following actions are available for the songs below:
        </Text>
        <Flex gap="lg">
          {guideItems.map((item, i) => (
            <Flex gap="xs" key={i}>
              <Button
                size="xs"
                color={item.button_color}
                variant={item.button_text === "Add" ? "filled" : "light"}
              >
                {item.button_text}
              </Button>
              <Text fw={600}>{item.text}</Text>
            </Flex>
          ))}
        </Flex>
      </Alert>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <SongTable
            title="Added Songs"
            songQueryVariety={songQueryVariety}
            songQueryId={songQueryId}
            refresh={refresh}
            reqData={{
              url: `/song-queries/${songQueryId}/retrieve-added-songs/`,
              data: {
                song_query_id: songQueryId,
                refresh,
              },
            }}
            isAvailable
            isAdded
            onSuccess={() => setRefresh(!refresh)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <SongTable
            title="Available Songs"
            songQueryVariety={songQueryVariety}
            songQueryName={songQueryName}
            songQueryId={songQueryId}
            refresh={refresh}
            reqData={{
              url: `/retrieve-location-songs/`,
              data: {
                location_id: locationId,
                refresh,
              },
            }}
            isAvailable
            onSuccess={() => setRefresh(!refresh)}
          />
        </Grid.Col>
      </Grid>
    </div>
  );
}

const ScrollList = ({ children }) => (
  <div style={{ maxHeight: "800px", overflow: "auto" }}>{children}</div>
);

const SongTable = ({
  title,
  songQueryVariety,
  reqData,
  refresh,
  songQueryName,
  isAvailable,
  isAdded,
  onSuccess,
  songQueryId,
}) => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);
  const [songs, setSongs] = useState([]);
  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const table = useReactTable({
    manualPagination: true,
    data: songs,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 25,
      },
    },
  });

  useEffect(() => {
    fetchSongs();
  }, [
    songQueryId,
    searchValue,
    JSON.stringify(table.getState().pagination),
    refresh,
  ]);

  function fetchSongs() {
    setLoading(true);

    const req = {
      ...reqData.data,
      // location_id: locationId,
      pagination: true,
      search_value: searchValue,
      variety: 1,
      page_size: table.getState().pagination.pageSize,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
    };

    const cancel = cancelRef.current;
    if (cancel) cancel();

    axios
      .post(reqData.url, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setSongs(data.response[0].data);
        setPageCount(data.response[0].page_count);
        if (data.response[0].total !== undefined) {
          setTotal(data.response[0].total);
        } else {
          setTotal(data.response[0].data.length);
        }
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setSongs([]);
        setLoading(false);
      });
  }

  return (
    <>
      <Flex gap="xs">
        <Title mb="xs" order={4}>
          {title} ({total})
        </Title>
        {loading && <Loader size="sm" />}
      </Flex>
      <TextInput
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search..."
        size="md"
      />
      <ScrollList>
        {table.getRowModel().rows.map((song, i) => (
          <Song
            // key={song.original.location_song_id}
            key={`${i}${isAdded ? "added" : "available"}`}
            added={
              (songQueryVariety === 1 &&
                song.original.in_rotation_category &&
                song.original.in_rotation_category.id === songQueryId) ||
              (songQueryVariety === 2 &&
                song.original.in_sound_code &&
                song.original.in_sound_code.id === songQueryId)
            }
            artists={[{ ...song.original.artist }]}
            inRotationCategory={song.original.in_rotation_category}
            inSoundCode={song.original.in_sound_code}
            locationSongId={song.original.location_song_id}
            name={song.original.name}
            removable={isAdded}
            songQueryId={songQueryId}
            songQueryName={songQueryName}
            songQueryJoinId={song.original.id}
            songQueryVariety={songQueryVariety}
            onSuccess={() => {
              onSuccess();
            }}
          />
        ))}
      </ScrollList>
      <Pagination
        mt="sm"
        total={pageCount}
        onChange={(e) => table.setPageIndex(e - 1)}
        size="lg"
        disabled={loading}
      />
    </>
  );
};

const Song = ({
  added,
  artists,
  inRotationCategory,
  inSoundCode,
  name,
  locationSongId,
  removable,
  songQueryId,
  songQueryJoinId,
  songQueryName,
  songQueryVariety,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);

  function onAddClick() {
    const req = {
      location_song_join: locationSongId,
      song_query_id: songQueryId,
    };

    axios
      .post(`/add-song-to-query/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Added");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onRemoveClick() {
    const req = {
      song_query_join_id: songQueryJoinId,
    };

    axios
      .post(`/remove-song-from-query/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Removed!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const showInRotationCategory =
    songQueryVarieties.rotation_category === songQueryVariety &&
    inRotationCategory &&
    inRotationCategory.id !== songQueryId;

  const showInSoundCode =
    songQueryVarieties.sound_code === songQueryVariety &&
    inSoundCode &&
    inSoundCode.id !== songQueryId;

  return (
    <Card mt="xs" key={locationSongId}>
      <Group align="center">
        <div style={{ flexGrow: 1 }}>
          <Text
            fw={700}
            component={Link}
            to={`/location-songs/${locationSongId}`}
            size="sm"
          >
            {name}
          </Text>
          {artists && artists.length > 0 && (
            <Text size="sm" c="dimmed">
              {formatArtistList(artists)}
            </Text>
          )}
        </div>
        <div>
          {added && (
            <Button size="xs" disabled>
              Already Added
            </Button>
          )}
          {(showInRotationCategory || showInSoundCode) && (
            <Button
              variant="light"
              color={buttonColors.move}
              loading={loading}
              onClick={onAddClick}
              size="xs"
            >
              Move
            </Button>
          )}
          {!removable &&
            !added &&
            !showInRotationCategory &&
            !showInSoundCode && (
              <Button
                loading={loading}
                color={buttonColors.add}
                onClick={onAddClick}
                size="xs"
              >
                Add
              </Button>
            )}
          {removable && (
            <Button
              color={buttonColors.remove}
              loading={loading}
              onClick={onRemoveClick}
              variant="light"
              size="xs"
            >
              Remove
            </Button>
          )}
        </div>
      </Group>
      {showInRotationCategory && (
        <Text c="dimmed" size="xs">
          currently in{" "}
          <Anchor
            component={Link}
            to={`/song-categories/${inRotationCategory.id}`}
            c="dark"
          >
            <i>{inRotationCategory.name}</i>
          </Anchor>
        </Text>
      )}
      {showInSoundCode && (
        <Text c="dimmed" size="xs">
          currently in{" "}
          <Anchor
            component={Link}
            to={`/song-categories/${inSoundCode.id}`}
            c="dark"
          >
            <i>{inSoundCode.name}</i>
          </Anchor>
        </Text>
      )}
    </Card>
  );
};
