import React from "react";

import { CampaignList } from "@components/Campaign";

export default function CampaignIndex() {
  return (
    <React.Fragment>
      <CampaignList isIndex />
    </React.Fragment>
  );
}
