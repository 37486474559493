import React from "react";
import { Title, Box, TextInput } from "@mantine/core";

import { Rte } from "@components/shared";

export default function EntityConfigTextFields({
  fields = [],
  onChange,
  values,
}) {
  return (
    <React.Fragment>
      {fields.map((item) => (
        <Box mb="xs" key={item.key}>
          <Title order={5}>{item.title}</Title>
          {item.rte && (
            <Rte
              value={values[item.name]}
              onChange={(e) => onChange({ [item.name]: e })}
            />
          )}
          {!item.rte && (
            <TextInput
              value={values[item.name]}
              onChange={(e) => onChange({ [item.name]: e.target.value })}
            />
          )}
        </Box>
      ))}
    </React.Fragment>
  );
}
