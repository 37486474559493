import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, Route, Routes } from "react-router-dom";
import { Card, Grid, Title } from "@mantine/core";

import { SongForm, SongSettings } from "@components/Song";
import { CurationTagManagement } from "@components/Curation";
import { ErrorView, PageTitle, Subnav } from "@components/shared";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

import { formatArtistList } from "@components/Curation/helpers";

export default function SongDetail() {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/songs/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  return (
    <div>
      <PageTitle
        title={info.name}
        subtitle={
          <React.Fragment>
            Song from {info.artists ? formatArtistList(info.artists) : ""}
          </React.Fragment>
        }
        icon={entityIcon.song()}
        style={{ marginBottom: "1em" }}
      />
      <Subnav
        buttonColor={entityColor.location}
        links={[
          { to: `/songs/${id}`, text: "Detail" },
          { to: `/songs/${id}/settings`, text: "Settings" },
        ]}
      />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <React.Fragment>
              <Grid mt="lg">
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Title mb="sm" order={4}>
                    Edit
                  </Title>
                  <Card>
                    <SongForm id={id} name={info.name} />
                  </Card>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Title mb="sm" order={4}>
                    Tags
                  </Title>
                  <Card mb="sm">
                    <CurationTagManagement
                      songId={id}
                      items={info.tags}
                      fetchData={fetchData}
                    />
                  </Card>
                </Grid.Col>
              </Grid>
            </React.Fragment>
          }
        />
        <Route
          path="settings"
          element={
            <React.Fragment>
              <SongSettings songId={id} />
            </React.Fragment>
          }
        />
      </Routes>
    </div>
  );
}
