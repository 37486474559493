import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Badge,
  Center,
  Box,
  Group,
  Card,
  SegmentedControl,
  Text,
} from "@mantine/core";

import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";

const filters = [
  { label: "All", value: "all" },
  {
    label: (
      <Center>
        {entityIcon.location()}
        <Box ml={10}>Location</Box>
      </Center>
    ),
    value: "location",
  },
  {
    label: (
      <Center>
        {entityIcon.organization()}
        <Box ml={10}>Organization</Box>
      </Center>
    ),
    value: "organization",
  },
];

export default function EmailServerList() {
  const [servers, setServers] = useState([]);
  const [activeFilter, setFilter] = useState("all");

  useEffect(() => {
    fetchServers();
  }, []);

  function fetchServers() {
    axios
      .get(`/postmark-servers/`)
      .then(({ data }) => {
        setServers([
          ...data.response[0].location_servers.map((m) => ({
            ...m,
            location: true,
          })),
          ...data.response[0].organization_servers.map((m) => ({
            ...m,
            organization: true,
          })),
        ]);
      })
      .catch((err) => {
        setServers([]);
      });
  }

  const filteredServers = servers.filter((f) =>
    activeFilter === "all"
      ? true
      : activeFilter === "location"
      ? f.location
      : f.organization
  );

  return (
    <div>
      <SegmentedControl
        value={activeFilter}
        data={filters}
        onChange={(e) => setFilter(e)}
        mb="lg"
      />
      {filteredServers.map((s) => (
        <Card id={s.entity_id} mb="sm">
          <Group>
            <Text
              fw={700}
              component={Link}
              // href={`https://account.postmarkapp.com/servers/${s.postmark_server_id}/templates`}
              // target="_blank"
              to={`/postmark/${s.id}`}
              // to={
              //   s.location
              //     ? `/locations/${s.entity_id}/email`
              //     : `/organizations/${s.entity_id}/email`
              // }
            >
              {s.entity_name}
            </Text>
            <Badge
              variant="light"
              size="xs"
              color={
                s.location ? entityColor.location : entityColor.organization
              }
            >
              {s.location ? "location" : "organization"}
            </Badge>
          </Group>
          <Text c="dimmed">{s.name}</Text>
        </Card>
      ))}
    </div>
  );
}
