import React from "react";
import { Group, Tabs, Text, Title, Space } from "@mantine/core";
import entityIcon from "@util/entityIcon";

const SectionTitle = ({ children }) => (
  <Title order={4} mb="sm">
    {children}
  </Title>
);

export default function ContestReadOnly({ info }) {
  return (
    <div>
      {info.start_date_formatted && info.end_date_formatted && (
        <React.Fragment>
          <SectionTitle>Dates</SectionTitle>
          <Group spacing="xs">
            {entityIcon.calendar(24)}
            <Text>
              {info.start_date_formatted} - {info.end_date_formatted}
            </Text>
          </Group>
          <Space mb="xl" />
        </React.Fragment>
      )}
    </div>
  );
}
