import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Anchor, Table, Text, Group, Switch } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { KeywordStatusBadge } from "@components/Keyword";
import entityIcon from "@util/entityIcon";

export default function KeywordLinkList({ items = [], fetchData }) {
  return (
    <Box
      style={{
        maxHeight: "400px",
        overflow: "auto",
      }}
    >
      {items.length === 0 && <Text size="sm">No keywords yet</Text>}
      <Table striped>
        <Table.Tbody>
          {items.map((item, i) => (
            <Table.Tr key={item.id}>
              <Table.Td>
                <Group>
                  <Anchor
                    to={`/keywords/${item.id}`}
                    target="_blank"
                    component={Link}
                    c="dark"
                    fw={600}
                  >
                    {item.text}
                  </Anchor>
                  <KeywordStatusBadge
                    status={item.status}
                    text={item.status_formatted}
                  />
                </Group>
              </Table.Td>
              <Table.Td>
                <KeywordStatus
                  keywordId={item.id}
                  status={item.status}
                  fetchData={fetchData}
                />
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Box>
  );
}

const KeywordStatus = ({ keywordId, status, fetchData }) => {
  const [loading, setLoading] = useState(false);

  function onChange() {
    setLoading(true);
    const req = {
      keyword_id: keywordId,
      status: status === 1 || status === 3 ? 2 : 3,
    };

    axios
      .post(`/keywords/${keywordId}/set-status/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Status changed!");
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Switch checked={status === 2} onChange={onChange} disabled={loading} />
  );
};
