import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Box,
  Card,
  Center,
  Flex,
  Group,
  SegmentedControl,
  Text,
  TextInput,
  ThemeIcon,
} from "@mantine/core";
import { IconSearch, IconLock } from "@tabler/icons-react";
import { useSelector } from "react-redux";

import { DataFilters, PaginatedList } from "@components/shared";
import entityIcon from "@util/entityIcon";

const iconProps = {
  size: "xs",
  color: "gray",
  variant: "light",
  radius: "xl",
};

const filterArr = [
  {
    text: "Status",
    key_name: "status",
    default_value: 1,
    radio: true,
    options: [
      { text: "Created", value: 1 },
      { text: "Active", value: 2 },
      { text: "Inactive", value: 3 },
    ],
  },
].map((m, i) => ({
  ...m,
  all_values: m.options.map((mm) => mm.value),
  key: i + 1,
}));

const defaultFilters = filterArr.reduce((acc, cur) => {
  acc[cur.key_name] = cur.default_value;
  return acc;
}, {});

export default function UserList({
  items = null,
  requestInfo = {
    url: `/retrieve-users/`,
    data: {},
  },
}) {
  const [searchValue, setSearchValue] = useState("");
  const [method, setMethod] = useState("phone");
  const [filters, setFilters] = useState(defaultFilters);
  const [display, setDisplay] = useState(true);

  const managerInfo = useSelector((state) => state.manager);

  const formattedFilters = Object.keys(filters).reduce((acc, cur) => {
    const obj = filterArr.find((f) => f.key_name === cur);
    if (obj.radio) {
      acc[cur] = filters[cur];
    } else {
      acc[cur] = filters[cur].length ? filters[cur] : obj.all_values;
    }
    return acc;
  }, {});

  const reqData = {
    ...formattedFilters,
    ...requestInfo.data,
    search_value: searchValue,
    table: method,
  };

  const ManagerUserCard = ({ userInfo }) => {
    return (
      <React.Fragment>
        <Flex gap="xs" align="center">
          <Text fw={700}>{userInfo.full_name || "Unidentified"}</Text>
          {userInfo.locked && (
            <Badge
              variant="light"
              leftSection={
                <IconLock
                  size={12}
                  style={{ position: "relative", top: "2px" }}
                />
              }
              color="red"
              size="xs"
            >
              Locked
            </Badge>
          )}
        </Flex>
        {userInfo.mobile_phone && (
          <Group>
            {entityIcon.phone()}
            <Text>{userInfo.mobile_phone}</Text>
          </Group>
        )}
        {userInfo.email && (
          <Group>
            {entityIcon.email()}
            <Text>{userInfo.email}</Text>
          </Group>
        )}
      </React.Fragment>
    );
  };

  return (
    <div>
      {requestInfo && (
        <React.Fragment>
          <TextInput
            placeholder={`Search by ${
              method === "phone" ? "phone" : "email"
            }...`}
            size="lg"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {/* <DataFilters
            onChange={onFilterChange}
            values={filters}
            items={filterArr}
          /> */}
          {!managerInfo && (
            <SegmentedControl
              mt="sm"
              value={method}
              size="md"
              onChange={(e) => {
                setMethod(e);
                setSearchValue("");
                setDisplay(false);

                setTimeout(() => {
                  setDisplay(true);
                }, 100);
              }}
              data={[
                {
                  label: (
                    <Center>
                      {entityIcon.phone("1rem")}
                      <Box ml={10}>Phone</Box>
                    </Center>
                  ),
                  value: "phone",
                },
                {
                  label: (
                    <Center>
                      {entityIcon.email("1rem")}
                      <Box ml={10}>Email</Box>
                    </Center>
                  ),
                  value: "email",
                },
              ]}
              mb="sm"
            />
          )}
        </React.Fragment>
      )}
      <PaginatedList
        tableData={items}
        requestInfo={{
          url: managerInfo ? `/manager-retrieve-users/` : requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/users/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <Card
            component={Link}
            to={item.original.link_url}
            key={item.original.id}
            target="_blank"
          >
            {managerInfo ? (
              <ManagerUserCard userInfo={item.original} />
            ) : (
              <React.Fragment>
                <Text fw={700}>{item.original.title}</Text>
                {(item.original.last_seen ||
                  item.original.status === "inactive") && (
                  <Group>
                    {item.original.status === "inactive" && (
                      <Badge variant="light" size="xs" color="red">
                        inactive
                      </Badge>
                    )}
                    {item.original.last_seen && (
                      <Group>
                        <ThemeIcon {...iconProps}>
                          <IconSearch />
                        </ThemeIcon>
                        <Text c="dimmed">
                          last seen with {item.original.last_seen}
                        </Text>
                      </Group>
                    )}
                  </Group>
                )}
              </React.Fragment>
            )}
          </Card>
        )}
      />
    </div>
  );
}
