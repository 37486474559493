import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Grid,
  Card,
  Divider,
  Flex,
  Radio,
  Modal,
  Select,
  Space,
  Loader,
  Text,
  Skeleton,
} from "@mantine/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { EmailComposition } from "@components/Email";
import { TemplatePreview } from "@components/Email/EmailComposition";

export default function BulkCommunicationTemplate({
  bulkCommunicationId,
  editable = true,
  locationId,
  requiredTemplateItems,
  orgId,
}) {
  const queryClient = useQueryClient();
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTemplate();
  }, []);

  function fetchTemplate() {
    setLoading(true);
    axios
      .get(`/bulk-communicators/${bulkCommunicationId}/template/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
        setLoading(false);
      })
      .catch((err) => {
        setInfo(null);
        setLoading(false);
      });
  }

  function addTemplate(newTemplateInfo) {
    const req = {
      template_name: newTemplateInfo.alias,
      // data_model: newTemplateInfo.model,
      data_model: {},
      template_id: info.id,
    };

    axios
      .put(`/bulk-communicators/templates/${info.id}/`, req)
      .then(() => {
        toast.success("Saved!");
        // setSaving(false);
        fetchTemplate();
      })
      .catch((err) => {
        setSaving(false);
        toast.error(err);
      });
  }

  if (!info || loading) return null;

  const templateAlias = info.template_name;

  const constants = info.mapping_constants
    ? Object.keys(info.mapping_constants).reduce((acc, cur) => {
        acc[cur] = Object.keys(info.mapping_constants[cur]).reduce(
          (acc2, cur2) => {
            acc2.push({
              value: info.mapping_constants[cur][cur2],
              label: info.mapping_constants[cur][cur2],
            });
            return acc2;
          },
          []
        );
        return acc;
      }, {})
    : {};

  if (!templateAlias) {
    return (
      <EmailTemplateSelect
        templateId={info.id}
        bulkCommunicationId={bulkCommunicationId}
        onChange={(e) => addTemplate(e)}
      />
    );
  }

  return (
    <div>
      <EmailComposition
        editable={editable}
        bulkCommunicationId={bulkCommunicationId}
        locationId={locationId}
        orgId={orgId}
        fetchServerTemplate={fetchTemplate}
        requiredTemplateItems={info.required_template_items}
        serverTemplateId={info.id}
        savedTemplateName={info.template_name}
        savedDataModel={info.data_model}
        constants={constants}
        templateAlias={templateAlias}
      />
      {templateAlias && editable && (
        <>
          <Space mt="lg" />
          <ResetTemplateButton
            fetchData={() => {
              fetchTemplate();
              queryClient.invalidateQueries([
                `bulkCommunication${bulkCommunicationId}Alerts`,
              ]);
            }}
            bulkCommunicationId={bulkCommunicationId}
            templateId={info.id}
          />
        </>
      )}
    </div>
  );
}

const ResetTemplateButton = ({
  bulkCommunicationId,
  templateId,
  fetchData,
}) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  function onClick() {
    const req = {
      bulk_communicator_id: bulkCommunicationId,
      template_id: templateId,
    };

    axios
      .post(`/bulk-communicators/templates/${templateId}/reset/`, req)
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        size="xs"
        color="grey"
        variant="subtle"
      >
        Change template
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <Text size="lg" mb="sm">
          Changing templates will <b>clear all saved text and images.</b>
        </Text>
        <Text size="lg">Are you sure you want to do this?</Text>
        <Divider mt="lg" mb="lg" />
        <Button
          color="red"
          fullWidth
          loading={loading}
          onClick={onClick}
          mb="sm"
          mt="sm"
        >
          I'm sure
        </Button>
        <Flex justify="center">
          <Button size="xs" color="grey" variant="subtle" onClick={onClose}>
            Get me out of here
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

const EmailTemplateSelect = ({ onChange, templateId, bulkCommunicationId }) => {
  const [templates, setTemplates] = useState([]);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    if (!value) return;
    fetchPreview();
  }, [value]);

  function fetchTemplates() {
    const req = {
      bulk_communicator_id: bulkCommunicationId,
      context: "postmark-templates",
    };

    axios
      .post(`/bulk-communicators/${bulkCommunicationId}/data-grabber/`, req)
      .then(({ data }) => {
        setTemplates(
          data.response.map((template) => ({
            label: template.Name,
            value: `${template.Alias}`,
          }))
        );
      })
      .catch((err) => {
        setTemplates([]);
      });
  }

  function fetchPreview() {
    const req = {
      postmark_template_alias: value,
      bulk_communicator_template_id: templateId,
    };

    setLoading(true);
    setPreviewData(null);

    axios
      .post(`/bulk-communicators/templates/preview-template/`, req)
      .then(({ data }) => {
        setLoading(false);
        setPreviewData(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
        setPreviewData(null);
      });
  }

  return (
    <Card>
      <Grid>
        <Grid.Col
          span={{ base: 12, md: 6 }}
          id="fields"
          style={{ minHeight: "600px" }}
        >
          <Flex direction="column" style={{ height: "100%" }}>
            <Flex gap="xs" align="center">
              <Text fw={600} size="xl">
                Preview templates
              </Text>
              {loading && <Loader size="xs" />}
            </Flex>
            <Text size="lg">
              Get started by selecting a template to preview. Once you're
              satisfied with your selection, click Next below.
            </Text>
            <Divider mt="lg" mb="lg" />
            <Box style={{ flexGrow: 1 }}>
              {templates.map((m) => (
                <Radio
                  key={m.value}
                  checked={value === m.value}
                  label={m.label}
                  onChange={() => setValue(m.value)}
                  name="template"
                  size="lg"
                  mb="sm"
                />
              ))}
            </Box>
            {value && (
              <>
                <Divider mt="lg" mb="lg" />
                <Button
                  size="lg"
                  disabled={!value}
                  onClick={() =>
                    onChange({
                      alias: value,
                      model: previewData.SuggestedTemplateModel,
                    })
                  }
                >
                  Next
                </Button>
              </>
            )}
          </Flex>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          {error && (
            <Text c="red" size="sm">
              {error}
            </Text>
          )}
          {previewData ? (
            <TemplatePreview html={previewData.HtmlBody.RenderedContent} />
          ) : (
            <Skeleton visible>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  minHeight: "600px",
                  background: "var(--mantine-color-grey-5)",
                }}
              ></div>
            </Skeleton>
          )}
        </Grid.Col>
      </Grid>
    </Card>
  );

  return (
    <Select
      onChange={(e) => onChange(e)}
      value={value}
      data={templates}
      placeholder="Select one..."
      label="Select a template"
      searchable
    />
  );
};
