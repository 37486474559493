import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Modal,
  SegmentedControl,
  FileInput,
  Divider,
  Button,
  Text,
} from "@mantine/core";
import toast from "react-hot-toast";

import { varietyButtons } from "@components/Location/LocationList";

export default function BulkLocationDownload({ downloadReqData }) {
  const [isOpen, setOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [locationVariety, setLocationVariety] = useState("all");

  function onClose() {
    setOpen(false);
    setDownloading(false);
    setUploading(false);
    setValue(null);
  }

  function onDownloadClick() {
    const req = {
      ...downloadReqData,
      scope: locationVariety,
    };

    setDownloading(true);

    axios
      .post(`/download-association-csv/`, req)
      .then(({ data }) => {
        setDownloading(false);
        if (data.response.length && data.response[0].url) {
          const link = document.createElement("a");
          link.href = data.response[0].url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((err) => {
        setDownloading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Button
        size="xs"
        variant="subtle"
        color="gray"
        onClick={() => setOpen(true)}
      >
        Download Locations CSV
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <Box mb="lg">
          <Text size="xs" mb="xs" fw={600}>
            Location Variety
          </Text>
          <SegmentedControl
            value={locationVariety}
            onChange={(e) => setLocationVariety(e)}
            data={[
              { label: "All", value: "all" },
              { label: "Station", value: "stations" },
              { label: "Dealer", value: "dealers" },
              { label: "Retail", value: "retail" },
            ]}
          />
        </Box>
        <Button loading={downloading} fullWidth onClick={onDownloadClick}>
          Download CSV
        </Button>
      </Modal>
    </div>
  );
}
