import React, { useState } from "react";
import { Button, Divider } from "@mantine/core";

import { CurationSearch, CurationSongSelect } from "./";
import axios from "axios";
import toast from "react-hot-toast";

export default function TransferSongs({ items = [], onSuccess, artistId }) {
  const [loading, setLoading] = useState(false);
  const [newArtistId, setNewArtistId] = useState(null);
  const [selected, setSelected] = useState([]);

  function onClose() {
    setNewArtistId(null);
    setLoading(false);
    setSelected([]);
  }

  function onSubmit() {
    const req = {
      from_artist_id: artistId,
      to_artist_id: newArtistId,
      song_ids: selected,
    };

    setLoading(true);

    axios
      .post(`/curation-associate-songs-to-artist/`, req)
      .then(() => {
        toast.success("Transferred!");
        setLoading(false);
        onClose();
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <CurationSongSelect
        items={items}
        title="Select songs to transfer"
        selected={selected}
        onChange={(e) => setSelected(e)}
      />
      <Divider mt="lg" mb="lg" />
      <CurationSearch
        artistId={artistId}
        text={`Choose an artist to transfer these songs to`}
        isArtist
        onChange={(e) => {
          setNewArtistId(e);
        }}
        value={newArtistId}
      />
      <Divider mt="lg" mb="lg" />
      <Button
        fullWidth
        loading={loading}
        onClick={onSubmit}
        disabled={!newArtistId || selected.length === 0}
      >
        Transfer {selected.length} song{selected.length === 1 ? "" : "s"}
      </Button>
    </div>
  );
}
