import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Box, Title, Text, Grid } from "@mantine/core";

import { BarChart } from "@components/DataViz";

const HourReport = ({ campaignId, effortId }) => {
  const [reportData, setReportData] = useState(null);
  const [activeFilter, setActiveFilter] = useState("interactions");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [effortId, campaignId, activeFilter]);

  function fetchData() {
    const req = {
      report: 4,
      variety: activeFilter,
    };

    if (effortId) req.campaign_effort_id = effortId;
    if (campaignId) req.campaign_id = campaignId;

    setLoading(true);
    axios
      .post(`/retrieve-report-data/`, req)
      .then(({ data }) => {
        setReportData(data.response[0]);
        setLoading(false);
      })
      .catch((err) => {
        setReportData(null);
        setLoading(false);
      });
  }

  if (!reportData) return null;

  const boxes = [
    {
      title: "Interactions",
      color: "var(--mantine-color-blue-5)",
      data: Object.keys(reportData.interactions).map((m) => ({
        x: reportData.interactions[m].label,
        y: reportData.interactions[m].value,
      })),
    },
    {
      title: "Keywords",
      color: "var(--mantine-color-green-5)",
      data: Object.keys(reportData.keywords).map((m) => ({
        x: reportData.keywords[m].label,
        y: reportData.keywords[m].value,
      })),
    },
    {
      title: "Check-Ins",
      color: "var(--mantine-color-orange-5)",
      data: Object.keys(reportData.check_ins).map((m) => ({
        x: reportData.check_ins[m].label,
        y: reportData.check_ins[m].value,
      })),
    },
  ];

  return (
    <div>
      {/* <Group mb="md" mt="lg">
        <Title order={4}>By Hour</Title>
        {loading && <Loader size="xs" />}
      </Group> */}
      {/* <Button.Group style={{ marginBottom: "1em" }}>
        {varietyButtons
          // .filter((f) => (effortId ? f.text !== "Check-In" : true))
          .map((b) => (
            <Button
              key={b.key}
              variant={b.value !== activeFilter ? "light" : "filled"}
              onClick={() => setActiveFilter(b.value)}
              size="xs"
            >
              {b.text}
            </Button>
          ))}
      </Button.Group> */}
      <Card mt="sm">
        <BarChart
          title="Snapshot"
          categories={boxes[0].data.map((m) => m.x)}
          chartType="column"
          legendEnabled
          data={boxes.map((b) => ({
            name: b.title,
            data: b.data.map((bb) => bb.y),
            color: b.color,
          }))}
        />
      </Card>
      <Grid mt="sm">
        {boxes.map((b, i) => {
          const noData =
            b.data.reduce((acc, cur) => (acc += cur.y), 0) === 0 ? true : false;
          return (
            <Grid.Col span={{ base: 12, md: 4 }} key={i}>
              {noData ? (
                <Card>
                  <Title order={4} mb="sm">
                    {b.title}
                  </Title>
                  <Text>No data is available right now.</Text>
                </Card>
              ) : (
                <Card>
                  <BarChart
                    title={b.title}
                    data={{
                      name: b.title,
                      color: b.color,
                      data: b.data.map((m) => m.y),
                    }}
                    categories={b.data.map((d) => d.x)}
                    chartType="column"
                  />
                </Card>
              )}
            </Grid.Col>
          );
        })}
      </Grid>
    </div>
  );
};

export default HourReport;
