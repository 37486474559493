import React from "react";
import { Button } from "@mantine/core";

import ItemSlider from "@components/Microsite/ItemSlider";
import generateStaticMap from "@util/generateStaticMap";

export default function OrgGalleryLocationSelect({
  onLocationSelect,
  locations,
}) {
  return (
    <ItemSlider
      data={locations.map((loc) => ({
        id: loc.id,
        title: loc.name,
        category: `${loc.city}, ${loc.state_province}${
          loc.distance ? ` - ${loc.distance} miles` : ""
        }`,
        image: generateStaticMap(
          {
            latitude: loc.latitude,
            longitude: loc.longitude,
          },
          {
            width: 500,
            height: 500,
          },
          14
        ),
        children: (
          <div>
            <Button
              style={{ marginTop: "1.5em" }}
              onClick={() => onLocationSelect(loc)}
            >
              Select
            </Button>
          </div>
        ),
      }))}
    />
  );
}
