import React from "react";
import { IconFlame } from "@tabler/icons-react";

export default function SongBurnIcon({ burn = 0, size = 64 }) {
  const scaledBurn = burn * 20;
  const opacity = scaledBurn * 0.4 * 0.01;
  const color = getColor(burn);

  return (
    <IconFlame
      size={size}
      title="Burn"
      style={{
        opacity: opacity >= 1 ? 1 : opacity < 0.15 ? 0.1 : opacity,
        fill: color,
        fillOpacity: 0.65,
        strokeWidth: 1,
        color: color,
      }}
    />
  );
}

function getColor(val) {
  if (val < 5) {
    return "gray";
  } else if (val <= 10) {
    return "#ffce00";
  } else if (val <= 15) {
    return "#ff9a00";
  } else if (val <= 20) {
    return "#ff5a00";
  } else {
    return "#ff0000";
  }
}
