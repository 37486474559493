import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Badge,
  Box,
  Card,
  Center,
  Flex,
  Grid,
  Group,
  Modal,
  SegmentedControl,
  Text,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core";
import { IconLock } from "@tabler/icons-react";

import { UserInteractions } from "./";
import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

const scopes = [
  { value: "All", label: "All" },
  {
    value: "Location",
    label: (
      <Center>
        {entityIcon.location()}
        <Box ml={10}>Location</Box>
      </Center>
    ),
  },
  {
    value: "Organization",
    label: (
      <Center>
        {entityIcon.organization()}
        <Box ml={10}>Organization</Box>
      </Center>
    ),
  },
];

const iconProps = {
  size: "xs",
  color: "gray",
  variant: "light",
  radius: "xl",
};

export default function UserJoinList({ userId }) {
  const [items, setItems] = useState([]);
  const [scope, setScope] = useState("All");

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/users/${userId}/`)
      .then(({ data }) => {
        const res = data.response[0];
        setItems(
          [
            ...res.user_locations.map((l) => ({
              ...l,
              location: true,
            })),
            ...res.user_organizations.map((m) => ({
              ...m,
              organization: true,
            })),
          ].sort(
            (a, b) => new Date(b.last_interacted) - new Date(a.last_interacted)
          )
        );
      })
      .catch((err) => {
        setItems([]);
      });
  }

  const filteredItems = items.filter((f) =>
    scope === "All" ? true : scope === "Location" ? f.location : f.organization
  );

  return (
    <React.Fragment>
      <SegmentedControl
        value={scope}
        onChange={(e) => setScope(e)}
        data={scopes}
        mb="sm"
        mt="sm"
      />
      <Grid>
        {filteredItems.map((item) => (
          <Grid.Col span={{ base: 6 }} key={item.id}>
            <Item
              data={item}
              id={item.id}
              location={item.location}
              organization={item.organization}
            />
          </Grid.Col>
        ))}
      </Grid>
    </React.Fragment>
  );
}

const Item = ({ id, data, location, organization }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <Card p="xs">
      <Modal opened={isOpen} onClose={onClose} title={`Interactions`}>
        <UserInteractions joinId={id} />
      </Modal>
      <Flex gap="xs" align="center">
        <ThemeIcon {...iconProps} c="dimmed">
          {data.location
            ? entityIcon.location(12)
            : entityIcon.organization(12)}
        </ThemeIcon>
        <Text
          fw={600}
          component={Link}
          to={`/user-${location ? "locations" : "organizations"}/${id}`}
        >
          {data.location_name || data.organization_name}
        </Text>
        {/* {data.locked && (
          <Badge
            leftSection={
              <IconLock
                size={12}
                style={{ position: "relative", top: "2px" }}
              />
            }
            color="red"
            size="xs"
          >
            Locked
          </Badge>
        )} */}
      </Flex>
      {data.full_name && (
        <Group>
          <ThemeIcon {...iconProps}>{entityIcon.user()}</ThemeIcon>
          <Text c="dimmed">{data.full_name}</Text>
        </Group>
      )}
      {data.email && (
        <Group>
          <ThemeIcon {...iconProps}>{entityIcon.email()}</ThemeIcon>
          <Text c="dimmed">{data.email}</Text>
        </Group>
      )}
      {data.mobile_phone && (
        <Group>
          <ThemeIcon {...iconProps}>{entityIcon.phone()}</ThemeIcon>
          <Text c="dimmed">{data.mobile_phone}</Text>
        </Group>
      )}
      {data.last_interacted && (
        <Group>
          <ThemeIcon {...iconProps}>{entityIcon.calendar()}</ThemeIcon>
          <Text c="dimmed">
            {new Date(data.last_interacted).toLocaleString()}
          </Text>
        </Group>
      )}
    </Card>
  );
};
