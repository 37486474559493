import React, { useState, useEffect, useRef } from "react";
import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Checkbox,
  Flex,
  Group,
  Loader,
  Menu,
  Modal,
  Pagination,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import {
  IconTrash,
  IconDots,
  IconLink,
  IconExternalLink,
  IconKeyboard,
} from "@tabler/icons-react";
import axios from "axios";

import entityColor from "@util/entityColor";
import { TagManagement } from "@components/Tag";

export default function AssetList({
  assets,
  campaignId,
  contestId,
  effortId,
  isIndex,
  library = false,
  limit,
  locationId,
  lookAndFeelTemplateId,
  lootItemId,
  onExternalUrlUpdate,
  onRefresh,
  onRemoveSuccess,
  onRenameSuccess,
  organizationId,
  refresh,
  shouldFetch,
  showExternalUrl = false,
  variety = null,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(-1);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [filtered, setFiltered] = useState(false);
  const adminInfo = useSelector((state) => state.admin);
  const managerInfo = useSelector((state) => state.manager);

  useEffect(() => {
    if (!shouldFetch) {
      setItems(assets);
      setLoading(false);
    }
  }, [shouldFetch, JSON.stringify(assets)]);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const table = useReactTable({
    manualPagination: true,
    pageCount: pageCount,
    data: items,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 50,
      },
    },
  });

  useEffect(() => {
    fetchData();
  }, [
    searchValue,
    JSON.stringify(table.getState().pagination),
    variety,
    refresh,
    library,
    filtered,
  ]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    if (!shouldFetch) return;

    setLoading(true);

    const reqData = {
      pagination: limit ? false : true,
      page_size: table.getState().pagination.pageSize,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
    };

    const req = !limit
      ? { ...reqData, filtered: filtered }
      : { filtered: filtered };

    if (!searchValue == "") req.value = searchValue;
    if (locationId !== null) req.location_id = locationId;
    if (organizationId !== null) req.organization_id = organizationId;
    if (contestId !== null) req.contest_id = contestId;
    if (campaignId !== null) req.campaign_id = campaignId;
    if (effortId !== null) req.campaign_effort_id = effortId;
    if (lootItemId) req.loot_item_id = lootItemId;
    if (variety !== null) req.variety = variety;
    if (lookAndFeelTemplateId !== null)
      req.look_and_feel_template_id = lookAndFeelTemplateId;

    const cancel = cancelRef.current;
    if (cancel) cancel();

    axios
      .post(`/retrieve-${library ? "library-" : ""}assets/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLoading(false);
        setItems(data.response[0].data);
        setTotal(data.response[0].total);
        setPageCount(data.response[0].page_count);
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
        setTotal(0);
        setPageCount(1);
      });
  }

  // if (!items.length) {
  //   return <Text>Nothing here yet</Text>;
  // }

  return (
    <React.Fragment>
      {library && (locationId || organizationId) && (
        <Checkbox
          mt="lg"
          checked={filtered}
          label="Only show my assets"
          onClick={() => setFiltered(!filtered)}
        />
      )}
      {library && (
        <Group mb="lg" mt="lg">
          <TextInput
            placeholder="Search for an asset..."
            size="sm"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Group>
      )}
      <Table striped highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th></Table.Th>
            <Table.Th>Name</Table.Th>
            {library && (
              <React.Fragment>
                <Table.Th>Variety</Table.Th>
                <Table.Th>Uploaded By</Table.Th>
              </React.Fragment>
            )}
            <Table.Th>Type</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {items.length === 0 && (
            <Table.Tr>
              <Table.Td></Table.Td>
              <Table.Td>No items</Table.Td>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
              {library && (
                <React.Fragment>
                  <Table.Td></Table.Td>
                  <Table.Td></Table.Td>
                </React.Fragment>
              )}
            </Table.Tr>
          )}
          {items.map((item) => (
            <AssetItem
              assetId={item.asset_id}
              contentType={item.content_type}
              externalUrl={item.external_link_url}
              fetchData={fetchData}
              filename={item.original_filename}
              id={item.id}
              isIndex={isIndex}
              key={item.id}
              library={library}
              location={item.location_id ? true : false}
              onExternalUrlUpdate={(newUrl) =>
                onExternalUrlUpdate(item.id, newUrl)
              }
              onRefresh={() => onRefresh()}
              onRemoveSuccess={() => onRemoveSuccess(item.id)}
              onRenameSuccess={(newName) => onRenameSuccess(item.id, newName)}
              organization={item.organization_id ? true : false}
              assetData={item}
              showExternalUrl={showExternalUrl}
              url={item.filename_url}
              variety={item.variety_formatted}
              editable={
                adminInfo
                  ? true
                  : managerInfo && item.location_id === managerInfo.location_id
              }
            />
          ))}
        </Table.Tbody>
      </Table>
      {!limit && (
        <Group mt="lg">
          <Pagination
            total={pageCount}
            onChange={(e) => table.setPageIndex(e - 1)}
            size="lg"
          />
          <Select
            size="sm"
            style={{ width: "110px" }}
            value={`${table.getState().pagination.pageSize}`}
            onChange={(e) => table.setPageSize(e)}
            data={[5, 10, 20, 30, 40, 50].map((pageSize, i) => ({
              key: i,
              label: `${pageSize} items`,
              value: `${pageSize}`,
              placeholder: "Select a pagesize",
            }))}
          />
          <Text>
            {total} total item{total === 1 ? "" : "s"}
          </Text>
          {loading && <Loader size="xs" variant="dots" />}
        </Group>
      )}
    </React.Fragment>
  );
}

const AssetItem = ({
  assetData,
  assetId,
  contentType,
  externalUrl = "",
  fetchData,
  filename,
  id,
  isIndex,
  library,
  location,
  onExternalUrlUpdate,
  onRefresh,
  onRemoveSuccess,
  onRenameSuccess,
  organization,
  showActions = true,
  showExternalUrl = false,
  url,
  variety,
  editable,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [urlOpen, setUrlOpen] = useState(false);
  const [value, setValue] = useState("");
  const [urlValue, setUrlValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [urlLoading, setUrlLoading] = useState(false);

  const adminInfo = useSelector((state) => state.admin);

  useEffect(() => {
    setValue(filename);
  }, [filename]);

  useEffect(() => {
    setUrlValue(externalUrl);
  }, [externalUrl]);

  function truncateString(str, num) {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  function onRemoveClick() {
    const url = library
      ? `/library-assets/${id}/remove/`
      : `/assets/${id}/remove/`;

    axios
      .post(url)
      .then(() => {
        fetchData();
        onRefresh();
        onRemoveSuccess();
        toast.success("Removed!");
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onSaveClick() {
    const req = {
      original_filename: value,
      external_link_url: urlValue,
    };

    setLoading(true);

    const url = library ? `/assets/${assetId}/` : `/assets/${id}/`;

    axios
      .put(url, req)
      .then(() => {
        toast.success("Updated!");
        setOpen(false);
        setLoading(false);
        fetchData();
        onRefresh();
        onRenameSuccess(value);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onUrlSaveClick() {
    const req = {
      external_link_url: urlValue,
      original_filename: value,
    };

    setUrlLoading(true);

    axios
      .put(`/assets/${library ? assetId : id}/`, req)
      .then(() => {
        toast.success("Updated!");
        setUrlOpen(false);
        setUrlLoading(false);
        onRefresh();
        fetchData();
        onExternalUrlUpdate(value);
      })
      .catch((err) => {
        toast.error(err);
        setUrlLoading(false);
      });
  }

  const isLibraryAdminAsset = !organization && !location && library;

  return (
    <React.Fragment>
      <Modal
        size={library ? 1200 : 400}
        padding="20px"
        opened={isOpen}
        onClose={() => setOpen(false)}
        title={library ? "Filename & Tag Management" : "Rename Asset"}
      >
        <TextInput
          label="Filename"
          value={value}
          maxLength={100}
          onChange={(e) => setValue(e.target.value)}
          mb="sm"
        />
        {library && (
          <div style={{ minHeight: 400 }}>
            <TagManagement libraryAssetId={id} />
          </div>
        )}
        <Button
          fullWidth
          mt="sm"
          disabled={!value}
          onClick={onSaveClick}
          loading={loading}
        >
          Save
        </Button>
      </Modal>
      <Modal
        opened={urlOpen}
        onClose={() => setUrlOpen(false)}
        title="Edit URL"
      >
        <TextInput
          value={urlValue}
          onChange={(e) => setUrlValue(e.target.value)}
        />
        <Button
          fullWidth
          mt="sm"
          disabled={
            url && urlValue === "" ? false : !urlValue || !validateUrl(urlValue)
          }
          onClick={onUrlSaveClick}
          loading={urlLoading}
        >
          Save
        </Button>
      </Modal>
      <Table.Tr>
        <Table.Td style={{ width: 150 }}>
          {[
            "image/jpg",
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/webp",
          ].includes(contentType) && (
            <a href={url} target="_blank">
              <img
                src={url}
                style={{ width: "100px", height: "100px", objectFit: "cover" }}
              />
            </a>
          )}
        </Table.Td>
        <Table.Td>
          <Flex align="center" gap="xs">
            <Anchor href={url} target="_blank" weight={600}>
              <Tooltip label={filename}>
                <Text size="sm">{truncateString(filename, 40)}</Text>
              </Tooltip>
            </Anchor>
            {showExternalUrl && externalUrl && (
              <IconLink title="External URL" size={18} />
            )}
          </Flex>
        </Table.Td>
        {library && (
          <React.Fragment>
            <Table.Td style={{ width: 200 }}>
              <Badge
                variant="light"
                size="lg"
                color={
                  variety
                    ? variety == "contest"
                      ? entityColor.contest
                      : variety === "effort"
                      ? entityColor.effort
                      : variety === "trouble ticket"
                      ? entityColor.ticket
                      : variety === "general"
                      ? "indigo"
                      : entityColor.campaign
                    : "gray"
                }
              >
                {variety ? variety : "none"}
              </Badge>
            </Table.Td>
          </React.Fragment>
        )}
        {library && (
          <Table.Td style={{ width: 200 }}>
            <Text size="sm">
              {organization ? "Organization" : location ? "Location" : "Admin"}
            </Text>
          </Table.Td>
        )}
        <Table.Td>{contentType}</Table.Td>
        <Table.Td>
          <Menu>
            <Menu.Target>
              <ActionIcon variant="subtle" color="gray">
                <IconDots />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                component="a"
                href={url}
                target="_blank"
                icon={<IconExternalLink />}
              >
                Open
              </Menu.Item>
              {(adminInfo || !isLibraryAdminAsset) && (
                <>
                  {(adminInfo && location) || (adminInfo && organization)
                    ? false
                    : showActions && (
                        <Menu.Item
                          component="button"
                          onClick={() => setOpen(true)}
                          icon={<IconKeyboard />}
                        >
                          {library ? "Edit Tags" : "Rename"}
                        </Menu.Item>
                      )}
                  {showExternalUrl && (
                    <Menu.Item
                      component="button"
                      onClick={() => setUrlOpen(true)}
                      icon={<IconLink />}
                    >
                      External URL
                    </Menu.Item>
                  )}
                  {showActions && (
                    <React.Fragment>
                      <Menu.Divider />
                      <Menu.Item
                        component="button"
                        onClick={onRemoveClick}
                        icon={<IconTrash />}
                      >
                        Remove
                      </Menu.Item>
                    </React.Fragment>
                  )}
                </>
              )}
            </Menu.Dropdown>
          </Menu>
        </Table.Td>
      </Table.Tr>
    </React.Fragment>
  );
};

function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}
