import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Card, Flex, Text, Group, Badge, Button } from "@mantine/core";
import { IconMapPin } from "@tabler/icons-react";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";
import { ErrorView, PageTitle } from "@components/shared";

const reqColors = {
  POST: "indigo",
  GET: "green",
  PUT: "yellow",
  DELETE: "red",
};

export default function ApiRequestDetail() {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [showHeaders, setShowHeaders] = useState(false);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/api-requests/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  const PrettyJson = () => {
    if (!info.request_json) return;

    return (
      <pre style={{ color: "darkslategrey" }}>
        {JSON.stringify(info.request_json, null, 2)}
      </pre>
    );
  };

  const PrettyHeaders = () => {
    if (!info.request_json) return;

    return (
      <pre style={{ color: "darkslategrey" }}>
        {JSON.stringify(info.headers, null, 2)}
      </pre>
    );
  };

  if (!info) return null;
  return (
    <div>
      <PageTitle
        title={""}
        subtitle="Api Request"
        icon={entityIcon.request()}
        iconColor={entityColor.request}
        style={{ marginBottom: "5px" }}
      />
      <Card mt="md">
        <Flex direction="column">
          <Text fw={700}>Status: {info.response_status}</Text>
          <Text>
            <IconMapPin size={16} />{" "}
            {info.ip_address ? info.ip_address : "Unknown"}
          </Text>
          <Text>User Agent: {info.user_agent}</Text>
          <Text c="dimmed">
            {new Date(info.created_at).toLocaleDateString()}{" "}
            {new Date(info.created_at).toLocaleTimeString()}
          </Text>
        </Flex>
      </Card>
      <Group
        align="center"
        bg={reqColors[info.request_method]}
        p="md"
        style={{ borderRadius: "7px" }}
        mb="lg"
        mt="lg"
      >
        <Text size={20} lh={1} fw={650} c="white">
          {info.request_method}
        </Text>
        <Text size={20} lh={1} c="white">
          {info.path}
        </Text>
      </Group>
      <Card bg="rgb(235,235,235)" mb="sm" pl="xl">
        <Button
          style={{ width: "fit-content" }}
          size="xs"
          p={7}
          variant="subtle"
          color="darkslategrey"
          onClick={() => setShowHeaders(!showHeaders)}
        >
          {!showHeaders ? "show headers" : "hide headers"}
        </Button>
        {showHeaders && (
          <div>
            <Text fw={700} size="md" mt="xl" color="darkslategrey">
              Request Headers:
            </Text>
            <PrettyHeaders />
          </div>
        )}
        <Text fw={700} size="md" mt="xl" color="darkslategrey">
          Request JSON:
        </Text>
        {info.request_json && <PrettyJson />}
        {!info.request_json && (
          <Text m={10} c="dimmed">
            N/A
          </Text>
        )}
      </Card>
    </div>
  );
}
