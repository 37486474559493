import React, { useState } from "react";
import axios from "axios";
import { Button, Divider, Group, Modal, Text, Textarea } from "@mantine/core";
import toast from "react-hot-toast";
import { IconPlusMinus } from "@tabler/icons-react";

const operators = [
  { text: "add", value: "add" },
  { text: "subtract", value: "subtract" },
];

const increments = [1, 5, 10, 20, 50, 100];

export default function PointAssignment({ fetchData, reqData }) {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [operator, setOperator] = useState("add");
  const [comment, setComment] = useState("");

  function onSubmit() {
    // setLoading(true);

    const req = {
      value: operator === "subtract" ? -Math.abs(value) : value,
      comment,
      ...reqData,
    };

    axios
      .post(`/points-ledger/`, req)
      .then(() => {
        toast.success("Success!");
        onClose();
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onClose() {
    setOpen(false);
    setOperator("add");
    setComment("");
    setValue(0);
    setLoading(false);
  }

  return (
    <div>
      <Button
        leftSection={<IconPlusMinus />}
        variant="white"
        size="xs"
        radius="lg"
        onClick={() => setOpen(true)}
      >
        points
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <Text mb="md" fw={600}>
          I want to
        </Text>
        <Group grow>
          {operators.map((m, i) => (
            <Button
              key={i}
              onClick={() => setOperator(m.value)}
              variant={operator === m.value ? `light` : "subtle"}
              size="xs"
              color={m.value === "subtract" ? "red" : "blue"}
            >
              {m.text}
            </Button>
          ))}
        </Group>
        <Text mt="md" mb="md" fw={600}>
          points in the amount of
        </Text>
        <Group grow>
          {increments.map((m, i) => (
            <Button
              key={i}
              onClick={() => setValue(m)}
              variant={value === m ? `light` : "subtle"}
              size="xs"
            >
              {m}
            </Button>
          ))}
        </Group>
        <Text mt="md" mb="md" fw={600}>
          Why are you {operator === "add" ? "adding" : "subtracting"}
          {value ? ` ${value}` : ""} point{value === 1 ? "" : "s"}?
        </Text>
        <Textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Divider mt="lg" mb="lg" />
        <Button
          disabled={!operator || !value || !comment}
          fullWidth
          loading={loading}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </Modal>
    </div>
  );
}
