import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Box, Button, Flex, Title, LoadingOverlay } from "@mantine/core";

import { BasicForm } from "@components/shared";
import { PasswordForm } from "@components/Auth";
import { PhoneCheck } from "./UserAuth";
import { defaultEmailStreams } from "@components/Email/helpers";

export default function UserSignup({
  email = "",
  firstName = "",
  lastName = "",
  locationId,
  number,
  onSuccess,
  userLocationId,
  zip = "",
}) {
  const [loading, setLoading] = useState(false);
  const [demographics, setDemographics] = useState(null);
  const [step, setStep] = useState("demographics");
  const [tempPhone, setTempPhone] = useState("");

  if (!number && !tempPhone) {
    return (
      <PhoneCheck
        locationId={locationId}
        setUserScenario={(scenario) => {
          setTempPhone(scenario.number);
        }}
      />
    );
  }

  const fieldValues = demographics
    ? {
        first_name: demographics.first_name,
        last_name: demographics.last_name,
        email: demographics.email,
        zip: demographics.zip,
      }
    : {
        first_name: firstName,
        last_name: lastName,
        email: email,
        zip: zip,
      };

  function onSubmit(formData) {
    setLoading(true);

    const req = {
      ...formData,
      ...demographics,
      location_id: locationId,
      mobile_phone: tempPhone ? tempPhone : number,
      email_streams: {
        marketing: demographics.marketing,
        news_and_events: demographics.news_and_events,
        offers: demographics.offers,
      },
    };

    delete req.marketing;
    delete req.news_and_events;
    delete req.offers;

    if (userLocationId) req.user_location_id = userLocationId;

    axios
      .post(`/members/signup/`, { ...req })
      .then(({ data }) => {
        onSuccess(data.response[0]);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "first_name",
      label: "First Name",
      initialValue: fieldValues.first_name,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required"),
    },
    {
      name: "last_name",
      label: "Last Name",
      initialValue: fieldValues.last_name,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required"),
    },
    {
      name: "email",
      label: "Email",
      initialValue: fieldValues.email,
      required: true,
      schema: () =>
        Yup.string()
          .max(100, "Too long!")
          .email("Enter a valid email address")
          .required("Required"),
    },
    {
      name: "zip",
      label: "Zip",
      initialValue: fieldValues.zip,
      required: true,
      schema: () =>
        Yup.string()
          .matches(
            /^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/,
            "Not a valid zip"
          )
          .required("Required"),
    },
    ...defaultEmailStreams.map((field) => ({
      ...field,
      checkbox: true,
      initialValue: true,
      required: false,
      schema: () => null,
    })),
  ];

  return (
    <Box>
      <LoadingOverlay visible={loading} />
      <Title order={2} mb="lg" align="center">
        {step === "pw" ? "Create password" : "Fill out this form"}
      </Title>
      {step === "pw" ? (
        <React.Fragment>
          <PasswordForm fullWidth returnEarly={(e) => onSubmit(e)} />
          <Flex justify="center">
            <Button
              variant="subtle"
              mt="sm"
              onClick={() => setStep("demographics")}
            >
              back
            </Button>
          </Flex>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <BasicForm
            onSubmit={(e) => {
              setDemographics(e);
              setStep("pw");
            }}
            fields={fields}
            buttonProps={{
              fullWidth: true,
            }}
          />
        </React.Fragment>
      )}
    </Box>
  );
}
