import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  SegmentedControl,
  Card,
  Flex,
  Group,
  Text,
  TextInput,
} from "@mantine/core";

import { ManagementLayout, PaginatedList } from "@components/shared";
import { SongQueryForm } from "./";
import { songQueryVarieties } from "./helpers";
import entityIcon from "@util/entityIcon";

const varietyButtons = [
  {
    text: "Rotation Category",
    value: `${songQueryVarieties.rotation_category}`,
  },
  { text: "Sound Code", value: `${songQueryVarieties.sound_code}` },
];

const statusButtons = [
  { text: "Active", value: "1" },
  { text: "Inactive", value: "2" },
];

export default function SongQueryList({
  items = null,
  locationId,
  requestInfo = {
    url: `/retrieve-song-queries/`,
    data: {},
  },
}) {
  const [searchValue, setSearchValue] = useState("");
  // future proofing
  const [refresh, setRefresh] = useState(false);
  const [variety, setVariety] = useState(
    `${songQueryVarieties.rotation_category}`
  );
  const [status, setStatus] = useState("1");

  const reqData = {
    ...requestInfo.data,
    location_id: locationId,
    pagination: true,
    refresh,
    search_value: searchValue,
    status,
    variety,
  };

  const selectedCategory = varietyButtons.find((f) => f.value === variety)
    ? varietyButtons.find((f) => f.value === variety)
    : { text: "Name" };

  return (
    <ManagementLayout
      createComponent={
        <SongQueryForm
          locationId={locationId}
          variety={variety}
          varietyName={selectedCategory.text}
          onSuccess={() => setRefresh(!refresh)}
        />
      }
      dataComponent={
        <>
          <Flex gap="xs">
            <SegmentedControl
              value={variety}
              data={varietyButtons.map((b) => ({
                label: b.text,
                value: b.value,
              }))}
              onChange={(e) => setVariety(e)}
              mb="sm"
            />
            <SegmentedControl
              value={status}
              data={statusButtons.map((b) => ({
                label: b.text,
                value: b.value,
              }))}
              onChange={(e) => setStatus(e)}
              mb="sm"
            />
          </Flex>
          {requestInfo && (
            <React.Fragment>
              <TextInput
                placeholder="Search..."
                size="lg"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </React.Fragment>
          )}
          <PaginatedList
            tableData={items}
            requestInfo={{
              url: requestInfo.url,
              data: reqData,
            }}
            responseMapping={(r) =>
              r.map((m) => ({
                ...m,
                link_url: `/song-categories/${m.id}`,
              }))
            }
            displayFormatter={(item) => (
              <Card
                component={Link}
                to={item.original.link_url}
                key={item.original.id}
              >
                <Group position="apart">
                  <Text fw={700}>{item.original.name}</Text>
                </Group>
                <Flex spacing="xs" gap="xs">
                  {entityIcon.song(16)}
                  <Text size="xs" c="dimmed">
                    {item.original.song_count} songs
                  </Text>
                </Flex>
              </Card>
            )}
          />
        </>
      }
    />
  );
}
