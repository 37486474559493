import React, { useRef, useState, useEffect } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import mapPin from "@assets/map-pin.svg";
import { IconStar } from "@tabler/icons-react";

export default function HeatMap({
  data,
  defaultCenter = {
    lat: 33.749937,
    lng: -84.390744,
  },
  defaultZoom = 8,
  pins = [],
}) {
  if (!data) return;
  const [boundsFitted, setBoundsFitted] = useState(false);
  const [mapCenter, setMapCenter] = useState({
    lat: 33.749937,
    lng: -84.390744,
  });
  const heatMapData = {
    positions: data,
    options: {
      radius: 20,
      opacity: 0.6,
    },
  };

  const mapRef = useRef(null);
  const mapsRef = useRef(null);

  const dataSrc = data.length ? data : pins;

  const apiIsLoaded = (map, maps) => {
    if (!boundsFitted && dataSrc && dataSrc.length > 0) {
      mapRef.current = map;
      mapsRef.current = maps;
      setBoundsFitted(true);
      // console.log(map);
      let bounds = new maps.LatLngBounds();
      dataSrc.forEach((marker) => {
        bounds.extend(new maps.LatLng(marker.lat, marker.lng));
      });
      map.fitBounds(bounds);
    }
  };

  useEffect(() => {
    if (!boundsFitted) return;
    fitMapBounds();
    // if (!mapRef.current || !mapsRef.current) return;
    // fitMapBounds();
    // apiIsLoaded();
  }, [JSON.stringify(pins)]);

  const fitMapBounds = () => {
    const bounds = new mapsRef.current.LatLngBounds();

    dataSrc.forEach((place) => {
      bounds.extend(new mapsRef.current.LatLng(place.lat, place.lng));
    });

    mapRef.current.fitBounds(bounds);
  };

  return (
    <div style={{ height: "600px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: import.meta.env.VITE_GOOGLE_MAPS_REPORT_API_KEY,
        }}
        ref={mapRef}
        zoom={defaultZoom}
        center={mapCenter}
        // center={
        //   defaultCenter || {
        //     lat: 33.749937,
        //     lng: -84.390744,
        //   }
        // }
        yesIWantToUseGoogleMapApiInternals
        heatmapLibrary
        heatmap={heatMapData}
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
        // onGoogleApiLoaded={({ map, maps }) => {
        // console.log(map, maps);
        // mapRef.current = map;
        // mapsRef.current = maps;
        // onApiLoad(map, maps);
        // }}
      >
        {pins.map((pin, i) => {
          if (pin.special) {
            return (
              <IconStar
                style={{
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  height: "30px",
                  width: "30px",
                  transform: "translate(-50%, -100%)",
                  color: "var(--mantine-color-dark-5)",
                }}
                key={i}
                lat={pin.lat}
                lng={pin.lng}
                fill="var(--mantine-color-yellow-5)"
              />
            );
          }
          return (
            <img
              key={i}
              src={mapPin}
              lat={pin.lat}
              lng={pin.lng}
              style={{
                position: "absolute",
                top: "100%",
                left: "50%",
                height: "24px",
                width: "24px",
                transform: "translate(-50%, -100%)",
              }}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}

const K_WIDTH = 100;
const K_HEIGHT = 100;

const imageIcon = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: "absolute",
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,
  padding: 4,
};
