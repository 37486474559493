import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "@mantine/core";

import { titleize } from "./helpers";

export default function CurationTitleizeButton({
  reqData,
  onSuccess,
  buttonProps = {
    color: "gray",
    variant: "light",
    size: "xs",
  },
  buttonText = "Titelize",
  value,
  id,
}) {
  const [loading, setLoading] = useState(false);

  if (!value) return null;

  function onClick() {
    setLoading(true);
    // const req = { ...reqData, name: titleize(value) };

    const req = {};

    if (reqData.song_id) {
      req.songs = [{ song_id: id, name: titleize(value) }];
    } else {
      req.artists = [{ artist_id: id, name: titleize(value) }];
    }

    const url = reqData.song_id ? `/songs/${id}/` : `/artists/${id}/`;

    axios
      .put(url, req)
      .then(() => {
        toast.success("Updated!");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button {...buttonProps} onClick={onClick} loading={loading}>
      {buttonText}
    </Button>
  );
}
