export default function qbInit(queryComponents) {
  const obj = queryComponents.reduce((acc, cur) => {
    if (cur.default_value || cur.default_value === 0) {
      acc[cur.clientName] = cur.default_value;
    } else {
      if (cur.type === "select") {
        acc[cur.clientName] = [];
      } else {
        acc[cur.clientName] = "";
      }
    }
    return acc;
  }, {});

  return obj;
}
