import React, { useState } from "react";
import { Card } from "@mantine/core";

import { EffortForm, EffortList } from "./";
import { Header, ManagementLayout } from "@components/shared";

export default function EffortManagement({
  campaignId,
  locationId,
  organizationId,
  showCreate = true,
}) {
  const [success, setSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const requestInfo = {
    url: `/retrieve-efforts/`,
    data: {
      location_id: locationId,
      campaign_id: campaignId,
      organization_id: organizationId,
      refresh,
      skinny_collection: true,
    },
  };

  return <EffortList requestInfo={requestInfo} />;

  if (!showCreate) {
    return <EffortList requestInfo={requestInfo} />;
  }

  // if (!showCreate) {
  //   return <EffortList requestInfo={requestInfo} />;
  // }

  return (
    <ManagementLayout
      dataComponent={<EffortList requestInfo={requestInfo} />}
      createComponent={
        showCreate ? (
          <Card>
            <Header>Create Effort</Header>
            {!success && (
              <EffortForm
                campaignId={campaignId}
                onSuccess={() => {
                  setSuccess(!success);
                  setRefresh(!refresh);
                  setTimeout(() => {
                    setSuccess(false);
                  }, 100);
                }}
              />
            )}
          </Card>
        ) : null
      }
    />
  );
}
