import React from "react";
import axios from "axios";
import { shallow } from "zustand/shallow";

import PreferredLocation from "./PreferredLocation";
import useAppStore from "./microsite-store";

export default function UserPreferredLocation({ fetchUser }) {
  const { setPreferredLocation, user } = useAppStore(
    (state) => ({
      setPreferredLocation: state.setPreferredLocation,
      user: state.user,
    }),
    shallow
  );

  function setUserPreferredLocation(location) {
    if (!user) return;
    axios
      .post(`/microsite/update-preferred-location/`, {
        location_id: location.id,
        user_id: user.id,
      })
      .then(() => {
        setPreferredLocation(location);
      })
      .then(() => {
        fetchUser();
      })
      .catch((err) => {
        setPreferredLocation(location);
      });
  }

  return <PreferredLocation onSubmit={(d) => setUserPreferredLocation(d)} />;
}
