import React, { useState } from "react";
import { SegmentedControl } from "@mantine/core";

import { ContestManagement } from "@components/Contest";
import { PrizePoolManagement } from "@components/PrizePool";
import { AuctionManagement } from "@components/Auction";

export default function LocationContesting({ locationId }) {
  const [variety, setVariety] = useState("1");

  const varietyInt = parseInt(variety);

  return (
    <div>
      <SegmentedControl
        value={variety}
        data={[
          { label: "Contests", value: "1" },
          { label: "Prize Pools", value: "2" },
          { label: "Auctions", value: "3" },
        ]}
        onChange={(e) => setVariety(e)}
        mb="lg"
      />
      {varietyInt === 1 && (
        <ContestManagement showVarietyFilters={false} locationId={locationId} />
      )}
      {varietyInt === 2 && <PrizePoolManagement locationId={locationId} />}
      {varietyInt === 3 && <AuctionManagement locationId={locationId} />}
    </div>
  );
}
