import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Anchor,
  Badge,
  UnstyledButton,
  Group,
  Text,
  Grid,
  Modal,
  Card,
  Space,
  Title,
} from "@mantine/core";

import entityIcon from "@util/entityIcon";
import { ErrorView } from "@components/shared";
import { formatArtistList } from "@components/Curation/helpers";
import {
  CurateArtist,
  CurateButton,
  CurationAutoMatch,
  CurationEdit,
  CurationTagManagement,
  CurationTitleizeButton,
  SongList,
  TransferArtist,
  TransferSong,
} from "@components/Curation";

export default function CurationDetail({ isArtist, isSong }) {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [curating, setCurating] = useState(false);
  const [editing, setEditing] = useState(false);

  const params = useParams();
  const { id } = params;

  const reqData = {};
  if (isSong) reqData.song_ids = [id];
  if (isArtist) reqData.artist_ids = [id];

  useEffect(() => {
    fetchData();
  }, [id]);

  function fetchData() {
    const req = {};
    if (isSong) req.song_id = id;
    if (isArtist) req.artist_id = id;

    axios
      .post(`/retrieve-curation-item/`, req)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
      });
  }

  function onCurateClick() {
    setCurating(true);
    const req = { ...reqData };

    axios
      .post(`/curation-affirmation/`, req)
      .then(() => {
        window.location = `/curation`;
        setCurating(false);
      })
      .catch((err) => {
        setCurating(false);
        toast.error(err);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  return (
    <div>
      <Modal opened={editing} onClose={() => setEditing(false)}>
        <CurationEdit
          id={id}
          value={info.name}
          fetchData={() => {
            setEditing(false);
            fetchData();
          }}
          reqData={{
            song_id: id,
          }}
        />
      </Modal>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Group mb="sm">
            <Title order={4}>Song Info</Title>
            <Badge variant="light" color={info.curate ? "yellow" : "green"}>
              {info.curate ? "Uncurated" : "Curated"}
            </Badge>
          </Group>
          <Card mb="sm">
            <div>
              <UnstyledButton onClick={() => setEditing(true)}>
                <Text fw={600} size="lg">
                  {info.name}
                </Text>
              </UnstyledButton>
              {info.artists.map((artist, i) => (
                <Group key={i}>
                  <Text size="sm" c="dimmed">
                    {artist.name}
                  </Text>
                  {!artist.is_curated && (
                    <Badge color="red" size="xs">
                      Uncurated
                    </Badge>
                  )}
                </Group>
              ))}
              <Text size="sm" c="dimmed">
                {info.plays_count} play{info.plays_count === 1 ? "" : "s"}
              </Text>
              <Text size="sm" c="dimmed">
                {info.ratings_count} rating
                {info.ratings_count === 1 ? "" : "s"}
              </Text>
              <Group mt="lg">
                <CurationTitleizeButton
                  reqData={{
                    song_id: id,
                  }}
                  onSuccess={fetchData}
                  value={info.name}
                  id={id}
                />
                <TransferSong
                  songName={info.name}
                  songId={id}
                  songInfo={info}
                  onSuccess={fetchData}
                  artist={info.artists[0]}
                />
                {!info.artists[0].curate && info.curate && (
                  <CurateButton
                    buttonProps={{
                      size: "xs",
                      color: "gray",
                      variant: "light",
                    }}
                    onSuccess={fetchData}
                    reqData={{
                      song_ids: [id],
                    }}
                  />
                )}
                <TransferArtist
                  songName={info.name}
                  songId={id}
                  onSuccess={fetchData}
                  artist={info.artists[0]}
                />
                {info.artists[0].curate && (
                  <CurateArtist
                    onSuccess={fetchData}
                    artist={info.artists[0]}
                  />
                )}
              </Group>
            </div>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Title mb="sm" order={4}>
            Tags
          </Title>
          <Card mb="sm">
            <CurationTagManagement
              songId={isSong ? id : null}
              artistId={isArtist ? id : null}
              items={info.tags}
              fetchData={fetchData}
            />
          </Card>
        </Grid.Col>
      </Grid>
      <Space mt="lg" />
      <React.Fragment>
        {info.artists.map((artist) => (
          <div>
            <Space mt="lg" />
            <Title mb="sm" order={4}>
              Other songs by{" "}
              <Anchor
                fw={600}
                size="lg"
                component={Link}
                to={`/artists/${artist.id}`}
              >
                {artist.name}
              </Anchor>
            </Title>
            <SongList
              artist={{
                id: artist.id,
                name: artist.name,
                curate: artist.curate,
              }}
              items={artist.songs.filter((f) => f.id !== id)}
              fetchData={fetchData}
            />
            {artist.songs.filter((f) => f.curate && f.id !== id).length > 0 && (
              <CurationAutoMatch artistId={artist.id} onSuccess={fetchData} />
            )}
          </div>
        ))}
      </React.Fragment>
    </div>
  );
}
