import React, { useState } from "react";
import axios from "axios";
import { Button } from "@mantine/core";
import toast from "react-hot-toast";

import { IconDownload } from "@tabler/icons-react";

export default function EffortCsvDownload({ effortId }) {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    axios
      .post(`/campaign-effort-csv-bulk/`, { campaign_effort_id: effortId })
      .then(() => {
        setLoading(false);
        toast.success("Making CSV. A link will be sent when it's ready");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      color="gray"
      size="xs"
      variant="light"
      radius="xl"
      rightSection={<IconDownload size={16} />}
      loading={loading}
      onClick={onClick}
      style={{
        overflow: "visible",
      }}
    >
      Download CSV
    </Button>
  );
}
