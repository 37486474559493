import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Card, Button, Text, Group } from "@mantine/core";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";
import { ErrorView, PageTitle } from "@components/shared";
import { ApiRequestList } from "@components/ApiRequest";

export default function ServerSessionDetail() {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/server-sessions/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  function endSession() {
    axios
      .post(`/server-sessions/${id}/`, {})
      .then(() => {
        toast.success("Session Closed!");
        fetchData();
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;
  return (
    <div>
      <PageTitle
        title={
          <Group align="center">
            Server Session
            {!info.closed && (
              <Button
                variant="light"
                color="red"
                size="xs"
                onClick={() => endSession()}
              >
                End Session
              </Button>
            )}
          </Group>
        }
        subtitle={info.full_name}
        icon={entityIcon.user()}
        iconColor={entityColor.user}
      />
      <Group gap={0} mb="md">
        <Text fw={700} size="lg">
          Incoming Api Requests
        </Text>
        <Text ml="xs" c="blue">
          ({info.api_requests_count})
        </Text>
      </Group>
      <ApiRequestList serverSessionId={id} />
    </div>
  );
}
