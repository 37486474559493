import React, { useState } from "react";
import axios from "axios";
import { Button, Center, Text, Title, TextInput } from "@mantine/core";
import toast from "react-hot-toast";

export default function UserForgotPassword({ locationId }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function onSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const req = {
      location_id: locationId,
      email,
    };

    axios
      .post(`/forgot-password/`, req)
      .then(() => {
        setSuccess(true);
        setLoading(false);
        toast.success(
          `Check your ${email} inbox for password reset instructions`
        );
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  if (success) {
    return (
      <div>
        <Title align="center" order={2}>
          Email sent!
        </Title>
        <Text align="center">Check your {email} inbox for your link.</Text>
      </div>
    );
  }

  return (
    <div>
      <Title order={2} align="center">
        Forgot password?
      </Title>
      <Text align="center" mb="md">
        Get a link to reset your password by entering your email below
      </Text>
      <TextInput
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="email"
        size="md"
      />
      <Button
        fullWidth
        mt="md"
        type="submit"
        loading={loading}
        disabled={!email}
        onClick={onSubmit}
      >
        Submit
      </Button>
    </div>
  );
}
