import React from "react";

import { EmailServerCreate, EmailServerTemplateList } from "./";

export default function EmailServerEntityManagement({
  organizationId,
  locationId,
  fetchData,
  postmarkServerName,
  postmarkServerId = null,
  postmarkServers,
}) {
  return (
    <div>
      {!postmarkServerId ? (
        <EmailServerCreate
          fetchData={fetchData}
          organizationId={organizationId}
          locationId={locationId}
        />
      ) : (
        <EmailServerTemplateList
          organizationId={organizationId}
          locationId={locationId}
          postmarkServerName={postmarkServerName}
          postmarkServerId={postmarkServerId}
          postmarkServers={postmarkServers}
        />
      )}
    </div>
  );
}
