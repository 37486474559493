import React, { useContext, useState, useEffect } from "react";
import { Switch, Divider, Group, NumberInput } from "@mantine/core";

import Intervals from "./Intervals";
import { Counter } from "@components/shared";
import { Context } from "./";

export default function Checkins({ onUpdate }) {
  const {
    check_in_radius: checkInRadius,
    check_ins_enabled: checkInsEnabled,
    allow_check_in_this_location: allowCheckInThisLocation,
    intervals,
  } = useContext(Context);

  // const counterFields = [
  //   {
  //     title: "Check in radius",
  //     keyName: "check_in_radius",
  //     value: checkInRadius,
  //   },
  // ].map((m, i) => ({
  //   ...m,
  //   key: i + 1,
  // }));

  return (
    <div>
      <Switch
        label="Allow Check-ins"
        checked={checkInsEnabled}
        onChange={() =>
          onUpdate({
            check_ins_enabled: !checkInsEnabled,
          })
        }
        mb="sm"
      />
      {checkInsEnabled && (
        <React.Fragment>
          <Switch
            label="Allow Check-ins at this location"
            checked={allowCheckInThisLocation}
            onChange={() =>
              onUpdate({
                allow_check_in_this_location: !allowCheckInThisLocation,
              })
            }
            mb="sm"
          />
          <Group>
            <NumberInput
              value={checkInRadius}
              onBlur={(e) =>
                onUpdate({
                  check_in_radius: parseFloat(e.target.value),
                })
              }
              precision={3}
            />
            <h3>Check in radius</h3>
          </Group>
          {/* {counterFields.map((c) => (
            <Group key={c.key}>
              <Counter
                value={c.value}
                unlimited={c.unlimited}
                onChange={(e) =>
                  onUpdate({
                    [c.keyName]: e,
                  })
                }
              />
              <h3 style={{ margin: "0" }}>{c.title}</h3>
            </Group>
          ))} */}
          <Divider mt="lg" mb="lg" />
          <Intervals
            items={intervals}
            title="Check-In Interval"
            onChange={(e) => onUpdate({ intervals: e })}
          />
        </React.Fragment>
      )}
    </div>
  );
}
