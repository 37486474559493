import React, { useState } from "react";
import axios from "axios";
import { Modal, FileInput, Button } from "@mantine/core";
import toast from "react-hot-toast";

export default function BulkAssociate({ uploadReqData, onSuccess }) {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  function onClose() {
    setOpen(false);
    setDownloading(false);
    setUploading(false);
    setValue(null);
  }

  function onUploadClick() {
    setUploading(true);

    const formData = new FormData();
    formData.append("file", value);
    Object.keys(uploadReqData).forEach((k) =>
      formData.append(k, uploadReqData[k])
    );

    axios
      .post(`/upload-association-csv/`, formData)
      .then(() => {
        toast.success("Success!");
        setUploading(false);
        onSuccess();
        onClose();
      })
      .catch((err) => {
        setUploading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Button
        size="xs"
        variant="subtle"
        color="gray"
        onClick={() => setOpen(true)}
      >
        Bulk add
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <FileInput
          label="CSV File"
          placeholder="Select a file to upload..."
          accept=".csv"
          required
          value={value}
          onChange={setValue}
        />
        <Button
          loading={uploading}
          mt="sm"
          fullWidth
          disabled={!value}
          onClick={onUploadClick}
        >
          Upload CSV
        </Button>
      </Modal>
    </div>
  );
}
