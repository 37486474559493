import React, { Suspense } from "react";

const SuspenseFallback = <div></div>;

// const SuspenseWrapper = ({ children }) => <div>{children}</div>;

const SuspenseWrapper = ({ children }) => (
  <Suspense fallback={SuspenseFallback}>{children}</Suspense>
);

export default SuspenseWrapper;
