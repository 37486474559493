import React, { useState, useEffect } from "react";
import {
  Tabs,
  Text,
  Table,
  Group,
  Modal,
  Space,
  Menu,
  ActionIcon,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { IconDots } from "@tabler/icons-react";

import {
  PrizeCard,
  PrizeForm,
  PrizeLootItems,
  PrizeLootItemsForDupeContest,
  PrizeSettings,
} from "./";
import entityIcon from "@util/entityIcon";

export default function PrizeItem({
  contestForDuplication = false,
  id,
  locationId,
  onModalClose,
  onUpdate,
  organizationId,
  prizeData,
  showDelete = true,
  showDuplicate = true,
  showPrizeEdit,
}) {
  const [editOpen, setEditOpen] = useState(false);
  const [lootOpen, setLootOpen] = useState(false);
  const [duplicationErrors, setDuplicationErrors] = useState([]);

  useEffect(() => {
    if (!lootOpen && onModalClose) {
      onModalClose();
    }
  }, [lootOpen]);

  function onEditClose() {
    setEditOpen(false);
  }

  function onLootClose() {
    setLootOpen(false);
    onUpdate();
  }

  function onDuplicateClick() {
    const req = {
      prize_id: id,
    };

    axios
      .post(`/prizes/${id}/duplicate/`, req)
      .then(({ data }) => {
        if (
          data.response &&
          data.response[0] &&
          data.response[0].available === 0
        ) {
          return toast.error(
            `Insufficient quantity for ${data.response[0].name}`
          );
        }

        if (data.response.length) {
          setDuplicationErrors(data.response);
        } else {
          onUpdate();
          toast.success("Prize Duplicated!");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  const showDuplicationModal = duplicationErrors.length > 0;

  return (
    <PrizeCard prizeData={prizeData}>
      {/* <Divider mt="sm" mb="sm" /> */}
      <Group position="right">
        <Menu position="bottom">
          <Menu.Target>
            <ActionIcon variant="subtle" color="gray">
              <IconDots />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            {prizeData.can_edit && showPrizeEdit && (
              <Menu.Item component="button" onClick={() => setEditOpen(true)}>
                edit
              </Menu.Item>
            )}
            <Menu.Item component="button" onClick={() => setLootOpen(true)}>
              manage loot items
            </Menu.Item>
            {showDuplicate && (
              <Menu.Item component="button" onClick={onDuplicateClick}>
                duplicate
              </Menu.Item>
            )}
            {showDelete && prizeData.can_remove && (
              <React.Fragment>
                <Menu.Divider />
                <DeletePrizeButton prizeId={id} onSuccess={onUpdate} />
              </React.Fragment>
            )}
          </Menu.Dropdown>
        </Menu>
      </Group>
      <DuplicatePrizeItem
        errors={duplicationErrors}
        showModal={showDuplicationModal}
        onClose={() => setDuplicationErrors([])}
        prizeName={prizeData.name}
      />
      <Modal opened={editOpen} onClose={onEditClose} title={`Edit prize`}>
        <Tabs defaultValue="edit">
          <Tabs.List>
            <Tabs.Tab value="edit">edit</Tabs.Tab>
            <Tabs.Tab value="settings">settings</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="edit">
            <Space mt="md" />
            <PrizeForm
              name={prizeData.name}
              description={prizeData.description}
              id={id}
              onSuccess={() => {
                // onEditClose();
                onUpdate();
              }}
            />
          </Tabs.Panel>
          <Tabs.Panel value="settings">
            <Space mt="md" />
            <PrizeSettings
              prizeId={id}
              fetchData={() => {
                onEditClose();
                onUpdate();
              }}
              settings={prizeData.settings}
              settingsType={prizeData.settings_type}
            />
          </Tabs.Panel>
        </Tabs>
      </Modal>
      <Modal
        size="xl"
        opened={lootOpen}
        onClose={onLootClose}
        title={`Edit prize loot`}
      >
        {contestForDuplication ? (
          <PrizeLootItemsForDupeContest
            organizationId={organizationId}
            locationId={locationId}
            prizeId={id}
          />
        ) : (
          <PrizeLootItems
            organizationId={organizationId}
            locationId={locationId}
            prizeId={id}
          />
        )}
      </Modal>
    </PrizeCard>
  );
}

const DeletePrizeButton = ({ prizeId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    axios
      .delete(`/prizes/${prizeId}/`)
      .then(() => {
        toast.success("Removed!");
        setLoading(false);
        window.location.reload();
        // onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Menu.Item
      color="red"
      icon={entityIcon.trash()}
      component="button"
      onClick={onClick}
      loading={loading}
    >
      delete
    </Menu.Item>
  );
};

const DuplicatePrizeItem = ({ errors, showModal, prizeName, onClose }) => {
  return (
    <Modal
      opened={showModal}
      onClose={onClose}
      size="lg"
      title="Duplication failed"
    >
      <Text mb="sm">
        Unable to duplicate <b>{prizeName}</b> because of the following:
      </Text>
      <Table striped fontSize="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>item</Table.Th>
            <Table.Th>requested</Table.Th>
            <Table.Th>available</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {errors.map((er, i) => (
            <Table.Tr key={i}>
              <Table.Td>{er.name}</Table.Td>
              <Table.Td>{er.requested}</Table.Td>
              <Table.Td>{er.available}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Modal>
  );
};
