import React from "react";
import { Group, Text, Card, Accordion } from "@mantine/core";
import entityIcon from "@util/entityIcon";

export default function UserInteractionAccordion({ phoneData, emailData }) {
  return (
    <div>
      <Accordion defaultValue="">
        {emailData && (
          <AccordianItem
            title={emailData.address}
            icon={entityIcon.email()}
            accordionValue="email"
          >
            <Text>Validated: {emailData.validated ? "Yes" : "No"}</Text>
            <Text>Verified: {emailData.verified ? "Yes" : "No"}</Text>
            <Text>Auth Use: {emailData.use_for_auth ? "Yes" : "No"}</Text>
            <Text>
              Last Validated:{" "}
              {new Date(emailData.activated_at).toLocaleString()}
            </Text>
          </AccordianItem>
        )}
        {phoneData && (
          <AccordianItem
            title={phoneData.number_formatted}
            icon={entityIcon.phone()}
            accordionValue="phone"
          >
            <Text>Validated: {phoneData.validated ? "Yes" : "No"}</Text>
            <Text>Verified: {phoneData.verified ? "Yes" : "No"}</Text>
            <Text>Mobile: {phoneData.mobile ? "Yes" : "No"}</Text>
            <Text>
              Last Validated:{" "}
              {new Date(phoneData.last_validated_at).toLocaleString()}
            </Text>
          </AccordianItem>
        )}
      </Accordion>
    </div>
  );
}

const AccordianItem = ({ title, icon, children, accordionValue }) => {
  return (
    <Accordion.Item value={accordionValue}>
      <Accordion.Control>
        <Group mb="xs">
          {icon}
          <Text>{title}</Text>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Card mb="sm">{children}</Card>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
