import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { Button, Divider, Group, Modal, Text } from "@mantine/core";

export default function CurationAutoMatch({ artistId, onSuccess }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function onClose() {
    setOpen(false);
    setLoading(false);
  }

  function onSubmit() {
    setLoading(true);
    const req = { from_artist_id: artistId };

    axios
      .post(`/curation-auto-match/`, req)
      .then(() => {
        toast.success("Curated!");
        setLoading(false);
        onClose();
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      <Button onClick={() => setOpen(true)} variant="subtle" color="gray">
        Auto Match
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <Text sz="lg" align="center" fw={600}>
          Are you sure you want to start the auto matching process?
        </Text>
        <Divider mt="lg" mb="lg" />
        <Button fullWidth loading={loading} onClick={onSubmit}>
          Start auto matching process
        </Button>
      </Modal>
    </React.Fragment>
  );
}
