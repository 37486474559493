import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Box, Flex, Text, Title } from "@mantine/core";

import { EmailMessageEvents } from "@components/Email";
import { ErrorView } from "@components/shared";
import { formatRecipients } from "@components/Email/EmailActivityList";

export default function EmailDetail({ apiToken }) {
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const params = useParams();
  const { messageId, streamId, id: serverId } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    const req = {
      server_token: apiToken,
      postmark_message_id: messageId,
    };

    axios
      .post(`/postmark/messages/${messageId}/`, req)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  const metaBlocks = [
    { title: "Subject", value: info.Subject },
    {
      title: "From",
      value: info.From,
    },
    {
      title: "To",
      value: formatRecipients.format(info.Recipients),
    },
    {
      title: "Tag",
      value: info.Tag,
    },
    {
      title: "Open Tracking",
      value: info.TrackOpens ? "Yes" : "No",
    },
    {
      title: "Link Tracking",
      value:
        info.TrackLinks && info.TrackLinks === "HtmlAndText"
          ? "HTML & Text"
          : "",
    },
    {
      title: "Template",
      value:
        info.Metadata && info.Metadata.template ? info.Metadata.template : "",
    },
  ].filter((f) => (f.value ? true : false));

  return (
    <div>
      {metaBlocks.map((block, i) => (
        <Flex key={i} gap="lg" align="center">
          <Text style={{ minWidth: "100px" }} c="dimmed" size="sm">
            {block.title}:
          </Text>
          <Text>{block.value}</Text>
        </Flex>
      ))}
      <Title order={4} mt="lg" mb="sm">
        Events
      </Title>
      <EmailMessageEvents events={info.MessageEvents} />
      <Box mt="lg">
        {/* <CustomIframe style={{ width: "100%" }}>{info.HtmlBody}</CustomIframe> */}
        {/* <CustomIframe style={{ width: "100%" }}></CustomIframe> */}
      </Box>
    </div>
  );
}

const CustomIframe = ({ children, ...props }) => {
  const [contentRef, setContentRef] = useState(null);

  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe {...props} ref={setContentRef} frameBorder={0}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};
