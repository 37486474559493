import React from "react";

import { PinnedReportRecipeList } from "@components/ReportRecipe";

export default function Dashboard() {
  return (
    <div>
      <PinnedReportRecipeList
        reqData={{
          pinned_to: 1,
        }}
      />
    </div>
  );
}
