import React, { useState, useEffect } from "react";
import { Button, Group, Switch } from "@mantine/core";
import toast from "react-hot-toast";
import axios from "axios";

import { Counter } from "@components/shared";
import {
  initialPrizeSettings,
  initialPrizePoolSettings,
  initialAuctionItemSettings,
} from "./helpers";

export default function PrizeSettings({
  prizeId,
  settings = {},
  settingsType,
  fetchData,
}) {
  const [values, setValues] = useState(
    settingsType === "auction"
      ? initialAuctionItemSettings
      : settingsType === "prizepool"
      ? initialPrizePoolSettings
      : initialPrizeSettings
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (settings && JSON.stringify(settings) !== "{}") {
      setValues({
        ...values,
        ...settings,
      });
    }
  }, []);

  function onChange(updatedKeyVal) {
    setValues({
      ...values,
      ...updatedKeyVal,
    });
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      prize_id: prizeId,
      settings: values,
    };

    axios
      .put(`/prizes/${prizeId}/settings/`, req)
      .then(() => {
        toast.success("Updated!");
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      {settingsType === "auction" && (
        <React.Fragment>
          <Group mt="lg">
            <Counter
              value={values.min_starting_bid}
              onChange={(e) => onChange({ min_starting_bid: e })}
              unlimited={false}
              maxValue={values.max_bid_increment}
              minValue={1}
            />
            <h3 style={{ margin: "0" }}>min starting bid</h3>
          </Group>
          <Group mt="lg">
            <Counter
              value={values.max_bid_increment}
              onChange={(e) => onChange({ max_bid_increment: e })}
              unlimited={false}
              minValue={values.min_starting_bid}
              maxValue={100}
            />
            <h3 style={{ margin: "0" }}>max bid increment</h3>
          </Group>
        </React.Fragment>
      )}
      {settingsType === "prizepool" && (
        <React.Fragment>
          <Group mt="lg">
            <Counter
              value={values.entry_cost}
              onChange={(e) => onChange({ entry_cost: e })}
              unlimited={false}
            />
            <h3 style={{ margin: "0" }}>points to gain an entry</h3>
          </Group>
        </React.Fragment>
      )}
      {settingsType === "sweepstakes" && (
        <React.Fragment>
          <Switch
            label="Finalists enabled"
            checked={values.finalists}
            onChange={() =>
              onChange({
                finalists: !values.finalists,
              })
            }
          />
          {values.finalists && (
            <React.Fragment>
              <Group mt="lg">
                <Counter
                  value={values.number_of_finalists}
                  onChange={(e) => onChange({ number_of_finalists: e })}
                  unlimited={false}
                />
                <h3 style={{ margin: "0" }}>finalists</h3>
              </Group>
              <Switch
                label="Draw winner from finalists"
                mt="lg"
                checked={values.draw_winner_from_finalists}
                onChange={() =>
                  onChange({
                    draw_winner_from_finalists:
                      !values.draw_winner_from_finalists,
                  })
                }
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <Button
        fullWidth
        mt="lg"
        color="green"
        loading={loading}
        onClick={onSubmit}
      >
        Save
      </Button>
    </div>
  );
}
