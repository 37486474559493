import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Group,
  Text,
  Loader,
  Radio,
  Flex,
  TextInput,
} from "@mantine/core";
import axios from "axios";
import styled from "styled-components";
import toast from "react-hot-toast";

export default function CurationTagSearch({
  songId,
  artistId,
  tagId,
  fetchData,
}) {
  const [value, setValue] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [value]);

  function fetchData() {
    if (!value || value.length < 2) return;

    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    setLoading(true);

    const req = {
      context: songId ? "song" : "artist",
      search_value: value,
    };

    axios
      .post(`/curation-search/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setResults(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function onTagCreateClick() {
    setSubmitting(true);
    toast.success("This will create eventually");
  }

  function onTagAssociateClick() {
    const req = {
      to_id: selected,
      from_id: tagId,
    };

    setSubmitting(true);

    axios
      .post(`/curation-associate-artist/`, req)
      .then(() => {
        setSubmitting(false);
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        setSubmitting(false);
      });
  }

  return (
    <div>
      <Group mb="sm">
        <Text size="sm" fw={600}>
          {results.length > 0 ? "Select a new tag" : "Enter new tag"}
        </Text>
        {loading && <Loader size="xs" />}
      </Group>
      <TextInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="search tags"
        mb="sm"
      />
      {results.length > 0 && (
        <React.Fragment>
          <StyledRadioList>
            {results.map((r) => (
              <Box mb="sm" key={r.id}>
                <Flex align="flex-start" columnGap="12px">
                  <Radio
                    name="tagId"
                    checked={r.id === selected}
                    onChange={() => setSelected(r.id)}
                    mt="4px"
                  />
                  <Text>{r.name}</Text>
                </Flex>
              </Box>
            ))}
          </StyledRadioList>
          <Button
            fullWidth
            disabled={!selected}
            onClick={onTagAssociateClick}
            // loading={submitting}
          >
            Change tag
          </Button>
        </React.Fragment>
      )}
      {!loading && results.length === 0 && value.length > 0 && (
        <React.Fragment>
          <Text>No results</Text>
          {/* <Button fullWidth onClick={onTagCreateClick} loading={submitting}>
            Create tag
          </Button> */}
        </React.Fragment>
      )}
    </div>
  );
}

const StyledRadioList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-top: 15px;
`;
