import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextInput,
  Divider,
  Modal,
  Text,
  List,
  Flex,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core";
import { IconBomb } from "@tabler/icons-react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const confirmText = "DELETEMYACCOUNT";

export default function UserDeleteAccount() {
  const [isOpen, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmValue, setConfirmValue] = useState("");
  const [loading, setLoading] = useState(false);

  const userInfo = useSelector((state) => state.user);

  function onClose() {
    setOpen(false);
    setConfirm(false);
    setConfirmValue("");
    setLoading(false);
  }

  function onSubmit() {
    setLoading(true);
    const req = {
      comment: `User requested account deletion from member app. ID: ${userInfo.user_location_id}`,
    };

    axios
      .post(`/users/delete-request/`, req)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <UnstyledButton onClick={() => setOpen(true)}>
        <Flex align="center">
          <ThemeIcon variant="subtle" color="gray" mr="sm">
            <IconBomb size={20} />
          </ThemeIcon>
          <Text>Delete Account</Text>
        </Flex>
      </UnstyledButton>
      <Modal opened={isOpen} onClose={onClose}>
        {confirm ? (
          <>
            <Text mb="sm">Type {confirmText} in the text field below</Text>
            <TextInput
              value={confirmValue}
              onChange={(e) => setConfirmValue(e.target.value)}
            />
            <Divider mt="lg" mb="sm" />
            <Button
              disabled={confirmValue !== confirmText}
              loading={loading}
              onClick={onSubmit}
              fullWidth
            >
              Delete Account
            </Button>
            <Flex justify="center">
              <Button
                mt="md"
                variant="subtle"
                color="gray"
                size="xs"
                c="dimmed"
                onClick={onClose}
              >
                Get me out of here
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <Text>Sorry to see you go!</Text>
            <Text mt="sm" mb="sm">
              By clicking the "Delete my account" button below, we will:
            </Text>
            <List>
              <List.Item>log you out immediately</List.Item>
              <List.Item>lock your account</List.Item>
              <List.Item>delete your data within 30 days</List.Item>
              <List.Item>
                send you an email confirming your data deletion
              </List.Item>
            </List>
            <Divider mt="lg" mb="lg" />
            <Button fullWidth color="red" onClick={onClose}>
              No! Get me out of here!
            </Button>
            <Flex justify="center">
              <Button
                mt="md"
                variant="subtle"
                color="gray"
                size="xs"
                c="dimmed"
                onClick={() => setConfirm(true)}
              >
                Delete my account
              </Button>
            </Flex>
          </>
        )}
      </Modal>
    </div>
  );
}
