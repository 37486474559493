import React from "react";
import { Button } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

export default function ReportRecipeUnpinButton({ pinId, onSuccess }) {
  function onUnpin() {
    axios
      .delete(`/pinned-recipes/${pinId}/`)
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Button
      onClick={() => onUnpin()}
      size="xs"
      color="gray"
      p={0}
      variant="subtle"
    >
      unpin
    </Button>
  );
}
