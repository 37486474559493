import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import {
  Button,
  Group,
  Center,
  MantineProvider,
  Text,
  Title,
  Modal,
  Paper,
  useMantineTheme,
} from "@mantine/core";

import Microsite from "@components/Microsite";
import IntroModal from "@components/Microsite/IntroModal";
import { UserLockModal } from "@components/User";
import { TicketEntryForm } from "@components/Ticket";
import MetaPixel from "@util/metaPixel";
import { size } from "@util/breakpoints";

import { useTranslation } from "react-i18next";

import "@components/Microsite/locales";

export default function EntryContainer({ preview }) {
  const [campaignId, setCampaignId] = useState(null);
  const [effortId, setEffortId] = useState(null);
  const [error, setError] = useState(null);
  const [entityInfo, setEntityInfo] = useState(null);
  const [appConfig, setAppConfig] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);

  const theme = useMantineTheme();

  const params = useParams();
  const { campaignPath, effortPath, locationPath } = params;

  const { t, i18n } = useTranslation();

  axios.interceptors.response.use(function (response) {
    const res = response.data;
    if (res.response_code === 3) {
      setModalInfo(res.response[0]);
      return response;
    } else {
      return response;
    }
  });

  useEffect(() => {
    fetchAppData();
  }, []);

  function fetchAppData() {
    const req = {
      url: campaignPath,
      campaign_url: campaignPath,
    };

    if (preview) req.preview = true;
    if (effortPath) req.campaign_effort_url = effortPath;

    axios
      .post(`/microsite/retrieve-by-url/`, req)
      .then(({ data }) => {
        setCampaignId(data.response[0].campaign_id);
        setEffortId(data.response[0].campaign_effort_id);
        setEntityInfo(data.response[0]);
        setAppConfig(data.response[0].app_configuration);

        if (
          data.response[0] &&
          data.response[0].language &&
          data.response[0].language !== "en"
        ) {
          i18n.changeLanguage(data.response[0].language);
        }
      })
      .catch((err) => {
        setCampaignId(null);
        setEffortId(null);
        setError(err);
      });
  }

  if (error)
    return (
      <Center style={{ height: 500 }}>
        <Text>{error}</Text>
      </Center>
    );
  if ((!campaignId && !effortId) || !entityInfo) return <div></div>;

  const colors = appConfig && appConfig.colors ? appConfig.colors : null;
  const showMicrosite = entityInfo.active_effort_count > 0 || effortId;

  return (
    <Layout>
      <MantineProvider
        theme={{
          fontFamily: theme.fontFamily,
          fontFamilyMonospace: theme.fontFamilyMonospace,
          headings: {
            ...theme.headings,
            fontFamily: theme.fontFamily,
          },
          primaryColor: colors ? colors.base : "orange",
        }}
      >
        <UserLockModal data={modalInfo} />
        <IntroModal info={entityInfo} />
        <Paper
          shadow="xl"
          p="lg"
          radius="md"
          style={{
            // backgroundColor: `rgba(255, 255, 255, 0.975)`,
            backgroundColor: `rgba(0, 0, 0, 0.85)`,
            // backgroundColor: "transparent",
          }}
        >
          {entityInfo.pixel_code && (
            <MetaPixel pixelIds={[entityInfo.pixel_code]} />
          )}
          {showMicrosite ? (
            <div>
              <Microsite
                appConfigData={appConfig}
                campaignId={campaignId}
                effortId={effortId}
                entityInfoData={entityInfo}
                preview={preview}
              />
            </div>
          ) : (
            <React.Fragment>
              <Title c="white" align="center" mb="sm">
                Nothing available.
              </Title>
              <Text c="white" align="center" size="lg">
                {entityInfo.title} has nothing available for interaction at the
                moment. Check back later.
              </Text>
            </React.Fragment>
          )}
        </Paper>
      </MantineProvider>
      {!preview && (
        <Group justify="center" mt="lg">
          <TroubleModal
            buttonText={t("trouble_ticket_button")}
            effortId={effortId}
            campaignId={campaignId}
          />
        </Group>
      )}
    </Layout>
  );
}

const Layout = styled.div`
  width: 600px;
  margin: 0 auto;
  padding: 60px 0 20px 0;
  word-wrap: break-word;

  label {
    color: whitesmoke;
  }

  @media (max-width: ${size.tablet}) {
    width: 95%;
    padding-top: 20px;
  }
`;

const TroubleModal = ({ buttonText, effortId, campaignId }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  const reqData = {
    url: window.location.href,
  };

  if (effortId) reqData.campaign_effort_id = effortId;
  if (campaignId) reqData.campaign_id = campaignId;

  return (
    <div>
      <Modal opened={isOpen} onClose={onClose}>
        <TicketEntryForm reqData={reqData} />
      </Modal>
      <Button
        size="xs"
        variant="light"
        color="gray"
        onClick={() => setOpen(true)}
      >
        {buttonText}
      </Button>
    </div>
  );
};
