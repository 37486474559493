import React, { useState, useEffect } from "react";
import { Button, Flex, Text, UnstyledButton } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { IconCamera } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";

export default function ReportSnapshotCreate({ reqData, recipeId }) {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  function onSubmit() {
    const req = {
      ...reqData,
    };

    setLoading(true);

    axios
      .post(`/report-recipes/save-snapshot/`, req)
      .then(() => {
        queryClient.invalidateQueries([`snapshots`]);
        toast.success("Saved");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <Flex justify="flex-start">
      <UnstyledButton onClick={onSubmit}>
        <Flex gap="xs" align="center">
          <IconCamera />
          <Text fw={600} size="sm">
            Take Snapshot
          </Text>
        </Flex>
      </UnstyledButton>
    </Flex>
  );
}
