import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import {
  Box,
  Button,
  Title,
  Flex,
  Card,
  useMantineTheme,
  useMantineColorScheme,
} from "@mantine/core";
import styled from "styled-components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { SuspenseWrapper } from "@components/shared";

import { AuthLayout, ForgotPassword, LoginForm } from "@components/Auth";

import { UserLogin } from "@components/Auth";

import {
  AdminContainer,
  EntryContainer,
  GalleryContainer,
  ManagerContainer,
  ManagerTextOnlyContainer,
  UserContainer,
} from "./containers";

import { Code } from "@components/Auth";
import { TicketEntryForm } from "@components/Ticket";
import { SET_ADMIN } from "@duck/admin";
import { SET_USER } from "@duck/user";
import { SET_MANAGER } from "@duck/manager";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: 50000000,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      // refetchOnReconnect: false,
      // staleTime: 5 * 60 * 1000,
    },
  },
});

const devToolProps = {
  initialIsOpen: true,
  // position: "top-right",
};

function App({ admin, manager, user, dispatch }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { setColorScheme, clearColorScheme, colorScheme } =
    useMantineColorScheme();

  useEffect(() => {
    fetchData();
    clearColorScheme();
  }, []);

  function attemptRedirect(res) {
    const entryPoint = localStorage.getItem("mixer-entry-point");

    if (JSON.stringify(res) === "{}" || JSON.stringify(res) === "null") {
      if (
        window.location.pathname.indexOf("code") === -1 &&
        window.location.pathname.indexOf("submit-trouble-ticket") === -1 &&
        window.location.pathname.indexOf("entry") === -1 &&
        window.location.pathname.indexOf("gallery") === -1
      ) {
        if (entryPoint) {
          if (
            window.location.pathname.indexOf("admin") < 0 &&
            window.location.pathname.indexOf("manager") < 0 &&
            window.location.pathname.indexOf("user-login") < 0
          ) {
            return (window.location = entryPoint);
          }
        }
      }
    }
  }

  function fetchData() {
    axios
      .get(`/status/`)
      .then(({ data }) => {
        axios.interceptors.request.use(
          (request) => {
            request.headers["server-session-token"] =
              data.response[0].server_session_token;

            return request;
          },
          (error) => {
            return Promise.reject(error);
          }
        );
        attemptRedirect(data.response[0]);
        if (data.response[0] === null) {
          return setLoading(false);
        }
        if (data.response[0].admin_id) {
          setLoading(false);
          return dispatch({
            type: SET_ADMIN,
            payload: data.response[0],
          });
        }
        if (data.response[0].manager_id) {
          setLoading(false);
          return dispatch({
            type: SET_MANAGER,
            payload: data.response[0],
          });
        }
        if (data.response[0].user_location_id) {
          setLoading(false);
          return dispatch({
            type: SET_USER,
            payload: data.response[0],
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  if (loading) return <React.Fragment></React.Fragment>;
  if (error) return <AuthLayout title={error} />;

  if (window.location.href.indexOf("gallery") > 0) {
    return (
      <React.Fragment>
        <Toaster />
        <BrowserRouter>
          <Routes>
            <Route path="gallery/:id" element={<GalleryContainer />} />
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    );
  }

  if ((manager || admin) && window.location.href.indexOf("code") > 0) {
    return (
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <BrowserRouter>
          <Routes>
            <Route path="code/:id" element={<Code />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    );
  }

  const PreviewButton = () => {
    return (
      <Box
        style={{
          position: "fixed",
          bottom: 10,
          left: 10,
        }}
      >
        <Flex align="center" gap="sm">
          <Title c="red">PREVIEW </Title>
          <Button
            size="xs"
            variant="subtle"
            color="red"
            onClick={() => window.close()}
          >
            Close
          </Button>
        </Flex>
      </Box>
    );
  };

  const RqDevtools = () => <ReactQueryDevtools {...devToolProps} />;

  if (window.location.href.indexOf("entry") > 0) {
    return (
      <SuspenseWrapper>
        <QueryClientProvider client={queryClient}>
          <Toaster />
          <BrowserRouter>
            <Routes>
              <Route
                path="/entry/:campaignPath"
                element={
                  <React.Fragment>
                    <EntryContainer preview={manager || admin ? true : false} />
                    {(manager || admin) && <PreviewButton />}
                  </React.Fragment>
                }
              />
              <Route
                path="/entry/:campaignPath/:effortPath"
                element={
                  <React.Fragment>
                    <EntryContainer preview={manager || admin ? true : false} />
                    {(manager || admin) && <PreviewButton />}
                  </React.Fragment>
                }
              />
              <Route
                path="/entry/:campaignPath/:effortPath/:locationPath"
                element={<EntryContainer />}
              />
            </Routes>
          </BrowserRouter>
          <RqDevtools />
        </QueryClientProvider>
      </SuspenseWrapper>
    );
  }

  if (window.location.href.indexOf("submit-trouble-ticket") > 0) {
    return (
      <TicketHolder>
        <Toaster />
        <TicketEntryForm />
      </TicketHolder>
    );
  }

  const Auth = ({ children }) => (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <BrowserRouter>{children}</BrowserRouter>
      <RqDevtools />
    </QueryClientProvider>
  );

  if (admin)
    return (
      <QueryClientProvider client={queryClient}>
        <SuspenseWrapper>
          <Auth>
            <AdminContainer />
          </Auth>
        </SuspenseWrapper>
        <RqDevtools />
      </QueryClientProvider>
    );
  if (manager && manager.location_text_only) {
    return (
      <QueryClientProvider client={queryClient}>
        <SuspenseWrapper>
          <Auth>
            <ManagerTextOnlyContainer />
          </Auth>
        </SuspenseWrapper>
        <RqDevtools />
      </QueryClientProvider>
    );
  }
  if (manager) {
    return (
      <QueryClientProvider client={queryClient}>
        <SuspenseWrapper>
          <Auth>
            <ManagerContainer />
          </Auth>
        </SuspenseWrapper>
        <RqDevtools />
      </QueryClientProvider>
    );
  }
  if (user)
    return (
      <QueryClientProvider client={queryClient}>
        <UserContainer />
        <RqDevtools />
      </QueryClientProvider>
    );

  return (
    <SuspenseWrapper>
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<NonAuth />} />
            <Route path="admin" element={<LoginView isAdmin />} />
            <Route
              path="/admin-forgot-password"
              element={<ForgotView isAdmin />}
            />
            <Route path="manager" element={<LoginView isManager />} />
            <Route
              path="/manager-forgot-password"
              element={<ForgotView isManager />}
            />
            <Route path="user-login/:id/*" element={<UserLogin />} />
            <Route path="code/:id" element={<Code />} />
            <Route path="entry/*" element={<EntryContainer />} />
            <Route path="*" element={<NonAuth />} />
          </Routes>
        </BrowserRouter>
        <RqDevtools />
      </QueryClientProvider>
    </SuspenseWrapper>
  );
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
    manager: state.manager,
    user: state.user,
  };
}
export default connect(mapStateToProps)(App);

const NonAuth = () => (
  <AuthLayout title="Not Logged In!">
    {/* <p>You're not logged in.</p> */}
    <Button component={Link} to={`/manager`} variant="light">
      Manager Login
    </Button>
  </AuthLayout>
);

const ForgotView = ({ isAdmin, isManager, isUser }) => {
  return (
    <AuthLayout>
      <ForgotPassword isManager={isManager} isAdmin={isAdmin} isUser={isUser} />
      <Button
        size="xs"
        component={Link}
        to={isAdmin ? `/admin` : isUser ? `/user` : "/manager"}
        variant="light"
        mt="lg"
      >
        Remember password?
      </Button>
    </AuthLayout>
  );
};

const LoginView = ({ isAdmin, isManager, isUser }) => {
  return (
    <AuthLayout>
      <LoginForm isAdmin={isAdmin} isManager={isManager} isUser={isUser} />
      <Button
        component={Link}
        to={
          isAdmin
            ? `/admin-forgot-password`
            : isUser
            ? `/user-forgot-password`
            : `/manager-forgot-password`
        }
        variant="light"
        size="xs"
        mt="lg"
      >
        Forgot password?
      </Button>
    </AuthLayout>
  );
};

const TicketHolder = ({ children }) => {
  const theme = useMantineTheme();
  return (
    <Wrapper backgroundColor={theme.colors.dark[7]}>
      <div className="wrapper">
        <Card>{children}</Card>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.backgroundColor};

  .wrapper {
    width: 100%;
    width: 375px;
    background: #fff;
  }
`;
