import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, Route, Routes } from "react-router-dom";
import { Card } from "@mantine/core";

import { ArtistForm } from "@components/Artist";
import { SongList } from "@components/Song";
import { ErrorView, PageTitle, Subnav } from "@components/shared";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function ArtistDetail() {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/artists/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  return (
    <div>
      <PageTitle
        title={info.name}
        subtitle={<React.Fragment>Artist</React.Fragment>}
        icon={entityIcon.artist()}
      />
      <Subnav
        buttonColor={entityColor.artist}
        links={[
          { to: `/artists/${id}`, text: "Detail" },
          { to: `/artists/${id}/songs`, text: "Songs" },
        ]}
      />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <React.Fragment>
              <Card mt="lg">
                <ArtistForm id={id} name={info.name} />
              </Card>
            </React.Fragment>
          }
        />
        <Route
          exact
          path="songs"
          element={
            <React.Fragment>
              <SongList artistId={id} />
            </React.Fragment>
          }
        />
      </Routes>
    </div>
  );
}
