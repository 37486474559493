import React from "react";
import { Box, Divider } from "@mantine/core";
import { shallow } from "zustand/shallow";

import { PieChart, BarChart2, LineChart } from "@components/DataViz";
import useAppStore from "./report-recipe-runner-store";
import DynamicTable from "./DynamicTable";
import { HeatMap } from "@components/DataViz";
import ReportRecipeStatBlocks from "./ReportRecipeStatBlocks";

import qbGenerateRequest from "./qb-generate-request";

// VISUALIZERS = {
//   1 => 'pie-chart',
//   2 => 'line-chart',
//   3 => 'bar-chart',
//   4 => 'table'
// }

export default function ReportRecipeVisualizer({ queryChanged }) {
  const {
    queryComponents,
    queryData,
    recipeInfo,
    reportData,
    snapshot,
    savedRecipe,
  } = useAppStore(
    (state) => ({
      queryComponents: state.query_components,
      queryData: state.query_data,
      recipeInfo: state.recipe_info,
      reportData: state.report_data,
      snapshot: state.snapshot,
      savedRecipe: state.saved_recipe,
    }),
    shallow
  );

  const title = reportData.report_title
    ? reportData.report_title
    : snapshot
    ? `${snapshot.title}`
    : savedRecipe
    ? `${savedRecipe.title} - ${recipeInfo.title}`
    : recipeInfo.title;

  return (
    <VisualizerDisplay
      visualizer={queryData.visualizer}
      title={title}
      reportData={reportData}
      queryChanged={queryChanged}
    />
  );
}

export function VisualizerDisplay({
  visualizer,
  queryChanged,
  reportData,
  title,
  sharedReport,
}) {
  const {
    locations,
    orgs,
    queryComponents,
    queryData,
    recipeInfo,
    reportDataQuery,
    snapshot,
    statBlocks,
    tableConfig,
  } = useAppStore(
    (state) => ({
      queryComponents: state.query_components,
      queryData: state.query_data,
      recipeInfo: state.recipe_info,
      reportDataQuery: state.report_data_query,
      snapshot: state.snapshot,
      statBlocks: state.stat_blocks,
      locations: state.locations,
      orgs: state.orgs,
      tableConfig: state.table_config,
    }),
    shallow
  );

  const getSupplementalRequestData = () => {
    if (sharedReport) return {};
    const rqd = reportDataQuery ? { ...reportDataQuery } : null;
    const qd = { ...queryData };
    delete qd.visualizer;
    if (rqd) {
      delete rqd.visualizer;
    }

    return {
      data: snapshot
        ? {
            report_snapshot_id: snapshot.id,
            snapshot,
            visualizers: [],
            table_data: true,
          }
        : {
            ...qbGenerateRequest(queryComponents, queryChanged ? rqd : qd),
            location_ids: locations.map((m) => m.id),
            organization_ids: orgs.map((m) => m.id),
            table_data: true,
          },
      api_endpoint: snapshot
        ? `/report-snapshots/${snapshot.id}/run-report/`
        : `/report-recipes/${recipeInfo.id}/run-report/`,
    };
  };

  function renderTable() {
    const rqd = reportDataQuery
      ? {
          ...reportDataQuery,
          location_ids: locations.map((m) => m.id),
          organization_ids: orgs.map((m) => m.id),
        }
      : null;
    const qd = { ...queryData };
    delete qd.visualizer;
    if (rqd) {
      delete rqd.visualizer;
    }

    return (
      <>
        <DynamicTable
          requestConfig={getSupplementalRequestData()}
          title={title}
        />
      </>
    );
  }

  const visualizerData = sharedReport ? reportData.chart_data : reportData;
  const showStatBlocks = sharedReport
    ? reportData && reportData.meta_block_data.length > 0
    : snapshot
    ? statBlocks.length > 0
    : recipeInfo && recipeInfo.meta_blocks_enabled;

  return (
    <div>
      {showStatBlocks && (
        <>
          <Box>
            <ReportRecipeStatBlocks
              requestConfig={getSupplementalRequestData()}
              statBlockData={sharedReport ? reportData.meta_block_data : null}
            />
          </Box>
          <Divider mt="lg" mb="lg" />
        </>
      )}
      {visualizer === 1 && (
        <PieChart
          title={title}
          subtitle={visualizerData.subtitle}
          seriesData={visualizerData.series}
        />
      )}
      {visualizer === 2 && (
        <LineChart
          title={title}
          pointStart={visualizerData.point_start}
          pointInterval={visualizerData.point_interval}
          pointIntervalUnit={visualizerData.point_interval_unit}
          timezone={visualizerData.timezone}
          yAxisTitle={visualizerData.y_axis_title}
          seriesData={visualizerData.series}
        />
      )}
      {visualizer === 3 && (
        <BarChart2
          title={title}
          pointStart={visualizerData.point_start}
          pointInterval={visualizerData.point_interval}
          pointIntervalUnit={visualizerData.point_interval_unit}
          timezone={visualizerData.timezone}
          xAxisType={visualizerData.x_axis_type}
          yAxisTitle={visualizerData.y_axis_title}
          seriesData={visualizerData.series}
        />
      )}
      {!sharedReport && visualizer === 4 && visualizerData?.table_config && (
        <DynamicTable
          title={title}
          manualTableConfig={visualizerData.table_config}
        />
      )}
      {visualizer === 5 && (
        <Box mb="sm">
          <HeatMap
            data={visualizerData.heatmap_data || []}
            pins={visualizerData.pins || []}
            defaultCenter={
              visualizerData.center &&
              visualizerData.center.lat &&
              visualizerData.center.lng
                ? visualizerData.center
                : null
            }
            defaultZoom={visualizerData.zoom || 10}
          />
        </Box>
      )}
      {visualizer === 6 && (
        <ReportRecipeStatBlocks statBlockData={visualizerData.data} />
      )}
      {/* supplemental table */}
      {!sharedReport && !snapshot && (
        <>{recipeInfo.data_table && visualizer !== 4 && renderTable()}</>
      )}
      {snapshot && tableConfig.data.length > 0 && visualizer !== 4 && (
        <DynamicTable title={title} manualTableConfig={tableConfig} />
      )}
      {sharedReport && reportData.table_config && (
        <DynamicTable
          title={title}
          isShared
          manualTableConfig={reportData.table_config}
        />
      )}
    </div>
  );
}
