export function currencyFormatter(amount) {
  let parseAmount = parseFloat(amount);
  return parseAmount.toFixed(2);
}

export function penniesToDollars(amount) {
  return (amount / 100).toFixed(2);
}

export function dollarsToPennies(amount) {
  if (isNaN(amount)) return;

  return Math.round(Math.round(Math.round(parseFloat(amount) * 100)));
}

// const price = Math.round((parseFloat(dealerPayout) / percentage) * 100) / 100;

// return (
//   10 * Math.round(Math.round(Math.round((price * 100) / 10) / 10) / 10)
// ).toFixed(2);
