import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import { TransferList } from "@components/shared";

export default function KeywordContestManagement({ keywordId }) {
  const [contests, setContests] = useState([]);
  const [availableContests, setAvailableContests] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentContestIds = contests.map((c) => c.id);

  useEffect(() => {
    fetchKeywordContests();
    fetchAvailableContests();
  }, []);

  function onContestsRemove(removedContestIds) {
    const req = {
      keyword_id: keywordId,
      contest_ids: removedContestIds,
    };

    axios
      .post(`/keywords/${keywordId}/remove-contests/`, req)
      .then(() => {
        fetchKeywordContests();
        fetchAvailableContests();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onChange(e) {
    const newContestIds = e[0].map((m) => m.value);
    const removedContestIds = e[1].map((m) => m.value);

    const contestsWereRemoved = currentContestIds.some((r) =>
      removedContestIds.includes(r)
    );

    if (contestsWereRemoved) {
      return onContestsRemove(
        removedContestIds.filter((f) => currentContestIds.includes(f))
      );
    }

    const req = {
      keyword_id: keywordId,
      contest_ids: newContestIds,
    };

    axios
      .post(`/keywords/${keywordId}/add-contests/`, req)
      .then(() => {
        fetchKeywordContests();
        fetchAvailableContests();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function fetchKeywordContests() {
    setLoading(true);

    axios
      .get(`/keywords/${keywordId}/retrieve-contests/`)
      .then(({ data }) => {
        setContests(data.response.sort((a, b) => (a.name > b.name ? 1 : -1)));
      })
      .catch((err) => {
        setContests([]);
      });
  }

  function fetchAvailableContests() {
    axios
      .get(`/keywords/${keywordId}/available-contests/`)
      .then(({ data }) => {
        setAvailableContests(
          data.response.sort((a, b) => (a.name > b.name ? 1 : -1))
        );
      })
      .catch((err) => {
        setAvailableContests([]);
      });
  }

  const formattedAdded = formatOptions(contests);
  const formattedAvailable = formatOptions(availableContests);

  return (
    <div>
      <TransferList
        breakpoint="sm"
        listHeight={400}
        value={[[...formattedAdded], [...formattedAvailable]]}
        onChange={onChange}
        searchPlaceholder="Search..."
        nothingFound="Nothing here..."
        titles={["Current Contests", "Available Contests"]}
      />
    </div>
  );
}

const formatOptions = (opts) =>
  opts.map((m) => ({
    value: m.id,
    label: m.name,
    group: m.organization_name
      ? `${m.organization_name}`
      : m.location_name
      ? `${m.location_name}`
      : "",
  }));
