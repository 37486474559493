import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import { TransferList } from "@components/shared";
import { KeywordManagement } from "@components/Keyword";

export default function ContestKeywordManagement({
  contestId,
  hideTransfer = false,
}) {
  if (hideTransfer) {
    return <KeywordManagement contestId={contestId} />;
  }

  const [keywords, setKeywords] = useState([]);
  const [availableKeywords, setAvailableKeywords] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentKeywordIds = keywords.map((c) => c.id);

  useEffect(() => {
    fetchContestKeywords();
    fetchKeywords();
  }, []);

  function onChange(e) {
    const newKeywordIds = e[0].map((m) => m.value);
    const removedKeywordIds = e[1].map((m) => m.value);

    const keywordsWereRemoved = currentKeywordIds.some((r) =>
      removedKeywordIds.includes(r)
    );

    if (keywordsWereRemoved) {
      return onKeywordsRemove(
        removedKeywordIds.filter((f) => currentKeywordIds.includes(f))
      );
    }

    const req = {
      contest_id: contestId,
      keyword_ids: newKeywordIds,
    };

    axios
      .post(`/contests/${contestId}/add-keywords/`, req)
      .then(() => {
        fetchContestKeywords();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onKeywordsRemove(removedKeywordIds) {
    const req = {
      contest_id: contestId,
      keyword_ids: removedKeywordIds,
    };

    axios
      .post(`/contests/${contestId}/remove-keywords/`, req)
      .then(() => {
        fetchKeywords();
        fetchContestKeywords();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function fetchContestKeywords() {
    setLoading(true);

    axios
      .post(`/retrieve-keywords/`, {
        contest_id: contestId,
        pagination: false,
      })
      .then(({ data }) => {
        setKeywords(data.response);
      })
      .catch((err) => {
        setKeywords([]);
      });
  }

  function fetchKeywords() {
    axios
      .get(`/contests/${contestId}/retrieve-available-keywords/`)
      .then(({ data }) => {
        setAvailableKeywords(data.response);
      })
      .catch((err) => {
        setAvailableKeywords([]);
      });
  }

  const formattedAdded = keywords.map((m) => ({
    value: m.id,
    label: `${m.text}${
      m.organization_name || m.location_name
        ? ` (${m.organization_name || m.location_name})`
        : ""
    }`,
    group: m.campaign_name ? `${m.campaign_name} Campaign` : "",
  }));

  const addedIds = keywords.map((m) => m.id);

  const formattedAvailable = availableKeywords
    .filter((f) => !addedIds.includes(f.id))
    .map((m) => ({
      label: m.text,
      value: m.id,
      group: m.campaign_name
        ? `${m.campaign_name} Campaign`
        : m.campaign_effort_name
        ? `${m.campaign_effort_name} Effort`
        : "",
    }));

  return (
    <div>
      <TransferList
        breakpoint="sm"
        value={[[...formattedAdded], [...formattedAvailable]]}
        onChange={onChange}
        searchPlaceholder="Search..."
        nothingFound="Nothing here..."
        listHeight={400}
        titles={["Current Keywords", "Available Keywords"]}
      />
    </div>
  );
}
