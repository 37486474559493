import React, { useState } from "react";
import axios from "axios";
import { shallow } from "zustand/shallow";
import { Button, TextInput, Space } from "@mantine/core";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import {
  AllowCheckinQuestion,
  AllowGeospotQuestion,
  AllowLocationCheckin,
  MembersOnlyQuestion,
  MicrositeQuestion,
  OptionList,
  SectionTitle,
} from "./WizardComponents";
import { CustomTooltip } from "@components/shared";
import useAppStore from "./wizard-store";
import { entryOptions } from "./helpers";
import { locationVariety } from "@components/Location/helpers";
import { initialConfig as initialMicrositeConfig } from "@components/shared/MicrositeSettings/helpers";
import {
  initialState as effortInitialSettings,
  generateEffortRebounds,
} from "@components/Effort/EffortSettings/helpers";
import {
  effortWizardSettings,
  defaultEffortConfig,
} from "@components/Effort/helpers";

export default function WizardMultiSweepstakes() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    allowCheckin,
    allowGeospot,
    allowKeyword,
    allowLocationCheckin,
    campaignSettings,
    location,
    memberOnly,
    microsite,
    organization,
    recurring,
    registration,
    setAllowGeospot,
    setAllowCheckin,
    setCampaignSettings,
    setRecurring,
    setRegistration,
  } = useAppStore(
    (state) => ({
      allowCheckin: state.allow_checkin,
      allowGeospot: state.allow_geospot,
      allowKeyword: state.allow_keyword,
      allowLocationCheckin: state.allow_location_checkin,
      campaignSettings: state.campaignSettings,
      location: state.location,
      memberOnly: state.member_only,
      microsite: state.microsite,
      organization: state.organization,
      recurring: state.recurring,
      registration: state.registration,
      setAllowCheckin: state.setAllowCheckin,
      setAllowGeospot: state.setAllowGeospot,
      setCampaignSettings: state.setCampaignSettings,
      setRecurring: state.setRecurring,
      setRegistration: state.setRegistration,
    }),
    shallow
  );

  const isLocationDealer = location
    ? location.variety === locationVariety.dealer
    : false;
  const micrositeAnswered =
    memberOnly === false ? microsite !== null : microsite !== null;

  function onSubmit() {
    const req = {
      multi_sweeps: {
        location_id: location ? location.id : null,
        organization_id: organization ? organization.id : null,
        recurring_engagement: recurring ? 1 : 2,
        title: campaignSettings.title,
        award_type: 1,
        variety: 5,
        draft_app_configuration: initialMicrositeConfig,
        draft_configuration: defaultEffortConfig,
        settings: {
          ...effortInitialSettings,
          ...generateEffortRebounds(campaignSettings.title),
        },
        wizard_settings: {
          ...effortWizardSettings,
          activate_keyword_on_create: registration === "keyword",
          allow_checkin: allowCheckin === true,
          allow_location_checkin: !recurring
            ? false
            : !allowCheckin
            ? false
            : allowLocationCheckin,
          // allow_geospot: !recurring ? false : allowGeospot,
          builder_required: true,
          contest_required: true,
          end_required: true,
          featured_image_required: true,
          keyword_sms_required: true,
          keyword_required: registration === "keyword",
          // locations_required: organization ? true : false,
          locations_required: false,
          look_and_feel_required: microsite ? true : false,
          microsite,
          member_only: memberOnly,
          recurring,
          registration_method: registration,
          show_awards: true,
          show_builder: registration === "form",
          show_geospots: allowGeospot === true,
          // show_keywords: !recurring ? false : true,
          show_look_feel: microsite,
          show_keywords: recurring || registration === "keyword" ? true : false,
          start_required: true,
          use_checkins: allowCheckin === true,
          use_keywords: recurring ? true : false,
        },
      },
    };

    axios
      .post(`/wizard/`, req)
      .then(({ data }) => {
        setLoading(false);
        navigate(`/efforts/${data.response[0].campaign_effort_id}`);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const submitDisabled = !campaignSettings.title ? true : false;
  const checkinAnswered =
    recurring === false ? true : organization ? true : allowCheckin !== null;

  return (
    <div>
      <React.Fragment>
        <Space mt="lg" />
        <SectionTitle text="Can the user engage repeatedly, or only once?" />
        <OptionList
          options={[
            {
              label: (
                <label>
                  <CustomTooltip
                    label={
                      "Choose this if the user can increase their odds of winning by checking-in or submitting keywords"
                    }
                  >
                    Repeatedly
                  </CustomTooltip>
                </label>
              ),
              value: true,
            },
            {
              label: "Only once",
              value: false,
            },
          ].map((opt) => ({
            label: opt.label,
            onChange: () => {
              setRecurring(opt.value);
              if (!opt.value) {
                setAllowCheckin(false);
                setAllowGeospot(false);
              }
            },
            active: recurring === opt.value,
          }))}
        />
      </React.Fragment>
      {recurring !== null && (
        <>
          {/* {recurring !== null  && ( */}
          {!isLocationDealer && <MembersOnlyQuestion />}
          {!isLocationDealer && memberOnly === true && (
            <React.Fragment>
              <Space mt="lg" />
              <MicrositeQuestion />
            </React.Fragment>
          )}
          {micrositeAnswered && (
            <React.Fragment>
              <Space mt="lg" />
              <SectionTitle text="How should the user enter?" />
              <OptionList
                options={entryOptions.map((opt) => ({
                  label: opt.label,
                  onChange: () => setRegistration(opt.value),
                  active: registration === opt.value,
                }))}
              />
            </React.Fragment>
          )}
        </>
      )}
      {recurring && registration && (
        <>
          {!organization && <AllowCheckinQuestion />}
          {allowCheckin && <AllowLocationCheckin />}
          {allowLocationCheckin && <AllowGeospotQuestion />}
        </>
      )}
      {checkinAnswered &&
        recurring !== null &&
        registration &&
        micrositeAnswered && (
          <>
            <Space mt="lg" />
            <CustomTooltip label="Campaign titles will be visible to the users.">
              <SectionTitle text="Tell us a little more" />
            </CustomTooltip>
            <TextInput
              label="Title"
              maxLength={100}
              value={campaignSettings.title}
              onChange={(e) =>
                setCampaignSettings({
                  ...campaignSettings,
                  title: e.target.value,
                })
              }
            />
            <Button
              mt="sm"
              fullWidth
              onClick={onSubmit}
              loading={loading}
              disabled={submitDisabled}
            >
              Create
            </Button>
          </>
        )}
    </div>
  );
}
