import React, { useState } from "react";
import { Button } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { Parser } from "@json2csv/plainjs";

export default function SongReportCsvButton({ reqData }) {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {
      ...reqData,
      pagination: false,
    };

    axios
      .post(`/song-reports/`, req)
      .then(({ data }) => {
        setLoading(false);
        downloadData(data.response[0].data);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function downloadData(data) {
    const today = dayjs().format("YYYY-MM-DDTHH:mm:ssZ[Z]");
    const opts = {
      fields: [
        { label: "Artist", value: "artist_name" },
        { label: "Title", value: "song_name" },
        { label: "Category", value: "in_rotation_category.name" },
        { label: "Score", value: "score_historic" },
        { label: "Burn", value: "burn_historic" },
        { label: "1", value: "one_rating_historic" },
        { label: "2", value: "two_rating_historic" },
        { label: "3", value: "three_rating_historic" },
        { label: "4", value: "four_rating_historic" },
        { label: "5", value: "five_rating_historic" },
        { label: "Sample", value: "count_historic" },
        { label: "Sound", value: "in_sound_code.name" },
      ],
    };
    const parser = new Parser(opts);
    const csv = parser.parse(data);
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${today}-song-report.csv`;
    link.click();
  }

  return (
    <Button size="xs" variant="light" onClick={onClick} loading={loading}>
      Download CSV
    </Button>
  );
}
