import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";
import { Box, Button, Flex, Divider } from "@mantine/core";

const ratingLetters = ["M", "L"];
const ratingNumbers = [1, 2, 3, 4, 5];
const initialRating = {
  letter: "",
  number: "",
};

export default function MessageForm({
  contextFilter,
  joinId,
  onSuccess,
  title,
}) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(initialRating);

  const [searchParams] = useSearchParams();

  const entityJoinType = searchParams.get("entity_join_type");

  const manager = useSelector((state) => state.manager);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    setRating(initialRating);
    setLoading(false);
  }, [contextFilter]);

  const locationId =
    user && user.current_user_hash
      ? user.current_user_hash.location_id
      : manager.location_id;

  useEffect(() => {
    setMessage("");
  }, [window.location.href]);

  function onSubmit(e) {
    e.preventDefault();
    if (message.trim().length === 0) return setMessage("");
    const req = {
      message_text: message,
      context: contextFilter,
    };

    // from a manager
    if (entityJoinType) req[entityJoinType] = joinId;

    axios
      .post(`/send-convo-message/`, req)
      .then(() => {
        setMessage("");
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onRatingSubmit() {
    const req = {
      message_text: `${rating.number}${rating.letter}`,
      context: contextFilter,
    };

    setLoading(true);

    axios
      .post(`/send-convo-message/`, req)
      .then(() => {
        setLoading(false);
        setRating(initialRating);
        setMessage("");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  if (contextFilter === "rating") {
    return (
      <Box
        style={{
          overflow: "hidden",
        }}
      >
        <SongRatingSelections
          letterValue={rating.letter}
          numberValue={rating.number}
          onLetterChange={(e) =>
            setRating({
              ...rating,
              letter: e,
            })
          }
          onNumberChange={(e) =>
            setRating({
              ...rating,
              number: e,
            })
          }
        />
        <Button
          loading={loading}
          onClick={onRatingSubmit}
          disabled={!rating.letter || !rating.number}
          fullWidth
          mt="sm"
        >
          Submit
        </Button>
      </Box>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <Textarea
        id="msgInput"
        value={message}
        placeholder={
          contextFilter === "conversational"
            ? `Message ${title}`
            : "Enter keyword"
        }
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit(e);
          }
        }}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
    </form>
  );
}

const Textarea = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 1.15em;
  border-radius: 5px;
  /* border: 2px solid #000; */
  border: none;
  background: var(--inputBackground);
  margin-bottom: 1em;
  color: var(--textThirdColor);
  font-family: inherit;

  &:focus {
    outline: none;
  }
`;

export const SongRatingSelections = ({
  numberValue,
  letterValue,
  onNumberChange,
  onLetterChange,
  direction = "row",
}) => {
  return (
    <Flex
      direction={direction}
      align="center"
      gap="lg"
      pb="sm"
      style={{
        overflowX: "scroll",
      }}
    >
      <Flex align="center" gap="xs">
        {ratingNumbers.map((r, i) => (
          <Button
            key={i}
            variant={numberValue === r ? "light" : "subtle"}
            onClick={() => onNumberChange(r)}
          >
            {r}
          </Button>
        ))}
      </Flex>
      {direction === "row" && <Divider orientation="vertical" opacity={0.25} />}
      <Flex align="center" gap="xs">
        {ratingLetters.map((r, i) => (
          <Button
            key={i}
            variant={letterValue === r ? "light" : "subtle"}
            onClick={() => onLetterChange(r)}
          >
            {r}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};
