import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Group,
  Modal,
  Select,
  Switch,
  Text,
  TextInput,
  UnstyledButton,
} from "@mantine/core";
import toast from "react-hot-toast";
import axios from "axios";

import { UserContactTransfer } from "@components/User";
import { OrganizationSelect } from "@components/Organization";
import { LocationSelect } from "@components/Location";

import { AddressLookup } from "@components/shared";
import usStates from "@lib/usStates";
import countries from "@lib/countries";
import entityIcon from "@util/entityIcon";

export default function UserCreate() {
  const [loading, setLoading] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    address_1: "",
    address_2: "",
    city: "",
    state_province: "",
    zip: "",
    country: "",
    phone: null,
    email: null,
  });

  const navigate = useNavigate();

  function onSubmit() {
    setLoading(true);

    const req = {
      ...user,
    };

    delete req.phone;
    delete req.email;

    if (orgId) req.organization_id = orgId;
    if (locationId) req.location_id = locationId;
    if (user.email) req.email = user.email.value;
    if (user.phone) req.phone = user.phone.value;
    if (
      (user.email && user.email.verified) ||
      (user.email && user.email.manual_verification)
    )
      req.email_verified = true;
    if (
      (user.phone && user.phone.verified) ||
      (user.phone && user.phone.manual_verification)
    )
      req.phone_verified = true;

    axios
      .post(`/users/`, req)
      .then(({ data }) => {
        setLoading(false);
        toast.success("User created!");
        navigate(`/users/${data.response[0].user_id}`);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const hasVerifiedContactMethod = [
    user.phone ? user.phone.verified : false,
    user.email ? user.email.verified : false,
    user.phone && !user.phone.verified && user.phone.manual_verification,
    user.email && !user.email.verified && user.email.manual_verification,
  ].includes(true);

  const userInvalid =
    !user.first_name ||
    !user.last_name ||
    !user.email ||
    !user.phone ||
    !user.zip ||
    !hasVerifiedContactMethod ||
    (!orgId && !locationId);

  return (
    <div>
      <Card>
        <Text fw={600} size="lg">
          Create user
        </Text>
        <Text size="sm" c="dimmed">
          Fill out the following fields. A new user must have at least one
          verified contact method
        </Text>
        <Grid mt="sm">
          <Grid.Col span={{ base: 6 }}>
            <Text fw={600} mb="sm">
              Demographics
            </Text>
            <Group grow>
              <TextInput
                label="First Name"
                required
                maxLength={100}
                value={user.first_name}
                onChange={(e) =>
                  setUser({
                    ...user,
                    first_name: e.target.value,
                  })
                }
              />
              <TextInput
                label="Last Name"
                maxLength={100}
                required
                value={user.last_name}
                onChange={(e) =>
                  setUser({
                    ...user,
                    last_name: e.target.value,
                  })
                }
              />
            </Group>
            <AddressLookup
              onChange={(e) =>
                setUser({
                  ...user,
                  address_1: e,
                })
              }
              required={false}
              onSelect={(e) => {
                setUser({
                  ...user,
                  address_1: e.address_1,
                  city: e.city,
                  state_province: e.state,
                  zip: e.zip,
                  country: e.country,
                });
              }}
            />
            <TextInput
              label="Address line 2"
              value={user.address_2}
              onChange={(e) =>
                setUser({
                  ...user,
                  address_2: e.target.value,
                })
              }
            />
            <Group grow>
              <TextInput
                label="City"
                value={user.city}
                onChange={(e) =>
                  setUser({
                    ...user,
                    city: e.target.value,
                  })
                }
              />
              <Select
                label="State/Province"
                value={user.state_province}
                placeholder="Select one..."
                data={usStates}
                searchable
                onChange={(e) =>
                  setUser({
                    ...user,
                    state_province: e,
                  })
                }
              />
              <TextInput
                label="Zip"
                required
                value={user.zip}
                onChange={(e) =>
                  setUser({
                    ...user,
                    zip: e.target.value,
                  })
                }
              />
            </Group>
            <Select
              label="Country"
              value={user.country}
              placeholder="Select one..."
              data={countries}
              searchable
              onChange={(e) =>
                setUser({
                  ...user,
                  country: e,
                })
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6 }}>
            <Text fw={600} mb="sm">
              Contact Methods
            </Text>
            <ContactBox
              title="Phone"
              data={user.phone}
              isPhone
              onRemoveClick={() =>
                setUser({
                  ...user,
                  phone: null,
                })
              }
              setContactMethod={(e) =>
                setUser({
                  ...user,
                  phone: e,
                })
              }
            />
            <Divider mt="lg" mb="lg" />
            <ContactBox
              title="Email"
              isEmail
              data={user.email}
              onRemoveClick={() =>
                setUser({
                  ...user,
                  email: null,
                })
              }
              setContactMethod={(e) =>
                setUser({
                  ...user,
                  email: e,
                })
              }
            />
            {user.email && user.phone && !hasVerifiedContactMethod && (
              <Text color="red" fw={600} mt="xl" size="sm">
                The user must have at least one verified contact method
              </Text>
            )}
          </Grid.Col>
        </Grid>
        <Divider mt="lg" mb="lg" />
        <Text fw={600}>Add User Join</Text>
        <Text size="sm" c="dimmed" mb="xs">
          Add an organization or location to this user
        </Text>
        <Group grow>
          <OrganizationSelect
            value={orgId}
            disabled={locationId ? true : false}
            onChange={(e) => {
              setOrgId(e);
              setLocationId(null);
            }}
          />
          <LocationSelect
            label="Location"
            value={locationId}
            disabled={orgId ? true : false}
            onChange={(e) => {
              setOrgId(null);
              setLocationId(e);
            }}
          />
        </Group>
        {(orgId || locationId) && (
          <Group mt="sm">
            <Text size="sm" fw={600}>
              {locationId ? "A location" : "An organization"} will be added to
              this user
            </Text>
            <ActionIcon
              size="xs"
              color="gray"
              onClick={() => {
                setOrgId(null);
                setLocationId(null);
              }}
            >
              {entityIcon.trash()}
            </ActionIcon>
          </Group>
        )}
        <Button
          loading={loading}
          fullWidth
          mt="lg"
          size="lg"
          disabled={userInvalid}
          onClick={onSubmit}
        >
          Create user
        </Button>
      </Card>
    </div>
  );
}

const ContactBox = ({
  data,
  isEmail,
  isPhone,
  onRemoveClick,
  setContactMethod,
  title,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setOpen] = useState(false);

  const icon = isPhone ? entityIcon.phone(24) : entityIcon.email(24);

  function onClose() {
    setOpen(false);
    setInputValue("");
  }

  const isVerified = data ? data.verified || data.manual_verification : false;

  return (
    <div>
      <Text size="xs" mb="xs" fw={600}>
        {title}
      </Text>
      {data ? (
        <React.Fragment>
          <Group>
            {icon} <Text>{data.value}</Text>{" "}
            <Text
              fw={isVerified ? 600 : 400}
              size="xs"
              color={isVerified ? "green" : "gray"}
            >
              {isVerified ? "verified" : "unverified"}
            </Text>
            <ActionIcon size="xs" color="gray" onClick={onRemoveClick}>
              {entityIcon.trash()}
            </ActionIcon>
          </Group>
          {!data.verified && (
            <Switch
              label="I manually verified this"
              size="xs"
              mt="sm"
              checked={data.manual_verification}
              onChange={() =>
                setContactMethod({
                  ...data,
                  manual_verification: !data.manual_verification,
                })
              }
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <UnstyledButton
            onClick={() => setOpen(true)}
            style={{ width: "100%" }}
          >
            <Box
              style={{
                background: "var(--mantine-color-green-0)",
                padding: "var(--mantine-spacing-xl)",
                textAlign: "center",
                border: `1px solid var(--mantine-color-gray-4)`,
                borderRadius: "3px",
              }}
            >
              <Text>{icon}</Text>
              <Text fw={600}>Click to add {title.toLowerCase()}</Text>
            </Box>
          </UnstyledButton>
          <Modal opened={isOpen} onClose={onClose}>
            <UserContactTransfer
              selected={null}
              setSelected={() => true}
              isPhone={isPhone}
              isEmail={isEmail}
              value={inputValue}
              onVerifySkip={(e) => {
                setContactMethod({
                  value: e,
                  verified: false,
                  manual_verification: false,
                });
                onClose();
              }}
              onVerifySuccess={(e) => {
                setContactMethod({
                  value: e,
                  verified: true,
                });
                onClose();
              }}
              newUser
            />
          </Modal>
        </React.Fragment>
      )}
    </div>
  );
};
