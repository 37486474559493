import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Title } from "@mantine/core";

import { AdminForm, AdminList } from "./";
import { ManagementLayout } from "@components/shared";

export default function AdminManagement() {
  const [admins, setAdmins] = useState([]);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    fetchAdmins();
  }, []);

  function fetchAdmins() {
    axios
      .post(`/retrieve-admins/`, {
        status: 2,
        variety: 1,
      })
      .then(({ data }) => {
        setAdmins(
          data.response.map((m) => ({
            ...m,
            link_url: `/admins/${m.id}`,
          }))
        );
      })
      .catch((err) => {
        setAdmins([]);
      });
  }

  return (
    <ManagementLayout
      dataComponent={<AdminList items={admins} />}
      createComponent={
        <Card>
          <Title order={4}>Create Admin</Title>
          {!success && (
            <AdminForm
              onSuccess={() => {
                fetchAdmins();
                setSuccess(!success);
                setTimeout(() => {
                  setSuccess(false);
                }, 1);
              }}
            />
          )}
        </Card>
      }
    />
  );
}
