import React, { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import { Anchor, Image } from "@mantine/core";
import { Carousel } from "@mantine/carousel";

export default function ImageSlider({
  items = [],
  sliderProps = {},
  imageFormatter = null,
}) {
  const autoplay = useRef(Autoplay({ delay: 5000 }));

  if (!items.length) return null;

  return (
    <Carousel withIndicators loop plugins={[autoplay.current]} {...sliderProps}>
      {items.map((m, i) => (
        <Carousel.Slide key={i}>
          {m.external_link_url ? (
            <Anchor href={m.external_link_url} target="_blank">
              {imageFormatter ? imageFormatter(m) : <Image src={m.src} />}
            </Anchor>
          ) : imageFormatter ? (
            imageFormatter(m)
          ) : (
            <Image src={m.src} />
          )}
        </Carousel.Slide>
      ))}
    </Carousel>
  );
}
